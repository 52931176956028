import React, { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { MeetingContext } from "../../Povider";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";
import {
    MicIcon,
    MuteMicIconMute,
    VideoCallIcon,
    VideoCallIconBlack,
} from "../../../images";
import {AvatarNormal} from "../../common";

const VideoTile = ({
                       userId,
                       muted,
                       videoTileState,
                       showIcon,
                       aspectRatio = "1080/720",
                       showUserAvtar=true,
                   }) => {
    const videoElementRef = useRef(null);
    const meetingContext = useContext(MeetingContext);

    const users = useSelector((state) => state.auth.users);

    const videoTileUser = useMemo(() => users?.[userId], [users, userId]);
    const userFullName = useMemo(
        () =>
            [videoTileUser?.firstName, videoTileUser?.lastName]
                .filter(Boolean)
                .join(" "),
        [videoTileUser?.firstName, videoTileUser?.lastName]
    );

    const updateVideoTile = useCallback(() => {
        if (videoTileState?.tileId && videoElementRef?.current) {
            meetingContext.bindVideoTile({
                tileId: videoTileState.tileId,
                videoElement: videoElementRef.current,
            });
        }
    }, [videoTileState?.tileId]);

    useEffect(() => {
        updateVideoTile();
    }, [updateVideoTile]);

    return (
        <>
            <div
                style={{
                    borderRadius: "5px",
                    aspectRatio,
                    height: "fit-content",
                    position: "relative",
                    backgroundColor:"#E9EDF2"
                }}
            >
                {showIcon && (
                    <div
                        style={{
                            display: "flex",
                            padding: "5px",
                            gap: "5px",
                            zIndex: "1",
                            width: "100%",
                            position: "absolute",
                            justifyContent: "flex-end",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-around",
                                backgroundColor: "#f0f8ff69",
                                borderRadius: "4px",
                                padding: "2px",
                                boxShadow: " 0px 3px 3px 0px #00000029",
                            }}
                        >
                            {videoTileState?.boundVideoStream?.active ? (
                                <VideoCallIcon w={25} color="#000000" />
                            ) : (
                                <VideoCallIconBlack w={25} />
                            )}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-around",
                                backgroundColor: "#f0f8ff69",
                                borderRadius: "4px",
                                padding: "2px",
                                boxShadow: " 0px 3px 3px 0px #00000029",
                            }}
                        >
                            {!muted ? (
                                <MicIcon w={25} color="#000000" />
                            ) : (
                                <MuteMicIconMute w={25} />
                            )}
                        </div>
                    </div>
                )}
                {showUserAvtar &&
                    <>
                    {!videoTileState?.boundVideoStream?.active && (
                    <div
                        style={{
                            top:"15px",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection:'column',
                            gap:"15px",
                            alignItems: "center",
                            height: "100%", // Ensure it takes the height of the parent container
                            position: "absolute",
                            width: "100%", // Ensure it takes the width of the parent container
                        }}
                    >
                        <AvatarNormal
                            username={userFullName}
                            size={40}
                            avatarColor="white"
                            sx={{
                                font: "normal normal 300 13px/16px Source Serif Pro",
                                color: "black",
                            }}
                        />
                        <Stack
                            sx={{
                                backgroundColor: "#d9dcdf80",
                                borderRadius: "5px",
                                padding: "2px 8px",
                                maxWidth: "90%",
                                whiteSpace: "nowrap",
                                zIndex:"1",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}
                        >
                            {userFullName || userId}
                        </Stack>
                    </div>
                )}</>}
                {videoTileState?.tileId ? (
                    <video
                        ref={videoElementRef}
                        autoPlay={true}
                        playsInline={true}
                        muted={false}
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            backgroundColor:"#E9EDF2",
                            borderRadius: "inherit",
                            // display: videoTileState?.active ? "block" : "none",
                            aspectRatio,
                        }}
                    />
                ) : (
                    <></>
                )}
                {videoTileState?.boundVideoStream?.active && <Stack
                    sx={{
                        backgroundColor: "#d9dcdf80",
                        borderRadius: "5px",
                        padding: "2px 8px",
                        position: "absolute",
                        bottom: "10%",
                        transform: "translateX(-50%)",
                        left: "50%",
                        zIndex: 1,
                        maxWidth: "90%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {userFullName || userId}
                </Stack>}
            </div>
        </>
    );
};

export default VideoTile;
