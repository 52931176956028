import { Stack, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../Utils";

interface PageSidebarViewProps {
  countNumber: number | string;
  isSelected: boolean;
  onContainerClick: () => void;
}

const PageSidebarView: React.FC<PageSidebarViewProps> = ({
  countNumber,
  isSelected,
  onContainerClick,
}) => {
  return (
    <Stack
      onClick={onContainerClick}
      sx={{
        height: "163px",
        width: "125px",
        borderRadius: "10px",
        border: `2px solid   ${isSelected ? adjustHexColor("#A53A01", 45) : adjustHexColor("#E9EDF2", 0)}`,
        boxSizing: "border-box",
        bgcolor: adjustHexColor("#E9EDF2", 45),
      }}
    >
      {isSelected && (
        <Stack
          sx={{
            width: "35px",
            height: "41px",
            border: "2px solid #A53A01",
            borderRadius: "10px 0px",
            position: "relative",
            right: "2px",
            bottom: "2px",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "white",
          }}
        >
          <Typography
            sx={{
              font: "normal normal 900 18px/23px Source Serif Pro",
              textAlign: "center",
              cursor: "default",
            }}
          >
            {countNumber}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default PageSidebarView;
