import React, { FC, useState } from "react";
import {
  IconDropdownUser,
  // IconDropdownUserOptionInterface,
  // IconDropdownUserOptionInterface,
} from "../../../../../../../../common";
import { Stack, Typography } from "@mui/material";
import { getUserList } from "../../../../../../../../../Utils";

interface PersonViewProps {
  initialUserId?: string; // Accept the initial user ID
  onChange?: (userId: string) => void; // Callback to update the selected user ID
}

const PersonView: FC<PersonViewProps> = ({ initialUserId, onChange }) => {
  const userList = getUserList();
  const [selectedUser, setSelectedUser] = useState<any | null>(
    initialUserId
      ? userList?.find((user: any) => user?._id?.toString() === initialUserId) ||
          null
      : null
  );

  const handleUserSelect = (value: any) => {
    setSelectedUser(value); // Update the selected user state
    if (onChange) {
      onChange(value?._id?.toString()); // Pass the selected user ID to the parent component
    }
  };

  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
      <IconDropdownUser
        componentType="showUser"
        options={userList}
        showSelectedUser={selectedUser}
        onSelect={(user: any) => handleUserSelect(user)} // Handle user selection
      />
      {/* Display the selected user's name dynamically */}
      <Typography sx={{ font: "normal normal 300 14px/20px Source Serif Pro" }}>
        {selectedUser?.firstName + selectedUser?.lastName || "No user selected"}
      </Typography>
    </Stack>
  );
};

export default PersonView;
