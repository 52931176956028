import { Box, Popper, Stack, Paper } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  addReactionToMessage,
  removeReactionToMessage,
} from "../../../redux/Slice/message/messageActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { MessagesState } from "../../../redux/Slice/message/messageTypes";
import { AuthState } from "../../../redux";
import { init, getEmojiDataFromNative } from "emoji-mart";
import data from "@emoji-mart/data";
import {MEETING_ENUMS} from "../../pages/Message/constant";

interface ChatEmojiPreviewProps {
  emoji?: any;
  handleRemove?: any;
  messageId: any;
  userId: any;
}

const ChatEmojiPreview: React.FC<ChatEmojiPreviewProps> = ({
  emoji,
  userId,
  messageId,
}) => {
  const { currentChannelId, currentChannel, slackUserProfile } = useSelector(
    (state: RootState) => state.message as MessagesState
  );
  const { user } = useSelector((state: RootState) => state.auth as AuthState);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [resolvedEmoji, setResolvedEmoji] = useState<string | null>(null);

  const dispatch = useDispatch();

  // Initialize emoji data
  init({ data });

  // Handle mouse enter and leave events
  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  // Function to convert unified ID or shortcode to emoji
  const unifiedToEmoji = async (input?: string) => {
    if (!input) {
      return null; // Return null if no input is provided
    }

    // Check if the input is a valid unified ID (hexadecimal format)
    const isUnifiedId = /^[0-9a-fA-F]+(-[0-9a-fA-F]+)*$/.test(input);
    if (isUnifiedId) {
      try {
        const codePoints = input.split("-").map((cp) => parseInt(cp, 16));
        return String.fromCodePoint(...codePoints); // Convert unified ID to emoji
      } catch (error) {
        console.error("Error converting unified ID:", error);
      }
    } else {
      // If it's not a unified ID, treat it as a shortcode
      const emojiData = await getEmojiDataFromNative(input); // Get emoji data from shortcode
      if (emojiData) {
        const emojiUnified = emojiData.unified; // Get the unified ID from the shortcode
        const codePoints = emojiUnified
          .split("-")
          .map((cp: string) => parseInt(cp, 16));
        return String.fromCodePoint(...codePoints); // Convert to emoji
      }
    }

    return null; // Return null if neither input is valid
  };

  // Effect to resolve emoji when component mounts or emoji prop changes
  useEffect(() => {
    const fetchEmoji = async () => {
      const emojiChar = await unifiedToEmoji(emoji?.emoji); // Call your async function
      setResolvedEmoji(emojiChar); // Set the resolved emoji in state
    };

    fetchEmoji(); // Call the fetch function
  }, [emoji]); // Dependency array ensures it runs when 'emoji' changes

  // Function to handle adding/removing reactions
  const handleSelectedEmojiToRemove = async () => {
    const currentUserId =
      currentChannel.channelType === MEETING_ENUMS.SLACK
        ? slackUserProfile?.id
        : user?.data?._id;
    const isReaction = emoji.usersId.includes(currentUserId);
    const payload = {
      emoji: emoji?.emoji,
      emojiId: emoji?.emoji,
      messageId: messageId,
      channelId: currentChannelId,
      channelType: currentChannel?.channelType
        ? currentChannel?.channelType
        : MEETING_ENUMS.BEARISH,
        teamId: currentChannel?.teamId,
    };

    if (isReaction) {
      await dispatch(removeReactionToMessage(payload));
    } else {
      await dispatch(addReactionToMessage(payload));
    }
  };

  return (
    <Stack onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Stack
        sx={{
          cursor: "pointer",
          fontSize: "16px",
          bgcolor: "#E9EDF2",
          borderRadius: "50px",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
        onClick={handleSelectedEmojiToRemove}
      >
        {resolvedEmoji} {/* Render resolved emoji here */}
      </Stack>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="top"
        onMouseLeave={handleMouseLeave}
        sx={{
          zIndex: 10,
          padding: "5px",
        }}
      >
        <Paper
          sx={{
            boxShadow: "0px 3px 3px rgba(0,0,0,0.15)",
            borderRadius: "5px",
            bgcolor: "white",
          }}
        >
          <Box sx={{ p: 2 }}>{emoji?.usersName?.join(", ")}</Box>
        </Paper>
      </Popper>
    </Stack>
  );
};

export default ChatEmojiPreview;
