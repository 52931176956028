import * as React from "react";
import {
  Box,
  FormControl,
  InputAdornment,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import S from "./IconwordSmallDropdown.module.css";
import {
  ClockIcon,
  FlagIcon,
  MultiselectIcon,
  SelectIcon,
  StatusIcon,
  StrongestrelationshipIcon,
} from "../../../images";
import { useNavigate, useSearchParams } from "react-router-dom";

interface Option {
  id: number;
  value: string;
  icon: React.ElementType;
}

interface IconwordSmallDropdownProps {
  options?: Option[];
  initialValue?: { value: string };
  width?: string;
  onChange?: (selectedOption: { id: number; value: string }) => void;
}

const optionW: Option[] = [
  { id: 1, value: "Status", icon: SelectIcon },
  { id: 2, value: "Assigned to", icon: StrongestrelationshipIcon },
  { id: 3, value: "Priority", icon: FlagIcon },
  { id: 4, value: "Due Date", icon: ClockIcon },
  { id: 5, value: "Task type", icon: StatusIcon },
];

const IconwordSmallDropdown: React.FC<IconwordSmallDropdownProps> = ({
  options = optionW,
  initialValue = { value: "Status" },
  width = "125px",
  onChange,
  ...props
}) => {
  const [selectedValue, setSelectedValue] = React.useState<Option | undefined>(
    initialValue
      ? options?.find((option: Option) => option?.value === initialValue?.value)
      : options[0]
  );
  const [searchParams] = useSearchParams();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const handleChange = React.useCallback(
    (event: SelectChangeEvent<string>) => {
      const {
        target: { value },
      } = event;
      const selectedOption = options.find(
        (option: Option) => option.value === value
      );
      if (selectedOption) {
        setSelectedValue(selectedOption);
        onChange?.({ id: selectedOption.id, value: selectedOption.value });

        // Remove spaces from the selected value
        const sanitizedValue = selectedOption.value.replace(/\s+/g, "");

        // Get current query parameters
        const currentParams = new URLSearchParams(window.location.search);

        // Check if the current 'filterkey' is different from the selected value
        const currentFilterKey = currentParams.get("filterkey");
        if (currentFilterKey !== sanitizedValue) {
          // Update only the 'filterkey' in the query params
          currentParams.set("filterkey", sanitizedValue);

          // Use navigate to update the query parameters without affecting the rest of the URL
          navigate(`${window.location.pathname}?${currentParams.toString()}`, {
            replace: true,
          });
        }
      }
    },
    [onChange, options, navigate]
  );

  const MenuProps = React.useMemo(
    () => ({
      PaperProps: {
        className: S["iconwordSmallDropdown__menu"],
        style: { width: width },
      },
    }),
    [width]
  );

  return (
    <FormControl size="small" style={{ width }} {...props}>
      <Select
        displayEmpty
        IconComponent={() => null}
        value={selectedValue?.value || ""}
        onChange={handleChange}
        input={
          <OutlinedInput
            className={S["iconwordSmallDropdown__inputbox"]}
            classes={{
              notchedOutline: S["iconwordSmallDropdown__notchedOutline"],
              focused: S["iconwordSmallDropdown__focused"],
            }}
            startAdornment={
              <InputAdornment position="start">
                <MultiselectIcon w={14} />
              </InputAdornment>
            }
          />
        }
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        renderValue={(selected) => (
          <Box
            sx={{
              paddingTop: "2px",
              font: "normal normal 300 14px/20px Source Serif Pro",
            }}
          >
            {`Group: ${selected}`}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {options.map((option: Option) => (
          <MenuItem
            className={S["iconwordSmallDropdown__menu-item"]}
            key={option.id}
            value={option.value}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
              {React.createElement(option.icon, { color: "black", w: 15 })}
              <ListItemText
                primary={option.value}
                primaryTypographyProps={{
                  className: S["iconwordSmallDropdown__list-item-text"],
                }}
              />
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default IconwordSmallDropdown;
