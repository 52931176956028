import { createContext, useCallback, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  BackgroundBlurProcessor,
  BackgroundBlurVideoFrameProcessor,
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  DefaultVideoTransformDevice,
  LogLevel,
  MeetingSessionConfiguration,
  VideoTileState,
} from "amazon-chime-sdk-js";

import {
  Attendee,
  ChimeAttendee,
  ChimeMeeting,
  Meeting,
  MeetingContextRef,
  MeetingContextState,
  MeetingContextTypes,
  RealtimeAttendee,
  RealtimeAttendees,
} from "./MeetingContextTypes";
import {
  responseToJoinMeeting,
  createAttendeeAction,
  createNewMeeting,
  getSingleMeeting,
  requestToJoinMeeting,
  endMeeting,
} from "../../../redux";
import { removeGuestRequestAction, setEndMeetingAction } from "../../../redux/Slice/videoCenter/VideoCenterSlice";

export const initialRef = {
  logger: null,
  deviceController: null,
  configuration: null,
  meetingSession: null,
  meetings: {},
  chimeMeetings: {},
  attendees: {},
  chimeAttendees: {},
  elements: {
    audioOutput: null,
  },
};

export const initialState = {
  realtimeAttendees: {},
};

export const MeetingContext = createContext<MeetingContextTypes | undefined>(
  undefined
);

export const MeetingProvider = ({ children }: { children: any }) => {
  const dispatch = useDispatch();
  const ref: { current: MeetingContextRef } = useRef(initialRef);

  const [state, setState] = useState<MeetingContextState>(initialState);
  // const [isVideoActive, setIsVideoActive] = useState(false);
  const createLogger = useCallback(() => {
    ref.current.logger = new ConsoleLogger("AWS-CHIME :", LogLevel.INFO);
  }, []);

  const createDeviceController = useCallback(() => {
    createLogger();
    ref.current.deviceController = new DefaultDeviceController(
      ref.current.logger
    );
  }, []);

  const createConfiguration = useCallback(
    ({ meetingId, attendeeId }: { meetingId: string; attendeeId: string }) => {
      ref.current.configuration = new MeetingSessionConfiguration(
        ref.current.chimeMeetings[meetingId],
        ref.current.chimeAttendees[attendeeId]
      );
    },
    []
  );

  const createMeeting = useCallback(async () => {
    const payload = {
      meetingType: "INSTANT",
    };
    const [data]: any = await Promise.all([
      dispatch(createNewMeeting(payload)),
    ]);
    return data?.data?.meeting as Meeting;
  }, []);

  const createAttendee = useCallback(
    async ({ meetingId }: { meetingId: string }) => {
      const [data]: any = await Promise.all([
        dispatch(createAttendeeAction(meetingId )),
      ]);

      const attendee = data?.data?.attendee as Attendee;
      const chimeAttendee = data?.data?.chimeAttendee as ChimeAttendee;

      if (attendee?._id) {
        ref.current.attendees[attendee._id] = attendee;
        ref.current.attendees[attendee.chimeAttendeeId] = attendee;
      }

      if (chimeAttendee?.AttendeeId) {
        ref.current.chimeAttendees[chimeAttendee.AttendeeId] = chimeAttendee;
        ref.current.chimeAttendees[chimeAttendee.ExternalUserId] =
          chimeAttendee;
      }

      return attendee;
    },
    []
  );

  const getMeeting = useCallback(
    async ({
      meetingId,
      attendeeId,
    }: {
      meetingId: string;
      attendeeId: string;
    }) => {
      const [data]: any = await Promise.all([
        dispatch(getSingleMeeting(meetingId)),
      ]);
      // createDeviceController();
      // createConfiguration({meetingId, attendeeId: '66d08811c2e1d7f7203d7b2e'});
      // console.log('data222222222222222222222: ', data);

      const meeting = data?.data?.meeting as Meeting;
      const chimeMeeting = data?.data?.chimeMeeting as ChimeMeeting;

      if (meeting?._id) {
        const { _id, externalMeetingId, chimeMeetingId } = meeting;
        ref.current.meetings[_id] = meeting;
        ref.current.meetings[externalMeetingId] = meeting;
        ref.current.meetings[chimeMeetingId] = meeting;
      }

      if (chimeMeeting?.MeetingId) {
        ref.current.chimeMeetings[chimeMeeting.MeetingId] = chimeMeeting;
        ref.current.chimeMeetings[chimeMeeting.ExternalMeetingId] =
          chimeMeeting;
      }

      return meeting;
    },
    []
  );

  const joinMeeting = useCallback(
    ({ meetingId, attendeeId }: { meetingId: string; attendeeId: string }) => {
      createDeviceController();
      createConfiguration({ meetingId, attendeeId });
      ref.current.meetingSession = new DefaultMeetingSession(
        ref.current.configuration,
        ref.current.logger,
        ref.current.deviceController
      );

      const observer = {
        audioVideoDidStart: () => {
          ref.current.meetingSession.audioVideo.startLocalVideoTile();
        },
        audioVideoDidStop: (sessionStatus: any) => {
          console.log(
            "Stopped with a session status code: ",
            sessionStatus.statusCode()
          );
        },
        audioVideoDidStartConnecting: (reconnecting: any) => {
          if (reconnecting) {
            console.log("Attempting to reconnect", reconnecting);
          }
        },

        videoTileDidUpdate: (tileState: VideoTileState) => {
          setState((prevState: any) => {
            if (
              prevState.realtimeAttendees[tileState.boundAttendeeId as string]
            ) {
              return {
                ...prevState,
                realtimeAttendees: {
                  ...prevState.realtimeAttendees,
                  [tileState.boundAttendeeId as string]: {
                    ...prevState.realtimeAttendees[
                      tileState.boundAttendeeId as string
                    ],
                    videoTileState: tileState,
                  },
                },
              };
            } else {
              return prevState;
            }
          });
        },
        videoTileWasRemoved: (tileId: number) => {
          ref.current?.meetingSession?.audioVideo?.unbindVideoElement(tileId);
          setState((prevState: any) => {
            const attendeesArray: RealtimeAttendee[] = Object.values(
              prevState.realtimeAttendees
            );
            const attendee = attendeesArray.find(
              (attendee: RealtimeAttendee) =>
                attendee?.videoTileState?.tileId === tileId
            );
            return attendee
              ? {
                  ...prevState,
                  realtimeAttendees: {
                    ...prevState.realtimeAttendees,
                    [attendee?.chimeAttendeeId]: {
                      ...prevState.realtimeAttendees[attendee.chimeAttendeeId],
                      videoTileState: null,
                    },
                  },
                }
              : prevState;
          });
        },
      };

      ref.current.meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence(
        async (chimeAttendeeId: string, isPresent: boolean, userId: string) => {
          await ref.current.meetingSession.audioVideo.realtimeSubscribeToVolumeIndicator(
            chimeAttendeeId,
            async (
              _attendeeId: string,
              _volume: number | null,
              muted: boolean | null,
              _signalStrength: number | null
            ) => {
              if (typeof muted === "boolean") {
                setState((prevState: any) => ({
                  ...prevState,
                  realtimeAttendees: {
                    ...prevState.realtimeAttendees,
                    [chimeAttendeeId]: {
                      ...(prevState.realtimeAttendees[chimeAttendeeId] || {}),
                      muted,
                    },
                  },
                }));
              }
            }
          );

          setState((prevState) => {
            if (isPresent) {
              return {
                ...prevState,
                realtimeAttendees: {
                  ...prevState.realtimeAttendees,
                  [chimeAttendeeId]: {
                    ...(prevState.realtimeAttendees?.[chimeAttendeeId] || {}),
                    chimeAttendeeId,
                    userId,
                  },
                },
              };
            } else {
              //@ts-ignore
              const { [chimeAttendeeId]: _, ...rest } =
                prevState.realtimeAttendees;
              return {
                ...prevState,
                realtimeAttendees: rest,
              };
            }
          });
        }
      );

      // @ts-ignore
      window.audioVideo = ref.current.meetingSession.audioVideo;

      ref.current.meetingSession.audioVideo.addObserver(observer);
    },
    []
  );

  const leaveMeeting = useCallback(async (): Promise<void> => {
    ref.current.meetingSession?.audioVideo?.stop?.();
    await stopAudioInput();
    await stopVideoInput();
    await dispatch(setEndMeetingAction(null));
    ref.current = initialRef;
    setState(initialState);
  }, []);

  const getAudioOutputDevices = useCallback(async () => {
    return ref.current.meetingSession.audioVideo.listAudioOutputDevices();
  }, []);

  const startAudioOutput = useCallback(async (deviceId?: string) => {
    if (!deviceId) {
      const audioOutputDevices = await getAudioOutputDevices();
      if (audioOutputDevices.length === 0) {
        console.error("No audio output devices available.");
        return;
      }
      deviceId = audioOutputDevices[0]?.deviceId;
    }
    return ref.current.meetingSession.audioVideo.chooseAudioOutput(deviceId);
  }, []);

  const configureAudioOutput = useCallback(async () => {
    await startAudioOutput();

    if (!ref.current.elements.audioOutput) {
      const audioOutputElement = document.createElement("audio");
      audioOutputElement.style.display = "none";
      document.body.appendChild(audioOutputElement);
      ref.current.elements.audioOutput = audioOutputElement;
    }

    ref.current.meetingSession.audioVideo.bindAudioElement(
      ref.current.elements.audioOutput
    );
  }, []);

  const getAudioInputDevices = useCallback(async () => {
    return ref.current.meetingSession.audioVideo.listAudioInputDevices();
  }, []);

  const startAudioInput = useCallback(async (deviceId?: string) => {
    if (!deviceId) {
      const audioInputDevices = await getAudioInputDevices();
      if (audioInputDevices.length === 0) {
        console.error("No audio input devices available.");
        return;
      }
      deviceId = audioInputDevices[0]?.deviceId;
    }
    await ref.current.meetingSession.audioVideo.startAudioInput(deviceId);
  }, []);

  const stopAudioInput = useCallback(async () => {
    await ref.current.meetingSession.audioVideo.stopAudioInput();
  }, []);

  const muteAudioInput = useCallback(
    async () =>
      await ref.current.meetingSession.audioVideo.realtimeMuteLocalAudio(),
    []
  );
  const unmuteAudioInput = useCallback(
    async () =>
      await ref.current.meetingSession.audioVideo.realtimeUnmuteLocalAudio(),
    []
  );

  const getVideoInputDevices = useCallback(async () => {
    return ref.current.meetingSession.audioVideo.listVideoInputDevices();
  }, []);

  const startVideoInput = useCallback(async (deviceId?: string) => {
    await ref.current.meetingSession.audioVideo.chooseVideoInputQuality(
      750,
      350,
      15
    );
    if (!deviceId) {
      const videoInputDevices = await getVideoInputDevices();
      if (videoInputDevices.length === 0) {
        console.error("No video input devices available.");
        return;
      }
      deviceId = videoInputDevices[0]?.deviceId;
    }
    await ref.current.meetingSession.audioVideo.startVideoInput(deviceId);
    await ref.current.meetingSession.audioVideo.startLocalVideoTile();
  }, []);

  const stopVideoInput = useCallback(async () => {
    await ref.current.meetingSession.audioVideo.stopLocalVideoTile();
    await ref.current.meetingSession.audioVideo.stopVideoInput();
  }, []);

  const startSession = useCallback(async () => {
    await ref.current.meetingSession.audioVideo.start();
  }, []);

  const stopSession = useCallback(async () => {
    await ref.current.meetingSession.audioVideo.stop();
  }, []);

  const bindVideoTile = useCallback(
    ({
      tileId,
      videoElement,
    }: {
      tileId: number;
      videoElement: HTMLVideoElement;
    }) => {
      ref.current.meetingSession.audioVideo.bindVideoElement(
        tileId,
        videoElement
      );
    },
    []
  );

  const requestToJoinMeetingHandler = useCallback(
    async ({
      meetingId,
      attendeeId,
      chimeMeetingId,
      meeting_id,
    }: {
      meetingId: string;
      attendeeId: string;
      chimeMeetingId: string;
      meeting_id: string;
    }) => {
      try {
        dispatch(
          requestToJoinMeeting({
            meetingId,
            userId: attendeeId,
            chimeMeetingId,
            meeting_id,
            message: "A guest user wants to join the meeting.",
          })
        );
      } catch (error) {
        console.error("Error requesting to enter meeting:", error);
      }
    },
    []
  );

  const handleAcceptGuestRequest = useCallback(
    async ({
      meetingId,
      attendeeId,
    }: {
      meetingId: string;
      attendeeId: string;
    }) => {
      try {
        dispatch(
          responseToJoinMeeting({
            meetingId: meetingId,
            userId: attendeeId,
            status: "accepted",
          })
        );
        dispatch(removeGuestRequestAction(attendeeId));
      } catch (error) {
        console.error("Error accepting guest:", error);
      }
    },
    []
  );

  const requestMediaPermissions = useCallback(async (): Promise<boolean> => {
    let permissionsGranted = false;

    try {
      await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      permissionsGranted = true;
    } catch (error) {
      console.warn("Camera or microphone permission denied.");
    }

    return permissionsGranted;
  }, []);

  const handleJoinMeeting = useCallback(
    async ({
      meetingId,
      chimeMeetingId,
    }: {
      meetingId: string;
      chimeMeetingId: string;
    }) => {
      const attendee = await createAttendee({ meetingId: meetingId });

      joinMeeting({
        meetingId: chimeMeetingId,
        attendeeId: attendee?.chimeAttendeeId,
      });

      await configureAudioOutput();
      await startVideoInput();
      await startAudioInput();
      await startSession();
    },
    []
  );

  class VideoPreferences {
    static sortVideoTiles(videoTiles: any[]) {
      return videoTiles
        .map((tile) => {
          const { videoTileState = {}, userId } = tile;
          if (videoTileState?.isContent) {
            tile.priority = 1;
          } else if (videoTileState?.active && !tile?.muted) {
            if (
              userId !==
              ref.current.meetingSession.configuration.credentials
                .externalUserId
            ) {
              tile.priority = 2;
            } else {
              tile.priority = 3;
            }
          } else if (!tile?.muted) {
            if (
              userId !==
              ref.current.meetingSession.configuration.credentials
                .externalUserId
            ) {
              tile.priority = 4;
            } else {
              tile.priority = 5;
            }
          } else if (videoTileState?.active && tile?.muted) {
            if (
              userId !==
              ref.current.meetingSession.configuration.credentials
                .externalUserId
            ) {
              tile.priority = 6;
            } else {
              tile.priority = 7;
            }
          } else {
            tile.priority = 8;
          }
          return tile;
        })
        .sort((a, b) => a.priority - b.priority);
    }
  }

  const startScreenShare = useCallback(async () => {
    try {
      await ref.current.meetingSession.audioVideo.startContentShareFromScreenCapture();
    } catch (error) {
      console.error("Failed to start screen share", error);
    }
  }, []);

  const pauseScreenShare = useCallback(async () => {
    try {
      await ref.current.meetingSession.audioVideo.pauseContentShare();
    } catch (error) {
      console.error("Failed to pause screen share", error);
    }
  }, []);

  const unpauseScreenShare = useCallback(async () => {
    try {
      await ref.current.meetingSession.audioVideo.unpauseContentShare();
    } catch (error) {
      console.error("Failed to unpause screen share", error);
    }
  }, []);

  const stopScreenShare = useCallback(async () => {
    try {
      await ref.current.meetingSession.audioVideo.stopContentShare();
    } catch (error) {
      console.error("Failed to stop screen share", error);
    }
  }, []);

  const blurBackground = useCallback(async () => {
    try {
      if (await BackgroundBlurVideoFrameProcessor.isSupported()) {
        const blurProcessor = await BackgroundBlurVideoFrameProcessor.create();

        const videoInputDevices = await getVideoInputDevices();
        if (videoInputDevices.length === 0) {
          console.error("No video input devices available.");
          return;
        }
        const selectedVideoInputDevice = videoInputDevices[0];

        const transformDevice = new DefaultVideoTransformDevice(
          ref.current.logger,
          selectedVideoInputDevice,
          [blurProcessor as BackgroundBlurProcessor]
        );

        await ref.current.meetingSession.audioVideo.startVideoInput(
          transformDevice
        );
      } else {
        console.error("Background blur is not supported");
      }
    } catch (error) {
      console.error("Failed to blur video", error);
    }
  }, []);

  const unBlurBackground = useCallback(async () => {
    try {
      const videoInputDevices = await getVideoInputDevices();
      if (videoInputDevices.length === 0) {
        console.error("No video input devices available.");
        return;
      }
      const selectedVideoInputDevice = videoInputDevices[0];

      await ref.current.meetingSession.audioVideo.chooseVideoInputDevice(selectedVideoInputDevice);
      await ref.current.meetingSession.audioVideo.startLocalVideoTile();
    } catch (error) {
      console.error("Failed to unblur video", error);
    }
  }, []);

  const handleCallEnd = useCallback(async (meetingDetails: any) => {
    const meetingDetail = {
      meeting: meetingDetails.meeting,
    };
    await dispatch(endMeeting(meetingDetail, meetingDetails.meeting._id));
    ref.current = initialRef;
    setState(initialState);
  }, []);

  const value = useMemo(
    () => ({
      ...ref.current,
      ...state,
      getMeeting,
      createMeeting,
      createAttendee,
      joinMeeting,
      leaveMeeting,
      // audio output
      getAudioOutputDevices,
      startAudioOutput,
      configureAudioOutput,
      // audio input
      getAudioInputDevices,
      startAudioInput,
      stopAudioInput,
      muteAudioInput,
      unmuteAudioInput,
      // video input
      getVideoInputDevices,
      startVideoInput,
      stopVideoInput,
      // session
      startSession,
      stopSession,
      bindVideoTile,
      // screen share
      startScreenShare,
      pauseScreenShare,
      unpauseScreenShare,
      stopScreenShare,
      // video blur
      blurBackground,
      unBlurBackground,
      //
      handleAcceptGuestRequest,
      requestToJoinMeetingHandler,
      handleJoinMeeting,
      requestMediaPermissions,

      // end meeting
      handleCallEnd,
      VideoPreferences,
    }),
    [state]
  );

  return (
    <MeetingContext.Provider value={value}>{children}</MeetingContext.Provider>
  );
};
