import { Stack, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../Utils";
import { useTranslation } from "react-i18next";

// Inline component for displaying each metric
const InlineComponent: React.FC<{
  label: string;
  value: string;
  color: string;
}> = ({ color, label, value }) => {
  return (
    <Stack
      sx={{
        height: "60px",
        borderRadius: "5px",
        bgcolor: adjustHexColor(color, 25),
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        paddingTop: "12px",
        flex: 1,
        flexShrink: 0,
      }}
    >
      <Typography sx={{ font: "normal normal 900 20px/25px Source Serif Pro" }}>
        {value}
      </Typography>
      <Typography sx={{ font: "normal normal 300 14px/18px Source Serif Pro" }}>
        {label}
      </Typography>
    </Stack>
  );
};

// Props for LinkAnalyticsContainer
interface LinkAnalyticsContainerProps {
  analytics: any; // Accept analytics object directly
}

// Function to analyze visits and extract metrics
const analyzeVisits = (visits: any) => {
  const totalViews = visits?.length; // Total number of visits
  const totalBookings = visits?.filter(
    (visit: any) => visit?.bookedEvent
  )?.length; // Count of booked events
  const anonymousVisits = visits?.filter(
    (visit: any) => visit?.status === "anonymous"
  )?.length; // Count of anonymous visits
  const cancelRate =
    totalViews > 0
      ? ((anonymousVisits / totalViews) * 100)?.toFixed(2) + "%"
      : "0%"; // Calculate cancel rate as a percentage

  const totalDuration = visits?.reduce(
    (sum: any, visit: any) => sum + visit?.duration,
    0
  ); // Sum of all durations
  const averageTime =
    totalViews > 0 ? (totalDuration / totalViews)?.toFixed(2) : 0; // Calculate average duration

  return {
    totalViews,
    totalBookings,
    cancelRate,
    averageTime: `${averageTime} minutes`, // Format average time as needed
  };
};

// Main LinkAnalyticsContainer component
const LinkAnalyticsContainer: React.FC<LinkAnalyticsContainerProps> = ({
  analytics,
}) => {
  const { t } = useTranslation();
  const visits = analytics?.visits || []; // Safely access visits
  const { totalViews, totalBookings, cancelRate, averageTime } =
    analyzeVisits(visits); // Calculate metrics

  return (
    <Stack sx={{ gap: "30px", width: "100%" }}>
      <Typography sx={{ font: "normal normal 900 18px/23px Source Serif Pro" }}>
        {t("CALENDAR.LINK_ANALYTICS")}
      </Typography>

      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "20px",
        }}
      >
        <InlineComponent
          color="#A1FF9F"
          label={t("CALENDAR.TOTAL_VIEW")}
          value={totalViews?.toString()} // Convert to string for display
        />
        <InlineComponent
          color="#FFDCB2"
          label={t("CALENDAR.TOTAL_BOOKING")}
          value={totalBookings?.toString()} // Convert to string for display
        />
        <InlineComponent
          color="#CFA0FF"
          label={t("CALENDAR.AVERAGE_TIME")}
          value={averageTime} // Directly use calculated average time
        />
        <InlineComponent
          color="#EF5350"
          label={t("CALENDAR.CANCEL_RATE")}
          value={cancelRate} // Directly use calculated cancel rate
        />
      </Stack>
    </Stack>
  );
};

export default LinkAnalyticsContainer;
