import { Box, InputBase, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import g from "./BookEventerSubmitSlot.module.css";
import {
  ButtonSmall,
  ButtonType,
  SmallButtonColor,
} from "../../../../../common";
import { CreateBookingEventPayload } from "../../../../../../redux";

interface BookEventSubmitSlotProps {
  selectedTimeSlot?: {
    startTime: string; // Expecting an ISO date string
    endTime: string; // Expecting an ISO date string
  };
  handleBookMyEvent?: (eventData: CreateBookingEventPayload) => void;
  dateSelected?: any; // Should ideally be of a specific type
}

// Helper function to format the date (e.g., "October 7th, 2024")
const formatDate = (isoDateString: string) => {
  const date = new Date(isoDateString);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return date.toLocaleDateString("en-US", options).replace(",", "th"); // Handle ordinal suffix
};

// Function to format the date into a readable format (e.g., "October 14th, 2024")
const formatDateAPI = (isoDateString: string): string => {
  const date = new Date(isoDateString);

  // Check if the date is valid
  if (isNaN(date.getTime())) return "Invalid Date"; // Use getTime() to check validity

  const year = date.getUTCFullYear(); // Get UTC year
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Get UTC month, padded to 2 digits
  const day = String(date.getUTCDate()).padStart(2, '0'); // Get UTC day, padded to 2 digits

  return `${year}-${month}-${day}`; // Format to 'YYYY-MM-DD'
};


const BookEventerSubmitSlot: React.FC<BookEventSubmitSlotProps> = ({
  selectedTimeSlot,
  handleBookMyEvent,
  dateSelected,
}) => {
  const [bookingEventData, setBookingEventData] =
    useState<CreateBookingEventPayload>({
      date: formatDateAPI(dateSelected) || "", // Date in 'YYYY-MM-DD' format
      timeSlot: "", // Initialize without time to update it later
      guests: [{ email: "" }], // Initialize with an empty guest email
      description: "", // Optional description
      firstName: "", // First name of the person booking the event
      lastName: "", // Last name of the person booking the event
      phone: "", // Optional phone number
      company: "", // Optional company name
    });
  // Update booking data when selectedTimeSlot changes
  useEffect(() => {
    if (selectedTimeSlot) {
      const { startTime } = selectedTimeSlot; // Use startTime
      setBookingEventData((prevData) => ({
        ...prevData,
        date: formatDateAPI(dateSelected), // Ensure the date is formatted correctly
        timeSlot: startTime, // Format start time
      }));
    }
  }, [selectedTimeSlot, dateSelected]);

  // Function to update booking event data
  const updateField = (
    fieldName: keyof CreateBookingEventPayload,
    value: any
  ) => {
    setBookingEventData((prevState) => {
      // Handle guests separately to preserve the array structure
      if (fieldName === "guests") {
        return {
          ...prevState,
          guests: [{ email: value }], // Replace with new guest email
        };
      }
      return {
        ...prevState,
        [fieldName]: value,
      };
    });
  };


  return (
    <Box className={g.BookEventer__submit__container}>
      <InputBase
        className={g.input__container__hosts}
        type="text"
        value={"You’ve selected…"}
        readOnly
      />

      <Stack
        mt={"10px"}
        width={"100%"}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        gap={2}
      >
        <Typography
          className={g.selected___submitDate}
          children={formatDate(dateSelected) || "January 10th, 2023"} // Display formatted date
        />
        <Typography
          sx={{ marginRight: "50px" }}
          className={g.selected___submitDate}
          children={"at"}
        />
      </Stack>

      <Stack mt={"15px"}>
        <InputBase
          className={g.input__container__hosts}
          type="text"
          value={`${formatDate(dateSelected)}, ${bookingEventData.timeSlot}`} // Combine date and time
          readOnly
        />
      </Stack>

      <Stack width={"100%"} direction={"column"} gap={1}>
        <Stack
          width={"100%"}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={1}
          mt={"12px"}
        >
          <InputBase
            sx={{ minWidth: "40%" }}
            className={g.input__container__hostss}
            type="text"
            value={bookingEventData.firstName}
            placeholder="First Name"
            onChange={(e) => updateField("firstName", e.target.value)}
          />
          <InputBase
            sx={{ minWidth: "40%" }}
            className={g.input__container__hostss}
            type="text"
            value={bookingEventData.lastName}
            placeholder="Last Name"
            onChange={(e) => updateField("lastName", e.target.value)}
          />
        </Stack>

        <Stack
          width={"100%"}
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={2}
          mt={"12px"}
        >
          <InputBase
            sx={{ minWidth: "40%" }}
            className={g.input__container__hosts}
            type="email"
            value={bookingEventData.guests[0]?.email || ""}
            placeholder="Email"
            onChange={(e) => updateField("guests", e.target.value)}
          />
          <InputBase
            sx={{ minWidth: "40%" }}
            className={g.input__container__hosts}
            type="number"
            value={bookingEventData.phone}
            placeholder="(- - -) - 000 - 0000"
            onChange={(e) => updateField("phone", e.target.value)}
          />
        </Stack>

        <Stack mt={"12px"}>
          <textarea
            className={g.input__container__hosts}
            name="details"
            value={bookingEventData.description}
            onChange={(e) => updateField("description", e.target.value)}
            placeholder="Please share any other details you’d like me to know before the meeting."
            rows={3}
            style={{ resize: "none" }}
          ></textarea>
        </Stack>

        <Stack
          mt={2}
          width={"100%"}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <ButtonSmall
            colorVarient={SmallButtonColor.MildGreen}
            onClick={() =>
              handleBookMyEvent && handleBookMyEvent(bookingEventData)
            }
            label={"Schedule Event Now"}
            types={ButtonType.Button}
            sx={{ width: "150px", height: "40px" }}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default BookEventerSubmitSlot;
