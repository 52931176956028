import { Box, Stack, Typography, InputBase, Avatar } from "@mui/material";
import React from "react";
import g from "./BookEventer.module.css";

type HostData = {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  avatar: string;
};

type BookEventerProps = {
  hostData?: HostData;
  linkData: any;
};

const BookEventer: React.FC<BookEventerProps> = ({ hostData, linkData }) => {
  // Helper function to get initials
  const getInitials = (firstName: string, lastName: string): string => {
    return `${firstName?.charAt?.(0)}${lastName?.charAt?.(0)}`?.toUpperCase();
  };

  // Determine the avatar source
  const getAvatarSrc = (avatar: string | undefined): string => {
    if (avatar?.startsWith?.("http")) {
      return avatar; // Return the image URL if it's a valid URL
    } else if (avatar?.startsWith("#")) {
      return ""; // For #color|<user-initial>, we won't set a src, we will use the color prop
    }
    return ""; // Fallback for any other format
  };

  const avatarColor = hostData?.avatar?.startsWith("#")
    ? hostData?.avatar?.split("|")?.[0]?.slice(1) // Get the color after "#"
    : undefined;

  return (
    <Box className={g.host___container}>
      <Stack className={g.host_image_container}>
        <Avatar
          variant="rounded"
          src={getAvatarSrc(hostData?.avatar)}
          sx={{
            bgcolor: avatarColor ? `#${avatarColor}` : undefined,
            // width: 56,
            // height: 56,
          }}
          className={g.host_imgSrc}
        >
          {avatarColor
            ? null
            : getInitials(
                hostData?.firstName || "U", // Provide a default value
                hostData?.lastName || "U" // Provide a default value
              )}
        </Avatar>
        <Typography className={g.host_details_Inside_image_container}>
          {`${hostData?.firstName || "Unknown"} ${
            hostData?.lastName || "User"
          }`}
        </Typography>
      </Stack>

      <Stack direction={"column"} gap={3} mt={3}>
        <InputBase
          className={g.input__container__host}
          type="text"
          value={`${hostData?.firstName || "Unknown"} ${
            hostData?.lastName || "User"
          }`}
          readOnly
        />
        <InputBase
          className={g.input__container__host}
          type="text"
          value={linkData?.name}
          readOnly
        />
        <InputBase
          className={g.input__container__host}
          type="text"
          value={`${linkData?.videoCall?.platform} at ${linkData?.location}`}
          readOnly
        />
      </Stack>
    </Box>
  );
};

export default BookEventer;
