import { IconButton, IconButtonProps, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { adjustHexColor } from "../../../Utils";

interface VideoActionRoundedButtonsProps extends IconButtonProps {
  /** Initial status of the button, determines the default active/inactive state */
  initialStatus?: boolean;

  /** Text to display in the tooltip when the button is active */
  activeHoverText?: string;

  /** Text to display in the tooltip when the button is inactive (defaults to `activeHoverText` if not provided) */
  inActiveHoverText?: string;

  /** Background color when the button is active */
  activeColor: string;

  /** Background color when the button is inactive (defaults to `activeColor` if not provided) */
  inactiveColor?: string;

  /** Opacity level for the background color, defaults to 50 */
  clrOpacity?: number;

  /** Icon component to render inside the button */
  icon: React.ElementType;

  /** Color of the icon, defaults to white (`#ffffff`) */
  iconColor?: string;

  /** Size of the icon, defaults to 25px */
  iconSize?: number;

  /** Direction of icon */
  iconDirection?: "up" | "down" | "left" | "right";

  /** Callback function to be triggered when the button is clicked, receiving the new status as a parameter */
  onClickChange: (status: boolean) => void;
}

const VideoActionRoundedButtons: React.FC<VideoActionRoundedButtonsProps> = ({
  icon,
  iconColor = "#ffffff",
  iconSize = 25,
  activeHoverText,
  inActiveHoverText = activeHoverText,
  initialStatus,
  onClickChange,
  activeColor,
  iconDirection,
  inactiveColor = activeColor,
  clrOpacity = 50,
  ...props
}) => {
  const [status, setStatus] = useState<boolean>(initialStatus || false);
  const hoverText = status ? activeHoverText : inActiveHoverText;

  const changeHandler = (): void => {
    const value = !status;
    setStatus(value);
    onClickChange(value);
  };

  return (
    <Tooltip title={hoverText} placement="top" arrow disableInteractive>
      <IconButton
        onClick={changeHandler}
        sx={{
          p: 0,
          height: "50px",
          width: "50px",
          boxSizing: "border-box",
          border: "1px solid white",
          boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.16)",
          bgcolor: adjustHexColor(
            status ? activeColor : inactiveColor,
            clrOpacity
          ),
          ":hover": {
            bgcolor: adjustHexColor(
              status ? activeColor : inactiveColor,
              clrOpacity + 10
            ),
          },
        }}
        {...props}
      >
        {React.createElement(icon, {
          w: iconSize,
          color: iconColor,
          direction: iconDirection,
        })}
      </IconButton>
    </Tooltip>
  );
};

export default VideoActionRoundedButtons;
