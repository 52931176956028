import { createSlice } from "@reduxjs/toolkit";
import { FileUploadBlobState } from "./FileUploadManagerType";
import { updloadFile__Failure, updloadFile__Request, uploadFile__Success, } from "./FileUploadManagerSliceAction";

const initialState: FileUploadBlobState = {
    error: null,
    dataUrl: null,
};


const fileUploadSlice = createSlice({
    name: "fileUpload", // Name of the slice
    initialState, // Initial state of the slice
    reducers: {
        /* Create fileUpload */
        updloadFile__Request,
        updloadFile__Failure,
        uploadFile__Success,
        /* Get all fileUploads */

    },
});


export const {


    /* Create workspace action */
    updloadFile__Request: updloadFile__RequestAction,
    updloadFile__Failure: updloadFile__FailureAction,
    uploadFile__Success: updloadFile__SuccessAction,



    /* and so on... */
} = fileUploadSlice.actions;

export default fileUploadSlice.reducer;
