import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import g from "./invitations.module.css";

type Props = { eventData?: { startTime: string; endTime: string } };

const EventExpiry = ({ eventData }: Props) => {
  // Function to calculate how many days are left until the event
  const calculateDaysLeft = () => {
    if (!eventData?.startTime) return 0;

    // Parse the start and end times from the event data
    const eventStartTime = new Date(eventData.startTime);
    const eventEndTime = eventData.endTime ? new Date(eventData.endTime) : eventStartTime;

    // Get the current date and time
    const currentDate = new Date();

    // Use the end time for expiration calculation if it exists
    const targetDate = eventEndTime > currentDate ? eventEndTime : eventStartTime;

    // Calculate the time difference in milliseconds
    const timeDifference = targetDate.getTime() - currentDate.getTime();

    // Convert time difference from milliseconds to days
    const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return daysLeft > 0 ? daysLeft : 0; // Ensure that we don't return negative days
  };

  const daysLeft = calculateDaysLeft();

  return (
    <Box>
      <Stack direction="column" gap={2}>
        {/* Show expiration message only if the event is in the future */}
        {daysLeft > 0 && (
          <Typography className={g.Invitation___Event__end__time}>
            {`Expires in ${daysLeft} day${daysLeft > 1 ? "s" : ""}.`}
          </Typography>
        )}
        {/* Optionally handle past events if required */}
        {daysLeft === 0 && (
          <Typography className={g.Invitation___Event__end__time}>
            {`This event has expired.`}
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

export default EventExpiry;
