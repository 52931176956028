import { Stack, SxProps, Typography } from "@mui/material";
import React from "react";
import { UserDetailsArea } from "../../../UserDetailsArea";
import { EditableTextArea } from "../../../../../../common";
import { AllCredentialsContainer } from "../../../AllCredentialsContainer";
import { CompanyHierarchyContainer } from "../../../CompanyHierarchyContainer";

interface CenterContainerProps {
  sx?: SxProps;
}

const CenterContainer: React.FC<CenterContainerProps> = ({ sx }) => {
  return (
    <Stack sx={{ flex: 1, gap: "20px", justifyContent: "start", ...sx }}>
      <UserDetailsArea hideSocialButtons />

      <Stack gap={"20px"}>
        <Typography
          sx={{ font: "normal normal 400 15px/19px Source Serif Pro" }}
        >
          Intro
        </Typography>

        <EditableTextArea
          style={{ padding: "5px", border: "1px solid #E9EDF2" }}
          minRows={5}
          maxRows={5}
        />
      </Stack>

      <CompanyHierarchyContainer />

      <AllCredentialsContainer />
    </Stack>
  );
};

export default CenterContainer;
