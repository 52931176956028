import React, { useState } from "react";
import { InputBase } from "@mui/material";
import { styled } from "@mui/material/styles";
import { SearchIcon } from "../../../images";

interface MediumSearchbarProps {
  ph: string;
  bgOpacity?: boolean;
}

const MediumSearchbar: React.FC<MediumSearchbarProps> = ({
  ph = "Search all Projects",
  bgOpacity = false,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      console.log(`Typed word: ${searchTerm}`);
      setSearchTerm("");
    }
  };

  return (
    <Search bgOpacity={bgOpacity}>
      <SearchIconWrapper>
        <SearchIcon color="black" w={20} />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder={ph}
        inputProps={{ "aria-label": "search" }}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyDown={handleKeyDown}
      />
    </Search>
  );
};

export default MediumSearchbar;

const Search = styled("div")<{ bgOpacity?: boolean }>(({ bgOpacity }) => ({
  position: "relative",
  borderRadius: "5px",
  backgroundColor: bgOpacity ? "rgba(255, 255, 255, 0.5)" : "white", // Adjust opacity here (0.5 is 50% opacity)
  "&:hover": {
    backgroundColor: "white",
  },
  marginLeft: 0,
  width: "328px",
  height: "40px",
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  border: "1px solid #E9EDF2",
}));

const SearchIconWrapper = styled("div")({
  marginRight: "10px",
  marginLeft: "15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const StyledInputBase = styled(InputBase)({
  color: "inherit",
  flex: 1,
  "& .MuiInputBase-input": {
    padding: "0 10px 0 0px",
    font: "normal normal normal 11px/15px Source Serif Pro",
    "&::placeholder": {
      color: "inherit",
      font: "normal normal normal 11px/15px Source Serif Pro",
      opacity: 1,
    },
  },
});
