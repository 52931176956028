import { Box, Button, Stack } from "@mui/material";
import { Document, Page } from "react-pdf";

const PaginationButtons = ({
  numPages,
  selectedPage,
  handlePageChange,
  path,
}) => {
  const onDocumentLoadSuccess = ({ numPages }) => {
    // You can set the numPages here if needed
  };

  const onDocumentLoadError = (error) => {
    console.error(error);
  };

  return (
    <Box
      sx={{
        position: "fixed",
        left: "61px",
        background: "white",
        top: "52px",
        padding: "20px",
        height: "93.5%",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          width: "0px",
        },
        scrollbarWidth: "none",
        "-ms-overflow-style": "none",
      }}
    >
      <Stack
        sx={{
          overflowY: "scroll",
          width: "100%",
          direction: "column",
          gap: 1,
          "&::-webkit-scrollbar": {
            width: "0px",
          },
          scrollbarWidth: "none",
          "-ms-overflow-style": "none",
        }}
      >
        {/* Document to load and display thumbnails */}
        <Document
          file={path || require("./abc.pdf")}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadError}
          style={{ display: "none" }} // Hide main document view
        >
          {Array?.from(new Array(numPages), (el, index) => (
            <Button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              style={{
                margin: "0 5px",
                width: "125px",
                height: "163px",
                position: "relative",
                borderRadius: "5px",
                backgroundColor: "#E9EDF2",
                color: "#000000",
                border:
                  selectedPage === index + 1 ? "2px solid #A53A01" : "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {path && (
                <Page
                  pageNumber={index + 1}
                  scale={0.2} // Adjust scale for thumbnail size
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  style={{
                    width: "100%", // Ensure thumbnail fits within button
                    height: "auto",
                    marginBottom: "5px", // Space between thumbnail and page number
                  }}
                />
              )}
              <span
                style={{
                  position: "absolute",
                  top: "-1px",
                  left: "-1px",
                  width: "35px",
                  height: "41px",
                  border:
                    selectedPage === index + 1 ? "1px solid #A53A01" : "none",
                  borderRadius: "5px 0px 5px 0px",
                  font: "normal normal 900 18px/22px Source Serif Pro",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {index + 1}
              </span>
            </Button>
          ))}
        </Document>
      </Stack>
    </Box>
  );
};

export default PaginationButtons;
