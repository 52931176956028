import DOMPurify from "dompurify";

export const getHTMLContent = (html: string) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  // return txt.value;
  return DOMPurify.sanitize(txt.value)
}

export const getPlainTextFromHTML = (htmlContent: string): string => {
  // Sanitize the HTML content
  const sanitizedHTML = DOMPurify.sanitize(htmlContent);

  // Create a temporary div element to hold the sanitized HTML
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = sanitizedHTML;

  // Extract and return the plain text content
  return tempDiv.innerText || tempDiv.textContent || '';
};

export const getPlainTextFromCopy = (html: string): string => {
  // Create a temporary element to convert HTML to text
  const doc = new DOMParser().parseFromString(html, "text/html");

  // Replace <br> tags with newline characters (\n)
  const textWithLineBreaks = doc.body.innerHTML.replace(/<br\s*\/?>/gi, "\n");

  return textWithLineBreaks;
};

export const MEETING_ENUMS = {
  BEARISH: "BEARISH",
  SLACK:"SLACK",
  TEAMS:"TEAMS",
  GOOGLE_CHAT:"GOOGLE_CHAT"
}
