import { PayloadAction } from "@reduxjs/toolkit";
import { AgendaItem, VideoCenterState } from "../VideoCenterTypes";

export const createMeetingSuccess = (
  state: VideoCenterState,
  action: PayloadAction<string>
) => {
  state.errors = null;
};
export const getUserMeetingsSuccess = (
  state: VideoCenterState,
  action: PayloadAction<any>
) => {
  state.errors = null;
  const meetingsArray: any[] = action.payload?.data || [];
  state.upcomingMeetings = meetingsArray.reduce<Record<string, any>>(
    (acc, meeting) => {
      acc[meeting._id] = meeting;
      return acc;
    },
    {}
  );
  state.loading = false;
};
export const getPastMeetingsSuccess = (
  state: VideoCenterState,
  action: PayloadAction<any>
) => {
  state.errors = null;
  const meetingsArray: any[] = Array.isArray(action.payload?.data) ? action.payload.data : [];

  state.pastMeetings = meetingsArray.reduce<Record<string, any>>(
    (acc, meeting) => {
      acc[meeting._id] = meeting;
      return acc;
    },
    {}
  );
  state.loading = false;
};
export const getSingleMeetingSuccess = (
  state: VideoCenterState,
  action: PayloadAction<any>
) => {
  state.errors = null;
  state.onGoingMeeting.realtimeAttendees = action.payload?.attendeesWithDetails || [];
  state.onGoingMeeting.meetingsDetails = action.payload.data.eventsDetails
};
export const getOnGoingMeetingAgendaSuccess = (
  state: VideoCenterState,
  action: PayloadAction<any>
) => {
  const agendaArray: any = action.payload?.data || [];

  state.onGoingMeeting.meetingAgendas = agendaArray.reduce(
    (acc: Record<string, AgendaItem>, agenda: AgendaItem) => {
      acc[agenda._id] = agenda;
      return acc;
    },
    {} as Record<string, AgendaItem>
  );
};

export const getSelectedMeetingAgendaSuccess = (
  state: VideoCenterState,
  action: PayloadAction<any>
) => {
  const agendaArray: any = action.payload?.data || [];
  state.selectedMeeting.meetingAgendas = agendaArray.reduce(
    (acc: Record<string, AgendaItem>, agenda: AgendaItem) => {
      acc[agenda._id] = agenda;
      return acc;
    },
    {} as Record<string, AgendaItem>
  );
  state.loading = false;
};
export const updateAgendaStatusSuccess = (
  state: VideoCenterState,
  action: PayloadAction<any>
) => {
  state.errors = null;
};
export const deleteAgendaSuccess = (
  state: VideoCenterState,
  action: PayloadAction<any>
) => {
  state.errors = null;
  const agenda = action.payload;
  delete state.selectedMeeting.meetingAgendas[agenda.data._id];
};
export const getAllMeetingNoteSuccess = (
  state: VideoCenterState,
  action: PayloadAction<any>
) => {
  state.errors = null;

  // Assuming action.payload is an array of meeting notes
  const meetingNotesArray = action.payload?.data;

  // Convert the array into an object of objects with _id as the key
  const meetingNotesObject = meetingNotesArray.reduce((acc: any, note: any) => {
    acc[note._id] = note;
    return acc;
  }, {});

  // Update the state with the new object
  state.selectedMeeting.meetingNotes = meetingNotesObject;
  state.loading = false;
};
export const createMeetingNoteSuccess = (
  state: VideoCenterState,
  action: PayloadAction<any>
) => {
  state.errors = null;
  const meetingNote = action.payload.data;
  state.selectedMeeting.meetingNotes[meetingNote._id] = meetingNote;
};
export const updateMeetingNoteSuccess = (
  state: VideoCenterState,
  action: PayloadAction<any>
) => {
  state.errors = null;
  // const meetingNote = action.payload.data;
  // state.selectedMeeting.meetingNotes[meetingNote._id] = meetingNote;
};

export const deleteMeetingNotesSuccess = (
  state: VideoCenterState,
  action: PayloadAction<any>
) => {
  state.errors = null;
  const note = action.payload.data;
  delete state.selectedMeeting.meetingNotes[note.data._id];
};
export const createMeetingAgendaSuccess = (
  state: VideoCenterState,
  action: PayloadAction<any>
) => {
  state.errors = null;
  if (state.selectedMeeting) {
    action.payload?.data?.forEach((item: any) => {
      state.selectedMeeting.meetingAgendas[item._id] = item;
    });
  }
};
export const createOnGoingMeetingAgendaSuccess = (
  state: VideoCenterState,
  action: PayloadAction<any>
) => {
  state.errors = null;
  if (state.onGoingMeeting) {
    action.payload?.data?.forEach((item: any) => {
      state.onGoingMeeting.meetingAgendas[item._id] = item;
    });
  }
};


export const getCallActivityDetailsSuccess = (
  state: VideoCenterState,
  action: PayloadAction<any>
) => {
  state.errors = null;
  if (state.selectedPastMeeting) {
    state.selectedPastMeeting.activity = action.payload?.data[0]?.events
  }
};

export const getPastMeetingDetailsSuccess = (
  state: VideoCenterState,
  action: PayloadAction<any>
) => {
  state.errors = null;
  if (state.selectedPastMeeting) {
    state.selectedPastMeeting.activity = action.payload?.data[0]?.events
    state.selectedPastMeeting.agendas = action.payload?.data[0]?.agenda
    state.selectedPastMeeting.notes = action.payload?.data[0]?.notes
  }
};
export const addWorkspacesAndProjectsToMeetingSuccess = (
  state: VideoCenterState,
  action: PayloadAction<any>
) => {
  state.errors = null;
  if (state.selectedPastMeeting) {
    state.selectedPastMeeting.activity = action.payload?.data[0]?.events
    state.selectedPastMeeting.agendas = action.payload?.data[0]?.agenda
    state.selectedPastMeeting.notes = action.payload?.data[0]?.notes
  }
};
export const getUserProjectsSuccess = (
  state: VideoCenterState,
  action: PayloadAction<any>
) => {
  state.errors = null;
  state.projects = action.payload?.data;
};

export const updateMeetingsDetails__Success = (
  state: VideoCenterState,
  action: PayloadAction<any>
) => {
  state.upcomingMeetings[action?.payload?.data?._id] = action.payload?.data
  state.selectedMeeting.meeting = action.payload?.data
};
