import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import { ChecklistIcon, PlusIcon } from "../../../../../../../images";
import {
  GreenArrowButton,
  SmallChecklist,
  useCustomSnackbar,
} from "../../../../../../common";

interface ChecklistItem {
  itemName: string;
  assignee: string;
  isChecked: boolean;
}

interface Checklist {
  name: string;
  items: ChecklistItem[];
}

interface CheckListProps {
  helperupdateStackData?: any;
  objectTaskData?: any;
}

const CheckList: React.FC<CheckListProps> = ({
  helperupdateStackData,
  objectTaskData,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [checklists, setChecklists] = React.useState<Checklist[]>([]);
  const toggleContainer = () => {
    setIsExpanded(!isExpanded);
  };
  const enqueueSnackbar_v1 = useCustomSnackbar();
  // useEffect to monitor objectTaskData
  React.useEffect(() => {
    if (objectTaskData?.dataSet) {
      setChecklists(objectTaskData?.dataSet?.checklists);
    }
  }, [objectTaskData]);

  const addChecklist = () => {
    const newChecklistName = `Checklist ${checklists?.length + 1}`;

    // Check if a checklist with the same name already exists
    const isDuplicate = checklists?.some(
      (checklist) => checklist?.name === newChecklistName
    );

    if (isDuplicate) {
      enqueueSnackbar_v1(
        "warning",
        `A checklist with the name "${newChecklistName}" already exists.`,
        {
          position: { vertical: "bottom", horizontal: "left" },
          autoHideDuration: 5000, // Optional custom auto-hide duration in milliseconds
        }
      );
      return; // Do not add the duplicate checklist
    }

    const newChecklist: Checklist = {
      name: newChecklistName,
      items: [],
    };
    const updatedChecklists = [...checklists, newChecklist];
    setChecklists(updatedChecklists);

    // Call helperupdateStackData to update the stack with the new checklist
    helperupdateStackData("checklist", updatedChecklists);
  };

  const updateChecklist = (index: number, newItem: ChecklistItem) => {
    const newChecklists = [...checklists];

    // Ensure the existing checklist has an items array
    if (!Array?.isArray(newChecklists?.[index]?.items)) {
      newChecklists[index].items = [];
    }

    // Check if an item with the same name already exists in the checklist
    const isDuplicateItem = newChecklists?.[index]?.items?.some(
      (item) => item?.itemName === newItem?.itemName
    );

    if (isDuplicateItem) {
      enqueueSnackbar_v1(
        "warning",
        `An item with the name "${newItem?.itemName}" already exists in the checklist.`,
        {
          position: { vertical: "bottom", horizontal: "left" },
          autoHideDuration: 5000, // Optional custom auto-hide duration in milliseconds
        }
      );
      return; // Do not add the duplicate item
    }

    // Push the new item into the items array
    newChecklists[index]?.items?.push(newItem);

    // Update the state with the new checklists
    setChecklists(newChecklists);

    // Call helperupdateStackData to update the stack with the updated checklist
    helperupdateStackData("checklist", newChecklists);
  };

  return (
    <Stack sx={{ bgcolor: "white" }}>
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack sx={{ flexDirection: "row", gap: "10px", alignItems: "center" }}>
          <ChecklistIcon w={24} />
          <Typography
            sx={{ font: "normal normal normal 18px/25px Source Serif Pro" }}
          >
            Checklist
          </Typography>
          <Typography
            sx={{ font: "normal normal bold 14px/20px Source Serif Pro" }}
          >
            {checklists?.length}
          </Typography>
          <GreenArrowButton
            sx={{
              transform: isExpanded ? "rotate(90deg)" : "rotate(270deg)",
              transition: "transform 0.2s ease-in-out",
            }}
            onClick={toggleContainer}
          />
        </Stack>
        <Tooltip title="Add a new Checklist" placement="top" arrow>
          <IconButton sx={{ padding: 1 }} onClick={addChecklist}>
            <PlusIcon w={14} />
          </IconButton>
        </Tooltip>
      </Stack>
      <Stack
        sx={{
          maxHeight: isExpanded ? "270px" : "0px",
          overflow: "hidden",
          transition: "max-height 0.3s ease",
          overflowY: "scroll",
          scrollbarWidth: "none",
          marginTop: "15px",
          gap: "20px",
        }}
      >
        {checklists?.map((checklist, index) => (
          <SmallChecklist
            key={index}
            checklist={checklist}
            onSave={(newItem) => updateChecklist(index, newItem)}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default CheckList;
