import React from 'react';

interface TabLoaderProps {
  
}

const TabLoader: React.FC<TabLoaderProps> = ({  }) => {
  return <div>Loading...</div>;
};

export default TabLoader;