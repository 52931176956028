import { Stack } from "@mui/material";
import React, { lazy, Suspense, useEffect, useState } from "react";
import {
  a11yProps,
  StyledTab,
  TabPanel,
  TabsWithState,
} from "../../../../common/CardTabsWithoutIcon";
import { InfoView } from "./view";
import { useParams } from "react-router-dom";
import { RootState } from "../../../../../redux/store";
import { useSelector } from "react-redux";

interface ActivityEmailSidebarProps {}
type Params = Record<string, string | undefined>;
const LazyDetailsTab = lazy(() => import("./tabs/DetailsTab/DetailsTab"));
const LazyActivityTab = lazy(() => import("./tabs/ActivityTab/ActivityTab"));
const LazyConnectedTab = lazy(() => import("./tabs/ConnectedTab/ConnectedTab"));

const ActivityEmailSidebar: React.FC<ActivityEmailSidebarProps> = ({}) => {
  const { emailId } = useParams<Params>();
  const emailsData = useSelector(
    (state: RootState) => state && state?.emails?.emails?.data?.emails
  );
  const [emailData, setEmailData] = useState<any | null>(null);
  useEffect(() => {
    if (emailsData && emailId) {
      // Find the email with matching _id
      const foundEmail = emailsData?.find(
        (email: any) => email?.id === emailId
      );
      setEmailData(foundEmail || null);
    }
  }, [emailsData, emailId]);
  const [tabValue, setTabValue] = useState(0);

  const tabs: {
    label: string;
    content: React.ReactNode;
  }[] = [
    {
      label: "Details",
      content: (
        <LazyDetailsTab
          eCardQuery={emailData?.sender?.emailAddress?.address}
          isSideBarForEmail={true}
        />
      ),
    },
    {
      label: "Activity",
      content: <LazyActivityTab />,
    },
    {
      label: "Connected",
      content: <LazyConnectedTab />,
    },
  ];
  return (
    <Stack
      sx={{
        bgcolor: "#ffffff87",
        width: "293px",
        height: "100%",
        gap: "10px",
        padding: "0px 0px 0px 13px",
        borderLeft: "1px solid #E9EDF2",
        paddingTop: "15px",
      }}
    >
      <InfoView
        userName={emailData?.sender?.emailAddress?.name}
        userEmail={emailData?.sender?.emailAddress?.address}
        avatarColor="orange"
      />
      <TabsWithState
        ariaLabel="Email details tabs"
        value={tabValue}
        onChange={(event, newValue) => setTabValue(newValue)}
        tabIndicatorColor="#008D4C"
      >
        {tabs.map((tab, index) => (
          <StyledTab
            style={{
              font: "normal normal 600 13px/16px Source Serif Pro",
            }}
            key={index}
            disableRipple
            label={tab.label}
            {...a11yProps(index)}
          />
        ))}
      </TabsWithState>

      {tabs.map((tab, index) => (
        <TabPanel
          style={{ display: "contents" }}
          key={index}
          value={tabValue}
          index={index}
        >
          <Suspense fallback={<div>Loading...</div>}>{tab.content}</Suspense>
        </TabPanel>
      ))}
    </Stack>
  );
};

export default ActivityEmailSidebar;
