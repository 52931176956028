export const formatTimeAllotted = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

  return `${formattedHours} hrs ${formattedMinutes} mins`;
};
export const convertSecondsToTime = (
  seconds: number
): { hrs: string; mins: string; secs: string } => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  const remainingSeconds = seconds % 60;
  return {
    hrs: hours.toString().padStart(2, "0"),
    mins: minutes.toString().padStart(2, "0"),
    secs: remainingSeconds.toString().padStart(2, "0"),
  };
};
