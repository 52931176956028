import { Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { ButtonSmall } from "../Buttons";
import { ButtonType, SmallButtonColor } from "../Buttons/AllButtonProps";
import { CheckboxMedium } from "../CheckboxMedium";
import { SingleDatePickerSmall } from "../SingleDatePickerSmall";
import styles from "./SetItemExpiration.module.css"; // Import CSS module

interface SetItemExpirationProps {
  initialDate?: string | null; // Initial date in ISO 8601 format, can be null
  onSave: (formattedDate: string | null) => void; // Callback to return the formatted date
}

const SetItemExpiration: React.FC<SetItemExpirationProps> = ({
  initialDate,
  onSave,
}) => {
  const [isExpirationSet, setIsExpirationSet] = useState(false); // Manage checkbox state
  const [selectedDate, setSelectedDate] = useState<Date | null | string>(null); // Manage selected date
  // Convert the initial date string to a Date object and set the state
  useEffect(() => {
    if (initialDate) {
      const date = new Date(initialDate);
      setSelectedDate(date);
      setIsExpirationSet(true); // If there's an initial date, check the checkbox
    }
  }, [initialDate]);

  // Handle checkbox change
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsExpirationSet(e.target.checked);
    if (!e.target.checked) {
      setSelectedDate(null); // Reset the date if the checkbox is unchecked
    }
  };

  // Handle date picker change
  const handleDateChange = (date: Date | null | string) => {
    setSelectedDate(date); // Update the selected date
  };

  const handleSave = () => {
    if (isExpirationSet) {
      if (selectedDate) {
        const formattedDate =
          selectedDate instanceof Date
            ? new Date(
                selectedDate.getTime() -
                  selectedDate.getTimezoneOffset() * 60000
              ).toISOString() // Adjust timezone before formatting to ISO
            : selectedDate;

        onSave(formattedDate); // Return the formatted date
      }
    } else {
      console.log("false ");
    }
  };

  return (
    <Stack className={styles["setItemExpiration"]}>
      {/* --- card title --- */}
      <Stack className={styles["setItemExpiration__title"]}>
        <Typography className={styles["setItemExpiration__titleText"]}>
          Set Item Expiration
        </Typography>
      </Stack>

      {/* --- text / checkbox and datepicker here --- */}
      <Stack className={styles["setItemExpiration__content"]}>
        <Typography className={styles["setItemExpiration__description"]}>
          This item will automatically be deleted from your account on the date
          selected below.
        </Typography>

        <Stack
          direction="row"
          alignItems="center"
          id="objectivedatepicker"
          spacing={1} // Add spacing between checkbox and date picker
          className={styles["setItemExpiration__checkboxAndDatePicker"]}
        >
          {/* Checkbox to toggle date picker */}
          <CheckboxMedium
            boxSize={15}
            checked={isExpirationSet} // Bind to state
            onChange={handleCheckboxChange} // Handle state update
          />

          {/* Conditionally render or enable date picker based on checkbox state */}
          <SingleDatePickerSmall
            initialDate={selectedDate as string} // Pass selected date
            onSelect={handleDateChange} // Capture selected date
          />
        </Stack>
      </Stack>

      {/* --- buttons here --- */}
      <Stack className={styles["setItemExpiration__buttons"]}>
        <ButtonSmall
          label="Cancel"
          types={ButtonType.Button}
          colorVarient={SmallButtonColor.Transparent}
        />

        <ButtonSmall
          label="Save"
          types={ButtonType.Button}
          colorVarient={SmallButtonColor.Orange}
          onClick={() => handleSave()} // Handle Save click
        />
      </Stack>
    </Stack>
  );
};

export default SetItemExpiration;
