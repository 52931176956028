export const getToken = () => {
  const token = localStorage.getItem("token") || "";
  return token;
};

export const getAccessToken = () => {
    const token = localStorage.getItem("accessToken") || "";
    return token;
  };

export const setToken = (token: string) => {
  localStorage.setItem("token", token);
};

export const setAccessToken = (token: string) => {
    localStorage.setItem("accessToken", token);
  };

export const getUser = () => {
  const user = localStorage.getItem("loggedInUser") || "";
  return user;
};

export const setUser = (user: string) => {
  localStorage.setItem("loggedInUser", user);
};

export const getEmailId = () => {
  const emailId = localStorage.getItem("emailId") || "";
  return emailId;
};
