import { Stack, Typography } from "@mui/material";
import React from "react";
import { TranslateIcon } from "../../../../../images";
import {
  ButtonSmall,
  ButtonType,
  LanguageDropdownMenu,
  languagesList,
  SmallButtonColor,
} from "../../../../common";

interface TranslatePopupProps {}

const TranslatePopup: React.FC<TranslatePopupProps> = ({}) => {
  return (
    <Stack
      sx={{
        width: "250px",
        borderRadius: "5px",
        boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
        bgcolor: "white",
        padding: "15px",
        boxSizing: "border-box",
        gap: "15px",
      }}
    >
      <Stack gap={"10px"}>
        <Stack sx={{ flexDirection: "row", gap: "5px" }}>
          <TranslateIcon w={15} />
          <Typography
            sx={{ font: "normal normal 400 14px/18px Source Serif Pro" }}
          >
            Translate Email
          </Typography>
        </Stack>
        <Typography
          sx={{
            font: "normal italic 300 14px/18px Source Serif Pro",
            letterSpacing: "-0.05em",
          }}
        >
          We use BEBA AI to instantly translate emails...
        </Typography>
      </Stack>

      <Typography sx={{ font: "normal normal 400 14px/18px Source Serif Pro" }}>
        Detected Language
      </Typography>
      <LanguageDropdownMenu
        options={languagesList}
        placeholder="Select a Language"
        onChange={(value) => null}
      />

      <Typography sx={{ font: "normal normal 400 14px/18px Source Serif Pro" }}>
        Translate to
      </Typography>
      <LanguageDropdownMenu
        options={languagesList}
        placeholder="Select a Language"
        onChange={(value) => null}
      />

      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "center",
          marginTop: "5px",
        }}
      >
        <ButtonSmall
          label="Save"
          types={ButtonType.Button}
          colorVarient={SmallButtonColor.Blue}
        />
      </Stack>
    </Stack>
  );
};

export default TranslatePopup;
