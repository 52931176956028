import React from "react";
import { Stack } from "@mui/material";
import S from "./AddDealPopupLarge.module.css";

import {
  CreateDealView,
  DealFundingView,
  DealStageView,
  DealValuationView,
  ProjectContractView,
  ProjectPublicationView,
  ProjectStageView,
} from "./view";

interface AddDealPopupLargeProps {
  variants:
    | "ProjectPublication"
    | "ProjectContract"
    | "ProjectStage"
    | "DealValuation"
    | "DealFunding"
    | "DealStage"
    | "CreateDeal";
  createDeal?: any;
}

const AddDealPopupLarge: React.FC<AddDealPopupLargeProps> = ({ variants, createDeal }) => {
  const getComponent = (): { height: string; component: React.ReactNode } => {
    switch (variants) {
      case "ProjectPublication":
        return { height: "340px", component: <ProjectPublicationView /> };

      case "ProjectContract":
        return { height: "375px", component: <ProjectContractView /> };

      case "ProjectStage":
        return { height: "375px", component: <ProjectStageView /> };

      case "DealValuation":
        return { height: "515px", component: <DealValuationView /> };

      case "DealFunding":
        return { height: "390px", component: <DealFundingView /> };

      case "DealStage":
        return { height: "390px", component: <DealStageView /> };

      case "CreateDeal":
        return { height: "340px", component: <CreateDealView createDeal={createDeal} /> };
    }
  };

  return (
    <Stack className={S["add-deal-popup-large"]}>
      <Stack
        className={S["add-deal-popup-large_inner-layout"]}
        sx={{ height: getComponent().height }}
      >
        {getComponent().component}
      </Stack>
    </Stack>
  );
};

export default AddDealPopupLarge;
