// Interface for regular menu items
export interface ShowAsFilterItemInterface {
    id: number;
    value: string;
    divider?: false;
}

// Type for divider
export interface ShowAsFilterDividerInterface {
    divider: true;
    id?: undefined;
    value?: undefined;
}

// Union type for both menu items and dividers
export type ShowAsFilterListInterface =
    | ShowAsFilterItemInterface
    | ShowAsFilterDividerInterface;

// List including both items and dividers
export const ShowAsFilterOptionList: ShowAsFilterListInterface[] = [
    { id: 1, value: "Free" },
    { divider: true },
    { id: 2, value: "Home Office" },
    { id: 3, value: "Elsewhere" },
    { id: 4, value: "Tentative" },
    { id: 5, value: "Proposed" },
    { id: 6, value: "Busy" },
    { id: 7, value: "Out of Office" },
];
