import { Box, Button, ButtonProps } from "@mui/material";
import React from "react";
import { AddtoGroupIcon } from "../../../../../images";
import g from "./BarButtonOptions.module.css";

type BarOptions = {
  id: number;
  name: string;
};

type BarButtonOptionsProps = {
  label: BarOptions;
  icon?: React.ReactNode;
  buttonProps?: ButtonProps;
  onClick?: any;
};

const BarButtonOptions: React.FC<BarButtonOptionsProps> = ({
  label,
  icon = <AddtoGroupIcon />,
  buttonProps = {},
  onClick = () => {},
}) => {
  const { name = "Add to Group" } = label;

  // Function to handle button click
  const handleBarButtonOptions = () => {
    if (onClick) {
      onClick();
    }
    // Implement your logic here, e.g., console log for demonstration
    console.log(`Adding to group or click: ${name}`);
  };

  return (
    <Box>
      <Button
        {...buttonProps}
        className={g.addToGroupButton}
        startIcon={icon}
        onClick={handleBarButtonOptions}
      >
        {`${name}`} {/* Use dynamic label */}
      </Button>
    </Box>
  );
};

export default BarButtonOptions;
