import React, { useState } from "react";
import {
  Box,
  IconButton,
  InputBase,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import {
  adjustHexColor,
  CalendarTypesEnum,
  getCalenderLogo,
} from "../../../../../Utils";
import {
  AllDirArrowCircleIcon,
  DeadlineIcon,
  UpdateIcon,
  XCircleIcon,
} from "../../../../../images";
import { EventTab, TaskTab } from "./tab";
import styles from "./InCalendarEventPopup.module.css";
import {
  ButtonSmall,
  ButtonType,
  CheckboxMedium,
  SmallButtonColor,
} from "../../../../common";
import { CalendarPicker } from "../CalendarPicker";
import { CalendarPickerOptionInterface } from "../CalendarPicker/CalendarPickerType";
import { AppointmentMenu } from "../AppointmentMenu";
import {
  CalendarState,
  createEvent__api,
  EventData,
  ProposedTime,
  recallAPI,
} from "../../../../../redux";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

interface InCalendarEventPopupProps {
  handleClose?: any;
}

const InCalendarEventPopup: React.FC<InCalendarEventPopupProps> = ({
  handleClose,
}) => {
  const dispatch = useDispatch();
  const calendarState = useSelector(
    (state: RootState) => state && (state?.calendar as CalendarState)
  );

  const [createLoader, setCreateLoader] = useState<boolean>(false);

  const { allCalendarList } = calendarState || {};

  // Function to format time in 12-hour format with zero-padded hours
  const formatTime = (date: Date) => {
    let hours = date?.getHours();
    let minutes: any = date?.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Zero-pad hours and minutes if less than 10
    const paddedHours = hours < 10 ? `0${hours}` : hours;
    const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${paddedHours}:${paddedMinutes} ${ampm}`;
  };

  // Function to add hours to a date object
  const addHours = (date: Date, hours: number) => {
    const newDate = new Date(date);
    newDate?.setHours(newDate?.getHours() + hours);
    return newDate;
  };

  const [eventData, setEventData] = useState<EventData>({
    title: "",
    eventType: "appointment",
    taskOrEvent: "event",
    calendarId: allCalendarList?.[0]?._id || "",
    startTime: new Date().toISOString(), // Current date and time in ISO format
    endTime: new Date(new Date().getTime() + 2 * 60 * 60 * 1000).toISOString(), // 2 hours from the current time in ISO format
    allDay: false,
    timezone: "UTC",
    guests: [],
    videoCall: {
      platform: "BearishOS",
      customLink: "",
    },
    location: "",
    showAs: "busy",
    description: "",
    repeat: {
      type: "none",
      custom: {
        interval: 1,
        repeatDays: [],
        frequency: "day",
        endDate: null,
      },
    },
    travelTime: {
      time: 0,
      direction: "before",
    },
    proposedTimes: [], // Can store up to 3 proposed times
    createdByType: "user", // Defaults to user-created event
  });

  console.log(eventData, "eventData");

  // Update startTime and endTime when a new date is selected
  const updateDate = (selectedDate: Date) => {
    // Only update the date part, preserving the existing time.
    const updatedStartTime = new Date(selectedDate);
    const updatedEndTime = new Date(selectedDate);

    // Set the updated date for both startTime and endTime
    updateField && updateField("startTime", updatedStartTime.toISOString());
    updateField && updateField("endTime", updatedEndTime.toISOString());
  };

  // Update startTime when a new time is selected
  const updateStartTime = (formattedTime: string) => {
    // Combine the selected time with the existing date from startTime
    const existingDate = new Date(eventData.startTime);
    const updatedStartTime = new Date(
      `${existingDate.toISOString().split("T")[0]}T${formattedTime}:00.000Z`
    );
    updateField && updateField("startTime", updatedStartTime.toISOString());
  };

  // Update endTime when a new time is selected
  const updateEndTime = (formattedTime: string) => {
    // Combine the selected time with the existing date from endTime
    const existingDate = new Date(eventData.endTime);
    const updatedEndTime = new Date(
      `${existingDate.toISOString().split("T")[0]}T${formattedTime}:00.000Z`
    );
    updateField && updateField("endTime", updatedEndTime.toISOString());
  };

  // Function to update proposed times in the event data
  const updateProposedTimes = (proposedTimes: ProposedTime[]) => {
    setEventData((prev) => ({
      ...prev,
      proposedTimes: proposedTimes.slice(0, 3), // Ensure maximum of 3 proposed times
    }));
  };

  // Function to update the state
  const updateField = (field: keyof EventData, value: any) => {
    setEventData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const [tabView, setTabView] = useState<"event" | "task">("event");
  // "#B5005B" is calendarPink, "#A53A01" is crmOrange
  const [cardColor, setCardColor] = useState<"#B5005B" | "#A53A01">("#B5005B");

  // Popover state management
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // Cache adjusted colors to avoid unnecessary recalculations
  const adjustedHeaderColor = adjustHexColor(cardColor, 80);

  const tabSwich = (tabName: "event" | "task") => {
    if (tabName === "event") {
      setCardColor("#B5005B");
      setTabView(tabName);
    } else {
      setCardColor("#A53A01");
      setTabView(tabName);
    }
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const calendarPickerlist: CalendarPickerOptionInterface[] =
    allCalendarList && typeof allCalendarList === "object" // Check if it's a valid object
      ? Object?.values(allCalendarList)?.map((calendar: any) => ({
          id: calendar?._id, // Use the _id as the id
          calendarType: CalendarTypesEnum.BearishCalendar, // Set to bearishCalendar
          calendarName: calendar?.name, // Use the name from the calendar
          _id: calendar?._id, // Include _id if needed
        }))
      : []; // Return an empty array if allCalendarList is not valid

  const handleCreateEventOrTask = async () => {
    try {
      if (!eventData?.title || !eventData?.calendarId) {
        return;
      }

      // Format startTime and endTime to ISO strings
      const formattedStartTime = new Date(eventData.startTime).toISOString();
      const formattedEndTime = new Date(eventData.endTime).toISOString();

      // Update eventData with formatted times
      const updatedEventData = {
        ...eventData,
        startTime: formattedStartTime,
        endTime: formattedEndTime,
      };

      setCreateLoader(true);

      // Use updatedEventData instead of eventData
      const action = createEvent__api(updatedEventData);

      const createEorTRes: any = await dispatch(action);

      if (createEorTRes?.success && handleClose) {
        handleClose();
        dispatch(recallAPI("getAllCalEvent"));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCreateLoader(false);
    }
  };

  return (
    <Stack className={styles["in-calendar-event-popup"]}>
      {/* Card Header */}
      <Stack className={styles["in-calendar-event-popup__header"]}>
        <Stack
          className={styles["in-calendar-event-popup__header-stripe"]}
          sx={{ bgcolor: cardColor }}
        />
        <Stack
          className={styles["in-calendar-event-popup__header-content"]}
          sx={{ bgcolor: adjustedHeaderColor }}
        >
          <Stack className={styles["in-calendar-event-popup__header-title"]}>
            <Typography
              className={styles["in-calendar-event-popup__header-text"]}
            >
              Calendar Name
            </Typography>
            <Box
              component={"img"}
              src={getCalenderLogo({
                calendarType: CalendarTypesEnum.TeamsCalendar,
              })}
              alt="calendar logo"
              className={styles["in-calendar-event-popup__header-logo"]}
            />
          </Stack>
          <Stack className={styles["in-calendar-event-popup__header-actions"]}>
            {tabView === "event" && (
              <IconButton
                className={styles["in-calendar-event-popup__icon-button"]}
                onClick={handlePopoverOpen} // Open popover on click
              >
                <UpdateIcon w={20} color="#fff" />
              </IconButton>
            )}

            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              PaperProps={{
                style: { boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)" },
              }}
            >
              <CalendarPicker
                width="200px"
                buttonSx={{ paddingLeft: "5px" }}
                options={calendarPickerlist || []}
                onSelect={(value) =>
                  updateField("calendarId", value?._id || value?.id)
                }
              />
            </Popover>
            <IconButton
              onClick={() => handleClose && handleClose()}
              className={styles["in-calendar-event-popup__icon-button"]}
            >
              <XCircleIcon w={20} color="#fff" />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
      {/* End of Card Header */}

      {/* Scrollable Container */}
      <Stack className={styles["in-calendar-event-popup__scrollable-body"]}>
        <Stack className={styles["in-calendar-event-popup__input-group"]}>
          <DeadlineIcon w={20} />
          <InputBase
            className={styles["in-calendar-event-popup__input"]}
            placeholder="Event title"
            value={eventData?.title}
            onChange={(e: any) => {
              updateField("title", e.target.value);
            }}
          />
        </Stack>

        <Stack className={styles["in-calendar-event-popup__button-group"]}>
          <Stack className={styles["in-calendar-event-popup__button-row"]}>
            <Stack
              onClick={() => {
                tabSwich("event");
                updateField("taskOrEvent", "event");
              }}
              className={styles["in-calendar-event-popup__button-item"]}
            >
              <CheckboxMedium boxSize={15} checked={tabView === "event"} />
              <Typography
                sx={{
                  font: "normal normal 300 15px/19px Source Serif Pro",
                  fontWeight: tabView === "event" ? 900 : 300,
                }}
              >
                Event
              </Typography>
            </Stack>
            {tabView === "event" && (
              <Stack
                className={styles["in-calendar-event-popup__appointment-menu"]}
              >
                <AllDirArrowCircleIcon w={15} direction="right" />
                <AppointmentMenu
                  onSelect={(value) => {
                    updateField(
                      "eventType",
                      value as
                        | "Appointment"
                        | "Meeting"
                        | "Reminders"
                        | "Bills/Payment"
                        | "Workshop"
                        | "Other"
                        | "Out of Office"
                    );
                  }}
                />
              </Stack>
            )}
          </Stack>

          <Stack
            onClick={() => {
              tabSwich("task");
              updateField("taskOrEvent", "task");
            }}
            className={styles["in-calendar-event-popup__button-item"]}
          >
            <CheckboxMedium boxSize={15} checked={tabView === "task"} />
            <Typography
              sx={{
                font: "normal normal 300 15px/19px Source Serif Pro",
                fontWeight: tabView === "task" ? 900 : 300,
              }}
            >
              Task
            </Typography>
          </Stack>
        </Stack>

        <Stack>
          {tabView === "event" ? (
            <EventTab
              updateStartTime={updateStartTime}
              updateEndTime={updateEndTime}
              updateDate={updateDate}
              updateProposedTimes={updateProposedTimes}
              updateField={updateField}
            />
          ) : (
            <TaskTab
              updateField={updateField}
              updateStartTime={updateStartTime}
              updateEndTime={updateEndTime}
              updateDate={updateDate}
            />
          )}
        </Stack>

        <Stack sx={{ flexDirection: "row", justifyContent: "end" }}>
          <ButtonSmall
            label="Save"
            onClick={() => handleCreateEventOrTask()}
            types={ButtonType.Button}
            loaderLogin={createLoader}
            colorVarient={
              tabView === "event"
                ? SmallButtonColor.CalendarMarronPink
                : SmallButtonColor.Orange
            }
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default InCalendarEventPopup;
