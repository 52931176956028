import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { XCircleIcon } from "../../../../../images";
import {
  a11yProps,
  StyledTab,
  TabPanel,
  TabsWithState,
} from "../../../../common/CardTabsWithoutIcon";
import {
  CalendarSettingsPayload,
  CalendarState,
  getAllCalendars__api,
  getCalendarSettings__api,
  recallAPI,
  updateCalendarSettings__api,
} from "../../../../../redux";
import { RootState } from "../../../../../redux/store";
import { getUser } from "../../../../../Utils";
import { useTranslation } from "react-i18next";

interface CalendarSettingsProps {
  setShowCalendarSetting?: any;
}
// Define the props expected by the tabs' content
interface TabContentProps {
  calendarSettings?: any;
  updateCalendarSettings: (
    settings: Partial<CalendarSettingsPayload>
  ) => Promise<void>;
}
const LazyAppearanceTab = lazy(
  () => import("./tabs/AppearanceTab/AppearanceTab")
);
const LazyGeneralTab = lazy(() => import("./tabs/GeneralTab/GeneralTab"));
const LazyAddCalendarsTab = lazy(
  () => import("./tabs/AddCalendarTab/AddCalendarTab")
);
const LazyExistingCalendarsTab = lazy(
  () => import("./tabs/ExistingCalendarTab/ExistingCalendarTab")
);

const tabs: {
  label: string;
  content: React.FC<TabContentProps>; // Updated to be a functional component with props
}[] = [
  {
    label: "Appearance",
    content: (props) => <LazyAppearanceTab {...props} />, // Pass props to the lazy-loaded component
  },
  {
    label: "General",
    content: (props) => <LazyGeneralTab {...props} />, // Pass props to the lazy-loaded component
  },
  {
    label: "Add Calendars",
    content: (props) => <LazyAddCalendarsTab {...props} />, // Pass props to the lazy-loaded component
  },
  {
    label: "Existing Calendars",
    content: (props) => <LazyExistingCalendarsTab {...props} />, // Pass calendar data and props
  },
];

const CalendarSettings: React.FC<CalendarSettingsProps> = ({
  setShowCalendarSetting,
}) => {
  const dispatch = useDispatch();

  const calendarState = useSelector(
    (state: RootState) => state && (state?.calendar as CalendarState)
  );

  const { reCall, calSettings } = calendarState || {};

  const [allCalendarList, setAllCalendarList] = useState([]);
  const [calendarSettings, setCalendarSettings] = useState<any>([]);

  const [tabValue, setTabValue] = useState(0);

  const getAllCalendar = async () => {
    try {
      const action = getAllCalendars__api();
      const allCalendarRes: any = await dispatch(action);

      if (allCalendarRes?.success && allCalendarRes?.data?.length > 0) {
        setAllCalendarList(allCalendarRes?.data);
      } else {
        setAllCalendarList([]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(recallAPI(null));
    }
  };

  const getCalendarSettings = async () => {
    try {
      const action = getCalendarSettings__api();
      await dispatch(action);
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(recallAPI(null));
    }
  };
  useEffect(() => {
    if (!calSettings && calSettings?.length === 0) {
      getCalendarSettings();
    }
    if (allCalendarList?.length === 0) {
      getAllCalendar();
    }
  }, []);
  useEffect(() => {
    if (calSettings) {
      setCalendarSettings(calSettings);
    }
  }, [calSettings]);

  const user = getUser();

  const updateCalendarSettings = async (
    settings: Partial<CalendarSettingsPayload>
  ) => {
    try {
      // Retrieve the existing calendar settings from state
      const existingSettings = calendarSettings; // Assuming calendarSettings is already in your state

      // Create the payload by merging new settings with existing settings, using defaults where necessary
      const payload: Partial<CalendarSettingsPayload> = {
        userId: user?.data?._id, // Assuming user ID is retrieved from the user object

        // Appearance settings
        appearance: {
          smartFilters:
            settings?.appearance?.smartFilters ??
            existingSettings?.appearance?.smartFilters ??
            true, // Default to true
          firstDayOfWeek:
            settings?.appearance?.firstDayOfWeek ||
            existingSettings?.appearance?.firstDayOfWeek ||
            "Monday", // Default to "Monday"
          workWeekDays: settings?.appearance?.workWeekDays ||
            existingSettings?.appearance?.workWeekDays || [
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
            ], // Default work week (Mon-Fri)
          meetingTimes: {
            startTime:
              settings?.appearance?.meetingTimes?.startTime ||
              existingSettings?.appearance?.meetingTimes?.startTime ||
              "09:00 AM", // Default start time
            endTime:
              settings?.appearance?.meetingTimes?.endTime ||
              existingSettings?.appearance?.meetingTimes?.endTime ||
              "05:00 PM", // Default end time
            timezone:
              settings?.appearance?.meetingTimes?.timezone ||
              existingSettings?.appearance?.meetingTimes?.timezone ||
              "UTC", // Default timezone
            secondaryTimezone:
              settings?.appearance?.meetingTimes?.secondaryTimezone ||
              existingSettings?.appearance?.meetingTimes?.secondaryTimezone ||
              null, // Default to null
            autoUpdateTimezone:
              settings?.appearance?.meetingTimes?.autoUpdateTimezone ??
              existingSettings?.appearance?.meetingTimes?.autoUpdateTimezone ??
              false, // Default to false
          },
        },

        // General settings
        general: {
          weather:
            settings?.general?.weather ??
            existingSettings?.general?.weather ??
            true, // Default to true
          reminders: settings?.general?.reminders ||
            existingSettings?.general?.reminders || [15], // Default to 15-minute reminder
        },

        // Holiday calendars
        holidayCalendars: {
          religiousHolidays: {
            christian:
              settings?.holidayCalendars?.religiousHolidays?.christian ??
              existingSettings?.holidayCalendars?.religiousHolidays
                ?.christian ??
              false, // Default to false
            orthodox:
              settings?.holidayCalendars?.religiousHolidays?.orthodox ??
              existingSettings?.holidayCalendars?.religiousHolidays?.orthodox ??
              false, // Default to false
            muslim:
              settings?.holidayCalendars?.religiousHolidays?.muslim ??
              existingSettings?.holidayCalendars?.religiousHolidays?.muslim ??
              false, // Default to false
            hindu:
              settings?.holidayCalendars?.religiousHolidays?.hindu ??
              existingSettings?.holidayCalendars?.religiousHolidays?.hindu ??
              false, // Default to false
            jewish:
              settings?.holidayCalendars?.religiousHolidays?.jewish ??
              existingSettings?.holidayCalendars?.religiousHolidays?.jewish ??
              false, // Default to false
          },
          regionalHolidays: {
            usa:
              settings?.holidayCalendars?.regionalHolidays?.usa ??
              existingSettings?.holidayCalendars?.regionalHolidays?.usa ??
              false, // Default to false
            regions:
              settings?.holidayCalendars?.regionalHolidays?.regions ||
              existingSettings?.holidayCalendars?.regionalHolidays?.regions ||
              [], // Default to an empty array
          },
        },

        // Soft delete (if provided)
        isDeleted: settings?.isDeleted ?? existingSettings?.isDeleted ?? false, // Default to false if not provided
      };

      // Update the local state with the new merged settings
      setCalendarSettings((prevSettings: any) => ({
        ...prevSettings,
        ...payload, // Apply changes to the current settings
      }));

      // Dispatch the update action with the payload
      const action = updateCalendarSettings__api(payload);
      await dispatch(action);
    } catch (err) {
      console.error("Error updating calendar settings:", err);
    }
  };

  useEffect(() => {
    if (reCall === "getAllCal") {
      getAllCalendar();
    }
  }, [reCall]);
  const { t } = useTranslation();
  return (
    <Stack
      sx={{
        bgcolor: "white",
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        padding: "20px",
        gap: "20px",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            font: "normal normal 900 20px/25px Source Serif Pro",
            marginBottom: "10px",
          }}
        >
          {t("CALENDAR.CALENDAR_SETTINGS")}
        </Typography>

        <Tooltip title="Close" placement="top" arrow>
          <IconButton
            onClick={() => setShowCalendarSetting(false)}
            sx={{ p: 1 }}
            disableRipple
          >
            <XCircleIcon w={20} color="black" />
          </IconButton>
        </Tooltip>
      </Stack>
      <TabsWithState
        ariaLabel="Email details tabs"
        value={tabValue}
        onChange={(event, newValue) => setTabValue(newValue)}
        tabIndicatorColor="#B5005B"
        sx={{
          maxWidth: "max-content",
          gap: "20px",
          borderBottom: "1px solid #E9EDF2",
          "& .css-1aquho2-MuiTabs-indicator": {
            bottom: "0px !important",
          },
        }}
      >
        {tabs.map((tab, index) => (
          <StyledTab
            style={{
              font: "normal normal 400 18px/23px Source Serif Pro",
            }}
            key={index}
            disableRipple
            label={tab.label}
            {...a11yProps(index)}
          />
        ))}
      </TabsWithState>

      {tabs.map((tab, index) => (
        <TabPanel
          style={{ display: "contents" }}
          key={index}
          value={tabValue}
          index={index}
        >
          <Suspense fallback={<div>{t("CALENDAR.LOADING")}</div>}>
            {index === 3 ? (
              <LazyExistingCalendarsTab
                calendars={allCalendarList}
                calendarSettings={calendarSettings}
                updateCalendarSettings={updateCalendarSettings} // Pass the update function
              />
            ) : (
              <tab.content
                calendarSettings={calendarSettings}
                updateCalendarSettings={updateCalendarSettings}
              /> // Pass the update function
            )}
          </Suspense>
        </TabPanel>
      ))}
    </Stack>
  );
};

export default CalendarSettings;
