import { IconButton, Popover, Stack, Typography } from "@mui/material";
import React from "react";
import { TeamMemberIcon } from "../../../../images";
import S from "./ShareButton.module.css";
import { PopOverModel } from "./PopOverModel";
import { useShareButton } from "./useShareButton";
import { useParams } from "react-router-dom";
import {
  addWorkspaceMember__api,
  getAllWorkspaceMember__api,
  getOneFile__api,
  getShareCalendarWithUser__api,
  shareCalendarWithUser__api,
  shareFileWithEmail__api,
  shareFileWithUsers__api,
  UpdateWorkspaceMember,
  updateWorkspaceMember__api,
} from "../../../../redux";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/rootReducer";

interface ShareButtonProps {
  wId?: string;
  reStructure?: boolean;
  calendarId?: string;
}
type Params = Record<string, string | undefined>;

const ShareButton: React.FC<ShareButtonProps> = ({
  wId,
  reStructure = false,
  calendarId,
}) => {
  const dispatch = useDispatch();
  const { workspaceId, fileId } = useParams<Params>();
  const {
    anchorEl,
    tooltipTitle,
    selectedTab,
    selectedOption,
    open,
    id,
    handleClick,
    handleClose,
    handleCopyLinkClick,
    resetSelection,
    setSelectedTab,
  } = useShareButton();
  const workspaceState = useSelector((state: RootState) => state.workspace);
  const { error } = workspaceState || {};

  // Handle selected data from PersonDropdown
  const handleSelectedData = async (data: {
    userData: any;
    action: any;
    customEmail?: any;
  }) => {
    // Map roles based on the action
    let memberRole = data.action;

    // Role mapping for different member roles
    if (memberRole === "Guest" || memberRole === "Commenter") {
      memberRole = "viewer"; // Map "Guest" and "Commenter" to "viewer"
    } else if (
      memberRole === "Editor" ||
      memberRole === "admin" ||
      memberRole === ""
    ) {
      memberRole = "editor"; // Map "Editor" and "admin" to "editor"
    }

    // Role mapping for calendar (must use "view-only", "write", "full-control")
    if (calendarId) {
      if (memberRole === "viewer") {
        memberRole = "view-only"; // Map viewer to "view-only"
      } else if (memberRole === "editor") {
        memberRole = "write"; // Map editor to "write"
      } else if (memberRole === "admin") {
        memberRole = "full-control"; // Map admin to "full-control"
      }
    }

    // Handling workspace sharing
    if (workspaceId) {
      // Proceed only if workspaceId is available
      const payload = {
        memberId: data.userData._id,
        memberRole: memberRole, // Use the mapped role
        permissions: "edit", // Adjust permissions as needed
      };

      try {
        const addMemberResponse: any = await dispatch(
          addWorkspaceMember__api(payload, workspaceId)
        );
        if (addMemberResponse?.success) {
          await getAllMembers();
        }
      } catch (error) {
        console.error("Error adding member:", error);
      }
    }
    // Handling file sharing
    else if (fileId) {
      // For internal product user
      if (
        data?.userData?._id &&
        data?.userData?.email &&
        !data?.userData?.recipientEmail
      ) {
        const payload = {
          memberId: data?.userData?._id,
          memberRole: memberRole, // Use the mapped role
        };

        try {
          await dispatch(shareFileWithUsers__api(fileId, payload));
        } catch (error) {
          console.error("Error sharing file with user:", error);
        }
      }

      // For external user via email
      if (data?.userData?.recipientEmail && !data?.userData?._id) {
        const recipientEmail =
          data?.userData?.recipientEmail || data.customEmail;

        if (recipientEmail) {
          const emailPayload = {
            fileId: fileId,
            recipientEmail: recipientEmail,
          };

          try {
            await dispatch(shareFileWithEmail__api(emailPayload));
          } catch (error) {
            console.error("Error sharing file via email:", error);
          }
        }
      }
    }
    // Handling calendar sharing
    else if (calendarId) {
      let payload = null;

      // For product user (has _id and email)
      if (
        data?.userData?._id &&
        data?.userData?.email &&
        !data?.userData?.recipientEmail
      ) {
        payload = {
          calendarId,
          productUsers: [
            {
              userId: data?.userData?._id,
              role: memberRole || "view-only", // Mapped role, default to "view-only"
            },
          ],
        };
      }

      // For external user via email
      if (data?.userData?.recipientEmail && !data?.userData?._id) {
        const recipientEmail =
          data?.userData?.recipientEmail || data.customEmail;

        if (recipientEmail) {
          payload = {
            calendarId,
            externalUsers: [
              {
                email: recipientEmail,
                role: memberRole || "view-only", // Mapped role, default to "view-only"
              },
            ],
          };
        }
      }

      /* Final payload submission */
      try {
        if (payload) {
          const action = shareCalendarWithUser__api(payload);
          const shareRes: any = await dispatch(action);

          if (shareRes?.success) {
            await getAllMembers();
          }
        }
      } catch (error) {
        console.log("Error sharing calendar:", error);
      }
    }
  };

  const getAllMembers = async () => {
    if (workspaceId) {
      try {
        await dispatch(getAllWorkspaceMember__api({ workspaceId }));
      } catch (err) {
        if (error) {
          console.error("Error from state:", error);
        } else {
          console.error("Failed to fetch members:", err);
        }
      }
    } else if (fileId) {
      try {
        await dispatch(getOneFile__api(fileId));
      } catch (e) {
        console.error(e);
      }
    } else if (calendarId) {
      try {
        await dispatch(getShareCalendarWithUser__api(calendarId));
      } catch (e) {
        console.error(e);
      }
    }
  };

  const updateMembers = async (item: any, permissions: string) => {
    console.log(calendarId, permissions);
    if (workspaceId && item?._id && permissions) {
      try {
        const payload: UpdateWorkspaceMember = {
          role: item?.role,
          permissions: permissions,
        };

        const updateResponse: any = await dispatch(
          updateWorkspaceMember__api(workspaceId, item?._id, payload)
        );
        if (updateResponse?.success) {
          await getAllMembers();
        }
      } catch (err) {
        if (error) {
          console.error("Error from state:", error);
        } else {
          console.error("Failed to fetch members:", err);
        }
      }
    } else if (fileId) {
      console.log(item, permissions);
    } else if (calendarId) {
      console.log(item, permissions);
    }
  };

  return (
    <>
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        className={S["share-button"]}
        disableRipple
        disableFocusRipple
      >
        <Stack direction={"row"} alignItems={"center"} gap={"5px"}>
          {!reStructure && <TeamMemberIcon w={20} />}
          <Typography
            sx={{ font: "normal normal 300 14px/20px Source Serif Pro" }}
          >
            Share
          </Typography>
        </Stack>
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          className: S["share-button__popover"],
        }}
      >
        <PopOverModel
          tooltipTitle={tooltipTitle}
          resetSelection={resetSelection}
          handleSelectedData={handleSelectedData}
          selectedOption={selectedOption}
          handleCopyLinkClick={handleCopyLinkClick}
          setSelectedTab={setSelectedTab}
          selectedTab={selectedTab}
          getAllMembers={getAllMembers}
          updateMembers={updateMembers}
          calendarId={calendarId}
        />
      </Popover>
    </>
  );
};

export default ShareButton;
