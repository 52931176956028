import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  EmailFilterChipsList,
  EmailFilterChipsListInterface,
  EmailFilterChipsVariantEnum,
} from "./EmailFilterChipsTypes";
import { adjustHexColor } from "../../../../../Utils";

interface EmailFilterChipsProps {
  variant: EmailFilterChipsVariantEnum;
}

const EmailFilterChips: React.FC<EmailFilterChipsProps> = ({ variant }) => {
  const [cardVariant, setCardVariant] = useState<
    EmailFilterChipsListInterface | undefined
  >();

  useEffect(() => {
    setCardVariant(EmailFilterChipsList?.find((item) => item.name === variant!));
  }, [variant]);

  return (
    <Stack
      sx={{
        height: "20px",
        flexDirection: "row",
        alignItems: "center",
        gap: "5px",
        padding: "0px 8px",
        borderRadius: "10px",
        bgcolor: adjustHexColor(cardVariant?.bgColor || "##008D4C", 25),
      }}
    >
      {cardVariant && React.createElement(cardVariant.icon, { w: 13 })}
      <Typography sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}>
        {/* {cardVariant?.name} */}
        {variant}
      </Typography>
    </Stack>
  );
};

export default EmailFilterChips;
