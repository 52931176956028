import { Box, Rating, Stack, Typography } from "@mui/material";
import React from "react";
import {
  DummyImage,
  RatingStarIcon,
  WebexPng,
} from "../../../../../../../images";
import {
  AvatarNormal,
  ButtonSmall,
  ButtonType,
  CheckboxMedium,
  CompanyLongDropdown,
  DropdownWithColor,
  DUMMY_CompanyLongDropdownOption,
  DUMMY_DEALSTAGE_OPTIONS,
  LargeInputBox,
  SingleDatePickerSmall,
  SingleTeammateDropdown,
  SmallButtonColor,
} from "../../../../../../common";
import { adjustHexColor } from "../../../../../../../Utils";
import M from "../../AddDealPopupLarge.module.css";
import S from "./ProjectContractView.module.css";

interface ProjectContractViewProps {}

const ProjectContractView: React.FC<ProjectContractViewProps> = ({}) => {
  return (
    <>
      <Stack className={M["add-deal-popup-large__header"]}>
        <AvatarNormal
          size={15}
          username="Kendal Michaels VI"
          avatarColor="green"
          imgSrc={DummyImage}
        />
        <Typography
          sx={{ font: "normal normal normal 14px/20px Source Serif Pro" }}
        >
          Kendal Michaels
        </Typography>
      </Stack>

      {/* Card body */}
      <Stack className={M["add-deal-popup-large__body"]}>
        <LargeInputBox
          borderColor="#E9EDF2"
          height="30px"
          label={"Project name"}
          value=""
          onChange={(value) => null}
        />

        <Stack className={S["project-contract-view__row"]}>
          <DropdownWithColor
            options={DUMMY_DEALSTAGE_OPTIONS}
            label="Onboarding stage"
            placeholder="choose one... "
            onChange={(value) => console.log("new data", value)}
            onSelect={(value) => console.log("selected option", value)}
          />

          <SingleTeammateDropdown label="Manager" placeholder="select user" />

          <SingleTeammateDropdown
            label="Associated people"
            placeholder="select user"
          />
        </Stack>

        <LargeInputBox
          height="30px"
          borderColor="#E9EDF2"
          label={"Rate USD"}
          value=""
          onChange={(value) => null}
        />

        <Stack className={S["project-contract-view__row--priority"]}>
          <DropdownWithColor
            options={DUMMY_DEALSTAGE_OPTIONS}
            label="Team"
            placeholder="choose one... "
            onChange={(value) => console.log("new data", value)}
            onSelect={(value) => console.log("selected option", value)}
          />

          <SingleDatePickerSmall label="Projected start date" />

          <Stack className={S["project-contract-view__confidence"]}>
            <Typography
              sx={{
                font: "normal normal 300 10px/13px Source Serif Pro",
                marginBottom: "5px",
              }}
            >
              Join confidence
            </Typography>
            <Rating
              value={3}
              className={S["project-contract-view__rating"]}
              emptyIcon={
                <RatingStarIcon w={20} color={adjustHexColor("#E2875E", 20)} />
              }
              icon={<RatingStarIcon w={20} color="#E2875E" />}
            />
          </Stack>

          <DropdownWithColor
            options={DUMMY_DEALSTAGE_OPTIONS}
            label="Accounts setup"
            placeholder="choose one... "
            onChange={(value) => console.log("new data", value)}
            onSelect={(value) => console.log("selected option", value)}
          />

          <DropdownWithColor
            options={DUMMY_DEALSTAGE_OPTIONS}
            label="Hardware setup"
            placeholder="choose one... "
            onChange={(value) => console.log("new data", value)}
            onSelect={(value) => console.log("selected option", value)}
          />
        </Stack>

        <Stack className={S["project-contract-view__row--categories"]}>
          <DropdownWithColor
            options={DUMMY_DEALSTAGE_OPTIONS}
            label="Security training"
            placeholder="choose one... "
            onChange={(value) => console.log("new data", value)}
            onSelect={(value) => console.log("selected option", value)}
          />
          <Stack className={S["project-contract-view__confidence"]}>
            <Typography
              sx={{
                font: "normal normal 300 10px/13px Source Serif Pro",
                marginBottom: "5px",
              }}
            >
              Contract Signed
            </Typography>
            <CheckboxMedium boxSize={15} />
          </Stack>
          <Stack className={S["project-contract-view__confidence"]}>
            <Typography
              sx={{
                font: "normal normal 300 10px/13px Source Serif Pro",
                marginBottom: "5px",
              }}
            >
              Payroll setup
            </Typography>
            <CheckboxMedium boxSize={15} />
          </Stack>
          <Stack className={S["project-contract-view__confidence"]}>
            <Typography
              sx={{
                font: "normal normal 300 10px/13px Source Serif Pro",
                marginBottom: "5px",
              }}
            >
              Team meeting setup
            </Typography>
            <CheckboxMedium boxSize={15} />
          </Stack>
        </Stack>

        <LargeInputBox
          height="30px"
          borderColor="#E9EDF2"
          label={"Details"}
          value=""
          onChange={(value) => null}
        />

        {/* Buttons */}
        <Stack className={S["project-contract-view__buttons"]}>
          <ButtonSmall
            label="Cancel"
            types={ButtonType.Button}
            colorVarient={SmallButtonColor.Transparent}
          />
          <ButtonSmall
            label="Save"
            types={ButtonType.Button}
            colorVarient={SmallButtonColor.CRMOrange}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default ProjectContractView;
