import { createSlice } from "@reduxjs/toolkit";
import { ShareState } from "./ShareType";
import {
    /* Add Share */
    addShare__Failure,
    addShare__Request,
    addShare__Success,

    /* Get Share */
    getShare__Failure,
    getShare__Request,
    getShare__Success,
} from "./ShareSliceAction";

const initialState: ShareState = {
    error: null,
    data: null,
};

const ShareSlice = createSlice({
    name: "share", // Name of the slice
    initialState, // Initial state of the slice
    reducers: {
        /* Create Share */
        addShare__Request,
        addShare__Failure,
        addShare__Success,

        /* Get Share */
        getShare__Failure,
        getShare__Request,
        getShare__Success,
    },
});

export const {
    /* Share action */
    addShare__Request: addShare__RequestAction,
    addShare__Failure: addShare__FailureAction,
    addShare__Success: addShare__SuccessAction,

    getShare__Request: getShare__RequestAction,
    getShare__Failure: getShare__FailureAction,
    getShare__Success: getShare__SuccessAction,

    /* and so on... */
} = ShareSlice.actions;

export default ShareSlice.reducer;
