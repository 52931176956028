import React, { useState, useEffect, useRef, KeyboardEvent } from "react";
import {
  useAutocomplete,
  AutocompleteGetTagProps,
} from "@mui/base/useAutocomplete";
import { styled } from "@mui/material/styles";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { Stack, Typography } from "@mui/material";
import { AvatarNormal } from "../AvatarNormal";
import { XCircleIcon } from "../../../images";
import { HostDropdownListInterface } from "./HostDropdownType";

const Root = styled("div")(
  ({ theme }) => `
  width: 100%;
  box-sizing: border-box;
  color: ${
    theme.palette.mode === "dark" ? "rgba(255,255,255,0.65)" : "rgba(0,0,0,.85)"
  };
  font-size: 14px;
`
);

const InputWrapper = styled("div")(
  () => `
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  & input {
    color: #000;
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
    font: normal normal 300 13px/16px Source Serif Pro;
  }
`
);

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
  label: string;
  companyIconUrl?: string;
}

function Tag(props: TagProps) {
  const { label, companyIconUrl, onDelete, ...other } = props;

  const handleDelete = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    if (typeof onDelete === "function") {
      onDelete(event); // Pass the event to the onDelete function
    }
  };

  return (
    <div {...other}>
      <AvatarNormal size={20} username={label} imgSrc={companyIconUrl} />
      <span>{label}</span>
      <span style={{ display: "flex" }} onClick={handleDelete}>
        <XCircleIcon w={12} />
      </span>
    </div>
  );
}

const StyledTag = styled(Tag)<TagProps>(
  () => `
  display: flex;
  align-items: center;
  margin: 2px;
  height: 30px;
  border: 1px solid #E9EDF2;
  border-radius: 100px;
  box-sizing: content-box;
  padding: 0px 8px;
  gap: 5px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color:  #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font: normal normal 300 13px/16px Source Serif Pro;
  }

  & svg {
    cursor: pointer;
  }
`
);

const Listbox = styled("ul")(
  ({ theme }) => `
  box-sizing: border-box;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;
  scrollbar-width: none;

  & li {
    padding: 5px 12px;
    display: flex;
    box-sizing: border-box;
  }

  & li[aria-selected='true'] {
    background-color: #e6f7ff;
  }

  & li.${autocompleteClasses.focused} {
    background-color: #ebebeb;
    cursor: pointer;
  }
`
);

interface HostDropdownProps {
  options: HostDropdownListInterface[];
  onChangeSelection: (selectedOptions: HostDropdownListInterface[]) => void;
  getHosts: (value: string) => void;
  initialHosts?: HostDropdownListInterface[];
}

// Custom comparison function for React.memo
const areEqual = (
  prevProps: HostDropdownProps,
  nextProps: HostDropdownProps
) => {
  if (prevProps.options.length !== nextProps.options.length) {
    return false;
  }

  for (let i = 0; i < prevProps.options.length; i++) {
    if (prevProps.options[i].name !== nextProps.options[i].name) {
      return false;
    }
  }

  return true;
};

const HostDropdown: React.FC<HostDropdownProps> = React.memo(
  ({ options, onChangeSelection, getHosts, initialHosts }) => {
    const [inputValue, setInputValue] = useState("");
    const [customOptions, setCustomOptions] =
      useState<HostDropdownListInterface[]>(options);
    const [value, setValue] = useState<HostDropdownListInterface[]>(
      initialHosts || []
    );

    const handleAddOption = (
      event: KeyboardEvent<HTMLDivElement>,
      newInputValue: string
    ) => {
      if (event.key === "Enter" && newInputValue) {
        const optionExists = customOptions?.some(
          (option: any) =>
            option?.companyName.toLowerCase() === newInputValue.toLowerCase()
        );

        if (!optionExists) {
          const newOption = { domain: "", name: newInputValue, icon: "" };
          setCustomOptions([...customOptions, newOption]);
          setValue((prevValue) => [...prevValue, newOption]);
          setInputValue("");
        }
      } else if (event.key === "Backspace" && !inputValue && value.length) {
        const newValue = value.slice(0, -1);
        setValue(newValue);
      }
    };

    const handleDeleteTag = (tagToDelete: HostDropdownListInterface) => {
      setValue((prevValue) =>
        prevValue.filter((tag) => tag.name !== tagToDelete.name)
      );
    };

    useEffect(() => {
      const formattedValue = value.map((item) => ({
        name: item.name,
        icon: item.icon,
        domain: item.domain,
      }));
      onChangeSelection(formattedValue);
    }, [value, onChangeSelection]);

    const prevOptionsRef = useRef(options);

    useEffect(() => {
      if (prevOptionsRef.current !== options) {
        prevOptionsRef.current = options;
      }
    }, [options]);

    const {
      getRootProps,
      getInputProps,
      getTagProps,
      getListboxProps,
      getOptionProps,
      groupedOptions,
      focused,
      setAnchorEl,
    } = useAutocomplete({
      id: "host-dropdown",
      multiple: true,
      options: customOptions,
      getOptionLabel: (option) => option.name ?? "",
      inputValue: inputValue,
      onInputChange: (event, newInputValue) => {
        setInputValue(newInputValue);
        getHosts(newInputValue);
      },
      onChange: (event, newValue) => {
        setValue((prevValue) => [...prevValue, ...newValue]);
      },
      clearOnBlur: false,
    });

    useEffect(() => {
      setCustomOptions(options);
    }, [options]);

    useEffect(() => {
      if (initialHosts) {
        setValue(initialHosts);
      }
    }, [initialHosts]);

    console.log(initialHosts, "initialHostsxx");

    return (
      <Root>
        <div
          {...getRootProps()}
          onKeyDown={(event) => handleAddOption(event, inputValue)}
        >
          <InputWrapper ref={setAnchorEl} className={focused ? "focused" : ""}>
            {value?.map((option: HostDropdownListInterface, index: number) => {
              const { key, ...tagProps } = getTagProps({ index });
              return (
                <StyledTag
                  key={key}
                  {...tagProps}
                  label={option?.name ?? ""}
                  companyIconUrl={option.icon}
                  onDelete={() => handleDeleteTag(option)}
                />
              );
            })}
            <input {...getInputProps()} value={inputValue} />
          </InputWrapper>
        </div>
        {groupedOptions?.length > 0 ? (
          <Listbox {...getListboxProps()}>
            {(groupedOptions as HostDropdownListInterface[])?.map(
              (option, index) => {
                const { ...optionProps } = getOptionProps({ option, index });
                return (
                  <li
                    {...optionProps}
                    style={{
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <AvatarNormal
                      username={option?.name ?? ""}
                      size={30}
                      imgSrc={option?.icon}
                    />
                    <Typography
                      sx={{
                        font: "normal normal 400 14px/18px Source Serif Pro",
                      }}
                    >
                      {option.name}
                    </Typography>
                  </li>
                );
              }
            )}
          </Listbox>
        ) : null}
      </Root>
    );
  },
  areEqual
);

export default HostDropdown;
