import React from "react";
import { SmallThreeDotMenuListInterface } from "../SmallThreeDotMenu/SmallThreeDotMenuTypes";
import {
  DeleteIcon,
  DownloadIcon,
  ShareIcon,
  TemplateIcon,
} from "../../../images";
import { SmallThreeDotMenu } from "../SmallThreeDotMenu";
import {useTranslation} from "react-i18next";

interface ThreeDotMenuNotesProps {
  displayOption?: "left" | "right";
}

const ThreeDotMenuNotes: React.FC<ThreeDotMenuNotesProps> = ({
  displayOption = "left",
}) => {
  const {t}=useTranslation()
  const ThreeDotMenuNotesOptionsList: SmallThreeDotMenuListInterface[] = [
    {
      id: 1,
      icon: TemplateIcon,
      text: t('VIDEO_CENTER.MEETING.CREATE_TEMPLATE'),
    },
    {
      id: 2,
      icon: TemplateIcon,
      text: t('VIDEO_CENTER.MEETING.APPLY_TEMPLATE'),
    },
    {
      id: 3,
      icon: DownloadIcon,
      text: t('VIDEO_CENTER.MEETING.DOWNLOAD'),
    },
    {
      id: 4,
      icon: ShareIcon,
      text:t('VIDEO_CENTER.MEETING.SHARE'),
    },
    {
      id: 5,
      icon: DeleteIcon,
      text: t('VIDEO_CENTER.MEETING.DELETE'),
      color: "red",
    },
  ];
  return (
    <SmallThreeDotMenu
      menuItems={ThreeDotMenuNotesOptionsList}
      onClickAction={(value) => console.log(value)}
      displayOption={displayOption}
    />
  );
};

export default ThreeDotMenuNotes;
