import { Stack } from "@mui/material";
import React, { useState } from "react";
import { CustomCheckboxDropdown } from "../../../../../../../../common";

interface SelectDropdownProps {
  options: { value: string; color: string }[];
  initialValues?: string[];
  onChange?: (selectedOptions: string[]) => void;
}

const SelectDropdownView: React.FC<SelectDropdownProps> = ({
  options,
  initialValues = [],
  onChange,
}) => {
  const [selectedOptions, setSelectedOptions] =
    useState<string[]>(initialValues);

  const handleSelectionChange = (selectedValues: any) => {
    setSelectedOptions(selectedValues);
    if (onChange) {
      onChange(selectedValues);
    }
  };

  return (
    <Stack>
      <CustomCheckboxDropdown
        placeHolder="Select..."
        options={options}
        selectedOptions={selectedOptions}
        onChange={handleSelectionChange}
      />
    </Stack>
  );
};

export default SelectDropdownView;
