import { Box, Stack, Tooltip, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { SoonHorizontal } from "../../../SoonHorizontal";
import { PersonDropdown } from "../../../PersonDropdown";
import { AvatarNamePlateSmall } from "../../../AvatarNamePlateSmall";
import { InternalSharedButton } from "../../InternalSharedButton";
import { ShareOptionDropdown } from "../ShareOptionDropdown";
import { ConnectedIcon } from "../../../../../images";
import S from "../ShareButton.module.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { useParams } from "react-router-dom";
type PopOverModelProps = {
  selectedTab: string;
  setSelectedTab: (string: string) => void;
  tooltipTitle: string;
  handleSelectedData: (data: { userData: any; action: any }) => void;
  resetSelection: () => void;
  selectedOption: string;
  handleCopyLinkClick: () => void;
  wId?: string;
  getAllMembers: () => void;
  updateMembers?: (item: any, premissions: string) => void;
  calendarId?: any;
};
type Params = Record<string, string | undefined>;

const PopOverModel = ({
  setSelectedTab,
  selectedTab,
  tooltipTitle,
  handleSelectedData,
  resetSelection,
  selectedOption,
  handleCopyLinkClick,
  getAllMembers,
  updateMembers,
  calendarId,
}: PopOverModelProps) => {
  const { workspaceId, fileId } = useParams<Params>();
  const workspaceState = useSelector(
    (state: RootState) => state && state?.workspace
  );
  const cloudStorageState = useSelector(
    (state: RootState) => state && state?.cloudStorage
  );
  const calendarState = useSelector(
    (state: RootState) => state && state?.calendar
  );

  const { memberOfWorkspace } = workspaceState || {};
  const { memberOfCloudStorageFile } = cloudStorageState || {};
  const { memberOfCalendar } = calendarState || {};

  // Check if workspace members exist
  const workspaceMembers = memberOfWorkspace?.members || [];
  // Check if cloud storage members exist
  const cloudStorageMembers = memberOfCloudStorageFile?.members || [];
  // Check if calendar members exist
  const calendarMembers = memberOfCalendar?.members || [];

  // Logic to determine which members to display
  const contentToDisplay =
    workspaceId && workspaceMembers.length > 0
      ? workspaceMembers // Show workspace members if workspaceId exists
      : fileId && cloudStorageMembers.length > 0
      ? cloudStorageMembers // Show cloud storage members if fileId exists and workspaceId is absent
      : calendarId && calendarMembers.length > 0
      ? calendarMembers // Show calendar members if calendarId exists and others are absent
      : null; // Show nothing if no conditions are met
  useEffect(() => {
    // Fetch workspace members if workspaceId is present
    if (workspaceId && !workspaceMembers?.length) {
      getAllMembers(); // Fetch workspace members
    }
    // Fetch cloud storage members if no workspaceId but fileId exists
    else if (fileId && !cloudStorageMembers?.length) {
      getAllMembers(); // Fetch cloud storage members
    }
    // Fetch cloud storage members if no workspaceId but fileId exists
    else if (calendarId && !calendarMembers?.length) {
      getAllMembers(); // Fetch cloud storage members
    }
  }, [workspaceId, fileId, calendarId]);

  return (
    <div>
      {" "}
      <Stack className={S["share-button__popover"]}>
        {/* Header with Share and Public tabs */}
        <Stack className={S["share-button__header"]}>
          <Box
            component="span"
            className={`${S["share-button__header-item"]} ${
              selectedTab === "share"
                ? S["share-button__header-item--selected"]
                : ""
            }`}
            onClick={() => setSelectedTab("share")}
          >
            <Typography
              sx={{ font: "normal normal 300 14px/20px Source Serif Pro" }}
            >
              Share
            </Typography>
          </Box>
          <Box
            component="span"
            className={`${S["share-button__header-item"]} ${
              selectedTab === "public"
                ? S["share-button__header-item--selected"]
                : ""
            }`}
            onClick={() => setSelectedTab("public")}
            sx={{ marginLeft: "20px", marginRight: "5px" }}
          >
            <Typography
              sx={{ font: "normal normal 300 14px/20px Source Serif Pro" }}
            >
              Public
            </Typography>
          </Box>
          <SoonHorizontal />
        </Stack>

        {/* Middle section with search box and button */}
        <Stack p={"15px"}>
          <PersonDropdown
            selectedData={handleSelectedData}
            resetSelection={resetSelection}
            selectedOption={selectedOption}
          />
        </Stack>

        {/* Middle section where all shared content will appear */}
        <Stack className={S["share-button__scrollable-content"]}>
          {contentToDisplay?.map((item: any, index: number) => (
            <Stack
              key={index}
              sx={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <AvatarNamePlateSmall
                userName={
                  item?.userId
                    ? `${item?.userId?.firstName} ${item?.userId?.lastName}`
                    : ""
                }
                imgSrc={item?.userId?.avatar || ""}
                size="default"
                department={item?.userId?.department || "Unknown Department"}
                teamName={item?.userId?.teamName || "Unknown Team"}
              />
              <InternalSharedButton
                selectedOption={
                  item?.permissions === "edit" ? "Editor" : "Guest"
                }
                onSelectionChange={(value: any) => {
                  const apiValue = value === "Editor" ? "edit" : "view";
                  updateMembers && updateMembers(item, apiValue);
                }}
              />
            </Stack>
          ))}
        </Stack>
        {/* Footer with filter and copy link */}
        <Stack className={S["share-button__footer"]}>
          <ShareOptionDropdown />
          <span
            className={S["share-button__copy-link"]}
            onClick={handleCopyLinkClick}
          >
            <Tooltip placement="top" title={tooltipTitle}>
              <Stack direction={"row"} alignItems={"center"} gap={"5px"}>
                <ConnectedIcon w={15} />
                <Typography className={S["share-button__tooltip-text"]}>
                  Copy link
                </Typography>
              </Stack>
            </Tooltip>
          </span>
        </Stack>
      </Stack>
    </div>
  );
};

export default PopOverModel;
