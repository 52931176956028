import { Box, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  bearishOSOctaIcon,
  ClosedEyeIcon,
  EyeIcon,
  OutlookCalendarLogoPng,
} from "../../../../../images";
import { ControlsCalendar } from "../ControlsCalendar";
import {
  getIconPopupCalendarIcons,
  IconPopupCalendarIconsEnum,
} from "../IconPopupCalendar/IconPopupCalendarTypes";
import { Rotate90DegreesCcw } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { recallAPI, updateCalendarAppearance__api } from "../../../../../redux";

interface SidebarCalenderInfoCardProps {
  label: string;
  iconImgSrc: string;
  viewStatus: boolean;
  onEyeButtonClick: any;
  isAliveToken?: boolean;
  calColor?: string;
  _id?: string;
}

const SidebarCalenderInfoCard: React.FC<SidebarCalenderInfoCardProps> = ({
  iconImgSrc,
  label,
  onEyeButtonClick,
  viewStatus,
  isAliveToken = true,
  calColor = "#BF350F",
  _id,
}) => {
  const dispatch = useDispatch();
  const [eyeIconStatus, setEyeIconStatus] = useState<boolean>(viewStatus);

  const loginOutLook = async () => {
    dispatch(recallAPI("OutLookLogin"));
  };

  const handleUpdateAppearance = async (type: "icon" | "color", value: any) => {
    try {
      if (!value || !_id) {
        return;
      }

      const action = updateCalendarAppearance__api(_id, value, type);

      const updateRes: any = await dispatch(action);

      if (updateRes?.success) {
        dispatch(recallAPI("getAllCalEvent"));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleShareCalendarWithUser = async () => {};

  // Determine if the iconImgSrc is an SVG string
  return (
    <Stack
      sx={{
        height: "30px",
        width: "100%",
        borderRadius: "5px",
        flexDirection: "row",
        alignItems: "center",
        gap: "10px",
        flexShrink: 0,
        // ":hover": { bgcolor: "#E9EDF2" },
      }}
    >
      {iconImgSrc === "OutlookMS" ? (
        <Box
          component="img"
          src={OutlookCalendarLogoPng}
          alt="Outlook logo"
          sx={{ height: "20px", width: "20px" }}
        />
      ) : iconImgSrc !== "bearishOS" ? (
        React.createElement(
          getIconPopupCalendarIcons(iconImgSrc as IconPopupCalendarIconsEnum),
          {
            w: 18,
          }
        )
      ) : (
        <Box
          component="img"
          src={bearishOSOctaIcon}
          alt="logo"
          sx={{ height: "20px", width: "20px" }}
        />
      )}

      <Stack sx={{ flexGrow: 1, width: "calc(100% - 100px)" }}>
        <Typography
          sx={{
            font: "normal normal 300 14px/18px Source Serif Pro",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            color: isAliveToken ? "black" : "red !important",
          }}
        >
          {label}
        </Typography>
      </Stack>

      {!isAliveToken && (
        <IconButton
          onClick={() => {
            loginOutLook();
          }}
          sx={{ p: 0, borderRadius: 0 }}
          disableRipple
        >
          {<Rotate90DegreesCcw sx={{ width: "15px", height: "15px" }} />}
        </IconButton>
      )}

      <IconButton
        sx={{ p: 0, borderRadius: 0 }}
        disableRipple
        onClick={() => {
          setEyeIconStatus((pre) => !pre); // Toggle the eye icon status
          onEyeButtonClick(); // Call the function
        }}
      >
        {eyeIconStatus ? <EyeIcon w={15} /> : <ClosedEyeIcon w={15} />}
      </IconButton>

      <ControlsCalendar
        handleUpdateAppearance={handleUpdateAppearance}
        calColor={calColor}
        _id={_id}
      />
    </Stack>
  );
};

export default SidebarCalenderInfoCard;
