import { Stack, Typography } from "@mui/material";
import React from "react";
import { AvatarNormal } from "../../../../common";
import { BearishOSIcon, DummyImage } from "../../../../../images";
import { CrmStatusBadge } from "../../components/CrmStatusBadge";

interface HeaderViewProps {}

const HeaderView: React.FC<HeaderViewProps> = ({}) => {
  return (
    <Stack
      sx={{
        flexShrink: 0,
        padding: "3px 0px",
        flexDirection: "row",
        alignItems: "center",
        gap: "15px",
      }}
    >
      <AvatarNormal
        size={50}
        avatarColor="red"
        imgSrc={DummyImage}
        sx={{ borderRadius: "5px" }}
      />

      <Typography
        sx={{
          font: "normal normal 900 20px/25px Source Serif Pro",
          maxWidth: "600px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        user name
      </Typography>

      <BearishOSIcon w={25} />
      <CrmStatusBadge variant="active" />
    </Stack>
  );
};

export default HeaderView;
