import React from "react";
import { Box, Typography } from "@mui/material";
import { NoTaskHappy } from "../../../../../images";

type Props = {};

const NoTask = (props: Props) => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        textAlign: "center",
        color: "#555",
        padding: 2,
        width: "100%",
      }}
    >
      <img
        src={NoTaskHappy}
        alt="No Tasks Found"
        style={{ width: "330px", marginBottom: "16px", marginTop: "2rem" }}
      />
      <Typography variant="h4" component="h2" sx={{ margin: "0 0 8px", fontFamily:"Source Serif Pro", fontWeight:"600" }}>
        No Tasks? No Problem! 😊
      </Typography>
      <Typography variant="h6" component="p" sx={{ margin: 0, fontFamily:"Source Serif Pro",  fontWeight:"500" }}>
        Every journey starts somewhere. Create your first task and watch your
        productivity soar! 🚀
      </Typography>
    </Box>
  );
};

export default NoTask;
