import React, { useEffect, useState } from "react";
import { IconDropDownButton } from "../../../IconDropDownButton";
import { Menu, MenuItem, Typography } from "@mui/material";
import { DragAndDropMenuListType } from "../../MenuTypes";
import { useTranslation } from "react-i18next";

interface ValueDropdownProps {
  options: DragAndDropMenuListType[];
  initialValue?: DragAndDropMenuListType;
  onSelect: (selectedValue: DragAndDropMenuListType) => void;
  width?: string;
}

const ValueDropdown: React.FC<ValueDropdownProps> = ({
  initialValue,
  onSelect,
  options,
  width = "125px",
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [listOption, setListOption] = useState<DragAndDropMenuListType[]>([]);
  const [selectedValue, setSelectedValue] = useState<
    DragAndDropMenuListType | undefined
  >(initialValue);

  useEffect(() => {
    // Filter out empty or undefined options
    const filteredOptions = options.filter(
      (item) => item && item.label && item.label.trim() !== ""
    );

    // If initialValue exists and is non-empty, ensure it's added to the list
    if (
      initialValue &&
      initialValue.label &&
      initialValue.label.trim() !== ""
    ) {
      setSelectedValue(initialValue);
      setListOption([initialValue, ...filteredOptions]);
    } else {
      setListOption(filteredOptions);
    }
  }, [initialValue, options]);

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget); // Open the menu at the button's position
  };

  const handleMenuClose = () => {
    setAnchorEl(null); // Close the menu
  };

  const handleMenuSelect = (value: DragAndDropMenuListType) => {
    setSelectedValue(value);
    setAnchorEl(null); // Close the menu after selection
    onSelect(value); // Trigger the onSelect callback with the selected value
  };

  const isMenuOpen = Boolean(anchorEl); // Check if the menu is open

  return (
    <div>
      <IconDropDownButton
        label={
          (selectedValue &&
            selectedValue.label &&
            selectedValue.translatedWord &&
            t(selectedValue.translatedWord)) ||
          selectedValue?.label ||
          ""
        }
        icon={selectedValue && selectedValue.icon}
        sx={{ width: width }}
        menuOpen={isMenuOpen}
        onButtonClick={handleButtonClick} // Open the MUI Menu
      />

      {/* MUI Menu */}
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            padding: 0,
            marginTop: "10px",
            zIndex: 110,
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.16)",
            borderRadius: "5px",
            maxHeight: "400px",
            overflow: "auto",
            scrollbarWidth: "none",
          },
        }}
      >
        {listOption.map((item, index) => (
          <MenuItem
            selected={item.label === selectedValue?.label}
            key={index}
            onClick={() => handleMenuSelect(item)}
            sx={{
              minHeight: "30px !important",
              maxHeight: "30px",
              padding: "0px 10px",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              height: "30px",
              boxSizing: "border-box",
            }}
          >
            {item.icon &&
              React.createElement(item.icon, { w: 15, color: "black" })}
            <Typography
              sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
            >
              {item.translatedWord ? t(item.translatedWord) : item.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ValueDropdown;
