import { Box, Stack } from "@mui/material";
import React from "react";
import { EmailIcon } from "../../../../../../../../images";
import { TouchPointVariantEnum, TouchPointViewProps } from "../TouchPointsType";

type OtherEmailsInnerViewProps = Extract<
  TouchPointViewProps,
  { variant: TouchPointVariantEnum.Email }
>;

const OtherEmailsInnerView: React.FC<OtherEmailsInnerViewProps> = ({
  emailMsg,
  emailSubject,
  timeZone,
  timestamp,
}) => {
  return (
    <Stack sx={{ flexDirection: "row", alignItems: "start", gap: "15px" }}>
      <Box sx={{ flexShrink: 0 }}>
        <EmailIcon w={15} />
      </Box>
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "4px",
          flexWrap: "wrap",
          font: "normal normal 300 13px/16px Source Serif Pro",
        }}
      >
        <span>{emailSubject}</span>
        <span>sent</span>
        <span>on</span>
        <span>{timestamp}</span>
        <span>{timeZone}</span>

        <span>{emailMsg}</span>
      </Stack>
    </Stack>
  );
};

export default OtherEmailsInnerView;
