import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { ReplyIcon } from "../../../images";
import { getHTMLContent } from "../../pages/Message/constant";

interface MessageReplyBarView {
  message?: string;
  handleEmojiSelect?: any;
  handleSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleClick?: () => void;
  replyMessage?: string;
  handleCancelReply?: () => void;
}

const MessageReplyBarView: React.FC<MessageReplyBarView> = ({
  replyMessage,
  handleCancelReply,
}) => {

  return (
    <Box display="flex" flexDirection="column" width="100%">
      {/* Replying to section */}
      {replyMessage && (
        <Box
          sx={{
            backgroundColor: "#f4f6fa", // Light background for reply section
            borderLeft: "4px solid #775EE2", // Add left border
            padding: "8px 16px", // Padding for the section
            marginBottom: "10px", // Space between reply section and input
            borderRadius: "4px", // Rounded corners
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: "bold",
              color: "#3C4043", // Darker color for the text
            }}
          >
            <Box><ReplyIcon w={15} />{" "}</Box>
            
            <Typography component="span" sx={{ fontStyle: "italic" }}>
            <div
              dangerouslySetInnerHTML={{ __html: getHTMLContent(replyMessage) }}
            />
            </Typography>
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default MessageReplyBarView;
