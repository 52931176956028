import { Box, Stack, Typography } from "@mui/material"; // Import Typography for text display
import React, { useEffect, useState } from "react";
import {
  AddCommentView,
  SavedView as DisplayComment,
} from "../../pages/Workspace/components/CommentCard/view";
import FroalalComment from "../../pages/Workspace/components/CommentCard/froalalComment/FroalalComment";
import ManageCommentButtons from "../../pages/Workspace/components/CommentCard/view/SavedView/ManageCommentButtons";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  addCommentToDocument__api,
  getAllComments__api,
} from "../../../redux/Slice/comments";

interface CommentProps {
  parentPageId?: any;
}

const Comments: React.FC<CommentProps> = ({ parentPageId = "" }) => {
  const dispatch = useDispatch();
  const { workspaceId, componentId } = useParams<{
    workspaceId: string;
    componentId: string;
  }>();

  const [isReplying, setIsReplying] = useState(false);
  const [replyContent, setReplyContent] = useState("");
  const [allCommentData, setAllCommentData] = useState<any[]>([]); // Array to hold comments
  const [noCommentsMessage, setNoCommentsMessage] = useState<string | null>(
    null
  ); // State for no comments message

  const handleButtonClick = (label: string) => {
    if (label === "Save") {
      addCommentToDocument(replyContent);
    } else if (label === "Cancel") {
      setReplyContent("");
      setIsReplying(false);
    }
  };

  const getAllCommentsOfDocument = async () => {
    if (!workspaceId || !componentId) {
      return;
    }
    try {
      const action = getAllComments__api(
        parentPageId ? parentPageId : componentId,
        "document"
      );
      const allComments: any = await dispatch(action);

      if (allComments?.success) {
        if (
          allComments?.data &&
          Object?.keys(allComments?.data)?.length === 0
        ) {
          // If data is an empty object
          setNoCommentsMessage("No comments found"); // Set no comments message
          setAllCommentData([]); // Reset to empty array
        } else {
          setAllCommentData(allComments.data || []); // Set state to comments or empty array
          setNoCommentsMessage(null); // Clear no comments message
        }
      } else {
        console.warn("Failed to fetch comments:", allComments);
        setNoCommentsMessage("Failed to fetch comments"); // Set error message
        setAllCommentData([]); // Reset to empty array
      }
    } catch (error) {
      console.error("Failed to fetch comments:", error);
      setNoCommentsMessage("Error fetching comments"); // Set error message
      setAllCommentData([]); // Reset to empty array
    }
  };

  const addCommentToDocument = async (replyContent: string) => {
    if (!componentId || !replyContent) {
      return;
    }

    const payload = {
      documentId: componentId,
      content: replyContent,
      mentions: [],
    };

    const action = addCommentToDocument__api(payload);
    await dispatch(action);

    setIsReplying(false);
    setReplyContent("");
    await getAllCommentsOfDocument(); // Refresh comments after adding a new one
  };

  useEffect(() => {
    getAllCommentsOfDocument();
  }, [workspaceId, componentId]); // Depend on workspaceId and componentId

  return (
    <Box
      sx={{
        minWidth: "26.6%",
        maxWidth: "330px",
        height: "100%",
        maxHeight: "80%",
        overflow: "hidden",
        overflowX: "hidden",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          width: "0 !important", // hide scrollbar on Chrome, Safari, etc.
        },
        scrollbarWidth: "none", // hide scrollbar on Firefox
      }}
    >
      {isReplying ? (
        <Stack
          direction={"row"}
          alignItems={"flex-start"}
          gap={"3px"}
          ml={"5px"}
          position={"relative"}
          justifyContent={"flex-start"}
        >
          <FroalalComment
            content={replyContent}
            handleContentChange={(e: any) => setReplyContent(e)} // Update reply content as user types
          />
          <Stack
            zIndex={10009}
            position={"absolute"}
            bottom={"55px"}
            right={"10px"}
          >
            <ManageCommentButtons
              onButtonClick={handleButtonClick} // Handle Save or Cancel actions
              mode="editMode"
            />
          </Stack>
        </Stack>
      ) : (
        <AddCommentView onClickbox={() => setIsReplying(true)} />
      )}

      {/* Display saved comments */}
      <Stack mt={"20px"} direction={"column"} gap={2}>
        {noCommentsMessage ? (
          <Typography variant="body1" color="textSecondary">
            {noCommentsMessage}
          </Typography>
        ) : (
          allCommentData?.map((comment) => (
            <DisplayComment
              getAllCommentsOfDocument={getAllCommentsOfDocument}
              key={comment?._id}
              commentDatas={comment}
            />
          ))
        )}
      </Stack>
    </Box>
  );
};

export default Comments;
