import React from "react";
import { getUser } from "../../../../../Utils";
import {  BearishOSIcon } from "../../../../../images";
import { Box, Button, Container, Typography } from "@mui/material";

type Props = {};

const BebaAI = (props: Props) => {
  const user = getUser();

  const handleNotifyClick = () => {
    alert("You will be notified when BebaAI goes live!");
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundColor: "#f7f9fc",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          maxWidth: 500,
          width: "100%",
          padding: 4,
          backgroundColor: "#fff",
          borderRadius: 8,
          boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.1)",
          transition: "box-shadow 0.3s ease, transform 0.3s ease",
          "&:hover": {
            boxShadow: "0px 15px 40px rgba(0, 0, 0, 0.2)",
            transform: "translateY(-10px)",
          },
        }}
      >
          <BearishOSIcon w={70} />
        <Typography
          variant="h4"
          color="primary"
          fontFamily="Source Serif Pro"
          sx={{
            fontWeight: 700,
            marginBottom: 2,
            fontSize: "1.8rem",
          }}
        >
          BebaAI Coming Soon
        </Typography>

        <Typography
          variant="body1"
          color="text.secondary"
          fontFamily="Source Serif Pro"
          sx={{
            fontSize: "1.1rem",
            marginBottom: 3,
            lineHeight: 1.8,
            maxWidth: "400px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          BebaAI is currently under development. Sign up to be notified when
          it's live and ready for you!
        </Typography>

        {user?.data?.email && (
          <Typography
            variant="body2"
            color="text.secondary"
            fontFamily="Source Serif Pro"
            sx={{
              marginBottom: 3,
              fontSize: "1rem",
              fontStyle: "italic",
            }}
          >
            Logged in as: {user.data.email}
          </Typography>
        )}

        <Button
          variant="contained"
          color="primary"
          onClick={handleNotifyClick}
          sx={{
            width: "100%",
            padding: "14px 24px",
            fontFamily: "Source Serif Pro",
            fontWeight: "600",
            borderRadius: "30px",
            textTransform: "none",
            fontSize: "1rem",
            transition:
              "background-color 0.3s, transform 0.3s, box-shadow 0.3s",
            "&:hover": {
              backgroundColor: "#2c4170",
              transform: "scale(1.05)",
              boxShadow: "0px 12px 30px rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          Notify Me When Live
        </Button>
      </Box>
    </Container>
  );
};

export default BebaAI;
