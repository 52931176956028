import React from "react";
import { EmailCardLong } from "../EmailCardLong";
import { EmailCardSquare } from "../EmailCardSquare";
import { EmailFilterChipsVariantEnum } from "../EmailFilterChips/EmailFilterChipsTypes";
import { Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../../../redux/middleware/apiMiddleware";
import { EmailData } from "../EmailTypes/EmailTypes";
import { getAccessToken, getToken } from "../../../../common/LocalStorage";

type Props = {
  viewMode?: string;
  emails: EmailData[] | null;
  setViewMode?: any;
};
const EmailCardManager = ({
  viewMode = "square",
  emails,
  setViewMode,
}: Props) => {
  const navigate = useNavigate();
  const token = getToken();
  const accessToken = getAccessToken();
  const { emailId } = useParams();
  const handleOpenBody = async (messageId: string, index: number) => {
    if (viewMode !== "square") {
      setViewMode("square");
    }

    navigate(`/Email/${messageId}/${index + 1}`);
  };

  const handleAddStar = async (messageId: string) => {
    try {
      const res = await axios.put(
        `${baseURL}/email/star-toggle`,
        { accessToken: accessToken, messageId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };


  return (
    <Stack
      direction={viewMode === "square" ? "row" : "column"}
      alignItems={"center"}
      flexWrap={viewMode === "square" ? "wrap" : "unset"}
      justifyContent={viewMode === "square" ? "center" : "flex-start"}
      gap={!emailId ? (viewMode === "square" ? "0px" : "0px") : "0px"}
    >
      {emails?.map((email: EmailData, index: number) => (
        <React.Fragment key={index}>
          {viewMode !== "square" ? (
            <EmailCardLong
              email={email}
              isClicked={email.isClicked}
              isSelected={email?.id === emailId}
              handleOpenBody={(messageId: string) =>
                handleOpenBody(messageId, index)
              }
              isRead={email?.isRead}
              handleAddStar={(messageId: string) => handleAddStar(messageId)}
            />
          ) : (
            <EmailCardSquare
              email={email}
              isClicked={email.isClicked}
              isSelected={email?.id === emailId}
              handleOpenBody={(messageId: string) =>
                handleOpenBody(messageId, index)
              }
              isRead={email?.isRead}
              handleAddStar={(messageId: string) => handleAddStar(messageId)}
            />
          )}
        </React.Fragment>
      ))}
    </Stack>
  );
};

export default EmailCardManager;
