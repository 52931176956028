import { Stack, Typography, Tooltip } from "@mui/material";
import React from "react";

interface User {
  id: string;
  firstName: string;
  lastName: string;
  avatar: string;
}

interface EmojiViewProps {
  emoji: string;
  userList: User[];
}

const EmojiView: React.FC<EmojiViewProps> = ({ emoji = "🔥", userList }) => {
  // Create a string of user names for the tooltip
  const userNames = userList
    ?.map((user) => `${user?.firstName} ${user?.lastName}`)
    ?.join(", ");

  return (
    <Tooltip title={userNames} arrow>
      <Stack
        sx={{
          flexDirection: "row",
          gap: "5px",
          boxSizing: "border-box",
          alignItems: "center",
          height: "15px",
          cursor: "pointer", // Add pointer cursor to indicate hover
        }}
      >
        <span style={{ fontSize: "12px" }}>{emoji}</span>
        <Typography sx={{ font: "normal normal 400 11px/14px Inter" }}>
          {userList?.length} {/* Display the number of users who reacted */}
        </Typography>
      </Stack>
    </Tooltip>
  );
};

export default EmojiView;
