import { Box, Typography } from "@mui/material";
import React from "react";
import g from "./TotalCRM.module.css";

type TotalCRMProps = {
  label: string;
  labelColor?: "crmOrange" | "traxBlue";
  totalCount: number;
};

const TotalCRM: React.FC<TotalCRMProps> = ({
  label,
  totalCount,
  labelColor = "crmOrange",
}) => {
  const getLabelColor = (): string => {
    switch (labelColor) {
      case "crmOrange":
        return "#E2875E";
      case "traxBlue":
        return "#00767B";
      default:
        return "#E2875E";
    }
  };

  return (
    <Box className={g.boxTotalCRM}>
      <Typography
        className={g.boxText1TotalCRM}
        children={label}
        sx={{ color: getLabelColor() }}
      />
      <Typography
        className={g.boxText2TotalCRM}
        children={totalCount?.toString()}
      />
    </Box>
  );
};

export default TotalCRM;
