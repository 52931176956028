import { Box, Stack } from "@mui/material";
import React from "react";
import { CheckedCircleIcon, EmailIcon } from "../../../../../../../../images";
import { UserInfoChip } from "../../../../EmailCardComponents";
import { TouchPointVariantEnum, TouchPointViewProps } from "../TouchPointsType";

type TasksInnerViewProps = Extract<
  TouchPointViewProps,
  { variant: TouchPointVariantEnum.Task }
>;

const TasksInnerView: React.FC<TasksInnerViewProps> = ({
  dueTime,
  taskCreatorInfo,
  taskName,
  timeZone,
  timestamp,
}) => {
  return (
    <Stack sx={{ flexDirection: "row", alignItems: "start", gap: "15px" }}>
      <Box sx={{ flexShrink: 0 }}>
        <CheckedCircleIcon w={15} />
      </Box>
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "4px",
          flexWrap: "wrap",
          font: "normal normal 300 13px/16px Source Serif Pro",
        }}
      >
        <span>Task created by</span>
        <UserInfoChip
          popoverPlace="bottom"
          showOnlyName
          fontSx={{ font: "inherit" }}
          userName={taskCreatorInfo.userName}
          userFirstName={taskCreatorInfo.userName}
          userEmail={taskCreatorInfo.userEmail}
          userIconSrc={taskCreatorInfo.userIconSrc}
          userAvatarColor={taskCreatorInfo.userAvatarColor}
        />

        <span>{taskName}</span>

        <span>on</span>
        <span>{timestamp}</span>
        <span>{timeZone}</span>

        <span>due</span>
        <span>on</span>
        <span>{dueTime}</span>
      </Stack>
    </Stack>
  );
};

export default TasksInnerView;
