import React from "react";
import {
  AnalyticsIcon,
  BearishOSIcon,
  ClockIcon,
  ConnectedIcon,
  CopyIcon,
  CustomizeIcon,
  DeleteIcon,
  ExportIcon,
  LockIcon,
  ProjectIcon,
  RightArrowIcon,
  UndoIcon,
} from "../../../images";

type RegularMenuItem = {
  text: string;
  icon: React.ElementType; // Accepts React components
  iconProps?: {
    color?: string;
    w?: number;
  }; // Optional icon properties
  action?:any;
  isTextOption?: boolean;
  divider?: false;
};

type DividerMenuItem = {
  divider: true;
  text?: undefined;
  icon?: undefined;
  action?: undefined;
  isTextOption?: undefined;
};

export type ThreeDotLargeMenuItemProps = RegularMenuItem | DividerMenuItem;

export const ThreeDotLargeMenuList: ThreeDotLargeMenuItemProps[] = [
  {
    text: "Lock page",
    icon: LockIcon,
    action: () => console.log("Lock page clicked"),
  },
  {
    text: "Copy link",
    icon: ConnectedIcon,
    action: () => console.log("Copy link clicked"),
  },
  {
    text: "Duplicate",
    icon: CopyIcon,
    action: () => console.log("Duplicate clicked"),
  },
  {
    text: "Move to",
    icon: RightArrowIcon,
    action: () => console.log("Move to clicked"),
  },
  {
    text: "Delete",
    icon: DeleteIcon,
    action: () => console.log("Delete clicked"),
    iconProps: { color: "#FF4500" },
  },
  { divider: true },
  {
    text: "Undo",
    icon: UndoIcon,
    action: () => console.log("Undo clicked"),
  },
  {
    text: "View edit history",
    icon: ClockIcon,
    action: () => console.log("View edit history clicked"),
  },
  {
    text: "View analytics",
    icon: AnalyticsIcon,
    action: () => console.log("View analytics clicked"),
  },
  {
    text: "Export",
    icon: ExportIcon,
    action: () => console.log("Export clicked"),
  },
  { divider: true },
  {
    text: "Open Bearish OS app",
    icon: BearishOSIcon,
    action: () => console.log("Open Bearish OS app clicked"),
  },
  {
    text: "Connect to project",
    icon: ProjectIcon,
    iconProps: { color: "black", w: 15 },
    action: () => console.log("Connect to project clicked"),
  },
  {
    text: "Customize page",
    icon: CustomizeIcon,
    iconProps: { color: "black", w: 15 },
    action: () => console.log("Customize page clicked"),
  },
];

interface HeaderWithText {
  textOrIcon: React.ElementType | string;
  textStyle?: { font: string };
  title: string;
  titleStyle: { font: string };
}

export type ThreeDotLargeMenuHeaderOptionsInterface = HeaderWithText;

export const ThreeDotLargeMenuHeaderOptions: ThreeDotLargeMenuHeaderOptionsInterface[] =
  [
    {
      textOrIcon: "Aa",
      textStyle: { font: "normal normal 600 25px/34px Source Serif Pro" },
      title: "Default",
      titleStyle: { font: "normal normal 300 14px/20px Source Serif Pro" },
    },
    {
      textOrIcon: "Aa",
      textStyle: { font: "normal normal bold 25px/30px Averia Serif Libre" },
      title: "Averia",
      titleStyle: { font: "normal normal 300 14px/16px Averia Serif Libre" },
    },
    {
      textOrIcon: "Aa",
      textStyle: { font: "normal normal normal 25px/34px Avenir Next" },
      title: "Next",
      titleStyle: { font: "normal normal normal 14px/19px Avenir Next" },
    },
  ];
