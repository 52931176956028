import { Stack, Typography } from "@mui/material";
import React from "react";
import { AvatarNormal } from "../../../../../../common";

interface InfoViewProps {
  userName: string;
  userEmail: string;
  userImgSrc?: string;
  avatarColor?: string;
}

const InfoView: React.FC<InfoViewProps> = ({
  userEmail,
  userName,
  avatarColor,
  userImgSrc,
}) => {
  return (
    <Stack
      sx={{
        gap: "10px",
        flexDirection: "row",
        alignItems: "start",
      }}
    >
      <AvatarNormal
        size={30}
        username={userName}
        avatarColor={avatarColor}
        imgSrc={userImgSrc}
      />
      <Stack sx={{ flexDirection: "column", gap: "8px", paddingTop: "4px" }}>
        <Typography
          sx={{ font: "normal normal 900 15px/19px Source Serif Pro" }}
        >
          {userName}
        </Typography>
        <Typography
          sx={{ font: "normal normal 300 11px/14px Source Serif Pro" }}
        >
          {userEmail}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default InfoView;
