import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Box, Stack, Zoom } from "@mui/material";
import { WelcomeWalkthrough } from "../../common";
import { cookieDomain, getUser, isAuthenticated } from "../../../Utils";
import { dashboaedPNG } from "../../../images";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { clearAllStorage } from "../../../redux/middleware/apiMiddleware";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { reLoginUserDetails } from "../../../redux/Slice/auth/authActions";

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const authState = useSelector((state: RootState) => state && state.auth);

  const [showWelcome, setShowWelcome] = useState(() => {
    const showWelcomeLocalStorage = localStorage.getItem("WelcomeWalkthrough");
    return !showWelcomeLocalStorage || showWelcomeLocalStorage === "true";
  });

  const auth = useMemo(() => isAuthenticated(), []);
  const user = useMemo(() => getUser(authState), [authState]);

  const token = useMemo(() => localStorage.getItem("token"), []);
  const userId = useMemo(() => localStorage.getItem("user_id"), []);

  const userFirstName = useMemo(() => {
    if (!auth || !user?.data) return "";
    const { firstName = "", lastName = "" } = user?.data;
    return `${firstName} ${lastName}`?.trim();
  }, [auth, user]);

  const handleReLogin = useCallback(() => {
    if (!authState?.isAuthenticated && !authState?.user && token && userId) {
      try {
        dispatch(reLoginUserDetails(userId));
      } catch (error) {
        console.error("Error during re-login:", error);
      }
    }
  }, [authState, token, userId, dispatch]);

  useEffect(() => {
    handleReLogin();
  }, [handleReLogin]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.get("f") === "1") {
      clearAllStorage();

      const redirectUrl = `http://os${
        cookieDomain === ".localtest.me" ? ".localtest.me:3000" : cookieDomain
      }/login?f=success`;

      window.location.replace(redirectUrl);
    }
  }, [location]);

  const styles: any = {
    heading: {
      fontWeight: 600,
      fontSize: "28px",
      textAlign: "center",
      fontFamily: "Source Serif Pro",
    },
    paragraph: {
      fontWeight: 300,
      fontSize: "18px",
      textAlign: "center",
      fontFamily: "Source Serif Pro",
    },
  };

  return (
    <Box>
      <Stack
        direction="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <img
          style={{ width: "650px", height: "400px" }}
          src={dashboaedPNG}
          alt="dashboard"
        />
        <Stack alignItems="center" justifyContent="center" gap={2}>
          <div style={styles.heading}>{t(`DASHBOARD.GET_READY`)}</div>
          <div
            style={styles.paragraph}
            dangerouslySetInnerHTML={{ __html: t("DASHBOARD.GET_READY_PARA") }}
          />
        </Stack>
      </Stack>

      {/* Welcome Walkthrough */}
      <Stack position="absolute" bottom="30px" right="30px">
        <Zoom
          in={showWelcome}
          style={{ transitionDelay: showWelcome ? "500ms" : "0ms" }}
        >
          <div>
            <WelcomeWalkthrough
              setShowWelcome={setShowWelcome}
              userFirstName={userFirstName}
            />
          </div>
        </Zoom>
      </Stack>
    </Box>
  );
};

export default Dashboard;
