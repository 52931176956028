import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
    auth: {
        clientId: '4e0e2079-971b-447b-b046-96cc8743b125', // Replace with your Azure AD App's clientId
        authority: 'https://login.microsoftonline.com/common', // Set to 'common' for multi-tenant apps
        // authority: `https://login.microsoftonline.com/3b4f9c52-8880-4e10-b868-b70c9811dbc0`, // Replace with your tenant ID
        redirectUri: 'https://os.bearishos.com' // URL where the user will be redirected after login
        //  redirectUri: 'http://localhost:3000' // URL where the user will be redirected after login
    },
    cache: {
        cacheLocation: 'localStorage', // For SPA, using localStorage is generally preferred
        storeAuthStateInCookie: false, // Set to false for SPAs
    }
};

// Create MSAL instance
const msalInstance = new PublicClientApplication(msalConfig);

// Properly initialize MSAL (only use handleRedirectPromise for redirect flows)
const initializeMsal = async () => {
  try {
    await msalInstance.handleRedirectPromise(); // Ensures MSAL is initialized properly
  } catch (error) {
    console.error("MSAL initialization error:", error);
  }
};

// Export the instance and initialization method
export { msalInstance, initializeMsal };
