import { IconButton, Popover, Stack } from "@mui/material";
import React, { useState } from "react";
import { CopyIcon } from "../../../../../../../images";
import { DaySelection } from "../../../DaySelection";
import { WeekDaysEnum } from "../../../../../../../Utils";

interface CopyTimeViewProps {
  onIconClick?: () => void;
  selectedDays?: (dayList: WeekDaysEnum[]) => void;
}

const CopyTimeView: React.FC<CopyTimeViewProps> = ({
  selectedDays,
  onIconClick,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selected, setSelected] = useState<WeekDaysEnum[]>([]);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (onIconClick) {
      onIconClick();
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    if (selectedDays && selected.length > 0) {
      selectedDays(selected);
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Stack>
      <IconButton
        sx={{ p: 0 }}
        disableRipple
        aria-describedby={id}
        onClick={handleClick}
      >
        <CopyIcon w={15} />
      </IconButton>

      {/* Popover */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: { boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)" },
        }}
      >
        <DaySelection onSelect={(value) => setSelected(value)} />
      </Popover>
    </Stack>
  );
};

export default CopyTimeView;
