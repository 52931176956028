import React, { useRef, useState } from "react";
import { IconButton, Stack, Typography, Skeleton } from "@mui/material";
import d from "../../../../../../images/svgs/callLogNext.svg";
import a from "../../../../../../images/svgs/callLogPrevious.svg";
import c from "../../../../../../images/svgs/callLogPlay.svg";
import b from "../../../../../../images/svgs/callLogPause.svg";
import e from "../../../../../../images/svgs/12079894_4906331.svg";

const VideoPlayer: React.FC<{ path: string }> = ({ path }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [loader, setLoader] = useState(false);

  const handlePlay = async () => {
    if (videoRef.current) {
      try {
        await videoRef.current.play();
        setIsPlaying(true);
      } catch (error) {
        console.error("Error attempting to play video:", error);
      }
    }
  };

  const handlePause = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleFastForward = () => {
    if (videoRef.current) {
      videoRef.current.currentTime += 5;
    }
  };

  const handleBack = () => {
    if (videoRef.current) {
      videoRef.current.currentTime -= 5;
    }
  };


  return (
    <Stack direction="column" alignItems="center">
      {loader ? (
        <Skeleton variant="rounded" width={775} height={525} />
      ) : path ? (
        <video
          ref={videoRef}
          width="775px"
          height="525px"
          onLoadedData={() => setLoader(false)}
          controls
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        >
          <source src={path} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <Stack
          width="775px"
          height="525px"
          border="3px solid #e3e3e3"
          borderRadius="5px"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            sx={{
              color: "red",
              fontSize: "20px",
              fontStyle: "italic",
              fontWeight: "600",
              fontFamily: "Source Serif Pro",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img src={e} width="500px" alt="" />
            Recording not available
          </Typography>
        </Stack>
      )}

      <Stack direction="row" gap="20px" mt="20px">
        <IconButton sx={{backgroundColor:"orange"}} onClick={handleBack}>
          <img src={a} width="25px" height="25px" alt="Rewind" />
        </IconButton>
        <IconButton sx={{backgroundColor:"orange"}} onClick={handlePause} disabled={!isPlaying}>
          <img src={b} width="25px" height="25px" alt="Pause" />
        </IconButton>
        <IconButton sx={{backgroundColor:"orange"}} onClick={handlePlay} disabled={isPlaying}>
          <img src={c} width="25px" height="25px" alt="Play" />
        </IconButton>
        <IconButton sx={{backgroundColor:"orange"}} onClick={handleFastForward}>
          <img src={d} width="25px" height="25px" alt="Fast Forward" />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default VideoPlayer;
