import { createSlice } from "@reduxjs/toolkit";
import { MessagesState } from "./messageTypes";
import {
  addReactionToMessageFailure,
  addReactionToMessageRequest,
  addReactionToMessageSuccess,
  changeMessagesActivitiesStatusFailure,
  changeMessagesActivitiesStatusRequest,
  changeMessagesActivitiesStatusSuccess,
  changeParticipantsRoleFailure,
  changeParticipantsRoleRequest,
  changeParticipantsRoleSuccess,
  createNewConnectionFailure,
  createNewConnectionRequest,
  createNewConnectionSuccess,
  forwardMessageFailure,
  forwardMessageRequest,
  forwardMessageSuccess,
  getChannelActivitiesFailure,
  getChannelActivitiesRequest,
  getChannelActivitiesSuccess,
  /* User */
  getChannelMessageFailure,
  getChannelMessageRequest,
  getChannelMessageSuccess,
  getExternalConnectionsFailure,
  getExternalConnectionsRequest,
  getExternalConnectionsSuccess,
  getMessageActivitiesFailure,
  getMessageActivitiesRequest,
  getMessageActivitiesSuccess,
  getSlackUserProfileFailure,
  getSlackUserProfileRequest,
  getSlackUserProfileSuccess,
  getSlackUsersFailure,
  getSlackUsersRequest,
  getSlackUsersSuccess,
  getTeamsUserProfileFailure,
  getTeamsUserProfileRequest,
  getTeamsUserProfileSuccess,
  getUserConnectionsFailure,
  getUserConnectionsRequest,
  getUserConnectionsSuccess,
  markMessagesAsReadFailure,
  markMessagesAsReadRequest,
  markMessagesAsReadSuccess,
  removeReactionToMessageFailure,
  removeReactionToMessageRequest,
  removeReactionToMessageSuccess,
  scheduleMessageFailure,
  scheduleMessageRequest,
  scheduleMessageSuccess,
  sendMessageFailure,
  sendMessageRequest,
  sendMessageSuccess,
  setSnoozeMessagesActivitiesFailure,
  setSnoozeMessagesActivitiesRequest,
  setSnoozeMessagesActivitiesSuccess,
  updateConnectionFailure,
  updateConnectionRequest,
  updateConnectionSuccess,
  updateMessageFailure,
  updateMessageRequest,
  updateMessageSuccess,
} from "./MessageSliceActions";
import dayjs from "dayjs";

/*
 * ==============================================
 * Define the initial state for the message slice
 * ==============================================
 */
interface Channel {
  _id: string;
  createdAt: string;
  lastMessageDate?: string;
  lastMessageId?: string;
  lastMessageContent?: string;
}

const initialState: MessagesState = {
  messages: {}, // Array to store messages
  channels: {}, // Array to store channel information
  groups: [], // Array to store group information
  currentChannelId: null, // Currently selected channel ID (if any)
  currentGroupId: null, // Currently selected group ID (if any)
  currentUserId: null, // Currently logged-in user ID (if any)
  channelActivity:null, // Channel activity
  currentChannelName: null, // Currently selected
  loading: false, // Loading state
  error: null, // Error state
  currentChannel: {},
  editMessageContent: {},
  replyMessageContent: {},
  slackUsers: [],
  teamsUsers: [],
  messageActivity: [],
  externalChannels: {},
  slackUserProfile: {},
  teamsUserProfile: {},
};

/*
 * ==============================================
 * Create a slice for Fetch Messages
 * ==============================================
 */
const messageSlice = createSlice({
  name: "message", // Name of the slice
  initialState, // Initial state of the slice

  reducers: {
    // Reducers to handle different actions
    getChannelMessageRequest,
    getChannelMessageSuccess,
    getChannelMessageFailure,

    sendMessageRequest,
    sendMessageSuccess,
    sendMessageFailure,

    getUserConnectionsRequest,
    getUserConnectionsSuccess,
    getUserConnectionsFailure,
    getExternalConnectionsRequest,
    getExternalConnectionsSuccess,
    getExternalConnectionsFailure,

    createNewConnectionRequest,
    createNewConnectionSuccess,
    createNewConnectionFailure,

    updateConnectionFailure,
    updateConnectionSuccess,
    updateConnectionRequest,

    updateMessageFailure,
    updateMessageSuccess,
    updateMessageRequest,

    scheduleMessageFailure,
    scheduleMessageSuccess,
    scheduleMessageRequest,

    forwardMessageFailure,
    forwardMessageSuccess,
    forwardMessageRequest,

    addReactionToMessageFailure,
    addReactionToMessageSuccess,
    addReactionToMessageRequest,

    removeReactionToMessageFailure,
    removeReactionToMessageSuccess,
    removeReactionToMessageRequest,

    markMessagesAsReadFailure,
    markMessagesAsReadSuccess,
    markMessagesAsReadRequest,

    changeParticipantsRoleFailure,
    changeParticipantsRoleSuccess,
    changeParticipantsRoleRequest,

    getChannelActivitiesRequest,
    getChannelActivitiesSuccess,
    getChannelActivitiesFailure,

    getMessageActivitiesRequest,
    getMessageActivitiesSuccess,
    getMessageActivitiesFailure,

    changeMessagesActivitiesStatusRequest,
    changeMessagesActivitiesStatusSuccess,
    changeMessagesActivitiesStatusFailure,

    setSnoozeMessagesActivitiesRequest,
    setSnoozeMessagesActivitiesSuccess,
    setSnoozeMessagesActivitiesFailure,

    /* get slack users */
    getSlackUsersRequest,
    getSlackUsersSuccess,
    getSlackUsersFailure,

    /* get slack users */
    getSlackUserProfileRequest,
    getSlackUserProfileSuccess,
    getSlackUserProfileFailure,
    /* get slack users */
    getTeamsUserProfileRequest,
    getTeamsUserProfileSuccess,
    getTeamsUserProfileFailure,

    addMessage(state, action) {
      const { message } = action.payload;
      if (message?._id) {
        state.messages[message._id] = message;
      }
    },

    addChannelId(state, action) {
      //@ts-ignore
      state.currentChannelId = action.payload;
    },
    addChannelName(state, action) {
      //@ts-ignore
      state.currentChannelName = action.payload;
    },

    addChannel(state, action) {
      const channel = action.payload;
      state.currentChannel = channel;
    },

    addEditMessageContent(state, action) {
      state.editMessageContent = action.payload;
    },

    addEditMessageContentState(state, action) {
      const message = state.messages[action.payload.id?._id];
      if (message) {
        message.content = action?.payload?.content;
        message.isEdited = true;
      } else {
        console.error("Message not found:", action.payload.id);
      }
    },
    setIsLoading(state, action) {
      state.loading = action.payload;
    },
    addReplyMessageContent(state, action) {
      state.replyMessageContent = action.payload;
    },
    markDoneMessage(state, action) {
      state.replyMessageContent = action.payload;
    },
    removeMessageActivity(state, action) {
      delete state.messageActivity[action.payload];
    },
    sortChannelsBasedOnLastContent(state, action) {
      const { message } = action.payload;
      const channel: any = state.channels[message?.channelId];

      if (channel) {
        channel.lastMessage = message;

        const sortedChannelIds: any = Object.keys(state.channels).sort(
          (a, b) => {
            const dateA: any = state.channels[a].lastMessage?.createdAt
              ? dayjs(state.channels[a].lastMessage.createdAt)
              : dayjs(state.channels[a].createdAt);
            const dateB = state.channels[b].lastMessage?.createdAt
              ? dayjs(state.channels[b].lastMessage.createdAt)
              : dayjs(state.channels[b].createdAt);

            return dateB.valueOf() - dateA.valueOf();
          }
        );

        const sortedChannels: any = {};
        sortedChannelIds.forEach((id: any) => {
          sortedChannels[id] = state.channels[id];
        });

        state.channels = sortedChannels;
      }
    },

    sortChannels(state) {
      const sortedChannelIds = Object.keys(state.channels).sort((a, b) => {
        const dateA = state.channels[a].lastMessage?.createdAt
          ? dayjs(state.channels[a].lastMessage.createdAt)
          : dayjs(state.channels[a].createdAt);
        const dateB = state.channels[b].lastMessage?.createdAt
          ? dayjs(state.channels[b].lastMessage.createdAt)
          : dayjs(state.channels[b].createdAt);

        return dateB.valueOf() - dateA.valueOf();
      });

      const sortedChannels: any = {};
      sortedChannelIds.forEach((id) => {
        sortedChannels[id] = state.channels[id];
      });

      state.channels = sortedChannels;
    },

    addNewConnection(state, action) {
      const { data } = action.payload;
      state.channels[data._id] = data;

      const sortedChannelIds = Object.keys(state.channels).sort((a, b) => {
        const dateA = state.channels[a].lastMessageDate
          ? dayjs(state.channels[a].lastMessageDate)
          : dayjs(state.channels[a].createdAt);
        const dateB = state.channels[b].lastMessageDate
          ? dayjs(state.channels[b].lastMessageDate)
          : dayjs(state.channels[b].createdAt);

        return dateB.valueOf() - dateA.valueOf();
      });

      const sortedChannels: any = {};
      sortedChannelIds.forEach((id) => {
        sortedChannels[id] = state.channels[id];
      });

      state.channels = sortedChannels;
    },
  },
});

/*
 * ======================================================
 * Export the actions to be used in dispatch calls
 * ======================================================
 */
export const {
  /* Get Channel Messages */
  getChannelMessageRequest: getChannelMessageRequestAction,
  getChannelMessageSuccess: getChannelMessageSuccessAction,
  getChannelMessageFailure: getChannelMessageFailureAction,

  /* send Messages */
  sendMessageRequest: sendMessageRequestAction,
  sendMessageSuccess: sendMessageSuccessAction,
  sendMessageFailure: sendMessageFailureAction,

  /* get user connections */
  getUserConnectionsRequest: getUserConnectionsRequestAction,
  getUserConnectionsSuccess: getUserConnectionsSuccessAction,
  getUserConnectionsFailure: getUserConnectionsFailureAction,

  /* get external connections */
  getExternalConnectionsRequest: getExternalConnectionsRequestAction,
  getExternalConnectionsSuccess: getExternalConnectionsSuccessAction,
  getExternalConnectionsFailure: getExternalConnectionsFailureAction,

  /* create new connection */
  createNewConnectionRequest: createNewConnectionRequestAction,
  createNewConnectionSuccess: createNewConnectionSuccessAction,
  createNewConnectionFailure: createNewConnectionFailureAction,

  /* update connection */
  updateConnectionRequest: updateConnectionRequestAction,
  updateConnectionSuccess: updateConnectionSuccessAction,
  updateConnectionFailure: updateConnectionFailureAction,

  /* update message */
  updateMessageRequest: updateMessageRequestAction,
  updateMessageSuccess: updateMessageSuccessAction,
  updateMessageFailure: updateMessageFailureAction,

  /* schedule message */
  scheduleMessageRequest: scheduleMessageRequestAction,
  scheduleMessageSuccess: scheduleMessageSuccessAction,
  scheduleMessageFailure: scheduleMessageFailureAction,

  /* forward message */
  forwardMessageRequest: forwardMessageRequestAction,
  forwardMessageSuccess: forwardMessageSuccessAction,
  forwardMessageFailure: forwardMessageFailureAction,

  /* add reaction message */
  addReactionToMessageRequest: addReactionToMessageRequestAction,
  addReactionToMessageSuccess: addReactionToMessageSuccessAction,
  addReactionToMessageFailure: addReactionToMessageFailureAction,

  /* remove reaction message */
  removeReactionToMessageRequest: removeReactionToMessageRequestAction,
  removeReactionToMessageSuccess: removeReactionToMessageSuccessAction,
  removeReactionToMessageFailure: removeReactionToMessageFailureAction,

  /* read message */
  markMessagesAsReadRequest: markMessagesAsReadRequestAction,
  markMessagesAsReadSuccess: markMessagesAsReadSuccessAction,
  markMessagesAsReadFailure: markMessagesAsReadFailureAction,

  /* change role */
  changeParticipantsRoleRequest: changeParticipantsRoleRequestAction,
  changeParticipantsRoleSuccess: changeParticipantsRoleSuccessAction,
  changeParticipantsRoleFailure: changeParticipantsRoleFailureAction,

  /* get channel activities */
  getChannelActivitiesRequest: getChannelActivitiesRequestAction,
  getChannelActivitiesSuccess: getChannelActivitiesSuccessAction,
  getChannelActivitiesFailure: getChannelActivitiesFailureAction,

  /* get message activities */
  getMessageActivitiesRequest: getMessageActivitiesRequestAction,
  getMessageActivitiesSuccess: getMessageActivitiesSuccessAction,
  getMessageActivitiesFailure: getMessageActivitiesFailureAction,

  /* get message activities */
  changeMessagesActivitiesStatusRequest: changeMessagesActivitiesStatusRequestAction,
  changeMessagesActivitiesStatusSuccess: changeMessagesActivitiesStatusSuccessAction,
  changeMessagesActivitiesStatusFailure: changeMessagesActivitiesStatusFailureAction,

  /* get message activities */
  setSnoozeMessagesActivitiesRequest: setSnoozeMessagesActivitiesRequestAction,
  setSnoozeMessagesActivitiesSuccess: setSnoozeMessagesActivitiesSuccessAction,
  setSnoozeMessagesActivitiesFailure: setSnoozeMessagesActivitiesFailureAction,

  /* get slack users */
  getSlackUsersRequest: getSlackUsersRequestAction,
  getSlackUsersSuccess: getSlackUsersSuccessAction,
  getSlackUsersFailure: getSlackUsersFailureAction,

  /* get slack users */
  getSlackUserProfileRequest: getSlackUserProfileRequestAction,
  getSlackUserProfileSuccess: getSlackUserProfileSuccessAction,
  getSlackUserProfileFailure: getSlackUserProfileFailureAction,
  /* get slack users */
  getTeamsUserProfileRequest: getTeamsUserProfileRequestAction,
  getTeamsUserProfileSuccess: getTeamsUserProfileSuccessAction,
  getTeamsUserProfileFailure: getTeamsUserProfileFailureAction,

  /* add message */
  addMessage: addMessageAction,

  addChannelId: addChannelIdAction,

  addChannelName: addChannelNameAction,

  addChannel: addChannelAction,

  addEditMessageContent: addEditMessageContentAction,

  addEditMessageContentState: addEditMessageContentStateAction,
  setIsLoading: setIsLoadingAction,
  addReplyMessageContent: addReplyMessageContentAction,
  removeMessageActivity: removeMessageActivityAction,
  sortChannels: sortChannelsAction,
  sortChannelsBasedOnLastContent: sortChannelsBasedOnLastContentAction,
  addNewConnection: addNewConnectionAction,
} = messageSlice.actions;

/*
 * ======================================================
 * Export the reducer to be used in the store
 * ======================================================
 */
export default messageSlice.reducer;
