import { ImportCSVIcon, StoreIcon } from "../../../../images";
import { DragAndDropMenuListType } from "../../../common";

export const DUMMY_CRM_COMPANIES_LIST: DragAndDropMenuListType[] = [
    {
        icon: StoreIcon,
        label: "All Companies",
        draggable: true,
    },
    {
        icon: StoreIcon,
        label: "Created by",
        draggable: true,
    },
    {
        icon: StoreIcon,
        label: "Created at",
        draggable: true,
    },
];


export const CRM_sortOrFilterMenuList: DragAndDropMenuListType[] = [
    {
        icon: ImportCSVIcon,
        label: "Data ID number",
        draggable: true,
    },

    {
        icon: ImportCSVIcon,
        label: "Domain",
        draggable: true,
    },
    {
        icon: ImportCSVIcon,
        label: "Full name",
        draggable: true,
    },

    {
        icon: ImportCSVIcon,
        label: "Description",
        draggable: true,
    },

    {
        icon: ImportCSVIcon,
        label: "Teams",
        draggable: true,
    },
    {
        icon: ImportCSVIcon,
        label: "Categories iouap er foaisfdiusd fs dfiosaduf sdoif sdfisao",
        draggable: true,
    },
    {
        icon: ImportCSVIcon,
        label: "Primary location",
        subList: ['location1', 'location2', 'location3', 'location4', 'location5', 'location6', 'location7'],
        draggable: true,
    },
    {
        icon: ImportCSVIcon,
        label: "AngelList",
        draggable: true,
    },
    {
        icon: ImportCSVIcon,
        label: "Facebook",
        draggable: true,
    },

    {
        icon: ImportCSVIcon,
        label: "Help me import",
        draggable: false,
    },
];