import { CalendarTypesEnum } from "../../../../../Utils";

export interface CalendarPickerOptionInterface {
    id: string | number;
    calendarType: CalendarTypesEnum;
    calendarName: string;
    _id?: string;
}

export const DUMMY_CalendarPickerlist: CalendarPickerOptionInterface[] = [
    {
        id: 8,
        calendarName: "calendar calendar calendar calendar - 8",
        calendarType: CalendarTypesEnum.BearishCalendar,
    },
];
