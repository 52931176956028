export const languagesList = [
  "Detect",
  "English",
  "Español",
  "Français",
  "Deutsch",
  "中文 (Mandarin)",
  "日本語",
  "Русский",
  "Português",
  "Italiano",
  "العربية",
  "한국어",
  "Nederlands",
  "Türkçe",
  "हिन्दी",
  "Svenska",
  "Polski",
  "Ελληνικά",
  "ไทย",
  "Tiếng Việt",
  "עברית",
];
