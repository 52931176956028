import { Stack } from "@mui/material";
import React, { lazy, Suspense, useEffect } from "react";
import {
  a11yProps,
  StyledTab,
  TabPanel,
  TabsWithState,
} from "../../../../common/CardTabsWithoutIcon";
import { SettingsMessagesMain } from "../SettingsMessagesMain";
import { HighlightAndFlagButton } from "./view";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface MessageCenterTabsProps {}

const LazyNewUpdatesTab = lazy(
  () => import("./tabs/NewUpdatesTab/NewUpdatesTab")
);
const LazyDoneTab = lazy(() => import("./tabs/DoneTab/DoneTab"));
const LazySnoozedTab = lazy(() => import("./tabs/SnoozedTab/SnoozedTab"));
const LazySummariesTab = lazy(() => import("./tabs/SummariesTab/SummariesTab"));

const tabs: {
  label: string;
  content: React.ReactNode;
  LANG: string;
}[] = [
  {
    label: "New Updates",
    content: <LazyNewUpdatesTab />,
    LANG: "MESSAGE.NEW_UPDATE",
  },
  {
    label: "Done",
    content: <LazyDoneTab />,
    LANG: "MESSAGE.DONE",
  },
  {
    label: "Snoozed",
    content: <LazySnoozedTab />,
    LANG: "MESSAGE.SNOOZED",
  },
  {
    label: "Summaries",
    content: <LazySummariesTab />,
    LANG: "MESSAGE.SUMMARIES",
  },
];

const MessageCenterTabs: React.FC<MessageCenterTabsProps> = ({}) => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = React.useState(0);
  const navigate = useNavigate();
  const params = useParams();
  useEffect(() => {
    if (params.id) {
      setTabValue(0);
    }
  }, [params.id]);

  const handleTabChange = (newValue: number) => {
    setTabValue(newValue);
    if (params.id) {
      navigate(`/Message`);
    }
  };

  return (
    <Stack
      sx={{
        bgcolor: "white",
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        gap: "20px",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "24px",
          borderBottom: "1px solid #E9EDF2",
          justifyContent: "space-between",
        }}
      >
        <Stack direction="row" gap="25px" alignItems={"center"}>
          <TabsWithState
            ariaLabel="Message center tabs"
            value={tabValue}
            onChange={(event, newValue) => handleTabChange(newValue)}
            tabIndicatorColor="#775EE2"
            sx={{
              maxWidth: "max-content",
              gap: "25px",
              borderBottom: "0px solid #E9EDF2",
              "& .css-1aquho2-MuiTabs-indicator": {
                bottom: "0px !important",
              },
            }}
          >
            {tabs.map((tab, index) => (
              <StyledTab
                style={{
                  font: "normal normal 300 15px/19px Source Serif Pro",
                  padding: "10px 0px",
                }}
                key={index}
                disableRipple
                label={t(tab.LANG)}
                {...a11yProps(index)}
              />
            ))}
          </TabsWithState>

          <SettingsMessagesMain />
          <HighlightAndFlagButton />
        </Stack>
      </Stack>

      {!params.id &&
        tabs.map(
          (tab: { label: string; content: React.ReactNode }, index: number) => (
            <TabPanel
              style={{ display: "contents" }}
              key={index}
              value={tabValue}
              index={index}
            >
              <Suspense fallback={<div>Loading...</div>}>
                {tab.content}
              </Suspense>
            </TabPanel>
          )
        )}
    </Stack>
  );
};

export default MessageCenterTabs;
