import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { GreenDropdownIcon, XCircleIcon } from "../../../images";
import { AnalyticsView, LinkCountView, NameView, SettingView } from "./View";
import { adjustHexColor } from "../../../Utils";
import styles from "./LinkDetails.module.css";
import {
  CloudStorageState,
  deleteLink__api,
  getDetailsSliderState,
  getLink__api,
  toggleAskDetails__api,
  toggleDownload__api,
  toggleWatermark__api,
  updateExpiration__api,
  updateLinkPassword__api,
} from "../../../redux";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

interface LinkDetailsProps {
  closeDetails?: () => void;
  selectedFileData?: any;
}

const LinkDetails: React.FC<LinkDetailsProps> = ({
  closeDetails,
  selectedFileData,
}) => {
  const dispatch = useDispatch();

  const cloudStorageState = useSelector(
    (state: RootState) => state?.cloudStorage as CloudStorageState
  );

  const { detailsSidebarLinkData = null } = cloudStorageState || {};

  const handleStateToggle = async () => {
    dispatch(getDetailsSliderState("Item Insight"));
  };

  const deleteLink = async () => {
    try {
      const action = deleteLink__api(linkDatas?._id);

      const deleteSuccess: any = await dispatch(action);

      if (deleteSuccess?.success) {
        handleStateToggle();
      }
    } catch (error) {
      console.log("🚀 ~ deleteLink ~ error:", error);
    }
  };

  const toggleDownload = async () => {
    try {
      const action = toggleDownload__api(linkDatas?._id);

      await dispatch(action);
    } catch (error) {
      console.log("🚀 ~ toggleDownload ~ error:", error);
    }
  };

  const toggleAskDetails = async () => {
    try {
      const action = toggleAskDetails__api(linkDatas?._id);

      await dispatch(action);
    } catch (error) {
      console.log("🚀 ~ toggleAskDetails ~ error:", error);
    }
  };
  const toggleUpdateExpiration = async (date: any) => {
    try {
      const action = updateExpiration__api(linkDatas?._id, date);

      await dispatch(action);
    } catch (error) {
      console.log("🚀 ~ toggleUpdateExpiration ~ error:", error);
    }
  };

  const toggleUpdatePassword = async (pass: any) => {
    try {
      const action = updateLinkPassword__api(linkDatas?._id, pass);

      await dispatch(action);
    } catch (error) {
      console.log("🚀 ~ toggleUpdatePassword ~ error:", error);
    }
  };

  const toggleWaterMark = async () => {
    try {
      const action = toggleWatermark__api(linkDatas?._id);

      await dispatch(action);
    } catch (error) {
      console.log("🚀 ~ toggleWaterMark ~ error:", error);
    }
  };

  const [linkDatas, setLinkData] = useState<any>({});

  useEffect(() => {
    if (detailsSidebarLinkData) {
      setLinkData(detailsSidebarLinkData);
    }
  }, [detailsSidebarLinkData]);

  return (
    <Stack className={styles["link-details"]}>
      {/* ----------------    card title + Back button + close button --------------- */}
      <Stack className={styles["link-details__header"]}>
        <Typography className={styles["link-details__title"]}>
          Link Details
        </Typography>

        <Stack className={styles["link-details__header-actions"]}>
          <IconButton
            className={styles["link-details__back-button"]}
            disableRipple
            onClick={() => handleStateToggle()}
          >
            <GreenDropdownIcon
              open
              sx={{ transform: "rotate(180deg)", height: "11px" }}
            />
            <Typography className={styles["link-details__back-text"]}>
              Back
            </Typography>
          </IconButton>

          <Tooltip title="close" placement="top" arrow>
            <IconButton
              onClick={() => closeDetails && closeDetails()}
              className={styles["link-details__close-button"]}
              disableRipple
            >
              <XCircleIcon w={15} />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
      {/* ----------------    card title + Back button + close button container end here --------------- */}

      {/*---------    scrollable container here  --------- */}
      <Stack className={styles["link-details__content"]}>
        <LinkCountView
          arrivalsCount={linkDatas?.totalArrivals}
          minutesCount={linkDatas?.totalMinutes}
          viewCount={linkDatas?.totalViews}
        />
        <NameView
          createdBy={linkDatas?.createdBy}
          linkName={linkDatas?.name || "link-details-name"}
          onLinkEdit={(value) => console.log(value)}
        />
        <SettingView
          linkDatas={linkDatas}
          toggleDownload={toggleDownload}
          toggleWaterMark={toggleWaterMark}
          toggleUpdatePassword={toggleUpdatePassword}
          toggleUpdateExpiration={toggleUpdateExpiration}
          toggleAskDetails={toggleAskDetails}
        />
        <AnalyticsView />

        {/* --- delete button --- */}
        <Stack className={styles["link-details__delete-container"]}>
          <IconButton
            className={styles["link-details__delete-button"]}
            sx={{ ":hover": { bgcolor: adjustHexColor("#ff0000", 110) } }}
            onClick={() => {
              deleteLink();
            }}
          >
            <Typography className={styles["link-details__delete-text"]}>
              Permanently delete this link
            </Typography>
          </IconButton>
        </Stack>
      </Stack>
      {/*---------    scrollable container end here  --------- */}
    </Stack>
  );
};

export default LinkDetails;
