import { Avatar, AvatarGroup, Box, Stack, Typography } from "@mui/material";
import React from "react";
import g from "./invitations.module.css";
import { avatarInitialLetters, getUser } from "../../../../Utils"; // assuming getUser fetches current user's details

type Props = { eventData?: any; type?: string };

const SummonersSSummoners = ({ eventData, type }: Props) => {
  const user = getUser(); // current logged-in user

  // Filter out the user and the primary summoner
  const summonersGuest = eventData?.guests?.filter(
    (guest: any) => guest?.userId !== user?.data?._id // exclude current user
  );

  // Count internal and external guests separately
  const internalGuestsCount = summonersGuest?.filter(
    (guest: any) => guest?.userId !== null // Internal guests have userId
  )?.length;

  const externalGuestsCount = summonersGuest?.filter(
    (guest: any) => guest?.userId === null // External guests have no userId
  )?.length;

  return (
    <Box sx={{ width: "100%" }}>
      <Stack
        mt={1}
        mb={1}
        width={"100%"}
        direction={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={1}
      >
        <Typography className={g.Invitation___Event__description}>
          {eventData?.description ||
            "Strategy 2024 is a project to plan and execute goals for the organization in 2024."}
        </Typography>

        {type === "guest" && (
          <Stack
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={1}
            width={"100%"}
          >
            <AvatarGroup max={4}>
              {summonersGuest &&
                summonersGuest?.length > 0 &&
                summonersGuest?.map((summoner: any, index: number) => {
                  return (
                    <Avatar
                      key={index}
                      alt={avatarInitialLetters(
                        (summoner?.firstName || "First") +
                          " " +
                          (summoner?.lastName || "Last")
                      )}
                      src={
                        summoner?.avatar ||
                        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRdfvzlHq_B0jd8qHbjemgDYgTLnVPDQcKCw&s"
                      }
                    >
                      {avatarInitialLetters(
                        (summoner?.firstName || "First") +
                          " " +
                          (summoner?.lastName || "Last")
                      )}
                    </Avatar>
                  );
                })}
            </AvatarGroup>

            {/* Only show the statement if there are guests */}
            {(internalGuestsCount > 0 || externalGuestsCount > 0) && (
              <Typography className={g.Invitation___Event__accept}>
                {`${internalGuestsCount} member${
                  internalGuestsCount !== 1 ? "s" : ""
                } from your team ${
                  externalGuestsCount > 0
                    ? `and ${externalGuestsCount} external invitee${
                        externalGuestsCount !== 1 ? "s" : ""
                      }`
                    : ""
                } have accepted the invitation.`}
              </Typography>
            )}
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default SummonersSSummoners;
