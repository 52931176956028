import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Stack, Typography } from "@mui/material";

import { menuItems } from "../../../../InsideWorkspace/InsideWorkspaceType";
import {
  getWikiPage__api,
  updateWikiPage__api,
} from "../../../../../../../redux";
import {
  ShareButton,
  ThreeDotMenuLarge,
  ViewPicker,
} from "../../../../../../common";
import { AddToWorkspace, CommentCard } from "../../../../components";
import FroalaEditorComponent from "../../../../../../common/FroalaEditor/FroalaEditor";

const PageWiki = () => {
  const dispatch = useDispatch();
  const { workspaceId, componentId, subcomponentId } = useParams();

  const [pageData, setPageData] = useState([]);
  const [content, setContent] = useState(""); // Local state for content
  const componentMounted = useRef(false); // Ref to track if mounted
  const [isCommentButtonClicked, setCommentButtonClicked] = useState(false);
  const handleGetOnePage = async () => {
    if (!workspaceId || !componentId) return;

    const action = getWikiPage__api(workspaceId, componentId, subcomponentId);
    const result = await dispatch(action);

    if (result?.success && result?.data) {
      setPageData(result?.data);
    } else {
      setPageData([]);
    }
  };

  const idsMatch = () => {
    return pageData?._id === subcomponentId && pageData?.wikiId === componentId;
  };

  const debouncedUpdateDocument = useCallback(
    async (updatedContent) => {
      if (!pageData || !idsMatch()) {
        return;
      }

      const documentPayload = {
        title: pageData?.title || pageData?.name,
        content: { text: updatedContent },
      };

      const action = updateWikiPage__api(
        workspaceId,
        componentId,
        subcomponentId,
        documentPayload
      );
      await dispatch(action);
    },
    [dispatch, workspaceId, componentId, pageData, subcomponentId]
  );

  const decodeHTML = (html) => {
    if (html) {
      const txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt?.value;
    } else {
      return "";
    }
  };

  useEffect(() => {
    handleGetOnePage();
    componentMounted.current = true;

    return () => {
      componentMounted.current = false;
    };
  }, [componentId, workspaceId, subcomponentId]);

  useEffect(() => {
    if (idsMatch() && pageData?.content?.text) {
      const decodedContent = decodeHTML(pageData?.content?.text);
      setContent(decodedContent);
    }
  }, [pageData]);

  const handleContentChange = (updatedContent) => {
    setContent(updatedContent);
    if (componentMounted.current) {
      debouncedUpdateDocument(updatedContent); // Call debounced function
    }
  };

  return (
    <Stack>
      <Stack
        ml={2}
        mb={3}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography
          sx={{
            fontFamily: "Source Serif Pro",
            fontSize: "25px",
            fontWeight: "900",
            color: "black",
            lineHeight: "31.33px",
          }}
        >
          {(pageData && pageData?.title) || pageData?.name}
        </Typography>

        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          gap={1}
        >
          <ViewPicker />
          <ShareButton />
          <AddToWorkspace />
          <ThreeDotMenuLarge options={menuItems} />
        </Stack>
      </Stack>
      <div style={{ marginLeft: "10px" }}>
        <Stack direction={"row"} alignItems={"flex-start"} gap={1}>
          <FroalaEditorComponent
            initialContent={content}
            key={content} // Keying the editor to content
            onContentChange={handleContentChange}
            isCommentButtonClicked={isCommentButtonClicked}
            setCommentButtonClicked={setCommentButtonClicked}
        
          />
          {isCommentButtonClicked && <CommentCard parentPageId={pageData?.parentPageId} />}
        </Stack>
      </div>
    </Stack>
  );
};

export default PageWiki;
