import { Box } from "@mui/material";
import { useEffect } from "react";
import QuickMessagePreviewView from "../../../common/QuickActions/View/MessageView/MessagePreviewView/QuickMessagePreviewView";
import { AuthState } from "../../../../redux";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";
import { MessagesState } from "../../../../redux/Slice/message/messageTypes";
import {MEETING_ENUMS} from "../constant";

const MessagesScreen = (messages: any) => {
  const { user } = useSelector((state: RootState) => state.auth as AuthState);
  const { currentChannel,slackUserProfile } = useSelector(
    (state: RootState) => state.message as MessagesState
  );

  useEffect(() => {
    let scrollableDiv: any = document?.getElementById("scrollableDiv");

    function scrollToBottom() {
      scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
    }
    scrollToBottom();
  }, [messages]);

  return (
    <Box id="scrollableDiv">
      {messages?.messages &&
        Object.keys(messages.messages).map((key: string, index: any) => {
          const message = messages.messages[key];
          let messageType;

          if (
            currentChannel?.channelType === MEETING_ENUMS.SLACK
              ? message?.userId === slackUserProfile?.id
              : message?.userId === user?.data?._id
          ) {
            messageType = "send";
          } else {
            messageType = "received";
          }

          return (
            <QuickMessagePreviewView
              editMessageHistory={message?.editHistory}
              key={index}
              isEdited={message?.isEdited}
              replyMessage={message?.repliedContent || ""}
              messageId={message?._id}
              messageType={messageType}
              messageSender={message?.messageSender}
              messageText={message?.content}
              timeStamp={message?.createdAt}
              displayName={message?.displayName}
              reaction={message?.reaction}
            />
          );
        })}
    </Box>
  );
};

export default MessagesScreen;
