import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../Utils";
import { FastRewindFilledIcon } from "../../../../../images";
import {useTranslation} from "react-i18next";

interface ForwardBackwardButtonProps {
  variant: "forward" | "backward";
}

const ForwardBackwardButton: React.FC<ForwardBackwardButtonProps> = ({
  variant,
}) => {
    const {t}=useTranslation()
  const getData = (): {
    hoverText: string;
    iconDirection: "left" | "right";
  } => {
    switch (variant) {
      case "backward":
        return { hoverText: "PAST_CALL_DETAILS.SKIP_BACK", iconDirection: "left" };
      case "forward":
        return { hoverText: "PAST_CALL_DETAILS.SKIP_FORWARD", iconDirection: "right" };
    }
  };

  return (
    <Tooltip
      title={t(getData().hoverText)}
      placement="top"
      arrow
      disableInteractive
    >
      <IconButton
        sx={{
          p: 0,
          height: "50px",
          width: "50px",
          boxSizing: "border-box",
          border: "1px solid white",
          boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.16)",
          bgcolor: adjustHexColor("#E9EDF2", 50),
          ":hover": {
            bgcolor: adjustHexColor("#E9EDF2", 50 + 10),
          },
        }}
      >
        <FastRewindFilledIcon
          w={25}
          color="black"
          direction={getData().iconDirection}
        />
      </IconButton>
    </Tooltip>
  );
};

export default ForwardBackwardButton;
