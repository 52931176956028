import { createSlice } from "@reduxjs/toolkit";
import { CommentState } from "./CommentsType";

import {
    /* Add Comment to Task */
    addCommentToTask__Failure,
    addCommentToTask__Request,
    addCommentToTask__Success,

    /* Add Comment to Document */
    addCommentToDocument__Failure,
    addCommentToDocument__Request,
    addCommentToDocument__Success,

    /* Add Comment to Whiteboard */
    addCommentToWhiteboard__Failure,
    addCommentToWhiteboard__Request,
    addCommentToWhiteboard__Success,

    /* Add Comment to Project */
    addCommentToProject__Failure,
    addCommentToProject__Request,
    addCommentToProject__Success,

    /* Toggle Reaction to Comment */
    toggleReactionToComment__Failure,
    toggleReactionToComment__Request,
    toggleReactionToComment__Success,

    /* Add Comment to Cell */
    addCommentToCell__Failure,
    addCommentToCell__Request,
    addCommentToCell__Success,

    /* Add Sub Comment to Comment */
    addSubCommentToComment__Failure,
    addSubCommentToComment__Request,
    addSubCommentToComment__Success,

    /* Toggle Archive Comment */
    toggleArchiveComment__Failure,
    toggleArchiveComment__Request,
    toggleArchiveComment__Success,

    /* Toggle Resolve Comment */
    toggleResolveComment__Failure,
    toggleResolveComment__Request,
    toggleResolveComment__Success,

    /* Edit Comment */
    editComment__Failure,
    editComment__Request,
    editComment__Success,

    /* Get All Sub Comments */
    getAllSubComments__Failure,
    getAllSubComments__Request,
    getAllSubComments__Success,

    /* Get All Comments */
    getAllComments__Failure,
    getAllComments__Request,
    getAllComments__Success
} from "./CommentsSliceAction";


const initialState: CommentState = {
    error: null,
    data: null,
};


const fileUploadSlice = createSlice({
    name: "fileUpload", // Name of the slice
    initialState, // Initial state of the slice
    reducers: {
        /* Add Comment to Task */
        addCommentToTask__Failure,
        addCommentToTask__Request,
        addCommentToTask__Success,

        /* Add Comment to Document */
        addCommentToDocument__Failure,
        addCommentToDocument__Request,
        addCommentToDocument__Success,

        /* Add Comment to Whiteboard */
        addCommentToWhiteboard__Failure,
        addCommentToWhiteboard__Request,
        addCommentToWhiteboard__Success,

        /* Add Comment to Project */
        addCommentToProject__Failure,
        addCommentToProject__Request,
        addCommentToProject__Success,

        /* Toggle Reaction to Comment */
        toggleReactionToComment__Failure,
        toggleReactionToComment__Request,
        toggleReactionToComment__Success,

        /* Add Comment to Cell */
        addCommentToCell__Failure,
        addCommentToCell__Request,
        addCommentToCell__Success,

        /* Add Sub Comment to Comment */
        addSubCommentToComment__Failure,
        addSubCommentToComment__Request,
        addSubCommentToComment__Success,

        /* Toggle Archive Comment */
        toggleArchiveComment__Failure,
        toggleArchiveComment__Request,
        toggleArchiveComment__Success,

        /* Toggle Resolve Comment */
        toggleResolveComment__Failure,
        toggleResolveComment__Request,
        toggleResolveComment__Success,

        /* Edit Comment */
        editComment__Failure,
        editComment__Request,
        editComment__Success,

        /* Get All Sub Comments */
        getAllSubComments__Failure,
        getAllSubComments__Request,
        getAllSubComments__Success,

        /* Get All Comments */
        getAllComments__Failure,
        getAllComments__Request,
        getAllComments__Success
    },
});


export const {

    /* Add Comment to Task */
    addCommentToTask__Failure: addCommentToTask__FailureAction,
    addCommentToTask__Request: addCommentToTask__RequestAction,
    addCommentToTask__Success: addCommentToTask__SuccessAction,

    /* Add Comment to Document */
    addCommentToDocument__Failure: addCommentToDocument__FailureAction,
    addCommentToDocument__Request: addCommentToDocument__RequestAction,
    addCommentToDocument__Success: addCommentToDocument__SuccessAction,

    /* Add Comment to Whiteboard */
    addCommentToWhiteboard__Failure: addCommentToWhiteboard__FailureAction,
    addCommentToWhiteboard__Request: addCommentToWhiteboard__RequestAction,
    addCommentToWhiteboard__Success: addCommentToWhiteboard__SuccessAction,

    /* Add Comment to Project */
    addCommentToProject__Failure: addCommentToProject__FailureAction,
    addCommentToProject__Request: addCommentToProject__RequestAction,
    addCommentToProject__Success: addCommentToProject__SuccessAction,

    /* Toggle Reaction to Comment */
    toggleReactionToComment__Failure: toggleReactionToComment__FailureAction,
    toggleReactionToComment__Request: toggleReactionToComment__RequestAction,
    toggleReactionToComment__Success: toggleReactionToComment__SuccessAction,

    /* Add Comment to Cell */
    addCommentToCell__Failure: addCommentToCell__FailureAction,
    addCommentToCell__Request: addCommentToCell__RequestAction,
    addCommentToCell__Success: addCommentToCell__SuccessAction,

    /* Add Sub Comment to Comment */
    addSubCommentToComment__Failure: addSubCommentToComment__FailureAction,
    addSubCommentToComment__Request: addSubCommentToComment__RequestAction,
    addSubCommentToComment__Success: addSubCommentToComment__SuccessAction,

    /* Toggle Archive Comment */
    toggleArchiveComment__Failure: toggleArchiveComment__FailureAction,
    toggleArchiveComment__Request: toggleArchiveComment__RequestAction,
    toggleArchiveComment__Success: toggleArchiveComment__SuccessAction,

    /* Toggle Resolve Comment */
    toggleResolveComment__Failure: toggleResolveComment__FailureAction,
    toggleResolveComment__Request: toggleResolveComment__RequestAction,
    toggleResolveComment__Success: toggleResolveComment__SuccessAction,

    /* Edit Comment */
    editComment__Failure: editComment__FailureAction,
    editComment__Request: editComment__RequestAction,
    editComment__Success: editComment__SuccessAction,

    /* Get All Sub Comments */
    getAllSubComments__Failure: getAllSubComments__FailureAction,
    getAllSubComments__Request: getAllSubComments__RequestAction,
    getAllSubComments__Success: getAllSubComments__SuccessAction,

    /* Get All Comments */
    getAllComments__Failure: getAllComments__FailureAction,
    getAllComments__Request: getAllComments__RequestAction,
    getAllComments__Success: getAllComments__SuccessAction

} = fileUploadSlice.actions;


export default fileUploadSlice.reducer;
