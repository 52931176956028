import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { HostDropdown, HostDropdownListInterface } from "../../../../../../common";
import axios from "axios";
import { baseURL } from "../../../../../../../redux/middleware/apiMiddleware";

const useDebounce = (callback: (...args: any[]) => void, delay: number) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const debouncedCallback = useCallback((...args: any[]) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  }, [callback, delay]);

  return debouncedCallback;
};

interface HostViewProps {
  updateFilterData: (key: "hosts", value: any) => void;
  hostData?: HostDropdownListInterface[];
}

const HostView: React.FC<HostViewProps> = ({ updateFilterData, hostData }) => {
  const [hostListData, setListData] = useState<HostDropdownListInterface[]>([]);
  const [mappedHostData, setMappedHostData] = useState<HostDropdownListInterface[]>([]);
  const token = localStorage.getItem("accessToken");

  const handleDropdownChange = (selectedOptions: HostDropdownListInterface[]) => {
    const selectedValues = selectedOptions.map(option => ({
      name: option?.name,
      domain: option.domain,
      icon: option.icon,
    }));
    updateFilterData("hosts", selectedValues);
  };

  const fetchHosts = async (hostName: string) => {
    try {
      const res = await axios.get(`${baseURL}/company/fetch-company-list?companyName=${hostName}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setListData(res.data.data);
    } catch (err) {
      console.error("Error fetching hosts:", err);
    }
  };
  const debouncedGetHosts = useDebounce(fetchHosts, 500);


  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (hostData && hostData.length > 0) {
      const mappedData = hostData.map(item => ({
        companyUrl: '',
        companyName: item.name || "",
        domain: item.domain || "",
        icon: item.icon || "",
        name: item.name || "",
      }));
      setMappedHostData(mappedData);
      setLoading(false); // Set loading to false once data is mapped
    } else {
      setLoading(false); // Even if hostData is empty, we finish loading
    }
  }, [hostData]);


  const hostListDataMapped: HostDropdownListInterface[] = hostListData.map(item => ({
    companyUrl: '',
    companyName: item.name || "",
    domain: item.domain || "",
    icon: item.icon || "",
    name: item.name || "",
  }));


  return (
    <Stack
      sx={{
        flexDirection: "row",
        gap: "10px",
        alignItems: "start",
        borderBottom: "0.5px solid #E9EDF2",
        marginTop: "10px",
      }}
    >
      <Typography
        sx={{
          font: "normal normal 300 14px/18px Source Serif Pro",
          paddingTop: "10px",
        }}
      >
        Hosts
      </Typography>
      {mappedHostData.length > 0 && (
        <HostDropdown
          options={hostListDataMapped}
          onChangeSelection={handleDropdownChange}
          getHosts={debouncedGetHosts}
          initialHosts={mappedHostData}
        />
      )}

      {mappedHostData.length <= 0 && (
        <HostDropdown
          options={hostListDataMapped}
          onChangeSelection={handleDropdownChange}
          getHosts={debouncedGetHosts}
        />
      )}
    </Stack>
  );
};

export default HostView;
