/* GroupTable.tsx */
import React, { useState, useEffect } from "react";
import { ColDef, CellValueChangedEvent } from "ag-grid-community";
import { ICellRendererParams } from "ag-grid-community";
import {
  AddCalculationsMenu,
  CName,
  DataTable,
  DealData,
  CompanyData,
  PeopleData,
} from "../../../components";
import {
  CustomHeaderComponent,
  DealValueView,
  TableDealOwnerView,
} from "../../../common";
import {
  DropdownWithColor,
  DUMMY_DEALSTAGE_OPTIONS,
} from "../../../../../common";
import {
  CompanyIcon,
  DealStageIcon,
  DescriptionIcon,
  FundingIcon,
  StrongestrelationshipIcon,
} from "../../../../../../images";

// Unified data type for the Group Table
type GroupTableData = {
  type: "deal" | "company" | "contact";
  deal?: string;
  description: string;
  dealStage?: {
    dealStageName?: string;
    dealStageColor?: string;
    _id?: string;
  };
  dealOwner?: any;
  dealValue?: string;
  company?: {
    companyName?: string;
    companyLogoUrl?: string;
  };
  contact?: {
    fullName?: string;
    jobTitle?: string;
    company?: string; // Optionally include company name if available
  };
};

const groupColumnDefs: ColDef<GroupTableData>[] = [
  {
    headerName: "Object Type",
    field: "type",
    headerClass: "my-header-class",
    sortable: true,
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 100,
    hide: false,
    pinned: "left",
  },
  {
    headerName: "Deal",
    field: "deal",
    headerClass: "my-header-class",
    sortable: true,
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return (
        <CName
          targetOpen={true}
          targetLocation={params?.data?.targetLocation}
          value={params?.value}
          p={params}
        />
      );
    },
  },
  {
    headerName: "Deal Stage",
    field: "dealStage",
    headerClass: "my-header-class",
    sortable: true,
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      // return <DealStatusDropdownTable params={params} width="150px" />;
      return (
        <DropdownWithColor
          options={DUMMY_DEALSTAGE_OPTIONS}
          onChange={(value) => null}
          onSelect={(value) => null}
          initialValue={DUMMY_DEALSTAGE_OPTIONS[1]}
          disableAddingNewOpt
          // disableColorEditing
        />
      );
    },
  },
  {
    headerName: "Deal Owner",
    field: "dealOwner",
    headerClass: "my-header-class",
    sortable: true,
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      // return <CName value={params.value.dealOwnerName} />;
      return <TableDealOwnerView p={params} />;
    },
  },
  {
    headerName: "Deal Value",
    field: "dealValue",
    headerClass: "my-header-class",
    sortable: true,
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      // return <CName value={params.value} />;
      return <DealValueView value={params.value} />;
    },
  },
  {
    headerName: "Description",
    field: "description",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <CName value={params.value} />;
    },
  },
  {
    headerName: "Company",
    field: "company",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <CName value={params.value.companyName} />;
    },
  },
  {
    headerName: "Contact",
    field: "contact",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return params?.value ? <CName value={params?.value?.fullName} /> : null;
    },
  },
];
const pinnedBottomRowData: any = [
  {
    deal: "Tech Corp",
    description:
      "The relationship strength of the company that owns the company.",
    dealStage: {
      dealStageName: "Negotiation",
      dealStageColor: "green",
      _id: "deal-5q",
    },
    actions: <AddCalculationsMenu />, // Rendering your component
    dealOwner: {
      dealOwnerName: "John Doe",
      dealOwnerAvatarUrl: "https://i.pravatar.cc/150?img=51",
      _id: "owner-1a",
    },
    dealValue: "$1M",
    company: {
      companyName: "Tech Corp",
      companyLogoUrl: "https://i.pravatar.cc/150?img=51",
    },
  },
];

const GroupTable = ({ groupDetails }: { groupDetails: any }) => {
  const [rowData, setRowData] = useState<GroupTableData[]>([]);

  useEffect(() => {
    const combinedData: GroupTableData[] = [];
    // Add deals to combined data
    if (groupDetails?.deals) {
      const dealsData = groupDetails?.deals?.map((deal: any) => ({
        type: "deal",
        deal: deal?.dealName,
        description: deal?.description || "No description provided.",
        dealStage: {
          dealStageName: deal?.dealStage || "Unknown Stage",
          dealStageColor: "blue", // You may add logic for color based on stage
        },
        dealOwner: deal?.dealOwner,
        dealValue: `$${deal?.dealValue?.toLocaleString()}`, // Format deal value as currency
        company: {
          companyName: deal?.company || "Unknown Company",
        },
        eId: deal?._id,
        targetLocation: "deal",
      }));
      combinedData?.push(...dealsData);
    }

    // Add companies to combined data
    if (groupDetails?.companies) {
      const companiesData = groupDetails?.companies?.map((company: any) => ({
        type: "company",
        description: company?.description || "No description provided.",
        company: {
          companyName: company?.businessName || "Unknown Company",
          companyLogoUrl: company?.businessLogo || "", // Default empty if no logo
        },
        eId: company?._id,
        targetLocation: "company",
        // Add any relevant fields for the company
      }));
      combinedData?.push(...companiesData);
    }

    // Add contacts to combined data
    if (groupDetails?.people) {
      const contactsData = groupDetails?.people?.map((contact: any) => ({
        type: "contact",
        description: "Contact information not provided.",
        contact: {
          fullName: `${contact?.firstName} ${contact?.lastName}`,
          jobTitle: contact.jobTitle || "No job title provided.",
        },
        eId: contact?._id,
        targetLocation: "people",
      }));
      combinedData?.push(...contactsData);
    }

    setRowData(combinedData);
  }, [groupDetails]);

  const updateGroupData = async (
    event: CellValueChangedEvent<GroupTableData>
  ) => {
    const updatedData = event.data;
    // Make an API call to update the data
    try {
      console.log("Data updated successfully:", updatedData);
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  return (
    <DataTable<GroupTableData>
      columnDefs={groupColumnDefs}
      rowData={rowData}
      pinnedBottomRowData={pinnedBottomRowData}
      onCellValueChanged={updateGroupData}
    />
  );
};

export default GroupTable;
