import React, { useState, MouseEvent } from "react";
import {
  Attech45Icon,
  ImageIcon,
  LinkIcon,
  PaintRollerIcon,
} from "../../../images";
import { IconButton, Stack, Typography, Popover } from "@mui/material";
import { FontStyleBold } from "../FontStyleBold";
import {useTranslation} from "react-i18next";

interface StyleFormatProps {
  w?: number;
}

const StyleFormat: React.FC<StyleFormatProps> = ({ w = 15 }) => {
    const {t}=useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <IconButton
        sx={{
          p: 0,
          borderRadius: "5px",
          flexDirection: "column",
          justifyContent: "center",
          color: "black",
        }}
        disableRipple
        onClick={handleClick}
      >
        <PaintRollerIcon w={w} />
        <Typography
          sx={{ font: "normal normal 300 11px/14px Source Serif Pro" }}
        >
            {t("VIDEO_CENTER.MEETING.STYLE")}
        </Typography>
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        sx={{ mt: "-25px" }}
        PaperProps={{
          style: {
            padding: 0,
            boxShadow: "none",
          },
        }}
      >
        <Stack
          sx={{
            minHeight: "45px",
            borderRadius: "5px",
            border: "1px solid #E9EDF2",
            bgcolor: "white",
            flexDirection: "row",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <FontStyleBold iconsSize={20} showLabel />

          <IconButton
            sx={{
              borderRadius: "5px",
              color: "black",
              flexDirection: "column",
              textTransform: "none",
              padding: "4px",
            }}
          >
            <LinkIcon w={20} />{" "}
            <Typography
              sx={{
                font: "normal normal 300 11px/14px Source Serif Pro",
              }}
            >
              Link
            </Typography>
          </IconButton>

          <IconButton
            sx={{
              borderRadius: "5px",
              color: "black",
              flexDirection: "column",
              textTransform: "none",
              padding: "4px",
            }}
          >
            <ImageIcon w={20} />{" "}
            <Typography
              sx={{
                font: "normal normal 300 11px/14px Source Serif Pro",
              }}
            >
              Image
            </Typography>
          </IconButton>

          <IconButton
            sx={{
              borderRadius: "5px",
              color: "black",
              flexDirection: "column",
              textTransform: "none",
              padding: "4px",
            }}
          >
            <Attech45Icon w={20} />{" "}
            <Typography
              sx={{
                font: "normal normal 300 11px/14px Source Serif Pro",
              }}
            >
              Attech
            </Typography>
          </IconButton>
        </Stack>
      </Popover>
    </>
  );
};

export default StyleFormat;
