import React, { useState } from "react";
import { Menu } from "@mui/material";
import { IconDropDownButton } from "../IconDropDownButton";
import { DragAndDropMenu, DragAndDropMenuListType } from "../DragAndDropMenu";

interface DropdownButtonAllCompaniesProps {
  options: DragAndDropMenuListType[];
  buttonLabel: string;
  labelIcon: React.ElementType;
}

const DropdownButtonAllCompanies: React.FC<DropdownButtonAllCompaniesProps> = ({
  buttonLabel,
  labelIcon,
  options,
}) => {
  const [menuOpt, setMenuOpt] = useState<DragAndDropMenuListType[]>(options);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <IconDropDownButton
        label={buttonLabel}
        icon={labelIcon}
        menuOpen={open}
        sx={{ width: "150px" }}
        onButtonClick={handleClick}
      />
      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            padding: 0,
            marginTop: "10px",
            zIndex: 100,
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.16)",
            borderRadius: "5px",
          },
        }}
      >
        <DragAndDropMenu
          menuWidth="180px"
          hideSearchBar
          menuItems={menuOpt}
          setMenuItems={(newValue) => setMenuOpt(newValue)}
          dragIconPlace="right"
          AddButtonClick={() => null}
          AddButtonLabel="Create a new type"
        />
      </Menu>
    </div>
  );
};

export default DropdownButtonAllCompanies;
