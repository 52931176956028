import {
  DUMMY_EMAIL_CARD_USER,
  UserInfoChipProps,
} from "../../../EmailCardComponents";

export enum TouchPointVariantEnum {
  Email = "email",
  CalendarEvent = "calendarEvent",
  Call = "call",
  Task = "task",
  Bill = "bill",
  Message = "message",
}

interface BaseProps {
  timestamp: string;
  timeZone: string;
}

export interface CallProps extends BaseProps {
  usersList: UserInfoChipProps[];
  platform: "Bearish" | "Zoom" | "Webex" | "BlueJeans";
}

export interface CalendarEventProps extends BaseProps {
  eventName: string;
  usersList: UserInfoChipProps[];
  platform: "Bearish" | "Google" | "Outlook" | "Teams";
}

export interface TaskProps extends BaseProps {
  taskCreatorInfo: UserInfoChipProps;
  taskName: string;
  dueTime: string;
}

export interface MessageProps extends BaseProps {}

export interface BillProps extends BaseProps {
  invoiceNum: string | number;
  invoiceStatus: "paid" | "unpaid";
}

export interface EmailProps extends BaseProps {
  emailSubject: string;
  emailMsg: string;
}

export type TouchPointViewProps =
  | ({ variant: TouchPointVariantEnum.Bill } & BillProps)
  | ({ variant: TouchPointVariantEnum.CalendarEvent } & CalendarEventProps)
  | ({ variant: TouchPointVariantEnum.Call } & CallProps)
  | ({ variant: TouchPointVariantEnum.Message } & MessageProps)
  | ({ variant: TouchPointVariantEnum.Email } & EmailProps)
  | ({ variant: TouchPointVariantEnum.Task } & TaskProps);

//   -------------------------------------------------------------

export const DUMMY_touchPointsList: TouchPointViewProps[] = [
  {
    variant: TouchPointVariantEnum.Bill,
    timestamp: "2024-08-31T10:00:00Z",
    timeZone: "PST",
    invoiceNum: "INV-001",
    invoiceStatus: "paid",
  },
  {
    variant: TouchPointVariantEnum.CalendarEvent,
    timestamp: "2024-08-31T14:00:00Z",
    timeZone: "PST",
    eventName: "Project Meeting",
    usersList: DUMMY_EMAIL_CARD_USER,
    platform: "Bearish",
  },
  {
    variant: TouchPointVariantEnum.Call,
    timestamp: "2024-08-31T15:00:00Z",
    timeZone: "PST",
    usersList: DUMMY_EMAIL_CARD_USER,
    platform: "BlueJeans",
  },
  {
    variant: TouchPointVariantEnum.Email,
    timestamp: "2024-08-31T16:00:00Z",
    timeZone: "PST",
    emailSubject: "Monthly Report",
    emailMsg: "Please find the attached report for this month.",
  },
  {
    variant: TouchPointVariantEnum.Message,
    timestamp: "2024-08-31T17:00:00Z",
    timeZone: "PST",
  },
  {
    variant: TouchPointVariantEnum.Task,
    timestamp: "2024-08-31T18:00:00Z",
    timeZone: "PST",
    taskCreatorInfo: {
      userName: "John Doe",
      userEmail: "john.doe@example.com",
      userIconSrc: "https://randomuser.me/api/portraits/men/1.jpg",
      userAvatarColor: "#FF5722",
    },
    taskName: "Complete Documentation",
    dueTime: "2024-09-01T10:00:00Z",
  },
];
