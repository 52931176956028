import * as React from "react";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { CustomCheckboxDropdownOptionInterface } from "../CustomCheckboxDropdown/CustomCheckboxDropdownType";
import S from "./CustomCheckboxDropdownMultiple.module.css";
import { CheckboxMedium } from "../CheckboxMedium";
import { InputBase, ListItemText, Stack, Tooltip } from "@mui/material";
import { ColorPickerDot } from "../ColorPickerDot";
import { adjustHexColor } from "../../../Utils";

// Custom hook for stopping event propagation
const useStopPropagation = () => {
  return (event: React.MouseEvent | React.KeyboardEvent) => {
    event.stopPropagation();
  };
};

interface CustomCheckboxDropdownProps {
  options: CustomCheckboxDropdownOptionInterface[];
  placeHolder?: string;
  width?: string;
  badgeWithoutText?: boolean;
  initialValues?: string[]; // Add initialValues prop
  onChange?: (value: {
    selectedOption: string[];
    updatedOptions: CustomCheckboxDropdownOptionInterface[];
  }) => void;
}

const CustomCheckboxDropdownMultiple: React.FC<CustomCheckboxDropdownProps> = ({
  options,
  placeHolder,
  badgeWithoutText = false,
  initialValues = [], // Default to empty array
  onChange,
  width = "230px",
  ...props
}) => {
  const [personName, setPersonName] = React.useState<string[]>(initialValues); // Initialize with initialValues
  const [open, setOpen] = React.useState(false);
  const [localOptions, setLocalOptions] =
    React.useState<CustomCheckboxDropdownOptionInterface[]>(options);
  const [addingCustom, setAddingCustom] = React.useState(false);
  const [newCustomValue, setNewCustomValue] = React.useState("");
  const [newCustomColor, setNewCustomColor] = React.useState("#007BB2");

  const stopPropagation = useStopPropagation();

  // Add useEffect to update personName when initialValues change
  React.useEffect(() => {
    if(initialValues){
      setPersonName(initialValues);
    }
  }, [initialValues]);

  const handleAddCustomClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setAddingCustom(true);
  };

  // Save custom option if the input value is not empty and unique
  const handleSaveCustomClick = () => {
    if (
      newCustomValue?.trim() !== "" &&
      !localOptions?.some((option) => option?.value === newCustomValue)
    ) {
      const newCustomOption: CustomCheckboxDropdownOptionInterface = {
        value: newCustomValue,
        color: newCustomColor,
      };
      setLocalOptions((prevOptions) => {
        const updatedOptions = [...prevOptions, newCustomOption];
        if (onChange) {
          onChange({ selectedOption: personName, updatedOptions });
        }
        return updatedOptions;
      });
    }
    setAddingCustom(false);
    setNewCustomValue("");
    setNewCustomColor("#000000");
  };

  const handleClose = () => {
    if (addingCustom && newCustomValue?.trim() !== "") {
      handleSaveCustomClick();
    }
    setAddingCustom(false);
    setOpen(false);
  };

  const handleColorChange = (value: string, color: string) => {
    setLocalOptions((prevOptions) =>
      prevOptions?.map((option) =>
        option?.value === value ? { ...option, color: color } : option
      )
    );
  };

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    const selectedValues = typeof value === "string" ? value?.split(",") : value;
    setPersonName(selectedValues);
    if (onChange) {
      onChange({
        selectedOption: selectedValues,
        updatedOptions: localOptions,
      });
    }
  };

  const MenuProps = {
    PaperProps: {
      className: S["custom-checkbox-dropdown-mt__menu"],
      style: { width: width },
    },
  };

  return (
    <div>
      <FormControl style={{ width }} {...props}>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          displayEmpty
          IconComponent={() => null}
          value={personName} // Use personName as value
          onChange={handleChange}
          input={
            <OutlinedInput
              className={S["custom-checkbox-dropdown-mt__inputbox"]}
              classes={{
                notchedOutline:
                  S["custom-checkbox-dropdown-mt__notched-outline"],
                focused: S["custom-checkbox-dropdown-mt__focused"],
              }}
              style={{ outline: "none" }}
            />
          }
          onOpen={() => setOpen(true)}
          onClose={handleClose}
          renderValue={(selected) => {
            if (selected?.length === 0) {
              return (
                <em
                  className={
                    S["custom-checkbox-dropdown-mt__select-placeholder"]
                  }
                >
                  {placeHolder}
                </em>
              );
            }

            return (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: "5px 10px" }}>
                {selected?.map((value) => {
                  const option = localOptions?.find(
                    (opt) => opt?.value === value
                  )!;
                  return (
                    <span
                      key={value}
                      style={{
                        width: badgeWithoutText ? "25px" : `auto`,
                        height: badgeWithoutText ? "15px" : `auto`,
                        font: "normal normal 600 12px/16px Source Serif Pro",
                        padding: "2px 5px",
                        boxSizing: "border-box",
                        borderRadius: "5px",
                        backgroundColor: adjustHexColor(option.color, 35),
                      }}
                    >
                      {!badgeWithoutText && option?.value}
                    </span>
                  );
                })}
              </Box>
            );
          }}
          MenuProps={MenuProps}
        >
          {localOptions.map((item) => (
            <MenuItem
              className={S["custom-checkbox-dropdown-mt__menu-item"]}
              key={item.value}
              value={item.value}
            >
              <>
                <CheckboxMedium
                  boxSize={14}
                  checked={personName?.includes(item?.value)}
                />
                <ListItemText
                  primary={item?.value}
                  primaryTypographyProps={{
                    className: S["custom-checkbox-dropdown-mt__list-item-text"],
                  }}
                />
                <div onMouseDown={stopPropagation} onClick={stopPropagation}>
                  <ColorPickerDot
                    initialColor={item?.color}
                    onColorChange={(color) =>
                      handleColorChange(item?.value, color)
                    }
                  />
                </div>
              </>
            </MenuItem>
          ))}
          {addingCustom ? (
            <Stack className={S["custom-checkbox-dropdown-mt__menu-item"]}>
              <Stack
                direction="row"
                width={"100%"}
                spacing={1}
                alignItems="center"
              >
                <InputBase
                  value={newCustomValue}
                  onChange={(e) => setNewCustomValue(e.target.value)}
                  fullWidth
                  onMouseDown={stopPropagation}
                  onClick={stopPropagation}
                  onKeyDown={stopPropagation}
                  onKeyUp={stopPropagation}
                  sx={{
                    height: "20px",
                    border: "0.5px solid #D9E3EF",
                    font: "normal normal 300 12px/16px Source Serif Pro",
                  }}
                />
                <div onMouseDown={stopPropagation} onClick={stopPropagation}>
                  <ColorPickerDot
                    onColorChange={(color) => setNewCustomColor(color)}
                  />
                </div>
              </Stack>
            </Stack>
          ) : (
            <MenuItem className={S["custom-checkbox-dropdown-mt__menu-item"]}>
              <Tooltip title="Add new priority" placement="right" arrow>
                <ListItemText
                  onClick={handleAddCustomClick}
                  primary="Add Custom"
                  primaryTypographyProps={{
                    className: S["custom-checkbox-dropdown-mt__list-item-text"],
                  }}
                />
              </Tooltip>
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  );
};

export default CustomCheckboxDropdownMultiple;
