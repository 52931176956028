import { PayloadAction } from "@reduxjs/toolkit";
import { CommentState } from "../CommentsType";


export const addCommentToTask__Failure = (
    state: CommentState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

export const addCommentToDocument__Failure = (
    state: CommentState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

export const addCommentToWhiteboard__Failure = (
    state: CommentState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

export const addCommentToProject__Failure = (
    state: CommentState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

export const toggleReactionToComment__Failure = (
    state: CommentState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

export const addCommentToCell__Failure = (
    state: CommentState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

export const addSubCommentToComment__Failure = (
    state: CommentState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

export const toggleArchiveComment__Failure = (
    state: CommentState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

export const toggleResolveComment__Failure = (
    state: CommentState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

export const editComment__Failure = (
    state: CommentState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

export const getAllSubComments__Failure = (
    state: CommentState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

export const getAllComments__Failure = (
    state: CommentState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};
