import React, { useEffect, useState } from "react";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import {
  CallJoinedUserProps,
  CallWaitingUserProps,
} from "./InCallGuestMenuType";
import { AddedUserTabView, WaitingUserTabView } from "./tabs";
import styles from "./InCallGuestMenu.module.css";
import { useSelector } from "react-redux";
import {useTranslation} from "react-i18next";

interface InCallGuestMenuProps {
  joinedUserList?: CallJoinedUserProps[];
  waitingUserList?: CallWaitingUserProps[];
}

const InCallGuestMenu: React.FC<InCallGuestMenuProps> = ({
  joinedUserList = [],
  waitingUserList = [],
}) => {
  const {t}=useTranslation()
  const { user } = useSelector((state: any) => state.auth);
  const { onGoingMeeting } = useSelector((state: any) => state.videoCenter);
  const [displayValue, setDisplayValue] = useState<"added" | "waiting">(
    "added"
  );

  const waitingListCount = waitingUserList.length;
  const waitingListCountPadded = waitingListCount.toString().padStart(4, "0");

  useEffect(() => {
    if (waitingListCount === 0) {
      setDisplayValue("added");
    }
  }, [waitingListCount]);

  return (
    <Stack className={styles["inCallGuestMenu"]}>
      <Stack className={styles["inCallGuestMenu__header"]}>
        <Tooltip
          title={displayValue === "waiting" ? t('VIDEO_CENTER.MEETING.SHOW_ALL_PARTICIPANTS'): ""}
          placement="top"
          arrow
        >
          <IconButton
            className={styles["inCallGuestMenu__iconButton"]}
            disableRipple
            onClick={() => setDisplayValue("added")}
          >
            <Typography className={styles["inCallGuestMenu__participantsText"]}>
              {t('VIDEO_CENTER.MEETING.PARTICIPANTS')}
            </Typography>
          </IconButton>
        </Tooltip>
        <Stack className={styles["inCallGuestMenu__separator"]} />
        {onGoingMeeting.meeting?.meetingHostId === user?.data?._id
          ? waitingListCount > 0 && (
              <Tooltip
                title={displayValue === "added" ? t('VIDEO_CENTER.MEETING.SHOW_WAITING_USERS') : ""}
                placement="top"
                arrow
              >
                <IconButton
                  className={styles["inCallGuestMenu__iconButton"]}
                  disableRipple
                  onClick={() => setDisplayValue("waiting")}
                >
                  <Typography
                    className={styles["inCallGuestMenu__waitingText"]}
                  >
                    {waitingListCountPadded} {t("VIDEO_CENTER.MEETING.WAITING_ROOM")}
                  </Typography>
                </IconButton>
              </Tooltip>
            )
          : null}
      </Stack>

      {displayValue === "added" ? (
        <AddedUserTabView joinedUserList={joinedUserList} />
      ) : (
        <WaitingUserTabView waitingUserList={waitingUserList} />
      )}
    </Stack>
  );
};

export default InCallGuestMenu;
