import { WeekDaysList } from "../../../../../Utils";

export type DaysTimeSchedule = {
  day: WeekDaysList;
  startTime: string;
  endTime: string;
  isActive?: boolean; // Assuming it's optional
};

export interface CalendarScheduleMenuListInterface {
  _id?: string;
  name: string;
  schedule: DaysTimeSchedule[]; // Change days to schedule
}

export const DUMMY_CalendarScheduleMenu_OPTIONS: CalendarScheduleMenuListInterface[] =
  [
    {
      _id: "1",
      name: "name 1",
      schedule: [
        { day: "monday", startTime: "10:15 am", endTime: "09:30 pm" },
        { day: "wednesday", startTime: "11:30 pm", endTime: "06:30 pm" },
      ],
    },
  ];
