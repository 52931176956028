import React, { useState } from "react";
import { Stack, TextField } from "@mui/material";

interface PhoneViewProps {
  initialValue?: string;
  onChange?: (phone: string) => void;
}

const PhoneView: React.FC<PhoneViewProps> = ({
  initialValue = "",
  onChange,
}) => {
  const [phone, setPhone] = useState<string>(initialValue);
  const [isValid, setIsValid] = useState<boolean>(true);

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPhone(value);

    // Validate phone number using a regular expression (US phone number format)
    const phonePattern = /^\d{3}-\d{3}-\d{4}$/;
    const valid = phonePattern.test(value);
    setIsValid(valid);

    if (valid && onChange) {
      onChange(value); // Pass the valid phone number back to the parent component
    }
  };

  return (
    <Stack>
      <TextField
        label=""
        value={phone}
        onChange={handlePhoneChange}
        error={!isValid}
        helperText={
          !isValid
            ? "Please enter a valid phone number (e.g., 123-456-7890)."
            : ""
        }
        placeholder="123-456-7890"
        fullWidth      variant="outlined" // Use outlined variant for better control over styles
        sx={{
          "& .MuiOutlinedInput-root": {
            border: "none", // Remove border
            "& fieldset": {
              border: "none", // Remove fieldset border
            },
            "&:hover fieldset": {
              border: "none", // Remove border on hover
            },
            "&.Mui-focused fieldset": {
              border: "none", // Remove border on focus
              outline: "none", // Remove outline on focus
            },
          },
          padding: 0,
          font: "normal normal 300 13px/17px Source Serif Pro",
        }}
      />
    </Stack>
  );
};

export default PhoneView;
