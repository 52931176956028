import React, { useState, useEffect } from "react";
import styles from "./ApprovedView.module.css";
import { adjustHexColor } from "../../../../../../../../../Utils";

interface ApprovedViewOptionInterface {
  id: number | string;
  initialValue: string;
  changedValue: string;
  initialColor: string;
  selectedColor: string;
}

interface ApprovedViewProps {
  options: ApprovedViewOptionInterface[];
  initialValues?: string;
  onChange?: (isApproved: boolean) => void;
}

const ApprovedView: React.FC<ApprovedViewProps> = ({
  options,
  initialValues,
  onChange,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>(
    initialValues || ""
  );

  useEffect(() => {
    setSelectedOption(initialValues || "");
  }, [initialValues]);

  const handleOptionClick = (value: string) => {
    setSelectedOption(value);
    if (onChange) {
      onChange(value === "Approved");
    }
  };

  return (
    <div className={styles["approved-view"]}>
      {options?.map((option) => (
        <div
          key={option?.id}
          className={`${styles["approved-view__option"]} ${
            selectedOption === option?.changedValue
              ? styles["approved-view__option--selected"]
              : ""
          }`}
          style={{
            backgroundColor:
              selectedOption === option?.changedValue
                ? adjustHexColor(option?.selectedColor, 25)
                : "transparent",
          }}
          onClick={() => handleOptionClick(option?.changedValue)}
        >
          <span className={styles["approved-view__option-text"]}>
            {option?.changedValue}
          </span>
        </div>
      ))}
    </div>
  );
};

export default ApprovedView;
