import { IconButtonProps } from "@mui/material";
import React from "react";
import { VideoActionRoundedButtons } from "../../../../common";
import { PlayIcon } from "../../../../../images";
import {useTranslation} from "react-i18next";

interface PlayButtonProps extends IconButtonProps {
  initialStatus: boolean;
  onClickChange: (status: boolean) => void;
}

const PlayButton: React.FC<PlayButtonProps> = ({
  initialStatus,
  onClickChange,
  ...props
}) => {
  const {t}=useTranslation()
  return (
    <VideoActionRoundedButtons
      activeColor="#000AFF"
      inactiveColor="#FF8181"
      activeHoverText={t("PAST_CALL_DETAILS.PAUSE")}
      inActiveHoverText={t("PAST_CALL_DETAILS.PLAY")}
      icon={PlayIcon}
      initialStatus={initialStatus}
      onClickChange={onClickChange}
      {...props}
    />
  );
};
export default PlayButton;
