import React from "react";
import { Popover, Stack } from "@mui/material";
import { ColoredBadge } from "../../../../common";
import { CategoryMenu } from "../MenuScroll";

// Define the types for the badge data
type BadgeData = {
  id: string;
  title: string;
  bgColor: string;
};
// TypeScript interface for component props
interface Category {
  id: string;
  name: string;
  color: string;
  _id?: string;
}

type CategoriesTableProps = {
  words: (Category | string)[]; // Allow either app categories or Outlook categories (strings)
  p?: any;
};

// Function to generate a random color
const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

// Function to ensure each category has an id and a color
const normalizeCategory = (
  category: Category | string,
  index: number
): Category => {
  if (typeof category === "string") {
    console.log("normalizeCategory ---- true ----");
    return {
      id: `outlook-${index}`, // Generate an id for the Outlook category
      name: category,
      color: getRandomColor(), // Assign a random color
    };
  }
  return {
    ...category,
    color: category?.color || getRandomColor(),
    name: String(category?.name), // Cast to string to ensure consistency
  };
};

const CategoriesTable: React.FC<CategoriesTableProps> = ({ words, p }) => {
  // Map over categories and normalize them
  const renderedCategories =
    p &&
    p?.data?.categories?.map((category: any, index: number) =>
      normalizeCategory(category, index)
    );
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const predefinedBadges: BadgeData[] = [
    { id: "1", title: "3D Printing", bgColor: "green" },
    { id: "2", title: "Accounting", bgColor: "blue" },
    { id: "3", title: "Aerospace", bgColor: "red" },
    { id: "4", title: "Agriculture", bgColor: "green" },
    { id: "5", title: "Airline", bgColor: "blue" },
    { id: "6", title: "Animation", bgColor: "red" },
    { id: "7", title: "Apparel", bgColor: "green" },
    { id: "8", title: "Architecture", bgColor: "blue" },
    { id: "9", title: "Arts", bgColor: "red" },
    { id: "10", title: "Asset Management", bgColor: "red" },
  ];

  const handleBadgeSelection = (id: string) => {
    console.log(id, "Badge Selected");
    handleClose();
  };

  return (
    <>
      <Stack
        direction={"row"}
        onClick={(e: any) => handleClick(e)}
        alignItems={"center"}
        flexWrap={"wrap"}
        gap={1}
        justifyContent={"center"}
      >
        {renderedCategories?.map((category: any, index: any) => (
          <ColoredBadge
            key={index}
            title={category?.name || "N/A"}
            sx={{ height: "20px", p: "0px 10px" }}
            bgColor={category?.color || "#000000"}
            bgColorOpacity={50}
          />
        ))}
      </Stack>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            boxShadow: "0.5px solid #E9EDF2",
            padding: "0px",
          },
        }}
      >
        <CategoryMenu
          badges={predefinedBadges}
          onBadgeSelect={handleBadgeSelection}
        />
      </Popover>
    </>
  );
};

export default CategoriesTable;
