import { Stack, SxProps, Typography } from "@mui/material";
import React from "react";
import DetailsView from "../DetailsView/DetailsView";
import {
  AngelListIcon,
  DateIcon,
  EmailIcon,
  EmploymentTypeIcon,
  FacebookIcon,
  InstagramIcon,
  JobTitleIcon,
  LastCalendarIcon,
  LinkedinIcon,
  NamebadgeIcon,
  Phone64Icon,
  XIcon,
} from "../../../../../../../images";
import { TextLinkButton } from "../../../../../../common";
import SocialMediaLinkButton from "../SocialMediaLinkButton/SocialMediaLinkButton";

interface RightContainerProps {
  sx?: SxProps;
}

const RightContainer: React.FC<RightContainerProps> = ({ sx }) => {
  return (
    <Stack sx={{ flex: 1, gap: "20px", ...sx }}>
      {/* details area */}
      <Stack gap={"25px"}>
        <Typography
          sx={{ font: "normal normal 400 15px/19px Source Serif Pro" }}
        >
          Advanced Details
        </Typography>
        <Stack sx={{ gap: "10px" }}>
          <DetailsView
            icon={NamebadgeIcon}
            iconHoverText="Nickname"
            value="name here"
          />
          <DetailsView
            icon={EmailIcon}
            iconHoverText="Email"
            value="jane@gmail.com"
          />
          <DetailsView
            iconHoverText="Mobile"
            icon={Phone64Icon}
            value="123456789"
          />
          <DetailsView
            iconHoverText="Home"
            icon={Phone64Icon}
            value="123456789"
          />
          <DetailsView
            icon={JobTitleIcon}
            iconHoverText="ID"
            value="DFSDF54155FSF"
          />
          <DetailsView
            icon={EmploymentTypeIcon}
            iconHoverText="Type"
            value="Full Time"
          />
          <DetailsView
            icon={DateIcon}
            iconHoverText="Start Day"
            value="05-03-2021"
          />
          <DetailsView
            icon={LastCalendarIcon}
            iconHoverText="End Day"
            value="N/A"
          />
        </Stack>
      </Stack>

      <Stack gap={"20px"}>
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
          <Typography
            sx={{ font: "normal normal 400 15px/19px Source Serif Pro" }}
          >
            Socials
          </Typography>

          <TextLinkButton
            buttonLabel="Edit"
            sx={{ color: "#308893" }}
            onClick={() => null}
          />
        </Stack>
        <Stack sx={{ gap: "10px" }}>
          <SocialMediaLinkButton
            icon={AngelListIcon}
            link="www.anglelist.com/janesmith"
          />
          <SocialMediaLinkButton icon={FacebookIcon} link="www.facebook.com" />
          <SocialMediaLinkButton
            icon={InstagramIcon}
            link="www.instagram.com"
          />

          <SocialMediaLinkButton
            icon={XIcon}
            link="www.anglelist.com/janesmith"
          />

          <SocialMediaLinkButton
            icon={LinkedinIcon}
            link="www.linkedin.com/janesmith/jdhajksdsa/d/asdsadasds"
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default RightContainer;
