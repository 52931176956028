import React, { useState, MouseEvent } from "react";
import { IconButton, Stack, Popover, Typography } from "@mui/material";
import {
  ColorIcon,
  TeamMemberIcon,
  ThreeDotIconIcon,
} from "../../../../../images";
import {
  EmojiPickerSmall,
  LightCirclesColor,
  LightCirclesColorOptionsList,
  ShareButton,
} from "../../../../common";

interface ControlsCalendarProps {
  calColor?: string;
  handleUpdateAppearance?: any;
  _id?: string;
}

const ControlsCalendar: React.FC<ControlsCalendarProps> = ({
  calColor,
  handleUpdateAppearance,
  _id,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack>
      <IconButton
        sx={{ p: 0, borderRadius: 0 }}
        disableRipple
        onClick={handleClick}
      >
        <ThreeDotIconIcon w={15} />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            marginTop: "5px",
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            padding: "0px",
            borderRadius: "5px",
          },
        }}
      >
        <Stack sx={{ borderRadius: "5px", width: "120px" }}>
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              height: "30px",
              padding: "0px 10px",
              ":hover": { bgcolor: "#E9EDF2" },
            }}
          >
            <TeamMemberIcon w={15} />
            <ShareButton calendarId={_id} reStructure={true} />
          </Stack>
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              height: "30px",
              padding: "0px 10px",
              ":hover": { bgcolor: "#E9EDF2" },
            }}
          >
            <ColorIcon w={15} />
            <Typography
              sx={{
                font: "normal normal 300 13px/18px Source Serif Pro",
                marginRight: "10px",
              }}
            >
              Color
            </Typography>
            <LightCirclesColor
              initialSelect={calColor}
              onSelect={(v: any) => {
                handleUpdateAppearance && handleUpdateAppearance("color", v);
              }}
              colorsList={LightCirclesColorOptionsList}
            />
          </Stack>
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              height: "30px",
              padding: "0px 10px",
              ":hover": { bgcolor: "#E9EDF2" },
            }}
          >
            <EmojiPickerSmall
              onSelect={(emoji: any) => {
                handleUpdateAppearance &&
                  handleUpdateAppearance("icon", emoji?.native);
              }}
              showEmojiOnSelect
            />
            <Typography
              sx={{ font: "normal normal 300 13px/18px Source Serif Pro" }}
            >
              Icon
            </Typography>
          </Stack>
        </Stack>
      </Popover>
    </Stack>
  );
};

export default ControlsCalendar;
