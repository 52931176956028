import React, { useEffect, useRef, useState } from "react";
import {
  MenuItem,
  Stack,
  Typography,
  InputBase,
  IconButton,
  Button,
  Divider,
} from "@mui/material";
import Styles from "./DragAndDropMenu.module.css";
import { CheckboxMedium } from "../../CheckboxMedium";
import {
  AddCalPlusIcon,
  ChevronIcon,
  DragAndDropIcon,
  SearchIcon,
  StoreIcon,
} from "../../../../images";
import { allowDrop, handleDragStart, handleDrop } from "./DragAndDropMenuUtils";
import { DragAndDropMenuListType } from "../MenuTypes";
import { useTranslation } from "react-i18next";

type DragAndDropMenuProps = {
  label?: string;
  labelIcon?: React.ElementType;
  labelIconDirection?: "up" | "down" | "left" | "right";
  menuItems: DragAndDropMenuListType[];
  setMenuItems: React.Dispatch<React.SetStateAction<DragAndDropMenuListType[]>>;
  dragIconPlace?: "right" | "left";
  hideSearchBar?: boolean;
  maxHeight?: string;
  menuWidth?: string;
  backgroundColor?: string;
  multiple?: boolean;
  initialValue?: DragAndDropMenuListType[];
  onMenuSelect?: (data: DragAndDropMenuListType[]) => void;
} & (
  | {
      AddButtonLabel?: never;
      AddButtonClick?: never;
    }
  | {
      AddButtonLabel: string;
      AddButtonClick: () => void;
    }
);

const DragAndDropMenu: React.FC<DragAndDropMenuProps> = ({
  label,
  menuItems,
  setMenuItems,
  dragIconPlace = "left",
  AddButtonClick,
  labelIcon,
  labelIconDirection = "left",
  AddButtonLabel,
  hideSearchBar = false,
  multiple = false,
  maxHeight = "270px",
  menuWidth = "inherit",
  backgroundColor = "inherit",
  initialValue = [],
  onMenuSelect,
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const elementRef = useRef<HTMLDivElement>(null);
  const btnElementRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number | null>(null);
  const [btnWidth, setBtnWidth] = useState<number | null>(null);

  const [selectedValues, setSelectedValues] =
    useState<DragAndDropMenuListType[]>(initialValue);

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchTerm(event.target.value);
  };

  const filteredItems = menuItems.filter(
    (item) => item.label?.toLowerCase().includes(searchTerm.toLowerCase())
    // item.translatedWord?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    if (elementRef.current) {
      setWidth(elementRef.current.offsetWidth);
    }
    if (btnElementRef.current) {
      setBtnWidth(btnElementRef.current.offsetWidth);
    }
  }, []);

  const textMaxWidth = (isSublist: boolean = false) => {
    let value: number = 60;
    if (multiple) {
      value = value + 15;
    }
    if (isSublist) {
      value = value + 30;
    }
    return value;
  };

  const handleClick = (menuItem: DragAndDropMenuListType) => {
    setSelectedValues((prevSelected) => {
      if (multiple) {
        if (prevSelected.some((item) => item.label === menuItem.label)) {
          // Remove the item if it's already selected
          return prevSelected.filter((item) => item.label !== menuItem.label);
        } else {
          // Add the item to the selection if it's not selected
          return [...prevSelected, menuItem];
        }
      } else {
        return [menuItem];
      }
    });
  };

  useEffect(() => {
    if (onMenuSelect && selectedValues.length > 0) {
      onMenuSelect(selectedValues);
    }
  }, [selectedValues]);

  return (
    <div
      style={{
        width: menuWidth,
        padding: "10px 0px 3px 0px",
        backgroundColor: backgroundColor,
        borderRadius: "5px",
      }}
    >
      {label && (
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            padding: "0px 10px 3px 10px",
            gap: "5px",
          }}
        >
          {labelIcon ? (
            React.createElement(labelIcon, {
              w: 15,
              color: "black",
              direction: labelIconDirection,
            })
          ) : (
            <ChevronIcon w={15} direction="left" />
          )}

          <Typography
            sx={{
              font: "normal normal normal 12px/13px Source Serif Pro",
              paddingTop: "1px",
            }}
          >
            {label}
          </Typography>
        </Stack>
      )}

      {!hideSearchBar && (
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            boxSizing: "border-box",
            width: "100%",
            height: "30px",
            padding: "0px 10px",
          }}
        >
          <InputBase
            placeholder={t("VIDEO_CENTER.MEETING.SEARCH")}
            sx={{ font: "normal normal normal 14px/20px Source Serif Pro" }}
            value={searchTerm}
            onChange={handleSearchInputChange}
          />
          <SearchIcon w={20} />
        </Stack>
      )}

      <Stack
        sx={{ maxHeight: maxHeight, overflow: "auto", scrollbarWidth: "none" }}
      >
        {filteredItems.map((item, index) => (
          <>
            <MenuItem
              onClick={() => handleClick(item)}
              sx={{
                flexDirection: "row",
                alignItems: "center",
                minHeight: "auto",
                padding: "0px 10px 0px 10px",
              }}
              key={index}
            >
              <div
                ref={elementRef}
                data-index={index}
                draggable={item.draggable}
                onDragStart={(e) => handleDragStart(e, index)}
                onDrop={(e) => handleDrop(e, menuItems, setMenuItems)}
                onDragOver={allowDrop}
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "30px",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    flexDirection:
                      dragIconPlace === "left" ? "row" : "row-reverse",
                  }}
                >
                  <IconButton
                    disabled={!item.draggable}
                    style={{
                      cursor: item.draggable ? "move" : "default",
                      width: "32px",
                    }}
                  >
                    {item.draggable && <DragAndDropIcon w={15} />}
                  </IconButton>
                  <Stack
                    sx={{
                      flexDirection: "row",
                      alignItems: "center",
                      flex: 1,
                      justifyContent: "space-between",
                      gap: "10px",
                    }}
                  >
                    <Stack
                      sx={{
                        flexDirection: "row",
                        alignItems: "center",
                        flex: 1,
                        justifyContent: "start",
                        gap: "5px",
                      }}
                    >
                      {item.icon ? (
                        React.createElement(item.icon, { w: 15 })
                      ) : (
                        <StoreIcon w={15} />
                      )}
                      <Typography
                        className={Styles.dropDownMenuTextOption}
                        sx={{
                          maxWidth: width
                            ? `${
                                width -
                                textMaxWidth(
                                  item.subList?.length ? true : false
                                )
                              }px`
                            : "100px",
                          // maxWidth: width ? `${width - 60}px` : "100px",
                          scrollbarWidth: "none",
                        }}
                      >
                        {item.translatedWord
                          ? t(item.translatedWord)
                          : item.label}
                      </Typography>
                    </Stack>
                    {item.subList && (
                      <Stack
                        sx={{
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <Typography
                          sx={{
                            font: "normal normal normal 12px/13px Source Serif Pro",
                            paddingTop: "1px",
                          }}
                        >
                          {item.subList.length}
                        </Typography>
                        <ChevronIcon w={15} direction="right" />
                      </Stack>
                    )}
                  </Stack>
                </Stack>
                {multiple && (
                  <CheckboxMedium
                    boxSize={15}
                    checked={selectedValues.some((value) => value == item)}
                  />
                )}
              </div>
            </MenuItem>
          </>
        ))}
      </Stack>

      {AddButtonLabel && (
        <>
          <Divider sx={{ margin: "0px 10px 8px 10px" }} />
          <Button
            onClick={AddButtonClick}
            variant="text"
            sx={{
              width: "100%",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              flexShrink: 0,
              minHeight: 0,
              borderRadius: 0,
              height: "30px",
              textTransform: "none",
              padding: "0px 10px",
              bgcolor: "inherit",
              color: "black",
              ":hover": {
                backgroundColor: "#E9EDF2",
              },
            }}
          >
            <Stack
              ref={btnElementRef}
              sx={{
                flexDirection: "row",
                alignItems: "center",
                flex: 1,
                gap: "5px",
              }}
            >
              <AddCalPlusIcon w={16} />
              <Typography
                sx={{
                  font: "normal normal normal 14px/20px Source Serif Pro",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: btnWidth ? `${btnWidth - 20}px` : "130px",
                }}
              >
                {AddButtonLabel}
              </Typography>
            </Stack>
            <ChevronIcon w={15} direction="right" />
          </Button>
        </>
      )}
      {/* </Stack> */}
    </div>
  );
};

export default DragAndDropMenu;
