import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { TravelTimeMenu } from "../../../TravelTimeMenu";
import { StyledRadioButtonBlack } from "../../../../../../common";
import { useTranslation } from "react-i18next";

interface BufferTimeViewProps {
  updateField?: any;
}

interface TravelTime {
  time: 0 | 15 | 30 | 45 | 60 | 90 | 120;
  direction: "before" | "after" | "both" | undefined; // Direction of travel time
}

const BufferTimeView: React.FC<BufferTimeViewProps> = ({ updateField }) => {
  const { t } = useTranslation();
  const [travelBefore, setTravelBefore] = useState<boolean>(false);
  const [travelAfter, setTravelAfter] = useState<boolean>(false);
  const [bufferTime, setTravelTime] = useState<TravelTime>({
    time: 0, // Default time
    direction: undefined, // Initial direction
  });

  // Function to update travel time and direction
  const updateTravelTime = (
    time: 0 | 15 | 30 | 45 | 60 | 90 | 120,
    before: boolean,
    after: boolean
  ) => {
    const direction =
      before && after
        ? "both"
        : before
        ? "before"
        : after
        ? "after"
        : undefined; // No direction if neither is selected

    const updatedTravelTime: TravelTime = {
      time,
      direction,
    };

    setTravelTime(updatedTravelTime); // Update the local state

    updateField("bufferTime", updatedTravelTime); // Call the function to update the parent state
  };

  return (
    <Stack sx={{ flexDirection: "row", gap: "15px", alignItems: "center" }}>
      <Typography sx={{ font: "normal normal 300 15px/19px Source Serif Pro" }}>
        {t("CALENDAR.BUFFER_TIME")}
      </Typography>

      <TravelTimeMenu
        options="TravelTimeMenuOptionsList120"
        onSelect={(value) => {
          updateTravelTime(
            value as 15 | 30 | 45 | 60 | 90 | 120,
            travelBefore,
            travelAfter
          );
        }}
      />

      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
        <StyledRadioButtonBlack
          radioSize={15}
          checked={travelBefore} // Bind checked state
          onClick={() => {
            const newValue = !travelBefore;
            setTravelBefore(newValue);
            // Pass the updated before value to the update function
            updateTravelTime(bufferTime.time, newValue, travelAfter);
          }}
        />
        <Typography
          sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
          onClick={() => {
            const newValue = !travelBefore;
            setTravelBefore(newValue);
            // Pass the updated before value to the update function
            updateTravelTime(bufferTime.time, newValue, travelAfter);
          }}
        >
          {t("CALENDAR.BEFORE")}
        </Typography>
      </Stack>
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
        <StyledRadioButtonBlack
          radioSize={15}
          checked={travelAfter} // Bind checked state
          onClick={() => {
            const newValue = !travelAfter;
            setTravelAfter(newValue);
            // Pass the updated after value to the update function
            updateTravelTime(bufferTime.time, travelBefore, newValue);
          }}
        />
        <Typography
          sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
          onClick={() => {
            const newValue = !travelAfter;
            setTravelAfter(newValue);
            // Pass the updated after value to the update function
            updateTravelTime(bufferTime.time, travelBefore, newValue);
          }}
        >
          {t("CALENDAR.AFTER")}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default BufferTimeView;
