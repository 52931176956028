import {
  IconButton,
  Stack,
  Typography,
  Popover,
  Zoom,
  Button,
  Box,
  Modal,
} from "@mui/material";
import React, { useState } from "react";
import { AddCalPlusIcon, GreenDropdownIcon } from "../../../../../images";
import styles from "./SidebarCalendarContainer.module.css";
import { CalendarSettingsEditPopup } from "../CalendarSettingsEditPopup";

interface SidebarCalendarContainerProps {
  label: string;
  viewAllButtonClick: () => void;
  children: React.ReactNode;
  getAllCalendar?: any;
  cl?: any;
}

const SidebarCalendarContainer: React.FC<SidebarCalendarContainerProps> = ({
  children,
  label,
  viewAllButtonClick,
  getAllCalendar,
  cl,
}) => {
  const [isOpened, setIsOpened] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleAddCalPlusClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const isPopoverOpen = Boolean(anchorEl);

  const [zoomType, setZoomType] = useState<null | number>(null);

  const handleClickItem = (itemNameIndex: number) => {
    // Set zoomType based on itemNameIndex. Allow 0 as valid index
    if (itemNameIndex !== null) {
      setZoomType(itemNameIndex);
    } else {
      setZoomType(null);
    }
  };

  return (
    <Stack
      className={styles["sidebar-calendar-container"]}
      style={{ maxHeight: !isOpened ? "20px" : "200px" }}
    >
      <Stack className={styles["sidebar-calendar-container__header"]}>
        <Typography
          onClick={() => setIsOpened((value) => !value)}
          className={styles["sidebar-calendar-container__label"]}
        >
          {label}
        </Typography>
        <Stack className={styles["sidebar-calendar-container__divider"]} />

        <IconButton
          className={styles["sidebar-calendar-container__icon-button"]}
          disableRipple
          onClick={handleAddCalPlusClick}
        >
          <AddCalPlusIcon w={15} />
        </IconButton>

        <IconButton
          className={styles["sidebar-calendar-container__icon-button"]}
          disableRipple
          onClick={() => setIsOpened((value) => !value)}
        >
          <GreenDropdownIcon sx={{ height: "10px" }} open={isOpened} />
        </IconButton>
      </Stack>

      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            marginTop: "5px",
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            padding: "0px",
            borderRadius: "5px",
          },
        }}
      >
        <Stack
          sx={{
            width: "165px",
            boxSizing: "border-box",
            borderRadius: "5px",
            gap: "2px",
          }}
        >
          {[
            "Subscribe to calendar",
            "Create new calendar",
            "From URL",
            "Search for calendar",
          ].map((item, index) => (
            <Button
              key={index}
              sx={{
                p: "4px 0px 4px 10px",
                borderRadius: "5px",
                color: "black",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "start",
              }}
              onClick={() => {
                handleClickItem(index);
              }}
              disabled={index === 0 || index === 2 || index === 3} // Disable 1st, 3rd, and 4th buttons
            >
              <Typography
                sx={{
                  textTransform: "capitalize",
                  font: "normal normal 300 15px/19px Source Serif Pro",
                }}
              >
                {item}
              </Typography>
            </Button>
          ))}
        </Stack>
      </Popover>

      <Stack className={styles["sidebar-calendar-container__content"]}>
        {children}
      </Stack>

      {cl > 5 && (
        <Stack className={styles["sidebar-calendar-container__footer"]}>
          <Typography
            onClick={viewAllButtonClick}
            className={styles["sidebar-calendar-container__view-all"]}
          >
            View all
          </Typography>
        </Stack>
      )}

      <Modal
        open={zoomType !== null} // Open when zoomType is not null
        onClose={() => setZoomType(null)} // Handle modal close
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Zoom in={zoomType !== null}>
            <div>
              {zoomType === 1 && (
                <CalendarSettingsEditPopup
                  createTrue={true}
                  getAllCalendar={getAllCalendar}
                  setZoomType={setZoomType}
                />
              )}
            </div>
          </Zoom>
        </Box>
      </Modal>
    </Stack>
  );
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 0,
};
export default SidebarCalendarContainer;
