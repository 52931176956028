import { IconButton, Link, Stack, Typography } from "@mui/material";
import React from "react";
import { NamePlateCloudStorage } from "../../../NamePlateCloudStorage";

interface AnalyticsViewProps {}

const AnalyticsView: React.FC<AnalyticsViewProps> = ({}) => {
  return (
    <Stack gap={"20px"}>
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "start",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            font: "normal normal 400 14px/18px Source Sarif Pro",
            paddingBottom: "5px",
            borderBottom: "1px solid #E9EDF2",
          }}
        >
          Link Analytics
        </Typography>

        <Link
          underline="hover"
          sx={{
            font: "normal normal 400 14px/18px Source Sarif Pro",
            color: "#000AFF",
            cursor: "pointer",
          }}
        >
          View More
        </Link>
      </Stack>

      {/* ---- scrollable container ---- */}
      <Stack
        sx={{
          gap: "20px",
          maxHeight: "220px",
          overflowY: "scroll",
          scrollbarWidth: "none",
        }}
      >
        <NamePlateCloudStorage
          agent="iPad, iPadOS, macbook, linux, android"
          dateStamp="December 24, 2023"
          timeStamp="10:25 pm"
          downloaded={true}
          location="San Francisco CA USA San Francisco Bay Area, Northern California, United States of America"
          nameOrEmail="Richard Rightwaterson"
          visitLink="link one"
          visitTime="03 hrs 20 mins"
        />
        <NamePlateCloudStorage
          agent="iPad, iPadOS, macbook, linux, android"
          dateStamp="December 24, 2023"
          timeStamp="10:25 pm"
          downloaded={true}
          location="San Francisco CA USA San Francisco Bay Area, Northern California, United States of America"
          nameOrEmail="Richard Rightwaterson"
          visitLink="link one"
          visitTime="03 hrs 20 mins"
        />
        <NamePlateCloudStorage
          agent="iPad, iPadOS, macbook, linux, android"
          dateStamp="December 24, 2023"
          timeStamp="10:25 pm"
          downloaded={true}
          location="San Francisco CA USA San Francisco Bay Area, Northern California, United States of America"
          nameOrEmail="Richard Rightwaterson"
          visitLink="link one"
          visitTime="03 hrs 20 mins"
        />
        <NamePlateCloudStorage
          agent="iPad, iPadOS, macbook, linux, android"
          dateStamp="December 24, 2023"
          timeStamp="10:25 pm"
          downloaded={true}
          location="San Francisco CA USA San Francisco Bay Area, Northern California, United States of America"
          nameOrEmail="Richard Rightwaterson"
          visitLink="link one"
          visitTime="03 hrs 20 mins"
        />
        <NamePlateCloudStorage
          agent="iPad, iPadOS, macbook, linux, android"
          dateStamp="December 24, 2023"
          timeStamp="10:25 pm"
          downloaded={true}
          location="San Francisco CA USA San Francisco Bay Area, Northern California, United States of America"
          nameOrEmail="Richard Rightwaterson"
          visitLink="link one"
          visitTime="03 hrs 20 mins"
        />
      </Stack>
      {/* ---- scrollable container end ---- */}
    </Stack>
  );
};

export default AnalyticsView;
