import { Stack, SxProps, Typography } from "@mui/material";
import React from "react";

interface ValueAreaProps {
  width?: string;
  label: string;
  value: string;
  hideRightBorder?: boolean;
  sx?: SxProps;
}

const ValueArea: React.FC<ValueAreaProps> = ({
  label,
  value,
  width,
  sx,
  hideRightBorder = false,
}) => {
  return (
    <Stack
      sx={{
        boxSizing: "border-box",
        padding: "10px 0px 0px 10px",
        gap: "10px",
        flexShrink: 0,
        width: width,
        borderRight: hideRightBorder
          ? "1px solid inherit"
          : "1px solid #E9EDF2",
        ...sx,
      }}
    >
      <Typography sx={{ font: "normal normal 600 11px/14px Source Serif Pro" }}>
        {label}
      </Typography>
      <Typography sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}>
        {value}
      </Typography>
    </Stack>
  );
};

export default ValueArea;
