import {
  Box,
  Breadcrumbs,
  Grow,
  Link,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CloudStorageItemGrid,
  CloudStorageItemList,
  DetailsCloudStorage,
  ItemMainInsights,
  LinkDetails,
  MediumSearchbar,
  SwitchSmall,
} from "../../common";
import { ViewMode } from "../Projects";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CloudStorageState,
  deleteFile__api,
  downloadFile__api,
  getAllFiles__api,
  getAllFolders__api,
  getDetailsSliderState,
  selectedFile,
  toggleRefreshFiles,
  updateFile__api,
} from "../../../redux";
import { RootState } from "../../../redux/store";
import { DarkFolderIcon, NoFileFolder } from "../../../images";
import FileActionDialogRenameMove from "./components/FileActionDialogRenameMove/FileActionDialogRenameMove";

const CloudStorage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const cloudStorageState = useSelector(
    (state: RootState) => state?.cloudStorage as CloudStorageState
  );

  const {
    bearishOSCloudStorage,
    refreshFiles = false,
    bearishOSCloudStorageFolders,
    detailsSlider = null,
  } = cloudStorageState || {};

  const [fileFolderLoader, setFileFolderLoader] = useState({
    initialization: true,
    refreshPanel: false,
  });

  const [detailsSliderState, setDetailsSliderState] = useState(detailsSlider);

  useEffect(() => {
    if (detailsSlider) {
      setDetailsSliderState(detailsSlider);
    }
  }, [detailsSlider]);

  const [items, setItems] = useState<any[]>([]);
  const [currentDir, setCurrentDir] = useState<string>(""); // Track current directory

  // Set the currentDir based on the URL query parameter
  useEffect(() => {
    const searchParams = new URLSearchParams(location?.search);
    const dirParam = searchParams?.get("dir") || ""; // Set to empty string if there's no dir param (root level)
    setCurrentDir(dirParam);
  }, [location?.search]);

  const handleItemClick = (id: string) => {
    setItems((prevItems: any) => {
      const updatedItems = prevItems?.map((item: any) =>
        item?._id === id ? { ...item, isSelected: !item.isSelected } : item
      );
      return updatedItems;
    });
  };
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedFileData, setSelectedFileData] = useState<any>(null);
  const [actionSelect, setActionSelect] = useState<string>("");

  const handleGetAllFileFolders = async () => {
    setFileFolderLoader({ ...fileFolderLoader, refreshPanel: true });
    await dispatch(getAllFiles__api());
    setFileFolderLoader({ initialization: false, refreshPanel: false });
    dispatch(toggleRefreshFiles(false));
  };

  const handleGetAllFolders = async () => {
    setFileFolderLoader({ ...fileFolderLoader, refreshPanel: true });
    await dispatch(getAllFolders__api());
    setFileFolderLoader({ initialization: false, refreshPanel: false });
    dispatch(toggleRefreshFiles(false));
  };

  const handleNavigate = (fileId?: string, fileName?: string, item?: any) => {
    if (!fileId || !fileName) return;

    const sanitizedFileId = encodeURIComponent(fileId);
    const sanitizedFileName = encodeURIComponent(fileName);
    const fileExtension = sanitizedFileName?.split(".")?.pop();
    if (!fileExtension) {
      console.error("Invalid file extension:", fileExtension);
      return;
    }

    if (!item) {
      return;
    }

    const newDir = `${currentDir}`?.replace("//", "/"); // Navigate deeper by updating currentDir
    setCurrentDir(newDir);

    dispatch(selectedFile(item));

    navigate(
      `/cloud-storage/${sanitizedFileId}/${fileExtension}?dir=${newDir}`
    );
  };

  // Folder click logic, passing both folderName and directory
  const handleFolderClick = (folderName: string, folderDirectory: string) => {
    let newDir = folderDirectory
      ? `${folderDirectory}/${folderName}`
      : `/${folderName}`; // Build new directory path
    newDir = newDir?.replace("//", "/"); // Ensure no double slashes
    setCurrentDir(newDir); // Update current directory
    navigate(`/cloud-storage/?dir=${newDir}`); // Update URL for deep linking
  };

  const [viewMode, setViewMode] = useState<ViewMode>("square");
  const [collapsed, setCollapsed] = useState<boolean>(false);

  useEffect(() => {
    if (refreshFiles) {
      handleGetAllFileFolders();
      handleGetAllFolders();
    }
  }, [refreshFiles]);

  useEffect(() => {
    if (bearishOSCloudStorage?.length) {
      setItems(bearishOSCloudStorage);
    }
  }, [bearishOSCloudStorage]);

  // Filter files that belong to the current directory
  const currentDirFiles = items?.filter(
    (item) => item?.directory === currentDir
  );

  // 2. Detect subfolders based on current directory from `bearishOSCloudStorageFolders`
  // Check if we are in the root directory
  const isRootDir = currentDir === "/" || currentDir === "";

  // Filter folders based on the current directory
  const subfolders = bearishOSCloudStorageFolders?.filter((folder: any) => {
    const folderDir = folder?.directory || "/"; // Default to root if directory is not set

    // Show root-level folders if we are at the root, or subfolders that match the current directory
    if (isRootDir) {
      return folderDir === "/";
    } else {
      return folderDir === currentDir; // Show folders that belong to the current directory
    }
  });

  const downloadFileAction = async (fileId: string) => {
    try {
      if (!fileId) {
        return;
      }

      const action = downloadFile__api(fileId);

      const dataurl: any = await dispatch(action);

      if (dataurl?.success && dataurl?.data?.url) {
        // Create a temporary link element
        const link = document.createElement("a");
        link.href = dataurl.data?.url; // Assuming `dataurl.data` contains the file URL or Blob
        link.download = "BearishOS-file-download"; // You can set the filename dynamically if needed

        // Append the link to the document and trigger a download
        document.body.appendChild(link);
        link.click();

        // Remove the link after download is triggered
        document.body.removeChild(link);
      }
    } catch (err) {
      console.error("Error downloading file:", err);
    }
  };

  const deleteFileAction = async (fileId: string) => {
    if (!fileId) {
      return;
    }
    try {
      const action = deleteFile__api(fileId);

      await dispatch(action);
    } catch (err) {
    } finally {
      dispatch(toggleRefreshFiles(true));
    }
  };

  const optionAction = (action: string, fileId?: string, fileData?: any) => {
    if (!action) {
      return;
    }

    if (action === "Details" || action === "Edit Tags") {
      setSelectedFileData(fileData);
      setCollapsed(true);
      dispatch(getDetailsSliderState("Main Details"));
    } // Pass original file data to the dialog}

    if (action === "Download") {
      if (!fileId) {
        return;
      }
      downloadFileAction(fileId);
    }
    // else if (action === "Delete") {
    //   if (!fileId) {
    //     return;
    //   }
    //   deleteFileAction(fileId);
    // }
    else if (
      action === "Rename" ||
      action === "Move" ||
      action === "Watermark" ||
      action === "Delete" ||
      action === "Upload New Version"
    ) {
      if (!fileId) {
        return;
      }
      setActionSelect(action);
      setDialogOpen(true); // Open the dialog
      setSelectedFileData(fileData); // Pass original file data to the dialog
    } else if (action === "Set Expiration" || action === "Lock") {
      setActionSelect(action);
      setDialogOpen(true); // Open the dialog
      setSelectedFileData(fileData); // Pass original file data to the dialog
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogSubmit = async (payload: any) => {
    if (!payload || !selectedFileData?._id) {
      return;
    }

    const action = updateFile__api(selectedFileData?._id, payload);

    await dispatch(action);

    dispatch(toggleRefreshFiles(true));

    setDialogOpen(false); // Close dialog after submission
  };

  // Split the currentDir into breadcrumb parts
  const breadcrumbParts = currentDir?.split("/").filter(Boolean); // Remove empty parts

  // Handle breadcrumb navigation
  const handleBreadcrumbClick = (index: number) => {
    // Create the new directory path based on which breadcrumb was clicked
    const newDir = `/${breadcrumbParts?.slice(0, index + 1)?.join("/")}`;
    navigate(`/cloud-storage/?dir=${newDir}`);
  };

  return (
    <Box>
      <Stack p="15px 25px 2px 25px">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className="boxProject"
          position="relative"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Breadcrumbs aria-label="breadcrumb">
              {/* Root Level labeled "All Items" */}
              <Link
                underline="hover"
                color="inherit"
                onClick={() => navigate("/cloud-storage/")}
                sx={{
                  fontFamily: "Source Serif Pro, sans-serif", // Change to your desired font
                  fontWeight: "normal",
                  fontSize: "20px",
                  cursor: "pointer",
                  transition: "font-weight 0.3s ease", // Smooth transition for font weight
                  "&:hover": {
                    fontWeight: "bold", // Bold on hover
                  },
                }}
              >
                All Items
              </Link>

              {/* Dynamic Breadcrumbs */}
              {breadcrumbParts?.slice(0, -1)?.map((part, index) => (
                <Link
                  key={index}
                  underline="hover"
                  color="inherit"
                  onClick={() => handleBreadcrumbClick(index)}
                  sx={{
                    fontFamily: "Source Serif Pro, sans-serif", // Change to your desired font
                    fontWeight: "normal",
                    fontSize: "20px",
                    cursor: "pointer",
                    transition: "font-weight 0.3s ease", // Smooth transition for font weight
                    "&:hover": {
                      fontWeight: "bold", // Bold on hover
                    },
                  }}
                >
                  {part}
                </Link>
              ))}

              {/* Current Folder (non-clickable) */}
              {breadcrumbParts?.length > 0 && (
                <Typography
                  color="text.primary"
                  sx={{
                    fontFamily: "Source Serif Pro, sans-serif", // Change to your desired font
                    fontWeight: "bold", // Always bold for the current folder
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                >
                  {breadcrumbParts?.at(-1)}
                </Typography>
              )}
            </Breadcrumbs>
          </Stack>

          <Stack direction="row" alignItems="center" gap={2}>
            <SwitchSmall viewMode={viewMode} setViewMode={setViewMode} />
            <MediumSearchbar ph={"Search all Items"} />
          </Stack>

          {/* {selectedFileData && (
            <Stack position={"absolute"} right={"15px"} top={"44px"}>
              <IconButton onClick={() => setCollapsed(!collapsed)}>
                <GreenArrowButton />
              </IconButton>
            </Stack>
          )} */}
        </Stack>

        <Stack mt={3} direction={"column"} gap={0}>
          {/* Render Files in the Current Directory */}
          <Stack
            sx={{
              maxHeight: "calc(84vh - 0px)",
              overflow: "hidden",
              transition: "max-height 0.3s ease",
              overflowY: "scroll",
              scrollbarWidth: "none",
            }}
            direction={"row"}
            alignItems={"flex-start"}
            gap={0.5}
            width={"100%"}
            justifyContent={"space-between"}
          >
            <Stack
              flexWrap={"wrap"}
              width={"100%"}
              height="84vh"
              overflow="hidden"
              maxHeight="35%"
              sx={{
                "&::-webkit-scrollbar": {
                  width: "0 !important", // hide scrollbar on Chrome, Safari, etc.
                },
                scrollbarWidth: "none", // hide scrollbar on Firefox
                overflowX: "hidden",
                overflowY: "scroll",
              }}
              direction={viewMode === "square" ? "row" : "column"}
              gap={viewMode === "square" ? 3 : 0}
            >
              {/* Check for currentDirFiles and subfolders availability */}
              {currentDirFiles &&
              currentDirFiles?.length === 0 &&
              subfolders &&
              subfolders?.length === 0 ? (
                <Stack
                  width={"100%"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <img
                    width={"350px"}
                    height={"350px"}
                    src={NoFileFolder}
                    alt="No File Folder"
                  />
                </Stack>
              ) : (
                <>
                  {/* Render Files */}
                  {currentDirFiles?.map((item, index) => (
                    <Grow
                      key={item?._id || index}
                      in={true}
                      timeout={500}
                      mountOnEnter
                      unmountOnExit
                      style={{ transformOrigin: "top" }}
                    >
                      <div
                        style={{
                          width: viewMode !== "square" ? "100%" : "auto",
                        }}
                      >
                        {viewMode === "square" ? (
                          <CloudStorageItemGrid
                            plateform={item?.platform}
                            opendBy={item?.uploadedBy}
                            fileSize={item?.size}
                            path={item?.path || ""}
                            isSelected={item?.isSelected}
                            onContainerClick={() =>
                              handleNavigate(
                                item?._id,
                                item?.originalName,
                                item
                              )
                            }
                            timeStamp={item?.uploadDate}
                            fileName={item?.originalName}
                            checkBoxClick={() => handleItemClick(item?._id)}
                            optionAction={(value: any) => {
                              optionAction(value, item?._id, item);
                            }}
                          />
                        ) : (
                          <CloudStorageItemList
                            plateform={item?.platform}
                            opendBy={item?.uploadedBy}
                            fileSize={item?.size}
                            isSelected={item?.isSelected}
                            onContainerClick={() =>
                              handleNavigate(
                                item?._id,
                                item?.originalName,
                                item
                              )
                            }
                            timeStamp={item?.uploadDate}
                            fileName={item?.originalName}
                            checkBoxClick={() => handleItemClick(item?._id)}
                            collapsedView={collapsed}
                            optionAction={(value: any) => {
                              optionAction(value, item?._id, item);
                            }}
                          />
                        )}
                      </div>
                    </Grow>
                  ))}

                  {/* Render Folders from `bearishOSCloudStorageFolders`, excluding the current folder */}
                  <Stack
                    direction={"row"}
                    alignItems={"flex-start"}
                    gap={0.5}
                    justifyContent={"space-between"}
                  >
                    <Stack
                      flexWrap={"wrap"}
                      direction={viewMode === "square" ? "row" : "column"}
                      gap={viewMode === "square" ? 3 : 0}
                      m={"5px 5px"}
                      width={"100%"}
                    >
                      {subfolders?.map((folder: any, index: any) => (
                        <Grow
                          key={folder?._id || index}
                          in={true}
                          timeout={500}
                          mountOnEnter
                          unmountOnExit
                          style={{ transformOrigin: "top" }}
                        >
                          <Stack
                            style={{
                              width: viewMode !== "square" ? "100%" : "auto",
                              border: "1px solid #ccc",
                              borderRadius: "5px",
                              marginBottom: "10px",
                              marginTop: "10px",
                              backgroundColor: "#f9f9f9",
                              boxShadow: "0 3px 3px rgba(0, 0, 0, 0.1)",
                              cursor: "pointer",
                            }}
                            direction={"row"}
                            alignItems={"center"}
                            gap={2}
                            padding={"0 15px"}
                            onClick={() =>
                              handleFolderClick(
                                folder.folderName,
                                folder.directory
                              )
                            } // Pass folderName and directory
                          >
                            <DarkFolderIcon color="#117BB8" w={18} />
                            <Typography
                              sx={{
                                padding: "10px",
                                fontWeight: "bold",
                                color: "#333",
                                textAlign: "center",
                                fontFamily: "Source Serif Pro",
                              }}
                            >
                              {folder.folderName}
                            </Typography>
                          </Stack>
                        </Grow>
                      ))}
                    </Stack>
                  </Stack>
                </>
              )}
            </Stack>

            <Slide direction="left" in={collapsed} mountOnEnter unmountOnExit>
              <Stack>
                {detailsSliderState === "Main Details" && (
                  <DetailsCloudStorage
                    closeDetails={() => {
                      setCollapsed(false);
                      setSelectedFileData(null);
                      dispatch(getDetailsSliderState(null));
                    }}
                    selectedFileData={selectedFileData}
                    handleDialogSubmit={handleDialogSubmit}
                  />
                )}
                {detailsSliderState === "Item Insight" && (
                  <ItemMainInsights
                    closeDetails={() => {
                      setCollapsed(false);
                      setSelectedFileData(null);
                      dispatch(getDetailsSliderState(null));
                    }}
                    selectedFileData={selectedFileData}
                  />
                )}
                {detailsSliderState === "Link Details" && (
                  <LinkDetails
                    selectedFileData={selectedFileData}
                    closeDetails={() => {
                      setCollapsed(false);
                      setSelectedFileData(null);
                      dispatch(getDetailsSliderState(null));
                    }}
                  />
                )}
              </Stack>
            </Slide>
          </Stack>
        </Stack>
      </Stack>
      <FileActionDialogRenameMove
        open={dialogOpen}
        onClose={handleDialogClose}
        onSubmit={handleDialogSubmit}
        fileData={selectedFileData}
        action={
          actionSelect as
            | "Rename"
            | "Move"
            | "Set Expiration"
            | "Delete"
            | "Lock"
            | "Watermark"
            | "Upload New Version"
        }
        folders={bearishOSCloudStorageFolders} // Pass folder list as prop
        deleteFileAction={deleteFileAction}
      />
    </Box>
  );
};

export default CloudStorage;
