import React, { useState } from "react";
import { InputBase } from "@mui/material";
import {
  LoadScript,
  Autocomplete as GoogleAutocomplete,
} from "@react-google-maps/api";
import "./s.css";
// Directly define the libraries array as string literals
const libraries: ["places"] = ["places"];

interface LocationAutocompleteProps {
  onChange?: any;
}

const LocationAutocomplete: React.FC<LocationAutocompleteProps> = ({
  onChange,
}) => {
  const [autocomplete, setAutocomplete] = useState<any | null>(null);
  const [inputValue, setInputValue] = useState<string>("");

  // Handle autocomplete loading from Google Places API
  const onLoad = (autocompleteInstance: any) => {
    setAutocomplete(autocompleteInstance);
  };

  // Handle place changes
  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete?.getPlace();
      if (place && place?.formatted_address) {
        setInputValue(place?.formatted_address);
        if (onChange) {
          onChange(place?.formatted_address);
        }
      }
    }
  };

  // Custom inline styles with correct type
  const wrapperStyles: React.CSSProperties = {
    position: "relative", // TypeScript compatible
    zIndex: 99999, // Ensures it's above other elements
    width : "100%"
  };

  return (
    <div style={wrapperStyles}>
      {" "}
      {/* Root element with high z-index */}
      <LoadScript
        googleMapsApiKey="AIzaSyDc78rZdKBBC9N4yknYbsyHIo-mH55wE3Q"
        libraries={libraries}
      >
        <GoogleAutocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
          <InputBase
            placeholder="Search Location"
            value={inputValue}
            onChange={(event: any) => setInputValue(event.target.value)}
            sx={{ font: " normal normal 400 14px/18px Source Sarif pro" }}
          />
        </GoogleAutocomplete>
      </LoadScript>
    </div>
  );
};

export default LocationAutocomplete;
