import { Menu, MenuItem, Stack, Typography } from "@mui/material";
import React, { MouseEvent, useEffect, useState } from "react";
import { ChevronIcon } from "../../../../../../../images";
import { repeatOptionMenuRepeatModeEnum } from "../../RepeatOptionMenuType";

interface RepeatViewProps {
  componentType: "numCount" | "dayMonthCount";
  onSelect: (value: string | repeatOptionMenuRepeatModeEnum) => void;
}

const RepeatView: React.FC<RepeatViewProps> = ({ componentType, onSelect }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Lists for number and time options
  const numList: string[] = Array.from({ length: 100 }, (_, i) =>
    (i + 1)?.toString()
  );
  const timeList: repeatOptionMenuRepeatModeEnum[] = Object.values(
    repeatOptionMenuRepeatModeEnum
  );

  // Determine the initial value and options based on the componentType prop
  const [selectedValue, setSelectedValue] = useState<string>(
    componentType === "numCount" ? numList[0] : timeList[0]
  );
  const options: string[] = componentType === "numCount" ? numList : timeList;

  const handleMenuClick = (value: string) => {
    setSelectedValue(value);
    handleClose();
  };

  useEffect(() => {
    if (componentType === "numCount") {
      onSelect(selectedValue);
    } else {
      // Cast to ensure type compatibility for repeatOptionMenuRepeatModeEnum
      onSelect(selectedValue as repeatOptionMenuRepeatModeEnum);
    }
  }, [selectedValue]);

  return (
    <Stack>
      <Stack
        onClick={handleClick}
        sx={{
          cursor: "pointer",
          flexDirection: "row",
          alignItems: "center",
          gap: "3px",
        }}
      >
        <Typography
          sx={{ font: "normal normal 900 13px/16px Source Serif Pro" }}
        >
          {selectedValue}
        </Typography>
        <ChevronIcon w={13} direction={open ? "up" : "down"} />
      </Stack>

      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          sx: {
            marginTop: "2px",
            maxHeight: "130px",
            overflowY: "auto",
            scrollbarWidth: "none",
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        {options.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => handleMenuClick(item)}
            selected={item === selectedValue}
            sx={{
              padding: "0px 10px",
              minHeight: 0,
              height: "20px",
              flexShrink: 0,
              font: "normal normal 300 13px/16px Source Serif Pro",
              bgcolor:
                item === selectedValue ? "rgba(0, 0, 0, 0.08)" : "inherit",
            }}
          >
            {item}
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
};

export default RepeatView;
