import { InputBase, Stack, Typography, ClickAwayListener } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { FolderFileIcon } from "../../../images";
import styles from "./SmallSearchFolder.module.css";
import { SmallSearchFolderListInterface } from "./SmallSearchFolderType";

interface SmallSearchFolderProps {
  placeholder?: string;
  options: SmallSearchFolderListInterface[];
  onSelect: (selectedOption: SmallSearchFolderListInterface) => void;
}

const SmallSearchFolder: React.FC<SmallSearchFolderProps> = ({
  placeholder,
  options,
  onSelect,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    setIsDropdownOpen(true);
    setHighlightedIndex(-1);
  };

  const handleOptionClick = (option: SmallSearchFolderListInterface) => {
    onSelect(option);
    setInputValue(option.foldername);
    setIsDropdownOpen(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!isDropdownOpen || filteredOptions.length === 0) return;

    switch (event.key) {
      case "ArrowDown":
        event.preventDefault();
        setHighlightedIndex(
          (prevIndex) =>
            prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : 0 // Wrap around to the first item
        );
        break;
      case "ArrowUp":
        event.preventDefault();
        setHighlightedIndex(
          (prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : filteredOptions.length - 1 // Wrap around to the last item
        );
        break;
      case "Enter":
        event.preventDefault();
        if (
          highlightedIndex >= 0 &&
          highlightedIndex < filteredOptions.length
        ) {
          handleOptionClick(filteredOptions[highlightedIndex]);
        }
        break;
      case "Escape":
        setIsDropdownOpen(false);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (dropdownRef.current && highlightedIndex >= 0) {
      const highlightedOption = dropdownRef.current.children[
        highlightedIndex
      ] as HTMLElement;
      highlightedOption.scrollIntoView({ block: "nearest" });
    }
  }, [highlightedIndex]);

  const filteredOptions = options.filter((option) =>
    option.foldername.toLowerCase().includes(inputValue.toLowerCase())
  );

  return (
    <ClickAwayListener onClickAway={() => setIsDropdownOpen(false)}>
      <Stack className={styles["small-search-folder"]}>
        <InputBase
          placeholder={placeholder}
          value={inputValue}
          onChange={handleInputChange}
          className={styles["small-search-folder__input-base"]}
          onClick={() => (inputValue ? setIsDropdownOpen(true) : null)}
          onKeyDown={handleKeyDown}
        />
        {isDropdownOpen && filteredOptions.length > 0 && (
          <Stack
            className={styles["small-search-folder__dropdown"]}
            ref={dropdownRef}
          >
            {filteredOptions.map((option, index) => (
              <Stack
                key={option.folderid}
                className={`${styles["small-search-folder__item"]} ${
                  index === highlightedIndex
                    ? styles["small-search-folder__item--highlighted"]
                    : ""
                }`}
                onClick={() => handleOptionClick(option)}
                sx={{ bgcolor: "rgba(25, 118, 210, 0.12)" }}
              >
                <FolderFileIcon w={20} />
                <Typography
                  className={styles["small-search-folder__item-text"]}
                >
                  {option.foldername}
                </Typography>
              </Stack>
            ))}
          </Stack>
        )}
      </Stack>
    </ClickAwayListener>
  );
};

export default SmallSearchFolder;
