/* @Test___Subject */

import React, { useEffect } from "react";
import {
  externalEntitiesOAuthCallback,
  externalEntitiesOAuthCallbackAfterLogin,
} from "../../../redux/Slice/auth/authActions";
import { useDispatch } from "react-redux";

const CallBack = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    // Get the URL's search parameters
    const params = new URLSearchParams(window.location.search);

    // Check if the 'code' parameter exists and log it if found
    const code = params.get("code");
    if (code) {
      handleCodeVerification(code);
    } else {
      console.log("No code parameter found in the URL");
    }
  }, []);

  const handleCodeVerification = async (code: string) => {
    try {
      if (!code) return;

      const action = externalEntitiesOAuthCallback(code as string);
      const callBackRes: any = await dispatch(action);

      console.log("Response from externalEntitiesOAuthCallback:", callBackRes);

      // Check if success is true OR fullError contains "duplicate" to call handlelogin
      if (
        callBackRes?.state?.auth?.data === true ||
        (callBackRes?.state?.auth?.error &&
          callBackRes?.state?.auth?.error?.fullError.includes("duplicate"))
      ) {
        console.log("Conditions met, calling handlelogin...");
        await handlelogin(code);
      } else {
        console.log("Conditions not met for handlelogin.");
      }
    } catch (err) {
      console.log("Error in handleCodeVerification:", err);
    }
  };

  const handlelogin = async (code: string) => {
    try {
      if (!code) return;

      const action = externalEntitiesOAuthCallbackAfterLogin(code as string);
      const callBackRes: any = await dispatch(action);

      if (callBackRes?.success && callBackRes?.data) {
        // Additional logic if needed
      }

      console.log(
        "🚀 -------------------------------------------------------🚀"
      );
      console.log("🚀 ~ handleCodeVerification ~ callBackRes:", callBackRes);
      console.log(
        "🚀 -------------------------------------------------------🚀"
      );
    } catch (err) {
      console.log("Error in handlelogin:", err);
    }
  };

  return <div>CallBack</div>;
};

export default CallBack;
