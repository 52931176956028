import { Box, Drawer, Stack } from "@mui/material";
import React, { useCallback, useContext, useEffect } from "react";
import { styled } from "@mui/system";
import CompanyIconAndName from "../../CompanyIconAndName";
import styles from "../../DrawerNavbar.module.css";
import { useNavigate } from "react-router-dom";
import { MeetingContext } from "../../../../Povider";
import { CreateCallButton } from "../../../Buttons/CreateCallButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { VideoCenterState } from "../../../../../redux/Slice/videoCenter/VideoCenterTypes";
import {
  getSingleMeetingFailureAction,
  setEndMeetingObjBlankAction,
  setIsLoadingAction,
  setSelectedMeetingAction,
} from "../../../../../redux/Slice/videoCenter/VideoCenterSlice";
import { UpcomingCallCardContainer } from "../../../../pages/Calls/components";
import { PastCallCardContainer } from "../../../../pages/Calls/components/PastCallCardContainer";
import { useTranslation } from "react-i18next";
import { AuthState, getPastMeetings, getUpcomingMeetings, getUserProjects } from "../../../../../redux";
import {getAllExistingUsers} from "../../../../../redux/Slice/auth/authActions";

type CallsDrawerProps = {
  drawerOpen: boolean;
  drawerCloser: () => void;
};

const DrawerContainer = styled("div")({
  position: "fixed",
  top: 0,
  height: "100vh",
  zIndex: 1100, // Ensure the drawer is behind the navbar
});
const CallsDrawer: React.FC<CallsDrawerProps> = ({
  drawerOpen,
  drawerCloser,
}) => {
  const meetingContext = useContext(MeetingContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { errors, upcomingMeetings, pastMeetings } = useSelector(
    (state: RootState) => state.videoCenter as VideoCenterState
  );
  const { user } = useSelector((state: RootState) => state.auth as AuthState);

  const onCallButtonClickHandler = useCallback(async (data: any) => {
    if (data.trim() === "Start") {
      if (meetingContext?.createMeeting) {
        dispatch(setEndMeetingObjBlankAction(null));
        const meeting = await meetingContext.createMeeting();
        if (meeting?.externalMeetingId)
          navigate(`/Calls/${meeting?.externalMeetingId}`);
      }
    }
  }, []);


  const handleMeetingOnClick = async (value: string) => {
    dispatch(setIsLoadingAction(true));
    const selectedMeeting = upcomingMeetings[value];
    await dispatch(setSelectedMeetingAction(selectedMeeting));
    navigate(`/Calls/CallDetails/${selectedMeeting?._id}`);
    dispatch(setIsLoadingAction(false));
  };
  
  const handlePastMeetingOnClick = async (value: string) => {
    navigate(`/Calls/PastCallDetails/${value}`)
  };

  useEffect(() => {
    if (errors) {
      navigate(`/Calls`);
      dispatch(getSingleMeetingFailureAction(null));
    }
  }, [errors]);

  const handleGetUserMeeting = async () => {
    await dispatch(getUpcomingMeetings());
    // const resp = await dispatch(getAllWorkspace__api());
    await dispatch(getUserProjects(user?.data?._id));
    await dispatch(getAllExistingUsers());
  };

  useEffect(() => {
    handleGetUserMeeting();

    const timer = setTimeout(() => {
      dispatch(getPastMeetings());
    }, 3000);

    return () => clearTimeout(timer);
  }, [dispatch]);

  return (
    <>
      <DrawerContainer>
        <Drawer
          sx={{
            position: "absolute",
            top: 0,
            height: "100vh",
            width: "215px",
            // left: '-240px', // Start hidden behind the Navbar
            transition: drawerOpen
              ? "left 225ms ease-out"
              : "left 195ms ease-in",
            left: drawerOpen ? "30px" : "-215px",
            zIndex: 1100, // Ensure the drawer is behind the navbar
            "& .MuiPaper-root": {
              position: "absolute",
              top: 0,
              height: "100vh",
              width: "215px",
              left: 0,
              boxSizing: "border-box",
              zIndex: 1100, // Ensure the paper is also behind the navbar
            },
          }}
          variant="persistent"
          anchor="left"
          open={drawerOpen}
        >
          <Stack
            bgcolor={"white"}
            direction="column"
            flex={1}
            className={`${styles.drawerNavbar__drawer}`}
          >
            {/* Company logo and name here */}
            <CompanyIconAndName arrowButtonClickHandler={drawerCloser} />
            <Stack className={styles.drawerNavbar__content}>
              {/* Buttons and other lists here */}
              <Box style={{ marginTop: "20px", marginLeft: "5px" }}>
                <CreateCallButton onClick={onCallButtonClickHandler} />
              </Box>
              <UpcomingCallCardContainer
                handleMeetingOnClick={handleMeetingOnClick}
                upcomingMeetings={upcomingMeetings}
                labelText={t("VIDEO_CENTER.UPCOMING_CALLS")}
              />
              <PastCallCardContainer
                handleMeetingOnClick={handlePastMeetingOnClick}
                pastMeetings={pastMeetings}
                labelText={t("VIDEO_CENTER.PAST_CALLS")}
              />
            </Stack>
          </Stack>
        </Drawer>
      </DrawerContainer>
    </>
  );
};

export default CallsDrawer;
