import { Stack, Typography } from "@mui/material";
import React from "react";

interface TraxDrawerContainerProps {
  label: string;
  children: React.ReactNode;
}

const TraxDrawerContainer: React.FC<TraxDrawerContainerProps> = ({
  children,
  label,
}) => {
  return (
    <Stack sx={{ flex: 1, boxSizing: "border-box" }}>
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
          padding: "12px 0px",
        }}
      >
        <Typography
          sx={{
            font: "normal normal 600 15px/19px Source Serif Pro",
            whiteSpace: "nowrap",
          }}
        >
          {label}
        </Typography>
        <Stack sx={{ bgcolor: "#E9EDF2", height: "1px", width: "100%" }} />
      </Stack>

      <Stack sx={{ gap: "8px", paddingRight: "10px" }}>{children}</Stack>
    </Stack>
  );
};

export default TraxDrawerContainer;
