import React from "react";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import { stringAvatar } from "../../../Utils";

// Define the type for the company data
interface CompanyData {
  name: string;
  logoUrl?: string;
  domain?: string;
}

// Define the props type for the component
interface BrandFetchCInfoProps {
  imgSizeW?: number;
  imgSizeH?: number;
  roundedOrSquare?: "sq" | "rd" | "cr";
  domainRequired?: boolean;
  companyFont?: string;
  domainFont?: string;
  companyData?: CompanyData;
}

// Centralized default data for the component
const defaultCompanyData: CompanyData = {
  name: "WebX",
  logoUrl: "https://logosandtypes.com/wp-content/uploads/2022/03/Webex.png",
  domain: "webx.com",
};

// Define default props
const defaultProps: Partial<BrandFetchCInfoProps> = {
  imgSizeH: 25,
  imgSizeW: 25,
  roundedOrSquare: "rd",
  domainRequired: false,
  companyFont: "inherit",
  domainFont: "inherit",
};

const BrandFetchCInfo: React.FC<BrandFetchCInfoProps> = ({
  imgSizeH = defaultProps.imgSizeH,
  imgSizeW = defaultProps.imgSizeW,
  roundedOrSquare = defaultProps.roundedOrSquare,
  domainRequired = defaultProps.domainRequired,
  companyFont = defaultProps.companyFont,
  domainFont = defaultProps.domainFont,
  companyData = defaultCompanyData,
}) => {
  // Create a variable to hold the avatar styles
  const avatarStyles = React.useMemo(() => stringAvatar(companyData?.name), [companyData?.name]);

  // Determine the borderRadius based on roundedOrSquare prop
  const borderRadius = React.useMemo(() => {
    switch (roundedOrSquare) {
      case "sq":
        return 0;
      case "rd":
        return "20%";
      case "cr":
        return "50%";
      default:
        return "20%";
    }
  }, [roundedOrSquare]);

  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Avatar
          src={companyData?.logoUrl}
          sx={{
            width: imgSizeW,
            height: imgSizeH,
            borderRadius: borderRadius,
            ...avatarStyles.sx, // Merge the avatar styles
          }}
        >
          {avatarStyles.children}
        </Avatar>
        <Stack
          direction="column"
          alignItems="flex-start"
          spacing={0.5}
        >
          <Typography sx={{ font: companyFont }}>{companyData?.name}</Typography>
          {domainRequired && companyData?.domain && (
            <Typography
              sx={{
                font: domainFont,
                color: "blue",
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {companyData?.domain}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default BrandFetchCInfo;
