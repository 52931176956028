import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { GreenDropdownIcon } from "../../../../../images";

interface ViewHeaderButtonProps {
  headerText?: string;
  createNewButtonText?: string;
  onBackButtonClick?: () => void;
  onCreateButtonClick?: () => void;
}

const ViewHeaderButton: React.FC<ViewHeaderButtonProps> = ({
  headerText,
  createNewButtonText,
  onBackButtonClick,
  onCreateButtonClick,
}) => {
  return (
    <Stack
      sx={{
        bgcolor: "white",
        flexDirection: "row",
        alignItems: "start",
        justifyContent: "space-between",
        position: "sticky",
        top: 0,
        zIndex: 100,
        paddingBottom: "20px",
      }}
    >
      <Stack sx={{ flexDirection: "row", alignItems: "start", gap: "15px" }}>
        <Typography
          sx={{
            font: "normal normal 400 14px/18px Source Serif Pro",
            paddingBottom: "5px",
            borderBottom: "1px solid #E9EDF2",
          }}
        >
          {headerText}
        </Typography>
        {/* <IconButton
          onClick={onBackButtonClick}
          sx={{ p: 0, gap: "5px", alignItems: "center", color: "black" }}
          disableRipple
        >
          <GreenDropdownIcon
            open
            sx={{ transform: "rotate(180deg)", height: "11px" }}
          />
          {/* <Typography
            sx={{ font: "normal normal 400 13px/16px Source Serif Pro" }}
          >
            Back
          </Typography> */}
        {/* </IconButton> */} 
      </Stack>

      <IconButton
        onClick={onCreateButtonClick}
        sx={{ p: 0, gap: "5px", alignItems: "center", color: "#000AFF" }}
        disableRipple
      >
        <Typography
          sx={{ font: "normal normal 400 14px/18px Source Serif Pro" }}
        >
          {createNewButtonText}
        </Typography>
      </IconButton>
    </Stack>
  );
};

export default ViewHeaderButton;
