import React, { useEffect, useState } from "react";
import { Box, Stack, Modal, Typography } from "@mui/material";
import { HeaderOverViewName, ReactangleHeaderOverView } from "../components";
import { MultiIconButton } from "../../common";
import {
  BarButtonOptions,
  CrmRightSideTabs,
  TimelineTabs,
} from "../../components";
import { AddtoGroupIcon } from "../../../../../images";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addToGroup__api,
  getDealById__api,
  updateDeal__api,
} from "../../../../../redux";
import { RootState } from "../../../../../redux/store";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
  height: "fit-content",
  borderRadius: "6px",
};

type Params = Record<string, string | undefined>;
const DealID = () => {
  const { dealId } = useParams<Params>();
  const dispatch = useDispatch();

  const crmState = useSelector((state: RootState) => state && state?.crm);
  const { groupList } = crmState || {};
  const [grpList, setGrpList] = useState<any>([]);

  useEffect(() => {
    if (groupList && groupList?.length > 0) {
      setGrpList(groupList);
    }
  }, [groupList]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [dealDetails, setDealDetails] = useState<any>({});

  const getDealDetails = async () => {
    try {
      if (!dealId) {
        return;
      }

      const action = getDealById__api(dealId);

      const getDealRes: any = await dispatch(action);

      if (getDealRes?.success && getDealRes?.data) {
        setDealDetails(getDealRes?.data);
      } else {
        setDealDetails({});
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDealDetails();
  }, [dealId]);

  const updateDeal = async (updatedFields: { [key: string]: any }) => {
    try {
      if (!dealDetails?._id) {
        return;
      }

      // Update local state with the new deal details after a successful API call
      setDealDetails((prevDetails: any) => ({
        ...prevDetails,
        ...payload, // Merge updated fields into the existing deal details
      }));
      // Constructing the payload based on updated fields
      const payload: any = {
        dealName: updatedFields?.dealName || dealDetails?.dealName,
        dealStage: updatedFields?.dealStage || dealDetails?.dealStage,
        dealOwner: updatedFields?.dealOwner || dealDetails?.dealOwner,
        dealValue: updatedFields?.dealValue || dealDetails?.dealValue,
        description: updatedFields?.description || dealDetails?.description,
        company: updatedFields?.company || dealDetails?.company,
        dealIcon: updatedFields?.dealIcon || dealDetails?.dealIcon,
        contactsInvolved:
          updatedFields?.contactsInvolved ||
          dealDetails?.contactsInvolved ||
          [],
      };

      const action = updateDeal__api(dealDetails?._id, payload);

      await dispatch(action);
    } catch (error) {
      console.log(error);
    }
  };
  const addToGroup = async (_id: string) => {
    try {
      if (!dealId || !_id) {
        return;
      }

      const payload: any = {
        dealId: dealId,
      };

      const action = addToGroup__api(_id, payload);
      await dispatch(action);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        gap={0.5}
        sx={{ padding: "4px 10px" }}
      >
        <HeaderOverViewName
          imageUrl={dealDetails?.dealIcon}
          headerType="deal"
          name={dealDetails?.dealName}
          roundedOrSquare="rd"
          onUpdate={updateDeal} // Pass the update function as a prop
        />
        <Stack
          direction={"row"}
          onClick={handleOpen}
          alignItems={"center"}
          gap={1}
        >
          <BarButtonOptions
            onClick={() => {
              console.log("");
            }}
            label={{ id: 1, name: "Add to Group" }}
            icon={<AddtoGroupIcon />}
          />
          <MultiIconButton />
        </Stack>
      </Stack>

      <Stack>
        <ReactangleHeaderOverView />
      </Stack>

      <Stack direction={"row"} height={"80vh"}>
        <TimelineTabs cType={"DEAL"} contactDetails={dealDetails} />
        <CrmRightSideTabs
          typeOfIdentifier={"deal"}
          onUpdate={updateDeal}
          contactDetails={dealDetails}
        />
      </Stack>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <CreateGroupPopUp variant="group" /> */}
          <Stack minWidth={"200px"} direction={"column"} gap={1}>
            {grpList &&
              grpList?.length > 0 &&
              grpList?.map((g: any) => {
                return (
                  <Typography
                    key={g?._id}
                    sx={{
                      font: " normal normal normal 14px/20px Source Serif Pro",
                      padding: "10px 20px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      addToGroup(g?._id);
                    }}
                  >
                    {" "}
                    <span style={{ marginRight: "10px" }}>
                      {" "}
                      {g?.groupIcon}{" "}
                    </span>{" "}
                    {g?.groupName}{" "}
                  </Typography>
                );
              })}
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
};

export default DealID;
