import { Button, ButtonProps, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../Utils";
import { useTranslation } from "react-i18next";

interface StartVideoMeetingButtonProps extends ButtonProps {
  isStarted?: boolean;
}

const StartVideoMeetingButton: React.FC<StartVideoMeetingButtonProps> = ({
  sx,
  isStarted,
  ...props
}) => {
    const {t}=useTranslation()
  return (
    <Button
      variant="text"
      sx={{
        width: "380px",
        height: "25px",
        textTransform: "none",
        bgcolor: "black",
        borderRadius: "5px",
        boxSizing: "border-box",
        color: "white",
        opacity: 1,
        ":hover": { bgcolor: adjustHexColor("#000000", 85), opacity: 1 },
        ...sx,
      }}
      {...props}
    >
      <Typography
        sx={{
          font: "normal normal 900 15px/19px Source Serif Pro",
          color: "white",
        }}
      >
          {t(isStarted ? "VIDEO_CENTER.MEETING.JOIN_VIDEO_MEETING" : "VIDEO_CENTER.MEETING.START_VIDEO_MEETING")}
      </Typography>
    </Button>
  );
};

export default StartVideoMeetingButton;
