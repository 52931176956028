import React, { useRef, useState, useEffect } from "react";
import {
  SmallThreeDotMenu,
  SmallThreeDotMenuListInterface,
} from "../../../../common";
import {
  AddCalPlusIcon,
  FolderIcon,
  LogoutIcon,
  RenameIcon,
  UploadIcon,
} from "../../../../../images";
import { useDispatch } from "react-redux";
import {
  createFolder__api,
  toggleRefreshFiles,
  uploadCloudFile__api,
} from "../../../../../redux";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";

interface ThreeDotMenuSmallProps {}

const MENU_LISTS: SmallThreeDotMenuListInterface[] = [
  {
    id: 1,
    icon: FolderIcon,
    text: "Create me folder",
  },
  {
    id: 2,
    icon: AddCalPlusIcon,
    text: "Create new item",
  },
  {
    id: 3,
    icon: UploadIcon,
    text: "Upload to drive",
  },
  {
    id: 4,
    icon: UploadIcon, // Reusing UploadIcon, change if needed
    text: "Upload folder to drive",
  },
  {
    id: 5,
    icon: RenameIcon,
    text: "Rename",
  },
  {
    id: 6,
    icon: LogoutIcon,
    text: "Logout",
  },
];
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ThreeDotMenuSmall: React.FC<ThreeDotMenuSmallProps> = () => {
  const dispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const folderInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [folderName, setFolderName] = useState("");

  const handleFolderNameChange = (event: any) => {
    setFolderName(event.target.value);
  };

  const handleSubmit = async () => {
    // Implement folder name submission logic here

    const urlParams = new URLSearchParams(window?.location?.search);
    const dir = urlParams?.get("dir") || "/"; // If 'dir' is not found, set it to an empty string

    try {
      const payload = {
        folderName,
        directory: dir,
      };

      if (!folderName) {
        return;
      }

      const action = createFolder__api(payload);

      const actionRes: any = await dispatch(action);
      if (actionRes?.success) {
        dispatch(toggleRefreshFiles(true));
      }
    } catch (err) {}
    handleClose(); // Close the modal after submission
  };

  const handleMenuClick = (text: string) => {
    if (text === "Create new item" || text === "Upload to drive") {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    } else if (text === "Upload folder to drive") {
      if (folderInputRef.current) {
        folderInputRef.current.click();
      }
    } else if (text === "Create me folder") {
      handleOpen();
    }
    console.log(text);
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files && files?.length > 0) {
      setSelectedFiles(files); // Store selected files in state
      const actionRes: any = await handleFileFolderUpload(files); // Pass selected files directly to upload
      if (actionRes) {
        dispatch(toggleRefreshFiles(true));
      }
    }
  };

  const handleFileFolderUpload = async (files: FileList) => {
    const formData = new FormData();
    // Get the 'dir' parameter from the URL
    const urlParams = new URLSearchParams(window?.location?.search);
    const dir = urlParams?.get("dir") || ""; // If 'dir' is not found, set it to an empty string

    // Set the folderLocation to the value of 'dir'
    formData.append("folderLocation", dir);
    formData.append("locking[isLocked]", "false");

    // Declare lockedUntil and expiryDate as string | null
    const lockedUntil: string | null = null; // Replace with actual value if available
    if (lockedUntil !== null) {
      formData.append("locking[lockedUntil]", lockedUntil);
    }

    formData.append("enableWatermark", "false");

    const expiryDate: string | null = null; // Replace with actual value if available
    if (expiryDate !== null) {
      formData.append("setExpiryDate", expiryDate);
    }

    // Append each file to FormData
    for (let i = 0; i < files?.length; i++) {
      formData.append("files", files[i]);
    }

    const action = uploadCloudFile__api(formData);
    const actionRes: any = await dispatch(action);
    // Reset the input value only after the upload
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset the input
    }
    if (actionRes?.success) {
      return true;
    }
  };

  const handleFolderChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;

    if (files && files?.length > 0) {
      const urlParams = new URLSearchParams(window?.location?.search);
      const baseDir = urlParams?.get("dir") || ""; // Base directory from URL or empty

      // Create a Map to group files by their folder structure
      const folderMap: Record<string, File[]> = {};

      // Iterate over all the selected files
      for (let i = 0; i < files?.length; i++) {
        const file = files[i];
        const filePath = file?.webkitRelativePath;

        // Extract the folder path (excluding the file name)
        const folderPath = `${baseDir}/${filePath?.substring(
          0,
          filePath?.lastIndexOf("/")
        )}`;

        // If folderPath doesn't exist in the map, initialize it
        if (!folderMap?.[folderPath]) {
          folderMap[folderPath] = [];
        }

        // Add the file to the corresponding folder
        folderMap?.[folderPath]?.push(file);
      }

      // Iterate over the folderMap, create folders, and upload files
      for (const [folder, folderFiles] of Object?.entries(folderMap)) {
        // 1. Call the folder creation API
        try {
          const folderPayload = {
            folderName: folder, // Use the folder name
          };

          const createFolderAction = createFolder__api(folderPayload);
          await dispatch(createFolderAction);

          // 2. Prepare files for upload after folder creation
          const formData = new FormData();
          formData.append("folderLocation", folder); // Set the folder location
          folderFiles?.forEach((file) => formData.append("files", file)); // Append files

          // 3. Dispatch the upload action
          const uploadAction = uploadCloudFile__api(formData);
          await dispatch(uploadAction);
        } catch (err) {
          console.error(
            `Error creating folder or uploading files to: ${folder}`,
            err
          );
        }
      }

      // Reset the folder input after the upload
      if (folderInputRef.current) {
        folderInputRef.current.value = "";
      }

      dispatch(toggleRefreshFiles(true)); // Refresh files view
    }
  };

  useEffect(() => {
    if (folderInputRef.current) {
      folderInputRef.current.setAttribute("webkitdirectory", "true");
    }
  }, []);

  return (
    <div>
      <SmallThreeDotMenu
        w={15}
        menuItems={MENU_LISTS}
        onClickAction={(value: any) => handleMenuClick(value)}
        displayOption="right"
      />
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        multiple // Allow multiple file selection
      />
      <input
        type="file"
        ref={folderInputRef}
        style={{ display: "none" }}
        onChange={handleFolderChange}
        multiple // Allow multiple file selection for folders
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontFamily: "Source Serif Pro" }}
          >
            Enter Folder Name
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            value={folderName}
            onChange={handleFolderNameChange}
            label="Folder Name"
            sx={{ fontFamily: "Source Serif Pro" }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{ mt: 2, fontFamily: "Source Serif Pro" }}
          >
            Create Folder
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default ThreeDotMenuSmall;
