import { Stack } from "@mui/material";
import React from "react";
import { EventIconButton } from "../../../../../../common";
import {
  CheckboxFilledIcon,
  MultiCircleIcon,
  XCircleIcon,
} from "../../../../../../../images";
import { useTranslation } from "react-i18next";

interface ButtonGroupTwoViewProps {}

const ButtonGroupTwoView: React.FC<ButtonGroupTwoViewProps> = ({}) => {
  const { t } = useTranslation();
  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
      <EventIconButton
        buttonTitle={t('CALENDAR.ACCEPT')}
        icon={CheckboxFilledIcon}
        iconColor="#21B481"
      />
      <EventIconButton
        buttonTitle={t('CALENDAR.REJECT')}
        icon={XCircleIcon}
        iconColor="#FF0000"
      />
      <EventIconButton
        buttonTitle={t('CALENDAR.MAYBE')}
        icon={MultiCircleIcon}
        iconColor="#FFB100"
      />
    </Stack>
  );
};

export default ButtonGroupTwoView;
