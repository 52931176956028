import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";
import Pusher from "pusher-js";
import pusherInstance from "../../../Utils/pusher";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { VideoCenterState } from "../../../redux/Slice/videoCenter/VideoCenterTypes";
import {
  addAgendaToOnGoingMeetingAction, ClintSendAction,
  guestRequestsForAccessAction, guestRequestsForPlaseWaitAction,
  removeAgendaWithIdAction,
  setEndMeetingAction,
  updateAgendaWithIdAction,
} from "../../../redux/Slice/videoCenter/VideoCenterSlice";
import { MeetingContext } from "../MeetingProvider/MeetingProvider";
import { AuthState, getOnGoingMeetingAgenda } from "../../../redux";

interface PusherContextType {
  pusher: Pusher | null;
}

const PusherContext = createContext<PusherContextType | null>(null);

export const usePusher = (): PusherContextType | null => {
  return useContext(PusherContext);
};

interface PusherProviderProps {
  children: ReactNode;
}

export const PusherProvider: React.FC<PusherProviderProps> = ({ children }) => {
  const [pusher, setPusher] = useState<Pusher | null>(null);
  const dispatch = useDispatch();
  const { onGoingMeeting } = useSelector(
    (state: RootState) => state.videoCenter as VideoCenterState
  );

  const { user } = useSelector((state: RootState) => state.auth as AuthState);
  const meetingContext = useContext(MeetingContext);
  useEffect(() => {
    if (onGoingMeeting?.meeting?._id) {
      var channel: any = pusherInstance.subscribe(onGoingMeeting?.meeting?._id);
    }
    var callback = (eventName: any, data: any) => {
      switch (eventName) {
        case "guest-request":
          handleGuestRequest(data);
          break;
        case "guest-join-request-response":
          handleGuestAccepted(data);
          break;
        case "agenda-update":
          handleUpdateAgenda(data);
          break;
        case "agenda-delete":
          handleRemoveAgenda(data);
          break;
        case "new-agenda":
          handleAddAgenda(data);
          break;
        case "end-meeting":
          handleEndMeeting(data);
          break;
        default:
          break;
      }
    };

    if (channel) {
      channel.bind_global(callback);
    }

    const handleGuestRequest = async (data: any) => {
      // Dispatch the action with the updated guest requests
      await dispatch(guestRequestsForAccessAction(data));
    };

    const handleGuestAccepted = async (data: any) => {
      if(data.status === 'wait'){
        await dispatch(guestRequestsForPlaseWaitAction(data));
      }
      if (data.userId !== user?.data?._id) return;
      if(data.status === 'rejected' || data.status === 'wait'){
        await dispatch(ClintSendAction(data));
        return
      }
      if (data.meetingId && data.chimeMeetingId) {
        await meetingContext?.handleJoinMeeting({
          meetingId: data.meetingId,
          chimeMeetingId: data.chimeMeetingId,
        });
        await dispatch(getOnGoingMeetingAgenda(data.meeting_id));
      }
    };
    const handleUpdateAgenda = async (data: any) => {
      if (data?.deletedAgenda?.meetingHostId === user?.data?._id) return;
      await dispatch(updateAgendaWithIdAction(data));
    };
    const handleRemoveAgenda = async (data: any) => {
      if (data?.deletedAgenda?.meetingHostId === user?.data?._id) return;
      await dispatch(removeAgendaWithIdAction(data?.deletedAgenda._id));
    };
    const handleAddAgenda = async (data: any) => {
      if (data?.savedAgendas[0]?.meetingHostId === user?.data?._id) return;
      await dispatch(addAgendaToOnGoingMeetingAction(data));
    };
    const handleEndMeeting = async (data: any) => {
      if (data.userId === user?.data?._id) return;
      await dispatch(setEndMeetingAction(null));
      meetingContext?.leaveMeeting();
    };

    setPusher(pusherInstance);
    return () => {
      if (channel) {
        channel.unbind_global(callback);
        pusherInstance.unsubscribe(onGoingMeeting?.meeting?._id || "");
        pusherInstance.disconnect();
      }
    };
  }, [onGoingMeeting?.meeting]);

  return (
    <PusherContext.Provider value={{ pusher }}>
      {children}
    </PusherContext.Provider>
  );
};
