import {
  IconButton,
  InputBase,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { DragAndDropIcon } from "../../../../../../../images";

import {
  ApprovedView,
  CheckboxView,
  ColorPickerView,
  ConnectionView,
  CountdownView,
  DateView,
  DependentDropdownView,
  EmailView,
  FileMediaView,
  FormulaView,
  LocationView,
  MoneyView,
  MultiSelectLabelView,
  NumberView,
  ParagraphView,
  PercentageView,
  PersonView,
  PhoneView,
  ProgressAutoView,
  ProgressManualView,
  RatingView,
  RelationView,
  RichTextView,
  RollupView,
  SelectDropdownView,
  ShortTextView,
  TimestampView,
  UrlView,
  VotingView,
} from "./FieldsView";
import {
  AddElementOptionValuesEnum,
  AddElementPopoutFormDataInterface,
} from "../../../../../../common";
import dayjs from "dayjs";

interface CustomFieldProps {
  objectTaskData?: any;
  helperupdateStackData?: any;
  data: AddElementPopoutFormDataInterface;
}

const CustomField: React.FC<CustomFieldProps> = ({
  data,
  helperupdateStackData,
  objectTaskData,
}) => {
  const { fieldname, formType, additionalValue, description, emoji } = data;

  const renderElement = () => {
    // fieldtype is came from props
    switch (formType) {
      case AddElementOptionValuesEnum.ShortText:
        return (
          <ShortTextView
            initialValue={objectTaskData?.dataSet?.customElements?.shortText}
            onChange={(value) => {
              helperupdateStackData &&
                helperupdateStackData("customElements", {
                  shortText: value,
                });
            }}
          />
        );

      case AddElementOptionValuesEnum.Paragraph:
        return (
          <ParagraphView
            initialValue={objectTaskData?.dataSet?.customElements?.paragraph}
            onChange={(value) => {
              helperupdateStackData &&
                helperupdateStackData("customElements", {
                  paragraph: value,
                });
            }}
          />
        );

      case AddElementOptionValuesEnum.Number:
        return (
          <NumberView
            initialValue={objectTaskData?.dataSet?.customElements?.number}
            onChange={(value) => {
              helperupdateStackData &&
                helperupdateStackData("customElements", {
                  number: value,
                });
            }}
          />
        );

      case AddElementOptionValuesEnum.Checkbox:
        return (
          <CheckboxView
            initialValue={objectTaskData?.dataSet?.customElements?.checkbox}
            onChange={(value) => {
              helperupdateStackData &&
                helperupdateStackData("customElements", {
                  checkbox: value,
                });
            }}
          />
        );

      case AddElementOptionValuesEnum.Date:
        return (
          <DateView
            initialValue={objectTaskData?.dataSet?.customElements?.date}
            onChange={(value) => {
              helperupdateStackData &&
                helperupdateStackData("customElements", {
                  date: value,
                });
            }}
          />
        );

      case AddElementOptionValuesEnum.Timestamp:
        return (
          <TimestampView
            initialData={objectTaskData?.dataSet?.customElements?.timestamp}
            onChange={(value) => {
              helperupdateStackData &&
                helperupdateStackData("customElements", {
                  timestamp: value,
                });
            }}
          />
        );

      case AddElementOptionValuesEnum.SelectDropdown:
        return (
          <SelectDropdownView
            initialValues={
              objectTaskData?.dataSet?.customElements?.selectDropdown || []
            }
            options={additionalValue}
            onChange={(selectedOptions) => {
              helperupdateStackData &&
                helperupdateStackData("customElements", {
                  selectDropdown: selectedOptions,
                });
            }}
          />
        );

      case AddElementOptionValuesEnum.Approved:
        return (
          <ApprovedView
            initialValues={
              objectTaskData?.dataSet?.customElements?.approved
                ? "Approved"
                : "Waiting"
            }
            onChange={(isApproved) => {
              helperupdateStackData &&
                helperupdateStackData("customElements", {
                  approved: isApproved,
                });
            }}
            options={additionalValue}
          />
        );

      case AddElementOptionValuesEnum.MultiselectLabels:
        return (
          <MultiSelectLabelView
            options={additionalValue}
            initialValues={
              objectTaskData?.dataSet?.customElements?.multiSelectLabels || []
            }
            onChange={(selectedLabels) => {
              helperupdateStackData &&
                helperupdateStackData("customElements", {
                  multiSelectLabels: selectedLabels,
                });
            }}
          />
        );

      case AddElementOptionValuesEnum.Person:
        return (
          <PersonView
            initialUserId={
              objectTaskData?.dataSet?.customElements?.person || ""
            } // Initialize with the existing user ID if available
            onChange={(userId) => {
              helperupdateStackData("customElements", {
                person: userId, // Update the 'person' field with the selected user ID
              });
            }}
          />
        );

      case AddElementOptionValuesEnum.FileMedia:
        return <FileMediaView />;

      case AddElementOptionValuesEnum.URL:
        return (
          <UrlView
            initialValue={objectTaskData?.dataSet?.customElements?.url || ""} // Initial URL if available
            onChange={(validUrl) => {
              helperupdateStackData("customElements", {
                url: validUrl, // Update the 'url' field with the valid URL
              });
            }}
          />
        );

      case AddElementOptionValuesEnum.Email:
        return (
          <EmailView
            initialValue={objectTaskData?.dataSet?.customElements?.email || ""}
            onChange={(emailValue) => {
              helperupdateStackData("customElements", { email: emailValue });
            }}
          />
        );

      case AddElementOptionValuesEnum.Phone:
        return (
          <PhoneView
            initialValue={objectTaskData?.dataSet?.customElements?.phone || ""}
            onChange={(phoneValue) => {
              helperupdateStackData("customElements", { phone: phoneValue });
            }}
          />
        );

      case AddElementOptionValuesEnum.Formula:
        return (
          <FormulaView
            initialValue={
              objectTaskData?.dataSet?.customElements?.formula || {}
            }
            onChange={(formulaValue) => {
              helperupdateStackData("customElements", {
                formula: formulaValue,
              });
            }}
          />
        );

      case AddElementOptionValuesEnum.Relation:
        return <RelationView />;

      case AddElementOptionValuesEnum.Rollup:
        return <RollupView />;

      case AddElementOptionValuesEnum.ProgressManual:
        return (
          <ProgressManualView
            initialValue={
              objectTaskData?.dataSet?.customElements?.progressManual || 0
            }
            onChange={(newRating) => {
              helperupdateStackData("customElements", {
                progressManual: newRating, // Update the 'rating' field with the selected value
              });
            }}
          />
        );

      case AddElementOptionValuesEnum.ProgressAuto:
        return (
          <ProgressAutoView
            progress={
              objectTaskData?.dataSet?.customElements?.progressManual || 0
            }
          />
        );

      case AddElementOptionValuesEnum.Rating:
        return (
          <RatingView
            initialValue={objectTaskData?.dataSet?.customElements?.rating || 0} // Default to 0 if no rating
            onChange={(newRating) => {
              helperupdateStackData("customElements", {
                rating: newRating, // Update the 'rating' field with the selected value
              });
            }}
          />
        );

      case AddElementOptionValuesEnum.Money:
        return (
          <MoneyView
            initialValue={objectTaskData?.dataSet?.customElements?.money || 0}
            currencyType={
              objectTaskData?.dataSet?.customElements?.currencyType || "USD"
            } // Set currency type
            onChange={(newAmount) => {
              helperupdateStackData("customElements", {
                money: newAmount, // Update the 'money' field with the new value
              });
            }}
          />
        );

      case AddElementOptionValuesEnum.Location:
        return (
          <LocationView
            initialValue={
              objectTaskData?.dataSet?.customElements?.location || 0
            }
            onChange={(value) => {
              helperupdateStackData("customElements", {
                location: value, // Update the 'money' field with the new value
              });
            }}
          />
        );

      case AddElementOptionValuesEnum.DependentDropdown:
        return <DependentDropdownView />;

      case AddElementOptionValuesEnum.RichText:
        return <RichTextView />;

      case AddElementOptionValuesEnum.ColorPicker:
        return (
          <ColorPickerView
            options={additionalValue} // List of color options
            initialValue={
              objectTaskData?.dataSet?.customElements?.colorPicker || []
            }
            onChange={(newColors) => {
              helperupdateStackData("customElements", {
                colorPicker: newColors, // Update the 'colorPicker' field with selected colors
              });
            }}
          />
        );

      case AddElementOptionValuesEnum.Countdown:
        return (
          <CountdownView
            initialDate={dayjs(
              objectTaskData?.dataSet?.customElements?.countdown
            )} // Pass initial date if available
            onChange={(newDate) => {
              helperupdateStackData("customElements", {
                countdown: newDate, // Update the 'countdown' field with the selected date in ISO format
              });
            }}
          />
        );

      case AddElementOptionValuesEnum.Connection:
        return <ConnectionView />;

      case AddElementOptionValuesEnum.Voting:
        return <VotingView />;

      case AddElementOptionValuesEnum.Percentage:
        return (
          <PercentageView
            initialValue={objectTaskData?.dataSet?.customElements?.percentage}
            onChange={(value) => {
              helperupdateStackData("customElements", {
                percentage: value, // Update the 'countdown' field with the selected date in ISO format
              });
            }}
          />
        );

      default:
        break;
    }
  };

  return (
    <Stack
      sx={{
        flexDirection: "row",
        height: "min-content",
        minHeight: "40px",
        borderRight: "0.5px solid #E9EDF2",
        borderBottom: "0.5px solid #E9EDF2",
        borderLeft: "0.5px solid #E9EDF2",
        boxSizing: "border-box",
      }}
    >
      {/* left part >> dragAndDropIcon and Emoji and FieldName */}
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "start",
          flex: "0 0 45%",
          borderRight: "1px solid #E9EDF2",
          gap: "10px",
          padding: "10px 10px",
          boxSizing: "border-box",
        }}
      >
        <Stack sx={{ flexDirection: "row", gap: "10px" }}>
          <IconButton sx={{ p: 0 }} disableRipple>
            <DragAndDropIcon w={15} />
          </IconButton>

          {emoji && (
            <InputBase
              sx={{
                width: "15px",
                height: "15px",
                font: "normal normal 300 13px/17px Source Serif Pro",
              }}
              value={emoji.native}
              readOnly
            />
          )}
        </Stack>

        <Tooltip placement="top" arrow title={description}>
          <Typography
            sx={{
              font: "normal normal 300 13px/17px Source Serif Pro",
              cursor: "default",
              wordBreak: "break-word", // Break long words to the next line
              whiteSpace: "pre-wrap", // Preserve spaces and line breaks
            }}
          >
            {fieldname}
          </Typography>
        </Tooltip>
      </Stack>
      {/* right part >> details and input field here */}
      <Stack
        sx={{
          flexGrow: 1,
          padding: "5px 10px",
          boxSizing: "border-box",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {renderElement()}
      </Stack>
    </Stack>
  );
};

export default CustomField;
