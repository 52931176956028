import {
    AddressLocationIcon,
    AngelListIcon,
    BirthdayCakeOutlineIcon,
    EmploymentTypeIcon,
    BuildingIcon,
    CallIcon,
    DataIDnumberIcon,
    DateIcon,
    DepartmentIcon,
    EmailIcon,
    EmployeeCountIcon,
    FacebookIcon,
    FullNameIcon,
    GenderIcon,
    InstagramIcon,
    JobTitleIcon,
    LastCalendarIcon,
    LinkedinIcon,
    LocationIcon,
    NamebadgeIcon,
    RelationshipStrengthIcon,
    TotalhoursIcon,
    XIcon,
} from "../../../images";
import { DragAndDropMenuListType } from "../DragAndDropMenu";



export const AddColumnEmployeeDirectoryList: DragAndDropMenuListType[] = [
    { id: 1, icon: DataIDnumberIcon, label: "Full name", translatedWord: "TRAX.FULL_NAME", draggable: true },
    { id: 2, icon: NamebadgeIcon, label: "Nickname", translatedWord: "TRAX.NICKNAME", draggable: true },
    {
        id: 3,
        icon: BirthdayCakeOutlineIcon,
        label: "Date of Birth",
        translatedWord: "TRAX.DATE_OF_BIRTH", draggable: true,
    },
    { id: 4, icon: GenderIcon, label: "Gender", translatedWord: "TRAX.GENDER", draggable: true },
    { id: 5, icon: FullNameIcon, label: "Profile Picture", translatedWord: "TRAX.PROFILE_PICTURE", draggable: true },
    { id: 6, icon: EmailIcon, label: "Personal Email", translatedWord: "TRAX.PERSONAL_EMAIL", draggable: true },
    {
        id: 7,
        icon: CallIcon,
        label: "Phone Number Mobile",
        translatedWord: "TRAX.PHONE_NUMBER_MOBILE", draggable: true,
    },
    {
        id: 8,
        icon: CallIcon,
        label: "Phone Number Home",
        translatedWord: "TRAX.PHONE_NUMBER_HOME", draggable: true,
    },
    { id: 9, icon: AddressLocationIcon, label: "Home Address", translatedWord: "TRAX.HOME_ADDRESS", draggable: true },
    { id: 10, icon: AngelListIcon, label: "AngelList", translatedWord: "TRAX.ANGELLIST", draggable: true },
    { id: 11, icon: FacebookIcon, label: "Facebook", translatedWord: "TRAX.FACEBOOK", draggable: true },
    { id: 12, icon: InstagramIcon, label: "Instagram", translatedWord: "TRAX.INSTAGRAM", draggable: true },
    { id: 13, icon: XIcon, label: "X", translatedWord: "TRAX.X", draggable: true },
    { id: 14, icon: LinkedinIcon, label: "LinkedIn", translatedWord: "TRAX.LINKEDIN", draggable: true },
    { id: 15, icon: JobTitleIcon, label: "Employee ID", translatedWord: "TRAX.EMPLOYEE_ID", draggable: true },
    { id: 16, icon: EmployeeCountIcon, label: "Job Title", translatedWord: "TRAX.JOB_TITLE", draggable: true },
    { id: 17, icon: DepartmentIcon, label: "Department", translatedWord: "TRAX.DEPARTMENTS", draggable: true },
    { id: 18, icon: DepartmentIcon, label: "Manager", translatedWord: "TRAX.MANAGER", draggable: true },
    { id: 19, icon: DepartmentIcon, label: "Team Lead", translatedWord: "TRAX.TEAM_LEAD", draggable: true },
    { id: 20, icon: DepartmentIcon, label: "Team name", translatedWord: "TRAX.TEAM_NAME", draggable: true },
    {
        id: 21,
        icon: EmploymentTypeIcon,
        label: "Employment Type",
        translatedWord: "TRAX.EMPLOYEMNT_TYPE", draggable: true,
    },
    { id: 22, icon: DateIcon, label: "Start Date", translatedWord: "TRAX.START_DATE", draggable: true },
    { id: 23, icon: LastCalendarIcon, label: "End Date", translatedWord: "TRAX.END_DATE", draggable: true },
    {
        id: 24,
        icon: RelationshipStrengthIcon,
        label: "Employment Status",
        translatedWord: "TRAX.EMPLOYMENT_STATUS", draggable: true,
    },
    {
        id: 25,
        icon: LocationIcon,
        label: "Location Country",
        translatedWord: "TRAX.LOCATION_COUNTRY", draggable: true,
    },
    { id: 26, icon: BuildingIcon, label: "Location City", translatedWord: "TRAX.LOCATION_CITY", draggable: true },
    {
        id: 27,
        icon: TotalhoursIcon,
        label: "Location Timezone",
        translatedWord: "TRAX.LOCATION_TIMEZONE", draggable: true,
    },
    {
        id: 28,
        icon: CallIcon,
        label: "Office Phone number",
        translatedWord: "TRAX.OFFICE_PHONE_NUMBER", draggable: true,
    },
    { id: 29, icon: EmailIcon, label: "Employee Email", translatedWord: "TRAX.EMPLOYEE_EMAIL", draggable: true },
];