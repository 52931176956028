import { createApiAction } from "../constantApi_config";
import {
    createMeetingFailureAction,
    createMeetingRequestAction,
    createMeetingSuccessAction,
    getSingleMeetingFailureAction,
    getSingleMeetingRequestAction,
    getSingleMeetingSuccessAction,
    getUserMeetingsFailureAction,
    getUserMeetingsRequestAction,
    getUserMeetingsSuccessAction,
    updateAgendaStatusRequestAction,
    updateAgendaStatusSuccessAction,
    updateAgendaStatusFailureAction,
    deleteAgendaRequestAction,
    deleteAgendaSuccessAction,
    deleteAgendaFailureAction,
    createMeetingNoteRequestAction,
    createMeetingNoteSuccessAction,
    createMeetingNoteFailureAction,
    updateMeetingNoteSuccessAction,
    updateMeetingNoteFailureAction,
    updateMeetingNoteRequestAction,
    getAllMeetingNoteRequestAction,
    getAllMeetingNoteSuccessAction,
    getAllMeetingNoteFailureAction,
    deleteMeetingNotesRequestAction,
    deleteMeetingNotesSuccessAction,
    deleteMeetingNotesFailureAction,
    getOnGoingMeetingAgendaRequestAction,
    getOnGoingMeetingAgendaSuccessAction,
    getOnGoingMeetingAgendaFailureAction,
    getSelectedMeetingAgendaRequestAction,
    getSelectedMeetingAgendaSuccessAction,
    getSelectedMeetingAgendaFailureAction,
    createMeetingAgendaSuccessAction,
    createOnGoingMeetingAgendaSuccessAction,
    getPastMeetingsRequestAction,
    getPastMeetingsSuccessAction,
    getPastMeetingsFailureAction,
    getCallActivityDetailsFailureAction,
    getCallActivityDetailsSuccessAction,
    getCallActivityDetailsRequestAction,
    getPastMeetingDetailsFailureAction,
    getPastMeetingDetailsSuccessAction,
    getPastMeetingDetailsRequestAction,
    addWorkspacesAndProjectsToMeetingRequestAction,
    addWorkspacesAndProjectsToMeetingSuccessAction,
    addWorkspacesAndProjectsToMeetingFailureAction,
    getUserProjectsRequestAction,
    getUserProjectsSuccessAction,
    getUserProjectsFailureAction,
    updateMeetingsDetails__SuccessAction,
} from "./VideoCenterSlice";

export const createNewMeeting = (data: any) =>
  createApiAction({
    onStart: createMeetingRequestAction.type,
    onSuccess: createMeetingSuccessAction.type,
    onError: createMeetingFailureAction.type,
    method: "POST",
    data,
    url: "/meeting/create",
    axiosRequired: true,
    unwrap: false,
  });

export const getSingleMeeting = (meetingId: string) =>
  createApiAction({
    onStart: getSingleMeetingRequestAction.type,
    onSuccess: getSingleMeetingSuccessAction.type,
    onError: getSingleMeetingFailureAction.type,
    method: "GET",
    url: `/meeting/${meetingId}`,
    axiosRequired: true,
    unwrap: false,
  });

export const getUpcomingMeetings = () =>
  createApiAction({
    onStart: getUserMeetingsRequestAction.type,
    onSuccess: getUserMeetingsSuccessAction.type,
    onError: getUserMeetingsFailureAction.type,
    method: "GET",
    url: `/calendar/events`,
    axiosRequired: true,
    unwrap: false,
  });

export const getPastMeetings = () =>
  createApiAction({
    onStart: getPastMeetingsRequestAction.type,
    onSuccess: getPastMeetingsSuccessAction.type,
    onError: getPastMeetingsFailureAction.type,
    method: "GET",
    url: `/meeting/past/all`,
    axiosRequired: true,
    unwrap: false,
  });

// export const getAttendee = (meetingId: string, attendeeId: string) => createApiAction({
//     onStart: "meeting/get/request",
//     onSuccess: "meeting/get/success",
//     onError: "meeting/get/failure",
//     method: "GET",
//     url: `/meeting/${meetingId}/${attendeeId}`,
//     axiosRequired: true,
//     unwrap: false
// });

export const createAttendeeAction = (meetingId: string) =>
  createApiAction({
    onStart: "meeting/attendee/create/request",
    onSuccess: "meeting/attendee/create/success",
    onError: "meeting/attendee/create/failure",
    method: "POST",
    url: `/meeting/${meetingId}/attendees`,
    axiosRequired: true,
    unwrap: false,
  });
export const endMeeting = (data: any, meetingId: string) =>
  createApiAction({
    onStart: "meeting/attendee/create/request",
    onSuccess: "meeting/attendee/create/success",
    onError: "meeting/attendee/create/failure",
    method: "POST",
    url: `/meeting/${meetingId}/end`,
    axiosRequired: true,
    data,
    unwrap: false,
  });
export const requestToJoinMeeting = (data: any) =>
  createApiAction({
    onStart: "meeting/attendee/create/request",
    onSuccess: "meeting/attendee/create/success",
    onError: "meeting/attendee/create/failure",
    method: "POST",
    url: `/meeting/${data.meeting_id}/join-request`,
    axiosRequired: true,
    data,
    unwrap: false,
  });
export const responseToJoinMeeting = (data: any) =>
  createApiAction({
    onStart: "meeting/attendee/create/request",
    onSuccess: "meeting/attendee/create/success",
    onError: "meeting/attendee/create/failure",
    method: "POST",
    url: "/meeting/join-response",
    axiosRequired: true,
    data,
    unwrap: false,
  });

export const getOnGoingMeetingAgenda = (id: any) =>
  createApiAction({
    onStart: getOnGoingMeetingAgendaRequestAction.type,
    onSuccess: getOnGoingMeetingAgendaSuccessAction.type,
    onError: getOnGoingMeetingAgendaFailureAction.type,
    method: "GET",
    url: `/meeting/${id}/agendas`,
    axiosRequired: true,
    unwrap: false,
  });
export const getSelectedMeetingAgenda = (id: any) =>
  createApiAction({
    onStart: getSelectedMeetingAgendaRequestAction.type,
    onSuccess: getSelectedMeetingAgendaSuccessAction.type,
    onError: getSelectedMeetingAgendaFailureAction.type,
    method: "GET",
    url: `/meeting/${id}/agendas`,
    axiosRequired: true,
    unwrap: false,
  });
export const createMeetingAgenda = (data: any, meetingId: string) =>
  createApiAction({
    onStart: "meeting/attendee/create/request",
    onSuccess: createMeetingAgendaSuccessAction.type,
    onError: "meeting/attendee/create/failure",
    method: "POST",
    url: `/meeting/${meetingId}/agendas`,
    data,
    axiosRequired: true,
    unwrap: false,
  });
export const createOnGoingMeetingAgenda = (data: any, meetingId: string) =>
  createApiAction({
    onStart: "meeting/attendee/create/request",
    onSuccess: createOnGoingMeetingAgendaSuccessAction.type,
    onError: "meeting/attendee/create/failure",
    method: "POST",
    url: `/meeting/${meetingId}/agendas`,
    data,
    axiosRequired: true,
    unwrap: false,
  });
export const updateAgendaStatus = (id: any, payload: any) =>
  createApiAction({
    onStart: updateAgendaStatusRequestAction.type,
    onSuccess: updateAgendaStatusSuccessAction.type,
    onError: updateAgendaStatusFailureAction.type,
    method: "PUT",
    url: `/meeting/agendas/${id}/status`,
    data: payload,
    axiosRequired: true,
    unwrap: false,
  });
export const updateAgendaDetails = (id: any, payload: any) =>
  createApiAction({
    onStart: updateAgendaStatusRequestAction.type,
    onSuccess: updateAgendaStatusSuccessAction.type,
    onError: updateAgendaStatusFailureAction.type,
    method: "PUT",
    url: `/meeting/agendas/${id}`,
    data: payload,
    axiosRequired: true,
    unwrap: false,
  });
export const deleteAgenda = (id: any) =>
  createApiAction({
    onStart: deleteAgendaRequestAction.type,
    onSuccess: deleteAgendaSuccessAction.type,
    onError: deleteAgendaFailureAction.type,
    method: "DELETE",
    url: `/meeting/agendas/${id}`,
    axiosRequired: true,
    unwrap: false,
  });

export const createMeetingNotes = (data: any) =>
  createApiAction({
    onStart: createMeetingNoteRequestAction.type,
    onSuccess: createMeetingNoteSuccessAction.type,
    onError: createMeetingNoteFailureAction.type,
    method: "POST",
    url: `/meeting/${data.meetingId}/notes`,
    data,
    axiosRequired: true,
    unwrap: false,
  });
export const createOrUpdateNote = (id: any, data: any) =>
  createApiAction({
    onStart: updateMeetingNoteSuccessAction.type,
    onSuccess: updateMeetingNoteRequestAction.type,
    onError: updateMeetingNoteFailureAction.type,
    method: "POST",
    url: `/meeting/${id}/notes`,
    data,
    axiosRequired: true,
    unwrap: false,
  });
export const getAllMeetingNotes = (id: any) =>
  createApiAction({
    onStart: getAllMeetingNoteRequestAction.type,
    onSuccess: getAllMeetingNoteSuccessAction.type,
    onError: getAllMeetingNoteFailureAction.type,
    method: "GET",
    url: `/meeting/${id}/notes`,
    axiosRequired: true,
    unwrap: false,
  });

export const deleteMeetingNotes = (id: any) =>
  createApiAction({
    onStart: deleteMeetingNotesRequestAction.type,
    onSuccess: deleteMeetingNotesSuccessAction.type,
    onError: deleteMeetingNotesFailureAction.type,
    method: "DELETE",
    url: `/meeting/notes/${id}`,
    axiosRequired: true,
    unwrap: false,
  });
export const logMeetingActivity = (id: any, data: any) =>
  createApiAction({
    onStart: "deleteMeetingNotesRequestAction.type",
    onSuccess: "deleteMeetingNotesSuccessAction.type",
    onError: "deleteMeetingNotesFailureAction.type",
    method: "POST",
    data,
    url: `/meeting/${id}/log-event`,
    axiosRequired: true,
    unwrap: false,
  });


export const getCallActivityDetails = (id:string | undefined) =>
    createApiAction({
        onStart: getCallActivityDetailsRequestAction.type,
        onSuccess: getCallActivityDetailsSuccessAction.type,
        onError: getCallActivityDetailsFailureAction.type,
        method: "GET",
        url: `/meeting/activity/${id}`,
        axiosRequired: true,
        unwrap: false,
    });

export const getPastMeetingDetails = (id:string | undefined) =>
    createApiAction({
        onStart: getPastMeetingDetailsRequestAction.type,
        onSuccess: getPastMeetingDetailsSuccessAction.type,
        onError: getPastMeetingDetailsFailureAction.type,
        method: "GET",
        url: `/meeting/${id}/past/details`,
        axiosRequired: true,
        unwrap: false,
    });
export const addWorkspacesAndProjectsToMeeting = (data:any) =>
    createApiAction({
        onStart: addWorkspacesAndProjectsToMeetingRequestAction.type,
        onSuccess: addWorkspacesAndProjectsToMeetingSuccessAction.type,
        onError: addWorkspacesAndProjectsToMeetingFailureAction.type,
        method: "POST",
        url: `/meeting/add-workspaces-projects`,
        data,
        axiosRequired: true,
        unwrap: false,
    });
export const getUserProjects = (userId:any) =>
    createApiAction({
        onStart: getUserProjectsRequestAction.type,
        onSuccess: getUserProjectsSuccessAction.type,
        onError: getUserProjectsFailureAction.type,
        method: "GET",
        url: `/project/get-all-project?ownerId=${userId}`,
        axiosRequired: true,
        unwrap: false,
    });

export const updateMeetingsDetails = (calendarId: any,data:any) =>
    createApiAction({
        axiosRequired: true,
        method: "put",
        data: data,
        url: `/calendar/update/${calendarId}`,
        onStart: "updateCalendarEvent__RequestAction.type",
        onSuccess: updateMeetingsDetails__SuccessAction.type,
        onError: "updateCalendarEvent__FailureAction.type",
    });
