/* DealTable.tsx */
import React, { useEffect, useState } from "react";
import { ColDef, CellValueChangedEvent } from "ag-grid-community";
import { ICellRendererParams } from "ag-grid-community";
import {
  AddCalculationsMenu,
  CName,
  DataTable,
  DealData,
} from "../../../components";
import {
  AddColumnCrmTable,
  CustomHeaderComponent,
  DealValueView,
  TableDealOwnerView,
} from "../../../common";
import {
  DropdownWithColor,
  DUMMY_DEALSTAGE_OPTIONS,
} from "../../../../../common";
import {
  AssociatedDealsIcon,
  CompanyIcon,
  DealStageIcon,
  DescriptionIcon,
  FundingIcon,
  StrongestrelationshipIcon,
} from "../../../../../../images";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/store";

const dealColumnDefs: ColDef<DealData>[] = [
  {
    headerName: "Deal",
    field: "deal",
    headerClass: "my-header-class",
    sortable: true,
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    pinned: "left",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      headerSvg: AssociatedDealsIcon, // URL of the image
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return (
        <CName
          targetOpen={true}
          targetLocation={"deal"}
          value={params?.value}
          p={params}
        />
      );
    },
  },
  {
    headerName: "Deal Stage",
    field: "dealStage",
    headerClass: "my-header-class",
    sortable: true,
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 150,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      headerSvg: DealStageIcon,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      // return <DealStatusDropdownTable params={params} width="150px" />;
      return (
        <Stack mt={"15px"}>
          <DropdownWithColor
            options={DUMMY_DEALSTAGE_OPTIONS}
            onChange={(value) => null}
            onSelect={(value) => null}
            initialValue={DUMMY_DEALSTAGE_OPTIONS[1]}
            disableAddingNewOpt
            // disableColorEditing
          />
        </Stack>
      );
    },
  },
  {
    headerName: "Deal Owner",
    field: "dealOwner",
    headerClass: "my-header-class",
    sortable: true,
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      headerSvg: StrongestrelationshipIcon,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      // return <CName value={params.value.dealOwnerName} />;
      return <TableDealOwnerView p={params} />;
    },
  },
  {
    headerName: "Deal Value",
    field: "dealValue",
    headerClass: "my-header-class",
    sortable: true,
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 150,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      headerSvg: FundingIcon,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      // return <CName value={params.value} />;
      return <DealValueView value={params.value} />;
    },
  },
  {
    headerName: "Description",
    field: "description",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      headerSvg: DescriptionIcon,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <CName value={params.value} />;
    },
  },
  {
    headerName: "Company",
    field: "company",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      headerSvg: CompanyIcon,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <CName value={params.value.companyName} />;
    },
  },
];

const dealRowData: DealData[] = [
  {
    deal: "Webex deal",
    description:
      "The relationship strength of the company that owns the company.",
    dealStage: {
      dealStageName: "Negotiation",
      dealStageColor: "green",
      _id: "deal-5q",
    },
    dealOwner: {
      dealOwnerName: "John Doe",
      dealOwnerAvatarUrl: "https://i.pravatar.cc/150?img=51",
      _id: "owner-1a",
    },
    dealValue: "$500,000.00",
    company: {
      companyName: "Tech Corp",
      companyLogoUrl: "https://i.pravatar.cc/150?img=51",
    },
  },
];

const pinnedBottomRowData = [
  {
    deal: "Tech Corp",
    description:
      "The relationship strength of the company that owns the company.",
    dealStage: {
      dealStageName: "Negotiation",
      dealStageColor: "green",
      _id: "deal-5q",
    },
    actions: <AddCalculationsMenu />, // Rendering your component
    dealOwner: {
      dealOwnerName: "John Doe",
      dealOwnerAvatarUrl: "https://i.pravatar.cc/150?img=51",
      _id: "owner-1a",
    },
    dealValue: "$1M",
    company: {
      companyName: "Tech Corp",
      companyLogoUrl: "https://i.pravatar.cc/150?img=51",
    },
  },
];

const updateDealData = async (event: CellValueChangedEvent<DealData>) => {
  const updatedData = event.data;
  // Make an API call to update the data
  try {
    console.log("Data updated successfully:", updatedData);
  } catch (error) {
    console.error("Error updating data:", error);
  }
};

const DealTable = () => {
  const crmState = useSelector((state: RootState) => state && state?.crm);
  const { dealList } = crmState || {};

  const [rowData, setRowData] = useState<any>([]);
  const [columnDefs] = useState(dealColumnDefs);
  useEffect(() => {
    if (dealList && dealList?.length > 0) {
      const transformedDealList: DealData[] = dealList?.map((deal: any) => ({
        deal: deal?.dealName,
        description: deal?.description || "No description provided.",
        dealIcon: deal?.dealIcon || "No description provided.",
        dealStage: {
          dealStageName: deal?.dealStage || "Unknown Stage",
          dealStageColor: "blue", // You may add logic for color based on stage
          _id: deal?._id, // ID for the stage (or deal itself)
        },
        dealOwner: deal?.dealOwner,
        dealValue: `$${deal?.dealValue?.toLocaleString()}`, // Format deal value as currency
        company: {
          companyName: deal?.company?.name || "Unknown Company", // Handle null company
          companyLogoUrl:
            deal?.company?.logo || "https://via.placeholder.com/150", // Placeholder image
        },
        _id: deal?._id, //
        allData: deal,
      }));

      setRowData(transformedDealList); // Set the transformed data into the state
    }
  }, [dealList]);
  return (
    <Stack width={"100%"} direction={"row"} alignItems={"flex-start"} gap={0}>
      <DataTable<DealData>
        columnDefs={columnDefs}
        rowData={rowData}
        pinnedBottomRowData={pinnedBottomRowData}
        onCellValueChanged={updateDealData}
      />
      <AddColumnCrmTable />
    </Stack>
  );
};

export default DealTable;
