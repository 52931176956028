import { Box, Button, Stack } from "@mui/material";
import React, { } from "react";
import {
  InboxIcon,
} from "../../../../../../../images";

type InboxCategory = {
  name: string;
  count: number;
  icon: React.ReactNode; // Add icon type
};

type SmartFilterProps = {
  handleFilterEmails: (value: string) => Promise<void>;
  getFilters?: () => Promise<void>;
  activeFilterList: FilterItem[];
};



interface FilterItem {
  _id: string;
  name: string;
  isActive: boolean;
  count: number;
  icon: React.ReactNode;
}

const SmartFilter: React.FC<SmartFilterProps> = ({ handleFilterEmails, activeFilterList }) => {



  // Dummy state for inbox categories
  // const [inboxCategories] = useState<InboxCategory[]>([
  //   { name: "Main Inbox", count: 1100, icon: <InboxIcon w={18} /> },
  //   { name: "No replies", count: 0, icon: <ReplyIcon w={18} /> },
  //   { name: "Bills", count: 2, icon: <InvoiceBillIcon w={18} /> },
  //   { name: "Newsletters", count: 2, icon: <ClosureIcon w={18} /> },
  //   { name: "Events", count: 2, icon: <DateIcon w={18} /> },
  //   { name: "Social Media", count: 2, icon: <MarketingIcon w={18} /> },
  // ]);




  return (
    <Box>
      <Stack gap={'5px'}>
        {activeFilterList?.filter((item, i) => {
          return item?.isActive
        })?.map((category, index) => (
          <Stack direction="row" alignItems="center" key={index} >
            <Button
              sx={{
                backgroundColor: "none",
                textTransform: "capitalize",
                font: "normal normal 300 13px/17px Source Serif Pro",
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
                color: "black",
              }}
              onClick={() => handleFilterEmails(category?._id)}
            >
              <Stack direction="row" alignItems="center">
                {/* {category.icon} */}
                <InboxIcon w={18} />
                <span style={{ marginLeft: "8px" }}>{category.name}</span>
              </Stack>
              <span style={{ color: "blue" }}>({1})</span>
            </Button>
          </Stack>
        ))}
      </Stack>
    </Box >
  );
};

export default SmartFilter;
