import { Stack, Typography } from "@mui/material";
import React from "react";
import { BreaksView, CheckInOrOutView, DateView, StatusView } from "./view";

interface AttendanceHistoryTableProps {}

const AttendanceHistoryTable: React.FC<AttendanceHistoryTableProps> = ({}) => {
  const tableHeader: { label: string; width: string }[] = [
    { label: "Date", width: "95px" },
    { label: "Check in", width: "95px" },
    { label: "Check out", width: "105px" },
    { label: "Breaks", width: "65px" },
    { label: "Status", width: "40px" },
  ];

  return (
    <Stack sx={{ height: "230px", width: "425px", gap: "5px" }}>
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {tableHeader.map((item, index) => (
          <div style={{ width: item.width }}>
            <Typography
              key={index}
              sx={{
                font: "normal normal 300 12px/15px Source Serif Pro",
              }}
            >
              {item.label}
            </Typography>
          </div>
        ))}
      </Stack>

      <Stack sx={{ overflow: "auto", scrollbarWidth: "none" }}>
        {DUMMY_AttendanceList.map((item, index) => (
          <Stack
            sx={{
              height: "30px",
              borderRadius: "5px",
              flexShrink: 0,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
              boxSizing: "border-box",
              border:
                index % 2 == 0
                  ? "0.25px solid #FAF3F3"
                  : "0.25px solid #E9EDF2",
              backgroundColor: index % 2 == 0 ? "#FAF3F3" : "white",
            }}
          >
            <DateView date={item.date} />
            <CheckInOrOutView value={item.checkinTime} />
            <CheckInOrOutView value={item.checkOutTime} />
            <BreaksView breakCount={item.break} />
            <StatusView status={item.status} />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default AttendanceHistoryTable;

const DUMMY_AttendanceList: {
  date: string;
  checkinTime?: string;
  checkOutTime?: string;
  break: number | string;
  status: string;
}[] = [
  {
    date: "oct, 01",
    checkinTime: "0:08:00 am",
    checkOutTime: "0:06:00 pm",
    break: "001",
    status: "onTime",
  },
  {
    date: "oct, 01",
    break: "001",
    status: "onTime",
  },
  {
    date: "oct, 01",
    break: "001",
    status: "late",
  },
  {
    date: "oct, 01",
    checkinTime: "0:08:00 am",
    checkOutTime: "0:06:00 pm",
    break: "001",
    status: "sick",
  },
  {
    date: "oct, 01",
    break: "001",
    status: "holiday",
  },
  {
    date: "oct, 01",
    checkinTime: "0:08:00 am",
    checkOutTime: "0:06:00 pm",
    break: "001",
    status: "pto",
  },
  {
    date: "oct, 01",
    break: "001",
    status: "absent",
  },
  {
    date: "oct, 01",
    checkinTime: "0:08:00 am",
    checkOutTime: "0:06:00 pm",
    break: "001",
    status: "dayoff",
  },
];
