import { Box, Fade } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TraxPaths, TraxComponentMap } from "./TraxType";
import { Status } from "./Status";
import { useLocation } from "react-router-dom";

type Props = {};

const Trax: React.FC<Props> = () => {
  const location = useLocation();

  const [checked, setChecked] = useState(true);
  const [currentPath, setCurrentPath] = useState<TraxPaths>(
    location.pathname as TraxPaths
  );

  const Component =
    TraxComponentMap[currentPath as keyof typeof TraxComponentMap] || Status;

  useEffect(() => {
    setChecked(false);
  }, [location?.pathname]);

  useEffect(() => {
    if (!checked) {
      const timer = setTimeout(() => {
        setCurrentPath(location?.pathname as TraxPaths);
        setChecked(true);
      }, 200); // Duration of the fade out transition
      return () => clearTimeout(timer);
    }
  }, [checked, location.pathname]);
  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Fade in={checked} timeout={{ enter: 200, exit: 200 }}>
        <Box sx={{ width: "100%" }}>
          <Component />
        </Box>
      </Fade>
    </Box>
  );
};

export default Trax;
