import { Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { GreenDropdownIcon, TaskIcon } from "../../../../../../../images";
import {
  ButtonType,
  CheckboxMedium,
  DatePickerWithIcon,
  DropdownWithColor,
  DUMMY_TODO_OPTIONS,
  GreenArrowButton,
  IconDropdownUser,
  ObjectiveTaskDropdown,
  SmallButtonDropdown,
  SmallButtonDropdownColor,
  useCustomSnackbar,
} from "../../../../../../common";
import { useDispatch } from "react-redux";
import {
  createSubTask__api,
  CreateSubTaskPayload,
  getAllSubTasks__api,
} from "../../../../../../../redux";

import styles from "../../../ObjectiveAndTaskLargeModel/ObjectiveAndTaskLargeModel.module.css";
import dayjs from "dayjs";
import { getUserList } from "../../../../../../../Utils";

interface SubTasksProps {
  componentId?: string;
}

const SubTasks: React.FC<SubTasksProps> = ({ componentId = "" }) => {
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(false);
  const [subTaskData, setSubTaskData] = useState<any[]>([]); // State for storing subtasks

  const toggleContainer = () => {
    setIsExpanded(!isExpanded);
  };

  const handleCreateSubTask = async (
    taskName: string,
    status: any,
    dueDate: string,
    selectedUser: any
  ) => {
    if (!componentId) {
      return;
    }

    // Map selectedUser to an array of user IDs
    const assignedUserIds = Array?.isArray(selectedUser)
      ? selectedUser?.map((user: any) => user?._id)
      : [selectedUser?._id]; // Handle the case where selectedUser might be a single object

    // Ensure assignedUserIds is not empty
    if (assignedUserIds?.length === 0) {
      console.error("At least one user must be assigned to the subtask.");
      return;
    }

    const payload: CreateSubTaskPayload = {
      name: taskName,
      details: "",
      assignedUserIds: assignedUserIds,
      dueDate: dueDate,
      status: status,
      taskColor: "#FFA500",
    };

    const action = createSubTask__api(componentId, payload);

    await dispatch(action);
    handleGetAllSubtasks(); // Refresh the subtask list after creating a new one
  };

  const handleGetAllSubtasks = async () => {
    if (!componentId) return;
    const action = getAllSubTasks__api(componentId, 1, 10, "asc");
    const res: any = await dispatch(action);
    if (res?.data) {
      setSubTaskData(res?.data); // Update state with fetched subtask data
    }
  };
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    if (!componentId) return;
    handleGetAllSubtasks();
  }, [componentId]);
  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const contentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (contentRef?.current) {
      if (expanded) {
        contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
      } else {
        contentRef.current.style.maxHeight = "0px";
      }
    }
  }, [expanded]);

  const [taskName, setTaskName] = useState("");
  const [status, setStatus] = useState<any>(null); // Ensure status is properly typed
  const [dueDate, setDueDate] = useState("");
  const [selectedUser, setSelectedUser] = useState<any>(null); // Ensure selectedUser is properly typed
  const enqueueSnackbar_v1 = useCustomSnackbar();
  const addSubtask = () => {
    if (!status || !selectedUser || !taskName || !dueDate) {
      enqueueSnackbar_v1(
        "error",
        `Please fill all the fields (Status, User, Taskname and Due date) `,
        {
          position: { vertical: "bottom", horizontal: "left" },
          autoHideDuration: 5000, // Optional custom auto-hide duration in milliseconds
        }
      );
      return;
    }
    if (handleCreateSubTask) {
      handleCreateSubTask(taskName, status, dueDate, selectedUser);
    }
    // Reset fields
    setTaskName("");
    setStatus(null);
    setDueDate("");
    setSelectedUser(null);
  };
  const userList = getUserList();
  return (
    <Stack sx={{ bgcolor: "white" }}>
      <Stack sx={{ flexDirection: "row", gap: "10px", alignItems: "center" }}>
        <TaskIcon w={24} />
        <Typography
          sx={{ font: "normal normal normal 18px/25px Source Serif Pro" }}
        >
          Sub-Task
        </Typography>
        <Typography
          sx={{ font: "normal normal bold 14px/20px Source Serif Pro" }}
        >
          {subTaskData?.length || 0} {/* Display total number of sub-tasks */}
        </Typography>
        <GreenArrowButton
          sx={{
            transform: isExpanded ? "rotate(90deg)" : "rotate(270deg)",
            transition: "transform 0.2s ease-in-out",
          }}
          onClick={toggleContainer}
        />
      </Stack>
      <Stack
        sx={{
          height: isExpanded ? "270px" : "0px",
          overflow: "hidden",
          transition: "height 0.3s ease",
          overflowY: "scroll",
          scrollbarWidth: "none",
          marginTop: "15px",
          gap: "25px",
        }}
        mt={1}
      >
        <Stack className={styles["objective-task-dropdown__item-header"]}>
          <Stack sx={{ flexGrow: 1 }} onClick={toggleExpand}>
            <Stack
              sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}
            >
              <GreenDropdownIcon sx={{ height: 13 }} open={expanded} />
              <Typography
                sx={{ font: "normal normal 300 12px/16px Source Serif Pro" }}
              >
                Add your subtask
              </Typography>
            </Stack>
          </Stack>
          {expanded && (
            <Stack
              ref={contentRef}
              className={`${styles["objective-task-dropdown__content"]} ${
                expanded ? styles.expanded : ""
              }`}
            >
              <Stack alignItems={'center'} direction={"row"} spacing={2}>
                <CheckboxMedium boxSize={19} />
                <TextField
                  label="Task Name"
                  value={taskName}
                  onChange={(e) => setTaskName(e.target.value)}
                  sx={{
                    padding: 0,
                    "& .MuiOutlinedInput-root": {
                      border: "none", // Remove border
                      "& fieldset": {
                        border: "none", // Remove border fieldset
                      },
                      "&:hover fieldset": {
                        border: "none", // Remove border on hover
                      },
                      "&.Mui-focused fieldset": {
                        border: "none", // Remove border when focused
                      },
                    },
                    "& .MuiInputBase-input": {
                      fontFamily: "Source Serif Pro, serif", // Change font
                      padding: "10px 5px", // Remove padding inside the input
                    },
                  }}
                />
              </Stack>

              <Stack ml={4.2} mt={2} gap={3} direction={"row"}>
                <DropdownWithColor
                  options={DUMMY_TODO_OPTIONS}
                  onChange={(value) => null}
                  onSelect={(value: any) => {
                    setStatus(value);
                  }}
                  initialValue={DUMMY_TODO_OPTIONS?.[1]}
                />
                <DatePickerWithIcon
                  initialDate={dueDate ? dayjs?.(dueDate) : null}
                  onDateChange={(date: string | undefined) =>
                    setDueDate(date || "")
                  }
                />
                <IconDropdownUser
                  componentType="showUser"
                  showSelectedUser={selectedUser}
                  onSelect={(item) => setSelectedUser(item || [])}
                  options={userList}
                />
                <SmallButtonDropdown
                  label="Add Task"
                  onClick={() => addSubtask()}
                  types={ButtonType.Button}
                  colorVarient={SmallButtonDropdownColor.Orange}
                />
              </Stack>
            </Stack>
          )}
        </Stack>

        {/* Render ObjectiveTaskDropdown for each subtask */}
        {subTaskData?.length ?
          subTaskData?.map((data) => (
            <ObjectiveTaskDropdown
              handleCreateSubTask={handleCreateSubTask}
              key={data?._id}
              options={[data]} // Pass subtask data
              contentRef={contentRef}
              expanded={expanded}
              setStatus={setStatus}
              setDueDate={setDueDate}
              setSelectedUser={setSelectedUser}
            />
          )):<>No subtasks available </>}
      </Stack>
    </Stack>
  );
};

export default SubTasks;
