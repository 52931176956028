import { Button, Stack, Typography, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { AddCalPlusIcon } from "../../../images";
import { AddElementListInterface } from "../ElementsOptionsList/ElementsOptionsListType";
import { AvatarNormal } from "../AvatarNormal";

interface AddElementDropdownProps {
  options?: AddElementListInterface[];
  onSelect?: (value: string) => void;
  textTile?: string;
}

const AddElementDropdown: React.FC<AddElementDropdownProps> = ({
  onSelect,
  options,
  textTile
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleSelect = (value: AddElementListInterface["value"]) => {
    if (onSelect) {
      onSelect(value);
    }
    handleClose();
  };

  return (
    <Stack>
      <Button
        variant="text"
        onClick={handleClick}
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
          textTransform: "none",
          padding: "2px 5px",
          color: "inherit",
          width: "125px",
        }}
      >
        <AddCalPlusIcon w={15} />
        <Typography
          sx={{
            font: "normal normal 300 15px/19px Source Serif Pro",
            color: "#000",
          }}
        >
          {textTile}
        </Typography>
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            padding: 0,
          },
        }}
      >
        {options?.map((item: any) => (
          <MenuItem
            key={item.id}
            sx={{
              padding: "0px 10px",
              alignItems: "center",
              height: "auto",
            }}
            onClick={() => handleSelect(item)}
          >
            <Stack
              sx={{
                flexDirection: "row",
                minHeight: "30px",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <AvatarNormal
                size={15}
                imgSrc={item.avatar}
              />
              <Typography
                sx={{ font: "normal normal 300 11px/15px Source Serif Pro" }}
              >
                {item?.firstName}
              </Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
};

export default AddElementDropdown;
