import React, { useState, useEffect } from "react";
import { CheckboxMedium } from "../../../../../../../../common";

// Define prop types using an interface
interface CheckboxViewProps {
  initialValue?: boolean;
  onChange?: (value: boolean) => void;
}

const CheckboxView: React.FC<CheckboxViewProps> = ({
  initialValue = false,
  onChange,
}) => {
  const [checked, setChecked] = useState(initialValue);

  // Update state when initialValue changes
  useEffect(() => {
    setChecked(initialValue);
  }, [initialValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    setChecked(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return <CheckboxMedium checked={checked} onChange={handleChange} />;
};

export default CheckboxView;
