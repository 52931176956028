import { Stack } from "@mui/material";
import React from "react";
import { DescriptionIcon } from "../../../../../../../images";
import { EditableTextArea } from "../../../../../../common";
import { EventTimeView } from "../../view";

interface TaskTabProps {
  updateField?: any;
  updateStartTime?: any;
  updateEndTime?: any;
  updateDate?: any;
}

const TaskTab: React.FC<TaskTabProps> = ({
  updateField,
  updateStartTime,
  updateEndTime,
  updateDate,
}) => {
  return (
    <Stack sx={{ gap: "30px" }}>
      <EventTimeView
        updateField={updateField}
        updateStartTime={updateStartTime}
        updateEndTime={updateEndTime}
        updateDate={updateDate}
      />
      <Stack sx={{ flexDirection: "row", alignItems: "start", gap: "10px" }}>
        <DescriptionIcon w={20} />
        <EditableTextArea
          onChange={(e: any) => {
            updateField && updateField("description", e?.target?.value);
          }}
          defaultValue={""}
          placeholder="Add description"
        />
      </Stack>
    </Stack>
  );
};

export default TaskTab;
