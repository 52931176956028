import { Box, Stack, SxProps, Typography } from "@mui/material";
import React from "react";
import { AvatarNormal, MediumSquareButton } from "../../../../../../common";
import {
  BearishLogoPng,
  DateIcon,
  EmailIcon,
  QuickMessageIcon,
  VideocamIcon,
} from "../../../../../../../images";
import { CrmStatusBadge } from "../../../CrmStatusBadge";
import { WorkSheduleArea } from "../../../WorkSheduleArea";
import BadgeAreaView from "../BadgeAreaView/BadgeAreaView";
import { TraxTextBadge } from "../../../../common";
import { EmpDirectoryType } from "../../../../EmployeeDirectory";

interface LeftContainerProps {
  sx?: SxProps;
  EmpData: EmpDirectoryType;
}

const LeftContainer: React.FC<LeftContainerProps> = ({ sx, EmpData }) => {
  return (
    <Stack sx={{ flex: 1, gap: "30px", overflow: "hidden", ...sx }}>
      {/* user image and name here  */}
      <Stack gap={"22px"}>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "start",
            justifyContent: "space-between",
          }}
        >
          <AvatarNormal
            username={EmpData.empDetails?.fullName}
            imgSrc={EmpData.empDetails?.imgSrc}
            size={125}
            sx={{ borderRadius: "10px", border: "2px solid #E9EDF2" }}
          />
          <Stack
            sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}
          >
            <Box
              component={"img"}
              src={BearishLogoPng}
              alt="logo"
              sx={{ height: "20px", width: "20px" }}
            />

            <CrmStatusBadge
              variant={EmpData.status.isActive ? "active" : "inActive"}
            />
          </Stack>
        </Stack>

        <Typography
          sx={{
            font: "normal normal 900 25px/31px Source Serif Pro",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {EmpData.empDetails.fullName}
        </Typography>

        <Stack sx={{ flexDirection: "row", gap: "10px" }}>
          <MediumSquareButton buttonIcon={VideocamIcon} />
          <MediumSquareButton buttonIcon={EmailIcon} />
          <MediumSquareButton buttonIcon={QuickMessageIcon} />
          <MediumSquareButton buttonIcon={DateIcon} />
        </Stack>
      </Stack>

      <Stack sx={{ overflow: "auto", scrollbarWidth: "none", gap: "20px" }}>
        <WorkSheduleArea data={EmpData.workSchedule} />

        <BadgeAreaView label="Current Projects">
          <TraxTextBadge bgcolor="#FF8181" label="UI Redesign" />
          <TraxTextBadge bgcolor="#FFB100" label="BEBA AI" />
          <TraxTextBadge bgcolor="#4500FF" label="Project name" />
          <TraxTextBadge bgcolor="#FF8181" label="UI" />
        </BadgeAreaView>

        <BadgeAreaView label="Skills" sx={{ paddingBottom: "30px" }}>
          <TraxTextBadge bgcolor="#21B481" label="UX Design" />
          <TraxTextBadge bgcolor="#B5005B" label="UI Design" />
          <TraxTextBadge bgcolor="#E2875E" label="Frontend" />
          <TraxTextBadge bgcolor="#FF8181" label="Dev Oops" />
        </BadgeAreaView>
      </Stack>
    </Stack>
  );
};

export default LeftContainer;
