import { IconButton } from "@mui/material";
import React from "react";
import { ReplyIcon } from "../../../../../../images";

interface ReplyButtonViewProps {}

const ReplyButtonView: React.FC<ReplyButtonViewProps> = ({}) => {
  return (
    <IconButton sx={{ p: 0 }} disableRipple>
      <ReplyIcon w={15} />
    </IconButton>
  );
};

export default ReplyButtonView;
