import { Divider, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React, { useState, lazy, Suspense } from "react";
import { XCircleIcon } from "../../../images";
import { QuickActionDisplayTypesEnum } from "./QuickActionsTypes";

// Lazily loaded components
const BadgeButtonsView = lazy(() => import("./View/BadgeButtonsView/BadgeButtonsView"));
const MessageView = lazy(() => import("./View/MessageView/MessageView"));
const NewEmailView = lazy(() => import("./View/NewEmailView/NewEmailView"));
const NewTaskView = lazy(() => import("./View/NewTaskView/NewTaskView"));
const NotesView = lazy(() => import("./View/NotesView/NotesView"));
const PlanEventView = lazy(() => import("./View/PlanEventView/PlanEventView"));
const StartCallView = lazy(() => import("./View/StartCallView/StartCallView"));

interface QuickActionsProps {}

const QuickActions: React.FC<QuickActionsProps> = ({}) => {
  const [displayViews, setDisplayViews] = useState<QuickActionDisplayTypesEnum>(
    QuickActionDisplayTypesEnum.Default
  );

  const setDefaultScreenHandler = () => {
    setDisplayViews(QuickActionDisplayTypesEnum.Default);
  };

  const renderDisplay = () => {
    switch (displayViews) {
      case QuickActionDisplayTypesEnum.Call:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <StartCallView onBackButtonClick={setDefaultScreenHandler} />
          </Suspense>
        );

      case QuickActionDisplayTypesEnum.Email:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <NewEmailView onBackButtonClick={setDefaultScreenHandler} />
          </Suspense>
        );

      case QuickActionDisplayTypesEnum.Event:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <PlanEventView onBackButtonClick={setDefaultScreenHandler} />
          </Suspense>
        );

      case QuickActionDisplayTypesEnum.Message:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <MessageView onBackButtonClick={setDefaultScreenHandler} />
          </Suspense>
        );

      case QuickActionDisplayTypesEnum.Notes:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <NotesView onBackButtonClick={setDefaultScreenHandler} />
          </Suspense>
        );

      case QuickActionDisplayTypesEnum.Task:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <NewTaskView onBackButtonClick={setDefaultScreenHandler} />
          </Suspense>
        );

      default:
        return (
          <Typography
            sx={{ font: "normal italic 14px/18px Source Serif Pro " }}
          >
            Select a quick option from above and more controls will appear here.
          </Typography>
        );
    }
  };

  return (
    <Stack
      sx={{
        width: "385px",
        height: "100%",
        bgcolor: "white",
        padding: "15px 15px 0px 15px",
        boxSizing: "border-box",
      }}
    >
      {/* ----------------    card title + Back button + close button --------------- */}
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "10px",
          alignItems: "start",
        }}
      >
        <Typography
          sx={{
            font: "normal normal 400 14px/18px Source Serif Pro",
            paddingBottom: "5px",
            borderBottom: "1px solid #E9EDF2",
          }}
        >
          Quick Actions
        </Typography>

        <Tooltip title="close" placement="top" arrow>
          <IconButton sx={{ p: 0 }} disableRipple>
            <XCircleIcon w={15} />
          </IconButton>
        </Tooltip>
      </Stack>

      {/* ---- menu here [ colored badged menu here] ---- */}

      <Stack>
        <Suspense fallback={<div>Loading menu...</div>}>
          <BadgeButtonsView setState={(value) => setDisplayViews(value)} />
        </Suspense>
        <Divider sx={{ borderColor: "#E9EDF2", marginY: "15px" }} />
      </Stack>
      {/* ----------------    card title + Back button + close button container end here --------------- */}

      {/*---------    scrollable container here  --------- */}

      <Stack
        sx={{
          flex: 1,
          overflowY: "scroll",
          scrollbarWidth: "none",
          gap: "20px",
          paddingBottom: "10px",
          marginBottom: "10px",
        }}
      >
        {renderDisplay()}
      </Stack>
      {/*---------    scrollable container end here  --------- */}
    </Stack>
  );
};

export default QuickActions;
