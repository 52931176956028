import React from "react";

const ImageViewer: React.FC<{ path: string }> = ({ path }) => (
  <img
    src={path}
    alt="Preview"
    style={{ maxWidth: "100%", maxHeight: "100%" }}
  />
);

export default ImageViewer;
