import { InputBase, Stack, Typography } from "@mui/material";
import React from "react";
import { CheckboxMedium } from "../../../CheckboxMedium";
import { SmallDateRangePicker } from "../../../SmallDateRangePicker";
import styles from "./SettingView.module.css";

interface LinkData {
  enableWDownload: boolean;
  askDetails: boolean;
  expirationDate: string | null;
  password: string;
  isWatermark: boolean;
}

interface SettingViewProps {
  linkData: LinkData;
  onUpdate: (field: keyof LinkData, value: any) => void;
}

const SettingView: React.FC<SettingViewProps> = ({ linkData, onUpdate }) => {
  return (
    <Stack className={styles["setting-view"]}>
      {/* -------------------------- */}
      <Stack flexDirection={"row"}>
        <Typography className={styles["setting-view__header"]}>
          Link Settings
        </Typography>
      </Stack>
      {/* -------------------------- */}

      {/* --------------  settings here ------------------ */}
      <Stack gap={"15px"}>
        {/* -----------checkbox 1 here-------- */}
        <Stack className={styles["setting-view__item"]}>
          <Stack className={styles["setting-view__row"]}>
            <CheckboxMedium
              boxSize={25}
              checked={linkData.enableWDownload}
              onChange={(e) => onUpdate("enableWDownload", e.target.checked)}
            />
            <Typography className={styles["setting-view__text"]}>
              Allow document to be downloaded
            </Typography>
          </Stack>
          <Typography className={styles["setting-view__description"]}>
            This will allow users to be able to download a copy of the file,
            document, image, or video.
          </Typography>
        </Stack>
        {/* -----------checkbox 1 end here-------- */}

        {/* -----------checkbox 2 here-------- */}
        <Stack className={styles["setting-view__item"]}>
          <Stack className={styles["setting-view__row"]}>
            <CheckboxMedium
              boxSize={25}
              checked={linkData.askDetails}
              onChange={(e) => onUpdate("askDetails", e.target.checked)}
            />
            <Typography className={styles["setting-view__text"]}>
              Require email and name to view
            </Typography>
          </Stack>
          <Typography className={styles["setting-view__description"]}>
            This will require the viewer to enter a name and email to view the
            document.{" "}
            <Typography
              component={"span"}
              className={styles["setting-view__italic"]}
            >
              To ensure the best possible analytics we highly recommend
              requiring this.
            </Typography>
          </Typography>
        </Stack>
        {/* -----------checkbox 2 end here-------- */}

        {/* -----------checkbox 3 here-------- */}
        <Stack className={styles["setting-view__item"]}>
          <Stack      id="objectivedatepicker" className={styles["setting-view__row"]}>
            <CheckboxMedium
              boxSize={25}
              checked={linkData.expirationDate !== null}
              onChange={(e) => {
                onUpdate(
                  "expirationDate",
                  e.target.checked ? "Your Expiration Date" : null
                ); // Replace with actual date picker logic
              }}
            />
            <Typography className={styles["setting-view__text"]}>
              Add a link expiration date
            </Typography>
            <SmallDateRangePicker
              onDateChange={(date: any) => onUpdate("expirationDate", date)} // Handle date change
            />
          </Stack>
        </Stack>
        {/* -----------checkbox 3 end here-------- */}

        {/* -----------checkbox 4 here-------- */}
        <Stack className={styles["setting-view__item"]}>
          <Stack className={styles["setting-view__row"]}>
            <CheckboxMedium
              boxSize={25}
              checked={linkData.password !== ""}
              onChange={(e) =>
                onUpdate("password", e.target.checked ? "Your Passcode" : "")
              } // Replace with actual passcode logic
            />
            <Typography className={styles["setting-view__text"]}>
              Add a link passcode
            </Typography>
            <InputBase
              placeholder="Type your passcode"
              className={styles["setting-view__input"]}
              value={linkData.password}
              onChange={(e) => onUpdate("password", e.target.value)}
            />
          </Stack>
        </Stack>
        {/* -----------checkbox 4 end here-------- */}

        {/* -----------checkbox 5 here-------- */}
        <Stack className={styles["setting-view__item"]}>
          <Stack className={styles["setting-view__row"]}>
            <CheckboxMedium
              boxSize={25}
              checked={linkData.isWatermark}
              onChange={(e) => onUpdate("isWatermark", e.target.checked)}
            />
            <Typography className={styles["setting-view__text"]}>
              Add a watermark
            </Typography>
          </Stack>
          <Typography className={styles["setting-view__description"]}>
            A watermark will be placed on every page of the document that will
            identify who the viewer is.
          </Typography>
        </Stack>
        {/* -----------checkbox 5 end here-------- */}
      </Stack>
      {/* --------------  settings end here ------------------ */}
    </Stack>
  );
};

export default SettingView;
