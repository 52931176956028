import * as React from "react";
import {
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import S from "./AccessDropDownButton.module.css";
import { GreenDropdownIcon, HalfLockIcon } from "../../../images";
import { CheckboxMedium } from "../CheckboxMedium";

type AccessType = "full" | "limited";

interface AccessDropDownButtonProps {
  width?: string;
  onChange?: (selectedOption: { access: AccessType }) => void;
  initialValue?: AccessType;
}

interface Option {
  id: number;
  title: string;
  subtitle: string;
  value: AccessType;
}

const OPTIONS: Option[] = [
  {
    id: 1,
    title: "Full Access",
    subtitle:
      "All team members have full access to see all communications with this record.",
    value: "full",
  },
  {
    id: 2,
    title: "Limited Access",
    subtitle: "Grant access to this record on a user-by-user basis below.",
    value: "limited",
  },
];

const AccessDropDownButton: React.FC<AccessDropDownButtonProps> = React.memo(
  ({ width = "125px", onChange, initialValue = "full", ...props }) => {
    const [selectedOption, setSelectedOption] =
      React.useState<AccessType>(initialValue);
    const [isOpen, setIsOpen] = React.useState(false);

    const handleChange = React.useCallback(
      (event: SelectChangeEvent<AccessType>) => {
        const {
          target: { value },
        } = event;
        setSelectedOption(value as AccessType);

        if (onChange) {
          onChange({ access: value as AccessType });
        }
      },
      [onChange]
    );

    const selectedOptionData = OPTIONS.find(
      (option) => option.value === selectedOption
    );

    const menuProps = {
      PaperProps: {
        className: S["access-dropdown-button__menu"],
        style: { width: "250px" },
      },
      anchorOrigin: {
        vertical: "bottom" as const,
        horizontal: "right" as const,
      },
      transformOrigin: {
        vertical: "top" as const,
        horizontal: "right" as const,
      },
    };

    return (
      <FormControl size="small" style={{ width }} {...props}>
        <Select
          open={isOpen}
          onClick={!isOpen ? () => setIsOpen(true) : () => null}
          displayEmpty
          IconComponent={() => (
            <GreenDropdownIcon
              open={!isOpen}
              sx={{ marginRight: "2px", height: "10px" }}
            />
          )}
          value={selectedOption}
          onChange={handleChange}
          input={
            <OutlinedInput
              className={S["access-dropdown-button__inputbox"]}
              classes={{
                notchedOutline: S["access-dropdown-button__notchedOutline"],
                focused: S["access-dropdown-button__focused"],
              }}
            />
          }
          onClose={() => setIsOpen(false)}
          renderValue={(selected) => {
            if (!selected) {
              return (
                <em className={S["access-dropdown-button__select-placeholder"]}>
                  {"Select option"}
                </em>
              );
            }

            return (
              <span className={S["access-dropdown-button__selected"]}>
                <HalfLockIcon w={12} />
                <span style={{ paddingLeft: "5px" }}>
                  {selectedOptionData?.title}
                </span>
              </span>
            );
          }}
          MenuProps={menuProps}
        >
          {OPTIONS.map((option) => (
            <MenuItem
              disableRipple
              className={S["access-dropdown-button__menu-item"]}
              key={option.id}
              value={option.value}
              sx={{
                //   ":hover": {
                //     backgroundColor: "transparent",
                //   },
                "&.Mui-selected": {
                  backgroundColor: "transparent",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                },
              }}
            >
              <Stack
                direction="column"
                gap={"5px"}
                sx={{ padding: "0px 20px 0px 10px" }}
              >
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography className={S["access-dropdown-button__title"]}>
                    {option.title}
                  </Typography>
                  <CheckboxMedium
                    boxSize={15}
                    checked={selectedOption === option.value}
                  />
                </Stack>
                <Typography className={S["access-dropdown-button__subtitle"]}>
                  {option.subtitle}
                </Typography>
              </Stack>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
);

export default AccessDropDownButton;
