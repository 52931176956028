import { CellValueChangedEvent, ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useRef } from "react";
import "./DataTable.css";

interface DataTableProps<T> {
  columnDefs: ColDef<T>[];
  rowData: T[];
  onCellValueChanged: (event: CellValueChangedEvent<T>) => void;
}

const DataTable = <T,>({
  columnDefs,
  rowData,
  onCellValueChanged,
}: DataTableProps<T>) => {
  const gridRef = useRef<AgGridReact<T>>(null);

  const onRowSelected = () => {
    const selectedNodes = gridRef.current?.api.getSelectedNodes();
    const selectedData = selectedNodes?.map(node => node.data);
    console.log("Selected rows:", selectedData);
  };

  return (
    <div
      className="ag-theme-alpine table-container"
      style={{
        width: "100%",
        maxHeight: 575,
        height: 575,
        overflow: "scroll", // Enable scrolling
        scrollbarWidth: "none", // Hide scrollbar for Firefox
      }}
    >
      <style>
        {`
          .table-container::-webkit-scrollbar {
            display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
          }
          .table-container {
            -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
          }
        `}
      </style>
      <AgGridReact<T>
        ref={gridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        onCellValueChanged={onCellValueChanged}
        rowSelection="multiple"
        onRowSelected={onRowSelected}
        suppressRowClickSelection={true} // Only select row when checkbox is clicked
        suppressMenuHide={true}
        animateRows={true}
        domLayout="normal"
        alwaysShowVerticalScroll={true}
        suppressHorizontalScroll={true}
        suppressMovableColumns={true}
      />
    </div>
  );
};

export default DataTable;
