import { Stack, Popover } from "@mui/material";
import React, { useState } from "react";
import { DottedButton } from "../DottedButton";
import { DateIcon } from "../../../images";
import { DragAndDropMenu, DragAndDropMenuListType } from "../DragAndDropMenu";
import { connectEventOptions } from "./ConnectEventDropdownType";

interface ConnectEventDropdownProps {
  options?: DragAndDropMenuListType[];
}

const ConnectEventDropdown: React.FC<ConnectEventDropdownProps> = ({
  options = connectEventOptions,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOptions, setMenuOptions] =
    useState<DragAndDropMenuListType[]>(options);

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "popover" : undefined;
  return (
    <Stack>
      <DottedButton
        icon={DateIcon}
        title="Connect Event"
        aria-describedby={id}
        onClick={handleButtonClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            padding: 0,
            width: "250px",
            boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.16)",
            marginTop: "10px",
          },
        }}
      >
        <DragAndDropMenu
          menuItems={menuOptions}
          setMenuItems={(newValue) => setMenuOptions(newValue)}
          dragIconPlace="left"
          label="Connect Events"
          multiple
          onMenuSelect={(value) => console.log(value)}
        />
      </Popover>
    </Stack>
  );
};

export default ConnectEventDropdown;
