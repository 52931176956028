import { InputBase, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ConnectedIcon } from "../../../../../../../images";
import { adjustHexColor, getUser } from "../../../../../../../Utils";

interface SlugViewProps {
  slugInitial?: any;
}

const SlugView: React.FC<SlugViewProps> = ({ slugInitial }) => {
  const [slugInitialData, setSlugInitialData] = useState(slugInitial);
  useEffect(() => {
    if (slugInitial) {
      setSlugInitialData(slugInitial);
    }
  }, [slugInitial]);

  const user = getUser();

  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        gap: "25px",
        font: "normal normal 300 12px/15px Source Serif Pro",
      }}
    >
      <ConnectedIcon w={20} />
      <Typography
        sx={{
          font: "inherit",
          color: adjustHexColor("#000", 50),
          maxWidth: "190px",
          overflow: "scroll",
          whiteSpace: "nowrap",
          scrollbarWidth: "none",
        }}
      >
        {`os.bearishos.com/${
          user?.data?.firstName + " " + user?.data?.lastName
        }/`}
      </Typography>

      <InputBase
        placeholder="slug"
        readOnly
        value={slugInitialData} // Use slugInitialData as the value
        onChange={(e) => setSlugInitialData(e.target.value)} // Handle changes to update the state
        sx={{
          height: "30px",
          width: "110px",
          padding: "0px 5px",
          borderRadius: "5px",
          border: "1px solid #E9EDF2",
          font: "inherit",
          color: adjustHexColor("#000", 100),
          "& input": {
            "&::placeholder": {
              opacity: 0.5,
            },
          },
        }}
      />
    </Stack>
  );
};

export default SlugView;
