import { CertificatesIcon, DescriptionIcon, LicenseIcon, RoleIcon, SkillsIcon, TranslateIcon, TypeIcon } from "../../../../../images";

export type CredentialsListType =
    'Degree' | 'Past Experience' | 'Certificate' | 'Language' | 'License' | 'Skills' | 'Other'


export interface AddCredentialsPopupListType {
    id: number;
    icon: React.ElementType;
    value: CredentialsListType
}

export const AddCredentialsPopupOptList: AddCredentialsPopupListType[] = [
    { id: 1, icon: DescriptionIcon, value: 'Degree' },
    { id: 2, icon: RoleIcon, value: 'Past Experience' },
    { id: 3, icon: CertificatesIcon, value: 'Certificate' },
    { id: 4, icon: TranslateIcon, value: 'Language' },
    { id: 5, icon: LicenseIcon, value: 'License' },
    { id: 6, icon: SkillsIcon, value: 'Skills' },
    { id: 7, icon: TypeIcon, value: 'Other' },
]