import { Stack, Tooltip, Typography } from "@mui/material";
import React from "react";

interface BreaksViewProps {
  breakCount: number | string;
}

const BreaksView: React.FC<BreaksViewProps> = ({ breakCount }) => {
  return (
    <Stack
      sx={{
        width: "40px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Tooltip title={breakCount} placement="top" arrow disableInteractive>
        <Typography
          sx={{ font: "normal normal 400 14px/18px Source Serif Pro" }}
        >
          {breakCount}
        </Typography>
      </Tooltip>
    </Stack>
  );
};

export default BreaksView;
