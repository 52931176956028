import { Stack, Typography } from "@mui/material";
import React from "react";
import { TextLinkButton } from "../../../../common";
import { DepartmentIcon } from "../../../../../images";
import { UserInfoChip } from "../../../Email/components";

interface CompanyHierarchyContainerProps {}

const CompanyHierarchyContainer: React.FC<
  CompanyHierarchyContainerProps
> = ({}) => {
  return (
    <Stack sx={{ gap: "13px" }}>
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
        <Typography
          sx={{ font: "normal normal 400 15px/19px Source Serif Pro" }}
        >
          Company Hierarchy
        </Typography>

        <TextLinkButton buttonLabel="Update" />
      </Stack>

      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "15px 25px",
          flexWrap: "wrap",
        }}
      >
        {/* -------  Manager ------ */}
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "25px" }}>
          <Stack
            sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}
          >
            <DepartmentIcon w={15} />
            <Typography
              sx={{ font: "normal normal 400 14px/18px Source Serif Pro" }}
            >
              Manager
            </Typography>
          </Stack>

          <UserInfoChip
            popoverPlace="top"
            chipHeight="30px"
            userEmail="userEmail here"
            userName="userName smndjash ksjkh skjdh aksj here"
            closeButtonClick={() => null}
          />
        </Stack>

        {/* -------  Team Lead ------ */}
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "25px" }}>
          <Stack
            sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}
          >
            <DepartmentIcon w={15} />
            <Typography
              sx={{ font: "normal normal 400 14px/18px Source Serif Pro" }}
            >
              Team Lead
            </Typography>
          </Stack>

          <UserInfoChip
            popoverPlace="top"
            chipHeight="30px"
            userEmail="userEmail here"
            userName="userNdsdsdsdsame here"
            closeButtonClick={() => null}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CompanyHierarchyContainer;
