import { IconButton, Stack, Tooltip, Typography, Popover } from "@mui/material";
import React, { useState } from "react";
import { AvatarNormal } from "../../../../common";
import { UserCount } from "../EmailCardComponents";
import { InfoIconBlack, TranslateIcon } from "../../../../../images";
import { TranslatePopup } from "../TranslatePopup";

interface EmailPreviewAreaProps {
  infoButton?: any;
  emailData?: any;
}
interface Recipient {
  emailAddress?: { name: string; address: string };
}

const EmailPreviewArea: React.FC<EmailPreviewAreaProps> = ({
  infoButton,
  emailData,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const id = open ? "translate-popover" : undefined;

  const handleTranslateClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack sx={{ flexGrow: 1, height: "100%", gap: "20px" }}>
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "start",
          justifyContent: "space-between",
        }}
      >
        <Stack sx={{ gap: "10px" }}>
          <Stack
            sx={{ flexDirection: "row", gap: "10px", alignItems: "center" }}
          >
            <AvatarNormal
              size={30}
              imgSrc={emailData?.sender?.emailAddress?.logo}
              username={emailData?.sender?.emailAddress?.name}
            />
            <Typography
              sx={{ font: "normal normal 900 13px/16px Source Serif Pro" }}
            >
              {emailData?.sender?.emailAddress?.name}
            </Typography>
            <Typography
              sx={{ font: "normal normal 300 11px/14px Source Serif Pro" }}
            >
              {emailData?.sender?.emailAddress?.address}
            </Typography>
          </Stack>
          <Stack
            sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}
          >
            <Typography
              sx={{ font: "normal normal 400 11px/14px Source Serif Pro" }}
            >
              to:
            </Typography>
            <Typography
              sx={{ font: "normal normal 300 11px/14px Source Serif Pro" }}
              
            >
              {emailData?.toRecipients?.map((rcpt: Recipient, index: any) => {
                const rcptName = rcpt?.emailAddress?.name;
                return <span key={index}>{rcptName + ", "}</span>;
              })}
            </Typography>
            <UserCount userList={emailData?.toRecipients} />
          </Stack>
        </Stack>

        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "20px" }}>
          <Tooltip title="Details" arrow placement="top">
            <IconButton
              onClick={() => infoButton()}
              sx={{ p: "2.5px" }}
              disableRipple
            >
              <InfoIconBlack w={15} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Translate" arrow placement="top">
            <IconButton
              sx={{ p: "2.5px" }}
              disableRipple
              onClick={handleTranslateClick}
            >
              <TranslateIcon w={15} />
            </IconButton>
          </Tooltip>

          {/* Popover for Translate Button */}
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              style: {
                marginTop: "12px",
                boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
              },
            }}
          >
            <TranslatePopup />
          </Popover>
        </Stack>
      </Stack>

      {/* ---------------------------------------------------------------------- */}

      {/* -- email preview area ---- */}

      <Stack sx={{ display: "flex", justifyContent: "center" }}>
        <Stack
          sx={{
            height: "100vh",
            overflow: "scroll",
            scrollbarWidth: "none",
            bgcolor: "#E9EDF2",
            borderRadius: "5px",
          }}
        >
          <iframe
            srcDoc={emailData?.body?.content}
            style={{ width: "100%", height: "100%", border: "none" }}
            title="Email Preview"
          />

          {/* <div >
          {emailData?.body?.content}
        </div> */}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default EmailPreviewArea;
