import { Stack, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../../../Utils";
import { MediumButtonSmall } from "../../../MediumButtonSmall";
import { ButtonType } from "../../../../../../common";

interface CoverViewProps {}

const CoverView: React.FC<CoverViewProps> = ({}) => {
  return (
    <Stack
      sx={{
        height: "100%",
        width: "100%",
        zIndex: 15000,
        bgcolor: adjustHexColor("#E9EDF2", 90),
        position: "absolute",
        boxSizing: "border-box",
        padding: "23px 50px 0px 50px",
        gap: "20px",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          font: "normal normal 900 18px/23px Source Serif Pro",
          textAlign: "center",
          marginBottom: "22px",
        }}
      >
        Get access to in meeting notes, a supercharged workspace, and all your
        apps all in one place with a free Bearish OS account!
      </Typography>

      <MediumButtonSmall
        label="Create account now"
        types={ButtonType.Button}
        sx={{
          width: "150px",
          font: "normal normal 600 13px/16px Source Serif Pro",
        }}
      />

      <Typography
        sx={{
          font: "normal italic 200 18px/23px Source Serif Pro",
          textAlign: "center",
          padding: "0px 25px",
        }}
      >
        The name and email you entered this call with will be used to create
        your account, and you can update everything else after the call.
      </Typography>
    </Stack>
  );
};

export default CoverView;
