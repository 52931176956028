import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ButtonSmall } from "../Buttons";
import { ButtonType, SmallButtonColor } from "../Buttons/AllButtonProps";
import { CheckboxMedium } from "../CheckboxMedium";
import styles from "./AddWatermark.module.css";

interface AddWatermarkProps {
  initialWatermarkState: boolean; // Initial state for the watermark
  onSave: (enableWatermark: boolean) => void; // Callback to return the updated state
}

const AddWatermark: React.FC<AddWatermarkProps> = ({
  initialWatermarkState,
  onSave,
}) => {
  const [enableWatermark, setEnableWatermark] = useState(initialWatermarkState); // State for enabling watermark

  // Update the state when the initial value changes
  useEffect(() => {
    setEnableWatermark(initialWatermarkState);
  }, [initialWatermarkState]);

  // Handle checkbox change
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnableWatermark(e.target.checked); // Update the state based on checkbox value
  };

  // Handle save button click
  const handleSave = () => {
    onSave(enableWatermark); // Call onSave with the current state
  };

  return (
    <Stack className={styles["addWatermark"]}>
      {/* --- header of card [ card title] ------ */}
      <Stack className={styles["addWatermark__header"]}>
        <Typography className={styles["addWatermark__title"]}>
          Add a watermark
        </Typography>
      </Stack>
      {/* ---------------------------------------- */}

      {/* -----------   body of card   ------------- */}
      <Stack className={styles["addWatermark__body"]}>
        <Typography className={styles["addWatermark__description"]}>
          This watermark will be visible on every page of the item every time it
          is downloaded. This watermark will display a transparent layer with
          the name, email, and time downloaded by the user.
        </Typography>

        <Stack className={styles["addWatermark__checkboxContainer"]}>
          <CheckboxMedium
            boxSize={15}
            checked={enableWatermark} // Bind checkbox to state
            onChange={handleCheckboxChange} // Handle checkbox change
          />
          <Typography className={styles["addWatermark__checkboxLabel"]}>
            Enable watermarking
          </Typography>
        </Stack>
      </Stack>
      {/* ------------------------------------------ */}

      {/* ---- buttons here ---- */}
      <Stack className={styles["addWatermark__buttonContainer"]}>
        <ButtonSmall
          label="Cancel"
          types={ButtonType.Button}
          colorVarient={SmallButtonColor.Transparent}
        />
        <ButtonSmall
          label="Save"
          types={ButtonType.Button}
          colorVarient={SmallButtonColor.Orange}
          onClick={handleSave} // Handle Save click
        />
      </Stack>
      {/* ---------------------------- */}
    </Stack>
  );
};

export default AddWatermark;
