import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { GreenDropdownIcon, PreviewIcon } from "../../../../../../../images";

interface HeaderViewProps {
  onPreviewButtonClick: () => void;
  viewFullDetailsBtnClick: () => void;
}

const HeaderView: React.FC<HeaderViewProps> = ({
  onPreviewButtonClick,
  viewFullDetailsBtnClick,
}) => {
  return (
    <Stack
      sx={{
        flexShrink: 0,
        width: "100%",
        height: "80px",
        borderBottom: "1px solid #E9EDF2",
        flexDirection: "row",
        alignItems: "center",
        boxSizing: "border-box",
        padding: "0px 15px",
        justifyContent: "space-between",
      }}
    >
      <Button
        onClick={onPreviewButtonClick}
        variant="text"
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "13px",
          minWidth: 0,
          height: "30px",
          padding: "0px 4px",
          boxSizing: "border-box",
          color: "black",
          textTransform: "none",
        }}
      >
        <PreviewIcon w={25} />
        <Typography
          sx={{
            font: "normal normal 600 18px/23px Source Serif Pro",
            color: "black",
          }}
        >
          Preview
        </Typography>
      </Button>

      <Button
        onClick={viewFullDetailsBtnClick}
        variant="text"
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "5px",
          minWidth: 0,
          height: "30px",
          padding: "0px 10px",
          boxSizing: "border-box",
          color: "black",
          textTransform: "none",
          border: "1px solid #E9EDF2",
          boxShadow: "0px 6px 3px 0px rgba(0, 118, 123, 0.16)",
          ":hover": {
            boxShadow: "0px 6px 3px 0px rgba(0, 118, 123, 0.16)",
          },
        }}
      >
        <Typography
          sx={{
            font: "normal normal 400 15px/19px Source Serif Pro",
            color: "black",
          }}
        >
          View full details
        </Typography>

        <GreenDropdownIcon
          sx={{ height: "12px", transform: "rotate(0deg)" }}
          open
        />
      </Button>
    </Stack>
  );
};

export default HeaderView;
