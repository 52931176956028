import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { AllDirArrowCircleIcon } from "../../../../../../images";
import { useNavigate } from "react-router-dom";

interface TraxRouteProps {
  employeeId: string;
}

const TraxRoute: React.FC<TraxRouteProps> = ({ employeeId }) => {
  const navigate = useNavigate();

  const backToEmp = () => {
    navigate(`/Trax/employee-directory`);
  };
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        gap: "5px",
        width: "fit-content",
        cursor: "pointer",
      }}
      onClick={() => backToEmp()}
    >
      <AllDirArrowCircleIcon w={15} direction="left" />
      <Typography sx={{ font: "normal normal 300 12px/14px Source Serif Pro" }}>
        Back to all employees
      </Typography>
    </Stack>
  );
};

export default TraxRoute;
