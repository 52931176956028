import {
  bearishOSOctaIcon,
  CalendarIconBearishOSIconPng,
  GoogleCalendarLogoPng,
  JiraLogoPng,
  MicrosoftTeamsLogoPng,
  OutlookCalendarLogoPng,
} from "../images";

export enum CalendarTypesEnum {
  BearishCalendar = "bearishCalendar",
  OutlookCalendar = "outlookCalendar",
  GoogleCalendar = "googleCalendar",
  TeamsCalendar = "teamsCalendar",
  JiraCalendar = "jiraCalendar",
}

export type WeekDaysList =
  | "sunday"
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday";

export enum WeekDaysEnum {
  Sunday = "sunday",
  Monday = "monday",
  Tuesday = "tuesday",
  Wednesday = "wednesday",
  Thursday = "thursday",
  Friday = "friday",
  Saturday = "saturday",
}

export const getCalenderLogo = ({
  calendarType,
}: {
  calendarType: CalendarTypesEnum;
}): string => {
  if (!calendarType) {
    return CalendarIconBearishOSIconPng;
  }

  switch (calendarType) {
    case CalendarTypesEnum.BearishCalendar:
      return CalendarIconBearishOSIconPng;

    case CalendarTypesEnum.GoogleCalendar:
      return GoogleCalendarLogoPng;

    case CalendarTypesEnum.JiraCalendar:
      return JiraLogoPng;

    case CalendarTypesEnum.OutlookCalendar:
      return OutlookCalendarLogoPng;

    case CalendarTypesEnum.TeamsCalendar:
      return MicrosoftTeamsLogoPng;

    default:
      return CalendarIconBearishOSIconPng;
  }
};
