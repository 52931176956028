import { Link, Stack } from "@mui/material";
import React from "react";

interface SocialMediaLinkButtonProps {
  icon: React.ElementType;
  link: string;
}

const SocialMediaLinkButton: React.FC<SocialMediaLinkButtonProps> = ({
  icon,
  link,
}) => {
  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
      <span style={{ flexShrink: 0 }}>
        {React.createElement(icon, { w: 20, color: "black" })}
      </span>
      <Link
        underline="hover"
        sx={{
          cursor: "pointer",
          color: "#0027FF",
          font: "normal normal 400 14px/18px Source Serif Pro",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {link}
      </Link>
    </Stack>
  );
};

export default SocialMediaLinkButton;
