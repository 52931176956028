import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { TravelTimeMenu } from "../../../TravelTimeMenu";
import { StyledRadioButtonBlack } from "../../../../../../common";
import { DeadlineIcon } from "../../../../../../../images";
import { useTranslation } from "react-i18next";

interface BufferTimeViewProps {
  bookLinkDataForHostBuffer?: any;
}

const BufferTimeView: React.FC<BufferTimeViewProps> = ({
  bookLinkDataForHostBuffer,
}) => {
  const { t } = useTranslation();
  const [beforeRadio, setBeforeRadio] = useState<boolean>(
    bookLinkDataForHostBuffer?.direction === "before" ||
      bookLinkDataForHostBuffer?.direction === "both"
  );
  const [afterRadio, setAfterRadio] = useState<boolean>(
    bookLinkDataForHostBuffer?.direction === "after" ||
      bookLinkDataForHostBuffer?.direction === "both"
  );
  return (
    <Stack sx={{ flexDirection: "row", gap: "15px", alignItems: "center" }}>
      <span style={{ marginRight: "10px" }}>
        <DeadlineIcon w={20} />
      </span>

      <TravelTimeMenu
        options="TravelTimeMenuOptionsList120"
        onSelect={(value) => null}
        initialValue={bookLinkDataForHostBuffer?.time}
      />

      <Stack
        onClick={() => setBeforeRadio((pre) => !pre)}
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "5px",
          cursor: "pointer",
        }}
      >
        <StyledRadioButtonBlack radioSize={15} checked={beforeRadio} />
        <Typography
          sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
        >
          {t("CALENDAR.BEFORE")}
        </Typography>
      </Stack>
      <Stack
        onClick={() => setAfterRadio((pre) => !pre)}
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "5px",
          cursor: "pointer",
        }}
      >
        <StyledRadioButtonBlack radioSize={15} checked={afterRadio} />
        <Typography
          sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
        >
          {t("CALENDAR.AFTER")}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default BufferTimeView;
