import { Box, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import {
  adjustHexColor,
  CalendarTypesEnum,
  getCalenderLogo,
} from "../../../../../Utils";
import { UserDetailsView } from "./view";
import { CreatedIcon } from "../../../../../images";

interface NamePlackCardProps {
  vister: any;
}
const formatVisitTime = (visitTime: string) => {
  if (!visitTime) {
    return;
  }
  const date = new Date(visitTime);

  const day = String(date?.getUTCDate())?.padStart(2, "0"); // Get day and pad with zero
  const month = String(date?.getUTCMonth() + 1)?.padStart(2, "0"); // Get month (0-indexed) and pad
  const hours = date?.getUTCHours();
  const minutes = String(date?.getUTCMinutes())?.padStart(2, "0"); // Get minutes and pad with zero

  const ampm = hours >= 12 ? "PM" : "AM"; // Determine AM/PM
  const formattedHours = String(hours % 12 || 12)?.padStart(2, "0"); // Format hours to 12-hour format

  return `${month}/${day} @ ${formattedHours}:${minutes} ${ampm}`; // Return formatted string
};

const NamePlackCard: React.FC<NamePlackCardProps> = ({ vister }) => {
  const visitTimeFormatted = formatVisitTime(vister?.visitTime); // Format visit time
  return (
    <Stack
      sx={{
        borderRadius: "5px",
        paddingLeft: "8px",
        bgcolor: adjustHexColor("#21B481", 25),
        width: "100%",
      }}
    >
      <Stack
        sx={{
          height: "45px",
          flexShrink: 0,
          // width: "500px",
          borderRadius: "5px",
          bgcolor: "white",
          padding: "0px 10px",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          boxSizing: "border-box",
        }}
      >
        <UserDetailsView vUser={vister?.userDetails} />
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
          <Typography
            sx={{
              font: "normal normal 300 13px/16px Source Serif Pro",
            }}
          >
            {visitTimeFormatted} {/* Display the formatted visit time */}
          </Typography>

          <Box
            component={"img"}
            src={getCalenderLogo({
              calendarType: CalendarTypesEnum.JiraCalendar,
            })}
            alt="calendar logo"
            sx={{ height: "20px", width: "20px" }}
          />
          <IconButton sx={{ p: 0 }} disableRipple>
            <CreatedIcon w={20} />
          </IconButton>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default NamePlackCard;
