import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import styles from "./EventControlCards.module.css";
import { AvatarNormal } from "../../../../common";
import { StreamIconBearishOSIconPng } from "../../../../../images";
import { useTranslation } from "react-i18next";
import {
  ButtonGroupOneView,
  ButtonGroupThreeView,
  ButtonGroupTwoView,
} from "./view";

type buttonsGroup = "group1" | "group2" | "group3";

interface EventControlCardsProps {
  tag: "pendingEvent" | "call" | "postcall";
}

const EventControlCards: React.FC<EventControlCardsProps> = ({ tag }) => {
  const { t } = useTranslation();

  const getTagLabel = (): {
    tagColor: string;
    translatedWord: string;
    buttonGroup: buttonsGroup;
  } => {
    switch (tag) {
      case "call":
        return {
          tagColor: "#FF8181",
          translatedWord: "CALL_DETAILS.CALL",
          buttonGroup: "group1",
        };
      case "pendingEvent":
        return {
          tagColor: "#B5005B",
          translatedWord: "CALL_DETAILS.PENDING_EVENT",
          buttonGroup: "group2",
        };
      case "postcall":
        return {
          tagColor: "#FF8181",
          translatedWord: "CALL_DETAILS.POST_CALL",
          buttonGroup: "group3",
        };
    }
  };

  const getButton = (buttonGroup: buttonsGroup) => {
    switch (buttonGroup) {
      case "group1":
        return <ButtonGroupOneView />;
      case "group2":
        return <ButtonGroupTwoView />;
      case "group3":
        return <ButtonGroupThreeView />;
    }
  };

  return (
    <Stack className={styles.eventControlCards}>
      {/* User icon with online/offline-status and name here */}
      <Stack className={styles.eventControlCards__user}>
        <AvatarNormal size={30} username="A B" />

        <Typography className={styles.eventControlCards__username}>
          name here
        </Typography>
      </Stack>

      {/* Heighlighter[@tags] and message text here */}
      <Stack className={styles.eventControlCards__content}>
        <Stack className={styles.eventControlCards__contentRow}>
          <Typography
            className={styles.eventControlCards__tagText}
            sx={{ color: getTagLabel().tagColor, width: "120px" }}
          >
            @{t(getTagLabel().translatedWord)}
          </Typography>
          <Stack
            sx={{
              flexGrow: 1,
              width: "calc(100% - 80px)",
            }}
          >
            <Typography className={styles.eventControlCards__message}>
              Example text and messages will appear here for you to see with
              ease Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Unde, aut?
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      {/* Buttons and company icons */}
      <Stack className={styles.eventControlCards__actions}>
        <Box
          className={styles.eventControlCards__icon}
          component={"img"}
          alt="logo"
          src={StreamIconBearishOSIconPng}
        />
        {getButton(getTagLabel().buttonGroup)}
      </Stack>
    </Stack>
  );
};

export default EventControlCards;
