import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AuthState} from '../../../../../redux';
import {useParams} from 'react-router-dom';
import {RootState} from '../../../../../redux/store';
import {MeetingContext} from '../../../../Povider';
import {VideoCenterState} from '../../../../../redux/Slice/videoCenter/VideoCenterTypes';
import {FormControl, InputLabel, Stack, Typography,TextField} from "@mui/material";
import VideoTiles from "../../VideoTiles";
import {MicButton, VideoButton} from "../index";
import ButtonSmall from "../../../../common/Buttons/ButtonSmall/ButtonSmall";
import {ButtonType, SmallButtonColor} from "../../../../common";
import {UserInfoChip} from "../../../Email/components";
import styles from "./WaitingRoom.module.css";
import {alpha, styled} from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import {createGuestUsers} from "../../../../../redux/Slice/auth/authActions";
import { useTranslation } from "react-i18next";

// @ts-ignore
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {JoinRequestCard} from "../JoinRequestCard";
import InputAreaWithLabel from "../InputAreaWithLabel/InputAreaWithLabel";
import ChipWithAvatar from "../../../../common/ChipWithAvatar/ChipWithAvatar";


const WaitingRoom: React.FC = () => {
    const params = useParams();
    const {user} = useSelector((state: RootState) => state.auth as AuthState);
    const [userDetails,setUserDetails]= useState({
        firstName:'',
        email:'',
        lastName:'',
    })

    const [isDisabled, setIsDisabled] = useState(false);
    const {t}=useTranslation()
    const dispatch=useDispatch()
    const meetingContext = useContext(MeetingContext);
    const videoTiles = useMemo(() => {
        const sorted = meetingContext?.VideoPreferences.sortVideoTiles(
            //@ts-ignore
            Object.values(meetingContext?.realtimeAttendees)
        );
        return sorted;
    }, [meetingContext?.VideoPreferences]);

    const { onGoingMeeting } = useSelector(
      (state: RootState) => state.videoCenter as VideoCenterState
    );
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUserDetails({ ...userDetails, [name]: value });
    };


    const requestToJoin = async () => {
        try {
            // Check if user is authenticated
            if (user) {
                if (params.id && user?.data?._id && onGoingMeeting?.meeting?.chimeMeetingId) {
                    setIsDisabled(true);
                    await meetingContext?.requestToJoinMeetingHandler({
                        meetingId: params.id,
                        attendeeId: user?.data?._id,
                        chimeMeetingId: onGoingMeeting?.meeting?.chimeMeetingId,
                        meeting_id: onGoingMeeting?.meeting?._id,
                    });
                }
            } else {
                // For guest users
                const payload = {
                    firstName: userDetails?.firstName,
                    lastName: userDetails?.lastName,
                    email: userDetails?.email,
                };

                // Dispatch action to create guest user
                const response:any = await dispatch(createGuestUsers(payload));
                // Assuming the response contains the newly created guest user's ID
                const guestUserId = response?.auth?.user?.data?._id;

                if (params.id && guestUserId && onGoingMeeting?.meeting?.chimeMeetingId) {
                    setIsDisabled(true);
                    await meetingContext?.requestToJoinMeetingHandler({
                        meetingId: params.id,
                        attendeeId: guestUserId,
                        chimeMeetingId: onGoingMeeting?.meeting?.chimeMeetingId,
                        meeting_id: onGoingMeeting?.meeting?._id,
                    });
                }
            }
        } catch (error) {
            console.error('Error while joining the meeting:', error);
            // Optionally handle errors, e.g., show an error message
        }
    };


    useEffect(() => {
        setUserDetails({
            firstName: user?.data?.firstName || '',
            lastName: user?.data?.lastName || '',
            email: user?.data?.email || ''
        });
    }, [user]);

    useEffect(() => {
        if(onGoingMeeting?.ClintNotification && onGoingMeeting?.meeting?.meetingHostId !== user?.data?._id){
            NotificationManager.info(
                <JoinRequestCard
                    cardType={onGoingMeeting?.ClintNotification?.status}
                    userName={onGoingMeeting?.notificationData?.userDetails?.userName || 'Guest'}
                />,
                'Join Request',
                0, // Duration set to 0 to keep the notification open indefinitely
            );
        }
    }, [onGoingMeeting?.ClintNotification]);
    // @ts-ignore
    // @ts-ignore
    return (
        <div className={styles.mainContainer}>
            <div className={styles.videoDiv}>
                <div className={styles.videoControl}><VideoTiles {...videoTiles?.[0]} aspectRatio={"750/350"} showUserAvtar={false}/></div>
                <Stack sx={{gap: 2}}>
                    <Stack sx={{flexDirection: "row", gap: 2, justifyContent: "space-around"}}>
                        <Stack sx={{flexDirection: "row", gap: 3}}>
                            <MicButton
                                initialStatus={true}
                                onClickChange={() => console.log("mic")}
                            />
                            <VideoButton
                                initialStatus={true}
                                onClickChange={() => console.log("Video")}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            </div>
            <div className={styles.formDiv}>
                <div className={styles.hedding}>
                    <Typography style={{font: "normal normal 900 25px Source Serif Pro"}}>{t('VIDEO_CENTER.MEETING.WELCOME_TO_CALL')}</Typography>
                    <Typography style={{font: "normal normal 300 18px Source Serif Pro"}}>{t('VIDEO_CENTER.MEETING.WHO_IS_IN_CALL')}</Typography>
                    <div className={styles.userDiv}>
                        {onGoingMeeting?.realtimeAttendees?.map((user:any, i:any) => (
                            <ChipWithAvatar
                                key={i}
                                userName={user.firstName || ''}
                                userImgSrc={`https://example.com/avatar${i + 1}.png`}
                                userAvatarClr={`#${user.avatar}`}
                            />
                        ))}
                    </div>

                </div>
                <div className={styles.hedding}>
                    <Typography style={{font: "normal italic 300 18px Source Serif Pro"}}>{t('VIDEO_CENTER.MEETING.JOIN_CALL_INSTRUCTIONS')}</Typography>
                    <div className={styles.formControl}>
                        <FormControl variant="standard">
                            <InputAreaWithLabel
                                id="firstName-input"
                                inputLabel={t('VIDEO_CENTER.MEETING.FIRST_NAME')}
                                value={userDetails.firstName}
                                onChange={handleInputChange}
                                disabled={user ? true : false}
                            />
                        </FormControl>
                        <FormControl variant="standard">
                            <InputAreaWithLabel
                                id="lastName-input"
                                inputLabel={t('VIDEO_CENTER.MEETING.LAST_NAME')}
                                value={userDetails.lastName}
                                onChange={handleInputChange}
                                disabled={user ? true : false}
                            />
                        </FormControl>
                        <FormControl variant="standard">
                            <InputAreaWithLabel
                                inputWidth={'200px'}
                                id="email-input"
                                inputLabel={t('VIDEO_CENTER.MEETING.EMAIL')}
                                value={userDetails.email}
                                onChange={handleInputChange}
                                disabled={user ? true : false}
                            />
                        </FormControl>
                    </div>
                </div>
                <div className={styles.hedding}>
                    {/*<ButtonSmall*/}
                    {/*    className={styles.buttonControl}*/}
                    {/*    label={'Ask to join'}*/}
                    {/*    types={ButtonType.Submit}*/}
                    {/*    colorVarient={SmallButtonColor?.CallsSalmon}/>*/}
                    <ButtonSmall
                        className={styles.buttonControl}
                        //@ts-ignore
                        disabled={!userDetails?.email || !userDetails?.firstName || !userDetails?.lastName || isDisabled}
                        onClick={requestToJoin}
                        label={t('VIDEO_CENTER.MEETING.ASK_TO_JOIN')}
                        types={ButtonType.Submit}
                        colorVarient={SmallButtonColor?.CallsSalmon}/>
                </div>
                <NotificationContainer/>
            </div>
            <div className={styles.triangledown}></div>
        </div>
    );
};

export default WaitingRoom;