import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { slackRedirect } from "../../../../redux/Slice/message/messageActions";
import { useDispatch } from "react-redux";

const RedirectAuth = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      const code = searchParams.get("code");
      if (code) {
        const response:any = await dispatch(slackRedirect(code));
        if (response?.success) {
          navigate("/Message")
        }
      }

    };
    fetchData();
  }, [searchParams]);

  return (
    <Box
      id="scrollableDiv"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f0f4c3",
      }}
    >
      <Typography variant="h4" color="success.main">
        Redirect Successful!
      </Typography>
    </Box>
  );
};

export default RedirectAuth;
