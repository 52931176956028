import React, { useState } from "react";
import { Stack, TextField,  } from "@mui/material";

interface FormulaViewProps {
  initialValue?: object;
  onChange?: (formula: object) => void;
}

const FormulaView: React.FC<FormulaViewProps> = ({
  initialValue = {},
  onChange,
}) => {
  const [formula, setFormula] = useState<string>(
    JSON.stringify(initialValue, null, 2)
  ); // Initialize with a stringified version of the object
  const [isValid, setIsValid] = useState<boolean>(true);

  const handleFormulaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFormula(value);

    try {
      const parsedFormula = JSON.parse(value); // Validate by parsing the input as JSON
      setIsValid(true);

      if (onChange) {
        onChange(parsedFormula); // Pass the valid formula object back to the parent component
      }
    } catch (error) {
      setIsValid(false); // Invalid JSON
    }
  };

  return (
    <Stack>
      <TextField
        label=""
        value={formula}
        onChange={handleFormulaChange}
        error={!isValid}
        helperText={!isValid ? "Please enter a valid JSON object." : ""}
        placeholder='{"key": "value"}'
        multiline
        fullWidth
        minRows={3}      variant="outlined" // Use outlined variant for better control over styles
        sx={{
          "& .MuiOutlinedInput-root": {
            border: "none", // Remove border
            "& fieldset": {
              border: "none", // Remove fieldset border
            },
            "&:hover fieldset": {
              border: "none", // Remove border on hover
            },
            "&.Mui-focused fieldset": {
              border: "none", // Remove border on focus
              outline: "none", // Remove outline on focus
            },
          },
          padding: 0,
          font: "normal normal 300 13px/17px Source Serif Pro",
        }}
      />
    </Stack>
  );
};

export default FormulaView;
