import {
  BookingEventPublicPage,
  Calendar,
  Calls,
  CloudStorage,
  Crm,
  Dashboard,
  Email,
  FirstLogin,
  ForgotPassword,
  InsideFile_CloudStorage,
  InsideWorkspace,
  InsideWorkspaceItem,
  Invitations,
  Login,
  Meeting,
  Message,
  OverView,
  Project,
  Settings,
  Signup,
  Trax,
  TraxOverview,
  Workspace,
} from "../components/pages";
import { CallDetails } from "../components/pages/Calls/callDetails";
import { CreateConnection } from "../components/pages/Message/createConnection";
import { MessageChats } from "../components/pages/Message/messageChats";
import {
  HomeIcon,
  VideoIcon,
  MailIcon,
  PlanIcon,
  TaskIcon,
  StreamIcon,
  CRMIcon,
  TraxIcon,
} from "./RouteIcon";
import { RouteConfig } from "./RouteTypes";
import { MeetingHeader } from "../components/pages/Calls/components/MeetingHeader";
import { PastCallDetails } from "../components/pages/Calls/PastcallDetails";
import { RedirectAuth } from "../components/pages/Message/redirectAuth";
import CallBack from "../components/pages/CallBack/CallBack";
import { teamsRedirectAuth } from "../components/pages/Message/teamsRedirectAuth";

export const ROUTES: RouteConfig[] = [
  {
    path: "/login",
    component: Login,
    isPrivate: false,
    useCommonLayout: false,
    name: "Login",
    bothActive: false,
  },

  /**
   *
   * after login, one route page will be served by default
   *
   */

  {
    path: "/profile-Mapping",
    component: FirstLogin,
    isPrivate: false,
    useCommonLayout: false,
    name: "ProfileMapping",
  },

  {
    path: "/signup",
    component: Signup,
    isPrivate: false,
    useCommonLayout: false,
    name: "Sign Up",
  },

  /* For testing... */
  {
    path: "/oauth/callback",
    component: CallBack,
    isPrivate: false,
    useCommonLayout: false,
    name: "Sign Up",
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    isPrivate: false,
    useCommonLayout: false,
    name: "Forgot Password",
  },

  // Dummy routes
  {
    path: "/dashboard",
    component: Dashboard,
    isPrivate: true,
    useCommonLayout: true,
    name: "Home",
    icon: HomeIcon,
    uniColor: "#a53a0187",
    isDrawerTab: true,
    bothActive: false,
  },
  {
    path: "/Calls",
    component: Calls,
    isPrivate: true,
    useCommonLayout: true,
    name: "Calls",
    icon: VideoIcon,
    uniColor: "#ff818180",
    isDrawerTab: true,
  },
  {
    path: "/Calls/CallDetails/:id",
    component: CallDetails,
    isPrivate: true,
    useCommonLayout: true,
    name: "Calls",
    icon: VideoIcon,
    uniColor: "#ff818180",
    isDrawerTab: false,
  },
  {
    path: "/Calls/PastCallDetails/:id",
    component: PastCallDetails,
    isPrivate: true,
    useCommonLayout: true,
    name: "Calls",
    icon: VideoIcon,
    uniColor: "#ff818180",
    isDrawerTab: false,
  },
  {
    path: "/Calls/:id",
    component: MeetingHeader,
    isPrivate: true,
    useCommonLayout: false,
    name: "Calls",
    icon: VideoIcon,
    uniColor: "#ff818180",
    isDrawerTab: false,
  },
  {
    path: "/Email/",
    component: Email,
    isPrivate: true,
    useCommonLayout: true,
    name: "Email",
    icon: MailIcon,
    uniColor: "#2a31b587",
    isDrawerTab: true,
  },

  {
    path: "/Email/:emailId?/:emailIndex?",
    component: Email,
    isPrivate: true,
    useCommonLayout: true,
    name: "Email",
    icon: MailIcon,
    uniColor: "#2a31b587",
    isDrawerTab: false,
  },
  {
    path: "/Calendar",
    component: Calendar,
    isPrivate: true,
    useCommonLayout: true,
    name: "Plan",
    icon: PlanIcon,
    uniColor: "#b5005b85",
    isDrawerTab: true,
  },

  {
    path: "/Calendar/invitations/:calendarId/:eventId/:status/:type",
    component: Invitations,
    isPrivate: true,
    useCommonLayout: true,
    name: "Plan",
    icon: PlanIcon,
    uniColor: "#b5005b85",
    isDrawerTab: false,
  },

  {
    path: "/book-event/:hostName/:slug",
    component: BookingEventPublicPage,
    isPrivate: false,
    useCommonLayout: false,
    name: "Cloud Storage Share",
    icon: TaskIcon,
    uniColor: "#0073b585",
    isDrawerTab: false,
    bothActive: true,
  },

  {
    path: "/workspace",
    component: Workspace,
    isPrivate: true,
    useCommonLayout: true,
    name: "Task",
    icon: TaskIcon,
    uniColor: "#0073b585",
    isDrawerTab: true,
  },
  {
    path: `/workspace/:workspaceId`,
    component: InsideWorkspace,
    isPrivate: true,
    useCommonLayout: true,
    name: "Task",
    icon: TaskIcon,
    uniColor: "#0073b585",
    isDrawerTab: false,
  },
  {
    path: `/workspace/:isPublic/:workspaceId/:componentType/:componentId?/:subcomponentId?`,
    component: InsideWorkspaceItem,
    isPrivate: true,
    useCommonLayout: true,
    name: "Task",
    icon: TaskIcon,
    uniColor: "#0073b585",
    isDrawerTab: false,
  },

  /* ---------------- SHARED WORKSPACE PUBLIC PAGE [START] ---------------- */
  {
    path: `/share/workspace/:sharedType/:workspaceId`,
    component: InsideWorkspace,
    isPrivate: false,
    useCommonLayout: true,
    name: "Shared Task",
    icon: TaskIcon,
    uniColor: "#0073b585",
    isDrawerTab: false,
  },
  {
    path: `/share/workspace/:sharedType/:isPublic/:workspaceId/:componentType/:componentId?/:subcomponentId?`,
    component: InsideWorkspaceItem,
    isPrivate: false,
    useCommonLayout: true,
    name: "Shared Task",
    icon: TaskIcon,
    uniColor: "#0073b585",
    isDrawerTab: false,
  },
  /* ---------------- SHARED WORKSPACE PUBLIC PAGE [END] ---------------- */

  {
    path: "/cloud-storage/",
    component: CloudStorage,
    isPrivate: true,
    useCommonLayout: true,
    name: "Cloud Storage",
    icon: TaskIcon,
    uniColor: "#0073b585",
    isDrawerTab: false,
  },

  {
    path: "/cloud-storage/:fileId/:fileType",
    component: InsideFile_CloudStorage,
    isPrivate: true,
    useCommonLayout: true,
    name: "Cloud Storage",
    icon: TaskIcon,
    uniColor: "#0073b585",
    isDrawerTab: false,
  },

  {
    path: "/Project",
    component: Project,
    isPrivate: true,
    useCommonLayout: true,
    name: "Project",
    icon: TaskIcon,
    uniColor: "#0073b585",
    isDrawerTab: false,
  },
  {
    path: "/Message",
    component: Message,
    isPrivate: true,
    useCommonLayout: true,
    name: "Message",
    icon: StreamIcon,
    uniColor: "#765ee286",
    isDrawerTab: true,
  },
  {
    path: "/auth/redirect",
    component: RedirectAuth,
    isPrivate: true,
    useCommonLayout: false,
    name: "Message",
    icon: StreamIcon,
    uniColor: "#765ee286",
    isDrawerTab: true,
  },
  {
    path: "/teams/auth/redirect",
    component: teamsRedirectAuth,
    isPrivate: true,
    useCommonLayout: false,
    name: "Message",
    icon: StreamIcon,
    uniColor: "#765ee286",
    isDrawerTab: true,
  },
  {
    path: "/Message/createconnection",
    component: CreateConnection,
    isPrivate: true,
    useCommonLayout: true,
    name: "Message",
    icon: StreamIcon,
    uniColor: "#765ee286",
    isDrawerTab: false,
  },
  {
    path: "/Message/:id",
    component: MessageChats,
    isPrivate: true,
    useCommonLayout: true,
    name: "Message",
    icon: StreamIcon,
    uniColor: "#765ee286",
    isDrawerTab: false,
  },
  {
    path: "/CRM",
    component: Crm,
    isPrivate: true,
    useCommonLayout: true,
    name: "CRM",
    icon: CRMIcon,
    uniColor: "#e2885e85",
    isDrawerTab: true,
  },
  {
    path: "/CRM/people",
    component: Crm,
    isPrivate: true,
    useCommonLayout: true,
    name: "CRM",
    icon: CRMIcon,
    uniColor: "#e2885e85",
    isDrawerTab: false,
  },
  {
    path: "/CRM/people/:personId",
    component: OverView,
    isPrivate: true,
    useCommonLayout: true,
    name: "CRM",
    icon: CRMIcon,
    uniColor: "#e2885e85",
    isDrawerTab: false,
  },
  {
    path: "/CRM/company",
    component: Crm,
    isPrivate: true,
    useCommonLayout: true,
    name: "CRM",
    icon: CRMIcon,
    uniColor: "#e2885e85",
    isDrawerTab: false,
  },
  {
    path: "/CRM/company/:companyId",
    component: OverView,
    isPrivate: true,
    useCommonLayout: true,
    name: "CRM",
    icon: CRMIcon,
    uniColor: "#e2885e85",
    isDrawerTab: false,
  },
  {
    path: "/CRM/deal",
    component: Crm,
    isPrivate: true,
    useCommonLayout: true,
    name: "CRM",
    icon: CRMIcon,
    uniColor: "#e2885e85",
    isDrawerTab: false,
  },
  {
    path: "/CRM/deal/:dealId",
    component: OverView,
    isPrivate: true,
    useCommonLayout: true,
    name: "CRM",
    icon: CRMIcon,
    uniColor: "#e2885e85",
    isDrawerTab: false,
  },
  {
    path: "/CRM/group/:groupId",
    component: OverView,
    isPrivate: true,
    useCommonLayout: true,
    name: "CRM",
    icon: CRMIcon,
    uniColor: "#e2885e85",
    isDrawerTab: false,
  },
  {
    path: "/Trax",
    component: Trax,
    isPrivate: true,
    useCommonLayout: true,
    name: "Trax",
    icon: TraxIcon,
    uniColor: "#30879381",
    isDrawerTab: true,
  },
  {
    path: "/Trax/employee-directory",
    component: Trax,
    isPrivate: true,
    useCommonLayout: true,
    // name: "Trax",
    // icon: TraxIcon,
    // uniColor: "#30879381",
    isDrawerTab: false,
  },
  {
    path: "/Trax/employee-directory/:empId",
    component: TraxOverview,
    isPrivate: true,
    useCommonLayout: true,
    isDrawerTab: false,
  },
  {
    path: "/Trax/time-attendance",
    component: Trax,
    isPrivate: true,
    useCommonLayout: true,
    // name: "Trax",
    // icon: TraxIcon,
    // uniColor: "#30879381",
    isDrawerTab: false,
  },
  {
    path: "/Settings",
    component: Settings,
    isPrivate: true,
    useCommonLayout: true,
    name: "Settings",
    icon: TraxIcon,
    uniColor: "#30879381",
    isDrawerTab: false,
  },
  {
    path: "/Settings/general",
    component: Settings,
    isPrivate: true,
    useCommonLayout: true,
    name: "Settings general",
    icon: TraxIcon,
    uniColor: "#30879381",
    isDrawerTab: false,
  },
  {
    path: "/Settings/plan-details",
    component: Settings,
    isPrivate: true,
    useCommonLayout: true,
    name: "Settings plans details",
    icon: TraxIcon,
    uniColor: "#30879381",
    isDrawerTab: false,
  },
  {
    path: "/Settings/app-notifications",
    component: Settings,
    isPrivate: true,
    useCommonLayout: true,
    name: "Settings app notifications",
    icon: TraxIcon,
    uniColor: "#30879381",
    isDrawerTab: false,
  },
  {
    path: "/Settings/profile",
    component: Settings,
    isPrivate: true,
    useCommonLayout: true,
    name: "Settings profile",
    icon: TraxIcon,
    uniColor: "#30879381",
    isDrawerTab: false,
  },
  {
    path: "/Settings/app-security",
    component: Settings,
    isPrivate: true,
    useCommonLayout: true,
    name: "Settings app security",
    icon: TraxIcon,
    uniColor: "#30879381",
    isDrawerTab: false,
  },
  {
    path: "/Settings/beba-ai",
    component: Settings,
    isPrivate: true,
    useCommonLayout: true,
    name: "Settings beba-ai",
    icon: TraxIcon,
    uniColor: "#30879381",
    isDrawerTab: false,
  },
  {
    path: "/Settings/tranquil-ai",
    component: Settings,
    isPrivate: true,
    useCommonLayout: true,
    name: "Settings tranquil-ai",
    icon: TraxIcon,
    uniColor: "#30879381",
    isDrawerTab: false,
  },
  {
    path: "/Settings/intelligent-connections",
    component: Settings,
    isPrivate: true,
    useCommonLayout: true,
    name: "Settings intelligent-connections",
    icon: TraxIcon,
    uniColor: "#30879381",
    isDrawerTab: false,
  },
];
