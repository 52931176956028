import { Box, Button, Divider, Stack } from "@mui/material";
import React from "react";
import {
  AddColumnEmployeeDirectoryList,
  DropdownButtonCSV,
  DropdownButtonFilterMenu,
  DropdownButtonSortMenu,
} from "../../../common";
import { AllEmployeesTrax } from "../components";
import { TotalCRM } from "../../CRM/components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/rootReducer";
import { EmployeeDrawer, EmpTable } from "./components";

interface EmployeeDirectoryProps {}

const EmployeeDirectory: React.FC<EmployeeDirectoryProps> = ({}) => {
  const traxState = useSelector((state: RootState) => state && state.trax);
  const { popupEmployeeDataId } = traxState || {};
  const dispatch = useDispatch();

  const { t } = useTranslation();
  return (
    <Box sx={{ padding: "10px 0 25px 0" }}>
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "15px",
          padding: "11px 0px 11px 15px ",
        }}
      >
        <AllEmployeesTrax onSelect={() => null} />
        <TotalCRM
          label={t("TRAX.TOTAL_EMPLOYEES")}
          totalCount={500}
          labelColor="traxBlue"
        />
      </Stack>
      <Divider />
      <Stack
        sx={{
          padding: "15px",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "15px" }}>
          <DropdownButtonSortMenu
            buttonColor="TraxBluish"
            sortOption={AddColumnEmployeeDirectoryList}
            sortedList={(list) => console.log(list)}
          />

          <DropdownButtonFilterMenu
            buttonColor="TraxBluish"
            filterOption={AddColumnEmployeeDirectoryList}
            filteredList={(list) => console.log(list)}
          />
        </Stack>
        <DropdownButtonCSV onSelect={() => null} />
      </Stack>

      {/* -------------------- */}

      <Stack width={"100%"} direction={"row"} alignItems={"flex-start"} gap={0}>
        <EmpTable />
      </Stack>

      <EmployeeDrawer />
    </Box>
  );
};

export default EmployeeDirectory;
