import { CalendarState } from "../CalendarType";

// Requests
export const LoginExternalCalendar__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};
// Requests
export const AuthExternalCalendar__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};

export const createCalendar__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};

export const getSharedWith__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};
export const updateEventDate__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};

export const updateEventTaskStatus__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};

export const updateCalendarAppearance__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};
export const shareCalendarWithUser__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};

export const subscribeToICal__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};
export const subscribeToUserCal__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};

export const getAllCalendars__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
  state.isGetAllCalendarList = true;
  state.lastApiCallTime = Date.now(); // Set timestamp when the API request is initiated
};

export const getOneCalendar__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};
export const getOneEvent__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};

export const updateCalendar__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};

export const deleteCalendar__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};

export const createCalendarSettings__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};

export const getCalendarSettings__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};

export const updateCalendarSettings__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};

export const addConnectedCalendar__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};

export const removeConnectedCalendar__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};

export const createEvent__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};

export const getAllEvents__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};

export const createTask__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};

export const getAllTasks__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};

export const createBookingLink__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};

export const getBookingLinks__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};

export const eventResponse__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};

// Requests
export const createSchedule__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};

export const getAllSchedules__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};

export const getOneSchedule__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};

export const updateSchedule__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};

export const deleteSchedule__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};

export const getAllBookingLinks__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};

export const trackVisit__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};

export const getPublicBookingLink__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};

export const getPrivateBookingLink__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};

export const getAvailableTimeSlots__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};

export const createBookingEvent__Request = (state: CalendarState) => {
  state.error = null;
  state.data = null;
};
