import { createApiAction } from "../constantApi_config";
import {
    updloadFile__FailureAction,
    updloadFile__RequestAction,
    updloadFile__SuccessAction,
} from "./FileUploadManagerSlice";

/* Upload File */
export const uploadFile__api = (files: FormData) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: files,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        url: `/upload-file`,
        onStart: updloadFile__RequestAction.type,
        onSuccess: updloadFile__SuccessAction.type,
        onError: updloadFile__FailureAction.type,
    });
