import { Stack } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../Utils";

interface DetailsPosterProps {
  path?: string;
}

const DetailsPoster: React.FC<DetailsPosterProps> = ({ path }) => {
  return (
    <Stack
      sx={{
        height: "150px",
        borderRadius: "5px",
        bgcolor: adjustHexColor("#E9EDF2", 25),
        flexShrink: 0,
        backgroundImage: `url(${path ? path : ""})`, // Set the background image
        backgroundSize: "cover", // Cover the entire container
        backgroundPosition: "center", // Center the image
        backgroundRepeat: "no-repeat", // Prevent repeating
      }}
    />
  );
};

export default DetailsPoster;
