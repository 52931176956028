import React from "react";
import { Box, Stack, styled, SxProps } from "@mui/material";
import { AvatarNormal } from "../AvatarNormal";

interface AvatarWithCompanyProps {
  size: 20 | 30 | 40;
  userName?: string | undefined;
  userIconSrc?: string;
  isActive?: boolean;
  companyLogoSrc?: string;
  sx?: SxProps;
  onClick?: any;
}

const AvatarWithCompany: React.FC<AvatarWithCompanyProps> = ({
  size,
  isActive,
  userName,
  companyLogoSrc,
  userIconSrc,
  sx,
  onClick,
}) => {
  const getProperty = (): {
    topBadege: {
      topPosition: number;
      rightPosition: number;
      w: string;
    };
    bottomBadege: {
      bottomPosition: number;
      rightPosition: number;
      w: string;
    };
    avatarBorder: string;
  } => {
    switch (size) {
      case 20:
        return {
          topBadege: {
            topPosition: 0,
            rightPosition: -1,
            w: "6px",
          },
          bottomBadege: {
            bottomPosition: 0.5,
            rightPosition: 1,
            w: "4px",
          },
          avatarBorder: "0.25px solid #E9EDF2",
        };
      case 30:
        return {
          topBadege: {
            topPosition: 0,
            rightPosition: -1,
            w: "8px",
          },
          bottomBadege: {
            bottomPosition: 0.5,
            rightPosition: 1,
            w: "5px",
          },
          avatarBorder: "1px solid #E9EDF2",
        };
      case 40:
        return {
          topBadege: {
            topPosition: 0,
            rightPosition: 0,
            w: "10px",
          },
          bottomBadege: {
            bottomPosition: 0,
            rightPosition: 0,
            w: "10px",
          },
          avatarBorder: "2px solid #E9EDF2",
        };
    }
  };

  // Styled Badge for the top badge (image)
  const TopBadgeStyle = {
    position: "absolute",
    top: getProperty().topBadege.topPosition,
    right: getProperty().topBadege.rightPosition,
    width: getProperty().topBadege.w,
    height: getProperty().topBadege.w,
    boxSizing: "border-box",
  };

  // Custom Box for the bottom badge (color)
  const BottomBadge = styled(Box)(({ theme }) => ({
    position: "absolute",
    bottom: getProperty().bottomBadege.bottomPosition,
    right: getProperty().bottomBadege.rightPosition,
    width: getProperty().bottomBadege.w,
    height: getProperty().bottomBadege.w,
    boxSizing: "border-box",
    borderRadius: "50%",
    backgroundColor: isActive ? "#008D4C" : "#FFB100",
  }));

  return (
    <Stack
      sx={{
        position: "relative",
        width: `${size}px`,
        height: `${size}px`,
        boxSizing: "border-box",
        borderRadius: "50%",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <AvatarNormal
        username={userName}
        size={size}
        imgSrc={userIconSrc}
        sx={{
          border: getProperty().avatarBorder,
          ...sx,
        }}
      />
      {companyLogoSrc && (
        <Box
          component={"img"}
          src={companyLogoSrc}
          alt="Top Badge"
          sx={TopBadgeStyle}
        />
      )}

      {isActive !== undefined && <BottomBadge />}
    </Stack>
  );
};

export default AvatarWithCompany;
