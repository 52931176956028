import { ShareState } from "../ShareType";

export const addShare__Request = (state: ShareState) => {
    state.error = null;
    state.data = null;
};

export const getShare__Request = (state: ShareState) => {
    state.error = null;
    state.data = null;
};
