import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  ButtonSmall,
  ButtonType,
  MediumInputIconBox,
  SmallButtonColor,
} from "../../../../common";
import {
  FromView,
  AdvancedActionsView,
  BasicFilterView,
  BodyKeywordView,
  HostView,
  PrioritylevelView,
  SubjectKeywordView,
} from "./view";
import axios from "axios";
import { baseURL } from "../../../../../redux/middleware/apiMiddleware";
import { getToken } from "../../../../common/LocalStorage";

interface CreateSmartFilterProps {
  getFilters?: () => Promise<void>;
  filterData: FilterData | null;
  setAnchorEl: (element: HTMLButtonElement | null) => void;
}

interface Host {
  domain: string;
  name: string;
  icon: string;
}

interface basicFilters {
  hasAttachment: boolean;
  hasLink: boolean;
  read: boolean;
  unread: boolean;
}

interface priorityLevel {
  highest: boolean;
  medium: boolean;
  lowest: boolean;
}

interface advancedActions {
  moveTo: boolean;
  markAsRead: boolean;
  markImportant: boolean;
  moveToTrash: boolean;
}

interface FilterData {
  _id?: string;
  name: string;
  from: string[];
  hosts: Host[];
  subjectKeywords: string[];
  subjectMatchType: string;
  bodyKeywords: string[];
  bodyMatchType: string;
  basicFilters: basicFilters;
  priorityLevel: priorityLevel;
  advancedActions: advancedActions;
}


const CreateSmartFilter: React.FC<CreateSmartFilterProps> = ({ filterData, setAnchorEl, getFilters }) => {
  const [nameValue, setNameValue] = useState("");

  const token = getToken();
  const isUpdate = filterData !== null && filterData?._id;
  const [filterdata, setFilterData] = useState<FilterData>({
    name: "",
    from: [],
    hosts: [],
    subjectKeywords: [],
    subjectMatchType: "EXACT_MATCH",
    bodyKeywords: [],
    bodyMatchType: "EXACT_MATCH",
    basicFilters: {
      hasAttachment: false,
      hasLink: false,
      read: false,
      unread: false,
    },
    priorityLevel: {
      highest: false,
      medium: false,
      lowest: false,
    },
    advancedActions: {
      moveTo: false,
      markAsRead: false,
      markImportant: false,
      moveToTrash: false,
    },
  });




  const updateFilterData = (key: keyof typeof filterdata, value: any) => {
    setFilterData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };


  const handleSubmit = async () => {
    try {
      const endpoint = filterdata?._id ? `${baseURL}/email/filter/${filterdata._id}` : `${baseURL}/email/filter`;
      const method = filterdata._id ? "put" : "post";

      const res = await axios({
        method,
        url: endpoint,
        data: filterdata,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (res.status === 200 || res.status === 201) {
        setFilterData({
          name: "",
          from: [],
          hosts: [],
          subjectKeywords: [],
          subjectMatchType: "EXACT_MATCH",
          bodyKeywords: [],
          bodyMatchType: "EXACT_MATCH",
          basicFilters: {
            hasAttachment: false,
            hasLink: false,
            read: false,
            unread: false,
          },
          priorityLevel: {
            highest: false,
            medium: false,
            lowest: false,
          },
          advancedActions: {
            moveTo: false,
            markAsRead: false,
            markImportant: false,
            moveToTrash: false,
          },
        });
        setNameValue(res.data.data.name);
        setAnchorEl(null)
        if (getFilters) {
          getFilters();
        }
      }
      return res;
    } catch (err) {
      console.log(err);
    }
  };

  
  useEffect(() => {
    const handleGetSingleFilter = async () => {
      if (isUpdate) {
        try {
          const res = await axios.get(`${baseURL}/email/filter/${filterData._id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (res.status === 200) {
            const apiData = res.data.data;

            setFilterData(prevData => ({
              ...prevData,
              _id: apiData._id,
              name: apiData.name,
              from: apiData.from,
              hosts: apiData.hosts,
              subjectKeywords: apiData.subjectKeywords,
              subjectMatchType: apiData.subjectMatchType,
              bodyKeywords: apiData.bodyKeywords,
              bodyMatchType: apiData.bodyMatchType,
              basicFilters: apiData.basicFilters,
              priorityLevel: apiData.priorityLevel,
              advancedActions: apiData.advancedActions,
            }));
            setNameValue(apiData.name);
          }
        } catch (err) {
          console.error("Error fetching filter data:", err);
        }
      }
    };

    handleGetSingleFilter();
  }, [filterData?._id]);


  return (
    <Stack
      sx={{
        bgcolor: "white",
        width: "325px",
        height: "100%",
        padding: "15px",
        boxSizing: "border-box",
      }}
    >
      <Stack
        sx={{
          overflowY: "scroll",
          scrollbarWidth: "none",
          gap: "10px",
        }}
      >
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
          <Typography sx={{ font: "normal normal 600 15px/19px Source Serif Pro" }}>
            {isUpdate ? "Update Smart Filter" : "Create Smart Filter"}
          </Typography>
          <Stack sx={{ height: "1px", flexGrow: 1, bgcolor: "#E9EDF2" }} />
        </Stack>
        <Typography
          sx={{ font: "normal italic 300 13px/16px Source Serif Pro" }}>
          Smart filters automatically use your inputs and AI to sort all
          incoming mail to convienent locations.
        </Typography>

        {/* input area for Name */}

        <MediumInputIconBox
          label="Name"
          value={nameValue}
          sx={{ width: "100%" }}
          onChange={(e) => {
            const value = typeof e === "string" ? e : e.target.value;
            setNameValue(value);
            setFilterData((PrevData) => ({ ...PrevData, name: value }))
          }}
        />
        <FromView updateFilterData={updateFilterData} fromData={filterdata?.from} />
        <HostView updateFilterData={updateFilterData} hostData={filterdata?.hosts} />

        <SubjectKeywordView updateFilterData={updateFilterData} subKeydData={filterdata?.subjectKeywords} subMatchType={filterdata?.subjectMatchType} />
        <BodyKeywordView updateFilterData={updateFilterData} bodyKeydData={filterdata?.bodyKeywords} bodyMatchType={filterdata?.bodyMatchType} />

        <BasicFilterView updateFilterData={updateFilterData} basicFilterData={filterdata?.basicFilters} />
        <PrioritylevelView updateFilterData={updateFilterData} priorityLevelData={filterdata?.priorityLevel} onChange={() => { }} />
        <AdvancedActionsView updateFilterData={updateFilterData} advancedActionsData={filterdata?.advancedActions} onChange={() => { }} />
        <Stack sx={{ flexDirection: "row", justifyContent: "end", marginTop: "10px", }}>
          <ButtonSmall
            label={isUpdate ? "Update" : "Save"}
            types={ButtonType.Button}
            colorVarient={SmallButtonColor.Blue}
            onClick={handleSubmit} 
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CreateSmartFilter;
