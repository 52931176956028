import React from "react";
import g from "./invitations.module.css";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import { avatarInitialLetters } from "../../../../Utils";

type Props = {
  eventData?: any;
};

const Summoner = ({ eventData }: Props) => {
  // Function to determine whether to show creator or first guest
  const getSummonerDetails = () => {
    // If bookingLinkId is not null, show the first guest's details
    if (eventData?.bookingLinkId && eventData?.guests?.length > 0) {
      return {
        firstName: eventData?.guests[0]?.firstName || 'Guest', // Default to 'Guest' if undefined
        lastName: eventData?.guests[0]?.lastName || '',
        avatar: eventData?.guests[0]?.avatar,
      };
    }

    // Otherwise, show the creator's details
    return {
      firstName: eventData?.createdBy?.firstName || 'Creator', // Default to 'Creator' if undefined
      lastName: eventData?.createdBy?.lastName || '',
      avatar: eventData?.createdBy?.avatar,
    };
  };

  // Get the summoner details
  const summoner = getSummonerDetails();

  return (
    <Box sx={{ width: "100%" }}>
      <Stack
        mt={1}
        mb={1}
        width={"100%"}
        direction={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Avatar
          sx={{
            width: 80,
            height: 80,
            backgroundColor: "#007FFF",
            boxSizing: "border-box",
            alignItems: "center",
            justifyContent: "center",
            font: `normal normal 600 ${80}px/${80}px Source Serif Pro`,
          }}
          src={
            summoner?.avatar ||
            "https://cdn-icons-png.flaticon.com/512/15735/15735344.png"
          }
          alt={avatarInitialLetters(
            `${summoner?.firstName} ${summoner?.lastName}`
          )}
        >
          {avatarInitialLetters(summoner?.firstName) +
            " " +
            summoner?.lastName}
        </Avatar>

        <Typography className={g.Invitation___Summmoner__boldName}>
          <span style={{ fontWeight: "600" }}>
            {`${summoner?.firstName} ${summoner?.lastName} `}{" "}
          </span>
          Invited you to the event {eventData?.title}
        </Typography>
      </Stack>
    </Box>
  );
};

export default Summoner;
