import { IconButton, Stack, Typography } from "@mui/material";
import React, { useRef } from "react";
import {
  GreenDropdownIcon,
  MicrosoftTeamsLogoPng,
} from "../../../../../images";
import { SmallPinnedCard } from "../../../../common";
import styles from "./SidebarPinnedChatContainer.module.css";

interface SidebarPinnedChatContainerProps {
  children: React.ReactNode;
  needName?: boolean;
}

const SidebarPinnedChatContainer: React.FC<SidebarPinnedChatContainerProps> = ({
  children,needName=true
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const scroll = (direction: "left" | "right") => {
    if (scrollRef.current) {
      const scrollAmount = 100;
      scrollRef.current.scrollBy({
        left: direction === "right" ? scrollAmount : -scrollAmount,
        behavior: "smooth",
      });
    }
  };

  return (
    <Stack className={styles.sidebarPinnedChatContainer}>
     {needName && <Stack className={styles.sidebarPinnedChatContainer__header}>
        <Typography className={styles.sidebarPinnedChatContainer__headerText}>
          Pinned Chats
        </Typography>
        <Stack className={styles.sidebarPinnedChatContainer__divider} />
      </Stack>}
      <Stack className={styles.sidebarPinnedChatContainer__controls}>
        <IconButton sx={{ p: 0 }} disableRipple onClick={() => scroll("left")}>
          <GreenDropdownIcon open sx={{ transform: "rotate(180deg)" }} />
        </IconButton>

        <Stack
          ref={scrollRef}
          className={styles.sidebarPinnedChatContainer__scrollContainer}
        >
          {children}
        </Stack>

        <IconButton sx={{ p: 0 }} disableRipple onClick={() => scroll("right")}>
          <GreenDropdownIcon open sx={{ transform: "rotate(0deg)" }} />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default SidebarPinnedChatContainer;
