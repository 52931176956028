import React from "react";
import {
  SmallThreeDotMenu,
  SmallThreeDotMenuListInterface,
} from "../../../../common";
import {
  ConnectedIcon,
  DarkFolderIcon,
  DocumentIcon,
  FolderFileIcon,
  FolderIcon,
} from "../../../../../images";

interface FolderOptionsProps {}

const MENU_LISTS: SmallThreeDotMenuListInterface[] = [
  {
    id: 1,
    icon: DarkFolderIcon,
    text: "Upload Folder",
    color: "#008D4C",
  },
  {
    id: 2,
    icon: DocumentIcon,
    text: "Upload Item",
    color: "#757575",
  },
  {
    id: 3,
    icon: FolderFileIcon,
    text: "Create Folder",
  },
  {
    id: 4,
    icon: DocumentIcon,
    text: "Create Document",
  },
  {
    id: 5,
    icon: ConnectedIcon,
    text: "Create link",
  },
];

const FolderOptions: React.FC<FolderOptionsProps> = ({}) => {
  return (
    <SmallThreeDotMenu
      w={15}
      menuItems={MENU_LISTS}
      onClickAction={(value) => console.log(value)}
      displayOption="right"
    />
  );
};

export default FolderOptions;
