import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CheckboxMedium } from "../../../../../../common";

interface ListProps {
  name: string;
  isSelected: boolean;
}

interface BasicFilterViewProps {
  onChange?: (list: ListProps[]) => void;
  updateFilterData: (key: "name" | "from" | "subjectKeywords" | "bodyKeywords" | "hosts" | "basicFilters" | "priorityLevel" | "advancedActions", value: any) => void;
  basicFilterData: any;
}

const initialFilterList: ListProps[] = [
  {
    name: "Has attachment",
    isSelected: false,
  },
  {
    name: "Has link",
    isSelected: false,
  },
  {
    name: "Status: Unread",
    isSelected: false,
  },
  {
    name: "Status: Read",
    isSelected: false,
  },
];

const BasicFilterView: React.FC<BasicFilterViewProps> = ({ onChange, updateFilterData, basicFilterData }) => {
  const [filterList, setFilterList] = useState(initialFilterList);


  useEffect(() => {
    if (basicFilterData) {
      setFilterList([
        { name: "Has attachment", isSelected: basicFilterData.hasAttachment },
        { name: "Has link", isSelected: basicFilterData.hasLink },
        { name: "Status: Unread", isSelected: basicFilterData.unread },
        { name: "Status: Read", isSelected: basicFilterData.read },
      ]);
    }
  }, [basicFilterData]);


  const handleClick = (index: number) => {
    const updatedList = filterList.map((item, i) =>
      i === index ? { ...item, isSelected: !item.isSelected } : item
    );

    const selectedFilters = {
      hasAttachment: updatedList[0].isSelected,
      hasLink: updatedList[1].isSelected,
      unread: updatedList[2].isSelected,
      read: updatedList[3].isSelected,
    };

    setFilterList(updatedList);
    if (onChange) {
      onChange(updatedList);
    }
    updateFilterData("basicFilters", selectedFilters);
  };

  return (
    <Stack gap={"15px"} mt={"10px"}>
      <Typography
        sx={{ font: "normal normal 300 14px/18px Source Serif Pro " }}
      >
        Basic filters
      </Typography>
      {filterList.map((item, index) => (
        <Stack
          key={index}
          sx={{
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
            cursor: "pointer",
          }}
          onClick={() => handleClick(index)}
        >
          <CheckboxMedium boxSize={17} checked={item.isSelected} />
          <Typography
            sx={{ font: "normal normal 300 13px/16px Source Serif Pro " }}
          >
            {item.name}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export default BasicFilterView;
