import { Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { CheckboxMedium } from "../../../../../../common";
import { FlagIcon } from "../../../../../../../images";

interface ListProps {
  name: string;
  iconColor: string;
  isSelected: boolean;
}

interface PrioritylevelViewProps {
  onChange?: (list: ListProps[]) => void;
  updateFilterData?: (key: "name" | "from" | "subjectKeywords" | "bodyKeywords" | "hosts" | "basicFilters" | "priorityLevel" | "advancedActions", value: any) => void | undefined;
  priorityLevelData?: any;
}

const initialFilterList: ListProps[] = [
  {
    name: "Highest priority",
    iconColor: "#FF0000",
    isSelected: false,
  },
  {
    name: "Medium priority",
    iconColor: "#FFD700",
    isSelected: false,
  },
  {
    name: "Lowest priority",
    iconColor: "#90EE90",
    isSelected: false,
  },
];

const PrioritylevelView: React.FC<PrioritylevelViewProps> = ({ onChange, updateFilterData, priorityLevelData }) => {
  const [filterList, setFilterList] = React.useState(initialFilterList);


  useEffect(() => {
    if (priorityLevelData) {
      setFilterList([
        { name: "Highest priority", iconColor: "#FF0000", isSelected: priorityLevelData.highest },
        { name: "Medium priority", iconColor: "#FFD700", isSelected: priorityLevelData.medium },
        { name: "Lowest priority", iconColor: "#90EE90", isSelected: priorityLevelData.lowest },
      ]);
    }
  }, [priorityLevelData]);

  const handleClick = (index: number) => {
    const updatedList = filterList.map((item, i) =>
      i === index ? { ...item, isSelected: !item.isSelected } : item
    );

    const selectedFilters = {
      highest: updatedList[0].isSelected,
      medium: updatedList[1].isSelected,
      lowest: updatedList[2].isSelected,
    };

    setFilterList(updatedList);
    if (onChange) {
      onChange(updatedList);
    }
    if (updateFilterData) {
      updateFilterData("priorityLevel", selectedFilters);
    }
  };


  return (
    <Stack gap={"15px"} mt={"5px"}>
      <Typography
        sx={{ font: "normal normal 300 14px/18px Source Serif Pro " }}
      >
        Priority level
      </Typography>
      {filterList.map((item, index) => (
        <Stack
          key={index}
          sx={{
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
            cursor: "pointer",
          }}
          onClick={() => handleClick(index)}
        >
          <CheckboxMedium boxSize={17} checked={item.isSelected} />
          <FlagIcon w={15} color={item.iconColor} />
          <Typography
            sx={{
              font: "normal normal 300 13px/16px Source Serif Pro",
              marginLeft: "5px",
            }}
          >
            {item.name}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export default PrioritylevelView;
