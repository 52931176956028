export interface SettingsMessagesMainListInterface {
  id: number;
  value: string;
  isSelected: boolean;
  LANG: string
}

// create this list with as need
export const SettingsMessagesMainList: SettingsMessagesMainListInterface[] = [
  { id: 1, value: "Show Internal Chats",LANG:"MESSAGE.SHOW_INTERNAL_CHATS", isSelected: true },
  { id: 2, value: "Show External Chats",LANG:"MESSAGE.SHOW_EXTERNAL_CHATS", isSelected: true },
  { id: 3, value: "Show Pinned Chats", LANG:"MESSAGE.SHOW_INTERNAL_CHATS",isSelected: true },
  { id: 4, value: "Show Channels", LANG:"MESSAGE.SHOW_CHANNELS",isSelected: true },
  { id: 5, value: "Show Apps", LANG:"MESSAGE.SHOW_APPS",isSelected: false },
];
