import {
  Box,
  CircularProgress,
  Button,
  Stack,
  Tooltip,
} from "@mui/material";
import g from "./invitations.module.css";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Invitationheader from "./Invitationheader";
import Summoner from "./Summoner";
import SummonersSSummoners from "./EventDetails";
import { eventResponse__api, getOneEvent__api } from "../../../../redux";
import { useDispatch } from "react-redux";
import EventExpiry from "./EventExpiry";

const Invitations = () => {
  const { calendarId, eventId, status, type } = useParams<{
    calendarId: string;
    eventId: string;
    status: string;
    type?: string;
  }>();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [eventData, setEventData] = useState<any | null>({});
  const [timer, setTimer] = useState(6); // 6-second countdown
  const [autoUpdated, setAutoUpdated] = useState(false); // Track if auto-update happened

  const handleGetOneEvent = async () => {
    if (!eventId || !calendarId) {
      return;
    }
    const action = getOneEvent__api(calendarId, eventId);
    const eventGetRes: any = await dispatch(action);

    if (eventGetRes.success && eventGetRes?.data) {
      setEventData(eventGetRes?.data);
    } else {
      setEventData({});
    }
  };

  const handleEventResponse = async (responseType: string) => {
    try {
      if (!calendarId || !eventId || !type || !responseType) {
        return;
      }
      const action = eventResponse__api(
        calendarId,
        eventId,
        responseType,
        type
      );
      await dispatch(action);
      setAutoUpdated(true); // Mark as updated
      setLoading(false); // Stop loading spinner
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prev) => {
        if (prev > 1) return prev - 1;
        clearInterval(countdown);

        // Check if status is defined before calling handleEventResponse
        if (!autoUpdated && status) {
          handleEventResponse(status); // Automatically call API after 6 seconds
        }
        return 0;
      });
    }, 1000);

    return () => clearInterval(countdown); // Cleanup interval on component unmount
  }, [status, autoUpdated]);

  // Fetch event details on component load
  useEffect(() => {
    handleGetOneEvent();
  }, [calendarId, eventId]);

  const handleButtonClick = (responseType: string) => {
    handleEventResponse(responseType); // Call API on button click
    setTimer(0); // Stop the timer
  };

  return (
    <Box
      sx={{
        minWidth: "700px",
        minHeight: "600px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "690px",
          height: "fit-content",
          padding: "20px 20px 25px 20px",
          boxShadow: "3px 3px 10px 3px #dddddd",
          display: "flex",
          alignItems: "flex-start",
          gap: "10px",
          flexDirection: "column",
        }}
      >
        <Invitationheader eventData={eventData} />
        <Summoner eventData={eventData} />{" "}
      <SummonersSSummoners type={type} eventData={eventData} />
        {/* Buttons */}
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          padding={"10px 5px"}
          width={"100%"}
        >
          <Stack minWidth={"234px"}>
            <EventExpiry eventData={eventData} />
          </Stack>
          <Stack
            width={"100%"}
            direction={"row"}
            alignItems={"center"}
            gap={"10px"}
            justifyContent={"flex-end"}
          >
            {type === "guest" && (
              <Button
                className={`${g.Invitation__buttons} ${g.Invitation__buttons__mayBe}`}
                onClick={() => handleButtonClick("maybe")}
              >
                Maybe
              </Button>
            )}
            {/* Not for guest */}
            <Button
              className={`${g.Invitation__buttons} ${g.Invitation__buttons__reject}`}
              onClick={() => handleButtonClick("rejected")}
            >
              {`${type === "guest" ? `Reject` : `Denied`}`}
            </Button>
            <Button
              className={`${g.Invitation__buttons} ${g.Invitation__buttons__accept}`}
              onClick={() => handleButtonClick("accepted")}
            >
              Accept Invitation
            </Button>

            <Stack direction="column" spacing={1} alignItems="flex-start">
              {!autoUpdated && loading && (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Tooltip
                    title={`Your status will update in ${timer} seconds...`}
                    open
                    placement="top"
                  >
                    <CircularProgress size={24} />
                  </Tooltip>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default Invitations;
