import { Stack } from "@mui/material";
import React from "react";
import { EventIconButton } from "../../../../../../common";
import { DoneIcon, EyeIcon } from "../../../../../../../images";
import { SnoozeButton } from "../../../../../Message/components";
import { useTranslation } from "react-i18next";

interface ButtonGroupThreeViewProps {}

const ButtonGroupThreeView: React.FC<ButtonGroupThreeViewProps> = ({}) => {
  const { t } = useTranslation();
  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
      <EventIconButton
        buttonTitle={t("CALENDAR.VIEW")}
        icon={EyeIcon}
        iconColor="#000"
      />
      <EventIconButton
        buttonTitle={t("MESSAGE.DONE")}
        icon={DoneIcon}
        iconColor="#008D4C"
      />
      <SnoozeButton onSelect={(value) => null} />
    </Stack>
  );
};

export default ButtonGroupThreeView;
