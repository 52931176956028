import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {  VideoCenterState } from "./VideoCenterTypes";
import {
  createMeetingFailure,
  createMeetingRequest,
  createMeetingSuccess,
  getSingleMeetingFailure,
  getSingleMeetingRequest,
  getUserMeetingsFailure,
  getUserMeetingsRequest,
  getUserMeetingsSuccess,
  getSelectedMeetingAgendaSuccess,
  getSelectedMeetingAgendaRequest,
  getSelectedMeetingAgendaFailure,
  deleteAgendaFailure,
  deleteAgendaRequest,
  deleteAgendaSuccess,
  updateAgendaStatusFailure,
  updateAgendaStatusRequest,
  updateAgendaStatusSuccess,
  getAllMeetingNoteSuccess,
  deleteMeetingNotesFailure,
  deleteMeetingNotesRequest,
  deleteMeetingNotesSuccess,
  updateMeetingNoteFailure,
  updateMeetingNoteRequest,
  updateMeetingNoteSuccess,
  createMeetingNoteFailure,
  createMeetingNoteRequest,
  createMeetingNoteSuccess,
  getAllMeetingNoteFailure,
  getAllMeetingNoteRequest,
  getOnGoingMeetingAgendaSuccess,
  getOnGoingMeetingAgendaFailure,
  getOnGoingMeetingAgendaRequest,
  createMeetingAgendaSuccess,
  createOnGoingMeetingAgendaSuccess,
  getPastMeetingsRequest,
  getPastMeetingsSuccess,
  getPastMeetingsFailure,
  getCallActivityDetailsSuccess,
  getCallActivityDetailsRequest,
  getCallActivityDetailsFailure,
  getPastMeetingDetailsRequest,
  getPastMeetingDetailsSuccess,
  getPastMeetingDetailsFailure,
  updateMeetingsDetails__Success,
  getSingleMeetingSuccess,
  addWorkspacesAndProjectsToMeetingSuccess,
  addWorkspacesAndProjectsToMeetingFailure,
  addWorkspacesAndProjectsToMeetingRequest,
  getUserProjectsSuccess,
  getUserProjectsRequest,
  getUserProjectsFailure,
} from "./VideoCenterSliceActions";

/*
 * ==============================================
 * Define the initial state for the meeting slice
 * ==============================================
 */

const initialState: VideoCenterState = {
  selectedMeeting: {
    meeting: {},
    meetingAgendas: [],
    meetingNotes: [],
    meetingFiles: [],
  },
  onGoingMeeting: {
    realtimeAttendees:null,
    ClintNotification: null,
    notificationData: null,
    meeting: {},
    guestRequests: {},
    meetingAgendas: {},
    meetingNotes: [],
    meetingFiles: [],
    meetingsDetails: {},
  },
  selectedPastMeeting:{
    activity:[],
    agendas:[],
    notes:[],
  },
  upcomingMeetings: [],
  pastMeetings: [],
  projects: [],
  loading: false,
  errors: null,
};

/*
 * ==============================================
 * Create a slice for Fetch meetings
 * ==============================================
 */
const videoCenterSlice = createSlice({
  name: "meeting", // Name of the slice
  initialState, // Initial state of the slice

  reducers: {
    // Reducers to handle different actions
    getCallActivityDetailsRequest,
    getCallActivityDetailsSuccess,
    getCallActivityDetailsFailure,

    getPastMeetingDetailsRequest,
    getPastMeetingDetailsSuccess,
    getPastMeetingDetailsFailure,

    createMeetingRequest,
    createMeetingSuccess,
    createMeetingFailure,

    getUserMeetingsRequest,
    getUserMeetingsSuccess,
    getUserMeetingsFailure,

    updateMeetingsDetails__Success,

    getPastMeetingsRequest,
    getPastMeetingsSuccess,
    getPastMeetingsFailure,

    getSingleMeetingRequest,
    getSingleMeetingSuccess,
    getSingleMeetingFailure,

    getSelectedMeetingAgendaSuccess,
    getSelectedMeetingAgendaRequest,
    getSelectedMeetingAgendaFailure,
    getOnGoingMeetingAgendaSuccess,
    getOnGoingMeetingAgendaRequest,
    getOnGoingMeetingAgendaFailure,

    // update agenda status
    updateAgendaStatusSuccess,
    updateAgendaStatusRequest,
    updateAgendaStatusFailure,
    // delete meeting agenda
    deleteAgendaSuccess,
    deleteAgendaRequest,
    deleteAgendaFailure,

    // get all meeting notes
    getAllMeetingNoteSuccess,
    getAllMeetingNoteRequest,
    getAllMeetingNoteFailure,

    // create meeting note
    createMeetingNoteSuccess,
    createMeetingNoteRequest,
    createMeetingNoteFailure,

    // update meeting note
    updateMeetingNoteSuccess,
    updateMeetingNoteRequest,
    updateMeetingNoteFailure,

    // delete meeting note
    deleteMeetingNotesSuccess,
    deleteMeetingNotesRequest,
    deleteMeetingNotesFailure,

    getUserProjectsSuccess,
    getUserProjectsRequest,
    getUserProjectsFailure,

    addWorkspacesAndProjectsToMeetingSuccess,
    addWorkspacesAndProjectsToMeetingRequest,
    addWorkspacesAndProjectsToMeetingFailure,

    createMeetingAgendaSuccess,
    createOnGoingMeetingAgendaSuccess,

    guestRequestsForAccess: (
      state: VideoCenterState,
      action: { payload: any }
    ) => {
      // Convert the incoming array to an object of objects
      const newRequest = action.payload;
      state.onGoingMeeting.notificationData = newRequest;
    },
    guestRequestsForPlaseWait: (
      state: VideoCenterState,
      action: { payload: any }
    ) => {
      // Convert the incoming array to an object of objects
      const newRequest = action.payload;
      console.log(newRequest,"newRequest")
      if (!state.onGoingMeeting.guestRequests) {
        state.onGoingMeeting.guestRequests = {};
      }
      // Update the state with the merged requests
      state.onGoingMeeting.guestRequests[newRequest?.userId] = newRequest;
    },

    ClintSendNotifection:(state, action) => {
      state.onGoingMeeting.ClintNotification = action.payload;
    },

    updateAgendaWithId: (state, action) => {
      const updatedAgenda = action.payload.updatedAgenda;
      state.onGoingMeeting.meetingAgendas[updatedAgenda._id] = updatedAgenda;
    },
    removeAgendaWithId: (state, action) => {
      const agendaId = action.payload;
      delete state.onGoingMeeting.meetingAgendas[agendaId];
    },
    removeSelectedMeetingAgendaWithId: (state, action) => {
      const agendaId = action.payload;
      delete state.selectedMeeting.meetingAgendas[agendaId];
    },

    addAgendaToOnGoingMeeting: (state, action) => {
      const agendas = action.payload;
      
      agendas?.savedAgendas?.forEach((agenda: any) => {
        state.onGoingMeeting.meetingAgendas[agenda._id] = agenda;
      });
    },

    removeGuestRequest: (state, action) => {
      const userId = action.payload;
      if (state.onGoingMeeting.guestRequests) {
        delete state.onGoingMeeting.guestRequests[userId];
      }
    },

    setDeleteAgenda(state, action) {
      const agendaId = action.payload;
      if (state.onGoingMeeting.meetingAgendas) {
        delete state.onGoingMeeting.meetingAgendas[agendaId];
      }
    },
    setOnGoingMeeting: (state, action) => {
      const meeting = action.payload;
      state.onGoingMeeting.meeting = meeting;
    },
    setSelectedMeeting: (state, action) => {
      const meeting = action.payload;
      state.selectedMeeting.meeting = meeting;
    },
    setUpdateAgendaDetails(
      state,
      action: PayloadAction<{ agendaId: string; payload: any }>
    ) {
      if (state.selectedMeeting) {
        const { agendaId, payload } = action.payload;

        if (agendaId) {
          state.selectedMeeting.meetingAgendas[agendaId].title = payload.title;
          state.selectedMeeting.meetingAgendas[agendaId].description =
            payload.description;
          state.selectedMeeting.meetingAgendas[agendaId].timeAllotted =
            payload.timeAllotted;
        }
      }
    },
    setCreateMeetingAgenda(state, action: PayloadAction<any>) {
      if (state.selectedMeeting) {
        action.payload.forEach((item: any) => {
          state.selectedMeeting.meetingAgendas[item._id] = item;
        });
      }
    },
    setIsLoading(state, action) {
      state.loading = action.payload;
    },
    setEndMeeting(state, action) {
      state.onGoingMeeting.meeting = {
      ...action.payload,
      isMeetingEnded: true,
      };
      state.onGoingMeeting.ClintNotification = action.payload;
      state.onGoingMeeting.notificationData = action.payload;
      state.onGoingMeeting.guestRequests = action.payload;
      state.onGoingMeeting.meetingAgendas = action.payload;
      state.onGoingMeeting.meetingNotes = action.payload;
    },
    setEndMeetingObjBlank(state, action) {
      state.onGoingMeeting.meeting = {};
    },
  },
});

/*
 * ======================================================
 * Export the actions to be used in dispatch calls
 * ======================================================
 */
export const {
  /* get meetingCallActivity */
  getCallActivityDetailsRequest: getCallActivityDetailsRequestAction,
  getCallActivityDetailsSuccess: getCallActivityDetailsSuccessAction,
  getCallActivityDetailsFailure: getCallActivityDetailsFailureAction,

  /*get past Meeting Details */
  getPastMeetingDetailsRequest: getPastMeetingDetailsRequestAction,
  getPastMeetingDetailsSuccess: getPastMeetingDetailsSuccessAction,
  getPastMeetingDetailsFailure: getPastMeetingDetailsFailureAction,


  /* get meeting */
  createMeetingRequest: createMeetingRequestAction,
  createMeetingSuccess: createMeetingSuccessAction,
  createMeetingFailure: createMeetingFailureAction,

  /* get meeting */
  getUserMeetingsRequest: getUserMeetingsRequestAction,
  getUserMeetingsSuccess: getUserMeetingsSuccessAction,
  getUserMeetingsFailure: getUserMeetingsFailureAction,
  /* get  past meeting */
  getPastMeetingsRequest: getPastMeetingsRequestAction,
  getPastMeetingsSuccess: getPastMeetingsSuccessAction,
  getPastMeetingsFailure: getPastMeetingsFailureAction,
  /* get meeting */
  getSingleMeetingRequest: getSingleMeetingRequestAction,
  getSingleMeetingSuccess: getSingleMeetingSuccessAction,
  getSingleMeetingFailure: getSingleMeetingFailureAction,

  updateMeetingsDetails__Success: updateMeetingsDetails__SuccessAction,

  // get meeting agenda
  getSelectedMeetingAgendaSuccess: getSelectedMeetingAgendaSuccessAction,
  getSelectedMeetingAgendaRequest: getSelectedMeetingAgendaRequestAction,
  getSelectedMeetingAgendaFailure: getSelectedMeetingAgendaFailureAction,
  // get meeting agenda
  getOnGoingMeetingAgendaSuccess: getOnGoingMeetingAgendaSuccessAction,
  getOnGoingMeetingAgendaRequest: getOnGoingMeetingAgendaRequestAction,
  getOnGoingMeetingAgendaFailure: getOnGoingMeetingAgendaFailureAction,

  // update agenda status
  updateAgendaStatusSuccess: updateAgendaStatusSuccessAction,
  updateAgendaStatusRequest: updateAgendaStatusRequestAction,
  updateAgendaStatusFailure: updateAgendaStatusFailureAction,

  // delete meeting agenda
  deleteAgendaSuccess: deleteAgendaSuccessAction,
  deleteAgendaRequest: deleteAgendaRequestAction,
  deleteAgendaFailure: deleteAgendaFailureAction,

  // get all meeting notes
  getAllMeetingNoteSuccess: getAllMeetingNoteSuccessAction,
  getAllMeetingNoteRequest: getAllMeetingNoteRequestAction,
  getAllMeetingNoteFailure: getAllMeetingNoteFailureAction,

  // create meeting note
  createMeetingNoteSuccess: createMeetingNoteSuccessAction,
  createMeetingNoteRequest: createMeetingNoteRequestAction,
  createMeetingNoteFailure: createMeetingNoteFailureAction,

  // update meeting note
  updateMeetingNoteSuccess: updateMeetingNoteSuccessAction,
  updateMeetingNoteRequest: updateMeetingNoteRequestAction,
  updateMeetingNoteFailure: updateMeetingNoteFailureAction,

  // delete meeting note
  deleteMeetingNotesSuccess: deleteMeetingNotesSuccessAction,
  deleteMeetingNotesRequest: deleteMeetingNotesRequestAction,
  deleteMeetingNotesFailure: deleteMeetingNotesFailureAction,

  // get user projects
  getUserProjectsSuccess: getUserProjectsSuccessAction,
  getUserProjectsRequest: getUserProjectsRequestAction,
  getUserProjectsFailure: getUserProjectsFailureAction,
 
  addWorkspacesAndProjectsToMeetingSuccess: addWorkspacesAndProjectsToMeetingSuccessAction,
  addWorkspacesAndProjectsToMeetingRequest: addWorkspacesAndProjectsToMeetingRequestAction,
  addWorkspacesAndProjectsToMeetingFailure: addWorkspacesAndProjectsToMeetingFailureAction,

  createMeetingAgendaSuccess: createMeetingAgendaSuccessAction,
  createOnGoingMeetingAgendaSuccess: createOnGoingMeetingAgendaSuccessAction,

  guestRequestsForAccess: guestRequestsForAccessAction,
  guestRequestsForPlaseWait:guestRequestsForPlaseWaitAction,
  removeGuestRequest: removeGuestRequestAction,
  setOnGoingMeeting: setOnGoingMeetingAction,
  setSelectedMeeting: setSelectedMeetingAction,
  addAgendaToOnGoingMeeting: addAgendaToOnGoingMeetingAction,
  setDeleteAgenda: setDeleteAgendaAction,
  setUpdateAgendaDetails: setUpdateAgendaDetailsAction,
  setCreateMeetingAgenda: setCreateMeetingAgendaAction,
  updateAgendaWithId: updateAgendaWithIdAction,
  removeAgendaWithId: removeAgendaWithIdAction,
  removeSelectedMeetingAgendaWithId: removeSelectedMeetingAgendaWithIdAction,
  setIsLoading: setIsLoadingAction,

  ClintSendNotifection:ClintSendAction,
  setEndMeeting: setEndMeetingAction,
  setEndMeetingObjBlank: setEndMeetingObjBlankAction,
} = videoCenterSlice.actions;

/*
 * ======================================================
 * Export the reducer to be used in the store
 * ======================================================
 */
export default videoCenterSlice.reducer;
