import { Box, Divider, Stack } from "@mui/material";
import React, { useState } from "react";
import { SearchBar } from "../SearchBar";
import g from "./CategoryMenu.module.css";
import { adjustHexColor } from "../../../../../../../Utils";
import { CrmColorBadge } from "../../../../common";

// Define the types for the badge data
type BadgeData = {
  id: string;
  title: string;
  bgColor: string;
};

type CategoryMenuProps = {
  badges?: BadgeData[];
  onBadgeSelect?: (id: string) => void;
};

// Example of predefined data (to be replaced by API data in the future)
const predefinedBadges: BadgeData[] = [
  { id: "1", title: "random category 1", bgColor: "green" },
  { id: "2", title: "random category 2", bgColor: "blue" },
  { id: "3", title: "random category 3", bgColor: "red" },
];

const CategoryMenu: React.FC<CategoryMenuProps> = ({
  badges = predefinedBadges,
  onBadgeSelect,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredBadges = badges.filter((badge) =>
    badge.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box className={g.CategoryBox}>
      <Stack>
        <SearchBar
          containerSx={{ height: "45px", marginRight: "5px" }}
          onKeyDown={() => null}
          font={"normal normal normal 14px/20px Source Serif Pro"}
          borderLine={false}
          onSearch={setSearchTerm}
        />
        <Divider sx={{ marginX: "10px" }} />

        <Stack
          minHeight={250}
          maxHeight={280}
          sx={{
            marginTop: "10px",
            overflowX: "hidden",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none", // for Internet Explorer and Edge
            scrollbarWidth: "none", // for Firefox
          }}
          p={1}
        >
          {filteredBadges.map((badge) => (
            <Box
              key={badge.id}
              onClick={() => onBadgeSelect && onBadgeSelect(badge.id)}
              sx={{
                p: "5px 12px",
                cursor: "pointer",
                height: "35px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                "&:hover": {
                  backgroundColor: adjustHexColor(badge.bgColor, 10),
                },
              }}
            >
              <CrmColorBadge
                title={badge.title}
                bgColor={adjustHexColor(badge.bgColor, 25)}
              />
            </Box>
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};
export default CategoryMenu;
