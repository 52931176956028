import { FirstMessageIcon } from "../../../images";
import { DragAndDropMenuListType } from "../DragAndDropMenu";

export const connectCommunicationOptions: DragAndDropMenuListType[] = [
    {
        icon: FirstMessageIcon,
        label: "Text messages",
        draggable: true,
    },
    {
        icon: FirstMessageIcon,
        label: "Comment name",
        draggable: true,
    },
    {
        icon: FirstMessageIcon,
        label: "Video call",
        draggable: true,
    },
    {
        icon: FirstMessageIcon,
        label: "Audio call",
        draggable: true,
    },
    {
        icon: FirstMessageIcon,
        label: "Email",
        draggable: true,
    },
    {
        icon: FirstMessageIcon,
        label: "Other",
        draggable: true,
    },
]