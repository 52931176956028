// MsgTimelineType.tsx

export enum MsgTimelineVariantEnum {
  Sent = "sent",
  Viewed = "viewed",
  Replied = "replied",
  Forwarded = "forwarded",
  Drafted = "drafted",
}

interface BaseProps {
  PrimaryPersonName: string;
  timestamp: string;
  sendVia: string;
  location: string;
  timeZone: string;
}

export interface SendProps extends BaseProps {
  SecondaryPersonName: string;
}

export interface ViewedProps extends BaseProps {
  AttachmentOpened?: boolean;
}

export interface RepliedProps extends BaseProps {
  SecondaryPersonName: string;
}

export interface ForwardProps extends BaseProps {
  SecondaryPersonName: string;
}

export interface DraftedProps extends BaseProps {}

export type MsgTimelineViewProps =
  | ({ variant: MsgTimelineVariantEnum.Sent } & SendProps)
  | ({ variant: MsgTimelineVariantEnum.Viewed } & ViewedProps)
  | ({ variant: MsgTimelineVariantEnum.Replied } & RepliedProps)
  | ({ variant: MsgTimelineVariantEnum.Forwarded } & ForwardProps)
  | ({ variant: MsgTimelineVariantEnum.Drafted } & DraftedProps);
