import { InputBase, Stack, Typography } from "@mui/material";
import React from "react";
import { CheckboxMedium } from "../../../CheckboxMedium";
import { SmallDateRangePicker } from "../../../SmallDateRangePicker";
import styles from "./SettingView.module.css";

interface SettingViewProps {
  linkDatas?: any;
  toggleDownload?: any;
  toggleWaterMark?: any;
  toggleUpdatePassword?: any;
  toggleUpdateExpiration?: any;
  toggleAskDetails?: any;
}

const SettingView: React.FC<SettingViewProps> = ({
  linkDatas,
  toggleDownload,
  toggleWaterMark,
  toggleUpdatePassword,
  toggleUpdateExpiration,
  toggleAskDetails,
}) => {
  return (
    <Stack className={styles["setting-view"]}>
      {/* -------------------------- */}
      <Stack flexDirection={"row"}>
        <Typography className={styles["setting-view__header"]}>
          Link Settings
        </Typography>
      </Stack>
      {/* -------------------------- */}

      {/* --------------  settings here ------------------ */}
      <Stack gap={"15px"}>
        {/* -----------checkbox 1 here-------- */}
        <Stack className={styles["setting-view__item"]}>
          <Stack className={styles["setting-view__row"]}>
            <CheckboxMedium
              onChange={(e: any) => {
                toggleDownload && toggleDownload();
              }}
              checked={linkDatas?.enableWDownload}
              boxSize={25}
            />
            <Typography className={styles["setting-view__text"]}>
              Allow document to be dowloaded
            </Typography>
          </Stack>
          <Typography className={styles["setting-view__description"]}>
            This will allow users to be able to download a copy of the file,
            document, image, or video.
          </Typography>
        </Stack>
        {/* -----------checkbox 1 end here-------- */}

        {/* -----------checkbox 2 here-------- */}
        <Stack className={styles["setting-view__item"]}>
          <Stack className={styles["setting-view__row"]}>
            <CheckboxMedium
              onChange={(e: any) => {
                toggleAskDetails && toggleAskDetails();
              }}
              checked={linkDatas?.askDetails}
              boxSize={25}
            />
            <Typography className={styles["setting-view__text"]}>
              Require email and name to view
            </Typography>
          </Stack>
          <Typography className={styles["setting-view__description"]}>
            This will require the viewer to enter a name and email to view the
            document.{" "}
            <Typography
              component={"span"}
              className={styles["setting-view__italic"]}
            >
              To ensure the best possible analytics we highly recommend
              requiring this.
            </Typography>
          </Typography>
        </Stack>
        {/* -----------checkbox 2 end here-------- */}

        {/* -----------checkbox 3 here-------- */}
        <Stack className={styles["setting-view__item"]}>
          <Stack className={styles["setting-view__row"]}>
            <CheckboxMedium
              checked={linkDatas?.expirationDate === null ? false : true}
              boxSize={25}
            />
            <Stack className={styles["setting-view__row--grow"]}>
              <Typography className={styles["setting-view__text"]}>
                Add a link expiration date
              </Typography>
              <SmallDateRangePicker
                onDateChange={(date: any) => {
                  toggleUpdateExpiration && toggleUpdateExpiration(date);
                }}
              />
            </Stack>
          </Stack>
        </Stack>
        {/* -----------checkbox 3 end here-------- */}

        {/* -----------checkbox 4 here-------- */}
        <Stack className={styles["setting-view__item"]}>
          <Stack className={styles["setting-view__row"]}>
            <CheckboxMedium checked={linkDatas?.password} boxSize={25} />
            <Typography className={styles["setting-view__text"]}>
              Add a link passcode
            </Typography>
            <InputBase
              onChange={(e) => {
                toggleUpdatePassword && toggleUpdatePassword(e?.target?.value);
              }}
              placeholder="Type your passcode"
              className={styles["setting-view__input"]}
            />
          </Stack>
        </Stack>
        {/* -----------checkbox 4 end here-------- */}

        {/* -----------checkbox 5 here-------- */}
        <Stack className={styles["setting-view__item"]}>
          <Stack className={styles["setting-view__row"]}>
            <CheckboxMedium
              onChange={(e: any) => {
                toggleWaterMark && toggleWaterMark();
              }}
              checked={linkDatas?.isWatermark}
              boxSize={25}
            />
            <Typography className={styles["setting-view__text"]}>
              Add a watermark
            </Typography>
          </Stack>
          <Typography className={styles["setting-view__description"]}>
            A watermark will be placed on every page of the document that will
            identify who the viewer is
          </Typography>
        </Stack>
        {/* -----------checkbox 5 end here-------- */}
      </Stack>
      {/* --------------  settings end here ------------------ */}
    </Stack>
  );
};

export default SettingView;
