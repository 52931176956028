import { Stack, Typography } from "@mui/material";
import React from "react";
import { ValueArea } from "./view";
import { TextLinkButton } from "../../../../common";

interface AllCredentialsContainerProps {}

const AllCredentialsContainer: React.FC<
  AllCredentialsContainerProps
> = ({}) => {
  return (
    <Stack sx={{ flex: 1, gap: "20px", paddingBottom: "30px" }}>
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
        <Typography
          sx={{ font: "normal normal 400 15px/19px Source Serif Pro" }}
        >
          Credentials
        </Typography>

        <TextLinkButton buttonLabel="Update" />
      </Stack>

      <ValueArea
        cardType="Degree"
        data="Bachelors of Arts, Business - University of California, Berkeley"
      />
      <ValueArea
        cardType="Certificate"
        data="Management Certificate, Google "
      />
      <ValueArea
        cardType="Past Experience"
        data="Full Time, Senior Manager from 2002 to 2020 at Google"
      />
    </Stack>
  );
};

export default AllCredentialsContainer;
