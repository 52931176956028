import React from "react";
import { Button, IconButton, IconButtonProps } from "@mui/material";
import { ButtonType, SendMessageButtonColor } from "../AllButtonProps";
import { SendIcon } from "../../../../images";
import { adjustHexColor } from "../../../../Utils";

interface SendMessageButtonProps extends IconButtonProps {
  colorVarient?: SendMessageButtonColor;
  buttonSize?: number;
  types?: ButtonType;
  text?: string;
  onClick?: () => void;
}

const SendMessageButton: React.FC<SendMessageButtonProps> = ({
  colorVarient,
  buttonSize = 30,
  types = ButtonType.Button,
  text = "",
  onClick = () => {},
  ...props
}) => {
  let bgColor: string;

  switch (colorVarient) {
    case SendMessageButtonColor.Blue:
      bgColor = "#2A30B5";
      break;
    case SendMessageButtonColor.MessagePurple:
      bgColor = "#775EE2";
      break;
    default:
      bgColor = "#775EE2"; // Provide a default value if needed
      break;
  }
  return (
    <Button
      onClick={() => onClick && onClick()}
      sx={{
        height: text ? "40px" : buttonSize,
        width: buttonSize,
        p: 0,
        borderRadius: "5px",
        bgcolor: bgColor,
        border: "2px solid #E9EDF2",
        boxSizing: "border-box",
        textTransform: "capitalize",
        color: "white",
        font: "normal normal 500 13px/17px Source Serif Pro",
        ":hover": { bgcolor: adjustHexColor(bgColor, 90) },
      }}
      // type={types}
      // {...props}
    >
      {text && text ? text : ""}
     {!text && <SendIcon w={text ? 25 : buttonSize - 10} color="#fff" />}
    </Button>
  );
};

export default SendMessageButton;
