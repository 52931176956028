import React, { useState } from "react";
import { IconButton, Stack, Tooltip, Typography, Popover } from "@mui/material";
import { ElementsIcon, PlusIcon } from "../../../../../../../images";
import CustomField from "../CustomFields/CustomField";
import {
  AddElementPopoutFormDataInterface,
  AddElementPopoutOptions,
  elementOptionMapping,
  ElementsOptionsList,
  useCustomSnackbar,
} from "../../../../../../common";
import { CUSTOM_FIELD_LIST } from "../CustomFields/CustomFieldData";

interface CustomElementsProps {
  objectTaskData?: any;
  helperupdateStackData?: any;
}

const CustomElements: React.FC<CustomElementsProps> = ({
  helperupdateStackData,
  objectTaskData,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [fields, setFields] = useState<AddElementPopoutFormDataInterface[]>([]);
  const enqueueSnackbar_v1 = useCustomSnackbar();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (value: string) => {
    const selectedField = CUSTOM_FIELD_LIST?.find(
      (field) => field?.formType === value
    );

    if (selectedField) {
      const newFieldData: AddElementPopoutFormDataInterface = {
        fieldname: selectedField?.fieldname,
        description: selectedField?.description,
        formType: selectedField?.formType,
        additionalValue: selectedField?.additionalValue || null,
        emoji: selectedField?.emoji || null,
      };

      // Check if the field already exists in the current fields
      const fieldExistsInCurrent = fields?.some(
        (field) => field?.formType === newFieldData?.formType
      );

      // Check if the field already exists in objectTaskData's custom elements
      const fieldExistsInObjectTaskData = objectTaskData?.dataSet
        ?.customElements
        ? Object?.keys(objectTaskData?.dataSet?.customElements)?.some(
            (key) => key?.toLowerCase() === newFieldData?.formType?.toLowerCase()
          )
        : false;

      if (!fieldExistsInCurrent && !fieldExistsInObjectTaskData) {
        setFields((prev) => [...prev, newFieldData]);
      } else {
        enqueueSnackbar_v1(
          "error",
          `You already selected ${newFieldData.fieldname}`,
          {
            position: { vertical: "bottom", horizontal: "left" },
            autoHideDuration: 5000, // Optional custom auto-hide duration in milliseconds
          }
        );
      }
    }

    handleClose();
  };

  // Assuming elementOptionMapping and AddElementPopoutFormDataInterface are already defined
  // Formatting existing custom elements from objectTaskData
  const existingFields = objectTaskData?.dataSet?.customElements
    ? Object?.entries(objectTaskData?.dataSet?.customElements)?.map(
        ([key, value]) => {
          // Determine the field name based on the key using the mapping
          const fieldName =
            (
              Object?.keys(elementOptionMapping) as Array<
                keyof typeof elementOptionMapping
              >
            )?.find((enumKey) => elementOptionMapping?.[enumKey] === key) ||
            key;

          // Find the corresponding custom field in CUSTOM_FIELD_LIST
          const customField = CUSTOM_FIELD_LIST?.find(
            (field) => field?.formType === fieldName
          ) as AddElementPopoutFormDataInterface | undefined;

          return {
            fieldname: customField?.fieldname, // Original key from the object
            formType: customField?.formType, // Correctly mapped field name
            description: customField?.description || "", // Get description from CUSTOM_FIELD_LIST or fallback to an empty string
            emoji: customField?.emoji || null, // Get emoji from CUSTOM_FIELD_LIST or null if not present
            additionalValue: customField?.additionalValue || null, // Get additionalValue from CUSTOM_FIELD_LIST or null if not present
          } as AddElementPopoutFormDataInterface;
        }
      )
    : [];

  return (
    <Stack gap={"10px"}>
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack sx={{ flexDirection: "row", gap: "10px", alignItems: "center" }}>
          <ElementsIcon w={24} />
          <Typography
            sx={{ font: "normal normal normal 18px/25px Source Serif Pro" }}
          >
            Custom elements
          </Typography>
        </Stack>

        <Tooltip title="Add custom elements" placement="top" arrow>
          <IconButton sx={{ padding: 1 }} onClick={handleClick}>
            <PlusIcon w={14} />
          </IconButton>
        </Tooltip>
      </Stack>

      {/* Added elements will show here */}
      <Stack>
        <Stack
          sx={{
            height: "35px",
            border: "0.5px solid #E9EDF2",
            borderRadius: "5px 5px 0px 0px",
            flexDirection: "row",
            alignItems: "center",
            pl: "10px",
            boxSizing: "border-box",
            bgcolor: "#E9EDF2",
          }}
        >
          <Typography
            sx={{ font: "normal normal normal 14px/20px Source Serif Pro" }}
          >
            Custom fields
          </Typography>
        </Stack>

        <Stack>
          {/* Render existing fields */}
          {[...existingFields, ...fields]?.length ? (
            [...existingFields, ...fields]?.map((field, index) => (
              <CustomField
                key={index}
                objectTaskData={objectTaskData}
                helperupdateStackData={helperupdateStackData}
                data={field}
              />
            ))
          ) : (
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <p>No fields available. Please add fields to get started!</p>
            </div>
          )}
        </Stack>
      </Stack>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        {/* Pass the options for selection */}
        <ElementsOptionsList
          options={AddElementPopoutOptions}
          onSelect={handleSelect}
          onClose={handleClose}
        />
      </Popover>
    </Stack>
  );
};

export default CustomElements;
