import React from "react";

import { CommentListInterface } from "./CommentCardType";
import { Comments } from "../../../../common";

interface CommentCardProps {
  cradState?: "new" | "edit" | "Saved";
  data?: CommentListInterface[];
  parentPageId?: any;
}

// min width of this card is  --> 280px <-- max has no limit
const CommentCard: React.FC<CommentCardProps> = ({parentPageId = ""}) => {
  return (
    <>
      <Comments parentPageId={parentPageId} />
    </>
  );
};

export default CommentCard;
