// Type for regular filter options
type RegularFilterOption = {
    optionLabel?: string;
    optionValue: string[];
    divider?: false;
    coloredtext?: { colorValue: string }[]

};

type DividerFilterOption = {
    divider: true;
    optionLabel?: undefined;
    optionValue?: undefined;
};

export type FilterButtonSubListInterface = RegularFilterOption | DividerFilterOption;

export type FilterButtonListInterface =
    | {
        id: string | number;
        label: string;
        options?: FilterButtonSubListInterface[];
        divider?: false;
    }
    | {
        divider: true;
        id?: undefined;
        label?: undefined;
        options?: undefined;
    };

export const FilterButtonList: FilterButtonListInterface[] = [
    { id: 1, label: "Appointments" },
    {
        id: 2,
        label: "Meetings",
        options: [
            { optionLabel: "I’m the organizer", optionValue: ["Sent", "Draft", "Proposed"] },
            { divider: true },
            {
                optionLabel: "I’m the attendee",
                optionValue: ["Accepted", "Declined", "Maybe", "Proposed", "Cancelled", "No Response"],
            },
        ],
    },
    {
        id: 3,
        label: "Labels",
        options: [
            { optionLabel: "Labels", optionValue: ["No Label"] },
            { divider: true },
            {
                optionValue: ["Red", "Orange", "Yellow", "Green", "Purple", "Blue"],
                coloredtext: [
                    { colorValue: "#FF0000" },
                    { colorValue: "#FFB100" },
                    { colorValue: "#E0D700" },
                    { colorValue: "#0C501C" },
                    { colorValue: "#833BC3" },
                    { colorValue: "#000AFF" },
                ]
            },
        ],
    },
    { divider: true },
    {
        id: 4,
        label: "Show as",
        options: [
            { optionLabel: "Show as", optionValue: ["Free"] },
            { divider: true },
            { optionValue: ["Home Office", "Elsewhere", "Tentative", "Proposed", "Busy", "Out of Office"] },
        ],
    },
    {
        id: 5,
        label: "Recurrence",
        options: [
            { optionLabel: "Recurrence", optionValue: ["Single"] },
            { divider: true },
            { optionValue: ["Recurring"] },
        ],
    },
];
