import React from "react";
import { Stack } from "@mui/material";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

interface SidebarMiniCalenderProps {}

const SidebarMiniCalender: React.FC<SidebarMiniCalenderProps> = ({}) => {
  return (
    <Stack>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          sx={{
            bgcolor: "white",
            height: "235px",
            width: "185px",
            "& .MuiPickersCalendarHeader-root": {
              height: "30px",
              minHeight: "unset",
              padding: "0px 0px 0px 3px",
              gap: 0,
            },
            "& .MuiPickersDay-root": {
              width: "22px",
              height: "22px",
              // fontSize: "0.75rem",
              fontWeight: "300",
            },
            "& .MuiTypography-caption": {
              fontSize: "0.6rem", // Smaller font size for days of the week
            },
            "& .MuiPickersSlideTransition-root": {
              minHeight: "unset",
              height: "160px", // Adjust height of the calendar area
            },
            "& .MuiPickersArrowSwitcher-root": {
              width: "75px",
              display: "flex",
              justifyContent: "space-between",
              padding: "0px",
            },
            "& .MuiPickersCalendarHeader-label": {
              fontSize: "0.75rem",
            },
            "& .MuiYearCalendar-root": {
              width: "185px",
              scrollbarWidth: "none",
            },
            "& .MuiPickersYear-yearButton": {
              width: "58px",
            },
          }}
        />
      </LocalizationProvider>
    </Stack>
  );
};

export default SidebarMiniCalender;
