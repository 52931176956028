import { Link, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { AvatarNormal } from "../AvatarNormal";
import styles from "./NamePlateCloudStorage.module.css";

interface NamePlateCloudStorageProps {
  nameOrEmail: string;
  userIconSrc?: string;
  timeStamp: string;
  dateStamp: string;
  visitTime: string;
  visitLink: string;
  location: string;
  agent: string;
  downloaded: boolean;
}

const NamePlateCloudStorage: React.FC<NamePlateCloudStorageProps> = ({
  agent,
  dateStamp,
  downloaded,
  location,
  nameOrEmail,
  timeStamp,
  visitLink,
  visitTime,
  userIconSrc,
}) => {
  const [showDetails, setShowDetails] = useState<boolean>(false);

  const stopPropagation = (event: React.MouseEvent | React.KeyboardEvent) => {
    event.stopPropagation();
  };

  return (
    <Stack className={styles.namePlateCloudStorage}>
      <Stack
        onClick={() => setShowDetails((value) => !value)}
        className={styles.namePlateCloudStorage__header}
      >
        <AvatarNormal size={40} username={nameOrEmail} imgSrc={userIconSrc} />
        <Stack className={styles.namePlateCloudStorage__info}>
          {/* ------   name and time  ----- */}
          <Stack className={styles.namePlateCloudStorage__row}>
            <Typography className={styles.namePlateCloudStorage__name}>
              {nameOrEmail}
            </Typography>
            <Typography className={styles.namePlateCloudStorage__time}>
              {visitTime}
            </Typography>
          </Stack>

          {/* -------------  timestamp and link info here  --------- */}
          <Stack className={styles.namePlateCloudStorage__row}>
            <Typography className={styles.namePlateCloudStorage__timestamp}>
              {dateStamp} at {timeStamp}
            </Typography>
            <Link
              underline="hover"
              className={styles.namePlateCloudStorage__link}
              onMouseDown={stopPropagation}
              onClick={stopPropagation}
            >
              <Typography className={styles.namePlateCloudStorage__linkText}>
                viewed via {visitLink}
              </Typography>
            </Link>
          </Stack>
        </Stack>
      </Stack>

      {/* ---------  details area show when on click on this parent container  ---------- */}
      <Stack
        className={
          showDetails
            ? styles.namePlateCloudStorage__detailsVisible
            : styles.namePlateCloudStorage__detailsHidden
        }
      >
        <Stack className={styles.namePlateCloudStorage__details}>
          {/* location info here */}
          <Stack className={styles.namePlateCloudStorage__detailsRow}>
            <Typography className={styles.namePlateCloudStorage__detailsLabel}>
              Location
            </Typography>
            <Typography className={styles.namePlateCloudStorage__detailsValue}>
              <span className={styles.namePlateCloudStorage__scrollText}>
                {location}
              </span>
            </Typography>
          </Stack>
          {/* ------  agent info  ----- */}
          <Stack className={styles.namePlateCloudStorage__detailsRow}>
            <Typography className={styles.namePlateCloudStorage__detailsLabel}>
              Agent
            </Typography>
            <Typography className={styles.namePlateCloudStorage__detailsValue}>
              <span className={styles.namePlateCloudStorage__scrollText}>
                {agent}
              </span>
            </Typography>
          </Stack>
          {/* ------  Downloaded info  ----- */}
          <Stack className={styles.namePlateCloudStorage__detailsRow}>
            <Typography className={styles.namePlateCloudStorage__detailsLabel}>
              Downloaded
            </Typography>
            <Typography className={styles.namePlateCloudStorage__detailsValue}>
              {downloaded ? "Yes" : "No"}
            </Typography>
          </Stack>
          {/* ------  Page info  ----- */}
          <Stack className={styles.namePlateCloudStorage__detailsRow}>
            <Typography className={styles.namePlateCloudStorage__detailsLabel}>
              Page One
            </Typography>
            <Typography className={styles.namePlateCloudStorage__detailsValue}>
              {visitTime}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default NamePlateCloudStorage;
