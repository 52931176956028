import React, { useState, MouseEvent } from "react";
import { Divider, IconButton, Stack, Typography, Popover } from "@mui/material";
import { AllDirArrowCircleIcon, FiltersIcon } from "../../../../../images";
import { CheckboxMedium } from "../../../../common";
import {
  FilterButtonList,
  FilterButtonListInterface,
  FilterButtonSubListInterface,
} from "./FilterButtonTypes";
import { FilterOptionView } from "./view";
import styles from "./FilterButton.module.css";
import { FilterCriteria, updateFilterCriteria } from "../../../../../redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

interface FilterButtonProps {
  option?: FilterButtonListInterface[];
  onChange: (value: FinalSelectedOptionsProps[]) => void;
}

interface SelectedPrimaryListProps {
  id: number | string;
  label: string;
}

export interface SelectedSecondaryListProps {
  FilterLabel: string;
  selectedOptions: {
    role?: string;
    value: string[];
  }[];
}

interface FinalSelectedOptionsProps {
  id: number | string;
  label: string;
  selectedOptions?: {
    role?: string;
    value: string[];
  }[];
}

const FilterButton: React.FC<FilterButtonProps> = ({
  onChange,
  option = FilterButtonList,
}) => { const { t } = useTranslation();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const [openedOptions, setOpenedOptions] = useState<
    FilterButtonSubListInterface[]
  >([]);
  const [openedFilterLabel, setOpenedFilterLabel] = useState<string>("");

  const [selectedPrimaryList, setSelectedPrimaryList] = useState<
    SelectedPrimaryListProps[]
  >([]);
  const [selectedSecondaryList, setSelectedSecondaryList] = useState<
    SelectedSecondaryListProps[]
  >([]);

  const [finalSelectedList, setFinalSelectedList] =
    useState<FinalSelectedOptionsProps[]>();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    if (!popoverAnchorEl) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleOptionClick = (
    event: MouseEvent<HTMLElement>,
    options: FilterButtonSubListInterface[]
  ) => {
    setPopoverAnchorEl(event.currentTarget);
    setOpenedOptions(options);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPopoverAnchorEl(null);

    // Generate finalSelectedList based on selectedPrimaryList and selectedSecondaryList
    const mergedList = selectedPrimaryList.map((primary) => {
      const secondary = selectedSecondaryList.find(
        (item) => item.FilterLabel === primary.label
      );

      // Create the final selected options, removing the role if it is undefined
      const selectedOptions = secondary?.selectedOptions.map((option) => ({
        ...(option.role ? { role: option.role } : {}), // Include role only if it exists
        value: option.value,
      }));

      return {
        id: primary.id,
        label: primary.label,
        selectedOptions,
      };
    });

    setFinalSelectedList(mergedList);
    onChange(mergedList);

    // Initialize filter criteria
    const newFilterCriteria: Partial<FilterCriteria> = {};

    // Check for specific selections and update filter criteria accordingly
    if (selectedPrimaryList?.some((item) => item?.label === "Meetings")) {
      newFilterCriteria.eventType = "meeting"; // Set to meeting if selected
    }
    if (selectedPrimaryList?.some((item) => item?.label === "Appointments")) {
      newFilterCriteria.eventType = "appointment"; // Set to appointment if selected
    }
    if (selectedPrimaryList?.some((item) => item?.label === "Show as")) {
      // Check for specific values from selectedSecondaryList related to "Show as"
      const showAsOptions = selectedSecondaryList?.find(
        (item) => item?.FilterLabel === "Show as"
      );
      if (showAsOptions) {
        // Assuming we have a value we want to add, for example:
        newFilterCriteria.allDay = showAsOptions?.selectedOptions?.some(
          (option) => option?.value?.includes("Free")
        );
        // Update as per your requirement, possibly iterate over values if needed.
      }
    }

    // Add logic for other filter criteria as needed
    if (selectedPrimaryList?.some((item) => item?.label === "Recurrence")) {
      newFilterCriteria.repeat = "daily"; // For example, set repeat to daily
    }

    // Call the updateFilterCriteria action
    dispatch(updateFilterCriteria(newFilterCriteria));
  };

  const handleClosePopover = () => {
    setPopoverAnchorEl(null);
  };

  const handleCheckboxChange = (id: number | string, label: string) => {
    setSelectedPrimaryList((prev) => {
      const exists = prev.some((item) => item.id === id);

      if (exists) {
        return prev.filter((item) => item.id !== id);
      } else {
        return [...prev, { id, label }];
      }
    });
  };

  const open = Boolean(anchorEl);
  const openPopover = Boolean(popoverAnchorEl);

  return (
    <Stack className={styles["filter-button"]}>
      <IconButton
        className={styles["filter-button__icon-button"]}
        onClick={handleClick}
        disableRipple
      >
        <FiltersIcon w={15} />
        <Typography className={styles["filter-button__label"]}>
          {t("CALENDAR.FILTER")}
        </Typography>
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            marginTop: "10px",
          },
        }}
      >
        <Stack className={styles["filter-button__popover"]}>
          {option?.map((item, index) =>
            item?.divider ? (
              <Divider key={`divider-${index}`} orientation="horizontal" />
            ) : (
              <Stack
                key={`option-${index}`}
                className={styles["filter-button__option"]}
              >
                <Stack
                  className={styles["filter-button__option-content"]}
                  onClick={() => handleCheckboxChange(item.id, item.label)}
                >
                  <CheckboxMedium
                    boxSize={16}
                    checked={selectedPrimaryList.some(
                      (selected) => selected.id === item.id
                    )}
                  />
                  <Typography className={styles["filter-button__option-label"]}>
                    {item.label}
                  </Typography>
                </Stack>

                {item.options && (
                  <IconButton
                    sx={{ p: 0 }}
                    disableRipple
                    onClick={(event) => {
                      setOpenedFilterLabel(item.label);
                      handleOptionClick(event, item.options || []);
                      event.stopPropagation();
                    }}
                  >
                    <AllDirArrowCircleIcon w={15} direction="right" />
                  </IconButton>
                )}
              </Stack>
            )
          )}
        </Stack>
      </Popover>

      <Popover
        open={openPopover}
        anchorEl={popoverAnchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          className: styles["filter-button__popover-details"],
        }}
      >
        <FilterOptionView
          optionList={openedOptions}
          FilterLabel={openedFilterLabel}
          secondaryList={selectedSecondaryList}
          setSecondaryList={setSelectedSecondaryList}
        />
      </Popover>
    </Stack>
  );
};

export default FilterButton;
