export type DragAndDropMenuListType = {
  id?: string | number;
  icon?: React.ElementType;
  label?: string;
  translatedWord?: string;
  draggable?: boolean;
  subList?: string[];
  divider?: boolean;value?: string;  // Optional value key for internal field references
};

export type AscendingDropdownOptionsType = {
  value: string;
  translatedWord: string;
}
export type SortAndFilterType = "Ascending" | "Descending";

export const SortAndFilterValue: AscendingDropdownOptionsType[] = [
  { value: "Ascending", translatedWord: "TRAX.ASSENDING" },
  { value: "Descending", translatedWord: "TRAX.DESCENDING" },
];

export type SortSelectedListType = {
  dragable?: boolean;
  selectedItem: DragAndDropMenuListType;
  order: SortAndFilterType;
};

export type FilterCondition = "where" | "and" | "or";
export const FilterConditionOptions: FilterCondition[] = ["where", "and", "or"];

export type FilterOperator =
  | "contains"
  | "not contains"
  | "starts with"
  | "ends with"
  | "is"
  | "is not"
  | "empty"
  | "not empty";

export const FilterOperatorOptions: AscendingDropdownOptionsType[] = [
  { value: "contains", translatedWord: "TRAX.CONTAINS" },
  { value: "not contains", translatedWord: "TRAX.NOT_CONTAINS" },
  { value: "starts with", translatedWord: "TRAX.STARTS_WITH" },
  { value: "ends with", translatedWord: "TRAX.ENDS_WITH" },
  { value: "is", translatedWord: "TRAX.IS" },
  { value: "is not", translatedWord: "TRAX.IS_NOT" },
  { value: "empty", translatedWord: "TRAX.EMPTY" },
  { value: "not empty", translatedWord: "TRAX.NOT_EMPTY" },
];

export type FilterCriteriaType = {
  condition: FilterCondition;
  selectedItem: DragAndDropMenuListType;
  operator: FilterOperator;
  filterInput?: string;
};
