import { Box, Stack } from "@mui/material";
import React from "react";
import { DateIcon } from "../../../../../../../../images";
import { UserCount } from "../../../../EmailCardComponents";
import { TouchPointVariantEnum, TouchPointViewProps } from "../TouchPointsType";

type CalenderEventsInnerViewProps = Extract<
  TouchPointViewProps,
  { variant: TouchPointVariantEnum.CalendarEvent }
>;

const CalenderEventsInnerView: React.FC<CalenderEventsInnerViewProps> = ({
  eventName,
  platform,
  timeZone,
  timestamp,
  usersList,
}) => {
  return (
    <Stack sx={{ flexDirection: "row", alignItems: "start", gap: "15px" }}>
      <Box sx={{ flexShrink: 0 }}>
        <DateIcon w={14} />
      </Box>
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "4px",
          flexWrap: "wrap",
          font: "normal normal 300 13px/16px Source Serif Pro",
        }}
      >
        <span>{eventName}</span>
        <span>with</span>

        <UserCount
          userList={usersList}
          sx={{ font: "inherit", color: "black" }}
        />
        <span>on</span>
        <span>{timestamp}</span>
        <span>{timeZone}</span>
        <span>via</span>
        <span>{platform}</span>
      </Stack>
    </Stack>
  );
};

export default CalenderEventsInnerView;
