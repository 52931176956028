import { TemplateIcon } from "../../../images";
import { DragAndDropMenuListType } from "../DragAndDropMenu";

export const allTemplateList: DragAndDropMenuListType[] = [
    {
        icon: TemplateIcon,
        label: "Template name one",
        draggable: true,
    },
    {
        icon: TemplateIcon,
        label: "Template name two",
        draggable: true,
    },
    {
        icon: TemplateIcon,
        label: "Template name three",
        draggable: true,
    },
    {
        icon: TemplateIcon,
        label: "Template name four",
        draggable: true,
    },
    {
        icon: TemplateIcon,
        label: "Template name five",
        draggable: true,
    },
    {
        icon: TemplateIcon,
        label: "Template name six",
        draggable: true,
    },
]