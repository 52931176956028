import { Stack, Typography } from "@mui/material";
import React from "react";
import {
  LocationBoldIcon,
  VideoCallFilledIcon,
} from "../../../../../../../images";
import {
  DropDownShortLarge,
  DropdownWIconLarge,
} from "../../../../../../common";
import LocationAutocomplete from "../../../../../../common/LocationAutocomplete/LocationAutocomplete";
import { useTranslation } from "react-i18next";

interface LocationViewProps {
  updateField?: any;
}

const LocationView: React.FC<LocationViewProps> = ({ updateField }) => {
  const { t } = useTranslation();
  const handlePlatformChange = (label: string) => {
    if (!updateField) {
      return;
    }

    // Define the mapping for platform labels to API values
    const platformMap: Record<string, string> = {
      "Bearish OS Video Calls": "BearishOS",
      Zoom: "zoom",
      "Google Meet": "googleMeet",
      "Cisco Webex": "ciscoWebex",
      "Microsoft Teams": "microsoftTeams",
    };

    // Check if the selected label is a predefined platform
    if (platformMap[label]) {
      // If it's a known platform, update accordingly
      updateField("videoCall", {
        platform: platformMap[label],
        customLink: null,
      });
    } else {
      // If it's not a known platform, treat it as a custom link
      updateField("videoCall", { platform: "custom", customLink: label });
    }
  };

  return (
    <Stack sx={{ gap: "15px" }}>
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
        <VideoCallFilledIcon w={20} />
        <DropDownShortLarge
          onChange={(value) => handlePlatformChange(value)}
          placeholder={t("CALENDAR.CALL_LOCATION")}
          borderColor="lightBlue"
          icon="chevron"
        />
      </Stack>
      <Typography sx={{ font: "normal normal 300 15px/19px Source Serif Pro" }}>
        {t("or")}
      </Typography>
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
        <LocationBoldIcon w={20} />
        <LocationAutocomplete
          onChange={(v: any) => {
            updateField && updateField("location", v);
          }}
        />
      </Stack>
    </Stack>
  );
};

export default LocationView;
