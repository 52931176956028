import { IconButton, Stack } from "@mui/material";
import React, { useState } from "react";
import {
  IconPopupCalendarList,
  IconPopupCalendarListInterface,
  IconPopupCalendarIconsEnum,
  getIconPopupCalendarIcons,
} from "./IconPopupCalendarTypes";
import { adjustHexColor } from "../../../../../Utils";

interface IconPopupCalendarProps {
  option?: IconPopupCalendarListInterface[];
  disabledIcons?: IconPopupCalendarIconsEnum[];
  onSelect: (iconName: IconPopupCalendarIconsEnum) => void;
}

const IconPopupCalendar: React.FC<IconPopupCalendarProps> = ({
  onSelect,
  option = IconPopupCalendarList,
  disabledIcons = [],
}) => {
  const [disabledIconsList, setDisabledIconsList] =
    useState<IconPopupCalendarIconsEnum[]>(disabledIcons);

  return (
    <Stack
      sx={{
        flexDirection: "row",
        flexWrap: "wrap",
        width: "305px",
        bgcolor: "white",
        borderRadius: "5px",
        boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
        gap: "5px",
        padding: "10px 5px",
        boxSizing: "border-box",
      }}
    >
      {option.map((item, index) => {
        const isDisabled = disabledIconsList.some(
          (value) => value === item.iconName
        );

        return (
          <span style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}>
            <IconButton
              disabled={isDisabled}
              sx={{ p: "5px" }}
              onClick={() => !isDisabled && onSelect(item.iconName)}
              key={index}
            >
              {React.createElement(getIconPopupCalendarIcons(item.iconName), {
                w: 15,
                color: isDisabled ? adjustHexColor("#000000", 25) : "#000000",
              })}
            </IconButton>
          </span>
        );
      })}
    </Stack>
  );
};

export default IconPopupCalendar;
