import { Stack } from "@mui/material";
import React from "react";
import MiniDetailsCard from "../../MiniDetailsCard/MiniDetailsCard";

interface DefaultQuickEmailViewProps {}

const DefaultQuickEmailView: React.FC<DefaultQuickEmailViewProps> = ({}) => {
  return (
    <Stack sx={{ gap: "10px", flexGrow: 1 }}>
      {/* --- map function here --- */}
      <MiniDetailsCard
        userName="John Doe"
        cardSubTitle="Subject line here"
        timeStamp="10-9-2023 at 03:55 AM"
      />
      <MiniDetailsCard
        userName="username 1"
        cardSubTitle="default text here"
        timeStamp="10-9-2023 at 03:55 AM"
      />
      <MiniDetailsCard
        userName="username2"
        cardSubTitle="example text here"
        timeStamp="10-9-2023 at 03:55 AM"
      />
    </Stack>
  );
};

export default DefaultQuickEmailView;
