import { Box, Fade } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { CrmComponentMap } from "./OverViewTypes"; // Adjust import as needed
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  CloudStorageState,
  getAllBusiness__api,
  getAllContacts__api,
  getAllDeals__api,
  getAllFiles__api,
  getAllGroups__api,
  getAllOutlookContacts__api,
  getAllWorkspace__api,
  recallAPI__CRM,
  reCallCRM_APIs_service,
} from "../../../../redux";

const OverView: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  /* CRM Center */
  const crmState = useSelector((state: RootState) => state && state?.crm);
  const { reCallAPIs, sortingDetails, filteringDetails } = crmState || {};

  /* Cloud Storage */
  const cloudStorageState = useSelector(
    (state: RootState) => state?.cloudStorage as CloudStorageState
  );

  const { bearishOSCloudStorage } = cloudStorageState || {};

  /* Workspace Center */

  const workspaceState = useSelector(
    (state: RootState) => state && state?.workspace
  );
  const { workspaceList } = workspaceState || {};
  const { workspaceList: workspaces = [] } = workspaceList || {};

  const { pathname } = location;

  // Extract base path and ID from pathname
  const basePath = pathname.split("/")[2]; // e.g., "people", "company", "deal"
  const id = pathname.split("/")[3]; // e.g., "personId", "companyId", "dealId"

  const fetchData = async (apiFunc: any, payload?: any) => {
    try {
      const action = apiFunc(payload);
      await dispatch(action);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllContact = async () => {
    // Preparing the payload without stringifying
    const payload = {
      page: 1,
      limit: 100,
      sort: sortingDetails?.contacts?.map((sort) => ({
        field: sort?.field,
        order: sort?.order,
      })),
      filters: filteringDetails?.contacts?.map((filter) => ({
        field: filter?.field,
        conditions: filter?.conditions?.map((condition) => ({
          type: condition?.type,
          value: condition?.value,
          conjunction: condition?.conjunction,
        })),
      })),
    };

    // console.log("Sending Payload:", payload);

    await fetchData(getAllContacts__api, payload);
  };

  const getAllBusiness = async () => {
    const payload = {
      page: 1,
      limit: 100,
      sort: sortingDetails?.companies?.map((sort) => ({
        field: sort?.field,
        order: sort?.order,
      })),
      filters: filteringDetails?.companies?.map((filter) => ({
        field: filter?.field,
        conditions: filter?.conditions?.map((condition) => ({
          type: condition?.type,
          value: condition?.value,
          conjunction: condition?.conjunction,
        })),
      })),
    };

    await fetchData(getAllBusiness__api, payload);
  };

  const getAllDeals = async () => {
    const payload = {
      page: 1,
      limit: 100,
      sort: sortingDetails?.deals?.map((sort) => ({
        field: sort?.field,
        order: sort?.order,
      })),
      filters: filteringDetails?.deals?.map((filter) => ({
        field: filter?.field,
        conditions: filter?.conditions?.map((condition) => ({
          type: condition?.type,
          value: condition?.value,
          conjunction: condition?.conjunction,
        })),
      })),
    };

    await fetchData(getAllDeals__api, payload);
  };

  const getAllGroups = async () => {
    const payload = {
      page: 1,
      limit: 100,
      sort: sortingDetails?.groups?.map((sort) => ({
        field: sort?.field,
        order: sort?.order,
      })),
      filters: filteringDetails?.groups?.map((filter) => ({
        field: filter?.field,
        conditions: filter?.conditions?.map((condition) => ({
          type: condition?.type,
          value: condition?.value,
          conjunction: condition?.conjunction,
        })),
      })),
    };

    await fetchData(getAllGroups__api, payload);
  };

  const loginOutLook = async () => {
    console.log("login Outlook");
  };
  const getOutLookContact = async () => {
    try {
      // Retrieve the access token from local storage
      const accessToken = localStorage.getItem("outlookAccessToken");

      // Create the payload
      const payload = {
        accessToken: accessToken || "", // Provide a fallback if the token is not found
      };

      // Make the API call
      await fetchData(getAllOutlookContacts__api, payload);
    } catch (error) {
      console.error("Error fetching Outlook contacts:", error);
    }
  };

  const getAllFileFolders = async () => {
    await dispatch(getAllFiles__api());
  };
  
  const getAllWorkspace = async () => {
    try {
      await dispatch(getAllWorkspace__api());
    } catch (err) {
      console.error("Error from state:", err);
    }
  };

  useEffect(() => {
    if (bearishOSCloudStorage?.length === 0) {
      getAllFileFolders();
    }

    if (workspaces?.length === 0) {
      getAllWorkspace();
    }
  }, []);

  useEffect(() => {
    const apiActions: Record<
      NonNullable<reCallCRM_APIs_service>,
      () => Promise<void>
    > = {
      All: async () => {
        await Promise.all([
          getAllContact(),
          getAllBusiness(),
          getAllDeals(),
          getAllGroups(),
          getOutLookContact(),
        ]);
        dispatch(recallAPI__CRM(null));
      },
      getAllContacts: async () => {
        await getAllContact();
        await getOutLookContact();
        dispatch(recallAPI__CRM(null));
      },
      getAllCompanies: async () => {
        await getAllBusiness();
        dispatch(recallAPI__CRM(null));
      },
      getAllDeals: async () => {
        await getAllDeals();
        dispatch(recallAPI__CRM(null));
      },
      getAllGroups: async () => {
        await getAllGroups();
        dispatch(recallAPI__CRM(null));
      },
      loginOutLookContacts: async () => {
        await loginOutLook();
        dispatch(recallAPI__CRM(null));
      },
    };

    // Ensure that the hook runs unconditionally, but only execute logic if `reCallAPIs` exists and is valid
    if (reCallAPIs && apiActions[reCallAPIs]) {
      apiActions[reCallAPIs]();
    }
    // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [reCallAPIs]);

  // Determine the component to render based on the current path
  let Component = null;
  switch (basePath) {
    case "people":
      Component = CrmComponentMap["/CRM/people"];
      break;
    case "company":
      Component = CrmComponentMap["/CRM/company"];
      break;
    case "deal":
      Component = CrmComponentMap["/CRM/deal"];
      break;
    case "group":
      Component = CrmComponentMap["/CRM/group"];
      break;
    default:
      // Handle unknown paths
      return <Navigate to="/404" />;
  }

  // Prepare props based on the path
  const props = { [`${basePath}Id`]: id };

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Fade in={true} timeout={{ enter: 200, exit: 200 }}>
        <Box sx={{ width: "100%" }}>
          {Component ? <Component {...props} /> : null}
        </Box>
      </Fade>
    </Box>
  );
};

export default OverView;
