import { Stack } from "@mui/material";
import React from "react";
import { MediumSearchbar } from "../../../common";
import { TraxStatusTabs } from "../components";
import { useTranslation } from "react-i18next";

interface StatusProps {}

const Status: React.FC<StatusProps> = ({}) => {
  const { t } = useTranslation();
  return (
    <Stack mt={"33px"} px={"30px"}>
      <Stack sx={{ flexDirection: "row", justifyContent: "end" }}>
        <MediumSearchbar ph={t("TRAX.SEARCH_ALL_TRAX")} />
      </Stack>
      <Stack sx={{ height: "calc(100vh - 135px)", marginTop: "-5px" }}>
        <TraxStatusTabs />
      </Stack>
    </Stack>
  );
};

export default Status;
