export const adjustHexColor = (color: string, adjustment: number): string => {
  // Clamp the adjustment value between 0 and 200
  adjustment = Math.max(0, Math.min(200, adjustment));

  const hexToRgb = (hex: string) => {
    hex = hex.replace("#", "");
    let alpha = 1;

    if (hex.length === 8) {
      // Handle hex colors with alpha (#RRGGBBAA)
      alpha = parseInt(hex.slice(6, 8), 16) / 255;
      hex = hex.slice(0, 6); // Remove alpha part for further processing
    } else if (hex.length === 4) {
      // Handle short hex colors with alpha (#RGBA)
      alpha = parseInt(hex.slice(3, 4).repeat(2), 16) / 255;
      hex = hex.slice(0, 3); // Remove alpha part for further processing
    } else if (hex.length === 3) {
      hex = hex
        .split("")
        .map((char) => char + char)
        .join("");
    }

    const bigint = parseInt(hex, 16);
    return {
      r: (bigint >> 16) & 255,
      g: (bigint >> 8) & 255,
      b: bigint & 255,
      a: alpha,
    };
  };

  const rgbToHex = (r: number, g: number, b: number) => {
    const componentToHex = (c: number) => c.toString(16).padStart(2, "0");
    return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
  };

  const parseRgbString = (rgbString: string) => {
    const result = rgbString.match(/\d+/g);
    if (result) {
      return {
        r: parseInt(result[0]),
        g: parseInt(result[1]),
        b: parseInt(result[2]),
        a: result[3] ? parseFloat(result[3]) : 1,
      };
    }
    return null;
  };

  let r: number,
    g: number,
    b: number,
    a = 1;

  if (color?.startsWith("#")) {
    const rgb = hexToRgb(color);
    r = rgb.r;
    g = rgb.g;
    b = rgb.b;
    a = rgb.a; // Capture alpha from hex if present
  } else if (color?.startsWith("rgb")) {
    const rgb = parseRgbString(color);
    if (rgb) {
      r = rgb.r;
      g = rgb.g;
      b = rgb.b;
      a = rgb.a;
    } else {
      throw new Error("Invalid RGB(A) color format");
    }
  } else {
    // Handle named colors
    const tempElement = document.createElement("div");
    tempElement.style.color = color;
    document.body.appendChild(tempElement);
    const computedColor = getComputedStyle(tempElement).color;
    document.body.removeChild(tempElement);
    const rgb = parseRgbString(computedColor);
    if (rgb) {
      r = rgb.r;
      g = rgb.g;
      b = rgb.b;
      a = rgb.a;
    } else {
      throw new Error("Invalid named color format");
    }
  }

  if (adjustment <= 100) {
    // Adjust transparency - Output in rgba format
    const newAlpha = (adjustment / 100) * a; // Adjust existing alpha value
    return `rgba(${r}, ${g}, ${b}, ${newAlpha.toFixed(2)})`;
  } else {
    // Darken color
    const factor = 1 - (adjustment - 100) / 100;
    r = Math.floor(r * factor);
    g = Math.floor(g * factor);
    b = Math.floor(b * factor);

    // Clamp values to the range [0, 255]
    r = Math.max(0, Math.min(255, r));
    g = Math.max(0, Math.min(255, g));
    b = Math.max(0, Math.min(255, b));

    return rgbToHex(r, g, b);
  }
};
