import {IconButton, Stack, Tooltip, Typography} from "@mui/material";
import React from "react";
import {NumberView} from "./view";
import {EditPencilIcon, TrashIcon} from "../../../../../images";
import {formatTimeAllotted} from "../../../../../Utils/timeConvertedIntoFromSec";
import {useTranslation} from "react-i18next";

interface AgendaItemChipProps {
    width?: string;
    agendaDetails?: any;
    index?: number;
    onEdit?: (key: string) => void;
    onDelete?: (key: string) => void;
    userId?: string;
    iseditable?: boolean;
}

const AgendaItemChip: React.FC<AgendaItemChipProps> = ({
                                                           width = "100%",
                                                           agendaDetails,
                                                           index = 1,
                                                           onEdit,
                                                           onDelete,
                                                           userId,
                                                           iseditable=true,
                                                       }) => {
    const {t} = useTranslation()
    const formattedTime = formatTimeAllotted(agendaDetails?.timeAllotted);
    return (
        <Stack
            sx={{
                height: "105px",
                width: width,
                boxSizing: "border-box",
                borderRadius: "5px",
                border: "2px solid #FF8181",
                bgcolor: "white",
                padding: "10px 15px 0px 10px",
                gap: "5px",
            }}
        >
            {/* header items */}
            <Stack
                sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "40px",
                }}
            >
                <Stack sx={{flexDirection: "row", alignItems: "center", gap: "10px"}}>
                    <NumberView orderNum={index + 1}/>
                    <Stack>
                        <Typography
                            sx={{
                                font: "normal normal 400 13px/16px Source Serif Pro",
                                display: "-webkit-box",
                                WebkitLineClamp: 1, // Limit to 2 lines
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}
                        >
                            {agendaDetails?.title}
                        </Typography>
                    </Stack>
                </Stack>
                {iseditable && userId === agendaDetails?.meetingHostId && (
                    <Stack
                        sx={{flexDirection: "row", alignItems: "center", gap: "5px"}}
                    >
                        <Tooltip title={t('VIDEO_CENTER.MEETING.EDIT')} placement="top" arrow>
                            <IconButton onClick={() => onEdit && onEdit(agendaDetails?._id)}>
                                <EditPencilIcon w={20}/>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={t('VIDEO_CENTER.MEETING.DELETE')} placement="top" arrow>
                            <IconButton
                                onClick={() => onDelete && onDelete(agendaDetails?._id)}
                            >
                                <TrashIcon w={20}/>
                            </IconButton>
                        </Tooltip>
                    </Stack>
                )}
            </Stack>

            {/* details here */}
            <Stack sx={{flexDirection: "row", justifyContent: "space-between"}}>
                <Stack
                    sx={{
                        width: "240px",
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: "Source Serif Pro",
                            fontWeight: 300,
                            fontSize: "11px",
                            lineHeight: "14px", // Increased line-height for better readability
                            display: "-webkit-box",
                            WebkitLineClamp: 2, // Limit to 2 lines
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    >
                        {agendaDetails?.description}
                    </Typography>
                </Stack>

                <Stack sx={{gap: "7px", alignItems: "end"}}>
                    {iseditable && userId === agendaDetails?.meetingHostId && (
                        <IconButton
                            sx={{
                                p: 0,
                                borderRadius: 0,
                                color: "#0008C6",
                                width: "fit-content",
                            }}
                            disableRipple
                        >
                            <Typography
                                sx={{
                                    font: "normal normal 300 11px/14px Source Serif Pro",
                                    color: "#0008C6",
                                }}
                            >
                                {t('VIDEO_CENTER.MEETING.ADD_ATTACHMENTS')}
                            </Typography>
                        </IconButton>
                    )}

                    <Stack
                        sx={{
                            flexDirection: "row",
                            width: "160px",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography
                            sx={{
                                font: "normal normal 400 11px/14px Source Serif Pro",
                            }}
                        >
                            {t('VIDEO_CENTER.MEETING.TIME_ALLOTTED')}
                        </Typography>{" "}
                        <Typography
                            sx={{
                                font: "normal normal 300 11px/14px Source Serif Pro",
                            }}
                        >
                            {formattedTime}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default AgendaItemChip;
