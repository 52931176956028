import React from "react";
import { Box } from "@mui/material";
import { AvatarNormal } from "../AvatarNormal";
import { adjustHexColor } from "../../../Utils";

interface AvatarGroupedProps {
  childAvatars: {
    username: string;
    userIconSrc?: string;
    avatarColor?: string;
  }[];
}

const AvatarGrouped: React.FC<AvatarGroupedProps> = ({ childAvatars }) => {
  const containerSize = 30; // Main group size in pixels
  const childGroupSize = 10; // Child avatar size in pixels
  const radius = 30; // Radius for positioning child avatars

  // Limit the number of child avatars to 6
  const limitedChildAvatars = childAvatars.slice(0, 6);

  const calculatePosition = (index: number, total: number) => {
    const angle = (2 * Math.PI * index) / total;
    return {
      top: `${50 + radius * Math.sin(angle)}%`,
      left: `${50 + radius * Math.cos(angle)}%`,
      transform: "translate(-50%, -50%)",
    };
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
      width={`${containerSize}px`}
      height={`${containerSize}px`}
      boxSizing={"border-box"}
      borderRadius={"50px"}
      border={`1px solid ${adjustHexColor("#E9EDF2", 100)}`}
      bgcolor={`${adjustHexColor("#775EE2", 25)}`}
    >
      {limitedChildAvatars.length === 1 ? (
        // If there's only one avatar, center it
        <AvatarNormal
          size={childGroupSize}
          username={limitedChildAvatars[0].username}
          imgSrc={limitedChildAvatars?.[0]?.userIconSrc}
          avatarColor={limitedChildAvatars[0].avatarColor}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            border: "0.25px solid #E9EDF2",
          }}
        />
      ) : (
        // Otherwise, position avatars in a circle
        limitedChildAvatars.map((avatar, index) => (
          <AvatarNormal
            key={index}
            size={childGroupSize}
            username={avatar.username}
            imgSrc={avatar?.userIconSrc}
            avatarColor={avatar.avatarColor}
            sx={{
              position: "absolute",
              boxSizing: "border-box",
              border: "0.25px solid #E9EDF2",
              ...calculatePosition(index, limitedChildAvatars.length),
            }}
          />
        ))
      )}
    </Box>
  );
};

export default AvatarGrouped;
