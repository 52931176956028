import React from 'react';

interface CreatePlanEventViewProps {
  
}

const CreatePlanEventView: React.FC<CreatePlanEventViewProps> = ({  }) => {
  return <div>CreatePlanEventView</div>;
};

export default CreatePlanEventView;