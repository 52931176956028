import { Button, ButtonProps, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../Utils";
import { PlayArrowIcon, PlayIcon } from "../../../../../images";

interface BlackLongArrowButtonProps extends ButtonProps {}

const BlackLongArrowButton: React.FC<BlackLongArrowButtonProps> = ({
  sx,
  ...props
}) => {
  return (
    <Button
      variant="text"
      sx={{
        width: "100%",
        height: "25px",
        textTransform: "none",
        bgcolor: "black",
        borderRadius: "5px",
        boxSizing: "border-box",
        color: "white",
        opacity: 1,
        ":hover": { bgcolor: adjustHexColor("#000000", 85), opacity: 1 },
        ...sx,
      }}
      {...props}
    >
      <PlayArrowIcon direction="down" color="#fff" w={18} />
    </Button>
  );
};

export default BlackLongArrowButton;
