import { Stack, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../Utils";
import { GreenArrowButton } from "../Buttons";
import styles from "./LinkPreviewCloudStorage.module.css";
import dayjs from "dayjs"; // import dayjs for formatting
import localizedFormat from "dayjs/plugin/localizedFormat"; // for localized format

dayjs.extend(localizedFormat);

interface LinkPreviewCloudStorageProps {
  linkVersion: number;
  linkName: string;
  dateStamp: string;
  onClickLink?: any;
}

const LinkPreviewCloudStorage: React.FC<LinkPreviewCloudStorageProps> = ({
  dateStamp,
  linkName,
  linkVersion,
  onClickLink,
}) => {
  // Convert dateStamp to "December 24, 2023, at 12:10 AM" format
  const formattedDate = dayjs(dateStamp)?.format("MMMM D, YYYY");
  const formattedTime = dayjs(dateStamp)?.format("h:mm A");

  return (
    <Stack
      className={styles["link-preview"]}
      sx={{
        border: `1px solid ${adjustHexColor("#008D4C", 25)}`,
        bgcolor: adjustHexColor("#E9EDF2", 25),
      }}
    >
      <Stack className={styles["link-preview__inner"]}>
        {/* colored box */}
        <Stack className={styles["link-preview__box"]}>
          <Typography className={styles["link-preview__version"]}>
            L{linkVersion}
          </Typography>
        </Stack>
        {/* ---------- */}
        <Stack className={styles["link-preview__text"]}>
          <Typography className={styles["link-preview__name"]}>
            {linkName}
          </Typography>
          <Typography className={styles["link-preview__timestamp"]}>
            {formattedDate}, at {formattedTime}
          </Typography>
        </Stack>
      </Stack>

      <GreenArrowButton onClick={() => onClickLink()} sx={{ p: 0 }} />
    </Stack>
  );
};

export default LinkPreviewCloudStorage;
