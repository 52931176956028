import React from "react";
import g from "./SuspenseState.module.css";
import { Box, Stack } from "@mui/material";
import { BearishIcon } from "../../images";

type SuspenseStateProps = {
  colors?: string;
};

const SuspenseState: React.FC<SuspenseStateProps> = ({
  colors = "#00B188",
}) => {
  return (
    <Box
      sx={{
        bgcolor: "#EEEEEE",
      }}
    >
      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        flexDirection={"row"}
        minHeight={"95vh"}
        minWidth={"100%"}
        className={g.infiniteSpin}
      >
        <BearishIcon width={150} height={150} color={colors} />
      </Stack>
    </Box>
  );
};

export default SuspenseState;
