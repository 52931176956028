import React from "react";
import { ColoredBadge } from "../../../../common";
import { adjustHexColor } from "../../../../../Utils";

interface CrmColorBadgeProps {
  title: string;
  bgColor: string;
}

const CrmColorBadge: React.FC<CrmColorBadgeProps> = ({ bgColor, title }) => {
  return (
    <ColoredBadge
      title={title}
      sx={{ height: "20px", p: "0px 10px" }}
      bgColor={bgColor}
    />
  );
};

export default CrmColorBadge;
