import React, { useState, useRef } from "react";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { XCircleIcon } from "../../../images";
import { ButtonSmall } from "../Buttons";
import { ButtonType } from "../Buttons/AllButtonProps";
import { FilePreviewWithCheckbox } from "../FilePreviewWithCheckbox";
import styles from "./UploadNewVersion.module.css";
import { useDispatch } from "react-redux";
import { uploadNewVersion__api } from "../../../redux";

interface UploadNewVersionProps {
  fileId?: string;
  onClose?: any;
}

const UploadNewVersion: React.FC<UploadNewVersionProps> = ({
  fileId = "",
  onClose,
}) => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isDragOver, setIsDragOver] = useState(false);

  const dispatch = useDispatch();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setUploadedFile(event.target.files[0]);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      setUploadedFile(event.dataTransfer.files[0]);
    }
  };

  const handleUploadNewVersion = async () => {
    // Prepare files
    const formData = new FormData();

    if (uploadedFile) {
      formData.append("files", uploadedFile);
      const newUploadVersionRes: any = await dispatch(
        uploadNewVersion__api(fileId, formData)
      );

      if (newUploadVersionRes?.success) {
        if (onClose) {
          onClose();
        }
      }
    }
  };

  return (
    <Stack className={styles["uploadContainer"]}>
      <Stack className={styles["uploadHeader"]}>
        <Typography className={styles["uploadTitle"]}>
          Upload new version
        </Typography>
        <Tooltip title="Close" placement="top" arrow>
          <IconButton
            onClick={() => onClose && onClose()}
            className={styles["uploadCloseButton"]}
            disableRipple
          >
            <XCircleIcon color="#000" />
          </IconButton>
        </Tooltip>
      </Stack>

      {!uploadedFile ? (
        // ---- file upload area here ----
        <Stack
          className={`${styles["uploadArea"]} ${
            isDragOver ? styles["uploadArea--dragOver"] : ""
          }`}
          onClick={() => fileInputRef.current?.click()}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <Typography className={styles["uploadText"]}>
            Click here or Drag and Drop your updated version here
          </Typography>
        </Stack>
      ) : (
        // ---- Selected file here ----
        <Stack className={styles["filePreview"]}>
          {/* ----------- use map function here if more than one file is selected -----------*/}
          <FilePreviewWithCheckbox
            fileName={uploadedFile.name}
            isChecked={false}
            onCheckBoxClick={() => null}
          />
          {/* ----------------------*/}
        </Stack>
      )}

      {/* ---- button here ---- */}
      <Stack className={styles["uploadButton"]}>
        <ButtonSmall
          onClick={() => handleUploadNewVersion()}
          label="Upload"
          types={ButtonType.Button}
        />
      </Stack>
      {/* --------------------- */}
    </Stack>
  );
};

export default UploadNewVersion;
