import { Typography } from "@mui/material";
import React from "react";

interface TableTextAreaProps {
  value: string;
}

const TableTextArea: React.FC<TableTextAreaProps> = ({ value }) => {
  return (
    <Typography
      sx={{
        font: "normal normal 400 14px/18px Source Serif Pro",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {value}
    </Typography>
  );
};

export default TableTextArea;
