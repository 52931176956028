import { Button, Tooltip, Typography } from "@mui/material";
import React from "react";
import { FilterCondition } from "../../MenuTypes";
import { UpdateIcon } from "../../../../../images";
import { useTranslation } from "react-i18next";

interface ConditionSelectorProps {
  initialValue: FilterCondition;
  onChange: (value: FilterCondition) => void;
}

const ConditionSelector: React.FC<ConditionSelectorProps> = ({
  initialValue,
  onChange,
}) => {
  const { t } = useTranslation();

  const getProperty = (): {
    label: string;
    translatedWord: string;
    icon?: React.ElementType;
    clickFunction: () => void;
    clickable: boolean;
    hovertext?: string;
  } => {
    switch (initialValue) {
      case "where":
        return {
          label: "Where",
          translatedWord: "TRAX.WHERE",
          clickFunction: () => null,
          clickable: false,
        };
      case "and":
        return {
          label: "And",
          translatedWord: "TRAX.AND",
          icon: UpdateIcon,
          clickable: true,
          clickFunction: () => onChange("or"),
          hovertext: t("TRAX.SWITCH_TO_OR"),
        };
      case "or":
        return {
          label: "Or",
          translatedWord: "TRAX.OR",
          icon: UpdateIcon,
          clickable: true,
          clickFunction: () => onChange("and"),
          hovertext: t("TRAX.SWITCH_TO_AND"),
        };
      default:
        return {
          label: "Where",
          translatedWord: "TRAX.WHERE",
          clickable: false,
          clickFunction: () => null,
        };
    }
  };

  const property = getProperty();

  return (
    <Tooltip
      title={property.hovertext || ""}
      placement="top"
      arrow
      disableInteractive
      disableHoverListener={!property.clickable}
    >
      <Button
        variant="text"
        onClick={property.clickFunction}
        sx={{
          textTransform: "none",
          width: "75px",
          height: "25px",
          border: "1px dashed #E9EDF2",
          borderRadius: "5px",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          boxSizing: "border-box",
          paddingLeft: "5px",
          gap: "5px",
        }}
        disableRipple={!property.clickable}
        aria-label={property.label}
      >
        <Typography
          sx={{
            font: "normal normal normal 13px/16px Source Serif Pro",
            color: "black",
          }}
        >
          {t(property.translatedWord)}
        </Typography>
        {property.icon &&
          React.createElement(property.icon, { width: 15, color: "black" })}
      </Button>
    </Tooltip>
  );
};

export default ConditionSelector;
