import React, { useState, useMemo, useCallback } from "react";
import { InputBase, Paper, List, ListItem, Typography } from "@mui/material";
import { InternalSharedButton } from "../Buttons/InternalSharedButton";
import S from "./PersonDropdown.module.css";
import { AvatarNamePlateSmall } from "../AvatarNamePlateSmall";
import { InternalShareButtonOptionValue } from "../Buttons/AllButtonProps";
import { getUserList } from "../../../Utils";

interface PersonOption {
  id: number;
  name: string;
  userName: string;
  imgSrc?: string;
  avatarColor?: string;
  teamName: string;
  department: string;
  _id?: string;
  recipientEmail?: string;
}

interface PersonDropdownProps {
  selectedData: (data: {
    userData: PersonOption;
    action: InternalShareButtonOptionValue;
    customEmail?: string; // Optional custom email
  }) => void;
  resetSelection: () => void;
  selectedOption: string;
}

const PersonDropdown: React.FC<PersonDropdownProps> = ({
  selectedData,
  resetSelection,
  selectedOption,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<PersonOption | null>(null);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [customEmail, setCustomEmail] = useState<string>("");

  const userList = getUserList();

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
      setDropdownVisible(event.target.value?.length > 0);
      setCustomEmail(""); // Reset custom email when typing
      // setButtonDisabled(event.target.value.length === 0);
    },
    []
  );

  const handleItemSelect = useCallback((item: PersonOption) => {
    setSelectedItem(item);
    setInputValue(item.userName);
    setDropdownVisible(true);
    setButtonDisabled(false);
  }, []);

  const filteredItems = useMemo(
    () =>
      userList?.filter((item: any) =>
        item?.email.toLowerCase()?.includes(inputValue?.toLowerCase())
      ),
    [inputValue, userList]
  );

  const handleSelectionChange = useCallback(
    (value: string) => {
      if (selectedItem) {
        selectedData({
          userData: selectedItem,
          action: value as InternalShareButtonOptionValue,
        });
        resetSelection();
      } else if (customEmail) {
        // Extract username and name from email
        const emailUsername = customEmail?.split("@")?.[0]; // Extract part before '@'

        // Name modification: for example, split by numbers and use only the alphabetic part
        const name = emailUsername?.replace(/\d/g, ""); // Remove numbers for a cleaner name

        // Handle custom email invitation
        selectedData({
          userData: {
            name: name || emailUsername, // Use the cleaned name or fallback to username
            userName: emailUsername, // Set username as the part before '@'
            teamName: "",
            department: "",
            recipientEmail: customEmail, // Store the email for API use
            id: 0,
          },
          action: value as InternalShareButtonOptionValue,
          customEmail,
        });
        resetSelection();
      }

      setInputValue(""); // Reset input value
      setSelectedItem(null);
      setButtonDisabled(true);
      setDropdownVisible(false); // Close the dropdown
    },
    [selectedItem, selectedData, resetSelection, customEmail]
  );

  return (
    <div className={S["person-dropdown__container"]}>
      <div className={S["person-dropdown__input-container"]}>
        <InputBase
          className={S["person-dropdown__input"]}
          sx={{ height: "30px", marginRight: "10px" }}
          value={inputValue}
          onChange={handleInputChange}
          onFocus={() => setDropdownVisible(inputValue?.length > 0)}
          placeholder="Select a person or enter an email"
          aria-haspopup="listbox"
        />
        <InternalSharedButton
          disabled={buttonDisabled}
          selectedOption={selectedOption}
          userRole={"Editor"}
          resetSelection={resetSelection}
          onSelectionChange={handleSelectionChange}
        />
      </div>
      <Paper
        className={S["person-dropdown__dropdown"]}
        sx={{
          display:
            dropdownVisible && (filteredItems?.length > 0 || inputValue)
              ? "block"
              : "none",
        }}
        role="listbox"
      >
        <List>
          {filteredItems?.map((item: any, index: number) => (
            <ListItem
              key={index}
              className={`${S["person-dropdown__list-item"]} ${
                selectedItem && selectedItem.id === item.id
                  ? S["person-dropdown__list-item--selected"]
                  : ""
              }`}
              onClick={() => handleItemSelect(item)}
              role="option"
              aria-selected={
                selectedItem && selectedItem._id === item._id ? true : false
              }
            >
              <AvatarNamePlateSmall
                size="default"
                userName={item?.firstName + item?.lastName}
                imgSrc={item?.imgSrc}
                avatarColor={item?.avatarColor || "orange"}
                teamName={item?.teamName || "default Team"}
                department={item?.department || "default Department"}
                sx={{ width: "100%" }}
              />
            </ListItem>
          ))}
          {/* Show the option to invite via email if the input is not in the filtered list */}
          {filteredItems?.length === 0 && inputValue && (
            <ListItem
              className={S["person-dropdown__list-item"]}
              onClick={() => {
                setCustomEmail(inputValue);
                setButtonDisabled(false);
              }}
            >
              <Typography
                sx={{
                  font: "normal normal 13px/17px Source Serif Pro",
                  color: "black",
                }}
              >
                Invite "{" "}
                <span
                  style={{
                    font: "normal normal 600 13px/17px Source Serif Pro",
                    color: "orange",
                  }}
                >
                  {inputValue}
                </span>{" "}
                " via email
              </Typography>
            </ListItem>
          )}
        </List>
      </Paper>
    </div>
  );
};

export default PersonDropdown;
