import React, { useState } from "react";
import { Typography } from "@mui/material";

interface DetailsViewProps {
  text: string;
}

const DetailsView: React.FC<DetailsViewProps> = ({ text }) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const toggleExpand = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <Typography
      onClick={toggleExpand}
      sx={{
        font: "normal normal 300 11px/14px Source Serif Pro",
        display: "-webkit-box",
        overflow: "hidden",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: expanded ? "none" : 2, // Toggle between showing all or 2 lines
        cursor: "pointer",
      }}
    >
      {text}
    </Typography>
  );
};

export default DetailsView;
