import React from "react";
import { CreateNewMessages } from "../components";

const CreateConnection: React.FC = () => {
  return (
    <>
      <div>
        <CreateNewMessages />
      </div>
    </>
  );
};

export default CreateConnection;
