import { RadioGroup, Stack, Typography } from "@mui/material";
import React from "react";
import { AllDirArrowsIcon } from "../../../../../images";
import {
  ButtonSmall,
  ButtonType,
  CustomRadioFormControlLabel,
  SmallButtonColor,
  SmallSearchFolder,
} from "../../../../common";
import styles from "./MoveEmail.module.css";
import { Dummy_SmallSearchFolderList } from "../../../../common/SmallSearchFolder/SmallSearchFolderType";

interface MoveEmailProps {}

const MoveEmail: React.FC<MoveEmailProps> = () => {
  const [selectedValue, setSelectedValue] = React.useState<any>("");

  const handleBodyRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedValue(event.target.value);
  };

  return (
    <Stack className={styles["move-email"]}>
      <Stack className={styles["move-email__header"]}>
        <Stack className={styles["move-email__icon-text"]}>
          <AllDirArrowsIcon w={15} direction="right" />
          <Typography className={styles["move-email__title"]}>
            Move email to...
          </Typography>
        </Stack>
        <Typography className={styles["move-email__description"]}>
          Select the folder you want to move your email to.
        </Typography>
      </Stack>

      <RadioGroup
        aria-labelledby="subjectKeyword-radio-buttons-group"
        name="subjectKeyword-radio-buttons-group"
        value={selectedValue}
        onChange={handleBodyRadioChange}
        className={styles["move-email__radio-group"]}
      >
        <CustomRadioFormControlLabel
          value="Folder name one"
          label="Folder name one"
        />
        <CustomRadioFormControlLabel
          value="Folder name Two"
          label="Folder name Two"
        />
        <CustomRadioFormControlLabel
          value="Folder name Three"
          label="Folder name Three"
        />
      </RadioGroup>

      <SmallSearchFolder
        placeholder="Search all folders"
        options={Dummy_SmallSearchFolderList}
        onSelect={(item) => null}
      />

      <Stack className={styles["move-email__button-container"]}>
        <ButtonSmall
          label="Save"
          types={ButtonType.Button}
          colorVarient={SmallButtonColor.Blue}
        />
      </Stack>
    </Stack>
  );
};

export default MoveEmail;
