import { Box, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import {
  AngelListIcon,
  EmailIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  PhoneOutlineIcon,
  XIcon,
} from "../../../../../../../images";

interface ContactDetailsViewProps {
  imageSrc: string;
  companyName: string;
  emailId: string;
  phoneNumber: string;
  hideSocialButtons: boolean;
}

const ContactDetailsView: React.FC<ContactDetailsViewProps> = ({
  companyName,
  emailId,
  imageSrc,
  phoneNumber,
  hideSocialButtons = false,
}) => {
  return (
    <Stack
      sx={{
        flex: 1,
        flexDirection: "row",
        gap: "10px",
        alignItems: "center",
        paddingTop: "5px",
      }}
    >
      <Stack
        sx={{
          height: "45px",
          width: "45px",
          borderRadius: "100px",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid #E9EDF2",
        }}
      >
        <Box
          component={"img"}
          src={imageSrc}
          alt="company img"
          sx={{ height: "43px", width: "43px", borderRadius: "50px" }}
        />
      </Stack>
      <Stack gap={"5px"}>
        <Typography
          sx={{ font: "normal normal 400 13px/16px Source Serif Pro" }}
        >
          {companyName}
        </Typography>
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <EmailIcon w={15} />
            <Typography
              sx={{
                font: "normal normal 400 11px/14px Source Serif Pro",
                maxWidth: "320px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {emailId}
            </Typography>
          </Stack>

          <Box
            sx={{
              height: "5px",
              width: "5px",
              borderRadius: "10px",
              bgcolor: "#E9EDF2",
            }}
          />

          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <PhoneOutlineIcon w={15} />
            <Typography
              sx={{
                font: "normal normal 400 11px/14px Source Serif Pro",
              }}
            >
              {phoneNumber}
            </Typography>
          </Stack>

          {!hideSocialButtons && (
            <Box
              sx={{
                height: "5px",
                width: "5px",
                borderRadius: "10px",
                bgcolor: "#E9EDF2",
              }}
            />
          )}

          {!hideSocialButtons && (
            <Stack
              sx={{ flexDirection: "row", alignItems: "center", gap: "6px" }}
            >
              <IconButton sx={{ p: "2px" }}>
                <AngelListIcon w={15} />
              </IconButton>

              <IconButton sx={{ p: "2px" }}>
                <FacebookIcon w={15} />
              </IconButton>

              <IconButton sx={{ p: "2px" }}>
                <InstagramIcon w={15} />
              </IconButton>

              <IconButton sx={{ p: "2px" }}>
                <XIcon w={15} />
              </IconButton>

              <IconButton sx={{ p: "2px" }}>
                <LinkedinIcon w={15} />
              </IconButton>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ContactDetailsView;
