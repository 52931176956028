import { Stack, Typography } from "@mui/material";
import React from "react";
import { AvatarNormal } from "../../../../common";
import {
  employeePreviewIdHandler,
  employeeDrawerHandler,
} from "../../../../../redux";
import { useDispatch } from "react-redux";

interface EmpPreviewAreaProps {
  value: any;
  empId: string;
}
const EmpPreviewArea: React.FC<EmpPreviewAreaProps> = ({ value, empId }) => {
  const dispatch = useDispatch();

  const clickHandler = (e: React.MouseEvent, eId: string) => {
    e.preventDefault();
    e.stopPropagation(); // Prevents background clicks
    dispatch(employeePreviewIdHandler(eId));
    dispatch(employeeDrawerHandler(true));
  };

  return (
    <Stack
      onClick={(e) => clickHandler(e, empId)}
      sx={{
        flexDirection: "row",
        cursor: "pointer",
        gap: "10px",
        font: "normal normal 400 14px/18px Source Serif Pro",
        ":hover": { font: "normal normal 600 14px/18px Source Serif Pro" },
      }}
    >
      <AvatarNormal
        size={15}
        username={value.name}
        imgSrc={value.imgSrc}
        sx={{ borderRadius: "5px" }}
      />
      <Typography
        sx={{
          font: "inherit",
        }}
      >
        {value.fullName}
      </Typography>
    </Stack>
  );
};

export default EmpPreviewArea;
