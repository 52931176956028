import React from "react";
import { EmployeePreviewPopupLarge } from "../../../components";
import { Drawer, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/rootReducer";
import {
  employeePreviewIdHandler,
  employeeDrawerHandler,
} from "../../../../../../redux";

interface EmployeeDrawerProps {}

const EmployeeDrawer: React.FC<EmployeeDrawerProps> = ({}) => {
  const traxState = useSelector((state: RootState) => state && state.trax);
  const { popupEmployeeDataId, showEmpPreviewDrawer } = traxState || {};
  const dispatch = useDispatch();

  const removeEmpData = () => {
    dispatch(employeePreviewIdHandler(null));
    dispatch(employeeDrawerHandler(false));
  };

  return (
    <Drawer
      anchor={"right"}
      open={!!popupEmployeeDataId && showEmpPreviewDrawer}
      onClose={removeEmpData}
      sx={{ zIndex: "1210 !important" }}
    >
      <Stack sx={{ width: "80vw", height: "100vh" }}>
        <EmployeePreviewPopupLarge onPreviewButtonClick={removeEmpData} />
      </Stack>
    </Drawer>
  );
};

export default EmployeeDrawer;
