import React from "react";
import { ColoredBadge } from "../../../../common";
import { SxProps } from "@mui/material";

interface TraxTextBadgeProps {
  label: string;
  bgcolor: string;
  colorOpacity?: number;
  fontColor?: string;
  sx?: SxProps;
}

const TraxTextBadge: React.FC<TraxTextBadgeProps> = ({
  bgcolor,
  label,
  colorOpacity = 65,
  fontColor = "#ffffff",
  sx,
}) => {
  return (
    <ColoredBadge
      title={label}
      bgColor={bgcolor}
      ColoredBadgeFont={"normal normal 400 11px/14px Source Serif Pro"}
      bgColorOpacity={colorOpacity}
      sx={{
        height: "20px",
        padding: "0px 10px",
        borderRadius: "30px",
        color: fontColor,
        ...sx,
      }}
    />
  );
};

export default TraxTextBadge;
