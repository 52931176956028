import { Button, Stack, SxProps, Typography } from "@mui/material";
import React from "react";
import { ArrowAllIcon, StoreIcon } from "../../../images";
import styles from "./IconDropDownButton.module.css";

interface IconDropDownButtonProps {
  menuOpen?: boolean;
  icon?: React.ElementType;
  iconColor?: string;
  label: string;
  numCount?: number;
  sx?: SxProps;
  onButtonClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const IconDropDownButton: React.FC<IconDropDownButtonProps> = ({
  menuOpen = false,
  label,
  numCount,
  icon,
  iconColor = "black",
  onButtonClick,
  sx,
}) => {
  return (
    <Button
      onClick={onButtonClick}
      className={styles.iconDropDownButton}
      id="basic-button"
      aria-controls={menuOpen ? "basic-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={menuOpen ? "true" : undefined}
      sx={{
        width: "fit-content",
        minWidth: "125px !important",
        p: 0,
        gap: "5px",
        ...sx,
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "5px",
          flexGrow: 0,
          width: "calc(100% - 20px)",
        }}
      >
        {icon && React.createElement(icon, { w: 15, color: iconColor })}
        <Typography
          className={styles.iconDropDownButton_label}
          children={label}
          sx={{
            whiteSpace: "nowrap",
            width: icon ? "calc(100% - 20px)" : "100%",
          }}
        />
      </Stack>
      <Stack
        sx={{
          flexShrink: 0,
          flexDirection: "row",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <Typography
          sx={{ font: "normal normal 900 14px/20px Source Serif Pro" }}
        >
          {numCount}
        </Typography>

        <ArrowAllIcon w={18} open={menuOpen} />
      </Stack>
    </Button>
  );
};

export default IconDropDownButton;
