import React, { useState } from "react";
import { IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import {
  AddToMeetingMenuListInterface,
  AddToMeetingMenuOptionList,
} from "./AddToMeetingMenuType";
import { useTranslation } from "react-i18next";

interface AddToMeetingMenuProps {
  options?: AddToMeetingMenuListInterface[];
  onSelect?: (value: string) => void;
}

const AddToMeetingMenu: React.FC<AddToMeetingMenuProps> = ({
  onSelect,
  options = AddToMeetingMenuOptionList,
}) => {
    const {t}=useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clickHandler = (value: string) => {
    if (onSelect) {
      onSelect(value);
    }
    handleClose();
  };

  return (
    <Stack>
      <IconButton
        sx={{ p: 0, borderRadius: 0 }}
        disableRipple
        onClick={handleClick}
      >
        <Typography
          sx={{
            font: "normal normal 400 15px/19px Source Serif Pro",
            color: "#0008C6",
          }}
        >
            {t("VIDEO_CENTER.MEETING.ADD_MEETING")}
        </Typography>
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            padding: 0,
            width: "205px",
            marginTop: "10px",
            zIndex: 100,
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.16)",
          },
        }}
      >
        {options.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => clickHandler(item.value)}
            sx={{
              height: "35px",
              flexDirection: "row",
              alignItems: "center",
              minHeight: 0,
              padding: "0px 0px 0px 15px",
              boxSizing: "border-box",
              gap: "5px",
            }}
          >
            <item.icon w={20} color={"#000"} />
            <Typography
              sx={{ font: "normal normal 300 15px/19px Source Serif Pro" }}
            >
              {t(item.LANG)}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
};

export default AddToMeetingMenu;
