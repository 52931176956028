import { Box, Link, Modal, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { VersionCloudStorage } from "../../../VersionCloudStorage";
import { UploadNewVersion } from "../../../UploadNewVersion";

interface VersionHistoryViewProps {
  fileId?: string;
}

const VersionHistoryView: React.FC<VersionHistoryViewProps> = ({
  fileId = "",
}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [versionCardSelected, setVersionCardSelected] = useState(false);
  return (
    <Stack gap={"15px"}>
      {/* ------------------------ */}
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "start",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            font: "normal normal 400 14px/18px Source Sarif Pro",
            paddingBottom: "5px",
            borderBottom: "1px solid #E9EDF2",
          }}
        >
          Version History
        </Typography>

        <Link
          sx={{
            color: "#000AFF",
            cursor: "pointer",
            font: "normal normal 400 14px/18px Source Sarif Pro",
          }}
          underline="hover"
          onClick={() => handleOpen()}
        >
          Upload new version
        </Link>
      </Stack>
      {/* ------------------------ */}
      {/* ------------------------ */}
      <Stack>
        <VersionCloudStorage
          vNumber={10}
          dateStamp="May 10th 2024"
          timeStamp="10:45 PM"
          isClicked={versionCardSelected}
          onContainerClick={() => setVersionCardSelected((val) => !val)}
          storageSize="14 GB"
          title="Latest Version"
          uploadedBy="Jenny"
        />
      </Stack>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <UploadNewVersion onClose={handleClose} fileId={fileId} />
        </Box>
      </Modal>

      {/* ------------------------ */}
    </Stack>
  );
};

export default VersionHistoryView;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 0,
};
