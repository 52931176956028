import { Stack } from "@mui/material";
import React from "react";

import {
  SlackPng,
  StreamIconBearishOSIconPng,
} from "../../../../../../images";
import { MessageNameCard } from "../../../../MessageNameCard";
import { useSelector } from "react-redux";
import { MessagesState } from "../../../../../../redux/Slice/message/messageTypes";
import { RootState } from "../../../../../../redux/store";
import Loader from "../../../../Loader/Loader";
import { AuthState } from "../../../../../../redux";
import {MEETING_ENUMS} from "../../../../../pages/Message/constant";

interface DefaultQuickMsgViewProps {
  channelsArray?: any;
}

const DefaultQuickMsgView: React.FC<DefaultQuickMsgViewProps> = ({
  channelsArray,
}) => {
  const { currentChannelId } = useSelector(
    (state: RootState) => state.message as MessagesState
  );
  const { user } = useSelector((state: RootState) => state.auth as AuthState);
  if (!channelsArray) return <Loader />;

  return (
    <Stack sx={{ width: "100%", gap: "5px" }}>
      {Object.values(channelsArray || {}).map((data: any, index: number) => {
        if (data.isPinned) return null;
        return (
          <MessageNameCard
            key={index}
            lastMessage={data?.lastMessage || ""}
            channelDetails={data}
            userData={user}
            companyImgSrc={
              data.channelType === MEETING_ENUMS.SLACK
                ? SlackPng
                : StreamIconBearishOSIconPng
            }
            userName={data.displayName} 
            channelId={data?._id}
            channelName={data.displayName}
            isSelected={data._id === currentChannelId}
          />
        );
      })}
    </Stack>
  );
};

export default DefaultQuickMsgView;
