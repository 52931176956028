import { Avatar, Stack, Typography, Tooltip } from "@mui/material";
import React from "react";
import { SxProps, Theme } from "@mui/system";
import { AvatarNormal } from "../AvatarNormal";

// Define your props, including sx
interface AvatarNamePlateSmallProps {
  userName: string;
  imgSrc?: string;
  avatarColor?: string;
    teamName?: string;
    department?: string;
    size?: "default" | "small";
    sx?: any; // Use SxProps from MUI for the sx prop
}

const AvatarNamePlateSmall: React.FC<AvatarNamePlateSmallProps> = ({
  userName,
  imgSrc,
  avatarColor,
  teamName,
  department,
  size = "default", // You can set default values if needed
  sx = {},
}) => {
  return (
    <Stack
      sx={{
        flexDirection: "row" as const, // Explicitly type `flexDirection`
        gap: "10px",
        alignItems: "center",
        height: size === "default" ? "40px" : "31px",
        width: size === "default" ? "194px" : "153px",
        ...sx, // Spread the sx prop
      }}
    >
      <AvatarNormal
        size={30}
        username={userName}
        avatarColor={avatarColor}
        imgSrc={imgSrc}
      />
      <Stack sx={{ flex: 1, minWidth: 0 }}>
        <Tooltip title={userName} placement="top" arrow>
          <Typography
            sx={{
              font:
                size === "default"
                  ? "normal normal normal 15px/21px Source Serif Pro"
                  : "normal normal normal 11px/15px Source Serif Pro",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              flex: 1,
              minWidth: 0,
            }}
          >
            {userName}
          </Typography>
        </Tooltip>
        <Tooltip title={`${teamName}, ${department}`} placement="top" arrow>
          <Typography
            sx={{
              font:
                size === "default"
                  ? "normal normal normal 12px/16px Source Serif Pro"
                  : "normal normal normal 10px/13px Source Serif Pro",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              flex: 1,
              minWidth: 0,
            }}
          >
            {teamName}, {department}
          </Typography>
        </Tooltip>
      </Stack>
    </Stack>
  );
};

export default AvatarNamePlateSmall;
