import { Stack, Popover } from "@mui/material";
import React, { useState } from "react";
import { DottedButton } from "../DottedButton";
import { TemplateIcon } from "../../../images";
import { DragAndDropMenu, DragAndDropMenuListType } from "../DragAndDropMenu";
import { useTranslation } from "react-i18next";

interface ViewTemplatesNotesProps {
  options?: DragAndDropMenuListType[];
}

const ViewTemplatesNotes: React.FC<ViewTemplatesNotesProps> = ({
}) => {
    const {t}=useTranslation()
    const allTemplateList: DragAndDropMenuListType[] = [
        {
            icon: TemplateIcon,
            label: t('VIDEO_CENTER.MEETING.TEMPLATE_NAME_ONE'),
            draggable: true,
        },
        {
            icon: TemplateIcon,
            label: t('VIDEO_CENTER.MEETING.TEMPLATE_NAME_TWO'),
            draggable: true,
        },
        {
            icon: TemplateIcon,
            label: t('VIDEO_CENTER.MEETING.TEMPLATE_NAME_THREE'),
            draggable: true,
        },
        {
            icon: TemplateIcon,
            label: t('VIDEO_CENTER.MEETING.TEMPLATE_NAME_FOUR'),
            draggable: true,
        },
        {
            icon: TemplateIcon,
            label: t('VIDEO_CENTER.MEETING.TEMPLATE_NAME_FIVE'),
            draggable: true,
        },
        {
            icon: TemplateIcon,
            label: t('VIDEO_CENTER.MEETING.TEMPLATE_NAME_SIX'),
            draggable: true,
        }
    ]
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOptions, setMenuOptions] =
    useState<DragAndDropMenuListType[]>(allTemplateList);

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "popover" : undefined;

  return (
    <Stack>
      <DottedButton
        icon={TemplateIcon}
        title={t('VIDEO_CENTER.MEETING.VIEW_ALL_TEMPLATES')}
        aria-describedby={id}
        onClick={handleButtonClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            padding: 0,
            width: "250px",
            boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.16)",
            marginTop: "-10px",
          },
        }}
      >
        <DragAndDropMenu
          menuItems={menuOptions}
          setMenuItems={(newValue) => setMenuOptions(newValue)}
          dragIconPlace="left"
          label={t('VIDEO_CENTER.MEETING.TEMPLATES')}
        />
      </Popover>
    </Stack>
  );
};

export default ViewTemplatesNotes;
