import { Container, Typography, Grid, Paper } from "@mui/material";
import React from "react";

const IntelligentConnection = () => {
  const connections = [
    {
      name: "Google Gmail",
      description: "Connect and handle gmail from within Bearish OS",
    },
    { name: "Outlook Email", description: "Connect to Microsoft Outlook" },
    {
      name: "Exchange Mail Management",
      description: "Enterprise access to Microsoft Exchange mail",
    },
    {
      name: "Microsoft Exchange Mail",
      description: "Enterprise access to Microsoft Exchange Mail",
    },
    {
      name: "Google Calendar",
      description: "Connect and handle google calendar within Bearish OS",
    },
    {
      name: "Outlook Calendar",
      description: "Connect to Microsoft Outlook Calendar",
    },
    {
      name: "Desktop Notifications",
      description: "Receive notifications directly on your device",
    },
    { name: "Box", description: "Manage all your Box documents and such" },
    {
      name: "Google Drive",
      description: "All your google drive documents in Bearish OS",
    },
    {
      name: "Google Meet",
      description: "Create, plan, and have meetings on Meet",
    },
    {
      name: "Cisco Webex",
      description: "Create, plan, and have meetings in Webex",
    },
    { name: "Teams", description: "Create, plan, and have meetings in Teams" },
  ];

  return (
    <Container
      style={{
        fontFamily: "Source Serif Pro",
        paddingTop: "20px",
        maxWidth: "95%",
        margin: "auto",
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        style={{
          fontWeight: "bold",
          fontSize: "28px",
          marginBottom: "10px",
          color: "#333",
          fontFamily: "Source Serif Pro",
        }}
      >
        Intelligent Connections
      </Typography>
      <Typography
        variant="subtitle1"
        style={{
          color: "#666",
          fontSize: "16px",
          lineHeight: "1.5",
          marginBottom: "20px",
          fontFamily: "Source Serif Pro",
          maxWidth: "585px",
        }}
      >
        Create and disconnect intelligent connections to all the apps you
        already use and love. Missing an app? We add new apps every week on
        Fridays.
      </Typography>

      <Grid container spacing={2}>
        {connections.map((connection, index) => (
          <Grid item xs={5} sm={6} key={index}>
            <Paper
              elevation={5}
              style={{
                padding: "15px",
                borderRadius: "5px",
                display: "flex",
                flexDirection: "column",
                height: "100%",
                border:"0.5px solid #E9EDF2",
                boxShadow:"none"
              }}
            >
              <Typography
                variant="h6"
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginBottom: "5px",
                  color: "#333",
                  fontFamily: "Source Serif Pro",
                }}
              >
                {connection.name}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  color: "#666",
                  fontSize: "14px",
                  lineHeight: "1.4",
                  fontFamily: "Source Serif Pro",
                }}
              >
                {connection.description}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default IntelligentConnection;
