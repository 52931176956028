import { Stack, Typography } from "@mui/material";
import React from "react";
import UpcomingCallCard from "../UpcomingCallCard/UpcomingCallCard";

interface UpcomingCallCardContainerProps {
  maxheight?: string;
  labelText?: string;
  gap?: string;
  upcomingMeetings?: any;
  handleMeetingOnClick?:any;
}

const UpcomingCallCardContainer: React.FC<UpcomingCallCardContainerProps> = ({
  labelText,
  gap = "10px",
  maxheight = "480px",
  upcomingMeetings,
  handleMeetingOnClick
}) => {
  const [isSelectedMeetingId, setIsSelectedMeetingId] = React.useState("");
  const handleMeetingClick = (meetingId: any) => {
    handleMeetingOnClick(meetingId)
    setIsSelectedMeetingId(meetingId);
  };

  return (
    <Stack
      sx={{
        width: "100%",
        maxHeight: maxheight,
        bgcolor: "white",
        marginTop: "10px",
        padding: "10px",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
          marginBottom: "10px",
        }}
      >
        <Typography
          sx={{ font: "normal normal 600 15px/19px Source Serif Pro" }}
        >
          {labelText}
        </Typography>
        <Stack
          sx={{
            height: "1px",
            borderRadius: "2px",
            flex: 1,
            bgcolor: "#E9EDF2",
          }}
        />
      </Stack>
      <Stack sx={{ gap: gap, overflow: "auto", scrollbarWidth: "none" }}>
        {Object.keys(upcomingMeetings).map((meetingId) => {
          const meetingDetail = upcomingMeetings[meetingId];
          return (
            <UpcomingCallCard
              isSelected={isSelectedMeetingId === meetingId ? true : false}
              meetingDetail={meetingDetail?.title}
              meetingDate={meetingDetail?.date}
              meetingTime={meetingDetail?.startTime}
              key={meetingId}
              meetingId={meetingDetail?.externalMeetingId}
              handleMeetingClick={handleMeetingClick}
              meeting_Id={meetingDetail._id}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};

export default UpcomingCallCardContainer;
