export interface SnoozeButtonListInterface {
    id: number | string;
    timeCount: string;
    timeType: string;
    value: string
}

export const SnoozeButtonList: SnoozeButtonListInterface[] = [
    { id: 1, timeCount: "15", timeType: "VIDEO_CENTER.MEETING.MINS", value: "15 mins" },
    { id: 2, timeCount: "30", timeType: "VIDEO_CENTER.MEETING.MINS", value: "30 mins" },
    { id: 3, timeCount: "45", timeType: "VIDEO_CENTER.MEETING.MINS", value: "45 mins" },
    { id: 4, timeCount: "60", timeType: "VIDEO_CENTER.MEETING.MINS", value: "60 mins" },
    { id: 5, timeCount: "120", timeType: "VIDEO_CENTER.MEETING.MINS", value: "120 mins" },
    { id: 6, timeCount: "24", timeType: "VIDEO_CENTER.MEETING.HOURS", value: "24 hours" },
    { id: 7, timeCount: "48", timeType: "VIDEO_CENTER.MEETING.HOURS", value: "48 hours" },

]