import { Stack } from "@mui/material";
import React from "react";

interface DateHeaderViewProps {
  date: string;
}

const DateHeaderView: React.FC<DateHeaderViewProps> = ({ date }) => {
  return (
    <Stack
      sx={{
        height: "25px",
        bgcolor: "#E9EDF2",
        borderRadius: "5px",
        flexDirection: "row",
        alignItems: "center",
        padding: "0px 5px",
        font: "normal normal 300 15px/19px Source Serif Pro",
      }}
    >
      {date}
    </Stack>
  );
};
export default DateHeaderView;
