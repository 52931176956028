/**
 * This file contains action creators for workspace related actions.
 */

import { createApiAction } from "../constantApi_config";

import {
    getAllEmails__RequestAction,
    getAllEmails__FailureAction,
    getAllEmails__SuccessAction,
    deleteEmail__RequestAction,
    deleteEmail__SuccessAction,
    deleteEmail__FailureAction,
    getMainSettings__RequestAction,
    getMainSettings__FailureAction,
    getMainSettings__SuccessAction,
    updateMainSettings__RequestAction,
    updateMainSettings__FailureAction,
    updateMainSettings__SuccessAction,
} from "./emailSlice";

/* getAllEmails__api */
/* Done */
export const getAllEmails__api = (accessToken: string, query: string) => {
    const menuArray = [
        "Starred",
        "Snoozed",
        "Sent",
        "Drafts",
        "Scheduled",
        "Spam",
        "Archives",
        "Trash",
        "Inbox",
    ];

    const queryMatchesMenu = menuArray.some((item) => item === query);

    const url = queryMatchesMenu
        ? `/email/folder-emails?tab=${query}`
        : query
            ? `/email/smart-filter-emails/${query}`
            : "/email/emails";

    return createApiAction({
        axiosRequired: true,
        method: "POST",
        url,
        data: { accessToken },
        onStart: getAllEmails__RequestAction.type,
        onSuccess: getAllEmails__SuccessAction.type,
        onError: getAllEmails__FailureAction.type,
    });
};

export const DeleteEmail__api = (accessToken: string, messageId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: "/email/move-email",
        data: { accessToken, messageId },
        onStart: deleteEmail__RequestAction.type,
        onSuccess: deleteEmail__SuccessAction.type,
        onError: deleteEmail__FailureAction.type,
    });

export const getMailSettings__api = () =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: "/email/settings",
        onStart: getMainSettings__RequestAction.type,
        onSuccess: getMainSettings__SuccessAction.type,
        onError: getMainSettings__FailureAction.type,
    });

export const updateMailSettings__api = (payload: any) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: "/email/settings",
        data: payload,
        onStart: updateMainSettings__RequestAction.type,
        onSuccess: updateMainSettings__SuccessAction.type,
        onError: updateMainSettings__FailureAction.type,
    });
