import { Stack, Typography } from "@mui/material";
import React from "react";
import { CrmStatusBadge } from "../../components/CrmStatusBadge";

interface TableStatusAreaProps {
  value: any;
}

const TableStatusArea: React.FC<TableStatusAreaProps> = ({ value }) => {
  const { jobType, isActive }: { jobType: string; isActive: boolean } = value;

  const getStatusBadge = () => {
    switch (isActive) {
      case true:
        return <CrmStatusBadge variant="active" />;
      case false:
        return <CrmStatusBadge variant="inActive" />;

      default:
        return <CrmStatusBadge variant="inActive" />;
    }
  };
  return (
    <Stack
      sx={{ flexDirection: "row", justifyContent: "space-between", gap: "5px" }}
    >
      <Typography
        sx={{
          font: "normal normal 400 14px/18px Source Serif Pro",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {jobType}
      </Typography>

      {getStatusBadge()}
    </Stack>
  );
};

export default TableStatusArea;
