import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getOneDoc__api, updateOneDoc__api } from "../../../../../../redux";
import { AddToWorkspace, CommentCard } from "../../../components";
import FroalaEditorComponent from "../../../../../common/FroalaEditor/FroalaEditor";
import { Stack, Typography, Skeleton } from "@mui/material";
import {
  ShareButton,
  ThreeDotMenuLarge,
  ViewPicker,
} from "../../../../../common";
import { menuItems } from "../../../InsideWorkspace/InsideWorkspaceType";

const DocumentOverView = () => {
  const dispatch = useDispatch();
  const { workspaceId, componentId } = useParams();
  const [content, setContent] = useState(""); // Local state for content
  const [title, setTitle] = useState(""); // Store the document title
  const [isLoading, setIsLoading] = useState(true); // Loading state for API fetch
  const componentMounted = useRef(false); // Ref to track if mounted
  const [isCommentButtonClicked, setCommentButtonClicked] = useState(false);

  // Fetch document data from the API
  const handleGetOneDocument = async () => {
    setIsLoading(true); // Start loading
    if (!workspaceId || !componentId) return;

    const action = getOneDoc__api(workspaceId, componentId);
    const docFetch = await dispatch(action);

    if (docFetch?.success && docFetch?.data) {
      const decodedContent = decodeHTML(docFetch.data.content);
      setContent(decodedContent || "");
      setTitle(docFetch.data.title || ""); // Set the document title
    }
    setIsLoading(false); // Stop loading
  };

  const debouncedUpdateDocument = useCallback(
    async (updatedContent) => {
      const addMonths = (months) => {
        const date = new Date();
        date.setMonth(date.getMonth() + months);
        return date.toISOString();
      };

      const documentPayload = {
        title: title, // Use the current title state
        content: updatedContent,
        isPublic: true,
        password: "",
        expirationDate: addMonths(6),
      };

      const action = updateOneDoc__api(
        workspaceId,
        componentId,
        documentPayload
      );
      await dispatch(action);
    },
    [dispatch, title, workspaceId, componentId]
  );

  const decodeHTML = (html) => {
    if (!html) {
      return "";
    }
    const txt = document?.createElement("textarea");
    txt.innerHTML = html;
    return txt?.value;
  };

  useEffect(() => {
    handleGetOneDocument(); // Call API on component mount
    componentMounted.current = true;
  }, [componentId, workspaceId]);

  const handleContentChange = (updatedContent) => {
    setContent(updatedContent);
    if (componentMounted.current) {
      debouncedUpdateDocument(updatedContent); // Call debounced function
    }
  };

  return (
    <Stack>
      <Stack
        ml={2}
        mb={3}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        {isLoading ? (
          <Skeleton variant="text" width={"100%"} height={50} />
        ) : (
          <Typography
            sx={{
              fontFamily: "Source Serif Pro",
              fontSize: "25px",
              fontWeight: "900",
              color: "black",
              lineHeight: "31.33px",
            }}
          >
            {title}
          </Typography>
        )}

        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          gap={1}
        >
          <ViewPicker />
          <ShareButton />
          <AddToWorkspace />
          <ThreeDotMenuLarge options={menuItems} />
        </Stack>
      </Stack>

      <div style={{ marginLeft: "10px" }}>
        <Stack direction={"row"} alignItems={"flex-start"} gap={1}>
          {isLoading ? (
            <Skeleton variant="rectangular" width={"100%"} height={400} />
          ) : (
            <FroalaEditorComponent
              initialContent={content} // Keep the previous content until new one is ready
              onContentChange={handleContentChange}
              isCommentButtonClicked={isCommentButtonClicked}
              setCommentButtonClicked={setCommentButtonClicked}
            />
          )}
          {isCommentButtonClicked && <CommentCard />}
        </Stack>
      </div>
    </Stack>
  );
};

export default DocumentOverView;
