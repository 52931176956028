import * as React from "react";
import {
  Avatar,
  Box,
  FormControl,
  InputAdornment,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import S from "./SingleTeammateDropdown.module.css";
import { GreenDropdownIcon, TeamMemberIcon } from "../../../images";
import { SearchInDropdown } from "../SearchInDropdown";
import { SmallButtonDropdown } from "../Buttons";
import { ButtonType } from "../Buttons/AllButtonProps";
import { useSelector } from "react-redux";
import { AuthState } from "../../../redux";
import { RootState } from "../../../redux/rootReducer";

interface SingleTeammateDropdownProps {
  componentHeader?: string;
  options?: any[];
  width?: string;
  onSelect?: (selectedOption: any | null) => void;
  label?: string;
  placeholder?: string;
  searchBarPlaceholder?: string;
}

const OPTIONS_PATTERN = [
  "John Doe",
  "Jane Smith",
  "Emily Johnson",
  "Michael Brown",
  "Sarah Davis",
  "David Wilson",
];

const SingleTeammateDropdown: React.FC<SingleTeammateDropdownProps> = ({
  options = OPTIONS_PATTERN,
  placeholder,
  searchBarPlaceholder = "Search all Projects",
  label,
  width = "240px",
  onSelect,
  ...props
}) => {
  const [personName, setPersonName] = React.useState<string[] | any>([]);
  const [open, setOpen] = React.useState(false);
  const userState: any = useSelector(
    (state: RootState) => state.auth as AuthState
  );

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const { value }: any = event.target;
    setPersonName(typeof value === "string" ? value?.split(",") : value);
    const selectedOption: any =
      options?.find((option) => option?._id === value?._id) || null;
    if (onSelect) {
      onSelect(selectedOption);
    }
  };

  const MenuProps = React.useMemo(
    () => ({
      PaperProps: {
        className: S["singleTeammateDropdown__menu"],
        style: { width: "inherit" },
      },
    }),
    [width]
  );

  return (
    <Box>
      {label && (
        <Typography
          sx={{
            font: "normal normal 300 10px/13px Source Serif Pro",
            marginBottom: "5px",
          }}
          children={label}
        />
      )}
      <FormControl size="small" style={{ width }} {...props}>
        <Select
          open={open}
          onClick={!open ? () => setOpen(true) : () => null}
          displayEmpty
          IconComponent={() => (
            <GreenDropdownIcon
              open={open}
              sx={{ marginRight: "10px", height: "12px" }}
            />
          )}
          value={personName}
          onChange={handleChange}
          input={
            <OutlinedInput
              className={S["singleTeammateDropdown__inputbox"]}
              classes={{
                notchedOutline: S["singleTeammateDropdown__notchedOutline"],
                focused: S["singleTeammateDropdown__focused"],
              }}
              startAdornment={
                <InputAdornment position="start">
                  <Stack direction={"row"} alignItems={"center"} gap={"5px"}>
                    <TeamMemberIcon />
                    <Avatar
                      src={personName?.avatar}
                      sx={{ height: "15px", width: "15px" }}
                    >
                      {!personName?.avatar && (
                        <Typography
                          sx={{
                            font: "normal normal normal 10px/13px Source Serif Pro",
                            color: "inherit",
                            textTransform: "uppercase", // Ensure initials are uppercase
                          }}
                        >
                          {personName?.firstName?.charAt?.(0)?.toUpperCase() || "F"}
                          {personName?.lastName?.charAt?.(0)?.toUpperCase() || "L"}
                        </Typography>
                      )}
                    </Avatar>
                  </Stack>
                </InputAdornment>
              }
            />
          }
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          renderValue={(selected: any) => {
            if (selected?.length === 0) {
              return (
                <em className={S["singleTeammateDropdown__selectPlaceholder"]}>
                  {placeholder}
                </em>
              );
            }

            return (
              <span className={S["singleTeammateDropdown__selected"]}>
                {selected?.firstName} {selected?.lastName}
              </span>
            );
          }}
          MenuProps={MenuProps}
        >
          <Stack className={S["singleTeammateDropdown__searchContainerOuter"]}>
            <Stack
              // sx={{ width }}
              className={S["singleTeammateDropdown__searchContainerInner"]}
            >
              <SearchInDropdown placeHolder={searchBarPlaceholder} />
            </Stack>
          </Stack>
          {options &&
            options?.map((name: any) => {
              if (name?._id === userState?.user?.data?._id) return;
              return (
                <MenuItem
                  className={S["singleTeammateDropdown__menu-item"]}
                  key={name._id}
                  value={name}
                >
                  <Stack
                    sx={{
                      flexDirection: "row",
                      alignItems: "center",
                      flex: 1,
                      justifyContent: "space-between",
                    }}
                  >
                    <Stack
                      sx={{
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <Avatar
                        src={name?.avatar}
                        sx={{ height: "15px", width: "15px" }}
                      >
                        <Typography
                          sx={{
                            font: "normal normal normal 10px/13px Source Serif Pro",
                          }}
                        >
                          {name?.firstName + " " + name?.lastName}
                        </Typography>
                      </Avatar>
                      <ListItemText
                        primary={`${name?.firstName} ${name?.lastName}`}
                        primaryTypographyProps={{
                          className:
                            S["singleTeammateDropdown__list-item-text"],
                        }}
                      />
                    </Stack>
                    <SmallButtonDropdown
                      label="Select"
                      types={ButtonType.Button}
                    />
                  </Stack>
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SingleTeammateDropdown;
