import React, { useEffect, useRef, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import {
  AvatarNormal,
  EventIconButton,
  useCustomSnackbar,
} from "../../../../common";
import {
  CheckboxFilledIcon,
  CheckedCheckboxIcon,
  ConnectedIcon,
  DoneIcon,
  DummyImage,
  EyeIcon,
  MultiCircleIcon,
  SnoozeIcon,
  XCircleIcon,
} from "../../../../../images";
import {
  CalendarTypesEnum,
  getCalenderLogo,
  getUser,
} from "../../../../../Utils";
import styles from "./CalendarEventCardLong.module.css";
import { useDispatch } from "react-redux";
import { eventResponse__api, recallAPI } from "../../../../../redux";
import { useTranslation } from "react-i18next";

interface CalendarEventCardLongProps {
  width?: string;
  openBookingLink: () => void;
  variant:
    | "bookinglink"
    | "pendingEvent"
    | "appointment"
    | "meeting"
    | "reminders"
    | "bills/payment"
    | "workshop"
    | "Other"
    | "out of office"
    | "Done"
    | "Pending"
    | "up-next"
    | "task";
  bookingLinkDatas?: any;
}

const CalendarEventCardLong: React.FC<CalendarEventCardLongProps> = ({
  variant,
  width = "950px",
  openBookingLink,
  bookingLinkDatas,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = getUser();
  const elementRef = useRef<HTMLDivElement>(null);
  const [elementWidth, setElementWidth] = useState(0);
  const enqueueSnackbar_v1 = useCustomSnackbar();
  const copySlugLink = () => {
    const hostName =
      user?.data?.firstName && user?.data?.lastName
        ? `${user?.data?.firstName}-${user?.data?.lastName}`
        : "defaultHostName"; // Use a default or fallback if no user name

    const slug = bookingLinkDatas?.bookingLinkSlug || "defaultSlug"; // Use a default slug if not available
    const link = `https://os.bearishos.com/book-event/${hostName}/${slug}`;

    // Copy the link to the clipboard
    navigator.clipboard
      .writeText(link)
      .then(() => {
        enqueueSnackbar_v1(
          "success",
          `Link copied for ${bookingLinkDatas?.name}`,
          {
            position: { vertical: "top", horizontal: "center" },
            autoHideDuration: 5000, // Optional custom auto-hide duration in milliseconds
          }
        );
      })
      .catch((err) => {
        enqueueSnackbar_v1(
          "error",
          `Link copied failed for ${bookingLinkDatas?.name}`,
          {
            position: { vertical: "top", horizontal: "center" },
            autoHideDuration: 5000, // Optional custom auto-hide duration in milliseconds
          }
        );
      });
  };

  const handleEventResponse = async (responseType: string) => {
    try {
      if (
        !bookingLinkDatas?.calendarId ||
        !bookingLinkDatas?._id ||
        !responseType
      ) {
        return;
      }

      if (!bookingLinkDatas?.bookingLinkId) {
        return;
      } else {
        console.log("Reading notification");
      }

      const action = eventResponse__api(
        bookingLinkDatas?.calendarId,
        bookingLinkDatas?._id,
        responseType,
        "host"
      );
      const r: any = await dispatch(action);

      if (r?.success) {
        dispatch(recallAPI("getAllCalEvent"));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getComponent = (): {
    tagName: string;
    tagColor: string;
    buttonArea: React.ReactNode;
  } => {
    switch (variant) {
      case "bookinglink":
        return {
          tagName: "@bookinglink",
          tagColor: "#B5005B",
          buttonArea: (
            <>
              <EventIconButton
                buttonTitle={t("CALENDAR.VIEW")}
                icon={EyeIcon}
                iconColor="#000000"
                onClick={openBookingLink}
              />
              <EventIconButton
                buttonTitle={t("CALENDAR.COPY_LINK")}
                icon={ConnectedIcon}
                iconColor="#000000"
                onClick={() => copySlugLink()}
              />
            </>
          ),
        };
      case "pendingEvent":
        return {
          tagName: "@pendingEvent",
          tagColor: "#B5005B",
          buttonArea: (
            <>
              <EventIconButton
                buttonTitle={t("CALENDAR.ACCEPT")}
                icon={CheckboxFilledIcon}
                iconColor="#21B481"
                onClick={() => handleEventResponse("accepted")}
              />
              <EventIconButton
                buttonTitle={t("CALENDAR.REJECT")}
                icon={XCircleIcon}
                iconColor="#FF0000"
                onClick={() => handleEventResponse("accepted")}
              />
              <EventIconButton
                buttonTitle={t("CALENDAR.MAYBE")}
                icon={MultiCircleIcon}
                iconColor="#FFB100"
                onClick={() => handleEventResponse("maybe")}
              />
            </>
          ),
        };

      case "up-next":
        return {
          tagName: "@UpcomingEvent",
          tagColor: "#B5005B",
          buttonArea: (
            <>
              <EventIconButton
                buttonTitle={t("CALENDAR.DONE")}
                icon={CheckedCheckboxIcon}
                iconColor="#21B481"
                onClick={() => console.log("Accepted")}
              />
              <EventIconButton
                buttonTitle={t("CALENDAR.SNOOZE")}
                icon={SnoozeIcon}
                iconColor="#FF0000"
                onClick={() => console.log(" Reject")}
              />
            </>
          ),
        };

      case "Pending":
        return {
          tagName: "@pendingEvent",
          tagColor: "#B5005B",
          buttonArea: (
            <>
              <EventIconButton
                buttonTitle={t("CALENDAR.ACCEPT")}
                icon={CheckboxFilledIcon}
                iconColor="#21B481"
                onClick={() => handleEventResponse("accepted")}
              />
              <EventIconButton
                buttonTitle={t("CALENDAR.REJECT")}
                icon={XCircleIcon}
                iconColor="#FF0000"
                onClick={() => handleEventResponse("rejected")}
              />
              <EventIconButton
                buttonTitle={t("CALENDAR.MAYBE")}
                icon={MultiCircleIcon}
                iconColor="#FFB100"
                onClick={() => handleEventResponse("maybe")}
              />
            </>
          ),
        };

      case "Done":
        return {
          tagName: "@pendingEvent",
          tagColor: "#B5005B",
          buttonArea: (
            <>
              <EventIconButton
                buttonTitle={t("CALENDAR.UP_NEXT")}
                icon={CheckboxFilledIcon}
                iconColor="#21B481"
                onClick={() => console.log("Accepted")}
              />
              {/* <EventIconButton
                buttonTitle="Reject"
                icon={XCircleIcon}
                iconColor="#FF0000"
                onClick={() => console.log(" Reject")}
              />
              <EventIconButton
                buttonTitle="Maybe"
                icon={MultiCircleIcon}
                iconColor="#FFB100"
                onClick={() => console.log("Maybe")}
              /> */}
            </>
          ),
        };

      case "appointment":
      case "bills/payment":
      case "meeting":
      case "reminders":
      case "workshop":
      case "Other":
      case "out of office":
        return {
          tagName: `@${variant}`,
          tagColor: "#B5005B",
          buttonArea: (
            <>
              <EventIconButton
                buttonTitle={t("CALENDAR.START")}
                icon={DoneIcon}
                iconColor="#008D4C"
                onClick={() => console.log("Start")}
              />
              <EventIconButton
                buttonTitle={t("CALENDAR.SNOOZE")}
                icon={SnoozeIcon}
                iconColor="#000000"
                onClick={() => console.log("Snooze")}
              />
            </>
          ),
        };
      case "task":
        return {
          tagName: "@bookinglink",
          tagColor: "#A53A01",
          buttonArea: (
            <>
              <EventIconButton
                buttonTitle={t("CALENDAR.VIEW")}
                icon={EyeIcon}
                iconColor="#000000"
                onClick={() => console.log("View")}
              />
              <EventIconButton
                buttonTitle={t("CALENDAR.START")}
                icon={DoneIcon}
                iconColor="#008D4C"
                onClick={() => console.log("Start")}
              />
              <EventIconButton
                buttonTitle="Snooze"
                icon={SnoozeIcon}
                iconColor="#000000"
                onClick={() => console.log("Snooze")}
              />
            </>
          ),
        };
    }
  };

  useEffect(() => {
    if (elementRef && elementRef?.current) {
      // Get the width of the element
      setElementWidth(elementRef.current.offsetWidth);
    }
  }, []); // Runs after the component is mounted

  const generateEventMessage = () => {
    const nameOrTitle =
      bookingLinkDatas?.name || bookingLinkDatas?.title || "ABC";
    const isBookingEvent = bookingLinkDatas?.name ? true : false;

    if (bookingLinkDatas?.status === "up-next") {
      // If it's an up-next event
      return isBookingEvent
        ? `${
            bookingLinkDatas?.description ||
            t("CALENDAR.NO_BOOKING_EVENT_DESC_1")
          }`
        : `${
            bookingLinkDatas?.description || t("CALENDAR.NO_UPNEXT_EVENT_DESC")
          }`;
    } else if (bookingLinkDatas?.status === "pending") {
      // If it's a pending event
      return isBookingEvent
        ? `${
            bookingLinkDatas?.description ||
            t("CALENDAR.NO_BOOKING_EVENT_DESC_2")
          }`
        : `${bookingLinkDatas?.description || t("CALENDAR.SCHDULED_LATER")}`;
    } else if (bookingLinkDatas?.status === "done") {
      // If it's a done event
      return isBookingEvent
        ? `${
            bookingLinkDatas?.description ||
            t("CALENDAR.COMPLETED_BOOKING_EVENT")
          }`
        : `${bookingLinkDatas?.description || t("CALENDAR.COMPLETED_EVENT")}`;
    } else {
      // Default case (if status is undefined or something else)
      return `${nameOrTitle + " " + t("CALENDAR.BOOKING_SHADOW")}`;
    }
  };
  return (
    // Then use the classes:
    <Stack className={styles.calendarEventCardLong} sx={{ width: width }}>
      <Stack
        className={styles.calendarEventCardLong__avatarSection}
        sx={{ width: `calc(100% - ${elementWidth}px)` }}
      >
        <Stack className={styles.calendarEventCardLong__avatar}>
          {/* Avatar and username */}
          <AvatarNormal
            size={30}
            username="user"
            avatarColor="green"
            imgSrc={DummyImage}
            sx={{ border: "1px solid #E9EDF2", boxSizing: "border-box" }}
          />
          <Typography className={styles.calendarEventCardLong__username}>
            {bookingLinkDatas?.name ||
              bookingLinkDatas?.title ||
              "event/task/booking name"}
          </Typography>
        </Stack>
        {/* Event details */}
        <Stack className={styles.calendarEventCardLong__eventDetails}>
          <Typography
            className={styles.calendarEventCardLong__tagName}
            sx={{ color: getComponent()?.tagColor }}
          >
            {getComponent().tagName}
          </Typography>
          <Typography
            className={styles.calendarEventCardLong__eventDescription}
          >
            {generateEventMessage()}
          </Typography>
        </Stack>
      </Stack>

      {/* Button section */}
      <Stack
        ref={elementRef}
        className={styles.calendarEventCardLong__buttonSection}
      >
        <Box
          component={"img"}
          src={getCalenderLogo({
            calendarType: CalendarTypesEnum?.BearishCalendar,
          })}
          alt="calendar logo"
          className={styles.calendarEventCardLong__calendarLogo}
        />
        {getComponent().buttonArea}
      </Stack>
    </Stack>
  );
};

export default CalendarEventCardLong;
