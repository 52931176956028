import { Box, Divider, Slide, Stack } from "@mui/material";
import React from "react";
import {
  ActivtiyIcon,
  BearishIcon,
  FirstMessageIcon,
  FoldReachIcon,
  LockIcon,
  QuickActionIcon,
  WorkspaceCIcon,
} from "../../../../../../images";
import { GreenArrowButton } from "../../../../../common";
import { CommentFilePanel } from "./CommentFilePanel";
import { useDispatch,  } from "react-redux";
import { changeLayoutState,  } from "../../../../../../redux";

type RightSidePanelProps = { setRightPanelOpen: any; rightPanelOpen: any };

const RightSidePanel = ({
  rightPanelOpen,
  setRightPanelOpen,
}: RightSidePanelProps) => {
  const dispatch = useDispatch();

  const toggleDrawer = () => {
    dispatch(
      changeLayoutState({
        type: "isDrawer_CloudStorage",
        value: rightPanelOpen ? true : false,
      })
    );
  };
  return (
    <Box sx={{ background: "white", height: "100%", padding: "15px" }}>
      <Stack
        direction={"row"}
        alignItems={"flex-start"}
        height={"93%"}
        justifyContent={"flex-start"}
      >
        <Stack
          direction={"column"}
          alignItems={"center"}
          justifyContent={"space-between"}
          height={"100%"}
        >
          <Stack
            direction={"column"}
            gap={2}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Stack mt={3} direction={"column"} gap={3}>
              <FirstMessageIcon w={25} />
              <ActivtiyIcon w={25} />
              <LockIcon w={25} />
            </Stack>
            <Divider
              orientation="horizontal"
              sx={{ borderColor: "#E9EDF2", width: "25px", height: "10px" }}
            />
            <Stack mt={3} direction={"column"} gap={3}>
              <FoldReachIcon color="#008D4C" w={25} />
              <WorkspaceCIcon w={25} />
              <QuickActionIcon w={25} />
              <BearishIcon width={25} height={25} color="orange" />
            </Stack>
          </Stack>

          <Stack>
            <Divider orientation="horizontal" sx={{ borderColor: "#E9EDF2" }} />
            <Stack direction={"column"} gap={2}>
              <GreenArrowButton
                onClick={() => {
                  setRightPanelOpen(rightPanelOpen ? false : true);
                  toggleDrawer()
                }}
              />
            </Stack>
          </Stack>
        </Stack>

        <Slide direction="left" in={rightPanelOpen} mountOnEnter unmountOnExit>
          <Stack width={"375px"} height={"100%"}>
            <CommentFilePanel />
          </Stack>
        </Slide>
      </Stack>
    </Box>
  );
};

export default RightSidePanel;
