import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import {
  CreatedIcon,
  EyeIcon,
  ForwardIcon,
  ReplyIcon,
  SendIcon,
} from "../../../../../../../images";
import {
  MsgTimelineViewProps,
  MsgTimelineVariantEnum,
} from "./MsgTimelineType";

const MsgTimelineView: React.FC<MsgTimelineViewProps> = (props) => {
  const {
    variant,
    PrimaryPersonName,
    location,
    timeZone,
    sendVia,
    timestamp,
  } = props;

  const renderIcon = () => {
    switch (variant) {
      case MsgTimelineVariantEnum.Drafted:
        return CreatedIcon;

      case MsgTimelineVariantEnum.Forwarded:
        return ForwardIcon;

      case MsgTimelineVariantEnum.Replied:
        return ReplyIcon;

      case MsgTimelineVariantEnum.Sent:
        return SendIcon;

      case MsgTimelineVariantEnum.Viewed:
        return EyeIcon;

      default:
        return EyeIcon;
    }
  };

  // Narrow down props based on the variant
  const getAdditionalProps = () => {
    switch (variant) {
      case MsgTimelineVariantEnum.Sent:
      case MsgTimelineVariantEnum.Replied:
      case MsgTimelineVariantEnum.Forwarded:
        return {
          SecondaryPersonName: (props as any).SecondaryPersonName,
        };
      case MsgTimelineVariantEnum.Viewed:
        return {
          AttachmentOpened: (props as any).AttachmentOpened,
        };
      case MsgTimelineVariantEnum.Drafted:
        return {}; // No additional props for Drafted
      default:
        return {};
    }
  };

  const additionalProps = getAdditionalProps();

  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "start",
        gap: "10px",
        font: "normal normal 300 13px/16px Source Serif Pro",
        paddingRight: "10px",
        boxSizing: "border-box",
      }}
    >
      <Box sx={{ flexShrink: 0, paddingTop: "4px" }}>
        {React.createElement(renderIcon(), { w: 15 })}
      </Box>
      <Typography sx={{ font: "inherit", lineHeight: 1.75 }}>
        {PrimaryPersonName} {variant} on {timestamp} {timeZone} via{" "}
        {sendVia}, {location}
        {variant === MsgTimelineVariantEnum.Sent ||
        variant === MsgTimelineVariantEnum.Replied ||
        variant === MsgTimelineVariantEnum.Forwarded ? (
          <> to {additionalProps.SecondaryPersonName}</>
        ) : null}
        {variant === MsgTimelineVariantEnum.Viewed &&
          additionalProps.AttachmentOpened && <> viewed attachment</>}
      </Typography>
    </Stack>
  );
};

export default MsgTimelineView;
