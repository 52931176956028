import { Button, ButtonProps, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../Utils";

interface TraxDrawerButtonsProps extends ButtonProps {
  icon: React.ElementType;
  buttonLabel: string;
  isSelected?: boolean;
}

const TraxDrawerButtons: React.FC<TraxDrawerButtonsProps> = ({
  isSelected,
  buttonLabel,
  icon,
  ...props
}) => {
  const highlightedColor = adjustHexColor("#00767B", 25);

  return (
    <Button
      variant="text"
      sx={{
        textTransform: "none",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        gap: "5px",
        height: "32px",
        padding: "0px 0px 0px 5px",
        boxSizing: "border-box",
        bgcolor: isSelected ? highlightedColor : "inherit",
        ":hover": {
          bgcolor: isSelected ? highlightedColor : "inherit",
        },
      }}
      {...props}
    >
      {React.createElement(icon, { w: 20, color: "black" })}
      <Typography
        sx={{
          font: "normal normal 300 13px/16px Source Serif Pro",
          color: "black",
        }}
      >
        {buttonLabel}
      </Typography>
    </Button>
  );
};

export default TraxDrawerButtons;
