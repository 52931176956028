import React, { useEffect, useRef, useState } from "react";
import { IconButton, Stack } from "@mui/material";
import { ButtonSmall } from "../../Buttons";
import { SmallButtonColor } from "../../Buttons/AllButtonProps";
import { DeleteIcon, DragAndDropIcon } from "../../../../images";
import { AscendingDropdown, ValueDropdown } from "../view";
import { SmallThreeDotMenu } from "../../SmallThreeDotMenu";
import {
  DragAndDropMenuListType,
  SortAndFilterType,
  SortAndFilterValue,
  SortSelectedListType,
} from "../MenuTypes";
import {
  handleSortMenuDragStart,
  sortMenuAllowDrop,
  sortMenuHandleDrop,
} from "./SortMenuUtils";
import { useTranslation } from "react-i18next";

interface SortMenuProps {
  buttonColor: "CRMOrange" | "TraxBluish";
  SelectedOptList?: SortSelectedListType[];
  options: DragAndDropMenuListType[];
  onChange: (newList: SortSelectedListType[]) => void;
}

const SortMenu: React.FC<SortMenuProps> = ({
  onChange,
  options,
  SelectedOptList,
  buttonColor,
}) => {
  const { t } = useTranslation();
  const [remainingList, setRemainingList] = useState<DragAndDropMenuListType[]>(
    []
  );

  const [selectedOption, setSelectedOption] = useState<SortSelectedListType[]>(
    SelectedOptList || []
  );

  // Create a ref for the last item
  const lastItemRef = useRef<HTMLDivElement>(null);

  const handleValueSelect = (
    index: number,
    newValue: DragAndDropMenuListType
  ) => {
    const updatedOptions = [...selectedOption];
    updatedOptions[index].selectedItem = newValue;
    updatedOptions[index].dragable = true;
    setSelectedOption(updatedOptions);
  };

  const handleOrderSelect = (index: number, newOrder: string) => {
    const updatedOptions = [...selectedOption];
    updatedOptions[index].order = newOrder as SortAndFilterType;
    setSelectedOption(updatedOptions);
  };

  const handleDeleteItem = (labelName: string) => {
    const updatedOptions = selectedOption.filter(
      (item) => item.selectedItem.label !== labelName
    );
    setSelectedOption(updatedOptions);
  };

  const addButtonHandler = () => {
    const isEmptyAdded: boolean = selectedOption.some(
      (item) => Object.keys(item.selectedItem).length === 0
    );

    if (!isEmptyAdded) {
      const newValue: SortSelectedListType = {
        selectedItem: {},
        order: "Ascending",
        dragable: false,
      };

      setSelectedOption((prevOptions) => [...prevOptions, newValue]);
      setTimeout(() => {
        if (lastItemRef.current) {
          lastItemRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }, 50);
    }

    setTimeout(() => {
      if (lastItemRef.current) {
        lastItemRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 50);
  };

  useEffect(() => {
    if (selectedOption.every((item) => !!item.selectedItem.label)) {
      onChange(selectedOption);

      const selectedLabels = selectedOption.map(
        (item) => item.selectedItem.label
      );
      const remainingOptions = options.filter(
        (option) => !selectedLabels.includes(option.label)
      );

      // Set remaining options in state
      setRemainingList(remainingOptions);
    }
  }, [selectedOption]);

  const getButtonColor = (): SmallButtonColor => {
    switch (buttonColor) {
      case "CRMOrange":
        return SmallButtonColor.CRMOrange;
      case "TraxBluish":
        return SmallButtonColor.TraxBluish;
    }
  };

  return (
    <Stack
      sx={{
        border: "1px solid #E9EDF2",
        borderRadius: "5px",
        maxHeight: "400px",
        minHeight: "150px",
        padding: "20px 20px 15px 20px",
        boxSizing: "border-box",
        gap: "25px",
        justifyContent: "space-between",
      }}
    >
      <Stack sx={{ overflow: "auto", scrollbarWidth: "none", gap: "15px" }}>
        {selectedOption.map((item, index) => {
          const isLastItem = index === selectedOption.length - 1;
          return (
            !item.selectedItem.divider && (
              <Stack key={index} ref={isLastItem ? lastItemRef : null}>
                <div
                  data-index={index}
                  draggable={item.dragable}
                  onDragStart={(e) => handleSortMenuDragStart(e, index)}
                  onDrop={(e) =>
                    sortMenuHandleDrop(e, selectedOption, setSelectedOption)
                  }
                  onDragOver={sortMenuAllowDrop}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <IconButton
                    disabled={!item.selectedItem.draggable}
                    style={{
                      cursor: item.selectedItem.draggable ? "move" : "default",
                      width: "32px",
                      marginRight: "-5px",
                    }}
                  >
                    <DragAndDropIcon w={15} />
                  </IconButton>

                  <ValueDropdown
                    initialValue={item.selectedItem}
                    onSelect={(value) => handleValueSelect(index, value)}
                    options={remainingList}
                    width="150px"
                  />

                  <AscendingDropdown
                    initialValue={item.order}
                    onSelect={(value) => handleOrderSelect(index, value)}
                    options={SortAndFilterValue}
                  />

                  <SmallThreeDotMenu
                    menuItems={[
                      {
                        id: 5,
                        icon: DeleteIcon,
                        text: "Delete Sort",
                        LANG: "TRAX.DELETE_SORT",
                        color: "red",
                      },
                    ]}
                    displayOption="left"
                    menuWidth="130px"
                    onClickAction={() =>
                      handleDeleteItem(item.selectedItem.label!)
                    }
                  />
                </div>
              </Stack>
            )
          );
        })}
      </Stack>
      <Stack>
        <ButtonSmall
          onClick={addButtonHandler}
          label={t("TRAX.ADD_SORT")}
          colorVarient={getButtonColor()}
          sx={{ padding: "0px 8px" }}
        />
      </Stack>
    </Stack>
  );
};

export default SortMenu;
