import { Stack, Typography } from "@mui/material";
import React from "react";

interface CheckInOrOutViewProps {
  value?: string;
}

const CheckInOrOutView: React.FC<CheckInOrOutViewProps> = ({ value }) => {
  return (
    <Stack
      sx={{ width: "80px", justifyContent: "center", alignItems: "center" }}
    >
      {!value ? (
        <Typography
          sx={{ font: "normal normal 300 14px/18px Source Serif Pro" }}
        >
          N/A
        </Typography>
      ) : (
        <Typography
          sx={{ font: "normal normal 700 14px/18px Source Serif Pro" }}
        >
          {value}
        </Typography>
      )}
    </Stack>
  );
};

export default CheckInOrOutView;
