export interface UserInfoChipProps {
  // emailAddress: { name: string; address: string };
  userName: string;
  userEmail: string;
  userIconSrc?: string;
  userAvatarColor?: string;
}

export const DUMMY_EMAIL_CARD_USER = [
  {
    userName: "John Doe",
    userEmail: "john.doe@example.com",
    emailAddress: {
      name: "John Doe",
      address: "john.doe@example.com",
    },
    userIconSrc: "https://randomuser.me/api/portraits/men/1.jpg",
    userAvatarColor: "#FF5722",
  },
  {
    userName: "Jane Smith",
    userEmail: "jane.smith@example.com",
    emailAddress: {
      name: "Jane Smith",
      address: "jane.smith@example.com",
    },
    userIconSrc: "https://randomuser.me/api/portraits/women/2.jpg",
    userAvatarColor: "#4CAF50",
  },
  {
    userName: "Alice Johnson",
    userEmail: "alice.johnson@example.com",
    emailAddress: {
      name: "Alice Johnson",
      address: "alice.johnson@example.com",
    },
    userIconSrc: "https://randomuser.me/api/portraits/women/3.jpg",
    userAvatarColor: "#9C27B0",
  },
  {
    userName: "Bob Brown",
    userEmail: "bob.brown@example.com",
    emailAddress: {
      name: "Bob Brown",
      address: "bob.brown@example.com",
    },
    userIconSrc: "https://randomuser.me/api/portraits/men/4.jpg",
    userAvatarColor: "#FFC107",
  },
  {
    userName: "Charlie Davis",
    userEmail: "charlie.davis@example.com",
    emailAddress: {
      name: "Charlie Davis",
      address: "charlie.davis@example.com",
    },
    userIconSrc: "https://randomuser.me/api/portraits/men/5.jpg",
    userAvatarColor: "#00BCD4",
  },
  {
    userName: "Diana Lee",
    userEmail: "diana.lee@example.com",
    emailAddress: {
      name: "Diana Lee",
      address: "diana.lee@example.com",
    },
    userIconSrc: "https://randomuser.me/api/portraits/women/6.jpg",
    userAvatarColor: "#E91E63",
  },
  {
    userName: "Evan Garcia",
    userEmail: "evan.garcia@example.com",
    emailAddress: {
      name: "Evan Garcia",
      address: "evan.garcia@example.com",
    },
    userIconSrc: "https://randomuser.me/api/portraits/men/7.jpg",
    userAvatarColor: "#3F51B5",
  },
  {
    userName: "Fiona Martinez",
    userEmail: "fiona.martinez@example.com",
    emailAddress: {
      name: "Fiona Martinez",
      address: "fiona.martinez@example.com",
    },
    userIconSrc: "https://randomuser.me/api/portraits/women/8.jpg",
    userAvatarColor: "#FFEB3B",
  },
];
