import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { CheckboxMedium } from "../../../../common";
import { WeekDaysEnum } from "../../../../../Utils";

interface DaySelectionProps {
  onSelect?: (selectedDays: WeekDaysEnum[]) => void;
}

const DaySelection: React.FC<DaySelectionProps> = ({ onSelect }) => {
  const [selectedDays, setSelectedDays] = useState<WeekDaysEnum[]>([]);

  const handleDaySelect = (day: WeekDaysEnum) => {
    setSelectedDays((prevSelectedDays) => {
      const isSelected = prevSelectedDays.includes(day);
      const newSelectedDays = isSelected
        ? prevSelectedDays.filter((d) => d !== day) // Unselect if already selected
        : [...prevSelectedDays, day]; // Select if not already selected

      if (onSelect) {
        onSelect(newSelectedDays);
      }

      return newSelectedDays;
    });
  };

  const capitalize = (day: string) => {
    return day.charAt(0).toUpperCase() + day.slice(1);
  };

  return (
    <Stack
      sx={{
        width: "120px",
        borderRadius: "5px",
        boxSizing: "border-box",
        bgcolor: "white",
        font: "normal normal 300 12px/16px Source Serif Pro",
      }}
    >
      <Stack
        sx={{
          borderRadius: "5px 5px 0px 0px",
          flexDirection: "row",
          alignItems: "center",
          borderBottom: "1px solid #E9EDF2",
          font: "inherit",
          paddingLeft: "10px",
          height: "25px",
        }}
      >
        <Typography sx={{ font: "inherit" }}>Copy to...</Typography>
      </Stack>

      <Stack
        sx={{
          boxSizing: "border-box",
          padding: "10px",
          gap: "15px",
          font: "inherit",
        }}
      >
        {Object.values(WeekDaysEnum).map((day) => (
          <Stack
            key={day}
            sx={{
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              font: "inherit",
              cursor: "pointer",
            }}
            onClick={() => handleDaySelect(day)} // Handle selection on click
          >
            <CheckboxMedium
              boxSize={15}
              checked={selectedDays.includes(day)} // Check if the day is selected
            />
            <Typography sx={{ font: "inherit" }}>{capitalize(day)}</Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default DaySelection;
