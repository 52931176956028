import React from "react";
import {
  CheckedCircleIcon,
  DateIcon,
  EmailIcon,
  NotesLineIcon,
  StreamIcon,
  VideoCallIcon,
} from "../../../../../images";
import { Button, Stack, Tooltip } from "@mui/material";
import { MediumSquareButton } from "../../../../common";

interface IconListInterface {
  id: number;
  icon: React.ElementType;
  value: string;
  hoverText?: string;
}

const iconsList: IconListInterface[] = [
  { id: 1, icon: VideoCallIcon, hoverText: "text", value: "VideoCallIcon" },
  { id: 2, icon: EmailIcon, hoverText: "text", value: "EmailIcon" },
  { id: 3, icon: StreamIcon, hoverText: "text", value: "StreamIcon" },
  {
    id: 4,
    icon: CheckedCircleIcon,
    hoverText: "text",
    value: "CheckedCircleIcon",
  },
  { id: 5, icon: DateIcon, hoverText: "text", value: "DateIcon" },
  { id: 6, icon: NotesLineIcon, hoverText: "text", value: "NotesLineIcon" },
];

type MultiIconButtonProps = {
  btnSize?: number;
  iconSize?: number;
  disableHoverText?: boolean;
};

const MultiIconButton: React.FC<MultiIconButtonProps> = ({
  disableHoverText = false,
  btnSize = 25,
  iconSize = 20,
}) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        gap: "10px",
      }}
    >
      {iconsList.map((icon, index) => (
        <Tooltip
          title={icon.hoverText}
          placement="top"
          arrow
          disableHoverListener={disableHoverText}
          key={index}
        >
          <MediumSquareButton
            buttonIcon={icon.icon}
            iconSize={iconSize}
            sx={{ height: btnSize, width: btnSize }}
          />
        </Tooltip>
      ))}
    </Stack>
  );
};

export default MultiIconButton;
