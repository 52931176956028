import React, { useState } from "react";
import { Stack, TextField } from "@mui/material";

interface EmailViewProps {
  initialValue?: string;
  onChange?: (email: string) => void;
}

const EmailView: React.FC<EmailViewProps> = ({
  initialValue = "",
  onChange,
}) => {
  const [email, setEmail] = useState<string>(initialValue);
  const [isValid, setIsValid] = useState<boolean>(true);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEmail(value);

    // Validate email using a regular expression
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const valid = emailPattern.test(value);
    setIsValid(valid);

    if (valid && onChange) {
      onChange(value); // Pass the valid email back to the parent component
    }
  };

  return (
    <Stack>
      <TextField
        label="" // Remove the label
        value={email}
        onChange={handleEmailChange}
        error={!isValid}
        helperText={!isValid ? "Please enter a valid email." : ""}
        placeholder="user@example.com"
        fullWidth
        variant="outlined" // Use outlined variant for better control over styles
        sx={{
          "& .MuiOutlinedInput-root": {
            border: "none", // Remove border
            "& fieldset": {
              border: "none", // Remove fieldset border
            },
            "&:hover fieldset": {
              border: "none", // Remove border on hover
            },
            "&.Mui-focused fieldset": {
              border: "none", // Remove border on focus
              outline: "none", // Remove outline on focus
            },
          },
          padding: 0,
          font: "normal normal 300 13px/17px Source Serif Pro",
        }}
      />
    </Stack>
  );
};

export default EmailView;
