import React, { useState, useEffect } from "react";
import { Box, Modal, TextField, Zoom } from "@mui/material";
import {
  AddWatermark,
  LockItemCloudStorage,
  MoveOrCopyItem,
  SetItemExpiration,
  UploadNewVersion,
} from "../../../../common";
import DeleteItemCloud from "../../../../common/DeleteItemCloud/DeleteItemCloud";

interface FileActionFormState {
  newFileName: string;
  selectedFolder: string;
  isLocked: boolean;
  lockedUntil: string | null;
  setExpiryDate: boolean;
  expiryDate: string | null;
  enableWatermark: boolean;
  description: string;
  tags: any;
}

interface FileActionDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (payload: any) => void;
  action:
    | "Rename"
    | "Move"
    | "Set Expiration"
    | "Delete"
    | "Lock"
    | "Watermark"
    | "Upload New Version";
  folders: any;
  fileData: any; // New prop to receive original file data
  deleteFileAction?: any;
}

const FileActionDialog: React.FC<FileActionDialogProps> = ({
  open,
  onClose,
  onSubmit,
  action,
  folders,
  fileData,
  deleteFileAction,
}) => {
  const [formState, setFormState] = useState<FileActionFormState>({
    newFileName: "",
    selectedFolder: "",
    isLocked: false,
    lockedUntil: null,
    setExpiryDate: false,
    expiryDate: null,
    enableWatermark: false,
    tags: null,
    description: "",
  });

  // Populate fields with original file data when the dialog opens
  useEffect(() => {
    if (fileData) {
      setFormState({
        newFileName: fileData?.originalName || "",
        selectedFolder: fileData?.directory || "",
        isLocked: fileData?.locking?.isLocked || false,
        lockedUntil: fileData?.locking?.lockedUntil || null,
        setExpiryDate: !!fileData?.setExpiryDate,
        expiryDate: fileData?.setExpiryDate || null,
        enableWatermark: fileData?.enableWatermark || null,
        tags: fileData?.tags || null,
        description: fileData?.description || "",
      });
    }
  }, [fileData]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFormSubmit = (updatedFormState?: FileActionFormState) => {
    const stateToUse = updatedFormState || formState; // Use the passed updated state or the current formState
    const payload = {
      name: stateToUse.newFileName,
      locking: {
        isLocked: stateToUse.isLocked,
        lockedUntil: stateToUse.isLocked ? stateToUse.lockedUntil : null,
      },
      setExpiryDate: stateToUse.setExpiryDate ? stateToUse.expiryDate : null,
      folderLocation: stateToUse.selectedFolder
        ? stateToUse.selectedFolder.startsWith("/")
          ? stateToUse.selectedFolder
          : `/${stateToUse.selectedFolder}`
        : "",

      enableWatermark: stateToUse.enableWatermark, // Add watermark logic if needed
      description: stateToUse.description,
      tags: stateToUse.tags,
    };
    console.log(payload, "payload");
    onSubmit(payload); // Send payload to parent component or API
  };

  // Conditional rendering based on action type
  const renderActionComponent = () => {
    switch (action) {
      case "Move":
        return (
          <MoveOrCopyItem
            folderData={folders}
            onSelectFolder={(folder: any) => {
              setFormState((prevState) => {
                const updatedState = {
                  ...prevState,
                  selectedFolder: folder,
                };
                handleFormSubmit(updatedState); // Pass updated state to handleFormSubmit
                return updatedState; // Return the updated state
              });
            }}
          />
        );
      case "Set Expiration":
        return (
          <SetItemExpiration
            initialDate={formState?.expiryDate || "2024-09-27T13:57:34.456Z"}
            onSave={(formattedDate: string | null) => {
              setFormState((prevState) => {
                const updatedState = {
                  ...prevState,
                  setExpiryDate: !!formattedDate,
                  expiryDate: formattedDate,
                };
                handleFormSubmit(updatedState); // Pass updated state to handleFormSubmit
                return updatedState; // Return the updated state
              });
            }}
          />
        );
      case "Delete":
        return (
          <DeleteItemCloud
            onClose={onClose}
            itemName={formState?.newFileName} // Assuming this is the file name you want to match
            fileId={fileData?._id} // Pass the fileId directly from fileData
            onDelete={deleteFileAction} // Directly pass the delete action function
          />
        );

      case "Lock":
        return (
          <LockItemCloudStorage
            initialLockState={formState.isLocked}
            initialLockUntil={formState.lockedUntil}
            onSave={(isLocked: boolean, lockedUntil: string | null) => {
              setFormState((prevState) => {
                const updatedState = {
                  ...prevState,
                  isLocked,
                  lockedUntil,
                };
                handleFormSubmit(updatedState); // Pass updated state to handleFormSubmit
                return updatedState; // Return the updated state
              });
            }}
          />
        );

      case "Watermark":
        return (
          <AddWatermark
            initialWatermarkState={formState.enableWatermark} // Assuming formState has this property
            onSave={(enableWatermark: boolean) => {
              setFormState((prevState) => {
                const updatedState = {
                  ...prevState,
                  enableWatermark,
                };
                handleFormSubmit(updatedState); // Pass updated state to handleFormSubmit
                return updatedState; // Return the updated state
              });
            }}
          />
        );
      case "Rename":
        return (
          <div>
            {/* <TextField
              label="File Name"
              name="newFileName"
              value={formState.newFileName}
              onChange={handleChange}
              fullWidth
              margin="normal"
            /> */}
            NO UI FOUND
          </div>
        );
      case "Upload New Version":
        return (
          <div>
            <UploadNewVersion onClose={onClose} fileId={fileData?._id} />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div>
        <Zoom in={open}>
          <Box sx={style}>{renderActionComponent()}</Box>
        </Zoom>
      </div>
    </Modal>
  );
};

export default FileActionDialog;
const style = {
  position: "absolute" as "absolute",
  top: "18%",
  left: "35%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
};
