import React, { Suspense, useEffect, useState } from "react";
import { Box, Fade, Stack } from "@mui/material";
import { useLocation } from "react-router-dom";
import HeaderView from "./HeaderView/HeaderView";
import { MultiIconButton } from "../../CRM/common";
import { TabButton } from "./TabButton";
import { TabLoader } from "../../../common";
import { useDispatch, useSelector } from "react-redux";
import { changeLayoutState } from "../../../../redux";
import { RootState } from "../../../../redux/rootReducer";

// Lazy load the components
const EmployeeTrax = React.lazy(() => import("./EmployeeTrax/EmployeeTrax"));
const EmpPerformance = React.lazy(
  () => import("./EmpPerformance/EmpPerformance")
);

interface TraxOverviewProps {}

const TraxOverview: React.FC<TraxOverviewProps> = () => {
  const drawerState = useSelector((state: RootState) => state && state.layout);
  const { isDrawer_Trax } = drawerState || {};
  const dispatch = useDispatch();
  const [tabName, setTabName] = useState<"trax" | "performance">("trax");
  const { pathname } = useLocation();

  const previousState = isDrawer_Trax;

  const id = pathname.split("/")[3];
  const props = { empId: id };

  let Component: React.LazyExoticComponent<React.FC<any>> | null = null;
  switch (tabName) {
    case "trax":
      Component = EmployeeTrax;
      break;
    case "performance":
      Component = EmpPerformance;
      break;
    default:
      Component = null;
  }

  useEffect(() => {
    dispatch(
      changeLayoutState({
        type: "isDrawer_Trax",
        value: false,
      })
    );

    return () => {
      dispatch(
        changeLayoutState({
          type: "isDrawer_Trax",
          value: previousState,
        })
      );
    };
  }, []);

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Fade in={true} timeout={{ enter: 200, exit: 200 }}>
        <Box sx={{ width: "100%" }}>
          <Stack
            sx={{
              padding: "0px 20px 0px 15px",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <HeaderView />
            <MultiIconButton />
          </Stack>
          <TabButton
            selectedPage={tabName}
            btnSelect={(value) => setTabName(value)}
          />
          <Stack
            sx={{ height: "calc(100vh - 160px)", padding: "0px 25px 0px 15px" }}
          >
            {Component ? (
              <Suspense fallback={<TabLoader />}>
                <Component {...props} />
              </Suspense>
            ) : null}
          </Stack>
        </Box>
      </Fade>
    </Box>
  );
};

export default TraxOverview;
