import { Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import { CustomCheckboxDropdownMultiple } from "../../../../../../../../common/CustomCheckboxDropdownMultiple";

interface ColorPickerOption {
  value: string; // The label or name of the option
  color: string; // The associated color (e.g., #ffcc00)
}

interface ColorPickerViewProps {
  options: ColorPickerOption[]; // The list of available color options
  initialValue?: string[]; // Initial selected color(s)
  onChange?: (selectedColors: string[]) => void; // Callback to handle the selected color values
}

const ColorPickerView: React.FC<ColorPickerViewProps> = ({
  options,
  initialValue = [],
  onChange,
}) => {
  const [selectedColors, setSelectedColors] = useState<string[]>([]);

  // Update selectedColors only when initialValue changes and is not empty
  useEffect(() => {
    if (initialValue && initialValue?.length > 0) {
      setSelectedColors(initialValue);
    }
  }, [initialValue]);

  const handleSelectionChange = (data: {
    selectedOption: string[];
    updatedOptions: { value: string; color: string }[];
  }) => {
    // Combine existing colors with new selections
    const combinedValues = Array.from(new Set([...selectedColors, ...data.selectedOption]));
    setSelectedColors(combinedValues);
    
    // Call the onChange callback with the updated color selections
    if (onChange) {
      onChange(combinedValues);
    }
  };

  return (
    <Stack>
      <CustomCheckboxDropdownMultiple
        badgeWithoutText
        placeHolder="Select colors..."
        options={options}
        initialValues={selectedColors} // Use selectedColors as the initial selected values
        onChange={handleSelectionChange} // Handle selection change events
      />
    </Stack>
  );
};

export default ColorPickerView;
