import React from 'react';

interface CreateQuickTaskViewProps {
  
}

const CreateQuickTaskView: React.FC<CreateQuickTaskViewProps> = ({  }) => {
  return <div>-- no preview --</div>;
};

export default CreateQuickTaskView;