import React from "react";
import { useLocation } from "react-router-dom";
import { pathToDrawerStateKey } from "../CommanDrawerType";
import {
  DashBoardDrawer,
  SettingsDrawer,
  CallsDrawer,
  EmailDrawer,
  PlanDrawer,
  WorkspaceDrawer,
  MessageDrawer,
  CrmDrawer,
  TraxDrawer,
  ProjectDrawer,
  CloudStorageDrawer,
} from "../../../common";
import { LayoutState } from "../../../../redux";

/** Props interface for DrawerManager component */
interface DrawerManagerProps {
  drawerOpen: LayoutState;
  handleChangeDrawer: () => void;
}

/** Object mapping route paths to corresponding drawer components */
const DrawerComponents: {
  [key: string]: React.ComponentType<{
    drawerOpen: boolean;
    drawerCloser: () => void;
  }>;
} = {
  "/dashboard": DashBoardDrawer,
  "/Calls": CallsDrawer,
  "/Email": EmailDrawer,
  "/Calendar": PlanDrawer,
  "/workspace": WorkspaceDrawer,
  "/Message": MessageDrawer,
  "/CRM": CrmDrawer,
  "/Trax": TraxDrawer,
  "/Settings": SettingsDrawer,
  "/Project": ProjectDrawer,
  "/cloud-storage/": CloudStorageDrawer,
};

/**
 * DrawerManager component dynamically renders drawer component based on current route
 * @param drawerOpen - Object containing drawer open states
 * @param handleChangeDrawer - Function to handle drawer open state change
 * @returns JSX.Element - Drawer component
 */
const DrawerManager: React.FC<DrawerManagerProps> = ({
  drawerOpen,
  handleChangeDrawer,
}) => {
  const location = useLocation();

  // Get the appropriate drawer component based on current route
  const drawerKey = Object.keys(DrawerComponents).find((key) =>
    location.pathname.startsWith(key)
  );

  const DrawerComponent = drawerKey ? DrawerComponents[drawerKey] : null;

  // Get drawer state key from pathToDrawerStateKey mapping
  const drawerStateKey = drawerKey
    ? pathToDrawerStateKey[drawerKey]
    : undefined;

  // Determine if drawer should be open based on drawer state key
  const isDrawerOpen = drawerStateKey ? drawerOpen[drawerStateKey] : false;



  return (
    <div className="d">
      {DrawerComponent && (
        <DrawerComponent
          drawerOpen={isDrawerOpen}
          drawerCloser={handleChangeDrawer}
        />
      )}
    </div>
  );
};

export default DrawerManager;
