import React, { useState } from "react";
import { Box, IconButton, Popover } from "@mui/material";
import { AddCalPlusIcon } from "../../../../../images";
import { addColumnOption } from "../../components";
import { DragAndDropMenu, DragAndDropMenuListType } from "../../../../common";

type AddColumnCrmTableProps = {};

const styleBox = {
  borderTop: "1px solid #E9EDF2",
  borderBottom: "1px solid #E9EDF2",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "4.5px",
  padding: "5px 0 5px 0",
};

const AddColumnCrmTable = (props: AddColumnCrmTableProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Initialize menuItems based on componentType
  const [menuItems, setMenuItems] =
    useState<DragAndDropMenuListType[]>(addColumnOption);
  return (
    <Box style={styleBox}>
      <IconButton onClick={handleClick}>
        <AddCalPlusIcon w={17} />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <DragAndDropMenu
          label="Add new columns"
          menuItems={menuItems}
          setMenuItems={setMenuItems}
          AddButtonLabel="Create a new type"
          AddButtonClick={() => null}
        />
      </Popover>
    </Box>
  );
};

export default AddColumnCrmTable;
