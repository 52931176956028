import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../Utils";
import { StreamBearishOSLogoIconPng } from "../../../../../images";
import styles from "./PastCallCard.module.css";

interface PastCallCardProps {
  isSelected?: boolean;
  meetingDetail?: string;
  meetingTime?: string;
  meetingId?: string;
  handleMeetingClick?: any;
  meeting_Id?: string;
}

const PastCallCard: React.FC<PastCallCardProps> = ({
  isSelected = false,
  meetingDetail = "Instant Meeting",
  meetingTime = "18-11-2024 - 15:23",
  meetingId,
  handleMeetingClick,
  meeting_Id,
}) => {
  return (
    <Stack className={styles.PastCallCard}>
      <Stack className={styles.PastCallCard__indicator} />
      <Stack
        className={styles.PastCallCard__content}
        sx={{ bgcolor: isSelected ? adjustHexColor("#E9EDF2", 35) : "inherit" }}
        onClick={() => handleMeetingClick(meetingId)}
      >
        <Stack className={styles.PastCallCard__header}>
          <Box
            component={"img"}
            src={StreamBearishOSLogoIconPng}
            sx={{ height: "20px", width: "20px" }}
            alt="logo"
          />
          <Typography className={styles.PastCallCard__meetingName}>
            {meetingDetail}
          </Typography>
        </Stack>
        <Typography className={styles.PastCallCard__time}>
          {meetingTime}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default PastCallCard;
