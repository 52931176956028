import { Stack, Tooltip, Typography } from "@mui/material";
import React from "react";

interface DetailsViewProps {
  icon: React.ElementType;
  iconHoverText?: string;
  value: string;
}

const DetailsView: React.FC<DetailsViewProps> = ({
  icon,
  value,
  iconHoverText,
}) => {
  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
      <Tooltip title={iconHoverText} placement="top" arrow disableInteractive>
        <span style={{ flexShrink: 0 }}>
          {React.createElement(icon, { w: 20, color: "black" })}
        </span>
      </Tooltip>
      <Typography
        sx={{
          font: "normal normal 300 13px/16px Source Serif Pro",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {value}
      </Typography>
    </Stack>
  );
};

export default DetailsView;
