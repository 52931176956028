import { Stack, Typography } from "@mui/material";
import React from "react";
import { AvatarNormal } from "../AvatarNormal";
import { InternalSharedButton } from "../Buttons/InternalSharedButton";
import { InternalShareButtonOptionValue } from "../Buttons/AllButtonProps";
import styles from "./ItemInsightsAccessCard.module.css";

interface ItemInsightsAccessCardProps {
  userNameOrEmail: string;
  userIconSrc?: string;
  timestamp: string;
  datestamp?: string;
  userRole?: string;
}

const ItemInsightsAccessCard: React.FC<ItemInsightsAccessCardProps> = ({
  timestamp,
  userNameOrEmail,
  userIconSrc,
  userRole,
}) => {
  const formatDate = (timestamp: string) => {
    const date = new Date(timestamp);

    // Format the date part (e.g., "October 1, 2024")
    const formattedDate = date?.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });

    // Format the time part (e.g., "9:37 AM")
    const formattedTime = date?.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    // Combine date and time with "at"
    return `${formattedDate} at ${formattedTime}`;
  };

  return (
    <Stack className={styles["item-insights-access-card"]}>
      <Stack className={styles["item-insights-access-card__details"]}>
        <AvatarNormal
          size={40}
          username={userNameOrEmail}
          imgSrc={userIconSrc}
        />
        <Stack className={styles["item-insights-access-card__text-container"]}>
          <Typography className={styles["item-insights-access-card__username"]}>
            {userNameOrEmail}
          </Typography>
          <Typography
            className={styles["item-insights-access-card__timestamp"]}
          >
            {formatDate(timestamp)}
          </Typography>
        </Stack>
      </Stack>
      <InternalSharedButton
        selectedOption={InternalShareButtonOptionValue.Guest}
        onSelectionChange={(value:any) => null}
        userRole={userRole}
      />
    </Stack>
  );
};

export default ItemInsightsAccessCard;
