import { Stack } from "@mui/material";
import React, { useState } from "react";
import ViewHeaderButton from "../ViewHeaderButton/ViewHeaderButton";
import DefaultPlanEventView from "./screen/DefaultPlanEventView";
import CreatePlanEventView from "./screen/CreatePlanEventView";

interface PlanEventViewProps {
  onBackButtonClick: () => void;
}

const PlanEventView: React.FC<PlanEventViewProps> = ({ onBackButtonClick }) => {
  const [eventScreen, setEventScreen] = useState<"default" | "create">(
    "default"
  );
  return (
    <Stack sx={{ flex: 1, height: "100%" }}>
      {eventScreen === "default" ? (
        <Stack>
          <ViewHeaderButton
            headerText="All events"
            createNewButtonText="Create new event"
            onBackButtonClick={onBackButtonClick}
            onCreateButtonClick={() => setEventScreen("create")}
          />
            <DefaultPlanEventView />
        </Stack>
      ) : (
        <CreatePlanEventView />
      )}
    </Stack>
  );
};

export default PlanEventView;
