import { PayloadAction } from "@reduxjs/toolkit";
import { CRMCenterState } from "../CRMCenterType";

/* Add Contact */
export const addContact__Failure = (
    state: CRMCenterState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

/* Get One Contact */
export const getOneContact__Failure = (
    state: CRMCenterState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

/* Update One Contact */
export const updateOneContact__Failure = (
    state: CRMCenterState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

/* Delete One Contact */
export const deleteOneContact__Failure = (
    state: CRMCenterState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

/* Add Business */
export const addBusiness__Failure = (
    state: CRMCenterState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

/* Get One Business */
export const getOneBusiness__Failure = (
    state: CRMCenterState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

/* Update One Business */
export const updateOneBusiness__Failure = (
    state: CRMCenterState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

/* Delete One Business */
export const deleteOneBusiness__Failure = (
    state: CRMCenterState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

/* Update Custom Elements */
export const updateCustomElements__Failure = (
    state: CRMCenterState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

/* Get All Contacts */
export const getAllContacts__Failure = (
    state: CRMCenterState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

/* Get All Business */
export const getAllBusiness__Failure = (
    state: CRMCenterState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

/* Create Group Failure */
export const createGroup__Failure = (
    state: CRMCenterState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

/* Get All Groups Failure */
export const getAllGroups__Failure = (
    state: CRMCenterState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

/* Get Group By ID Failure */
export const getGroupById__Failure = (
    state: CRMCenterState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

/* Update Group Failure */
export const updateGroup__Failure = (
    state: CRMCenterState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

/* Delete Group Failure */
export const deleteGroup__Failure = (
    state: CRMCenterState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

/* Add to Group Failure */
export const addToGroup__Failure = (
    state: CRMCenterState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

/* Create Deal Failure */
export const createDeal__Failure = (
    state: CRMCenterState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

/* Get All Deals Failure */
export const getAllDeals__Failure = (
    state: CRMCenterState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

/* Get Deal By ID Failure */
export const getDealById__Failure = (
    state: CRMCenterState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

/* Update Deal Failure */
export const updateDeal__Failure = (
    state: CRMCenterState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

/* Delete Deal Failure */
export const deleteDeal__Failure = (
    state: CRMCenterState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

/* Add Note to Deal Failure */
export const addNoteTo__Failure = (
    state: CRMCenterState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

export const getNoteTo__Failure = (
    state: CRMCenterState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};


/* OutLook === */
/* Get All Outlook Contacts Failure */
export const getAllOutlookContacts__Failure = (
    state: CRMCenterState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

/* Get Outlook Contact By ID Failure */
export const getOutlookContactById__Failure = (
    state: CRMCenterState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

/* Create Outlook Contact Failure */
export const createOutlookContact__Failure = (
    state: CRMCenterState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

/* Update Outlook Contact Failure */
export const updateOutlookContact__Failure = (
    state: CRMCenterState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

/* OutLook === */
