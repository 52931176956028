import * as React from "react";
import { useAutocomplete } from "@mui/base/useAutocomplete";
import { DropdownWithImgBadgeInterface } from "./DropdownWithImageBadgeTypes";
import { AvatarNormal } from "../AvatarNormal";
import { Stack, Typography } from "@mui/material";
import { InputWrapper, Listbox, Root, StyledTag, UserCount } from "./view";

/**
 * Props for the CustomizedHook component.
 */
interface CustomizedHookProps {
  options?: DropdownWithImgBadgeInterface[];
  initialData?: DropdownWithImgBadgeInterface[];
  onChangeSelection: (selectedOptions: DropdownWithImgBadgeInterface[]) => void;
  showMaxSelected?: number;
  disableAddUserInList?: boolean;
  placeholder?: string;

  /**
   * Placeholder show always at place of input area
   * default is false
   */
  alwaysShowPlaceholder?: boolean;

  /**
   * If set to true, this component will be in read-only mode.
   * In this mode, no options can be selected or deselected.
   * The default value is false.
   */
  readonlyComponent?: boolean;
}

export default function CustomizedHook({
  options,
  initialData = [],
  onChangeSelection,
  showMaxSelected,
  disableAddUserInList = false,
  readonlyComponent = false,
  alwaysShowPlaceholder = false,
  placeholder,
}: CustomizedHookProps) {
  const [inputValue, setInputValue] = React.useState("");
  const [customOptions, setCustomOptions] = React.useState<
    DropdownWithImgBadgeInterface[]
  >(options || []);
  const [selectedUserList, setSelectedUserList] =
    React.useState<DropdownWithImgBadgeInterface[]>(initialData);
  const [primaryUserList, setPrimaryUserList] = React.useState<
    DropdownWithImgBadgeInterface[]
  >([]);

  const [additionalUserList, setAdditionalUserList] = React.useState<
    DropdownWithImgBadgeInterface[]
  >([]);

  const isValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.com$/.test(email);
  };

  React.useEffect(() => {
    // Check if the input value is a valid email
    if (isValidEmail(inputValue) && !disableAddUserInList) {
      const emailExists = customOptions?.some(
        (option) => option.email === inputValue
      );

      if (!emailExists) {
        // Add the new valid email option for display
        setCustomOptions((prev: any) => [
          ...prev,
          {
            name: `${inputValue}`,
            email: inputValue,
            userIconSrc: "",
            _id: null,
          },
        ]);
      }
    }
  }, [inputValue, customOptions, disableAddUserInList]);

  const userListSeperator = () => {
    if (showMaxSelected && selectedUserList?.length > showMaxSelected) {
      setPrimaryUserList(selectedUserList?.slice(0, showMaxSelected));
      setAdditionalUserList(selectedUserList?.slice(showMaxSelected));
    } else {
      setPrimaryUserList(selectedUserList);
      setAdditionalUserList([]);
    }
  };

  React.useEffect(() => {
    userListSeperator();
  }, [selectedUserList]);

  const changeHandler = (newValue: DropdownWithImgBadgeInterface[]) => {
    setSelectedUserList(newValue);
    onChangeSelection(newValue);
  };

  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: "customized-hook-demo",
    multiple: true,
    options: customOptions,
    readOnly: readonlyComponent,
    getOptionLabel: (option) =>
      option?.email + " - " + (option?.name || option?.displayName),
    defaultValue: initialData,
    inputValue: inputValue,
    onInputChange: (event, newInputValue) => {
      setInputValue(newInputValue);
    },
    onChange: (event, newValue) => {
      changeHandler(newValue);
    },
  });

  return (
    <Root>
      <div {...getRootProps()}>
        <InputWrapper
          ref={setAnchorEl}
          className={focused ? "focused" : ""}
          sx={{ bgcolor: "transparent" }}
        >
          {primaryUserList.map(
            (option: DropdownWithImgBadgeInterface, index: number) => {
              const { key, ...tagProps } = getTagProps({ index });
              return (
                <StyledTag
                  key={key}
                  {...tagProps}
                  clendarInviteStatus={option?.calendarInviteStatus as any}
                  userData={option}
                  popoverPlace="top"
                />
              );
            }
          )}
          {showMaxSelected && additionalUserList.length > 0 && (
            <UserCount
              getTagProps={getTagProps}
              startIndex={showMaxSelected}
              userList={additionalUserList}
            />
          )}
          <input
            {...getInputProps()}
            readOnly={readonlyComponent}
            value={inputValue}
            style={{ minWidth: "50px", backgroundColor: "transparent" }}
            placeholder={
              alwaysShowPlaceholder
                ? placeholder
                : placeholder && primaryUserList.length === 0
                ? placeholder
                : ""
            }
          />
        </InputWrapper>
      </div>
      {groupedOptions?.length > 0 ? (
        <Listbox
          sx={{ zIndex: "2001 !important", position: "relative !important" }}
          {...getListboxProps()}
        >
          {(groupedOptions as DropdownWithImgBadgeInterface[])?.map(
            (option, index) => {
              const { ...optionProps } = getOptionProps({ option, index });
              return (
                <li
                  {...optionProps}
                  style={{
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  <AvatarNormal
                    username={
                      option?.name ||
                      option?.firstName + " " + option?.lastName ||
                      option?.displayName
                    }
                    size={30}
                    imgSrc={option?.userIconSrc || option?.avatar}
                  />
                  <Stack>
                    <Typography
                      sx={{
                        font: "normal normal 400 14px/18px Source Serif Pro",
                      }}
                    >
                      {option?.name ||
                        option?.firstName + " " + option?.lastName ||
                        option?.displayName}
                    </Typography>
                    <Typography
                      sx={{
                        font: "normal normal 300 12px/14px Source Serif Pro",
                      }}
                    >
                      {option?.email}
                    </Typography>
                  </Stack>
                </li>
              );
            }
          )}
        </Listbox>
      ) : null}
    </Root>
  );
}
