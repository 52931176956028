import { Box, Rating, Stack, Typography } from "@mui/material";
import React from "react";
import { RatingStarIcon, WebexPng } from "../../../../../../../images";
import {
  ButtonSmall,
  ButtonType,
  CompanyLongDropdown,
  DropdownWithColor,
  DUMMY_CompanyLongDropdownOption,
  DUMMY_DEALSTAGE_OPTIONS,
  LargeInputBox,
  SingleDatePickerSmall,
  SingleTeammateDropdown,
  SmallButtonColor,
} from "../../../../../../common";
import { adjustHexColor } from "../../../../../../../Utils";
import M from "../../AddDealPopupLarge.module.css";
import S from "./DealStageView.module.css";

interface DealStageViewProps {}

const DealStageView: React.FC<DealStageViewProps> = ({}) => {
  return (
    <>
      <Stack className={M["add-deal-popup-large__header"]}>
        <Box
          component={"img"}
          sx={{ height: "15px", width: "15px" }}
          src={WebexPng}
          alt="logo"
        />
        <Typography
          sx={{ font: "normal normal normal 14px/20px Source Serif Pro" }}
        >
          Webex
        </Typography>
      </Stack>

      {/* Card body */}
      <Stack className={M["add-deal-popup-large__body"]}>
        <LargeInputBox
          borderColor="#E9EDF2"
          height="30px"
          label={"Deal name"}
          value=""
          onChange={(value) => null}
        />

        <Stack className={S["deal-stage-view__row"]}>
          <DropdownWithColor
            options={DUMMY_DEALSTAGE_OPTIONS}
            label="Deal stage"
            placeholder="choose one... "
            onChange={(value) => console.log("new data", value)}
            onSelect={(value) => console.log("selected option", value)}
          />

          <SingleTeammateDropdown label="Contact" placeholder="select user" />

          <CompanyLongDropdown
            width="100%"
            label="Associated company"
            options={DUMMY_CompanyLongDropdownOption}
          />
        </Stack>

        <SingleTeammateDropdown
          width="100%"
          label="Associated people"
          placeholder="select user"
        />

        <LargeInputBox
          height="30px"
          borderColor="#E9EDF2"
          label={"Deal value"}
          value=""
          onChange={(value) => null}
        />

        <Stack className={S["deal-stage-view__row--priority"]}>
          <DropdownWithColor
            options={DUMMY_DEALSTAGE_OPTIONS}
            label="Priority"
            placeholder="choose one... "
            onChange={(value) => console.log("new data", value)}
            onSelect={(value) => console.log("selected option", value)}
          />

          <SingleDatePickerSmall label="Projected close date" />

          <Stack className={S["deal-stage-view__confidence"]}>
            <Typography
              sx={{
                font: "normal normal 300 10px/13px Source Serif Pro",
                marginBottom: "5px",
              }}
            >
              Close confidence
            </Typography>
            <Rating
              name="read-only"
              value={3}
              // readOnly
              className={S["deal-stage-view__rating"]}
              emptyIcon={
                <RatingStarIcon w={25} color={adjustHexColor("#E2875E", 20)} />
              }
              icon={<RatingStarIcon w={25} color="#E2875E" />}
            />
          </Stack>

          <DropdownWithColor
            options={DUMMY_DEALSTAGE_OPTIONS}
            label="Close-lost reason"
            placeholder="choose one... "
            onChange={(value) => console.log("new data", value)}
            onSelect={(value) => console.log("selected option", value)}
          />
        </Stack>

        <LargeInputBox
          height="30px"
          borderColor="#E9EDF2"
          label={"Details"}
          value=""
          onChange={(value) => null}
        />

        {/* Buttons */}
        <Stack className={S["deal-stage-view__buttons"]}>
          <ButtonSmall
            label="Cancel"
            types={ButtonType.Button}
            colorVarient={SmallButtonColor.Transparent}
          />
          <ButtonSmall
            label="Save"
            types={ButtonType.Button}
            colorVarient={SmallButtonColor.CRMOrange}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default DealStageView;
