import React from "react";
import { IconButton, Stack, IconButtonProps } from "@mui/material";
import { adjustHexColor } from "../../../../../Utils";
import {
  NotesLineIcon,
  EmailIcon,
  CheckedCircleIcon,
  QuickMessageIcon,
  VideoCallIcon,
  DateIcon,
} from "../../../../../images";
import { ColoredBadge } from "../../../ColoredBadge";
import { QuickActionDisplayTypesEnum } from "../../QuickActionsTypes";

type BadgeButtonProps = IconButtonProps & {
  bgColor: string;
  title: string;
  icon: React.ReactNode;
};

// inline component
const BadgeButton: React.FC<BadgeButtonProps> = ({
  bgColor,
  title,
  icon,
  ...props
}) => (
  <IconButton sx={{ p: 0, borderRadius: "5px", color: "black" }} {...props}>
    <ColoredBadge
      bgColor={adjustHexColor(bgColor, 25)}
      title={title}
      ColoredBadgeFont="normal normal 900 14px/18px Source Serif Pro"
      showIcon={true}
      icon={icon}
      sx={{
        maxWidth: "110px",
        flexShrink: 0,
        minWidth: "110px",
        height: "40px",
        p: 0,
      }}
    />
  </IconButton>
);

interface BadgeButtonViewProps {
  setState: (value: QuickActionDisplayTypesEnum) => void;
}

const BadgeButtonsView: React.FC<BadgeButtonViewProps> = ({ setState }) => {
  const buttons: {
    bgColor: string;
    title: string;
    icon: React.ReactNode;
    value: QuickActionDisplayTypesEnum;
  }[] = [
    {
      bgColor: "#A1FF9F",
      title: "Notes",
      icon: <NotesLineIcon w={20} />,
      value: QuickActionDisplayTypesEnum.Notes,
    },
    {
      bgColor: "#FFDCB2",
      title: "New email",
      icon: <EmailIcon w={20} />,
      value: QuickActionDisplayTypesEnum.Email,
    },
    {
      bgColor: "#CFA0FF",
      title: "New task",
      icon: <CheckedCircleIcon w={20} />,
      value: QuickActionDisplayTypesEnum.Task,
    },
    {
      bgColor: "#73F9FF",
      title: "Message",
      icon: <QuickMessageIcon w={20} />,
      value: QuickActionDisplayTypesEnum.Message,
    },
    {
      bgColor: "#FFE81D",
      title: "Start call",
      icon: <VideoCallIcon w={20} />,
      value: QuickActionDisplayTypesEnum.Call,
    },
    {
      bgColor: "#E45E5E",
      title: "Plan event",
      icon: <DateIcon w={20} />,
      value: QuickActionDisplayTypesEnum.Event,
    },
  ];

  return (
    <Stack
      sx={{
        flexWrap: "wrap",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: "15px 8px",
      }}
    >
      {buttons.map((button, index) => (
        <BadgeButton
          onClick={() => setState(button.value)}
          key={index}
          bgColor={button.bgColor}
          title={button.title}
          icon={button.icon}
        />
      ))}
    </Stack>
  );
};

export default BadgeButtonsView;
