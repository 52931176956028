import { Stack, SxProps, Typography } from "@mui/material";
import React from "react";

interface DetailsViewAreaProps {
  label: string;
  children: React.ReactNode;
  sx?: SxProps;
  hideBorderRight?: boolean;
}

const DetailsViewArea: React.FC<DetailsViewAreaProps> = ({
  children,
  label,
  hideBorderRight = false,
  sx,
}) => {
  return (
    <Stack
      sx={{
        flex: 1,
        minHeight: "75px",
        boxSizing: "border-box",
        padding: "10px 15px",
        gap: "10px",
        borderRight: hideBorderRight ? "1px solid white" : "1px solid #E9EDF2",
        overflow: "hidden",
        ...sx,
      }}
    >
      <Typography sx={{ font: "normal normal 600 11px/14px Source Serif Pro" }}>
        {label}
      </Typography>
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
        {children}
      </Stack>
    </Stack>
  );
};

export default DetailsViewArea;
