import { Stack, Typography } from "@mui/material";
import React from "react";

interface DateViewProps {
  date: string;
}

const DateView: React.FC<DateViewProps> = ({ date }) => {
  return (
    <Stack
      sx={{
        width: "55px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          font: "normal normal 400 14px/18px Source Serif Pro",
        }}
      >
        {date}
      </Typography>
    </Stack>
  );
};

export default DateView;
