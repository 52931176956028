import { Box, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { msalInstance } from "../../../components/pages/Email/components/authConfig";
import { getAllEmails__api } from "./emailActions";
import { getUser } from "../../../components/common/LocalStorage";

export const fetchEmailsFromBackend = async (
  accessToken: string,
  dispatch: any,
  query: string
): Promise<void> => {
  try {
    await dispatch(getAllEmails__api(accessToken, query));
  } catch (err) {
    console.error("Error fetching emails:", err);
  }
};

const MsalInitialize = () => {
  const [error, setError] = useState<string | null>(null);
  const [isMsalInitialized, setIsMsalInitialized] = useState<boolean>(false);
  const savedUser = getUser();
  const dispatch = useDispatch();

  useEffect(() => {
    const initializeMsal = async () => {
      try {
        // Initialize MSAL instance
        await msalInstance.initialize();
        setIsMsalInitialized(true);
        console.log("MSAL is initialized");

        const accounts = msalInstance.getAllAccounts();

        let correctAccount = null;

        if (savedUser && accounts.length > 0) {
          correctAccount = accounts.find(
            (account: any) => account.homeAccountId === savedUser
          );
        }

        // Handle silent token acquisition
        if (correctAccount) {
          try {
            const tokenResponse = await msalInstance.acquireTokenSilent({
              account: correctAccount,
              // scopes: ['Mail.Read'],

              scopes: ["Mail.Read", "User.Read", "Mail.ReadWrite", "Mail.Send"],
            });
            await fetchEmailsFromBackend(
              tokenResponse.accessToken,
              dispatch,
              ""
            );
          } catch (silentError) {
            console.error("Silent token acquisition failed:", silentError);
            setError("Failed to acquire token silently.");
          }
        } else if (accounts.length > 0) {
          correctAccount = accounts[0];
          localStorage.setItem("loggedInUser", correctAccount.homeAccountId);

          try {
            const tokenResponse = await msalInstance.acquireTokenSilent({
              // account: correctAccount,
              // scopes: ['Mail.Read'],
              scopes: ["Mail.Read", "User.Read", "Mail.ReadWrite", "Mail.Send"],
            });
            await fetchEmailsFromBackend(
              tokenResponse.accessToken,
              dispatch,
              ""
            );
          } catch (silentError) {
            console.error("Silent token acquisition failed:", silentError);
            setError("Failed to acquire token silently.");
          }
        }
      } catch (err) {
        console.error("MSAL Initialization Error:", err);
        setError("Failed to initialize MSAL");
      }
    };

    initializeMsal();
  }, [savedUser, dispatch]); // Add dispatch to the dependency array

  // Render the component UI
  return (
    <Box>
      {error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <Typography>
          MSAL is {isMsalInitialized ? "initialized" : "not initialized"}.
        </Typography>
      )}
    </Box>
  );
};

export default MsalInitialize;
