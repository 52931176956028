import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EmailState, reCallMAIL_APIs_service } from "./emailTypes";
import {
  /* Get all workspaces */
  getAllEmails__Request,
  getAllEmails__Failure,
  getAllEmails__Success,

  /* Delete workspace action */
  deleteEmail__Request,
  deleteEmail__Failure,
  deleteEmail__Success,
  /* get one gettings */
  getMainSettings__Request,
  getMainSettings__Failure,
  getMainSettings__Success,
  /* update settings */
  updateMainSettings__Request,
  updateMainSettings__Failure,
  updateMainSettings__Success,

  /* and so on... */
} from "./EmailSliceAction";

/*
 * ==============================================
 * Define the initial state for the email slice
 * ==============================================
 */

// emails.ts

const emailDummy = {}

const initialState: EmailState = {
  emails: {},
  error: null,
  reCallAPIs: null,
  mailSettings: null,
  data: null,
};

/*
 * ==============================================
 * Create a slice for workspace logic
 * ==============================================
 */
const emails = createSlice({
  name: "emails", // Name of the slice
  initialState, // Initial state of the slice
  reducers: {
    /* Get all emails */
    getAllEmails__Request,
    getAllEmails__Failure,
    getAllEmails__Success,

    /* Delete workspace action */
    deleteEmail__Request,
    deleteEmail__Failure,
    deleteEmail__Success,

    /* get one gettings */
    getMainSettings__Request,
    getMainSettings__Failure,
    getMainSettings__Success,
    /* update settings */
    updateMainSettings__Request,
    updateMainSettings__Failure,
    updateMainSettings__Success,
    recallAPI__MAIL: (
      state,
      { payload }: PayloadAction<reCallMAIL_APIs_service>
    ) => {
      if (payload) {
        state.reCallAPIs = payload; // Update the state with the payload value
      } else {
        state.reCallAPIs = null;
      }
    },
  },
});

/*
 * ======================================================
 * Export the actions to be used in dispatch calls
 * ======================================================
 */

export const {
  /*   
  // Function to handle adding a connected account
  const handleAddAccount = () => {
    const newAccount = {
      id: '1', // Replace with a unique identifier for the account
      name: 'My Gmail',
      type: 'gmail', // Use AccountType.GMAIL if you imported the enum
      isTokenAlive: true, // Assuming the token is valid
    };
    dispatch(addConnectedAccount(newAccount)); // Dispatch action to add the account
  };

  // Function to handle removing a connected account
  const handleRemoveAccount = (id: string) => {
    dispatch(removeConnectedAccount(id)); // Dispatch action to remove an account by ID
  };

  // Function to handle renaming a connected account
  const handleRenameAccount = (id: string, newName: string) => {
    dispatch(renameConnectedAccount({ id, newName })); // Dispatch action to rename an account
  };

  // Function to update the token status of a connected account
  const handleUpdateTokenStatus = (id: string, isTokenAlive: boolean) => {
    dispatch(updateTokenStatus({ id, isTokenAlive })); // Dispatch action to update token status
  }; 
  */
  recallAPI__MAIL,
  getAllEmails__Request: getAllEmails__RequestAction,
  getAllEmails__Failure: getAllEmails__FailureAction,
  getAllEmails__Success: getAllEmails__SuccessAction,

  /* Delete workspace action */
  deleteEmail__Request: deleteEmail__RequestAction,
  deleteEmail__Failure: deleteEmail__FailureAction,
  deleteEmail__Success: deleteEmail__SuccessAction,

  /* get one gettings */
  getMainSettings__Request: getMainSettings__RequestAction,
  getMainSettings__Failure: getMainSettings__FailureAction,
  getMainSettings__Success: getMainSettings__SuccessAction,
  /* update settings */
  updateMainSettings__Request: updateMainSettings__RequestAction,
  updateMainSettings__Failure: updateMainSettings__FailureAction,
  updateMainSettings__Success: updateMainSettings__SuccessAction,
} = emails.actions;

export default emails.reducer;
