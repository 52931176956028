import React, { useState, useEffect } from "react";
import { Avatar, Box, Stack, Typography, InputBase } from "@mui/material";
import { stringAvatar } from "../../../../../../Utils";
import { uploadFile__api } from "../../../../../../redux";
import { useDispatch } from "react-redux";

// Define the types for the component props
type HeaderType = "people" | "groups" | "company" | "deal";
type ShapeType = "sq" | "rd" | "cr";

type HeaderOverViewNameProps = {
  imageUrl?: string;
  headerType: HeaderType;
  firstName?: string;
  lastName?: string;
  name?: string; // New prop for other header types
  roundedOrSquare?: ShapeType;
  onUpdate?: any;
};

const HeaderOverViewName: React.FC<HeaderOverViewNameProps> = ({
  imageUrl = "",
  headerType,
  firstName = "",
  lastName = "",
  name, // Use the new name prop
  roundedOrSquare,
  onUpdate,
}) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [editFirstName, setEditFirstName] = useState(firstName);
  const [editLastName, setEditLastName] = useState(lastName);
  const [displayName, setDisplayName] = useState(
    headerType === "people" ? `${firstName} ${lastName}`.trim() : name || ""
  );

  // Function to generate a random color
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const avatarBorderColor = getRandomColor();

  // Handle double click to toggle editing
  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  // Handle input change for first and last name inputs
  const handleChangeFirstName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEditFirstName(event.target.value);
  };

  const handleChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditLastName(event.target.value);
  };

  const handleBlur = () => {
    let updatedFields = {};

    if (headerType === "people") {
      const fullName = `${editFirstName} ${editLastName}`.trim();
      setDisplayName(fullName);

      // Consolidate updates for first name and last name
      updatedFields = {
        firstName: editFirstName,
        lastName: editLastName,
      };
    } else if (headerType === "company") {
      // Use businessName for the company update
      updatedFields = { businessName: displayName };
    } else {
      // Handle other header types if necessary, using the displayName
      updatedFields = { dealName: displayName }; // Adjust if there are other types
    }

    // Call onUpdate with the consolidated updates
    onUpdate && onUpdate(updatedFields);
    setIsEditing(false);
  };

  // Update the display name when editing mode is exited
  useEffect(() => {
    if (!isEditing) {
      setEditFirstName(firstName);
      setEditLastName(lastName);
      setDisplayName(
        headerType === "people" ? `${firstName} ${lastName}`.trim() : name || ""
      );
    }
  }, [isEditing, firstName, lastName, headerType, name]);

  // Handle file change
  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      try {
        const formData = new FormData();
        formData.append("files", file);

        const action = uploadFile__api(formData);
        let result = "";

        try {
          const response: any = await dispatch(action);
          if (response?.success && response?.data) {
            result = response?.data?.[0]?.path; // Adjust based on your API response
          }
        } catch (e) {
          console.log(e, "Error uploading");
          return;
        }

        const mediaUrl = result || ""; // Set media URL based on API response

        if (mediaUrl) {
          if (onUpdate && headerType === "people") {
            onUpdate({ image: mediaUrl });
          } else if (onUpdate && headerType === "company") {
            onUpdate({ businessLogo: mediaUrl });
          }else if (onUpdate && headerType === "deal"){
            onUpdate({ dealIcon: mediaUrl });
          }
        }
      } catch (error) {
        alert("Error uploading media");
      }
    }
  };

  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        gap={1.5}
      >
        <label htmlFor="avatar-file-input">
          {/* Avatar Component */}
          <Avatar
            src={imageUrl}
            sx={{
              width: 50,
              height: 50,
              borderRadius:
                roundedOrSquare === "sq"
                  ? 0
                  : roundedOrSquare === "rd"
                  ? "20%"
                  : "50%",
            }}
          >
            {stringAvatar(displayName).children}
          </Avatar>
        </label>

        {/* Hidden file input */}
        <input
          id="avatar-file-input"
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={onFileChange}
        />
        <Stack
          direction="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          gap={0.5}
        >
          {isEditing ? (
            headerType === "people" ? (
              <Stack direction="row" gap={1}>
                <InputBase
                  value={editFirstName}
                  onChange={handleChangeFirstName}
                  onBlur={handleBlur}
                  sx={{
                    border: "1px dotted gray",
                    padding: "8px",
                    borderRadius: "4px",
                    bgcolor: "lightgray",
                  }}
                  placeholder="First Name"
                />
                <InputBase
                  value={editLastName}
                  onChange={handleChangeLastName}
                  onBlur={handleBlur}
                  sx={{
                    border: "1px dotted gray",
                    padding: "8px",
                    borderRadius: "4px",
                    bgcolor: "lightgray",
                  }}
                  placeholder="Last Name"
                />
              </Stack>
            ) : (
              <InputBase
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                onBlur={handleBlur}
                sx={{
                  border: "1px dotted gray",
                  padding: "8px",
                  borderRadius: "4px",
                  bgcolor: "lightgray",
                }}
                placeholder="Name"
              />
            )
          ) : (
            <Typography
              onDoubleClick={handleDoubleClick}
              sx={{
                font: "normal normal 900 20px/28px Source Serif Pro",
                cursor: "pointer",
                padding: "8px",
                border: "1px dotted transparent",
                bgcolor: "transparent",
                borderRadius: "4px",
                transition: "all 0.3s ease",
                "&:hover": {
                  padding: "8px",
                  border: `1px dotted ${avatarBorderColor}`,
                  bgcolor: "#F0F3F6",
                },
              }}
            >
              {displayName}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default HeaderOverViewName;
