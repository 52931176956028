import { Box, IconButton, Popover, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  BackButtonView,
  BufferTimeView,
  EditButtonView,
  SlugView,
} from "./view";
import {
  AvatarNormal,
  CheckboxMedium,
  DateAndTimePicker,
  DropDownShortLarge,
  EditableTextArea,
  StyledRadioButtonBlack,
} from "../../../../common";
import {
  DescriptionIcon,
  DummyImage,
  EmailIcon,
  LastCalendarIcon,
  LastMessageIcon,
  TrackedIcon,
  UpdateIcon,
  VideoCallFilledIcon,
} from "../../../../../images";
import { CalendarTypesEnum, getCalenderLogo } from "../../../../../Utils";
import { CalendarPicker } from "../CalendarPicker";
import { DUMMY_CalendarPickerlist } from "../CalendarPicker/CalendarPickerType";
import { CalendarScheduleMenu } from "../CalendarScheduleMenu";
import { DUMMY_CalendarScheduleMenu_OPTIONS } from "../CalendarScheduleMenu/CalendarScheduleMenuType";
import styles from "./BookingLinkCalendarPreview.module.css";
import { useTranslation } from "react-i18next";
// import { useDispatch } from "react-redux";

interface BookingLinkCalendarPreviewProps {
  onClickhandle: () => void;
  bLinkIdData: any;
}

const BookingLinkCalendarPreview: React.FC<BookingLinkCalendarPreviewProps> = ({
  onClickhandle,
  bLinkIdData,
}) => {
  const [bookLinkDataForHost, setBookLinkDataForHost] = useState<any>([]);

  const [expire, setExpire] = useState<"never" | "date">("never");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (bLinkIdData) {
      setBookLinkDataForHost(bLinkIdData);
    }
  }, [bLinkIdData]);
  const { t } = useTranslation();
  return (
    <Stack className={styles["bookingLinkCalendarPreview"]}>
      <BackButtonView onClickButtonHandle={onClickhandle} />
      {/* header layer */}
      <Stack className={styles["bookingLinkCalendarPreview__header"]}>
        <Stack className={styles["bookingLinkCalendarPreview__user"]}>
          <AvatarNormal
            size={50}
            username="User Name"
            avatarColor="red"
            imgSrc={DummyImage}
            sx={{ border: "1px solid #E9EDF2", boxSizing: "border-box" }}
          />
          <Typography
            className={styles["bookingLinkCalendarPreview__username"]}
          >
            {bookLinkDataForHost?.name}
          </Typography>
        </Stack>

        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
          <Box
            component={"img"}
            src={getCalenderLogo({
              calendarType: CalendarTypesEnum?.BearishCalendar,
            })}
            alt="calendar logo"
            className={styles["bookingLinkCalendarPreview__calendarLogo"]}
          />
          <IconButton onClick={handlePopoverOpen} sx={{ padding: "5px" }}>
            <UpdateIcon w={20} color="#000000" />
          </IconButton>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              style: { boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)" },
            }}
          >
            <CalendarPicker
              width="200px"
              buttonSx={{ paddingLeft: "5px" }}
              options={DUMMY_CalendarPickerlist}
              onSelect={(value) => null}
            />
          </Popover>
        </Stack>
      </Stack>

      {/* body layer */}
      <Stack className={styles["bookingLinkCalendarPreview__body"]}>
        {/* schedule dropdown */}
        <Stack className={styles["bookingLinkCalendarPreview__section"]}>
          <LastCalendarIcon w={20} />
          <CalendarScheduleMenu
            options={DUMMY_CalendarScheduleMenu_OPTIONS}
            disabled
            label={bookLinkDataForHost?.scheduleId}
          />
          <EditButtonView
            ButtonLabel={t("CALENDAR.EDIT")}
            onClick={() => null}
            sx={{ marginLeft: "-15px" }}
          />
        </Stack>
        {/* call location dropdown */}
        <Stack className={styles["bookingLinkCalendarPreview__section"]}>
          <VideoCallFilledIcon w={20} />
          <DropDownShortLarge
            disabled={true}
            onChange={(value) => null}
            placeholder="Choose Video Call Location"
            borderColor="lightBlue"
            icon="chevron"
            initialValue={bookLinkDataForHost?.videoCall?.platform}
          />
          <EditButtonView
            ButtonLabel={t("CALENDAR.EDIT")}
            onClick={() => null}
            sx={{ marginLeft: "-15px" }}
          />
        </Stack>

        <SlugView slugInitial={bookLinkDataForHost?.bookingLinkSlug} />

        <BufferTimeView
          bookLinkDataForHostBuffer={bookLinkDataForHost?.bufferTime}
        />

        <Stack className={styles["bookingLinkCalendarPreview__section"]}>
          <EmailIcon w={20} />
          <Stack
            sx={{ flexDirection: "row", gap: "15px", alignItems: "center" }}
          >
            <Typography
              className={styles["bookingLinkCalendarPreview__reminderLabel"]}
            >
              {t("CALENDAR.SEND_EMAIL_REMINDER")}
            </Typography>
            <CheckboxMedium
              checked={bookLinkDataForHost?.sendReminderEmail}
              boxSize={15}
            />
          </Stack>
        </Stack>

        <Stack sx={{ flexDirection: "row", alignItems: "start", gap: "25px" }}>
          <DescriptionIcon color="black" w={20} />
          <EditableTextArea
            maxRows={9}
            minRows={9}
            placeholder={t("CALENDAR.ADD_DESC")}
            style={{
              border: "1px solid #E9EDF2",
              borderRadius: "5px",
              font: "normal normal 300 12px/15px Source Serif Pro",
              width: "325px",
              height: "130px",
              maxHeight: "130px",
              flexShrink: 0,
              padding: "10px",
              boxSizing: "border-box",
              minHeight: 0,
            }}
          />
        </Stack>

        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Stack className={styles["bookingLinkCalendarPreview__section"]}>
            <LastMessageIcon w={20} />
            <Stack
              sx={{ flexDirection: "row", gap: "15px", alignItems: "center" }}
            >
              <Typography
                className={styles["bookingLinkCalendarPreview__feedbackLabel"]}
              >
                {t("CALENDAR.TURN_ON_POST_MEETING_FEEDBACK")}
              </Typography>
              <CheckboxMedium
                checked={bookLinkDataForHost?.postMeetingFeedback}
                boxSize={15}
              />
            </Stack>
          </Stack>
          <EditButtonView
            ButtonLabel={t("CALENDAR.SETUP_EDIR")}
            onClick={() => null}
          />
        </Stack>

        <Stack className={styles["bookingLinkCalendarPreview__section"]}>
          <TrackedIcon w={20} />
          <Stack
            sx={{ flexDirection: "row", gap: "15px", alignItems: "center" }}
          >
            <Typography
              className={styles["bookingLinkCalendarPreview__expiresLabel"]}
            >
              {t("CALENDAR.EXPIRES")}
            </Typography>
            <Stack
              onClick={() => setExpire("never")}
              className={
                styles["bookingLinkCalendarPreview__expiresRadioGroup"]
              }
            >
              <StyledRadioButtonBlack
                radioSize={15}
                checked={expire === "never"}
              />
              <Typography
                className={
                  styles["bookingLinkCalendarPreview__expiresRadioGroupLabel"]
                }
              >
                {t("CALENDAR.NEVER")}
              </Typography>
            </Stack>
            <Stack
              onClick={() => setExpire("date")}
              className={
                styles["bookingLinkCalendarPreview__expiresRadioGroup"]
              }
            >
              <StyledRadioButtonBlack
                radioSize={15}
                checked={expire === "date"}
              />
              <Typography
                className={
                  styles["bookingLinkCalendarPreview__expiresRadioGroupLabel"]
                }
              >
                {t("CALENDAR.ON")}
              </Typography>
            </Stack>
            <DateAndTimePicker
              sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default BookingLinkCalendarPreview;
