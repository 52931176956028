import React, { useEffect, useState } from "react";
import { Box, Stack, Modal, Typography } from "@mui/material";
import { HeaderOverViewName, ReactangleHeaderOverView } from "../components";
import { MultiIconButton } from "../../common";
import {
  BarButtonOptions,
  // CreateGroupPopUp,
  CrmRightSideTabs,
  TimelineTabs,
} from "../../components";
import {
  AddtoGroupIcon,
  CompanyIcon,
  LasTTouchPointIcon,
  RelationshipStrengthIcon,
} from "../../../../../images";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addToGroup__api,
  getOneContact__api,
  updateOneContact__api,
} from "../../../../../redux";
import { RootState } from "../../../../../redux/store";
import { formatDate } from "../../../../../Utils";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
  height: "fit-content",
  borderRadius: "6px",
};
type Params = Record<string, string | undefined>;
const PeopleID = () => {
  const { personId } = useParams<Params>();
  const dispatch = useDispatch();
  const location = useLocation();
  const crmState = useSelector((state: RootState) => state && state?.crm);
  const { groupList } = crmState || {};
  const [grpList, setGrpList] = useState<any>([]);
  // Extract the "q" query parameter
  const searchParams = new URLSearchParams(location.search);
  const q = searchParams.get("q");
  useEffect(() => {
    if (groupList && groupList?.length > 0) {
      setGrpList(groupList);
    }
  }, [groupList]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [contactDetails, setContactDetails] = useState<any>({});
  const getContactDetails = async () => {
    try {
      if (!q) {
        return;
      }

      const action = getOneContact__api(q);

      const getContactRes: any = await dispatch(action);

      if (getContactRes?.success && getContactRes?.data) {
        setContactDetails(getContactRes?.data);
      } else {
        setContactDetails({});
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getContactDetails();

    /* Change once new api updated */
  }, [q]);

  const updateContactField = async (updatedFields: { [key: string]: any }) => {
    console.log("updateContactField");
    // Update local state
    setContactDetails((prevDetails: any) => ({
      ...prevDetails,
      ...updatedFields, // Spread updated fields into the existing state
    }));

    // Create payload based on updated fields
    const payload: any = {
      firstName: updatedFields.firstName || contactDetails.firstName,
      lastName: updatedFields.lastName || contactDetails.lastName,
      company: updatedFields.company || contactDetails.company,
      image: updatedFields.image || contactDetails.image,
      jobTitle: updatedFields?.jobTitle || contactDetails?.jobTitle,
      phone: updatedFields?.phone || contactDetails?.phone,
      primaryLocation:
        updatedFields?.primaryLocation || contactDetails?.primaryLocation,
      relationshipIndicator:
        updatedFields?.relationshipIndicator ||
        contactDetails?.relationshipIndicator,
      // You can add more fields here as necessary
    };

    // Call the API to update the contact with the consolidated payload
    await updateOneContact(contactDetails?._id, payload);
  };

  const updateOneContact = async (contactId: string, payload: any) => {
    try {
      if (!contactDetails || !contactDetails?._id) {
        return;
      }

      const action = updateOneContact__api(contactId, payload);
      await dispatch(action);
    } catch (error) {
      console.log(error);
    }
  };

  const addToGroup = async (_id: string) => {
    try {
      if (!personId || !_id) {
        return;
      }

      const payload: any = {
        personId: personId,
      };

      const action = addToGroup__api(_id, payload);
      await dispatch(action);
    } catch (error) {
      console.log(error);
    }
  };

  // You can pass custom badge data or use the default one
  const customBadgeData = [
    {
      title: `${contactDetails?.relationshipIndicator}⭐`,
      icon: <RelationshipStrengthIcon />, // Replace with your own icon
    },
    {
      title: `${formatDate(contactDetails?.updatedAt)}`,
      icon: <LasTTouchPointIcon />, // Replace with another icon
    },
    {
      title: contactDetails?.businessId?.businessName,
      imageUrl: contactDetails?.businessId?.businessLogo,
    },
  ];

  return (
    <Box>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        gap={0.5}
        sx={{ padding: "4px 10px" }}
      >
        <HeaderOverViewName
          imageUrl={contactDetails?.image}
          headerType="people"
          firstName={contactDetails?.firstName}
          lastName={contactDetails?.lastName}
          roundedOrSquare="rd"
          onUpdate={updateContactField} // Pass the update function as a prop
        />
        <Stack
          direction={"row"}
          onClick={handleOpen}
          alignItems={"center"}
          gap={1}
        >
          <BarButtonOptions
            onClick={() => {
              console.log("NO ACTION");
            }}
            label={{ id: 1, name: "Add to Group" }}
            icon={<AddtoGroupIcon />}
          />
          <MultiIconButton />
        </Stack>
      </Stack>

      <Stack>
        <ReactangleHeaderOverView badgeData={customBadgeData} />
      </Stack>

      <Stack direction={"row"} height={"80vh"}>
        <TimelineTabs cType={"CONTACT"} contactDetails={contactDetails} />
        <CrmRightSideTabs
          typeOfIdentifier={"people"}
          contactDetails={contactDetails}
          onUpdate={updateContactField} // Pass the update function as a prop
        />
      </Stack>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <CreateGroupPopUp variant="group" /> */}
          <Stack minWidth={"200px"} direction={"column"} gap={1}>
            {grpList &&
              grpList?.length > 0 &&
              grpList?.map((g: any) => {
                return (
                  <Typography
                    key={g?._id}
                    sx={{
                      font: " normal normal normal 14px/20px Source Serif Pro",
                      padding: "10px 20px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      addToGroup(g?._id);
                    }}
                  >
                    {" "}
                    <span style={{ marginRight: "10px" }}>
                      {" "}
                      {g?.groupIcon}{" "}
                    </span>{" "}
                    {g?.groupName}{" "}
                  </Typography>
                );
              })}
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
};

export default PeopleID;
