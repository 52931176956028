import React from "react";
import {
  AddCredentialsPopupOptList,
  CredentialsListType,
} from "../../AddCredentialsPopup/AddCredentialsPopupType";
import { Stack, Typography } from "@mui/material";

interface ValueAreaProps {
  cardType: CredentialsListType;
  data: string;
}

const ValueArea: React.FC<ValueAreaProps> = ({ cardType, data }) => {
  const cardDtails =
    AddCredentialsPopupOptList.find((item) => item.value === cardType) ||
    AddCredentialsPopupOptList[0];

  return (
    <Stack sx={{ flexDirection: "row", gap: "5px", alignItems: "start" }}>
      <span style={{ flexShrink: 0 }}>
        {React.createElement(cardDtails.icon, { w: 15, color: "black" })}
      </span>
      <Typography sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}>
        {data}
      </Typography>
    </Stack>
  );
};

export default ValueArea;
