import { Box, Menu, MenuItem, Stack, SxProps, Typography } from "@mui/material";
import React, { MouseEvent, useEffect, useState } from "react";
import { CalendarPickerOptionInterface } from "./CalendarPickerType";
import styles from "./CalendarPicker.module.css";
import { getCalenderLogo } from "../../../../../Utils";

interface CalendarPickerProps {
  options: CalendarPickerOptionInterface[];
  onSelect: (value: CalendarPickerOptionInterface) => void;
  initialValue?: CalendarPickerOptionInterface;
  buttonSx?: SxProps;
  width?: string;
  readonlyComponent?: boolean;
}

const CalendarPicker: React.FC<CalendarPickerProps> = ({
  onSelect,
  initialValue,
  options,
  buttonSx,
  readonlyComponent = false,
  width = "150px",
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedValue, setSelectedValue] =
    useState<CalendarPickerOptionInterface>(initialValue || options[0]);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    if (!readonlyComponent) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (value: CalendarPickerOptionInterface) => {
    setSelectedValue(value);
    handleClose();
  };

  useEffect(() => {
    onSelect(selectedValue);
  }, [selectedValue]);

  return (
    <Stack>
      <Stack
        onClick={handleClick}
        className={styles["calendar-picker__button"]}
        sx={{
          width: width,
          padding: "0px 10px 0px 0px",
          bgcolor: "white",
          ...buttonSx,
        }}
      >
        <Box
          component={"img"}
          src={getCalenderLogo({ calendarType: selectedValue?.calendarType || "HomeIcon" })}
          alt="calendar logo"
          className={styles["calendar-picker__icon"]}
        />
        <Typography className={styles["calendar-picker__text"]}>
          {selectedValue?.calendarName}
        </Typography>
      </Stack>

      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          className: styles["calendar-picker__menu"],
          style: { width: width },
        }}
      >
        {options.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => handleSelect(item)}
            className={styles["calendar-picker__menu-item"]}
          >
            <Box
              component={"img"}
              src={getCalenderLogo({
                calendarType: item.calendarType,
              })}
              alt="calendar logo"
              className={styles["calendar-picker__icon"]}
            />
            <Typography className={styles["calendar-picker__text"]}>
              {item?.calendarName}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
};

export default CalendarPicker;
