import React from "react";
import style from "./MainActionButton.module.css";
import { Button, ButtonProps, Stack, Typography } from "@mui/material";
import { HomeIcon, EditIcon } from "../../../../images";
import { ButtonType, MainActionColor } from "../AllButtonProps";

interface ButtonXLargeProps extends ButtonProps {
  colorVarient: MainActionColor;
  label?: string;
  types?: ButtonType;
}

const MainActionButton: React.FC<ButtonXLargeProps> = ({
  colorVarient,
  label = "Home",
  types = ButtonType.Button,
  ...props
}) => {
  let icon: React.ReactNode = null;
  if (colorVarient === MainActionColor.Orange) {
    icon = <EditIcon color="white" w={20} />;
  } else if (colorVarient === MainActionColor.Blue) {
    icon = <HomeIcon color="white" w={20} />;
  } else if (colorVarient === MainActionColor.Green) {
    icon = <HomeIcon color="white" w={20} />;
  } else if (colorVarient === MainActionColor.BlueMessageButton) {
    icon = <EditIcon color="white" w={20} />;
  }

  return (
    <Button
      disableElevation
      variant="contained"
      type={types}
      // startIcon={icon}
      className={`
        ${style.MainActionButton}
        ${style[`MainActionButton--${colorVarient}`]}
      `}
      {...props}
    >
      <Stack className={style.MainActionButton__box}>
        {icon}
        <Typography className={style.MainActionButton__font}>{label}</Typography>
      </Stack>
      {/* // {label} */}
    </Button>
  );
};

export default MainActionButton;
