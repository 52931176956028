import { Button, ButtonProps } from "@mui/material";
import React from "react";

interface TextLinkButtonProps extends ButtonProps {
  buttonLabel: string;
}

const TextLinkButton: React.FC<TextLinkButtonProps> = ({
  buttonLabel,
  sx,
  ...props
}) => {
  return (
    <Button
      variant="text"
      sx={{
        font: "normal normal 300 11px/14px Source Serif Pro",
        color: "#0027FF",
        textTransform: "none",
        height: "20px",
        padding: "0px 4px",
        borderRadius: "6px",
        minWidth: 0,
        ...sx,
      }}
      {...props}
    >
      {buttonLabel}
    </Button>
  );
};

export default TextLinkButton;
