import { Stack, SxProps, Typography } from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import { GreenDropdownIcon } from "../../../../../../../images";

interface AccordianContainerProps {
  title: string;
  children: React.ReactNode;
  sx?: SxProps;
}

const AccordianContainer: React.FC<AccordianContainerProps> = ({
  children,
  title,
  sx,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  // const [height, setHeight] = useState<string>("0px");
  // const contentRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   if (contentRef.current) {
  //     setHeight(isOpen ? `${contentRef.current.scrollHeight}px` : "0px");
  //   }
  // }, [isOpen, height]);

  return (
    <Stack>
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
          font: "normal normal 400 13px/16px Source Serif Pro",
          paddingRight: "10px",
        }}
        onClick={() => setIsOpen((value) => !value)}
      >
        <Typography sx={{ font: "inherit" }}>{title}</Typography>
        <GreenDropdownIcon open={isOpen} sx={{ height: "10px" }} />
      </Stack>
      <Stack
        // ref={contentRef}
        sx={{
          maxHeight: isOpen ? "auto" : "0px",
          overflow: "hidden",
          boxSizing: "border-box",
          // transition: "max-height 0.3s ease-in-out",
        }}
      >
        <Stack
          sx={{
            flexDirection: "column",
            gap: "20px",
            padding: "10px 0px 0px 0px",
            boxSizing: "border-box",
            ...sx,
          }}
        >
          {children}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AccordianContainer;
