import React, { useState } from "react";
import { Box, IconButton, Popover } from "@mui/material";
import { AddCalPlusIcon } from "../../../../../images";
import {
  AddColumnEmployeeDirectoryList,
  DragAndDropMenu,
  DragAndDropMenuListType,
} from "../../../../common";

type AddColumnTraxTableProps = {};

const styleBox = {
  borderTop: "2px solid #E9EDF2",
  borderBottom: "2px solid #E9EDF2",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "4.5px",
  height: "48px",
  //   boxSizing: "border-box",
};

const AddColumnTraxTable = (props: AddColumnTraxTableProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Initialize menuItems based on componentType
  const [menuItems, setMenuItems] = useState<DragAndDropMenuListType[]>(
    AddColumnEmployeeDirectoryList
  );
  return (
    <Box
      sx={{
        boxSizing: "border-box",
        borderTop: "2px solid #E9EDF2",
        borderBottom: "2px solid #E9EDF2",
        // borderLeft: "2px solid #E9EDF2",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "49px",
      }}
    >
      <IconButton onClick={handleClick}>
        <AddCalPlusIcon w={17} />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <DragAndDropMenu
          label="Add new columns"
          menuItems={menuItems}
          setMenuItems={setMenuItems}
          AddButtonLabel="Create a new type"
          AddButtonClick={() => null}
        />
      </Popover>
    </Box>
  );
};

export default AddColumnTraxTable;
