import { Box, Stack, Paper, Popper } from "@mui/material";
import React, { useState } from "react";
import { EventIconButton } from "../../../../common";
import {
  CheckboxFilledIcon,
  MultiCircleIcon,
  XCircleIcon,
} from "../../../../../images";
import { useTranslation } from "react-i18next";

interface PendingEventProps {
  cardStatus: "pending" | "Accept" | "Reject" | "Maybe";
  onButtonClick: (value: "Accept" | "Reject" | "Maybe") => void;
  width: "5px" | "2px";
}

const PendingEvent: React.FC<PendingEventProps> = ({
  cardStatus,
  onButtonClick,
  width,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const handleChipLeave = () => {
    if (timeoutId) {
      clearTimeout(timeoutId); // Clear previous timeout to avoid multiple closings
    }
    const id = setTimeout(() => {
      setAnchorEl(null); // Close the popper after 1000ms
    }, 100);
    setTimeoutId(id);
  };

  const handleChipHover = (event: React.MouseEvent<HTMLElement>) => {
    if (timeoutId) {
      clearTimeout(timeoutId); // Cancel the timeout if the mouse re-enters
    }
    setAnchorEl(event.currentTarget); // Keep popper open when mouse enters
  };

  const clickHandler = (value: "Accept" | "Reject" | "Maybe") => {
    onButtonClick(value);
    handleChipLeave();
  };
  const { t } = useTranslation();
  return (
    <>
      <Stack
        sx={{ flexDirection: "row", flexShrink: 0 }}
        onMouseEnter={handleChipHover}
        onMouseLeave={handleChipLeave}
      >
        <Box sx={{ bgcolor: "#E0D700", width: width, borderRadius: "10px" }} />
        <Popper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement={"top-start"}
          onMouseLeave={handleChipLeave}
          sx={{
            zIndex: 5000,
            border: "0px",
            borderRadius: "5px",
            padding: "5px",
          }}
        >
          <Paper
            sx={{
              borderRadius: "5px",
              padding: 0,
              boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
              marginRight: "-10px",
            }}
          >
            <Stack
              sx={{
                height: "30px",
                flexDirection: "row",
                alignItems: "center",
                padding: "0px 8px",
                boxSizing: "border-box",
                gap: "5px",
                border: "1px solid #D9E3EF",
                borderRadius: "5px",
                bgcolor: "white",
              }}
            >
              <EventIconButton
                buttonTitle={t("CALENDAR.ACCEPT")}
                icon={CheckboxFilledIcon}
                iconColor="#21B481"
                onClick={() => clickHandler("Accept")}
              />
              <EventIconButton
                buttonTitle={t("CALENDAR.REJECT")}
                icon={XCircleIcon}
                iconColor="#FF0000"
                onClick={() => clickHandler("Reject")}
              />
              <EventIconButton
                buttonTitle={t("CALENDAR.MAYBE")}
                icon={MultiCircleIcon}
                iconColor="#FFB100"
                highlighted={cardStatus === "Maybe"}
                onClick={() => clickHandler("Maybe")}
              />
            </Stack>
          </Paper>
        </Popper>
      </Stack>
    </>
  );
};

export default PendingEvent;
