import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { AllDirArrowCircleIcon } from "../../../../../images";

interface EmailPageControllerProps {
  totalPage: number;
  currentPage?: number;
  onChange: (value: number) => void;
}

const EmailPageController: React.FC<EmailPageControllerProps> = ({
  onChange,
  totalPage,
  currentPage = 1,
}) => {
  const [pageNum, setPageNum] = useState<number>(currentPage);
  const numLength = totalPage.toString().length;
  const paddedCurrentPage = pageNum?.toString()?.padStart(numLength, "0");

  const pageDecrement = () => {
    setPageNum((prev) => {
      const newPage = Math.max(prev - 1, 1);
      onChange(newPage);
      return newPage;
    });
  };

  const pageIncrement = () => {
    setPageNum((prev) => {
      const newPage = Math.min(prev + 1, totalPage);
      onChange(newPage);
      return newPage;
    });
  };

  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        gap: "13px",
      }}
    >
      <Tooltip title="Back" arrow placement="top">
        <IconButton sx={{ p: "2.5px" }} disableRipple onClick={pageDecrement}>
          <AllDirArrowCircleIcon w={15} direction="left" />
        </IconButton>
      </Tooltip>

      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "3px",
          font: "normal normal 300 12px/15px Source Serif Pro",
          paddingTop: "2px",
          boxSizing: "border-box",
          cursor: "default",
        }}
      >
        <Typography sx={{ font: "inherit" }}>{paddedCurrentPage}</Typography>
        <Typography sx={{ font: "inherit" }}>of</Typography>
        <Typography sx={{ font: "inherit" }}>{totalPage}</Typography>
      </Stack>

      <Tooltip title="Forward" arrow placement="top">
        <IconButton sx={{ p: "2.5px" }} disableRipple onClick={pageIncrement}>
          <AllDirArrowCircleIcon w={15} direction="right" />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default EmailPageController;
