import { Stack, Typography } from "@mui/material";
import React from "react";
import { TraxTextBadge } from "../../common";
import { DisplayAreaView } from "./view";
import { empWorkSheduleType } from "../../EmployeeDirectory";

interface WorkSheduleAreaProps {
  data: empWorkSheduleType[];
}

const WorkSheduleArea: React.FC<WorkSheduleAreaProps> = ({ data }) => {
  return (
    <Stack sx={{ flex: 1, gap: "15px" }}>
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{ font: "normal normal 400 15px/19px Source Serif Pro" }}
        >
          Work Schedule
        </Typography>

        <TraxTextBadge bgcolor="#008D4C" label="Avaliable" />
      </Stack>

      <Stack sx={{ gap: "10px" }}>
        {data.map((item, index) => (
          <DisplayAreaView
            key={index}
            dayName={item.day}
            dayOff={item.dayOff}
            startTime={item.startTime}
            endTime={item.endTime}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default WorkSheduleArea;
