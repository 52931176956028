import { PayloadAction } from "@reduxjs/toolkit";
import { MessagesState } from "../messageTypes";



export const getChannelMessageFailure = (state: MessagesState, action: PayloadAction<string>) => {
    state.error = action.payload;
    state.loading = false
};

export const sendMessageFailure = (state: MessagesState, action: PayloadAction<string>) => {
    state.error = action.payload;
    state.loading = false
};

export const getUserConnectionsFailure = (state: MessagesState, action: PayloadAction<string>) => {
    state.error = action.payload;
    state.loading = false
};

export const getExternalConnectionsFailure = (state: MessagesState, action: PayloadAction<string>) => {
    state.error = action.payload;
    state.loading = false
};

export const createNewConnectionFailure = (state: MessagesState, action: PayloadAction<string>) => {
    state.error = action.payload;
    state.loading = false
};

export const updateConnectionFailure = (state: MessagesState, action: PayloadAction<string>) => {
    state.error = action.payload;
    state.loading = false
};

export const updateMessageFailure = (state: MessagesState, action: PayloadAction<string>) => {
    state.error = action.payload;
    state.loading = false
};

export const scheduleMessageFailure = (state: MessagesState, action: PayloadAction<string>) => {
    state.error = action.payload;
    state.loading = false
};

export const forwardMessageFailure = (state: MessagesState, action: PayloadAction<string>) => {
    state.error = action.payload;
    state.loading = false
};

export const addReactionToMessageFailure = (state: MessagesState, action: PayloadAction<string>) => {
    state.error = action.payload;
    state.loading = false
};

export const removeReactionToMessageFailure = (state: MessagesState, action: PayloadAction<string>) => {
    state.error = action.payload;
    state.loading = false
};
export const markMessagesAsReadFailure = (state: MessagesState, action: PayloadAction<string>) => {
    state.error = action.payload;
    state.loading = false
};
export const changeParticipantsRoleFailure = (state: MessagesState, action: PayloadAction<string>) => {
    state.error = action.payload;
    state.loading = false
};
export const getChannelActivitiesFailure = (state: MessagesState, action: PayloadAction<string>) => {
    state.error = action.payload;
};
export const getMessageActivitiesFailure = (state: MessagesState, action: PayloadAction<string>) => {
    state.error = action.payload;
    state.loading = false
};
export const changeMessagesActivitiesStatusFailure = (state: MessagesState, action: PayloadAction<string>) => {
    state.error = action.payload;
    state.loading = false
};
export const setSnoozeMessagesActivitiesFailure = (state: MessagesState, action: PayloadAction<string>) => {
    state.error = action.payload;
    state.loading = false
};
export const getSlackUsersFailure = (state: MessagesState, action: PayloadAction<string>) => {
    state.error = action.payload;
    state.loading = false
};
export const getSlackUserProfileFailure = (state: MessagesState, action: PayloadAction<string>) => {
    state.error = action.payload;
    state.loading = false
};
export const getTeamsUserProfileFailure = (state: MessagesState, action: PayloadAction<string>) => {
    state.error = action.payload;
    state.loading = false
};