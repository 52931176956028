import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  AllDirArrowCircleIcon,
  DescriptionIcon,
  LocationBoldIcon,
  StatusIcon,
  VideoCallFilledIcon,
} from "../../../../../../../images";
import {
  CheckboxMedium,
  DropDownShortLarge,
  DropdownWIconLarge,
  EditableTextArea,
} from "../../../../../../common";
import { CalendarPicker } from "../../../CalendarPicker";
import {
  CalendarPickerOptionInterface,
  DUMMY_CalendarPickerlist,
} from "../../../CalendarPicker/CalendarPickerType";
import {
  AddGuestView,
  AlternateTimeView,
  EventTimeView,
  RepeatTimeView,
} from "../../view";
import { ShowAsFilter } from "../../../ShowAsFilter";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../redux/store";
import { CalendarState } from "../../../../../../../redux";
import { CalendarTypesEnum } from "../../../../../../../Utils";
import LocationAutocomplete from "../../../../../../common/LocationAutocomplete/LocationAutocomplete";

interface EventTabProps {
  updateField?: any;
  updateDate?: any;
  updateEndTime?: any;
  updateStartTime?: any;
  updateProposedTimes?: any;
}

const EventTab: React.FC<EventTabProps> = ({
  updateField,
  updateDate,
  updateEndTime,
  updateStartTime,updateProposedTimes
}) => {
  const [expendedRepeatView, setExpendedRepeatView] = useState(false);
  const [showOptional, setShowOptional] = useState(false);

  const handlePlatformChange = (label: string) => {
    if (!updateField) {
      return;
    }

    // Define the mapping for platform labels to API values
    const platformMap: Record<string, string> = {
      "Bearish OS Video Calls": "BearishOS",
      Zoom: "zoom",
      "Google Meet": "googleMeet",
      "Cisco Webex": "ciscoWebex",
      "Microsoft Teams": "microsoftTeams",
    };

    // Check if the selected label is a predefined platform
    if (platformMap[label]) {
      // If it's a known platform, update accordingly
      updateField("videoCall", {
        platform: platformMap[label],
        customLink: null,
      });
    } else {
      // If it's not a known platform, treat it as a custom link
      updateField("videoCall", { platform: "custom", customLink: label });
    }
  };
  const calendarState = useSelector(
    (state: RootState) => state && (state?.calendar as CalendarState)
  );

  const { allCalendarList } = calendarState || {};

  const calendarPickerlist: CalendarPickerOptionInterface[] =
    allCalendarList && typeof allCalendarList === "object" // Check if it's a valid object
      ? Object?.values(allCalendarList)?.map((calendar: any) => ({
          id: calendar?._id, // Use the _id as the id
          calendarType: CalendarTypesEnum.BearishCalendar, // Set to bearishCalendar
          calendarName: calendar?.name, // Use the name from the calendar
          _id: calendar?._id, // Include _id if needed
        }))
      : []; // Return an empty array if allCalendarList is not valid
  const [description, setDescription] = useState("");

  return (
    <Stack
      sx={{ gap: "20px", font: "normal normal 300 15px/19px Source Serif Pro" }}
    >
      <EventTimeView
        updateStartTime={updateStartTime}
        updateEndTime={updateEndTime}
        updateDate={updateDate}
        updateField={updateField}
      />

      <RepeatTimeView
        updateField={updateField}
        isExpended={expendedRepeatView}
        onContainerClick={() => setExpendedRepeatView(true)}
      />

      <AddGuestView
        showOptional={showOptional}
        updateField={updateField}
        optionalBtnClick={() => setShowOptional(true)}
      />

      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
        <VideoCallFilledIcon w={20} />
        <DropDownShortLarge
          onChange={(value) => handlePlatformChange(value)}
          placeholder="Choose Video Call Location"
          borderColor="lightBlue"
          width="325px"
          icon="chevron"
        />
      </Stack>

      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
        <LocationBoldIcon w={20} />
        {/* <DropdownWIconLarge
          width="325px"
          icon="chevron"
          options={[
            "location-1",
            "location-2",
            "location-3",
            "location-4",
            "location-5",
            "location-6",
            "location-7",
          ]}
          placeholder="Choose a location"
          onChange={(e: any) => updateField && updateField("location", e)}
        /> */}
        <LocationAutocomplete
          onChange={(l: any) => updateField && updateField("location", l)}
        />
      </Stack>

      <Stack sx={{ flexDirection: "row", gap: "10px", alignItems: "center" }}>
        <StatusIcon w={20} />

        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
          <CheckboxMedium boxSize={15} />
          <Typography
            sx={{
              font: "normal normal 300 15px/19px Source Serif Pro",
              marginRight: "25px",
            }}
          >
            Show as
          </Typography>
        </Stack>

        <AllDirArrowCircleIcon w={15} direction="right" />

        <ShowAsFilter
          onSelect={(value) => {
            updateField && updateField("showAs", value);
          }}
          initialValue="Free"
        />
      </Stack>

      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
        {/* <BearishIcon height={20} width={20} color="#e2875e" /> */}
        <CalendarPicker
          options={calendarPickerlist || []}
          onSelect={(value) => {
            updateField && updateField("calendarId", value?._id || value?.id);
          }}
          width="300px"
        />
      </Stack>

      <Stack sx={{ flexDirection: "row", alignItems: "start", gap: "10px" }}>
        <DescriptionIcon w={20} />
        <EditableTextArea
          text={description} // Controlled value from parent state
          onTextChange={(newText: string) => {
            updateField && updateField("description", newText);
          }}
          placeholder="Add description"
        />
      </Stack>

      <AlternateTimeView updateProposedTimes={updateProposedTimes} />
      {/* ----- */}
    </Stack>
  );
};

export default EventTab;
