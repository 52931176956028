import {
  Box,
  IconButton,
  InputBase,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  adjustHexColor,
  CalendarTypesEnum,
  getCalenderLogo,
} from "../../../../../Utils";
import {
  ConnectedIcon,
  LastCalendarIcon,
  UpdateIcon,
  XCircleIcon,
} from "../../../../../images";
import { CalendarPicker } from "../CalendarPicker";
import {
  CalendarPickerOptionInterface,
  DUMMY_CalendarPickerlist,
} from "../CalendarPicker/CalendarPickerType";
import {
  ButtonSmall,
  ButtonType,
  CheckboxMedium,
  SmallButtonColor,
} from "../../../../common";
import { TravelTimeMenu } from "../TravelTimeMenu";
import { CalendarScheduleMenu } from "../CalendarScheduleMenu";
import { DUMMY_CalendarScheduleMenu_OPTIONS } from "../CalendarScheduleMenu/CalendarScheduleMenuType";
import { BufferTimeView, LocationView, SlugView } from "./view";
import {
  BookingLinkData,
  CalendarState,
  createBookingLink__api,
  recallAPI,
} from "../../../../../redux";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { useTranslation } from "react-i18next";

interface BookingLinkCalendarCreatorProps {
  onClick?: () => void;
  handleClose?: any;
}

const BookingLinkCalendarCreator: React.FC<BookingLinkCalendarCreatorProps> = ({
  onClick,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const calendarState = useSelector(
    (state: RootState) => state && (state?.calendar as CalendarState)
  );

  const { allCalendarList } = calendarState || {};

  // Popover state management
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const [bookingLinkData, setBookingLinkData] = useState<BookingLinkData>({
    name: "", // Booking link name
    scheduleId: "", // ID of the schedule associated with the booking link
    calendarId: allCalendarList?.[0]?._id, // ID of the calendar where the event will be booked
    // Updated buffer time structure
    bufferTime: {
      time: 15, // Default buffer time in minutes
      direction: "before", // Default direction (before, after, both)
    },
    eventDuration: 15, // Default event duration
    sendReminderEmail: true, // Default to sending reminder emails
    bookingLinkSlug: "", // Unique slug for the booking link
    expiration: null, // Optional expiration, set to null initially
    postMeetingFeedback: false, // Default feedback setting
    autoCharge: false, // Default auto-charge setting
    chargeRatePer30Min: 0, // Default charge rate
    paymentRequired: false, // Default payment required setting
    checkConflict: true,
    // Video Call Options
    videoCall: {
      platform: "BearishOS", // Default platform
      customLink: "", // Only required if custom platform is used
    },

    // Physical Location
    location: "", // Optional physical location, set to empty string
  });

  // Combined function to update a field in the booking link data
  const updateField = <K extends keyof BookingLinkData>(
    field: K,
    value: BookingLinkData[K]
  ) => {
    setBookingLinkData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  // Example handlers for input changes
  const handleInputChange =
    (field: keyof BookingLinkData) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value, type, checked } = e.target;

      // Determine the value type and update accordingly
      if (type === "checkbox") {
        updateField(field, checked as any);
      } else if (type === "number") {
        updateField(field, Number(value) as any);
      } else {
        updateField(field, value as any);
      }
    };

  const open = Boolean(anchorEl);

  const calendarPickerlist: CalendarPickerOptionInterface[] =
    allCalendarList && typeof allCalendarList === "object" // Check if it's a valid object
      ? Object?.values(allCalendarList)?.map((calendar: any) => ({
          id: calendar?._id, // Use the _id as the id
          calendarType: CalendarTypesEnum?.BearishCalendar || "bearishCalendar", // Set to bearishCalendar
          calendarName: calendar?.name, // Use the name from the calendar
          _id: calendar?._id, // Include _id if needed
        }))
      : []; // Return an empty array if allCalendarList is not valid

  const handleCreatBookingLink = async () => {
    try {
      if (
        !bookingLinkData?.calendarId ||
        !bookingLinkData?.scheduleId ||
        !bookingLinkData?.name ||
        !bookingLinkData?.bookingLinkSlug
      ) {
        return;
      }

      const action = createBookingLink__api(bookingLinkData);

      const createBookingLinkRes: any = await dispatch(action);

      if (createBookingLinkRes?.success && handleClose) {
        handleClose();
        dispatch(recallAPI("getAllBook"));
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Stack
      sx={{
        width: "400px",
        borderRadius: "5px",
        bgcolor: "white",
        boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
      }}
    >
      {/* header */}
      <Stack
        sx={{
          borderTopLeftRadius: "inherit",
          borderTopRightRadius: "inherit",
          height: "40px",
          flexDirection: "row",
        }}
      >
        <Stack
          sx={{
            borderTopLeftRadius: "inherit",
            width: "15px",
            bgcolor: "#21B481",
            flexShrink: 0,
          }}
        />
        <Stack
          sx={{
            flexGrow: 1,
            bgcolor: adjustHexColor("#21B481", 80),
            borderTopRightRadius: "inherit",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0px 10px",
            boxSizing: "border-box",
          }}
        >
          <Stack
            sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}
          >
            <Typography
              sx={{
                font: "normal normal 900 18px/23px Source Serif Pro",
                maxWidth: "250px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                color: "white",
              }}
            >
              {t("CALENDAR.BOOKING_LINK_NAME")}
            </Typography>
            <Box
              component={"img"}
              src={getCalenderLogo({
                calendarType: CalendarTypesEnum?.JiraCalendar || "jiraCalendar",
              })}
              alt="calendar logo"
              sx={{ height: "20px", width: "20px" }}
            />
          </Stack>
          <Stack
            sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}
          >
            <IconButton onClick={handlePopoverOpen} sx={{ padding: "5px" }}>
              <UpdateIcon w={20} color="#fff" />
            </IconButton>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              PaperProps={{
                style: { boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)" },
              }}
            >
              <CalendarPicker
                width="200px"
                buttonSx={{ paddingLeft: "5px" }}
                options={calendarPickerlist || DUMMY_CalendarPickerlist}
                onSelect={(value: any) => {
                  updateField("calendarId", value?._id || value?.id);
                }}
              />
            </Popover>

            <IconButton
              onClick={() => {
                onClick && onClick();
                handleClose && handleClose();
              }}
              sx={{ padding: "5px" }}
            >
              <XCircleIcon w={20} color="#fff" />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>

      {/* caard body here */}
      <Stack
        sx={{
          boxSizing: "border-box",
          padding: "20px 20px 20px 25px",
          gap: "20px",
          flexShrink: 0,
        }}
      >
        {/* Booking name input area here */}
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
          <ConnectedIcon w={20} />
          <InputBase
            value={bookingLinkData?.name}
            onChange={handleInputChange("name")}
            sx={{
              flexGrow: 1,
              font: "normal normal 400 18px/23px Source Serif Pro",
              height: "25px",
            }}
            placeholder={t("CALENDAR.BOOKING_LINK_NAME")}
          />
        </Stack>

        {/* schedule dropdown */}
        <Stack sx={{ flexDirection: "row", gap: "10px", alignItems: "center" }}>
          <LastCalendarIcon w={20} />
          <CalendarScheduleMenu
            onChange={(v: any) => {
              updateField && updateField("scheduleId", v?._id);
            }}
            options={DUMMY_CalendarScheduleMenu_OPTIONS}
          />
        </Stack>

        {/* -------------------------- */}
        <Stack sx={{ flexDirection: "row", gap: "15px", alignItems: "center" }}>
          <Typography
            sx={{ font: "normal normal 300 15px/19px Source Serif Pro" }}
          >
            {t("CALENDAR.CHECK_CONFLICTS")}
          </Typography>
          <CheckboxMedium
            onClick={(e: any) => {
              updateField && updateField("checkConflict", e?.target?.checked);
            }}
            boxSize={20}
            checked={bookingLinkData?.checkConflict}
          />
        </Stack>

        {/* -------------------------- */}
        <BufferTimeView updateField={updateField} />

        {/* -------------------------- */}

        <LocationView updateField={updateField} />

        {/* -------------------------- */}
        <Stack
          sx={{
            flexDirection: "row",
            gap: "35px",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Typography
            sx={{ font: "normal normal 300 15px/19px Source Serif Pro" }}
          >
            {t("CALENDAR.DURATION")}
          </Typography>
          <TravelTimeMenu
            options="TravelTimeMenuOptionsList150"
            onSelect={(value: any) => {
              updateField && updateField("eventDuration", value);
            }}
          />
        </Stack>
        {/* -------------------------- */}
        <Stack sx={{ flexDirection: "row", gap: "15px", alignItems: "center" }}>
          <Typography
            sx={{ font: "normal normal 300 15px/19px Source Serif Pro" }}
          >
            {t("CALENDAR.SEND_EMAIL_REMINDER")}
          </Typography>
          <CheckboxMedium
            onClick={(e: any) => {
              updateField &&
                updateField("sendReminderEmail", e?.target?.checked);
            }}
            boxSize={15}
          />
        </Stack>
        {/* -------------------------- */}
        <SlugView updateField={updateField} />

        {/* -----------  Button here  --------------- */}
        <Stack
          sx={{
            marginTop: "10px",
            flexDirection: "row",
            justifyContent: "end",
          }}
        >
          <ButtonSmall
            label="Create"
            onClick={() => handleCreatBookingLink()}
            types={ButtonType.Button}
            colorVarient={SmallButtonColor.MidGreen}
          />
        </Stack>
        {/* -------------------------- */}
      </Stack>
    </Stack>
  );
};

export default BookingLinkCalendarCreator;
