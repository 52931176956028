import { InputBase, InputBaseProps } from "@mui/material";
import React, { useEffect, useState } from "react";

interface InputAreaProps extends InputBaseProps {
  /**
   * Initial text value for the textarea.
   */
  text?: string;

  /**
   * Callback function that is called when the text changes.
   * @param newText The new text value.
   */
  onTextChange?: (newText: string) => void;

  /**
   * If true, triggers the text change callback on each word input.
   * If false, value are return from onTextChange when input area is unFocus and press enter button
   * Defaults to false.
   */
  readChangeOnEachWord?: boolean;
}

const InputArea: React.FC<InputAreaProps> = ({
  text = "",
  onTextChange,
  readChangeOnEachWord = false,
  ...props
}) => {
  const [textValue, setTextValue] = useState(text);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextValue(event.target.value);
    if (onTextChange && readChangeOnEachWord) {
      onTextChange(event.target.value);
    }
  };

  const handleBlur = () => {
    onTextChange && text !== textValue && onTextChange(textValue);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey && text !== textValue) {
      event.preventDefault();
      onTextChange && onTextChange(textValue);
      event.currentTarget.blur(); // Remove focus from textarea
    }
  };

  useEffect(() => {
    if (text !== undefined && text !== textValue) {
      setTextValue(text);
    }
  }, [text]);

  return (
    <InputBase
      value={textValue}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      placeholder="Enter text..."
      sx={{
        border: "1px solid #E9EDF2",
        borderRadius: "5px",
        height: "25px",
        width: "100px",
        boxSizing: "border-box",
        padding: "0px 5px",
        font: "normal normal normal 13px/16px Source Serif Pro",
      }}
      {...props}
    />
  );
};

export default InputArea;
