import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PersistGate } from "redux-persist/integration/react";
import { SuspenseState, transferCookiesToLocalStorage } from "./Utils";

// New wrapper component to handle the cookies transfer
const AppWrapper = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Transfer cookies to localStorage on app load
    transferCookiesToLocalStorage();

    // After transfer is done, set loading to false
    setLoading(false);
  }, []); // Runs only once on component mount

  // Show a loading screen while cookies are being transferred
  if (loading) {
    return <SuspenseState colors="#00B188" />;
  }

  // Once loading is done, render the app
  return <App />;
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    {/* PersistGate for Redux state persistence */}
    <PersistGate
      loading={<div>Loading persisted state...</div>}
      persistor={persistor}
    >
      <AppWrapper />
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
