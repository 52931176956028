import { PayloadAction } from "@reduxjs/toolkit";
import { AuthState, ExternalAuthState } from "../authTypes";

export const loginFailure = (state: AuthState, action: PayloadAction<string>) => {
    state.isAuthenticated = false;
    state.user = null;
    state.error = action.payload;
};

export const companyAvailibityFailure = (state: AuthState, action: PayloadAction<string>) => {
    state.error = action.payload;
}

export const fetchCompanyListFailure = (state: AuthState, action: PayloadAction<string>) => {
    state.error = action.payload;
    state.companyList = null;
}

export const getAllDepartmentsFailure = (state: AuthState, action: PayloadAction<string>) => {
    state.error = action.payload;
}

export const optSendFailure = (state: AuthState, action: PayloadAction<string>) => {
    state.isAuthenticated = false;
    state.error = action.payload;
};

export const optVerificationFailure = (state: AuthState, action: PayloadAction<string>) => {
    state.isAuthenticated = false;
    state.error = action.payload;
};

export const signUpFailure = (state: AuthState, action: PayloadAction<string>) => {
    state.isSignUp = false;
    state.user = null;
    state.error = action.payload;
};

export const googleAuthFailure = (
    state: AuthState,
    action: PayloadAction<ExternalAuthState>
) => {
    state.externalEntities = { type: "GoogleAuth" };
    state.eError = action.payload.error || action.payload.message;
    state.success = action.payload.success || false;
    state.eData = null;
};

export const microsoftAuthFailure = (
    state: AuthState,
    action: PayloadAction<ExternalAuthState>
) => {
    state.externalEntities = { type: "MicrosoftAuth" };
    state.eError = action.payload.error || action.payload.message;
    state.success = action.payload.success || false;
    state.eData = null;
};


export const forgotPasswordFailure = (state: AuthState, action: PayloadAction<string>) => {
    state.error = action.payload;
};

export const getAllUsersFailure = (state: AuthState, action: PayloadAction<string>) => {
    state.error = action.payload;
};
export const createNewGuestFailure = (state: AuthState, action: PayloadAction<string>) => {
    state.error = action.payload;
};

export const oAuthCallBackFailure = (state: AuthState, action: PayloadAction<string>) => {
    state.error = action.payload;
};

export const updateUserProfileFailure = (state: AuthState, action: PayloadAction<string>) => {
    state.error = action.payload;
};