import React, { useState } from "react";
import { Button, Box } from "@mui/material";
import styles from "./viewMode.module.css";

// Button component for individual view mode selection
interface ViewModeButtonProps {
  label: string;
  isSelected: boolean;
  onClick: () => void;
}

const ViewModeButton: React.FC<ViewModeButtonProps> = ({
  label,
  isSelected,
  onClick,
}) => {
  return (
    <Button
      disableElevation
      disableFocusRipple
      disableTouchRipple
      disableRipple
      variant="contained"
      onClick={onClick}
      className={`${styles["weekday-selector__button"]} ${
        isSelected ? styles["weekday-selector__button--selected"] : ""
      }`}
    >
      {label}
    </Button>
  );
};

// Main component for selecting view modes
const VIEW_MODES = ["Quarter Day", "Half Day", "Day", "Week", "Month"];

interface ViewModeSelectorProps {
  initialSelectedDays?: string[];
  onChange?: (selectedDays: string[]) => void;
}

const ViewModeSelector: React.FC<ViewModeSelectorProps> = ({
  initialSelectedDays = [],
  onChange,
}) => {
  const [selectedDay, setSelectedDay] = useState<string | null>(
    initialSelectedDays[0] || null
  );

  const handleDayChange = (day: string) => {
    setSelectedDay(day); // Set the selected day
    // Call onChange with the new selection
    if (onChange) {
      onChange([day]); // Pass the selected day in an array
    }
  };

  return (
    <Box className={styles["weekday-selector"]}>
      {VIEW_MODES.map((option, index) => (
        <ViewModeButton
          key={index}
          label={option}
          isSelected={selectedDay === option} // Check if this option is selected
          onClick={() => handleDayChange(option)} // Handle the button click
        />
      ))}
    </Box>
  );
};

export default ViewModeSelector;
