import React from "react";
import {
  Box,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import { EmojiPickerSmall } from "../EmojiPickerSmall";
import { VoiceRecordIcon } from "../../../images";
import { MessageReplyBar } from "../MessageReplyBar";
import { ScheduleMessage } from "../../pages/Message/components";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { scheduleMessage } from "../../../redux/Slice/message/messageActions";
import CustomFroalaEditor from "../CustomFroalaEditor";
import "froala-editor/css/froala_editor.pkgd.min.css";
import { useTranslation } from "react-i18next";
import { MEETING_ENUMS} from "../../pages/Message/constant";

interface MessageActionBarProps {
  message?: string;
  handleEmojiSelect?: any;
  handleSearchChange?: any;
  handleKeyPress?: any;
  handleClick?: () => void;
  replyMessage?: any; // New prop for handling the message being replied to
  handleCancelReply?: () => void; // To cancel the reply
  currentChannel?: any;
  handleClearInput: any;
  userDetails?: any;
  openSchedule?: any;
  setOpenSchedule?: any;
}

const MessageActionBar: React.FC<MessageActionBarProps> = ({
  message,
  handleEmojiSelect,
  handleSearchChange,
  handleKeyPress,
  handleClick,
  replyMessage,
  handleCancelReply,
  currentChannel,
  handleClearInput,
  userDetails,
  openSchedule,
  setOpenSchedule,
}) => {
  const {t}=useTranslation()
  const dispatch = useDispatch();
  const handleOpenSchedule = () => {
    setOpenSchedule(!openSchedule);
  };

  const handleScheduleMessage = async (dateAndTime: any) => {
    if (!message) {
      setOpenSchedule(false);
      return;
    }
    const formattedDateTime = dayjs(dateAndTime).second(0).toISOString();
    const payload = {
      scheduledAt: formattedDateTime,
      userId: userDetails?.data._id,
      content: message,
      channelId: currentChannel?._id,
      channelType: MEETING_ENUMS.BEARISH
    };
    await dispatch(scheduleMessage(payload));

    handleClearInput();
    setOpenSchedule(false);
  };

  return (
    <Box display="flex" flexDirection="column" width="100%">
      {replyMessage?.content && (
        <MessageReplyBar
          currentChannel={currentChannel}
          handleCancelReply={handleCancelReply}
          replyMessage={replyMessage?.content}
        />
      )}

      <Box
        display="flex"
        alignItems="center"
        border="1px solid #ccc"
        borderRadius="4px"
        padding="10px"
        width="100%"
      >
        <Box display="flex" alignItems="center" marginRight="16px">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginRight="8px"
          >
            <IconButton size="small">
              <EmojiPickerSmall
                w={20}
                onSelect={handleEmojiSelect}
                tooltipTitle={t('MESSAGE.ADD_EMOJI')}
              />
            </IconButton>
            <Typography variant="caption">{t('MESSAGE.EMOJIS')}</Typography>
          </Box>
        </Box>
        <CustomFroalaEditor
          currentChannel={currentChannel}
          value={message}
          handleSearchChanges={handleSearchChange}
        />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          marginRight="8px"
        >
          <IconButton size="small">
            <VoiceRecordIcon w={20} />
          </IconButton>
          <Typography variant="caption">{t('MESSAGE.RECORD')}</Typography>
        </Box>
        {openSchedule && (
          <ScheduleMessage
            styles={{ right: "455px", bottom: "87px" }}
            onSchedule={handleScheduleMessage}
          />
        )}
        <Button
          variant="outlined"
          style={{
            marginLeft: "8px",
            borderColor: "#D9E3EF",
            color: "black",
            textTransform: "none",
          }}
          disabled={!message}
          onClick={handleOpenSchedule}
        >
          {t('MESSAGE.SCHEDULE')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{
            marginLeft: "8px",
            color: "white",
            backgroundColor: "#775EE2",
            textTransform: "none",
          }}
          onClick={handleClick}
          disabled={!message}
        >
          {t('MESSAGE.SEND')}
        </Button>
      </Box>
    </Box>
  );
};

export default MessageActionBar;
