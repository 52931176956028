import { StreamIconBearishOSIconPng } from "../images";
import DOMPurify from "dompurify";
import { showSnackbar } from "../redux";
import { getMessagesActivities } from "../redux/Slice/message/messageActions";
import {getHTMLContent, getPlainTextFromHTML} from "../components/pages/Message/constant";

export const requestNotificationPermission = async () => {
  if (!("Notification" in window)) {
    console.error("This browser does not support desktop notifications.");
    return;
  }
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    console.log("Notification permission granted.");
  } else {
    console.log("Notification permission denied.");
  }
};

export const triggerNotification = (
  dispatch: any,
  data: any,
  userData: any,
  channels: any
) => {
  const checkNotifectionPremisson: any = channels[data?.message?.channelId]

  if (checkNotifectionPremisson?.mute) {
    return;
  }

  if (Notification.permission === "granted") {
    if (data.removeEmoji) return;
    const isMentioned = data.message.mentioned.length > 0;

    if (isMentioned) {
      dispatch(getMessagesActivities("NEW"));
    }
    const sanitizedHtml = (html: any) => {
      return DOMPurify.sanitize(html);
    };
    const sanitizedContent = sanitizedHtml(data.message.content);
    const htmlConvertText=getHTMLContent(sanitizedContent)
    const messageConvert = getPlainTextFromHTML(htmlConvertText)
    // const notificationBody = isMentioned
    //   ? `You were mentioned in a message`
    //   : `New message from ${data.message.displayName}`;

    const notificationContent = isMentioned
      ? `You were mentioned in a message\n${messageConvert}`
      : `New message from  ${data.message.displayName} \n\n ${messageConvert}`;

    // const notification = new Notification(notificationBody, {
    //   body: data.message.content,
    //   icon: StreamIconBearishOSIconPng,
    // });
    dispatch(
      showSnackbar({
        message: notificationContent,
        severity: "success",
      })
    );

    // notification.onclick = () => {
    //   window.location.href = `${window.location.origin}/Message/${data?.message?.channelId}`;
    // };
  }
};

export const triggerNotificationForReaction = (
  dispatch: any,
  data: any,
  userData: any
) => {
  const htmlConvertText=getHTMLContent(data.message.content)
  const messageConvert = getPlainTextFromHTML(htmlConvertText)
  if (userData._id !== data.message.userId || userData._id === data.sender)
    return;
  if (Notification.permission === "granted") {
    // const notification = new Notification(
    //   `New reaction from ${data.displayName}`,
    //   {
    //     body: data.message.content,
    //     icon: StreamIconBearishOSIconPng,
    //   }
    // );
    dispatch(
      showSnackbar({
        message: `New reaction from ${data.displayName} ${messageConvert} `,
        severity: "success",
      })
    );
    // notification.onclick = () => {
    //   window.location.href = `${window.location.origin}/Message/${data?.message?.channelId}`;
    // };
  }
};
export const triggerNotificationForEditMessage = (
  dispatch: any,
  data: any,
  userData: any
) => {
  const htmlConvertText=getHTMLContent(data.message.content)
  const messageConvert = getPlainTextFromHTML(htmlConvertText)
  if (Notification.permission === "granted") {
    // const notification = new Notification(
    //   `Your message got edited by ${data.message.displayName}`,
    //   {
    //     body: data.message.content,
    //     icon: StreamIconBearishOSIconPng,
    //   }
    // );
    dispatch(
      showSnackbar({
        message: `Your message got edited by ${data.message.displayName} ${messageConvert} `,
        severity: "success",
      })
    );
    // notification.onclick = () => {
    //   window.location.href = `${window.location.origin}/Message/${data?.message?.channelId}`;
    // };
  }
};

export const triggerNotificationForCreatedNewConnection = (
  data: any,
  dispatch: any
) => {
  if (Notification.permission === "granted") {
    if (data.message.isGroupChat) {
      // const notification = new Notification(
      //   `Your got invited to ${data.name} channel`,
      //   {
      //     body: "react to it",
      //     icon: StreamIconBearishOSIconPng,
      //   }
      // );
      dispatch(
        showSnackbar({
          message: `Your got invited to ${data.name} channel`,
          severity: "success",
        })
      );
      // notification.onclick = () => {
      //   window.location.href = `${window.location.origin}/Message/${data?.message?.channelId}`;
      // };
    } else {
      // const notification = new Notification(`You got new connection`, {
      //   body: data.message.content,
      //   icon: StreamIconBearishOSIconPng,
      // });
      dispatch(
        showSnackbar({
          message: `You got new connection`,
          severity: "success",
        })
      );
      // notification.onclick = () => {
      //   window.location.href = `${window.location.origin}/Message/${data?.message?.channelId}`;
      // };
    }
  }
};
