import React, { useState } from "react";
import { IconButton, Stack, TextareaAutosize, Typography } from "@mui/material";
import { IconDropdownUser } from "../IconDropdownUser";
import { CheckboxMedium } from "../CheckboxMedium";
import { DragAndDropIcon, PlusIcon } from "../../../images";
import { getUserList } from "../../../Utils";

interface ChecklistItem {
  itemName: string;
  assignee: string;
  isChecked: boolean;
}

interface SmallChecklistProps {
  checklist: {
    name: string;
    items: ChecklistItem[];
  };
  onSave: (newItem: ChecklistItem) => void;
}

const SmallChecklist: React.FC<SmallChecklistProps> = ({
  checklist,
  onSave,
}) => {
  const [newItemText, setNewItemText] = useState("");
  const [selectedUser, setSelectedUser] = useState<string>("{{userId2}}");
  const [isChecked, setIsChecked] = useState(false);

  const handleAddItem = () => {
    if (newItemText?.trim()) {
      const newItem: ChecklistItem = {
        itemName: newItemText,
        assignee: selectedUser,
        isChecked: isChecked,
      };
      onSave(newItem);
      setNewItemText("");
      setIsChecked(false);
      setSelectedUser("{{userId2}}"); // Reset to default or initial state
    }
  };

  const userList = getUserList();

  return (
    <Stack>
      <Stack
        sx={{
          height: "35px",
          bgcolor: "rgba(233, 237, 242,0.3)",
          flexDirection: "row",
          alignItems: "center",
          boxSizing: "border-box",
          paddingLeft: "10px",
          border: "0.5px solid #E9EDF2",
          borderRadius: "5px 5px 0px 0px",
        }}
      >
        <Typography
          sx={{ font: "normal normal normal 14px/20px Source Serif Pro" }}
        >
          {checklist?.name}
        </Typography>
      </Stack>
      <Stack
        sx={{
          borderBottom: "0.5px solid #E9EDF2",
          borderLeft: "0.5px solid #E9EDF2",
          borderRight: "0.5px solid #E9EDF2",
          borderRadius: "0px 0px 5px 5px",
        }}
      >
        {checklist?.items?.map((item, idx) => (
          <CheckListContainer key={idx} value={item} />
        ))}
        <Stack
          padding={"0 10px 0 10px"}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <CheckboxMedium
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
          />
          <TextareaAutosize
            placeholder="Add new item"
            value={newItemText}
            onChange={(e) => setNewItemText(e.target.value)}
            style={{
              height: "20px",
              flexGrow: 1,
              resize: "none",
              border: "0px",
              outline: "none",
              padding: "10px",
              font: "normal normal 300 14px/20px Source Serif Pro",
            }}
          />
          <IconDropdownUser
            componentType="showUser"
            showSelectedUser={selectedUser}
            options={userList}
            onSelect={(item: any) => setSelectedUser(item)}
          />
        </Stack>

        <IconButton onClick={handleAddItem}>
          <PlusIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};

interface CheckListContainerProps {
  value: ChecklistItem;
}

const CheckListContainer: React.FC<CheckListContainerProps> = ({ value }) => {
  const [inputValue, setInputValue] = useState(value.itemName);
  const [checked, setChecked] = useState(value.isChecked);
  const [selectedUser, setSelectedUser] = useState(value.assignee);
  const userList = getUserList();
  return (
    <Stack
      sx={{
        minHeight: "40px",
        flexDirection: "row",
        alignItems: "flex-start",
        padding: "15px 10px 10px 10px",
        gap: "10px",
        boxSizing: "border-box",
        borderTop: "0.5px solid #E9EDF2",
      }}
    >
      <IconButton sx={{ p: 0 }}>
        <DragAndDropIcon />
      </IconButton>
      <CheckboxMedium
        checked={checked}
        onChange={(e) => setChecked(e.target.checked)}
      />
      <TextareaAutosize
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        style={{
          height: "20px",
          flexGrow: 1,
          resize: "none",
          border: "0px",
          outline: "none",
          paddingRight: "45px",
          font: "normal normal 300 14px/20px Source Serif Pro",
        }}
      />
      <IconDropdownUser
        componentType="showUser"
        showSelectedUser={selectedUser}
        options={userList}
        onSelect={(item: any) => setSelectedUser(item)}
      />
    </Stack>
  );
};

export default SmallChecklist;
