// MainTopProject.tsx

import React, { useState } from "react";
import { Stack, Popover } from "@mui/material";
import {
  MediumSearchbar,
  SwitchSmall,
  XlargeCreateButton,
  XlargeCreateButtonColor,
} from "../../../../common";
import { HugeCreateItemBearishOS } from "../HugeCreateItemBearishOS";
import {
  ConnectionItem,
  CreateCardData,
  ShareProjectItem,
  initialCreateCardData,
} from "./types";
import { MainTopProjectProps } from "../../types";
import { ProjectIcon } from "../../../../../images";

const MainTopProject: React.FC<MainTopProjectProps> = ({
  viewMode,
  setViewMode,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [createCardData, setCreateCardData] = useState<CreateCardData>(
    initialCreateCardData
  );

  // console.log(createCardData, "createCardData");

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const updateMainDetails = (value: Partial<CreateCardData["mainDetails"]>) => {
    setCreateCardData((prevData) => ({
      ...prevData,
      mainDetails: {
        ...prevData.mainDetails,
        ...value,
      },
    }));
  };

  // Update function for connections
  const updateConnections = (
    value: Partial<{ connectionList: ConnectionItem[] }>
  ) => {
    setCreateCardData((prevData) => {
      // Get the existing connection list
      const existingConnectionList = prevData.connections.connectionList;

      // Filter new valid connections and avoid duplicates
      const newConnections =
        value.connectionList?.filter(
          (newConnection) =>
            newConnection &&
            typeof newConnection.id === "string" &&
            typeof newConnection.text === "string" &&
            !existingConnectionList.some(
              (existingConnection) => existingConnection.id === newConnection.id
            )
        ) || [];

      // Combine the existing connections with the new valid connections
      const updatedConnectionList = [
        ...existingConnectionList,
        ...newConnections,
      ];

      return {
        ...prevData,
        connections: {
          ...prevData.connections,
          connectionList: updatedConnectionList,
        },
      };
    });
  };

  // Update function for share project
  const updateShareProject = (
    value: Partial<{ shareProjectList: ShareProjectItem[] }>
  ) => {
    setCreateCardData((prevData) => {
      // Get the existing share project list
      const existingShareProjectList = prevData.shareProject.shareProjectList;

      // Filter new valid share project items and avoid duplicates
      const newShareProjectItems =
        value.shareProjectList?.filter(
          (newItem) =>
            newItem &&
            typeof newItem.userId === "string" &&
            typeof newItem.text === "string" &&
            typeof newItem.value === "string" &&
            !existingShareProjectList?.some(
              (existingItem) => existingItem.userId === newItem.userId
            )
        ) || [];

      // Combine the existing share project items with the new valid items
      const updatedShareProjectList = [
        ...existingShareProjectList,
        ...newShareProjectItems,
      ];

      return {
        ...prevData,
        shareProject: {
          shareProjectList: updatedShareProjectList,
        },
      };
    });
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        className="boxProject" // Replace with your actual CSS class name
      >
        <XlargeCreateButton
          label="Create a New Project"
          subtitle="Projects serve as centralized locations for all your work"
          colorVarient={XlargeCreateButtonColor.Green}
          icon={<ProjectIcon color="white" />}
          onClick={handleClick}
        />
        <Stack direction="row" alignItems="center" gap={2}>
          <SwitchSmall viewMode={viewMode} setViewMode={setViewMode} />
          <MediumSearchbar ph={"Search all Projects"} />
        </Stack>
      </Stack>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <HugeCreateItemBearishOS
          createCardData={createCardData}
          updateMainDetails={updateMainDetails}
          updateConnections={updateConnections}
          updateShareProject={updateShareProject}
        />
      </Popover>
    </>
  );
};

export default MainTopProject;
