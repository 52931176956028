import React, { useEffect, useState } from "react";
import { IconDropDownButton } from "../IconDropDownButton";
import { FiltersIcon } from "../../../images";
import { Popover } from "@mui/material";
import {
  DragAndDropMenu,
  DragAndDropMenuListType,
  FilterCriteriaType,
  FilterMenu,
} from "../DragAndDropMenu";
import { useTranslation } from "react-i18next";

interface DropdownButtonFilterMenuProps {
  filterOption: DragAndDropMenuListType[];
  filteredList?: (listData: FilterCriteriaType[]) => void;
  buttonColor: "CRMOrange" | "TraxBluish";
}

const DropdownButtonFilterMenu: React.FC<DropdownButtonFilterMenuProps> = ({
  filterOption,
  buttonColor,
  filteredList,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOpt, setMenuOpt] =
    useState<DragAndDropMenuListType[]>(filterOption);

  const open = Boolean(anchorEl);
  const id = open ? "dropdown-popover" : undefined;

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget); // Toggle the popover
  };

  const [filteredValueList, setFilteredValueList] = useState<
    FilterCriteriaType[]
  >([]);

  const handleClose = () => {
    setAnchorEl(null); // Close the popover
  };

  const updateSelectionList = (list: FilterCriteriaType[]) => {
    const uniqueList = list.filter(
      (item, index, self) =>
        index ===
        self.findIndex((t) => t.selectedItem.label === item.selectedItem.label)
    );

    // Ensure that the first element's condition is 'where'
    if (uniqueList.length > 0 && uniqueList[0].condition !== "where") {
      uniqueList[0].condition = "where";
    }

    setFilteredValueList(uniqueList);
  };

  const menuSelectHandler = (value: DragAndDropMenuListType) => {
    const data: FilterCriteriaType = {
      condition: "where",
      operator: "contains",
      selectedItem: value,
      filterInput: "",
    };
    setFilteredValueList((pre) => [...pre, data]);
  };

  useEffect(() => {
    if (filteredList) {
      filteredList(filteredValueList);
    }
  }, [filteredValueList]);

  return (
    <div>
      <IconDropDownButton
        label={
          filteredValueList.length > 0 ? t("TRAX.FILTERED") : t("TRAX.FILTER")
        }
        icon={FiltersIcon}
        numCount={
          filteredValueList.length > 0 ? filteredValueList.length : undefined
        }
        menuOpen={open}
        onButtonClick={handleButtonClick}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            padding: 0,
            // width: "160px",
            marginTop: "10px",
            zIndex: 100,
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.16)",
            borderRadius: "5px",
          },
        }}
      >
        {filteredValueList.length > 0 ? (
          <FilterMenu
            buttonColor={buttonColor}
            options={menuOpt}
            SelectedOptList={filteredValueList}
            onChange={(data) => updateSelectionList(data)}
          />
        ) : (
          <DragAndDropMenu
            labelIcon={FiltersIcon}
            menuItems={menuOpt}
            setMenuItems={(newValue) => setMenuOpt(newValue)}
            label={t("TRAX.FILTER_BY_COLUMN")}
            onMenuSelect={(opt) => menuSelectHandler(opt[0])}
            AddButtonLabel={t("TRAX.ADVANCE_FILTER")}
            AddButtonClick={() => null}
            dragIconPlace="left"
            menuWidth="250px"
            maxHeight="400px"
            backgroundColor="white"
          />
        )}
      </Popover>
    </div>
  );
};

export default DropdownButtonFilterMenu;
