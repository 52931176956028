import React, { useState } from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import {
  DatePickerMini,
  SingleDatePickerSmall,
  StyledRadioButtonBlack,
} from "../../../../../../common";
import styles from "./AlternateTimeView.module.css";
import { ProgressIcon } from "../../../../../../../images";
import { useTranslation } from "react-i18next";

interface ProposedTime {
  startTime: string; // Combined date and time in ISO 8601 format
  endTime: string; // Combined date and time in ISO 8601 format
}

interface AlternateTimeViewProps {
  updateProposedTimes?: (proposedTimes: ProposedTime[]) => void; // Function to update proposedTimes in parent component
}

const AlternateTimeView: React.FC<AlternateTimeViewProps> = ({
  updateProposedTimes,
}) => {
  const { t } = useTranslation();
  const [useAlternateTime, setUseAlternateTime] = useState<boolean>(false);
  const [alternateTimes, setAlternateTimes] = useState<ProposedTime[]>([]);

  const handleCheckboxToggle = () => {
    setUseAlternateTime((prev) => {
      if (!prev) {
        setAlternateTimes([{ startTime: "", endTime: "" }]); // Initialize with one empty proposed time
      } else {
        setAlternateTimes([]); // Clear all proposed times
        updateProposedTimes?.([]); // Clear proposed times in parent component
      }
      return !prev;
    });
  };

  const handleAddAlternateTime = () => {
    if (alternateTimes.length < 3) {
      setAlternateTimes((prev) => [...prev, { startTime: "", endTime: "" }]);
    }
  };

  const handleRemoveAlternateTime = (index: number) => {
    const updatedTimes = alternateTimes.filter((_, i) => i !== index);
    setAlternateTimes(updatedTimes);
    updateProposedTimes?.(updatedTimes); // Update proposed times in parent component
  };

  // Function to convert 12-hour time format to 24-hour time
  const convertTo24HourFormat = (time: string): string => {
    const [hourMinute, period] = time.split(" "); // Split time and period (AM/PM)
    let [hour, minute] = hourMinute.split(":").map(Number);

    if (period === "PM" && hour < 12) {
      hour += 12; // Convert PM hours to 24-hour format (except for 12 PM)
    } else if (period === "AM" && hour === 12) {
      hour = 0; // Convert 12 AM to 00:00
    }

    return `${String(hour).padStart(2, "0")}:${String(minute).padStart(
      2,
      "0"
    )}`;
  };

  // Function to update the time part of startTime
  const updateStartTime = (formattedTime: string, index: number) => {
    // Convert formatted time from 12-hour format to 24-hour format
    const convertedTime = convertTo24HourFormat(formattedTime);

    // Combine the selected time with the existing date from startTime
    const existingDate = new Date(alternateTimes[index].startTime);
    const updatedStartTime = new Date(
      `${existingDate.toISOString().split("T")[0]}T${convertedTime}:00.000Z`
    );

    // Convert to ISO string
    const updatedStartTimeIso = updatedStartTime.toISOString();

    // Update the startTime with the new time
    const updatedTimes = [...alternateTimes];
    updatedTimes[index].startTime = updatedStartTimeIso;

    setAlternateTimes(updatedTimes);
    if (updateProposedTimes) {
      updateProposedTimes(updatedTimes);
    }
  };

  // Function to update the time part of endTime
  const updateEndTime = (formattedTime: string, index: number) => {
    // Convert formatted time from 12-hour format to 24-hour format
    const convertedTime = convertTo24HourFormat(formattedTime);

    // Combine the selected time with the existing date from endTime
    const existingDate = new Date(alternateTimes[index].endTime);
    const updatedEndTime = new Date(
      `${existingDate.toISOString().split("T")[0]}T${convertedTime}:00.000Z`
    );

    // Convert to ISO string
    const updatedEndTimeIso = updatedEndTime.toISOString();

    // Update the endTime with the new time
    const updatedTimes = [...alternateTimes];
    updatedTimes[index].endTime = updatedEndTimeIso;

    setAlternateTimes(updatedTimes);
    if (updateProposedTimes) {
      updateProposedTimes(updatedTimes);
    }
  };

  // Function to update only the date part of startTime and endTime
  const updateDate = (selectedDate: Date, index: number) => {
    const updatedStartTime = new Date(selectedDate);
    const updatedEndTime = new Date(selectedDate);

    // Set the updated date for both startTime and endTime
    const updatedStartTimeIso = updatedStartTime.toISOString();
    const updatedEndTimeIso = updatedEndTime.toISOString();

    const updatedTimes = [...alternateTimes];
    updatedTimes[index].startTime = updatedStartTimeIso;
    updatedTimes[index].endTime = updatedEndTimeIso;

    setAlternateTimes(updatedTimes);
    if (updateProposedTimes) {
      updateProposedTimes(updatedTimes);
    }
  };

  return (
    <Stack className={styles["alternate-time-view"]}>
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
        <ProgressIcon w={20} />
        <Typography className={styles["alternate-time-view__title"]}>
          Propose Multiple or Alternative Times
        </Typography>
        <StyledRadioButtonBlack
          onClick={handleCheckboxToggle}
          checked={useAlternateTime}
        />
      </Stack>

      {useAlternateTime && (
        <Stack>
          {alternateTimes?.map((time, index) => (
            <Box
              key={index}
              className={styles["alternate-time-view__alternate-time"]}
              id="objectivedatepicker"
            >
              <Stack className={styles["alternate-time-view__row"]}>
                <SingleDatePickerSmall
                  onSelect={(date: any) => updateDate(date, index)}
                />
                <Typography className={styles["alternate-time-view__label"]}>
                  From
                </Typography>
                <DatePickerMini
                  onFormatedTimeSelect={(time: any) => {
                    console.log(time);
                    updateStartTime(time, index);
                  }}
                />
                <Typography className={styles["alternate-time-view__label"]}>
                  {t("MESSAGE.TO")}
                </Typography>
                <DatePickerMini
                  onFormatedTimeSelect={(time: any) => {
                    console.log(time);
                    updateEndTime(time, index);
                  }}
                />
              </Stack>
              <IconButton
                disableRipple
                className={styles["alternate-time-view__remove-button"]}
                onClick={() => handleRemoveAlternateTime(index)}
              >
                <Typography
                  className={styles["alternate-time-view__remove-text"]}
                >
                  Remove Time
                </Typography>
              </IconButton>
            </Box>
          ))}
          {alternateTimes.length < 3 && (
            <Stack
              className={styles["alternate-time-view__add-button-container"]}
            >
              <IconButton
                disableRipple
                className={styles["alternate-time-view__add-button"]}
                onClick={handleAddAlternateTime}
              >
                <Typography className={styles["alternate-time-view__add-text"]}>
                  Add Another Alternate Time
                </Typography>
              </IconButton>
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default AlternateTimeView;
