import React, { useState } from "react";
import { Stack, TextField } from "@mui/material";

interface UrlViewProps {
  initialValue?: string; // Initial URL value
  onChange?: (url: string) => void; // Callback when the URL is updated
}

const UrlView: React.FC<UrlViewProps> = ({ initialValue = "", onChange }) => {
  const [url, setUrl] = useState<string>(initialValue);
  const [isValid, setIsValid] = useState<boolean>(true);

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setUrl(value);

    // Validate URL using a regular expression
    const urlPattern =
      /^(https?:\/\/)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})(\/\S*)?$/;
    const valid = urlPattern?.test(value);
    setIsValid(valid);

    if (valid && onChange) {
      onChange(value); // Call parent onChange if valid
    }
  };

  return (
    <Stack>
      <TextField
        value={url}
        onChange={handleUrlChange}
        error={!isValid}
        helperText={!isValid ? "Please enter a valid URL." : ""}
        placeholder="http://example.com"
        fullWidth
        label="" // Remove the label
        variant="outlined" // Use outlined variant for better control over styles
        sx={{
          "& .MuiOutlinedInput-root": {
            border: "none", // Remove border
            "& fieldset": {
              border: "none", // Remove fieldset border
            },
            "&:hover fieldset": {
              border: "none", // Remove border on hover
            },
            "&.Mui-focused fieldset": {
              border: "none", // Remove border on focus
              outline: "none", // Remove outline on focus
            },
          },
          padding: 0,
          font: "normal normal 300 13px/17px Source Serif Pro",
        }}
      />
    </Stack>
  );
};

export default UrlView;
