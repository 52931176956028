import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AvatarNormal } from "../../../../../../common";
import { adjustHexColor } from "../../../../../../../Utils";
import EmojiView from "../../EmojiView/EmojiView";
import FroalalComment from "../../froalalComment/FroalalComment";
import ManageCommentButtons from "./ManageCommentButtons";
import { formatDate } from "../../../../../../../Utils/dateFormat";
import {
  addSubCommentToComment__api,
  editComment__api,
  toggleReactionToComment__api,
} from "../../../../../../../redux";
import { useDispatch } from "react-redux";
import ReplyManage from "./ReplyManage";

interface SavedViewProps {
  commentDatas?: any;
  getAllCommentsOfDocument?: any;
}
// Function to generate a random hex color
const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const SavedView: React.FC<SavedViewProps> = ({
  commentDatas,
  getAllCommentsOfDocument,
}) => {
  const [commentData, setCommentData] = useState(commentDatas || {});
  const dispatch = useDispatch();
  const [toggleReply, setToggleReply] = useState(false);
  const [toggleEdit, setToggleEdit] = useState(false);

  // States for comment content (edit and reply)
  const [editCommentContent, setEditCommentContent] = useState(
    commentData?.content || ""
  );
  const [replyContent, setReplyContent] = useState("");

  // Handle save/cancel for editing comment
  const handleEditAction = async (label: string) => {
    if (label === "Save") {
      if (!commentData?._id || !editCommentContent) {
        return;
      }
      const payload = {
        commentId: commentData?._id,
        content: editCommentContent,
        mentions: [],
      };

      const action = editComment__api(payload);
      await dispatch(action);

      if (getAllCommentsOfDocument) {
        getAllCommentsOfDocument();
      }
    }
    setToggleEdit(false); // Close edit mode
  };

  // Handle save/cancel for replying to a comment
  const handleReplyAction = async (label: string) => {
    if (label === "Save") {
      if (!commentData?._id || !replyContent) {
        return;
      }
      const payload = {
        commentId: commentData?._id,
        content: replyContent,
        mentions: [],
      };

      const action = addSubCommentToComment__api(payload);
      await dispatch(action);

      if (getAllCommentsOfDocument) {
        getAllCommentsOfDocument();
      }
    }
    setToggleReply(false); // Close reply mode
  };

  const handleAddReactionToComment = async (emoji: any) => {
    if (!commentData?._id || !emoji) {
      return;
    }

    const payload = {
      emoji: emoji,
    };

    const action = toggleReactionToComment__api(commentData?._id, payload);
    await dispatch(action);
    if (getAllCommentsOfDocument) {
      getAllCommentsOfDocument();
    }
  };

  const isValidUrl = (url: string | undefined): boolean => {
    if (!url) return false;
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z0-9]+([\\-\\.][a-z0-9]+)*)?\\.)+[a-z]{2,}|" + // domain name
        "localhost|" + // localhost
        "\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}|" + // IP address
        "\\[?[a-f0-9]*:[a-f0-9:%.~\\-\\[\\]]*\\])" + // IPv6
        "(\\:\\d+)?(\\/[-a-z0-9+&@#\\/%?=~_|!:,.;]*[a-z0-9+&@#\\/%=~_|])?$",
      "i"
    ); // fragment locator
    return !!pattern?.test(url);
  };
  const avatarUrl = commentData?.createdBy?.avatar;
  const avatarColor = avatarUrl?.startsWith("#")
    ? avatarUrl?.split("|")?.[0]
    : undefined;

  const decodeHTML = (html: any) => {
    if (!html) {
      return "";
    }
    const txt = document?.createElement("textarea");
    txt.innerHTML = html;
    return txt?.value;
  };
  const randomColor = getRandomColor();

  useEffect(() => {
    if (commentDatas) {
      setCommentData(commentDatas);
    }
  }, [commentDatas]);

  const reactions = commentData?.reactions || [];

  // Group users by emoji
  const emojiMap: Record<
    string,
    Array<{ id: string; firstName: string; lastName: string; avatar: string }>
  > = {};

  reactions?.forEach((reaction: any) => {
    const emoji = reaction?.emoji;
    const user = {
      id: reaction.userId?._id,
      firstName: reaction?.userId?.firstName,
      lastName: reaction?.userId?.lastName,
      avatar: reaction?.userId?.avatar,
    };

    if (!emojiMap?.[emoji]) {
      emojiMap[emoji] = [];
    }
    emojiMap[emoji]?.push(user);
  });

  return (
    <Stack position={"relative"} direction={"column"} gap={1} height={"100%"}>
      <Stack
        position={"absolute"}
        width={"2px"}
        top={"0px"}
        left={"13.5px"}
        borderRadius={"40px"}
        height={"100%"}
        bgcolor={randomColor}
      />

      <Stack
        sx={{
          flexDirection: "row",
          gap: "5px",
          padding: "0",
        }}
      >
        {!toggleEdit && (
          <AvatarNormal
            size={30}
            imgSrc={isValidUrl(avatarUrl) ? avatarUrl : undefined} // Set imgSrc conditionally
            avatarColor={avatarColor} // Set avatarColor conditionally
            username={
              commentData?.createdBy?.firstName +
                " " +
                commentData?.createdBy?.lastName || "John"
            }
          />
        )}
        <Stack
          sx={{
            flexGrow: 1,
            gap: "5px",
            maxWidth: "100%",
          }}
        >
          {!toggleEdit ? (
            <Stack>
              <Stack width={"100%"} direction={"column"} gap={1}>
                <Typography
                  component="span"
                  sx={{
                    minHeight: "35px",
                    font: "normal normal 600 14px/18px Source Serif Pro",
                    marginTop: "6px",
                  }}
                  children={
                    commentData?.createdBy?.firstName +
                    " " +
                    commentData?.createdBy?.lastName
                  }
                />

                <Typography
                  component="span"
                  sx={{ minHeight: "35px", font: "unset" }}
                  dangerouslySetInnerHTML={{
                    __html:
                      decodeHTML(commentData?.content) ||
                      "<p>This is paragraph</p>",
                  }}
                />
              </Stack>

              <Stack
                sx={{ flexDirection: "row", justifyContent: "space-between" }}
              >
                <Typography
                  sx={{
                    font: "normal normal 400 11px/14px Source Sarif Pro",
                    color: adjustHexColor("#000000", 50),
                  }}
                >
                  {formatDate(commentData?.updatedAt)}
                </Typography>
                <ManageCommentButtons
                  onButtonClick={(label: string, emoji?: any) => {
                    if (label === "Edit") {
                      setToggleEdit(true);
                    } else if (label === "Reply") {
                      setToggleReply(true);
                    } else if (label === "React") {
                      handleAddReactionToComment(emoji);
                    }
                  }}
                  mode="viewMode"
                />
              </Stack>

              <Stack
                sx={{
                  flexDirection: "row",
                  maxWidth: "100%",
                }}
              >
                <Stack
                  sx={{
                    height: "25px",
                    flexDirection: "row",
                    alignItems: "center",
                    overflow: "hidden",
                    overflowX: "scroll",
                    scrollbarWidth: "none",
                    gap: "5px",
                  }}
                >
                  {Object.entries(emojiMap).map(([emoji, users]) => (
                    <EmojiView key={emoji} emoji={emoji} userList={users} />
                  ))}
                </Stack>
              </Stack>
            </Stack>
          ) : (
            <Stack
              direction={"row"}
              alignItems={"flex-start"}
              gap={"3px"}
              position={"relative"}
              justifyContent={"flex-start"}
            >
              <FroalalComment
                content={decodeHTML(editCommentContent)}
                handleContentChange={(e: any) => setEditCommentContent(e)} // Update comment content on change
              />
              <Stack
                zIndex={"10009"}
                position={"absolute"}
                bottom={"55px"}
                right={"10px"}
              >
                <ManageCommentButtons
                  onButtonClick={handleEditAction} // Save or cancel edit
                  mode="editMode"
                />
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>

      {toggleReply && (
        <Stack
          direction={"row"}
          alignItems={"flex-start"}
          gap={"3px"}
          position={"relative"}
          ml={1}
          justifyContent={"flex-start"}
        >
          <FroalalComment
            content={replyContent}
            handleContentChange={(e: any) => setReplyContent(e)} // Update reply content on change
          />
          <Stack
            zIndex={"10009"}
            position={"absolute"}
            bottom={"55px"}
            right={"10px"}
          >
            <ManageCommentButtons
              onButtonClick={handleReplyAction} // Save or cancel reply
              mode="editMode"
            />
          </Stack>
        </Stack>
      )}

      <Stack padding={"0px 0px 0px 17px"}>
        <ReplyManage
          getAllCommentsOfDocument={getAllCommentsOfDocument}
          _id={commentData?._id || commentDatas?._id}
        />
      </Stack>
    </Stack>
  );
};

export default SavedView;
