import { CRMCenterState } from "../CRMCenterType";

/* Add Contact */
export const addContact__Request = (state: CRMCenterState) => {
    state.error = null;
    state.data = null;
};

/* Get One Contact */
export const getOneContact__Request = (state: CRMCenterState) => {
    state.error = null;
    state.data = null;
};

/* Update One Contact */
export const updateOneContact__Request = (state: CRMCenterState) => {
    state.error = null;
    state.data = null;
};

/* Delete One Contact */
export const deleteOneContact__Request = (state: CRMCenterState) => {
    state.error = null;
    state.data = null;
};

/* Add Business */
export const addBusiness__Request = (state: CRMCenterState) => {
    state.error = null;
    state.data = null;
};

/* Get One Business */
export const getOneBusiness__Request = (state: CRMCenterState) => {
    state.error = null;
    state.data = null;
};

/* Update One Business */
export const updateOneBusiness__Request = (state: CRMCenterState) => {
    state.error = null;
    state.data = null;
};

/* Delete One Business */
export const deleteOneBusiness__Request = (state: CRMCenterState) => {
    state.error = null;
    state.data = null;
};

/* Update Custom Elements */
export const updateCustomElements__Request = (state: CRMCenterState) => {
    state.error = null;
    state.data = null;
};

/* Get All Contacts */
export const getAllContacts__Request = (state: CRMCenterState) => {
    state.error = null;
    state.data = null;
};

/* Get All Business */
export const getAllBusiness__Request = (state: CRMCenterState) => {
    state.error = null;
    state.data = null;
};

/* Create Group */
export const createGroup__Request = (state: CRMCenterState) => {
    state.error = null;
    state.data = null;
};

/* Get All Groups */
export const getAllGroups__Request = (state: CRMCenterState) => {
    state.error = null;
    state.data = null;
};

/* Get Group By ID */
export const getGroupById__Request = (state: CRMCenterState) => {
    state.error = null;
    state.data = null;
};

/* Update Group */
export const updateGroup__Request = (state: CRMCenterState) => {
    state.error = null;
    state.data = null;
};

/* Delete Group */
export const deleteGroup__Request = (state: CRMCenterState) => {
    state.error = null;
    state.data = null;
};

/* Add to Group */
export const addToGroup__Request = (state: CRMCenterState) => {
    state.error = null;
    state.data = null;
};

/* Create Deal */
export const createDeal__Request = (state: CRMCenterState) => {
    state.error = null;
    state.data = null;
};

/* Get All Deals */
export const getAllDeals__Request = (state: CRMCenterState) => {
    state.error = null;
    state.data = null;
};

/* Get Deal By ID */
export const getDealById__Request = (state: CRMCenterState) => {
    state.error = null;
    state.data = null;
};

/* Update Deal */
export const updateDeal__Request = (state: CRMCenterState) => {
    state.error = null;
    state.data = null;
};

/* Delete Deal */
export const deleteDeal__Request = (state: CRMCenterState) => {
    state.error = null;
    state.data = null;
};

/* Add Note to Deal */
export const addNoteTo__Request = (state: CRMCenterState) => {
    state.error = null;
    state.data = null;
};

export const getNoteTo__Request = (state: CRMCenterState) => {
    state.error = null;
    state.data = null;
};


/* Outlook === */
/* Get All Outlook Contacts */
export const getAllOutlookContacts__Request = (state: CRMCenterState) => {
    state.error = null;
    state.data = null;
};

/* Get Outlook Contact By ID */
export const getOutlookContactById__Request = (state: CRMCenterState) => {
    state.error = null;
    state.data = null;
};

/* Create Outlook Contact */
export const createOutlookContact__Request = (state: CRMCenterState) => {
    state.error = null;
    state.data = null;
};

/* Update Outlook Contact */
export const updateOutlookContact__Request = (state: CRMCenterState) => {
    state.error = null;
    state.data = null;
};

/* Outlook === */