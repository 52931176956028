import { Stack, Typography } from "@mui/material";
import React from "react";
import { CheckedCircleIcon } from "../../../../../images";
import {
  ButtonSmall,
  ButtonType,
  Dummy_SmallSearchFolderList,
  SmallButtonColor,
  SmallSearchFolder,
} from "../../../../common";
import { PlainInputBox } from "../PlainInputBox";

interface AddEmailtoTaskProps {}

const AddEmailtoTask: React.FC<AddEmailtoTaskProps> = ({}) => {
  return (
    <Stack
      sx={{
        width: "250px",
        borderRadius: "5px",
        boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
        bgcolor: "white",
        padding: "15px",
        boxSizing: "border-box",
        gap: "15px",
      }}
    >
      <Stack gap={"10px"}>
        <Stack sx={{ flexDirection: "row", gap: "5px" }}>
          <CheckedCircleIcon w={15} />
          <Typography
            sx={{ font: "normal normal 400 14px/18px Source Serif Pro" }}
          >
            Add Email to Task
          </Typography>
        </Stack>
        <Typography
          sx={{
            font: "normal italic 300 14px/18px Source Serif Pro",
            letterSpacing: "-0.05em",
          }}
        >
          Add your email to a task inside one of your workspaces...
        </Typography>
      </Stack>

      <SmallSearchFolder
        placeholder="Search all workspaces"
        options={Dummy_SmallSearchFolderList}
        onSelect={(item) => null}
      />

      <Typography
        sx={{
          font: "normal normal 400 14px/18px Source Serif Pro",
          marginBottom: "-5px",
        }}
      >
        Pick a place in the workspace
      </Typography>

      <SmallSearchFolder
        placeholder="Search all workspace items"
        options={Dummy_SmallSearchFolderList}
        onSelect={(item) => null}
      />

      <Typography sx={{ font: "normal normal 400 14px/18px Source Serif Pro" }}>
        Name your task
      </Typography>

      <PlainInputBox placeholder="Type your task here" />

      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <ButtonSmall
          label="Save"
          types={ButtonType.Button}
          colorVarient={SmallButtonColor.Blue}
        />
      </Stack>
    </Stack>
  );
};

export default AddEmailtoTask;
