import { IconButton, InputBase, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { LastCalendarIcon, XCircleIcon } from "../../../../../images";
import {
  ButtonSmall,
  ButtonType,
  CheckboxMedium,
  DatePickerMini,
  SmallButtonColor,
} from "../../../../common";
import { adjustHexColor } from "../../../../../Utils";
import { WeekDaysEnum, WeekDaysList } from "../../../../../Utils";
import {
  CalendarScheduleMenuListInterface,
  DaysTimeSchedule,
} from "../CalendarScheduleMenu/CalendarScheduleMenuType";
import { CopyTimeView } from "./view";
import { useTranslation } from "react-i18next";
type InitialValueType = Partial<CalendarScheduleMenuListInterface>; // This allows for partial data
// or use Partial<CalendarScheduleMenuListInterface>

interface CalendarSchedulePopupProps {
  onCloseClick: () => void; // Function to handle the closing of the popup
  initialValue?: InitialValueType; // Initial value, optional
  onSave: (data: CalendarScheduleMenuListInterface) => void; // Callback for saving the schedule data
}

const CalendarSchedulePopup: React.FC<CalendarSchedulePopupProps> = ({
  onCloseClick,
  initialValue = {},
  onSave,
}) => {
  const { t } = useTranslation();
  const [sheduleData, setSheduleData] =
    useState<CalendarScheduleMenuListInterface>({
      _id:
        initialValue && "_id" in initialValue ? initialValue?._id : undefined,
      name:
        initialValue &&
        "name" in initialValue &&
        typeof initialValue?.name === "string"
          ? initialValue?.name
          : "Default Schedule Name", // Provide a default string value
      schedule:
        initialValue && Array?.isArray(initialValue?.schedule)
          ? initialValue?.schedule
          : [], // Make sure it's an array
    });

  // State to hold selected days list
  const [selectedDays, setSelectedDays] = useState<WeekDaysList[]>(
    initialValue && Array?.isArray(initialValue?.schedule)
      ? initialValue?.schedule?.map((item) => item?.day)
      : [] // Check if it's an array
  );

  const [selectedTime, setSelectedTime] = useState<DaysTimeSchedule[]>(
    initialValue && Array?.isArray(initialValue?.schedule)
      ? initialValue?.schedule
      : [] // Check if it's an array
  );
  const [tagedDay, setTagedDay] = useState<WeekDaysEnum>(WeekDaysEnum.Sunday);

  const handleDaySelection = (day: WeekDaysList) => {
    setSelectedDays((prevSelectedDays) =>
      prevSelectedDays.includes(day)
        ? prevSelectedDays.filter((selectedDay) => selectedDay !== day)
        : [...prevSelectedDays, day]
    );
  };

  const handleTimeChange = (
    day: WeekDaysList,
    timeType: "start" | "end",
    value: string
  ) => {
    setSelectedTime((prevSchedule) => {
      const existingSchedule = prevSchedule.find((item) => item.day === day);

      if (existingSchedule) {
        return prevSchedule.map((schedule) => {
          if (schedule.day === day) {
            return {
              ...schedule,
              startTime: timeType === "start" ? value : schedule.startTime,
              endTime: timeType === "end" ? value : schedule.endTime,
            };
          }
          return schedule;
        });
      } else {
        const newScheduleEntry: DaysTimeSchedule = {
          day: day,
          startTime: timeType === "start" ? value : "",
          endTime: timeType === "end" ? value : "",
        };
        return [...prevSchedule, newScheduleEntry];
      }
    });
  };

  const handleCopyFn = (dayList: WeekDaysEnum[]) => {
    const data = selectedTime?.find((item) => item?.day === tagedDay);

    if (!data) return;

    const { startTime, endTime } = data;

    setSelectedTime((prevSchedule) => {
      const updatedSchedule = dayList?.map((day) => {
        const existingSchedule = prevSchedule?.find(
          (item) => item?.day === day
        );
        return existingSchedule
          ? { ...existingSchedule, startTime, endTime }
          : { day, startTime, endTime };
      });

      const remainingSchedule = prevSchedule?.filter(
        (item) => !dayList?.includes(item?.day as WeekDaysEnum)
      );
      return [...remainingSchedule, ...updatedSchedule];
    });

    setSelectedDays((prevSelectedDays) => {
      return Array?.from(new Set([...prevSelectedDays, ...dayList]));
    });
  };

  useEffect(() => {
    const updatedSchedule = selectedDays.map((day) => {
      const timeEntry = selectedTime.find((item) => item.day === day);
      return {
        day,
        startTime: timeEntry?.startTime || "",
        endTime: timeEntry?.endTime || "",
      };
    });

    setSheduleData((prev) => ({ ...prev, schedule: updatedSchedule })); // Change days to schedule
  }, [selectedDays, selectedTime]);

  return (
    <Stack
      sx={{
        width: "400px",
        borderRadius: "5px",
        bgcolor: "white",
        boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
      }}
    >
      {/* header */}
      <Stack
        sx={{
          borderTopLeftRadius: "inherit",
          borderTopRightRadius: "inherit",
          height: "40px",
          flexDirection: "row",
        }}
      >
        <Stack
          sx={{
            borderTopLeftRadius: "inherit",
            width: "15px",
            bgcolor: "#21B481",
            flexShrink: 0,
          }}
        />
        <Stack
          sx={{
            flexGrow: 1,
            bgcolor: adjustHexColor("#21B481", 80),
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0px 10px",
            boxSizing: "border-box",
            borderTopRightRadius: "inherit",
          }}
        >
          <Typography
            sx={{
              font: "normal normal 900 18px/23px Source Serif Pro",
              maxWidth: "250px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              color: "white",
            }}
          >
            {sheduleData?.name || "Calendar Schedule name"}
          </Typography>
          <IconButton onClick={onCloseClick} sx={{ padding: "5px" }}>
            <XCircleIcon w={20} color="#fff" />
          </IconButton>
        </Stack>
      </Stack>

      {/* card body */}
      <Stack
        sx={{
          boxSizing: "border-box",
          padding: "20px 20px 20px 25px",
          gap: "20px",
          flexShrink: 0,
        }}
      >
        {/* Booking name input area */}
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
          <LastCalendarIcon w={20} />
          <InputBase
            value={sheduleData.name}
            onChange={(e) =>
              setSheduleData((prev) => ({ ...prev, name: e.target.value }))
            }
            sx={{
              flexGrow: 1,
              font: "normal normal 400 18px/23px Source Serif Pro",
              height: "25px",
            }}
            placeholder="Calendar schedule name"
          />
        </Stack>

        <Stack sx={{ gap: "23px" }}>
          {Object?.values(WeekDaysEnum)?.map((day) => (
            <Stack
              key={day}
              sx={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{ font: "normal normal 300 18px/23px Source Serif Pro" }}
              >
                {day?.charAt(0)?.toUpperCase() + day?.slice(1)}
              </Typography>
              <Stack
                sx={{ flexDirection: "row", alignItems: "center", gap: "15px" }}
              >
                <CheckboxMedium
                  boxSize={15}
                  checked={selectedDays.includes(day)}
                  onChange={() => handleDaySelection(day)}
                />

                <CopyTimeView
                  selectedDays={(list) => handleCopyFn(list)}
                  onIconClick={() => setTagedDay(day)}
                />

                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <DatePickerMini
                    onFormatedTimeSelect={(value) =>
                      handleTimeChange(day, "start", value)
                    }
                    initialTime={
                      selectedTime?.find((item) => item?.day === day)?.startTime
                    }
                  />
                  <Typography
                    sx={{
                      font: "normal normal 400 10px/13px Source Serif Pro",
                    }}
                  >
                      {t('MESSAGE.TO')}
                  </Typography>
                  <DatePickerMini
                    onFormatedTimeSelect={(value) =>
                      handleTimeChange(day, "end", value)
                    }
                    initialTime={
                      selectedTime?.find((item) => item?.day === day)?.endTime
                    }
                  />
                </Stack>
              </Stack>
            </Stack>
          ))}
        </Stack>

        {/* Button */}
        <Stack
          sx={{
            marginTop: "10px",
            flexDirection: "row",
            justifyContent: "end",
          }}
        >
          <ButtonSmall
            onClick={() => {
              // For new schedules, omit _id (only include for updates)
              const newSchedule = { ...sheduleData };
              if (!initialValue?._id) {
                delete newSchedule._id;
              }
              onSave && onSave(newSchedule);
            }}
            label={initialValue?._id ? "Update" : "Create"}
            types={ButtonType.Button}
            colorVarient={SmallButtonColor.MidGreen}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CalendarSchedulePopup;
