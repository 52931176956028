import { Stack } from "@mui/material";
import React, { useState } from "react";
import { AddColumnTraxTable, DataTable } from "../../../components";
import {
  CellValueChangedEvent,
  ColDef,
  ICellRendererParams,
} from "ag-grid-community";
import { AddCalculationsMenu, DomainTable } from "../../../../CRM/components";
import {
  EmailIcon,
  EmployeeCountIcon,
  FullNameIcon,
  LocationIcon,
  RelationshipStrengthIcon,
} from "../../../../../../images";
import {
  CurrentTimeArea,
  EmpPreviewArea,
  TableHeader,
  TableStatusArea,
  TableTextArea,
} from "../../../common";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/rootReducer";
import { EmpDirectoryType } from "../../EmployeeDirectoryType";

interface EmpTableProps {}

const peopleColumnDefs: ColDef<EmpDirectoryType>[] = [
  {
    headerName: "Employees",
    field: "empDetails",
    headerClass: "my-header-class-trax",
    sortable: true,
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 220,
    hide: false,
    pinned: "left",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerComponent: TableHeader,
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <EmpPreviewArea value={params.value} empId={params.data._id} />;
    },
  },

  {
    headerName: "Title",
    field: "jobTitle",
    sortable: true,
    filter: true,
    headerClass: "my-header-class-trax",
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 170,
    hide: false,
    headerComponent: TableHeader,
    headerComponentParams: {
      headerSvg: EmployeeCountIcon,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <TableTextArea value={params.value} />;
    },
  },
  {
    headerName: "Department",
    field: "department",
    headerClass: "my-header-class-trax",
    sortable: true,
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 170,
    hide: false,
    headerComponent: TableHeader,
    headerComponentParams: {
      headerSvg: FullNameIcon,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <TableTextArea value={params.value} />;
    },
  },

  {
    headerName: "Status",
    field: "status",
    sortable: true,
    filter: true,
    editable: false,
    wrapText: true,
    headerClass: "my-header-class-trax",
    autoHeight: true,
    minWidth: 170,
    hide: false,
    headerComponent: TableHeader,
    headerComponentParams: {
      headerSvg: RelationshipStrengthIcon,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <TableStatusArea value={params.value} />;
    },
  },
  {
    headerName: "Current Time",
    field: "currentTime",
    sortable: true,
    headerClass: "my-header-class-trax",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 170,
    hide: false,
    headerComponent: TableHeader,
    headerComponentParams: {
      headerSvg: LocationIcon,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <CurrentTimeArea value={params.value} />;
    },
  },
  {
    headerName: "Email",
    field: "email",
    sortable: true,
    headerClass: "my-header-class-trax",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 170,
    hide: false,
    headerComponent: TableHeader,
    headerComponentParams: {
      headerSvg: EmailIcon,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <DomainTable TableData={params} />;
    },
  },
];

const EmpTable: React.FC<EmpTableProps> = ({}) => {
  const [columnDefs] = useState(peopleColumnDefs);
  const traxState = useSelector((state: RootState) => state && state.trax);
  const { dirEmployees } = traxState || {};

  const updatePeopleData = async (
    event: CellValueChangedEvent<EmpDirectoryType>
  ) => {
    const updatedData = event.data;
    // Make an API call to update the data
    try {
      console.log("Data updated successfully:", updatedData);
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };
  return (
    <Stack width={"100%"} direction={"row"} alignItems={"flex-start"} gap={0}>
      <DataTable<EmpDirectoryType>
        columnDefs={columnDefs}
        rowData={dirEmployees}
        onCellValueChanged={updatePeopleData}
      />
      <AddColumnTraxTable />
    </Stack>
  );
};

export default EmpTable;
