import {
  IconButton,
  InputBase,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { XCircleIcon } from "../../../../../../images";
import { EmailRichTextMenu } from "../../../../EmailRichTextMenu";
import { ButtonSmall } from "../../../../Buttons";
import { ButtonType } from "../../../../Buttons/AllButtonProps";
import styles from "./CreateQuickNotesView.module.css";

interface CreateQuickNotesViewProps {
  onCloseClick?: () => void;
}

const CreateQuickNotesView: React.FC<CreateQuickNotesViewProps> = ({
  onCloseClick,
}) => {
  const [taskTitle, setTaskTitle] = useState("");
  return (
    <Stack className={styles["create-quick-notes"]}>
      <Stack className={styles["create-quick-notes__header"]}>
        <Typography className={styles["create-quick-notes__title"]}>
          {taskTitle !== "" ? taskTitle : " Untitled Note"}
        </Typography>

        <IconButton
          onClick={onCloseClick}
          className={styles["create-quick-notes__close-button"]}
          disableRipple
        >
          <XCircleIcon w={15} />
        </IconButton>
      </Stack>

      <Stack className={styles["create-quick-notes__body"]}>
        <InputBase
          value={taskTitle}
          onChange={(e) => setTaskTitle(e.target.value)}
          placeholder="Untitled Note"
          className={styles["create-quick-notes__input"]}
        />

        <TextareaAutosize
          placeholder="Start typing, or Upload from a template "
          minRows={3}
          className={styles["create-quick-notes__textarea"]}
        />
      </Stack>

      <Stack className={styles["create-quick-notes__footer"]}>
        <EmailRichTextMenu />
        <ButtonSmall label="Save" types={ButtonType.Button} />
      </Stack>
    </Stack>
  );
};

export default CreateQuickNotesView;
