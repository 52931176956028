import React, {
  useState,
  FC,
  ChangeEvent,
  useEffect,
  KeyboardEvent,
} from "react";
import { InputBase, Stack, SxProps } from "@mui/material";
import g from "./SearchBar.module.css";
import { SearchIcon } from "../../../../../../../images";

// Define the props interface with TypeScript
interface SearchBarProps {
  placeholder?: string;
  font?: string;
  borderLine?: boolean;
  iconSize?: number;
  onSearch?: (value: string) => void;
  setClearInput?: boolean;
  customStyles?: React.CSSProperties;
  onKeyDown?: any;
  containerSx?: SxProps;
}

const SearchBar: FC<SearchBarProps> = ({
  placeholder = "Search…",
  font = "normal normal normal 14px/20px Source Serif Pro",
  borderLine = true,
  iconSize = 20,
  onSearch,
  setClearInput,
  customStyles = {},
  containerSx,
  onKeyDown,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (onSearch) {
      onSearch(value);
    }
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onKeyDown();
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  useEffect(() => {
    if (searchTerm) {
      setSearchTerm("");
    }
  }, [setClearInput]);

  return (
    <Stack onClick={handleClick}
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        gap: "5px",
        ...containerSx,
      }}
      className={borderLine ? g.searchParamBorder : ""}
    >
      <SearchIcon
        style={{ marginLeft: "12px", marginRight: "8px" }}
        w={iconSize}
      />
      <InputBase
        type="search"
        placeholder={placeholder}
        sx={{ font, ...customStyles }}
        value={searchTerm}
        onKeyDown={handleKeyPress}
        onChange={handleSearchChange}
        className={g.searchParams}
      />
    </Stack>
  );
};

export default SearchBar;
