import { Stack } from "@mui/material";
import React, { useState } from "react";
import ViewHeaderButton from "../ViewHeaderButton/ViewHeaderButton";
import DefaultQuickEmailView from "./screen/DefaultQuickEmailView";
import { EmailSmallerPopout } from "../../../EmailSmallerPopout";

interface NewEmailViewProps {
  onBackButtonClick: () => void;
}

const NewEmailView: React.FC<NewEmailViewProps> = ({ onBackButtonClick }) => {
  const [emailScreen, setEmailScreen] = useState<"default" | "create">(
    "default"
  );
  return (
    <Stack sx={{ flex: 1, height: "100%" }}>
      {emailScreen === "default" ? (
        <>
          <ViewHeaderButton
            headerText="All emails"
            createNewButtonText="Create new email"
            onBackButtonClick={onBackButtonClick}
            onCreateButtonClick={() => setEmailScreen("create")}
          />
          <DefaultQuickEmailView />
        </>
      ) : (
        <EmailSmallerPopout emailData={""} onCloseClick={() => setEmailScreen("default")} />
      )}
    </Stack>
  );
};

export default NewEmailView;
