import { Button, Stack, Typography, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { adjustHexColor } from "../../../../../Utils";
import { ChevronIcon } from "../../../../../images";
import { NextShiftListType } from "./NextShiftType";
import styles from "./NextShift.module.css";

interface NextShiftProps {
  options: NextShiftListType[];
  initialValueId?: number | string;
  onSelect?: (value: NextShiftListType) => void;
}

const NextShift: React.FC<NextShiftProps> = ({
  options,
  initialValueId = 1,
  onSelect,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedvalue, setSelectedvalue] = useState<NextShiftListType>(
    options.find((item) => item.id === initialValueId)!
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Stack>
      <Button
        variant="text"
        className={styles["nextShift__button"]}
        onClick={handleClick}
        sx={{
          bgcolor: adjustHexColor("#007BB2", 65),
          ":hover": {
            bgcolor: adjustHexColor("#007BB2", 70),
          },
        }}
      >
        <Stack className={styles["nextShift__content"]}>
          <Typography className={styles["nextShift__text"]}>
            {selectedvalue.dayDate} {selectedvalue.timeRange}
          </Typography>
          <ChevronIcon w={18} color="white" />
        </Stack>
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{ paper: styles["nextShift__menu"] }}
      >
        {options.map((item, index) => (
          <MenuItem
            onClick={() => {
              setSelectedvalue(item);
              handleClose();
              if (onSelect) {
                onSelect(item);
              }
            }}
            key={index}
            className={styles["nextShift__menuItem"]}
          >
            <Typography sx={{ font: "inherit", width: "200px" }}>
              {item.dayDate}
            </Typography>
            <Typography sx={{ font: "inherit" }}>{item.timeRange}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
};

export default NextShift;
