import { ClosureIcon, DateIcon, InboxIcon, InvoiceBillIcon, MarketingIcon, ReplyAllIcon, ReplyIcon } from "../../../../../images";

export enum EmailFilterChipsVariantEnum {
  MainInbox = "Main Inbox",
  Bills = "Bills",
  Newsletters = "Newsletters",
  Events = "Events",
  SocialMedia = "Social Media",
  NoReplies = "No replies",
  SentItems = "Sent Items",
  DeletedItems = "Deleted Items",
  Inbox = "Inbox",
}

export interface EmailFilterChipsListInterface {
  name: EmailFilterChipsVariantEnum;
  icon: React.ElementType;
  bgColor: string;
}

export const EmailFilterChipsList: EmailFilterChipsListInterface[] = [
  {
    name: EmailFilterChipsVariantEnum.MainInbox,
    icon: InboxIcon,
    bgColor: "#2A30B5",
  },
  {
    name: EmailFilterChipsVariantEnum.Inbox,
    icon: InboxIcon,
    bgColor: "#2A30B5",
  },
  {
    name: EmailFilterChipsVariantEnum.Bills,
    icon: InvoiceBillIcon,
    bgColor: "#008D4C",
  },
  {
    name: EmailFilterChipsVariantEnum.Newsletters,
    icon: ClosureIcon,
    bgColor: "#CFA0FF",
  },
  {
    name: EmailFilterChipsVariantEnum.Events,
    icon: DateIcon,
    bgColor: "#B5005B",
  },
  {
    name: EmailFilterChipsVariantEnum.SocialMedia,
    icon: MarketingIcon,
    bgColor: "#007BFF",
  },
  {
    name: EmailFilterChipsVariantEnum.NoReplies,
    icon: ReplyAllIcon,
    bgColor: "#DC3545",
  },
  {
    name: EmailFilterChipsVariantEnum.SentItems,
    icon: ReplyAllIcon,
    bgColor: "#00008F",
  },
  {
    name: EmailFilterChipsVariantEnum.DeletedItems,
    icon: ReplyAllIcon,
    bgColor: "#DC3545",
  },
];
