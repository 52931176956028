import { Stack, Typography } from "@mui/material";
import React from "react";
import { AvatarNormal } from "../../../AvatarNormal";
import styles from "./MiniDetailsCard.module.css";

interface MiniDetailsCardProps {
  userName: string;
  userIconSrc?: string;
  cardSubTitle: string;
  timeStamp: string;
  cardTitle?: string;
}

const MiniDetailsCard: React.FC<MiniDetailsCardProps> = ({
  cardSubTitle,
  timeStamp,
  userName,
  cardTitle = userName,
  userIconSrc,
}) => {
  return (
    <Stack className={styles["mini-details-card"]}>
      <AvatarNormal size={35} username={userName} imgSrc={userIconSrc} />
      <Stack className={styles["mini-details-card__content"]}>
        <Stack className={styles["mini-details-card__header"]}>
          <Typography className={styles["mini-details-card__title"]}>
            {cardTitle}
          </Typography>
          <Typography className={styles["mini-details-card__timestamp"]}>
            {timeStamp}
          </Typography>
        </Stack>
        <Typography className={styles["mini-details-card__subtitle"]}>
          {cardSubTitle}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default MiniDetailsCard;
