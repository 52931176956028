import React from "react";
import {
  Avatar,
  AvatarGroup,
  AvatarGroupProps,
  Tooltip,
  Typography,
} from "@mui/material";
import styles from "./SmallGroupedAvatars.module.css";
import { SmallGroupedAvatarDataTpyes } from "./SmallGroupedAvaterDataTypes";

interface SmallGroupedAvatarsProps extends AvatarGroupProps {
  maxAvatars: number;
  usersData: SmallGroupedAvatarDataTpyes[];
}

const SmallGroupedAvatars: React.FC<SmallGroupedAvatarsProps> = ({
  maxAvatars = 4,
  usersData,
}) => {
  const generateRandomHexColor = (): string => {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  };

  const TOTAL_USERS: number = usersData.length;

  return (
    <AvatarGroup
      max={maxAvatars}
      total={TOTAL_USERS}
      renderSurplus={(surplus) => (
        <span className={styles["smallgroupedavatars__surplus"]}>
          +{surplus?.toString()}
        </span>
      )}
      slotProps={{
        additionalAvatar: {
          className: styles["smallgroupedavatars__additional-avatar"],
        },
      }}
    >
      {Array.from({ length: Math.min(maxAvatars, TOTAL_USERS) }).map(
        (_, index) => {
          const user = usersData[index];
          // Safeguard against undefined user?.name
          const nameLetters =
            user?.firstName && user?.lastName
              ? `${user?.firstName[0].toUpperCase()}${user?.lastName[0].toUpperCase()}`
              : "?"; // Default value if name is not defined

          return (
            <Tooltip
              key={user?._id}
              placement="top"
              title={`${user?.firstName} ${user?.lastName}`}
            >
              <Avatar
                className={styles["smallgroupedavatars__avatar"]}
                alt={nameLetters}
                src={user?.avatar?.startsWith("#") ? undefined : user?.avatar}
                sx={{
                  backgroundColor: user?.avatar?.startsWith("#")
                    ? user?.avatar?.split("|")[0] // Extract color if it's in hex format
                    : generateRandomHexColor(),
                }}
              >
                <Typography className={styles["smallgroupedavatars__text"]}>
                  {nameLetters}
                </Typography>
              </Avatar>
            </Tooltip>
          );
        }
      )}
    </AvatarGroup>
  );
};

export default SmallGroupedAvatars;
