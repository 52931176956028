import { Stack } from "@mui/material";
import React, { useState } from "react";
import ViewHeaderButton from "../ViewHeaderButton/ViewHeaderButton";
import DefaultQuickCallView from "./screen/DefaultQuickCallView";
import CreateQuickCallView from "./screen/CreateQuickCallView";

interface StartCallViewProps {
  onBackButtonClick: () => void;
}

const StartCallView: React.FC<StartCallViewProps> = ({ onBackButtonClick }) => {
  const [callScreen, setCallScreen] = useState<"default" | "create">("default");
  return (
    <Stack sx={{ flex: 1, height: "100%" }}>
      {callScreen === "default" ? (
        <Stack>
          <ViewHeaderButton
            headerText="All events"
            createNewButtonText="Start a call"
            onBackButtonClick={onBackButtonClick}
            onCreateButtonClick={() => setCallScreen("create")}
          />
          <DefaultQuickCallView />
        </Stack>
      ) : (
        <CreateQuickCallView />
      )}
    </Stack>
  );
};

export default StartCallView;
