// TraxCenterSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TraxCenterState } from "./TraxCenterType"; // Importing theTraxCenter type
import { DummyImage } from "../../../images";
// Initial state for the TraxCenter slice
const initialState: TraxCenterState = {
  error: null,
  data: null,
  popupEmployeeDataId: null,
  showEmpPreviewDrawer: false,
  dirEmployees: [
    {
      _id: "1",
      currentTime: {
        time: "01:07 AM",
        countryName: "India",
        flagSrc: DummyImage,
      },
      department: "Engineering",
      email: "sampleEmail.com",
      status: { jobType: "Contract", isActive: false },
      jobTitle: "Full stack developer",
      empDetails: { imgSrc: DummyImage, fullName: "user name1" },
      workSchedule: [
        { day: "Sun", dayOff: true },
        { day: "Mon", startTime: "09:00 am", endTime: "05:00 pm" },
        { day: "Tue", startTime: "09:00 am", endTime: "05:00 pm" },
        { day: "Wed", startTime: "09:00 am", endTime: "05:00 pm" },
        { day: "Tue", startTime: "09:00 am", endTime: "05:00 pm" },
        { day: "Fri", startTime: "09:00 am", endTime: "05:00 pm" },
        { day: "Sat", dayOff: true },
      ],
    },
    {
      _id: "2",
      currentTime: {
        time: "01:07 AM",
        countryName: "USA",
        flagSrc: DummyImage,
      },
      department: "Marketing",
      email: "sample123Email.com",
      status: { jobType: "Part Time", isActive: true },
      jobTitle: "H.R",
      empDetails: { imgSrc: DummyImage, fullName: "user name 2" },
      workSchedule: [
        { day: "Sun", dayOff: true },
        { day: "Mon", startTime: "11:00 am", endTime: "07:00 pm" },
        { day: "Tue", startTime: "11:00 am", endTime: "07:00 pm" },
        { day: "Wed", startTime: "11:00 am", endTime: "07:00 pm" },
        { day: "Tue", startTime: "11:00 am", endTime: "07:00 pm" },
        { day: "Fri", startTime: "11:00 am", endTime: "07:00 pm" },
        { day: "Sat", dayOff: true },
      ],
    },
    {
      _id: "3",
      currentTime: {
        time: "01:07 AM",
        countryName: "Japan",
        flagSrc: DummyImage,
      },
      department: "Sells",
      email: "1122sampleEmail.com",
      status: { jobType: "Full Time", isActive: true },
      jobTitle: "Project Manager",
      empDetails: { imgSrc: DummyImage, fullName: "username 3" },
      workSchedule: [
        { day: "Sun", dayOff: true },
        { day: "Mon", startTime: "09:00 am", endTime: "05:00 pm" },
        { day: "Tue", startTime: "09:00 am", endTime: "05:00 pm" },
        { day: "Wed", startTime: "09:00 am", endTime: "05:00 pm" },
        { day: "Tue", startTime: "09:00 am", endTime: "05:00 pm" },
        { day: "Fri", startTime: "09:00 am", endTime: "05:00 pm" },
        { day: "Sat", dayOff: true },
      ],
    },
  ],
};
// Define the slice
const traxCenterSlice = createSlice({
  name: "traxCenter", // Slice name
  initialState,
  reducers: {
    employeePreviewIdHandler: (
      state,
      { payload }: PayloadAction<string | null>
    ) => {
      if (payload) {
        state.popupEmployeeDataId = payload; // Update the state with the payload value
      } else {
        state.popupEmployeeDataId = null;
      }
    },
    employeeDrawerHandler: (state, { payload }: PayloadAction<boolean>) => {
      if (payload) {
        state.showEmpPreviewDrawer = payload; // Update the state with the payload value
      } else {
        state.showEmpPreviewDrawer = false;
      }
    },
  },
});

export const { employeePreviewIdHandler, employeeDrawerHandler } =
  traxCenterSlice.actions;

export default traxCenterSlice.reducer;
