import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { SxProps } from "@mui/material";

type TableDataType = {
  value: string;
};

type DomainTableProps = {
  TableData: TableDataType;
  useWithoutBorder?: boolean;
  boxSx?: SxProps;
  linkSx?: SxProps;
};

const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

// Function to extract and clean the main domain name from a URL
const extractMainDomain = (url: string): string => {
  try {
    const hostname = new URL(url).hostname;
    // Split hostname into parts by '.'
    const parts = hostname.split(".");

    // To handle common cases:
    // - Remove subdomains like 'www', 'm', 'mobile', etc.
    // - Use the last two parts of the hostname (domain + TLD)
    // This approach will cover common cases but may need refinement for special cases

    // If there are more than two parts, remove the subdomain
    if (parts.length > 2) {
      // We can assume the main domain is usually the last two parts
      return parts.slice(-2).join(".");
    }

    return hostname;
  } catch (error) {
    return "";
  }
};

const DomainTable: React.FC<DomainTableProps> = ({
  TableData,
  boxSx,
  linkSx,
  useWithoutBorder = false,
}) => {
  const domainName = extractMainDomain(TableData.value);
  return (
    <Box
      sx={{
        height: "20px",
        width: "fit-content",
        padding: "0px 10px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        border: useWithoutBorder ? "none" : "1px solid #E9EDF2",
        borderRadius: "5px",
        color: "#0027FF",
        font: "normal normal normal 12px/16px Source Serif Pro",
        ...boxSx,
      }}
      onClick={preventDefault}
    >
      <Link
        href={TableData.value}
        underline={useWithoutBorder ? "always" : "hover"}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          font: "inherit",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          color: useWithoutBorder ? "black" : "inherit",
          ...linkSx,
        }}
      >
        {TableData.value}
      </Link>
    </Box>
  );
};

export default DomainTable;
