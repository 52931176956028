import { IconButton, Popover, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

type Mode = "viewMode" | "editMode";

type Props = {
  mode?: Mode; // Optional prop to choose between viewMode or editMode
  onButtonClick?: (buttonLabel: string, emoji?: any) => void; // Optional prop to handle button clicks
};

const ManageCommentButtons: React.FC<Props> = ({
  mode = "viewMode",
  onButtonClick,
}) => {
  // State for controlling Popover and Emoji Picker
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const buttons =
    mode === "viewMode"
      ? [
          { label: "React", color: "#B5005B" },
          { label: "Edit", color: "#E2875E" },
          { label: "Reply", color: "#00C2CB" },
        ]
      : [
          { label: "Assign as task", color: "#00C2CB" },
          { label: "Cancel", color: "#FF0000" },
          { label: "Save", color: "#E2875E" },
        ];

  // Handle button clicks
  const handleButtonClick = (
    label: string,
    event?: React.MouseEvent<HTMLElement>
  ) => {
    if (label === "React") {
      // Open Emoji Picker when "React" is clicked
      setAnchorEl(event?.currentTarget || null);
    } else if (onButtonClick) {
      onButtonClick(label); // Call the callback function if provided
    }
  };

  // Handle closing of the Popover (emoji picker)
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Handle emoji selection
  const handleEmojiSelect = (emoji: any) => {
    console.log("Selected emoji:", emoji.native); // Log the selected emoji
    if (onButtonClick) {
      onButtonClick("React", emoji.native);
    }
    handleClose(); // Close the picker after selecting an emoji
  };

  const open = Boolean(anchorEl);
  const id = open ? "emoji-popover" : undefined;

  return (
    <Stack
      sx={{
        flexDirection: "row",
        gap: "10px",
        justifyContent: "end",
      }}
    >
      {buttons.map((button) => (
        <IconButton
          key={button.label}
          sx={{ p: 0, borderRadius: "0px" }}
          disableRipple
          onClick={(event) => handleButtonClick(button.label, event)} // Pass the label and event on click
        >
          <Typography
            sx={{
              font: "normal normal 400 11px/14px Source Serif Pro",
              color: button.color,
              transition: "font-weight 0.3s",
              "&:hover": {
                fontWeight: "bold",
              },
            }}
          >
            {button.label}
          </Typography>
        </IconButton>
      ))}

      {/* Emoji Picker Popover */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Picker
          previewPosition={"none"}
          theme={"dark"}
          data={data} // Provide the emoji data
          onEmojiSelect={handleEmojiSelect} // Handle emoji selection
        />
      </Popover>
    </Stack>
  );
};

export default ManageCommentButtons;
