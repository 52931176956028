import { ApiActionConfig, ApiPayload } from "./constantType";

/**
 * Creates a factory function for generating API action creators with shared and specific configurations.
 *
 * This factory function enables the creation of actions that follow a consistent pattern,
 * while allowing for specific configuration details to be provided for individual actions.
 * It merges common configuration settings with specific settings, handling the `axiosRequired`
 * flag separately to avoid conflicts and ensure correct default values.
 *
 * @param commonConfig - A partial configuration object containing settings that are shared
 *                       across multiple API actions. This does not include the `axiosRequired`
 *                       property as it is handled separately for each specific action.
 *
 * @returns A function that accepts specific API action configuration, combining it with the
 *          common configuration. The returned object includes the action type and payload,
 *          with `axiosRequired` defaulting to `false` unless explicitly set.
 */
const createApiActionFactory = (
    commonConfig: Omit<Partial<ApiActionConfig>, "axiosRequired">
) => {
    return (
        /**
         * Combines the common configuration with specific API action configurations.
         * This function allows for the customization of API actions by overriding or extending
         * the common configurations with specific settings provided for each action. It ensures
         * that `axiosRequired` is handled appropriately and merges headers from both common
         * and specific configurations.
         *
         * @param specificConfig - Configuration for the specific API action, including
         *                         properties `onStart`, `onSuccess`, and `onError` which
         *                         are mandatory for action handling, as well as other API
         *                         call settings like `method`, `url`, and optional `axiosRequired`.
         *
         * @returns An object representing the API action with the type and payload. The
         *          payload merges common and specific configurations and includes `axiosRequired`
         *          with a default value of `false` if not specified.
         */
        specificConfig: Omit<
            ApiActionConfig,
            "onStart" | "onSuccess" | "onError" | "axiosRequired"
        > &
            Pick<ApiActionConfig, "onStart" | "onSuccess" | "onError"> &
            Partial<Pick<ApiActionConfig, "axiosRequired">>
    ) => {
        const axiosRequired =
            specificConfig.axiosRequired !== undefined
                ? specificConfig.axiosRequired
                : false;
        const unwrap =
            specificConfig.unwrap !== undefined ? specificConfig.unwrap : false;

        return {
            type: specificConfig.onStart,
            payload: {
                axiosRequired,
                unwrap,
                ...commonConfig,
                ...specificConfig,
                headers: {
                    ...commonConfig.headers,
                    ...specificConfig.headers,
                },
            } as ApiPayload,
        };
    };
};

/**
 * Common API configuration object that includes settings shared across multiple API actions.
 * This example configuration sets a default language header for all API calls.
 */
const commonApiConfig = {
    headers: { language: "en" },
};

/**
 * Exported API action creator factory, pre-configured with common API settings.
 * This factory function can be used to create specific API actions with the shared
 * configuration applied, simplifying the creation of action objects with consistent patterns.
 */
export const createApiAction = createApiActionFactory(commonApiConfig);
