import React from "react";
import { useLocation } from "react-router-dom";

import { WorkspaceDrawer, CloudStorageDrawer } from "../../../common";
import { LayoutState } from "../../../../redux";
import { pathToPublicDrawerStateKey } from "../CommanDrawerType";

/** Props interface for DrawerManager component */
interface DrawerPublicManagerProps {
  drawerOpen: LayoutState;
  handleChangeDrawer: () => void;
}

/** Object mapping route paths to corresponding drawer components */
const DrawerComponents: {
  [key: string]: React.ComponentType<{
    drawerOpen: boolean;
    drawerCloser: () => void;
    shared?: boolean;
  }>;
} = {
  "/share/workspace": WorkspaceDrawer,
  "/share/cloud-storage/": CloudStorageDrawer,
};

/**
 * DrawerPublicManager DrawerPublicManagercomponent dynamically renders drawer component based on current route
 * @param drawerOpen - Object containing drawer open states
 * @param handleChangeDrawer - Function to handle drawer open state change
 * @returns JSX.Element - Drawer component
 */
const DrawerPublicManager: React.FC<DrawerPublicManagerProps> = ({
  drawerOpen,
  handleChangeDrawer,
}) => {
  const location = useLocation();

  // Get the appropriate drawer component based on current route
  const drawerKey = Object.keys(DrawerComponents).find((key) =>
    location.pathname.startsWith(key)
  );

  const DrawerComponent = drawerKey ? DrawerComponents[drawerKey] : null;

  // Get drawer state key from pathToPublicDrawerStateKey mapping
  const drawerStateKey = drawerKey
    ? pathToPublicDrawerStateKey[drawerKey]
    : undefined;

  // Determine if drawer should be open based on drawer state key
  const isDrawerOpen = drawerStateKey ? drawerOpen[drawerStateKey] : false;

  return (
    <>
      {DrawerComponent && (
        <DrawerComponent
          drawerOpen={isDrawerOpen}
          drawerCloser={handleChangeDrawer}
          shared={true}
        />
      )}
    </>
  );
};

export default DrawerPublicManager;
