import { Link, Stack, Typography } from "@mui/material";
import React from "react";
import { TimePickerCloudStorage } from "../../../TimePickerCloudStorage";
import { adjustHexColor } from "../../../../../Utils";
import { NamePlateCloudStorage } from "../../../NamePlateCloudStorage";

interface GraphViewProps {
  fileData: any;
}

const GraphView: React.FC<GraphViewProps> = ({ fileData }) => {
  return (
    <Stack gap={"20px"}>
      {/* ----------------------- */}
      <Typography sx={{ font: "normal normal 400 14px/18px Source Serif Pro" }}>
        All View
      </Typography>

      <Stack sx={{ flexDirection: "row", justifyContent: "center" }}>
        <TimePickerCloudStorage />
      </Stack>

      {/* ----------------------- */}
      {/* ----------------------- */}
      <Stack
        sx={{
          height: "150px",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: adjustHexColor("#E9EDF2", 25),
        }}
      >
        graph here
      </Stack>

      {/* ----------------------- */}
      {/* ----------------------- */}
      <Stack gap={"20px"}>
        <NamePlateCloudStorage
          agent="iPad, iPadOS, macbook, linux, android"
          dateStamp="December 24, 2023"
          timeStamp="10:25 pm"
          downloaded={true}
          location="San Francisco CA USA San Francisco Bay Area, Northern California, United States of America"
          nameOrEmail="Richard Rightwaterson"
          visitLink="link one"
          visitTime="03 hrs 20 mins"
        />
        <NamePlateCloudStorage
          agent="iPad, iPadOS, macbook, linux, android"
          dateStamp="December 24, 2023"
          timeStamp="10:25 pm"
          downloaded={true}
          location="San Francisco CA USA San Francisco Bay Area, Northern California, United States of America"
          nameOrEmail="Richard Rightwaterson"
          visitLink="link one"
          visitTime="03 hrs 20 mins"
        />
        <NamePlateCloudStorage
          agent="iPad, iPadOS, macbook, linux, android"
          dateStamp="December 24, 2023"
          timeStamp="10:25 pm"
          downloaded={true}
          location="San Francisco CA USA San Francisco Bay Area, Northern California, United States of America"
          nameOrEmail="Richard Rightwaterson"
          visitLink="link one"
          visitTime="03 hrs 20 mins"
        />
        NOT SETUP YET
      </Stack>
      {/* ----------------------- */}
      {/* ----------------------- */}

      {/* <Link
        underline="hover"
        sx={{
          font: "normal normal 400 14px/18px Source Sarif Pro",
          color: "#000AFF",
          cursor: "pointer",
        }}
      >
        View more
      </Link> */}
      {/* ----------------------- */}
    </Stack>
  );
};

export default GraphView;
