import { Stack, Typography } from "@mui/material";
import React from "react";

interface DisplayAreaViewProps {
  dayName: string;
  dayOff?: boolean;
  startTime?: string;
  endTime?: string;
}

const DisplayAreaView: React.FC<DisplayAreaViewProps> = ({
  dayName,
  dayOff,
  endTime,
  startTime,
}) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        font: "normal normal 300 11px/14px Source Serif Pro",
      }}
    >
      <Typography sx={{ font: "inherit", width: "35px" }}>
        {dayName}:
      </Typography>
      {dayOff ? (
        <Typography sx={{ font: "inherit" }}>Day Off</Typography>
      ) : (
        <Typography sx={{ font: "inherit" }}>
          {startTime} to {endTime}
        </Typography>
      )}
    </Stack>
  );
};

export default DisplayAreaView;
