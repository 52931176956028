// AnonymousComponent.tsx
import React from "react";
import { useParams } from "react-router-dom";
import { CloudStorageRoute, TraxRoute, WorkspaceRoute } from "./components";
import { RoutesParamType } from "../TopNavBarType";

const AnonymousComponent: React.FC = () => {
  const params = useParams<RoutesParamType>();

  if (params?.workspaceId) {
    return (
      <WorkspaceRoute
        workspaceId={params?.workspaceId}
        itemId={params?.componentId}
        sharedType={params?.sharedType}
      />
    );
  } else if (params?.fileType) {
    return (
      <CloudStorageRoute folderId={params?.fileType} fileId={params?.fileId} />
    );
  } else if (params?.empId) {
    return <TraxRoute employeeId={params.empId} />;
  }

  return null;
};

export default AnonymousComponent;
