import React from "react";
import { Box, Divider, Stack } from "@mui/material";
import { TotalCRM } from "../components";
import { PeopleTable } from "./components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  DropdownButtonAllCompanies,
  DropdownButtonCSV,
  DropdownButtonFilterMenu,
  DropdownButtonSortMenu,
  DropdownButtonVisibleMenu,
} from "../../../common";
import { CRM_sortOrFilterMenuList, DUMMY_CRM_COMPANIES_LIST } from "../common";
import {
  FilterItem,
  recallAPI__CRM,
  updateFilterKey,
  updateSortingKey,
} from "../../../../redux";
import { DataIDnumberIcon, PeopleIcon } from "../../../../images";

// Define the props for the People component
interface PeopleProps {}

// Define the data structure for the component
// const dropDownButtonData: DropdownButtonInterface[] = [
//   {
//     componentIcon: <AllpeopleIcon />,
//     label: "All People",
//     componentType: "allPeople",
//   },
//   { componentIcon: <VisibleIcon />, label: "Visible", componentType: "Sort" },
//   { componentIcon: <ImportIcon />, label: "Import", componentType: "CSV" },
//   { componentIcon: <SortIcon />, label: "Sort", componentType: "Sort" },
//   { componentIcon: <FiltersIcon />, label: "Filter", componentType: "Filter" },
// ];

// The People component
const People: React.FC<PeopleProps> = () => {
  const crmState = useSelector((state: RootState) => state && state?.crm);
  const { contactList, outlookContactList } = crmState || {};
  const dispatch = useDispatch();

  const handleSortSelection = (sortSelection: any) => {
    // Assume sortSelection is an array of objects similar to the provided JSON data
    sortSelection?.forEach((item: any) => {
      const field = CRM_sortOrFilterMenuList?.find(
        (option: any) => option?.label === item?.selectedItem?.label
      )?.value;
      const order = item?.order?.toLowerCase() === "ascending" ? "asc" : "desc";

      if (field) {
        dispatch(
          updateSortingKey({
            section: "contacts", // This needs to be dynamic based on your actual CRM sections
            sortConfig: { field, order },
          })
        );
      }
    });

    // Delay the API call by 1 second after sorting keys have been updated
    setTimeout(() => {
      dispatch(recallAPI__CRM("getAllContacts"));
    }, 1000); // 1000 milliseconds delay
  };
  const handleFilterUpdate = (filters: FilterItem[]) => {
    filters?.forEach((filter: FilterItem) => {
      const { selectedItem, operator, filterInput, condition } = filter;
      const field = selectedItem.value; // Use the value as the field identifier
      const filterCondition = {
        type: operator, // Corrected from 'operator' to 'type' to match FilterCondition
        value: filterInput || "", // Ensure undefined input is handled as empty string
        conjunction: condition, // Correctly pass the logical operator
      };

      dispatch(
        updateFilterKey({
          section: "contacts", // This should be dynamic or correctly set based on your app structure
          filterConfig: {
            field: field,
            conditions: [filterCondition], // Supports adding or updating multiple conditions per field
          },
        })
      );
    }); // Delay the API call by 1 second after sorting keys have been updated
    setTimeout(() => {
      dispatch(recallAPI__CRM("getAllContacts"));
    }, 1000); // 1000 milliseconds delay
  };
  return (
    <Box sx={{ padding: "10px 0 25px 0" }}>
      <Stack mb={1} p={1} direction={"column"} gap={1.2}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={0.5}
        >
          <Stack direction={"row"} alignItems={"center"} gap={1.5}>
            <DropdownButtonAllCompanies
              buttonLabel="All people"
              labelIcon={DataIDnumberIcon}
              options={DUMMY_CRM_COMPANIES_LIST}
            />
            <TotalCRM
              label="Total People"
              totalCount={
                (contactList?.length || 0) + (outlookContactList?.length || 0)
              }
            />
          </Stack>
          <Stack direction={"row"} alignItems={"center"} gap={1.5}>
            <DropdownButtonSortMenu
              buttonColor="CRMOrange"
              sortOption={CRM_sortOrFilterMenuList}
              sortedList={(value: any) => {
                handleSortSelection(value);
              }}
            />
            <DropdownButtonCSV onSelect={(value) => null} />
          </Stack>
        </Stack>
        <Divider />
        <Stack direction={"row"} alignItems={"center"} gap={1.5}>
          <DropdownButtonSortMenu
            buttonColor="CRMOrange"
            sortOption={CRM_sortOrFilterMenuList}
            sortedList={(value: any) => {
              handleSortSelection(value);
            }}
          />
          <DropdownButtonFilterMenu
            buttonColor="CRMOrange"
            filterOption={CRM_sortOrFilterMenuList}
            filteredList={(list: any) => {
              handleFilterUpdate(list);
            }}
          />
        </Stack>
      </Stack>
      <Stack width={"100%"} direction={"row"} alignItems={"flex-start"} gap={0}>
        <PeopleTable />
      </Stack>
    </Box>
  );
};

export default People;
