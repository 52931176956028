import { Button, ButtonProps, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { CopyNewIcon } from "../../../../../../../images";
import { adjustHexColor } from "../../../../../../../Utils";
import { useTranslation } from "react-i18next";

interface CopyButtonViewProps extends ButtonProps {
  linkValue: string;
}

const CopyButtonView: React.FC<CopyButtonViewProps> = ({
  linkValue,
  ...props
}) => {
  const [copied, setCopied] = useState(false);
  const {t}=useTranslation()

  const handleCopy = () => {
    navigator.clipboard
      .writeText(linkValue)
      .then(() => {
        setCopied(true);
      })
      .catch((err) => {
        console.error("Failed to copy the text: ", err);
      });
  };

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied(false);
      }, 3000); // Reset after 3 seconds
      return () => clearTimeout(timer);
    }
  }, [copied]);

  return (
    <Button
      variant="text"
      sx={{
        height: "35px",
        width: "160px",
        border: "2px solid #E9EDF2",
        borderRadius: "5px",
        boxSizing: "border-box",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        padding: "0px 0px 0px 10px",
        textTransform: "none",
        gap: "5px",
        color: adjustHexColor("#000000", 75),
      }}
      onClick={handleCopy}
      {...props}
    >
      <CopyNewIcon w={20} />
      <Typography
        sx={{
          font: "normal normal 400 13px/16px Source Serif Pro",
          color: "black",
        }}
      >
        {copied ? t("VIDEO_CENTER.MEETING.LINK_COPIED") : t("VIDEO_CENTER.MEETING.COPY_MEETING_LINK")}
      </Typography>
    </Button>
  );
};

export default CopyButtonView;
