import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import g from "./invitations.module.css";
import { formatDate, getUser } from "../../../../Utils";

type Props = { eventData?: any };

const Invitationheader = ({ eventData }: Props) => {
  const user = getUser(); // user.data?._id

  // Function to determine the response status
  const getResponseStatus = () => {
    // Check for guests with a userId first
    const guestWithUserId = eventData?.guests?.find(
      (guest: any) => guest?.userId === user?.data?._id // Checking against user.data._id
    );

    // If found, return their response status
    if (guestWithUserId) {
      return guestWithUserId?.responseStatus; // Return the guest's response status
    }

    // If no userId found and bookingLinkId is not null, return the first guest's response status
    if (eventData?.bookingLinkId && eventData?.guests?.length > 0) {
      return eventData.guests[0]?.responseStatus; // Return the first guest's status
    }

    // If neither condition met, return a default status
    return "No status available";
  };

  return (
    <Box>
      <Stack direction={"column"} gap={2}>
        <Typography className={g.Invitation___Today__date}>
          {`On`} {formatDate(eventData?.startTime)}
        </Typography>

        <Typography className={g.Invitation___Status__s}>
          {`Status: ${getResponseStatus()} Invitations`} {/* Displaying the status */}
        </Typography>
      </Stack>
    </Box>
  );
};

export default Invitationheader;
