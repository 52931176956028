import { Button, ButtonProps } from "@mui/material";
import React from "react";

interface MediumSquareButtonProps extends ButtonProps {
  buttonIcon: React.ElementType;
  iconSize?: number;
}

const MediumSquareButton: React.FC<MediumSquareButtonProps> = ({
  buttonIcon,
  iconSize = 20,
  sx,
  ...props
}) => {
  return (
    <Button
      variant="text"
      sx={{
        height: "30px",
        minWidth: 0,
        width: "30px",
        borderRadius: "5px",
        border: "1px solid #E9EDF2",
        color: "black",
        alignItems: "center",
        justifyContent: "center",
        padding: 0,
        ...sx,
      }}
      {...props}
    >
      {React.createElement(buttonIcon, { w: iconSize, color: "black" })}
    </Button>
  );
};

export default MediumSquareButton;
