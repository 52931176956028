import React, { useEffect, useState } from "react";
import g from "./CrmInfoPopup.module.css";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { BrandFetchCInfo, ColoredBadge } from "../../../../common";
import {
  DescriptionIcon,
  DomainIcon,
  IconProps,
  LocationIcon,
  TrendUpIcon,
} from "../../../../../images";
import { MultiIconButton } from "../../common/MultiIconButton";
import { adjustHexColor } from "../../../../../Utils";

type CrmInfoPopupProps = {
  companyData?: {
    description?: string; // Make description optional
    website?: string; // Make website optional
    location?: string; // Make location optional
    tags?: any; // Make tags optional
    name?: string; // Make name optional
    icon?: string; // Make icon optional
    domain?: string; // Make company
  };
};

interface InfoItem {
  icon: React.FC<IconProps>;
  text?: string;
  component?: React.ReactNode;
}

const iconSize = 15;

const CrmInfoPopup: React.FC<CrmInfoPopupProps> = ({ companyData }) => {
  const [infoItems, setInfoItems] = useState<InfoItem[]>([]);

  useEffect(() => {
    // Default values for company data if not provided
    const defaultDescription = "No description available.";
    const defaultWebsite = "No website provided.";
    const defaultLocation = "Location not specified.";
    const defaultTags = ["No tags available."];
    const defaultName = "No name available.";

    // Map companyData to infoItems array with fallback values
    const mappedInfoItems: InfoItem[] = [
      {
        icon: DescriptionIcon,
        text: companyData?.description || defaultDescription,
      },
      {
        icon: DomainIcon,
        component: (
          <Button
            variant="text"
            sx={{
              height: "20px",
              borderRadius: "5px",
              border: "1px solid #E9EDF2",
              padding: "0px 10px",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer",
              textTransform: "none",
            }}
          >
            <Typography
              sx={{
                font: "normal normal normal 12px/12px Source Serif Pro",
                color: "#0027FF",
              }}
            >
              {companyData?.website || defaultWebsite}
            </Typography>
          </Button>
        ),
      },
      {
        icon: LocationIcon,
        text: companyData?.location || defaultLocation,
      },
      {
        icon: TrendUpIcon,
        component: (
          <Stack sx={{ flexDirection: "row", gap: "10px", flexWrap: "wrap" }}>
            {(companyData?.tags || defaultTags).length > 0 ? (
              (companyData?.tags || defaultTags)?.map(
                (tag: any, index: any) => (
                  <ColoredBadge
                    key={index}
                    title={tag?.name}
                    bgColor={tag?.color}
                    ColoredBadgeFont="normal normal normal 11px/15px Source Serif Pro"
                    sx={{
                      borderRadius: "50px",
                      padding: "0px 8px",
                      height: "20px",
                    }}
                  />
                )
              )
            ) : (
              <Typography
                color="text.secondary"
                fontSize="11px"
                fontFamily="Source Serif Pro"
              >
                No tags provided
              </Typography>
            )}
          </Stack>
        ),
      },
    ];

    setInfoItems(mappedInfoItems);
  }, [companyData]);

  return (
    <Box className={g.crminfoPopupBox}>
      <Stack
        direction={"row"}
        alignItems={"flex-start"}
        padding={2}
        justifyContent={"flex-start"}
      >
        <BrandFetchCInfo
          imgSizeH={50}
          imgSizeW={50}
          roundedOrSquare="cr"
          domainRequired={true}
          domainFont="normal normal 300 13px/17px Source Serif Pro"
          companyFont="normal normal 900 20px/28px Source Serif Pro"
          companyData={{
            name: companyData?.name || "webX",
            logoUrl: companyData?.icon || "",
            domain: companyData?.domain || "",
          }}
        />
      </Stack>
      <Divider />
      <Stack minHeight={"185px"} padding={"0 20px"} gap={1.5} mt={"20px"}>
        {infoItems?.map((item, index) => (
          <Stack
            key={index}
            direction={"row"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
            gap={1.5}
          >
            <item.icon w={iconSize} style={{ flexShrink: 0 }} />
            {item?.text ? (
              <Typography className={g.descrquoteBox} style={{ flexGrow: 1 }}>
                {item?.text}
              </Typography>
            ) : (
              item.component
            )}
          </Stack>
        ))}
      </Stack>
      <Stack p={"10px 20px 0px 20px"}>
        <MultiIconButton />
      </Stack>
    </Box>
  );
};

export default CrmInfoPopup;
