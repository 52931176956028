import { Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface TimeContainerViewProps {
  /** Time taken in seconds */
  expendedTime: number;
  /** Total time allotted in seconds */
  totalTimeInSec: number;
}

const TimeContainerView: React.FC<TimeContainerViewProps> = ({
  expendedTime,
  totalTimeInSec,
}) => {
    const {t}=useTranslation()
  // Convert seconds to HH:mm format
  const formatTime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60); // Ensure no decimal part
    return `${String(hours).padStart(2, "0")} ${t('VIDEO_CENTER.MEETING.HRS')} ${String(minutes).padStart(2, "0")} ${t('VIDEO_CENTER.MEETING.MINS')} ${String(remainingSeconds).padStart(2, "0")} ${t('VIDEO_CENTER.MEETING.SECS')}`;
  };

  return (
    <Stack sx={{ gap: "5px" }}>
      <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
        <Typography
          sx={{
            font: "normal normal 400 11px/14px Source Serif Pro",
            width: "90px",
          }}
        >
            {t('VIDEO_CENTER.MEETING.TIME_ALLOTTED')}
        </Typography>
        <Typography
          sx={{ font: "normal normal 300 11px/14px Source Serif Pro" }}
        >
          {formatTime(totalTimeInSec)}
        </Typography>
      </Stack>
      <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
        <Typography
          sx={{
            font: "normal normal 400 11px/14px Source Serif Pro",
            width: "90px",
          }}
        >
            {t('VIDEO_CENTER.MEETING.TIME_TAKEN')}
        </Typography>
        <Typography
          sx={{ font: "normal normal 300 11px/14px Source Serif Pro" }}
        >
          {formatTime(expendedTime)}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default TimeContainerView;
