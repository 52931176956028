import React from "react";
import { NotesCard } from "../../../../NotesCard";
import { FolderFileIcon } from "../../../../../../images";
import { Stack } from "@mui/material";

interface DefaultQuickNotesViewProps {}

const DefaultQuickNotesView: React.FC<DefaultQuickNotesViewProps> = ({}) => {
  return (
    <Stack sx={{ gap: "20px", alignItems: "center" }}>
      {/* -------------   Use map function here ------------- */}
      <NotesCard
        cardTitleIcon={FolderFileIcon}
        cardTitleText="Random Folder Name here"
        dateStamp="01-03-2023"
        notesTitle="Example notes"
        notesDescription="Example Note information would be found here so the user can see the information that is presented with the information that here and away."
        userName="User name"
        userIcon="https://randomuser.me/api/portraits/women/68.jpg"
      />{" "}
      <NotesCard
        cardTitleIcon={FolderFileIcon}
        cardTitleText="Random Folder Name here"
        dateStamp="01-03-2023"
        notesTitle="Example notes"
        notesDescription="Example Note information would be found here so the user can see the information that is presented with the information that here and away."
        userName="User name"
        userIcon="https://randomuser.me/api/portraits/women/68.jpg"
      />
      <NotesCard
        cardTitleIcon={FolderFileIcon}
        cardTitleText="Random Folder Name here"
        dateStamp="01-03-2023"
        notesTitle="Example notes"
        notesDescription="Example Note information would be found here so the user can see the information that is presented with the information that here and away."
        userName="User name"
        userIcon="https://randomuser.me/api/portraits/women/68.jpg"
      />
      <NotesCard
        cardTitleIcon={FolderFileIcon}
        cardTitleText="Random Folder Name here"
        dateStamp="01-03-2023"
        notesTitle="Example notes"
        notesDescription="Example Note information would be found here so the user can see the information that is presented with the information that here and away."
        userName="User name"
        userIcon="https://randomuser.me/api/portraits/women/68.jpg"
      />
      {/* --------------  Map function end here   ------------- */}
    </Stack>
  );
};

export default DefaultQuickNotesView;
