import React, { useState } from "react";
import { Stack, Tooltip } from "@mui/material";
import {
  IconDropdownUser,
  IconDropdownUserOptionInterface,
  SmallGroupedAvatars,
} from "../../../../../../common";
import { getUserList } from "../../../../../../../Utils";

interface AssigneesDropdownProps {
  onSelect?: (userData: any) => void;
  mode?: string;
  selectedUser?: any;
  allusers?: any;
}

const AssigneesDropdown: React.FC<AssigneesDropdownProps> = ({
  onSelect,
  mode,
  selectedUser,
  allusers,
}) => {
  const userList = getUserList();

  const [selectedUsers, setSelectedUsers] = useState<
    IconDropdownUserOptionInterface[]
  >(allusers || []);

  const handleSelect = (item: IconDropdownUserOptionInterface) => {
    setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, item]);

    if (onSelect && item) {
      onSelect(item);
    }
  };

  // console.log("Selected Users:", selectedUsers);

  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "20px" }}>
      {/* <Stack
        sx={{
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
          // minWidth: "180px",
          // maxWidth: "180px",
        }}
      >
        <IconDropdownUser
          componentType="showUser"
          showSelectedUser={DUMMY_DATA[4] || null}
          options={userList}
          onSelect={(item) => console.log(item)}
        />
        <Typography
          sx={{ font: "normal normal 300 14px/20px Source Serif Pro" }}
        >
          Richa
        </Typography>
      </Stack> */}

      <SmallGroupedAvatars maxAvatars={4} usersData={selectedUsers} />

      <Tooltip title="Add new">
        <IconDropdownUser
          componentType={
            mode ? (mode === "view" ? "showUser" : "addUser") : "addUser"
          }
          options={userList}
          showSelectedUser={selectedUser || DUMMY_DATA[4] || null}
          onSelect={handleSelect}
        />
      </Tooltip>
    </Stack>
  );
};

export default AssigneesDropdown;

const DUMMY_DATA: IconDropdownUserOptionInterface[] = [
  {
    id: 1,
    name: "Richard Cornsmith",
    imgSrc: "https://randomuser.me/api/portraits/men/1.jpg",
    avatarColor: "#FF5733",
    email: "richard.cornsmith@example.com",
  },
  {
    id: 2,
    name: "John Doe",
    email: "john.doe@example.com",
  },
  {
    id: 3,
    name: "Jane Smith",
    imgSrc: "https://randomuser.me/api/portraits/women/3.jpg",
    avatarColor: "#3357FF",
    email: "jane.smith@example.com",
  },
  {
    id: 4,
    name: "Alice Johnson",
    imgSrc: "https://randomuser.me/api/portraits/women/4.jpg",
    avatarColor: "#FF33A6",
    email: "alice.johnson@example.com",
  },
  {
    id: 5,
    name: "Bob Brown",
    avatarColor: "#33FFA2",
    email: "bob.brown@example.com",
  },
  {
    id: 6,
    name: "Charlie Davis",
    email: "charlie.davis@example.com",
  },
  {
    id: 7,
    name: "Diana Evans",
    imgSrc: "https://randomuser.me/api/portraits/women/7.jpg",
    avatarColor: "#FFA233",
    email: "diana.evans@example.com",
  },
  {
    id: 8,
    name: "Edward Harris",
    avatarColor: "#33D6FF",
    email: "edward.harris@example.com",
  },
  {
    id: 9,
    name: "Fiona Green",
    imgSrc: "https://randomuser.me/api/portraits/women/9.jpg",
    avatarColor: "#FF5733",
    email: "fiona.green@example.com",
  },
  {
    id: 10,
    name: "George Hill",
    imgSrc: "https://randomuser.me/api/portraits/men/10.jpg",
    avatarColor: "#33FF57",
    email: "george.hill@example.com",
  },
  {
    id: 11,
    name: "Hannah Lee",
    imgSrc: "https://randomuser.me/api/portraits/women/11.jpg",
    avatarColor: "#3357FF",
    email: "hannah.lee@example.com",
  },
  {
    id: 12,
    name: "Ian Kelly",
    imgSrc: "https://randomuser.me/api/portraits/men/12.jpg",
    avatarColor: "#FF33A6",
    email: "ian.kelly@example.com",
  },
  {
    id: 13,
    name: "Jackie Moore",
    imgSrc: "https://randomuser.me/api/portraits/women/13.jpg",
    avatarColor: "#33FFA2",
    email: "jackie.moore@example.com",
  },
  {
    id: 14,
    name: "Kevin Nelson",
    imgSrc: "https://randomuser.me/api/portraits/men/14.jpg",
    avatarColor: "#A233FF",
    email: "kevin.nelson@example.com",
  },
  {
    id: 15,
    name: "Laura Owens",
    imgSrc: "https://randomuser.me/api/portraits/women/15.jpg",
    avatarColor: "#FFA233",
    email: "laura.owens@example.com",
  },
  {
    id: 16,
    name: "Michael Perez",
    imgSrc: "https://randomuser.me/api/portraits/men/16.jpg",
    avatarColor: "#33D6FF",
    email: "michael.perez@example.com",
  },
  {
    id: 17,
    name: "Nancy Queen",
    imgSrc: "https://randomuser.me/api/portraits/women/17.jpg",
    avatarColor: "#FF5733",
    email: "nancy.queen@example.com",
  },
  {
    id: 18,
    name: "Oscar Roberts",
    imgSrc: "https://randomuser.me/api/portraits/men/18.jpg",
    avatarColor: "#33FF57",
    email: "oscar.roberts@example.com",
  },
  {
    id: 19,
    name: "Paula Scott",
    imgSrc: "https://randomuser.me/api/portraits/women/19.jpg",
    avatarColor: "#3357FF",
    email: "paula.scott@example.com",
  },
  {
    id: 20,
    name: "Quincy Turner",
    imgSrc: "https://randomuser.me/api/portraits/men/20.jpg",
    avatarColor: "#FF33A6",
    email: "quincy.turner@example.com",
  },
];
