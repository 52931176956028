import { Box, Divider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import g from "./Notification.module.css";
import {
  ButtonIconXlong,
  CheckboxMedium,
  DayPickerlong,
  SmallTimePicker,
} from "../../../../common";
import { DesktopPng, SlackPng } from "../../../../../images";
type NotificationProps = {};

const Notification: React.FC<NotificationProps> = () => {
  const {t}=useTranslation()
  return (
    <Box sx={{ padding: "20px 25px 25px 25px" }}>
      <div>
        <Typography
          className={g["gs-container-header"]}
          children={"App Notification"}
        />
        <Typography
          className={g["italic-text-subject"]}
          children={
            "Manage all your notifications from here to ensure you’re always on top of your work."
          }
        />
      </div>

      <Stack gap={"20px"} mt={"20px"}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          gap={"20px"}
        >
          <ButtonIconXlong
            headerText={"Desktop Notifications"}
            bodyText={"Receive notifications directly on your device"}
            imgSrc={DesktopPng}
            comingSoon={false}
            buttonClick={() => {
              console.log("Desktop Notifications");
            }}
          />
          <ButtonIconXlong
            headerText={"Slack Notifications"}
            bodyText={"Receive notifications directly to Slack"}
            imgSrc={SlackPng}
            comingSoon={true}
            buttonClick={() => {
              console.log("Desktop Notifications");
            }}
          />
        </Stack>
        <Stack>
          <div>
            <Typography
              className={g["gs-container-header"]}
              children={"Email Notifications"}
            />
          </div>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            flexWrap={"wrap"}
            mt={"20px"}
            gap={"12px"}
          >
            <Stack
              direction={"row"}
              alignItems={"flex-start"}
              justifyContent={"center"}
              gap={"5px"}
              width={"48%"}
            >
              <CheckboxMedium />
              <Stack direction={"column"} gap={"10px"}>
                <Typography className={g["all-Activity"]}>
                  All Activity
                </Typography>
                <Typography className={g["sub-activity"]}>
                  You are notified when you are assigned a task, added as a
                  collaborator, when something you follow is updated, or when
                  you are @mentioned.
                </Typography>
              </Stack>
            </Stack>
            {/* ----s---- */}
            <Stack
              direction={"row"}
              alignItems={"flex-start"}
              justifyContent={"center"}
              gap={"5px"}
              width={"48%"}
            >
              <CheckboxMedium />
              <Stack direction={"column"} gap={"10px"}>
                <Typography className={g["all-Activity"]}>
                  Daily Summaries
                </Typography>
                <Typography className={g["sub-activity"]}>
                  Receive a daily summary of the events that have taken place
                  throughout or events that BEBA has done
                </Typography>
              </Stack>
            </Stack>
            {/* ----e---- */}
            {/* ----s---- */}
            <Stack
              direction={"row"}
              alignItems={"flex-start"}
              justifyContent={"center"}
              gap={"5px"}
              width={"48%"}
            >
              <CheckboxMedium />
              <Stack direction={"column"} gap={"10px"}>
                <Typography className={g["all-Activity"]}>Mentions</Typography>
                <Typography className={g["sub-activity"]}>
                  When you’re @mentioned on a task, message, event, project
                  workspace or something is assigned to you directly
                </Typography>
              </Stack>
            </Stack>
            {/* ----e---- */}
            {/* ----s---- */}
            <Stack
              direction={"row"}
              alignItems={"flex-start"}
              justifyContent={"center"}
              gap={"5px"}
              width={"48%"}
            >
              <CheckboxMedium />
              <Stack direction={"column"} gap={"10px"}>
                <Typography className={g["all-Activity"]}>
                  Weekly Updates
                </Typography>
                <Typography className={g["sub-activity"]}>
                  Receive a weekly summary of the events that have taken place
                  throughout or events that BEBA has done
                </Typography>
              </Stack>
            </Stack>
            {/* ----e---- */}
            {/* ----s---- */}
            <Stack
              direction={"row"}
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
              gap={"5px"}
              width={"48%"}
            >
              <CheckboxMedium />
              <Stack direction={"column"} gap={"10px"}>
                <Typography className={g["all-Activity"]}>None</Typography>
              </Stack>
            </Stack>
            {/* ----e---- */}
          </Stack>
        </Stack>
      </Stack>

      <Stack mt={"20px"} mb={"20px"}>
        <Divider />
      </Stack>
      {/* Optimize later */}
      <Stack>
        <div>
          <Typography
            className={g["gs-container-header"]}
            children={"Status"}
          />
        </div>
        {/* Optimize later */}
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          flexWrap={"wrap"}
          mt={"20px"}
          gap={"20px"}
        >
          {/* ----s---- */}
          <Stack
            direction={"row"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
            gap={"5px"}
          >
            <CheckboxMedium />
            <Stack direction={"column"} gap={"10px"}>
              <Typography className={g["all-Activity"]}>Online</Typography>
            </Stack>
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
            gap={"5px"}
          >
            <CheckboxMedium />
            <Stack direction={"column"} gap={"10px"}>
              <Typography className={g["all-Activity"]}>Offline</Typography>
            </Stack>
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
            gap={"5px"}
          >
            <CheckboxMedium />
            <Stack direction={"column"} gap={"10px"}>
              <Typography className={g["all-Activity"]}>
                Do not disturb
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
            gap={"5px"}
          >
            <CheckboxMedium />
            <Stack direction={"column"} gap={"10px"}>
              <Typography className={g["all-Activity"]}>
                Be back soon
              </Typography>
            </Stack>
          </Stack>
          {/* ----e---- */}
        </Stack>
      </Stack>

      <Stack mt={"25px"}>
        <div>
          <Typography
            className={g["gs-container-header"]}
            children={"Schedule"}
          />
        </div>
        {/* Optimize later */}
        <Stack
          direction={"column"}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
          flexWrap={"wrap"}
          mt={"10px"}
          gap={"20px"}
        >
          {/* ----s---- */}
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            gap={"5px"}
          >
            {" "}
            <CheckboxMedium />
            <Stack direction={"column"} gap={"10px"}>
              <Typography className={g["all-Activity"]}>
                Do not notify me between
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              gap={"15px"}
              ml={"15px"}
            >
              <SmallTimePicker />
              <Typography
                sx={{ font: "normal normal 300 13px/17px Source Serif Pro" }}
              >
                {t('MESSAGE.TO')}
              </Typography>
              <SmallTimePicker />
            </Stack>
          </Stack>

          {/* ----s---- */}
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            gap={"5px"}
          >
            {" "}
            <CheckboxMedium />
            <Stack direction={"column"} gap={"10px"}>
              <Typography className={g["all-Activity"]}>
                Do not disrupt on these days
              </Typography>
            </Stack>
            <Stack ml={"10px"}>
              <DayPickerlong />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Notification;
