/* CompanyTable.tsx */
import React, { useEffect, useState } from "react";
import { ColDef, CellValueChangedEvent } from "ag-grid-community";
import { ICellRendererParams } from "ag-grid-community";
import {
  AddCalculationsMenu,
  CategoriesTable,
  CName,
  DataTable,
  DomainTable,
  RatingTable,
  CompanyData,
  PrimaryLocationTable,
} from "../../../components";
import { AutoSizeTextArea, DateAndTimePicker } from "../../../../../common";
import { AddColumnCrmTable, CustomHeaderComponent } from "../../../common";
import {
  CompanyIcon,
  CrunchbaseBlackPng,
  DataIDnumberIcon,
  DescriptionIcon,
  DomainIcon,
  EmployeeCountIcon,
  EstimatedARRIcon,
  LasTTouchPointIcon,
  LocationIcon,
  NotesIcon,
  NotesLineIcon,
  ProjectIcon,
  RelatedTaskIcon,
  RelationshipStrengthIcon,
  TagIcon,
  TasksInvolvedIcon,
  TeamMemberIcon,
} from "../../../../../../images";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/store";

const companyColumnDefs: ColDef<CompanyData>[] = [
  {
    headerName: "Company",
    field: "company",
    headerClass: "my-header-class",
    sortable: true,
    filter: true,
    pinned: "left",
    editable: false,
    wrapText: true,
    autoHeight: true,
    checkboxSelection: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      headerSvg: CompanyIcon,
      // imageUrl:
      // "https://vignette.wikia.nocookie.net/es.pokemon/images/f/f4/Charizard_PLB.png/revision/latest?cb=20170426010226", // URL of the image
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return (
        <CName
          hPopover={true}
          br={"25%"}
          targetOpen={true}
          targetLocation="company"
          p={params}
          value={params?.value?.companyName}
        />
      );
    },
  },
  {
    headerName: "Categories",
    field: "categories",
    sortable: true,
    filter: true,
    headerClass: "my-header-class",
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 275,
    maxWidth: 350,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      headerSvg: TagIcon,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <CategoriesTable words={[]} p={params} />;
    },
  },
  {
    headerName: "Last touchpoint",
    field: "lastTouchpoint",
    sortable: true,
    filter: true,
    editable: false,
    wrapText: true,
    headerClass: "my-header-class",
    autoHeight: true,
    minWidth: 230,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      headerSvg: LasTTouchPointIcon,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return (
        <DateAndTimePicker
          sx={{ font: "normal normal 400 12px/16px Source Serif Pro" }}
        />
      );
    },
  },
  {
    headerName: "Relationship Strength",
    field: "relationshipStrength",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 200,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      headerSvg: RelationshipStrengthIcon,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <RatingTable tableData={params} />;
    },
  },
  {
    headerName: "Description",
    field: "description",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      headerSvg: DescriptionIcon,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <AutoSizeTextArea TableData={params} />;
    },
  },
  {
    headerName: "Domain",
    field: "domain",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      headerSvg: DomainIcon,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <DomainTable TableData={params} />;
    },
  },

  {
    headerName: "Data ID number",
    field: "dataIDNumber",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      headerSvg: DataIDnumberIcon,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <AutoSizeTextArea TableData={params} />;
    },
  },

  {
    headerName: "Teams",
    field: "teams",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 180,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      headerSvg: TeamMemberIcon,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <CName value={params.value} />;
    },
  },
  {
    headerName: "Primary location",
    field: "primaryLocation",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      headerSvg: LocationIcon,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <PrimaryLocationTable TableData={params.value} />;
    },
  },
  {
    headerName: "Crunchbase",
    field: "crunchBase",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 200,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      imageUrl: CrunchbaseBlackPng,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <DomainTable useWithoutBorder TableData={params} />;
    },
  },
  {
    headerName: "Estimated ARR",
    field: "estimatedARR",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 150,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      headerSvg: EstimatedARRIcon,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <CategoriesTable words={params.value} />;
    },
  },
  {
    headerName: "Projects involved in",
    field: "projectsInvolvedIn",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      headerSvg: ProjectIcon,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <CName value={params.value} />;
    },
  },
  {
    headerName: "Employee Count",
    field: "employeeCount",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 150,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      headerSvg: EmployeeCountIcon,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <CategoriesTable words={params.value} />;
    },
  },
  {
    headerName: "Next related task",
    field: "nextRelatedTask",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 200,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      headerSvg: RelatedTaskIcon,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <AutoSizeTextArea TableData={params} />;
    },
  },
  {
    headerName: "Tasks involved in",
    field: "tasksInvolvedIn",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 200,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      headerSvg: TasksInvolvedIcon,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <CName value={params.value} />;
    },
  },
  {
    headerName: "List of notes",
    field: "listOfNotes",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 220,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      headerSvg: NotesLineIcon,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <CName value={params.value} />;
    },
  },
];

const companyRowData: CompanyData[] = [
  {
    company: {
      companyName: "Red Hair Pirate - Shanks",
      companyLogoUrl:
        "https://static.wikia.nocookie.net/p__/images/e/e0/Red_hair.jpg/revision/latest?cb=20200824232939&path-prefix=protagonist",
    },
    categories: [
      { name: "Software", id: "kd" },
      { name: "Business", id: "eo" },
      { name: "Network", id: "eoe" },
    ],
    lastTouchpoint: Date.now(),
    relationshipStrength: 1.5,
    description:
      "The relationship strength of the company that owns the company.",
    domain: "tech.com",
    dataIDNumber: "hdi-973n",
    teams: "Tech Corp team",
    primaryLocation: "http://tech.com",
    crunchBase: "http://www.angleList.com",
    estimatedARR: ["esstimate arr"],
    projectsInvolvedIn: "projectsInvolvedIn",
    employeeCount: [10],
    nextRelatedTask: "nextRelatedTask",
    tasksInvolvedIn: "tasksInvolvedIn",
    listOfNotes: "listOfNotes",
  },
];

const pinnedBottomRowData: CompanyData[] = [
  {
    company: {
      companyName: "Red Hair Pirate - Shanks",
      companyLogoUrl:
        "https://static.wikia.nocookie.net/p__/images/e/e0/Red_hair.jpg/revision/latest?cb=20200824232939&path-prefix=protagonist",
    },
    categories: [
      { name: "Software", id: "kd" },
      { name: "Business", id: "eo" },
      { name: "Network", id: "eoe" },
    ],
    lastTouchpoint: 25,
    relationshipStrength: 2.5,
    description: "",
    domain: "",
    actions: <AddCalculationsMenu />, // Rendering your component
    dataIDNumber: "hdi-973n",
    teams: "Tech Corp team",
    primaryLocation: "frontend",

    crunchBase: "http://www.crunchBase.com",
    estimatedARR: ["esstimate arr"],
    projectsInvolvedIn: "projectsInvolvedIn",
    employeeCount: [10],
    nextRelatedTask: "nextRelatedTask",
    tasksInvolvedIn: "tasksInvolvedIn",
    listOfNotes: "listOfNotes",
  },
];

const updateCompanyData = async (event: CellValueChangedEvent<CompanyData>) => {
  const updatedData = event.data;
  // Make an API call to update the data
  try {
    console.log("Data updated successfully:", updatedData);
  } catch (error) {
    console.error("Error updating data:", error);
  }
};

const CompanyTable = () => {
  const crmState = useSelector((state: RootState) => state && state?.crm);
  const { companyList } = crmState || {};

  const [rowData, setRowData] = useState<any>([]);
  const [columnDefs] = useState(companyColumnDefs);

  useEffect(() => {
    const transformedCompanyList: CompanyData[] =
      companyList?.map((company: any) => ({
        company: {
          companyName: company?.businessName,
          companyLogoUrl: company?.businessLogo || "", // Default empty if no logo
        },
        categories: company?.categories?.map((category: any) => ({
          name: category?.name || "N/A", // Handle empty category names
          id: category?._id || "N/A",
          color: category?.color || "N/A",
        })),
        lastTouchpoint: new Date(company?.updatedAt).getTime(), // Convert to timestamp
        relationshipStrength: company?.relationshipStrength, // Mapping relationship strength
        description: company?.description || "No description available",
        domain: company?.domains[0] || "No domain", // Extract first domain
        dataIDNumber: company?._id,
        teams: company?.createdBy?.teamIds?.length
          ? company?.createdBy?.teamIds?.join(", ")
          : "No teams", // Combine team IDs into a string if available
        primaryLocation: company?.primaryLocation || "No location available", // Map primary location

        crunchBase: company?.socialLinks?.crunchbase || "No CrunchBase link",
        estimatedARR: company?.estimatedARR
          ? [company.estimatedARR.toString()]
          : ["0"], // Properly handle ARR as a string array or fallback to ["0"]

        projectsInvolvedIn: company?.associatedDeals?.length
          ? company?.associatedDeals?.join(", ")
          : "No projects", // Combine associated deals into a string
        employeeCount: [company?.employeeCount?.toString() || "0"], // Handle employee count

        nextRelatedTask: "No next task", // No data provided for nextRelatedTask
        tasksInvolvedIn: "No tasks", // No tasks provided
        listOfNotes: "No notes", // No notes provided
        allData: company,
      })) || [];

    setRowData(transformedCompanyList);
  }, [companyList]);

  return (
    <Stack width={"100%"} direction={"row"} alignItems={"flex-start"} gap={0}>
      <DataTable<CompanyData>
        columnDefs={columnDefs}
        rowData={rowData}
        pinnedBottomRowData={pinnedBottomRowData}
        onCellValueChanged={updateCompanyData}
      />
      <AddColumnCrmTable />
    </Stack>
  );
};

export default CompanyTable;
