import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";

import styles from "./CustomForwardMessage.module.css";
import { DropdownWithImageBadge, DropdownWithImageBadgeForChannels } from "../DropdownWithImageBadge";
import { ButtonSmall } from "../Buttons";
import { DropdownWithImgBadgeInterface, DUMMY_USER_EMAIL_LIST } from "../DropdownWithImageBadge/DropdownWithImageBadgeTypes";
import { ButtonType, SmallButtonColor } from "../Buttons/AllButtonProps";
import { useTranslation } from "react-i18next";
import Custom from "./Custom";

interface ForwardMessageProps {
  setOpenForwardMessagePopup?:any
  onClickSendButton?: any;
  onClickCancelButton?: any;
  usersList?:any
  setSelectUsersForForwardMessage?:any;
}

const CustomForwardMessage: React.FC<ForwardMessageProps> = ({setOpenForwardMessagePopup,onClickCancelButton,onClickSendButton,usersList,setSelectUsersForForwardMessage}) => {
  const {t}=useTranslation()

  const usersArray:DropdownWithImgBadgeInterface[] = usersList ? Object.values(usersList) : [];

  return (
    <Stack
      className={styles["forward-message"]}
    >
      <Typography className={styles["forward-message__title"]}>
        Forward message
      </Typography>
      <Stack className={styles["forward-message__content"]}>
        <Stack className={styles["forward-message__recipient-row"]}>
          <Typography className={styles["forward-message__recipient-label"]}>
            {t('MESSAGE.TO')}
          </Typography>
          <Custom
            options={usersArray}
            onChangeSelection={(userList:any) => setSelectUsersForForwardMessage(userList)}
          />
        </Stack>
      </Stack>
      <Stack className={styles["forward-message__actions"]}>
        <ButtonSmall
        onClick={onClickCancelButton}
          label={t('MESSAGE.CANCEL')}
          types={ButtonType.Button}
          colorVarient={SmallButtonColor.Transparent}
        />
        <ButtonSmall
          label= {t('MESSAGE.SEND')}
          onClick={onClickSendButton}
          types={ButtonType.Button}
          colorVarient={SmallButtonColor.MessagePurple}
        />
      </Stack>
    </Stack>
  );
};

export default CustomForwardMessage;
