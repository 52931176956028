import React, { useEffect, useRef } from "react";
import { IHeaderParams } from "ag-grid-community";
import { bearishOSOctaIcon, FilterIcon } from "../../../../../images";
import { Popover } from "@mui/material";
import { ColumnControls } from "../../components";

interface CustomHeaderProps extends IHeaderParams {
  displayName: string;
  showColumnMenu: (source: HTMLElement) => void;
  imageUrl?: string | null;
  headerSvg?: React.ElementType;
}

const CustomHeaderComponent: React.FC<CustomHeaderProps> = (props) => {
  const eMenu = useRef<HTMLSpanElement>(null);
  const eLabel = useRef<HTMLDivElement>(null);
  const eText = useRef<HTMLSpanElement>(null);
  const eFilterImg = useRef<HTMLImageElement>(null);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (eMenu.current) {
      eMenu.current.addEventListener("click", () =>
        props.showColumnMenu(eMenu.current!)
      );
    }
    if (eFilterImg.current) {
      eFilterImg.current.addEventListener("click", () =>
        props.showColumnMenu(eFilterImg.current!)
      );
    } // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [props.showColumnMenu]);

  return (
    <div
      className="ag-cell-label-container"
      role="presentation"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {" "}
      <img
        ref={eFilterImg}
        src={FilterIcon}
        width="15"
        height="15"
        alt="filter icon"
        className="custom-filter-icon"
      />
      <img
        src={bearishOSOctaIcon}
        width="15"
        height="15"
        style={{
          marginRight: "10px",
      
        }}
        alt="filter icon"
        onClick={(e: any) => handleClick(e)}
      />
      <div
        ref={eLabel}
        className="ag-header-cell-label"
        role="presentation"
        style={{ display: "flex", alignItems: "center", flexGrow: 1 }}
      >
        <span
          ref={eMenu}
          className="ag-header-icon ag-header-cell-menu-button"
          style={{ marginRight: "8px" }}
        ></span>
        {props.imageUrl && (
          <img
            src={props.imageUrl || ""}
            width="15"
            height="15"
            className="mr"
            alt="icon"
            style={{ marginRight: "8px" }}
          />
        )}
        <span style={{ marginRight: "8px", display :"flex", alignItems:"center", justifyContent:"center", marginTop:"2px" }}>
          {props.headerSvg &&
            React.createElement(props.headerSvg, { w: 15, color: "black" })}
        </span>
        <span
          ref={eText}
          className="ag-header-cell-text"
          role="columnheader"
          onClick={() => props.progressSort(false)}
          style={{ cursor: "pointer" }}
        >
          {props.displayName}
        </span>
        <span className="ag-header-icon ag-sort-indicator"></span>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <ColumnControls />
      </Popover>
    </div>
  );
};

export default CustomHeaderComponent;
