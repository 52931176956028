import { Box, Popper, Stack, Typography } from "@mui/material";
import React from "react";
import { AvatarNormal, SmallContactCardHover } from "../../../../../../common";
import { DummyImage } from "../../../../../../../images";
import {
  DUMMY_EMAIL_CARD_USER,
  UserCount,
} from "../../../../../Email/components";

interface UserDetailsViewProps {
  vUser?: any;
}

const UserDetailsView: React.FC<UserDetailsViewProps> = ({ vUser }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    if (anchorEl && !anchorEl.contains(document.activeElement)) {
      setAnchorEl(null);
    }
  };
  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
      <Stack
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "5px",
          maxWidth: "185px",
          cursor: "pointer",
        }}
      >
        <AvatarNormal
          size={30}
          username={vUser?.firstName + " " + vUser?.lastName || "U K"}
          avatarColor="orange"
          // imgSrc={DummyImage}
          sx={{
            border: "1px solid #E9EDF2",
            boxSizing: "border-box",
            color: "white",
            font: "normal normal 300 14px/17px Source Serif Pro",
          }}
        />
        <Stack sx={{ gap: "5px", width: "calc(100% - 35px)" }}>
          <Typography
            sx={{
              maxWidth: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              font: "normal normal 400 13px/16px Source Serif Pro",
            }}
          >
            {vUser?.firstName + " " + vUser?.lastName || "Unknown User"}
          </Typography>
          <Typography
            sx={{
              maxWidth: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              font: "normal normal 300 11px/14px Source Serif Pro",
            }}
          >
            {vUser?.email || "Unknown User Email"}
          </Typography>
        </Stack>
        <Popper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement="top"
          onMouseLeave={handleMouseLeave}
          sx={{
            zIndex: 10,
            // boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            boxShadow: "none",
            borderRadius: "5px",
            paddingBottom: "10px",
          }}
        >
          <SmallContactCardHover
            userEmail={vUser?.email || "Unknown User Email"}
            userName={
              vUser?.firstName + " " + vUser?.lastName || "Unknown User"
            }
            userIconSrc={DummyImage}
            userAvatarColor={"orange"}
          />
        </Popper>
      </Stack>
      <UserCount userList={DUMMY_EMAIL_CARD_USER} />
    </Stack>
  );
};

export default UserDetailsView;
