import React, { useEffect } from "react";
import {
  Button,
  FormControl,
  IconButton,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import S from "./CalendarScheduleMenu.module.css";
import {
  AddCalPlusIcon,
  ChevronIcon,
  GreenDropdownIcon,
} from "../../../../../images";
import { CalendarScheduleMenuListInterface } from "./CalendarScheduleMenuType";
import { CalendarSchedulePopup } from "../CalendarSchedulePopup";
import {
  CalendarState,
  createSchedule__api,
  getAllSchedules__api,
} from "../../../../../redux";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

interface CalendarScheduleMenuProps {
  label?: string;
  placeholder?: string;
  options: CalendarScheduleMenuListInterface[];
  width?: string;
  disabled?: boolean;
  onChange?: (selectedOption: any) => void;
  icon?: "greenArrow" | "chevron" | "none";
}
interface Schedule {
  _id: string;
  name: string;
  // Add other fields as needed
}

const CalendarScheduleMenu: React.FC<CalendarScheduleMenuProps> = ({
  options,
  placeholder,
  label,
  width = "325px",
  disabled = false,
  onChange,
  icon = "none",
  ...props
}) => {
  const dispatch = useDispatch();
  const calendarState = useSelector(
    (state: RootState) => state && (state?.calendar as CalendarState)
  );

  const { scheduleList } = calendarState || {};

  const [selectedValues, setSelectedValues] = React.useState<Schedule | null>(
    null
  ); // Use Schedule type

  const [open, setOpen] = React.useState(false);
  const [editOption, setEditOption] =
    React.useState<CalendarScheduleMenuListInterface | null>();

  const handleChange = React.useCallback(
    (event: SelectChangeEvent<string>) => {
      const {
        target: { value },
      } = event;

      // Check if scheduleList is an array before proceeding
      if (!Array?.isArray(scheduleList) || scheduleList?.length === 0) {
        console.error("scheduleList is not an array or is empty");
        return;
      }

      // Find the full Schedule object by name
      const selectedValue = scheduleList?.find(
        (item: any) => item?.name === value
      );

      // If selectedValue is found, ensure that _id is defined
      if (selectedValue && selectedValue?._id) {
        // Ensure the selected value has the type Schedule by mapping the fields
        const schedule: Schedule = {
          _id: selectedValue?._id, // Ensure _id is a string
          name: selectedValue?.name,
          // Add other necessary fields from selectedValue...
        };

        // Update the state with the full Schedule object
        setSelectedValues(schedule);

        // If there's an onChange handler, pass the schedule object
        if (onChange) {
          onChange(schedule);
        }
      } else {
        console.log("Selected value not found or _id is missing");
      }
    },
    [onChange, scheduleList] // Updated dependency array
  );

  const MenuProps = React.useMemo(
    () => ({
      PaperProps: {
        className: S["calendarScheduleMenu__menu"],
        style: { width: width },
      },
    }),
    [width]
  );

  const addNewClickHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setEditOption(null);
    handleClick(event);
  };

  const editClickHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: any
  ) => {
    event.stopPropagation();
    setEditOption(data);
    handleClick(event);
  };

  const getOpenedIcon = () => {
    switch (icon) {
      case "chevron":
        return (
          <ChevronIcon
            w={20}
            direction={open ? "down" : "up"}
            style={{ marginRight: "10px" }}
          />
        );

      case "greenArrow":
        return <GreenDropdownIcon open={open} sx={{ marginRight: "10px" }} />;
      default:
        return null;
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openSchedule = Boolean(anchorEl);
  const id = openSchedule ? "simple-popover" : undefined;

  const handleCreateSchedule = async (newValue: any) => {
    try {
      if (!newValue) {
        return;
      }

      const action = createSchedule__api(newValue);

      const createScheduleRes: any = await dispatch(action);

      if (createScheduleRes?.success) {
        handleGetAllSchedule();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleGetAllSchedule = async () => {
    try {
      const action = getAllSchedules__api();

      await dispatch(action);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (scheduleList?.length > 0) {
      return; // If scheduleList is not empty, exit the effect
    }
    handleGetAllSchedule(); // Call the function if scheduleList is empty
  }, []); // Empty dependency array ensures this runs only once on mount
  return (
    <FormControl size="small" style={{ width }} disabled={disabled} {...props}>
      {label && (
        <Typography
          sx={{
            font: "normal normal normal 10px/13px Source Serif Pro",
            color: "black",
            marginBottom: "5px",
          }}
        >
          {label}
        </Typography>
      )}
      <Select
        onClick={!open && !disabled ? () => setOpen(true) : () => null}
        open={open}
        multiple={false}
        displayEmpty
        IconComponent={() => getOpenedIcon()}
        value={selectedValues ? selectedValues?.name : ""} // Display the name in the dropdown
        onChange={handleChange}
        input={
          <OutlinedInput
            className={S["calendarScheduleMenu__inputbox"]}
            classes={{
              notchedOutline: S["calendarScheduleMenu__notchedOutline"],
              focused: S["calendarScheduleMenu__focused"],
            }}
          />
        }
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        renderValue={(selected) => {
          if (!selected) {
            return (
              <em className={S["calendarScheduleMenu__selectPlaceholder"]}>
                {placeholder}
              </em>
            );
          }

          return (
            <span className={S["calendarScheduleMenu__selected"]}>
              {selectedValues?.name}
            </span>
          );
        }}
        MenuProps={MenuProps}
        aria-label={placeholder}
      >
        {scheduleList &&
          scheduleList?.length > 0 &&
          scheduleList?.map((schedule: any) => (
            <MenuItem
              className={S["calendarScheduleMenu__menu-item"]}
              key={schedule?._id}
              value={schedule?.name} // Still using the name as the value for display purposes
            >
              <Typography className={S["calendarScheduleMenu__list-item-text"]}>
                {schedule?.name}
              </Typography>

              <IconButton
                sx={{ p: "2px 5px", borderRadius: "5px", color: "#21B481" }}
                onClick={(event) => editClickHandler(event, schedule)} // Pass event and schedule to handler
              >
                <Typography
                  sx={{
                    font: "normal normal 300 13px/17px Source Serif Pro",
                    color: "inherit",
                  }}
                >
                  Edit
                </Typography>
              </IconButton>
            </MenuItem>
          ))}

        <Button
          onClick={addNewClickHandler}
          variant="text"
          className={S["calendarScheduleMenu__menu-add-button"]}
          key={"add-button"}
        >
          <AddCalPlusIcon w={15} />
          <Typography className={S["calendarScheduleMenu__list-item-text"]}>
            Add new schedule
          </Typography>
        </Button>
      </Select>

      <Popover
        id={id}
        open={openSchedule}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <CalendarSchedulePopup
          initialValue={editOption ? editOption : undefined}
          onCloseClick={handleClose}
          onSave={(newValue) => {
            handleClose();
            handleCreateSchedule(newValue);
          }}
        />
      </Popover>
    </FormControl>
  );
};

export default CalendarScheduleMenu;
