import React, { useEffect, useState } from "react";
import g from "./SignUp.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../redux/store";
import { BearishOSIcon, Loginbg2x } from "../../../images";
import {
  ButtonXLarge,
  LargeInputBox,
  SignupOption,
  UnfilledButtonXLarge,
  VerifyOpt,
  ButtonType,
  ButtonXLargeColor,
  useCustomSnackbar,
} from "../../common";
import { SIGN_UP_OPTIONS_CONTANTS } from "../../../Utils";
import { Stack, Modal, Box, Typography } from "@mui/material";
import {
  AuthState,
  SendOtpPayload,
  externalEntitiesOptions,
  resetOptSendAction,
  sendOptToUser,
  tempAuthStoreSuccessAction,
  verifyOptOfUser,
} from "../../../redux";

const containerStyle = {
  backgroundImage: `url(${Loginbg2x})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  // Add other styles as needed
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: 24,
};

const Signup = () => {
  const { t } = useTranslation();
  const {
    isOptSend,
    error,
    externalEntities,
    eSuccess,
    eData,
    eError,
    isOptVerify,
    password_temp,
  } = useSelector((state: RootState) => state.auth as AuthState);

  const [userSignUp, setUserSignUp] = useState({
    emailAddress: "",
    password: "",
    cpassword: "",
  });

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    dispatch(resetOptSendAction());
    setOpen(false);
  };

  const dispatch = useDispatch();
  const [loaderSignUp, setLoaderSignUp] = useState(false);

  const [opt, setOpt] = useState("");

  const handleChangeSetOPT = (value: string) => {
    setOpt(value);
  };

  const navigate = useNavigate();

  const handleSignUp = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoaderSignUp(true);

    if (
      !userSignUp.emailAddress ||
      !userSignUp.password ||
      !userSignUp.cpassword
    ) {
      enqueueSnackbar_v1("error", "All Field are required", {
        position: { vertical: "top", horizontal: "center" },
        autoHideDuration: 3000, // Optional custom auto hide duration in milliseconds
      });
      setLoaderSignUp(false);
      return;
    }

    if (userSignUp.password !== userSignUp.cpassword) {
      enqueueSnackbar_v1("error", "Password and Confirm Password Mismatch", {
        position: { vertical: "top", horizontal: "center" },
        autoHideDuration: 3000, // Optional custom auto hide duration in milliseconds
      });
      setLoaderSignUp(false);
      return;
    }
    const payload: SendOtpPayload = {
      sendTo: userSignUp.emailAddress,
      password: userSignUp?.password || password_temp,
    };

    await dispatch(sendOptToUser(payload));
    setLoaderSignUp(false);
  };

  const handleExternallySignUp = async (type: string | "") => {
    setLoaderSignUp(true);

    await dispatch(externalEntitiesOptions({ optionAuth: type }));
    setLoaderSignUp(false);
  };

  const handleClick = async (type: number) => {
    if (type === 1) {
      handleClose();
    } else if (type === 2) {
      const payload = {
        OTP: opt,
        email: userSignUp.emailAddress,
      };
      dispatch(verifyOptOfUser(payload));

      dispatch(
        tempAuthStoreSuccessAction({
          email_temp: userSignUp.emailAddress,
          password_temp: userSignUp.password,
          timestamp_store_at: Date.now() || 0,
        })
      );
    } else if (type === 3) {
      console.log("Resending click");
      // resend the opt to the user
    }
  };

  const enqueueSnackbar_v1 = useCustomSnackbar();
  useEffect(() => {
    if (isOptVerify && !error) {
      // Example: Perform actions on successful sign-up
      // Navigate to '/login' after successful sign-up
      setLoaderSignUp(false);
      enqueueSnackbar_v1("success", "Email Verification is completed", {
        position: { vertical: "top", horizontal: "center" },
        autoHideDuration: 3000, // Optional custom auto hide duration in milliseconds
      });
      setTimeout(() => {
        dispatch(resetOptSendAction());
        navigate("/profile-Mapping"); // Redirect to profile-mapping path after 3 seconds
      }, 3000);
    } else if (isOptSend && !error) {
      setLoaderSignUp(false);
      enqueueSnackbar_v1("success", "OPT is sent", {
        position: { vertical: "top", horizontal: "center" },
        autoHideDuration: 3000, // Optional custom auto hide duration in milliseconds
      });
      handleOpen();
    } else if (eData && eSuccess && !eError && externalEntities) {
      // Example: Handle external authentication success
      // Check if externalEntities is not null or undefined before accessing type
      switch (externalEntities.type) {
        case "GoogleAuth":
          // Handle Google authentication success
          enqueueSnackbar_v1(
            "success",
            "Redirecting to Google Authentication",
            {
              position: { vertical: "top", horizontal: "center" },
              autoHideDuration: 3000, // Optional custom auto hide duration in milliseconds
            }
          );
          setTimeout(() => {
            window.location.href = eData; // Redirect to eData path after 3 seconds
          }, 3000);
          break;
        case "MicrosoftAuth":
          // Handle Microsoft authentication success
          enqueueSnackbar_v1(
            "success",
            "Redirecting to Microsoft Authentication",
            {
              position: { vertical: "top", horizontal: "center" },
              autoHideDuration: 3000, // Optional custom auto hide duration in milliseconds
            }
          );
          setTimeout(() => {
            window.location.href = eData; // Redirect to eData path after 3 seconds
          }, 3000);
          break;
        default:
          // Handle default case (if needed)
          break;
      }
    } else if (error !== null) {
      // Example: Handle authentication error
      setLoaderSignUp(false);
    }
    // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [
    error,
    eData,
    eSuccess,
    eError,
    externalEntities,
    isOptSend,
    isOptVerify,
  ]);

  return (
    <div className={`${g.container}`} style={containerStyle}>
      <div className={`${g.container_two}`}>
        <BearishOSIcon w={75} />
        <Typography
          children={t("cna")}
          variant="h1"
          sx={{
            font: "normal normal 600 40px/54px Source Serif Pro",
            margin: "30px 0 0 0",
          }}
        />
        <form
          style={{ gap: "0", padding: "0" }}
          autoComplete="off"
          className={g.form}
          onSubmit={handleSignUp}
        >
          <Stack mb={"20px"} direction={"column"} gap={"20px"}>
            <LargeInputBox
              label={t("ea")}
              value={userSignUp.emailAddress}
              autoComplete="off"
              onChange={(e) => {
                const value = typeof e === "string" ? e : e.target.value;
                // Create a copy of userSignUp and update the emailAddress property
                setUserSignUp({
                  ...userSignUp,
                  emailAddress: value,
                });
              }}
            />

            <LargeInputBox
              type="password"
              label={t("pw")}
              value={userSignUp.password}
              autoComplete="off"
              onChange={(e) => {
                const value = typeof e === "string" ? e : e.target.value;
                // Create a copy of userSignUp and update the emailAddress property
                setUserSignUp({
                  ...userSignUp,
                  password: value,
                });
              }}
            />
            <LargeInputBox
              type="password"
              label={t("cpw")}
              value={userSignUp.cpassword}
              autoComplete="off"
              onChange={(e) => {
                const value = typeof e === "string" ? e : e.target.value;
                // Create a copy of userSignUp and update the emailAddress property
                setUserSignUp({
                  ...userSignUp,
                  cpassword: value,
                });
              }}
            />
          </Stack>

          <ButtonXLarge
            colorVarient={ButtonXLargeColor.Orange}
            buttonSize="default"
            label={t("su")}
            fullwidth={true}
            types={ButtonType.Submit}
            loaderLogin={loaderSignUp}
          />
        </form>
        <SignupOption index={2} />

        <Stack mt={"20px"} width={"100%"} direction={"column"} gap={"10px"}>
          {SIGN_UP_OPTIONS_CONTANTS.map((oi) => {
            return (
              <UnfilledButtonXLarge
                handleExternallySignUp={handleExternallySignUp}
                key={oi.index}
                title={t(oi.title)}
                iconSvg={oi.i}
                authState={oi.authState}
              />
            );
          })}
        </Stack>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <VerifyOpt // limit of 6 digit code
            handleChangeSetOPT={handleChangeSetOPT}
            emails={userSignUp.emailAddress}
            onClick={handleClick}
            opt={opt}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default Signup;
