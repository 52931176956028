import { CalendarTypesEnum } from "../../../../../Utils";

// Define the types
type TaskDataType = {
    id: string | number;
    taskName: string;
    isCompleted: boolean;
    taskTime: string;
}

type EventDataType = {
    id: string | number;
    calendarType: CalendarTypesEnum;
    eventName: string;
    eventTime: string;
    status: 'pending' | 'completed' | 'removed'
}

type DatalistType =
    | {
        type: 'task';
        data: TaskDataType;
    }
    | {
        type: 'event';
        data: EventDataType;
    };

export interface SidebarCalendarDataListInterface {
    id: number | string;
    dateAndTime: string;
    dataList: DatalistType[];
}

// Full list with direct date format
export const DUMMY_SIDEBAR_CALENDAR_LIST: SidebarCalendarDataListInterface[] = [
    {
        id: 1,
        dateAndTime: 'Tuesday, Sep 10',
        dataList: [
            {
                type: 'task',
                data: {
                    id: 101,
                    taskName: 'Design UI for Dashboard',
                    isCompleted: false,
                    taskTime: '08:00 AM - 09:00 AM PST'
                }
            },
            {
                type: 'event',
                data: {
                    id: 201,
                    calendarType: CalendarTypesEnum.GoogleCalendar,
                    eventName: 'Project Kickoff Meeting , we need to complet before deadline',
                    eventTime: '09:30 AM - 10:30 AM PST',
                    status: "pending"
                }
            },
            {
                type: 'task',
                data: {
                    id: 102,
                    taskName: 'Backend API Integration',
                    isCompleted: true,
                    taskTime: '11:00 AM - 12:00 PM PST'
                }
            },
            {
                type: 'task',
                data: {
                    id: 103,
                    taskName: 'Write Unit Tests',
                    isCompleted: false,
                    taskTime: '01:00 PM - 02:00 PM PST'
                }
            }
        ]
    },
    {
        id: 2,
        dateAndTime: 'Wednesday, Sep 11',
        dataList: [
            {
                type: 'event',
                data: {
                    id: 202,
                    calendarType: CalendarTypesEnum.GoogleCalendar,
                    eventName: 'Client Feedback Session',
                    eventTime: '08:00 AM - 09:00 AM PST',
                    status: "completed"
                }
            },
            {
                type: 'task',
                data: {
                    id: 104,
                    taskName: 'Resolve Bugs in Login Module',
                    isCompleted: false,
                    taskTime: '10:00 AM - 11:00 AM PST'
                }
            },
            {
                type: 'task',
                data: {
                    id: 105,
                    taskName: 'Optimize Database Queries',
                    isCompleted: false,
                    taskTime: '01:00 PM - 02:00 PM PST'
                }
            }
        ]
    },
    {
        id: 3,
        dateAndTime: 'Thursday, Sep 12',
        dataList: [
            {
                type: 'event',
                data: {
                    id: 203,
                    calendarType: CalendarTypesEnum.GoogleCalendar,
                    eventName: 'Team Standup',
                    eventTime: '09:00 AM - 09:30 AM PST',
                    status: "removed"
                }
            },
            {
                type: 'task',
                data: {
                    id: 106,
                    taskName: 'Create Landing Page',
                    isCompleted: true,
                    taskTime: '10:00 AM - 11:00 AM PST'
                }
            },
            {
                type: 'task',
                data: {
                    id: 107,
                    taskName: 'Code Review for API',
                    isCompleted: false,
                    taskTime: '02:00 PM - 03:00 PM PST'
                }
            },
            {
                type: 'event',
                data: {
                    id: 204,
                    calendarType: CalendarTypesEnum.OutlookCalendar,
                    eventName: 'Sprint Planning',
                    eventTime: '03:30 PM - 04:30 PM PST',
                    status: "pending"
                }
            }
        ]
    },
    {
        id: 4,
        dateAndTime: 'Friday, Sep 13',
        dataList: [
            {
                type: 'task',
                data: {
                    id: 108,
                    taskName: 'Finalize API Documentation',
                    isCompleted: false,
                    taskTime: '08:30 AM - 09:30 AM PST'
                }
            },
            {
                type: 'task',
                data: {
                    id: 109,
                    taskName: 'Fix UI Issues in Mobile View',
                    isCompleted: true,
                    taskTime: '10:00 AM - 11:00 AM PST'
                }
            },
            {
                type: 'event',
                data: {
                    id: 205,
                    calendarType: CalendarTypesEnum.GoogleCalendar,
                    eventName: 'Design Review',
                    eventTime: '01:00 PM - 02:00 PM PST',
                    status: "completed"
                }
            }
        ]
    },
    {
        id: 5,
        dateAndTime: 'Saturday, Sep 14',
        dataList: [
            {
                type: 'task',
                data: {
                    id: 110,
                    taskName: 'Deploy Staging Environment',
                    isCompleted: false,
                    taskTime: '08:00 AM - 09:00 AM PST'
                }
            },
            {
                type: 'task',
                data: {
                    id: 111,
                    taskName: 'Fix Critical Bugs in Production',
                    isCompleted: false,
                    taskTime: '09:30 AM - 10:30 AM PST'
                }
            },
            {
                type: 'event',
                data: {
                    id: 206,
                    calendarType: CalendarTypesEnum.OutlookCalendar,
                    eventName: 'Code Freeze Discussion',
                    eventTime: '11:00 AM - 12:00 PM PST',
                    status: "pending"
                }
            },
            {
                type: 'task',
                data: {
                    id: 112,
                    taskName: 'Performance Testing',
                    isCompleted: true,
                    taskTime: '01:30 PM - 02:30 PM PST'
                }
            }
        ]
    },
    {
        id: 6,
        dateAndTime: 'Sunday, Sep 15',
        dataList: [
            {
                type: 'event',
                data: {
                    id: 207,
                    calendarType: CalendarTypesEnum.GoogleCalendar,
                    eventName: 'Marketing Team Sync',
                    eventTime: '09:00 AM - 10:00 AM PST',
                    status: "removed"
                }
            },
            {
                type: 'task',
                data: {
                    id: 113,
                    taskName: 'Implement Analytics',
                    isCompleted: false,
                    taskTime: '10:30 AM - 11:30 AM PST'
                }
            },
            {
                type: 'task',
                data: {
                    id: 114,
                    taskName: 'Fix Critical Bugs in Production',
                    isCompleted: true,
                    taskTime: '01:00 PM - 02:00 PM PST'
                }
            }
        ]
    },
    {
        id: 7,
        dateAndTime: 'Monday, Sep 16',
        dataList: [
            {
                type: 'task',
                data: {
                    id: 115,
                    taskName: 'Design Login Flow',
                    isCompleted: false,
                    taskTime: '09:00 AM - 10:00 AM PST'
                }
            },
            {
                type: 'event',
                data: {
                    id: 208,
                    calendarType: CalendarTypesEnum.GoogleCalendar,
                    eventName: 'Weekly Engineering Sync Weekly Engineering Sync',
                    eventTime: '11:00 AM - 12:00 PM PST',
                    status: "completed"
                }
            },
            {
                type: 'task',
                data: {
                    id: 116,
                    taskName: 'Test Payment Gateway Fixes',
                    isCompleted: false,
                    taskTime: '02:00 PM - 03:00 PM PST'
                }
            }
        ]
    },
    {
        id: 8,
        dateAndTime: 'Tuesday, Sep 17',
        dataList: [
            {
                type: 'task',
                data: {
                    id: 117,
                    taskName: 'Create Deployment Plan',
                    isCompleted: false,
                    taskTime: '09:00 AM - 10:00 AM PST'
                }
            }
        ]
    }
];
