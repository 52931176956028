import React from "react";
import { Stack, Typography } from "@mui/material";
import { adjustHexColor } from "../../../../../Utils";

interface LinkCountViewProps {
  minutesCount: number | string;
  viewCount: number | string;
  arrivalsCount: number | string;
}

interface InlineProps {
  CardName: string;
  count: number | string;
  cardColor: string;
}
const InlineComponent: React.FC<InlineProps> = ({
  CardName,
  cardColor,
  count,
}) => {
  return (
    <Stack
      sx={{
        height: "60px",
        width: "105px",
        boxSizing: "border-box",
        padding: "12px 0px 0px 0px",
        borderRadius: "5px",
        bgcolor: adjustHexColor(cardColor, 25),
      }}
    >
      <Typography
        sx={{
          font: "normal normal 900 20px/25px Source Serif Pro",
          textAlign: "center",
        }}
      >
        {count}
      </Typography>
      <Typography
        sx={{
          font: "normal normal 300 14px/18px Source Serif Pro",
          textAlign: "center",
        }}
      >
        {CardName}
      </Typography>
    </Stack>
  );
};

const LinkCountView: React.FC<LinkCountViewProps> = ({
  arrivalsCount,
  minutesCount,
  viewCount,
}) => {
  return (
    <Stack sx={{ flexDirection: "row", justifyContent: "space-between" }}>
      <InlineComponent
        CardName="Total minutes"
        count={minutesCount}
        cardColor="#A1FF9F"
      />
      <InlineComponent
        CardName="Total views"
        count={viewCount}
        cardColor="#FFDCB2"
      />
      <InlineComponent
        CardName="Total Arrivals"
        count={arrivalsCount}
        cardColor="#CFA0FF"
      />
    </Stack>
  );
};

export default LinkCountView;
