import React, { useEffect, useState } from "react";
import { Box, Stack, MenuItem, Typography } from "@mui/material";
import { GreenArrowButton, IconTextComponent } from "../Buttons";
import { CloseIcon, GreenArrow } from "../../../images";
import { AvatarMedium } from "../AvatarMedium";
import styles from "./TopNavbar.module.css";
import UserProfile from "./UserProfile";
import { StyledMenu, StyledSubMenu } from "./StyledMenubar";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BgColorCode } from "../AvatarLarge/AvatarLargeTypes"; // Ensure this import matches your file structure
import {
  RoutesParamType,
  STATUS_LISTS,
  TOP_NAVBAR_ROUTES,
  TopNavbarRoute,
  TopNavbarRouteAction,
} from "./TopNavBarType";
import {
  cookieDomain,
  ENABLE_WORKOS_MODE,
  getUser,
  isAuthenticated,
} from "../../../Utils";
import { AnonymousComponent } from "./Anonymous";
import { clearAllStorage } from "../../../redux/middleware/apiMiddleware";
import { RootState } from "../../../redux/store";
import { AuthState } from "../../../redux";
import { useSelector } from "react-redux";

interface TopNavbarProps {
  drawerOpen: boolean;
  drawerOpenOrCloser: () => void;
}

const TopNavbar: React.FC<TopNavbarProps> = ({
  drawerOpen,
  drawerOpenOrCloser,
}) => {
  const location = useLocation();
  const auth = isAuthenticated();
  const authState = useSelector(
    (state: RootState) => state && (state.auth as AuthState)
  );
  const user = getUser(authState);
  const params = useParams<RoutesParamType>();

  let bgColorCode: BgColorCode = BgColorCode.Default; // Default color
  let userInitials: string = "AA"; // Default initials

  if (auth && user && user.data && user.data.avatar) {
    const [extractedBgColorCode, extractedUserInitials] =
      user.data.avatar.split("|");
    userInitials = extractedUserInitials;

    // Find the matching enum value
    const matchedBgColorCode = (Object.values(BgColorCode) as string[]).find(
      (code) => code.toLowerCase() === extractedBgColorCode.toLowerCase()
    ) as BgColorCode;

    // Use the matched color code if found, otherwise use default
    bgColorCode = matchedBgColorCode || BgColorCode.Default;
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [statusAnchorEl, setStatusAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const navigate = useNavigate();
  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatusClick = (event: React.MouseEvent<HTMLElement>) => {
    setStatusAnchorEl(event.currentTarget);
  };

  const handleStatusClose = () => {
    setStatusAnchorEl(null);
  };

  const handleTopNavigationClick = (
    actionType: TopNavbarRouteAction | null | undefined
  ) => {
    if (!actionType) return;

    const { action, desc } = actionType;

    if (typeof action === "string") {
      navigate(action); // Navigate to URL
    } else if (typeof action === "number") {
      // Handle numeric action (if needed)
      console.log("Numeric action:", action, "desc:", desc);
    }
  };

  // Function to validate if the URL is valid
  const isValidUrl = (url: string | undefined) => {
    if (!url) return false;
    try {
      new URL(url); // Attempt to create a URL object
      return true;
    } catch (_) {
      return false;
    }
  };

  // Determine the image source
  const imgSrc = isValidUrl(user?.data?.avatar) ? user?.data?.avatar : null;

  // Function to handle logout and redirect to os.localtest.me with f=1 query param
  const handleLogout = () => {
    // Clear all cookies and localStorage
    clearAllStorage();

    if (ENABLE_WORKOS_MODE) {
      // Redirect to os.localtest.me with f=1 query parameter
      const redirectUrl = `http://os${
        cookieDomain === ".localtest.me" ? ".localtest.me:3000" : cookieDomain
      }/dashboard?f=1`; // Or use relative path as needed
      window.location.replace(redirectUrl);
    } else {
      window.location.replace("/login");
    }
  };

  // Function to open the support page
  const openSupportPage = () => {
    window.open("https://bearishos.freshdesk.com/support/home", "_blank");
  };

  // Function to open the legal page
  const openLegalPage = () => {
    window.open("https://www.bearishos.com/termofservice", "_blank");
  };

  const ShouldButtonHide = (): boolean => {
    if (
      location.pathname.startsWith("/Trax/employee-directory") &&
      params.empId
    ) {
      return true;
    }
    return false;
  };

  return (
    <Stack direction="row" justifyContent="end">
      <Stack
        sx={{
          width: `calc(100% - ${drawerOpen ? "275px" : "60px"})`,
          transition: "width 0.3s ease-in-out",
        }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={styles.topNavbar}
      >
        <Stack width={"100%"} direction={"row"} alignItems={"center"} gap={2}>
          {!ShouldButtonHide() && (
            <GreenArrowButton
              sx={{
                display: drawerOpen ? "none" : "block",
                transform: "rotate(180deg)",
              }}
              onClick={drawerOpenOrCloser}
            />
          )}
          <AnonymousComponent />
        </Stack>
        <Stack direction="row" gap={1} alignItems="center">
          {TOP_NAVBAR_ROUTES.map((item: TopNavbarRoute) => {
            // Check if current route starts with '/Projects'
            const isActive = location.pathname.startsWith("/Project");

            return (
              <IconTextComponent
                key={item.id}
                label={item.name}
                isActive={isActive && item.name === "Projects"}
                onClick={() => handleTopNavigationClick(item.actionType)}
              >
                <Box
                  sx={{
                    backgroundImage: `url(${item.iconSrc})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </IconTextComponent>
            );
          })}
          <Box className={styles.divider} />
          <AvatarMedium
            showBadge={true}
            imgSrc={imgSrc}
            userName={userInitials}
            bgColorCode={bgColorCode}
            onClick={handleAvatarClick}
          />
        </Stack>

        <StyledMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <Box className={styles.topNavbarUserBox}>
            <UserProfile
              fullName={
                user?.data?.firstName + " " + user?.data?.lastName ||
                "user-name"
              }
              email={user?.data?.email || "user-email"}
            />
          </Box>
          <Stack justifyContent="center" className={styles.topNavbarMenuItem}>
            <Typography className={styles.topNavbarSubscriptionType}>
              Subscription Type:
              <Typography
                component="span"
                className={styles.topNavbarSubscriptionTypeValue}
              >
                Pro
              </Typography>
            </Typography>
          </Stack>
          <MenuItem
            className={styles.topNavbarMenuItem}
            sx={{ justifyContent: "space-between" }}
            onClick={() => {
              openLegalPage();
            }}
          >
            <Typography className={styles.topNavbarMenuItemText}>
              Bearish's Policies & Legal
            </Typography>
            <Box
              className={styles.topNavbarMenuItemBox}
              style={{ backgroundImage: `url(${GreenArrow})` }}
            />
          </MenuItem>
          <MenuItem
            className={styles.topNavbarMenuItem}
            sx={{ justifyContent: "space-between" }}
            onClick={handleStatusClick}
          >
            <Typography className={styles.topNavbarMenuItemText}>
              Status:
              <Typography
                component="span"
                className={styles.topNavbarStatusValue}
              >
                {user?.data?.status || "Online"}
              </Typography>
            </Typography>
            <Box
              className={styles.topNavbarMenuItemBox}
              style={{ backgroundImage: `url(${GreenArrow})` }}
            />
          </MenuItem>
          <MenuItem
            onClick={() => navigate("/Settings")}
            className={styles.topNavbarMenuItem}
          >
            <Typography className={styles.topNavbarMenuItemText}>
              Settings
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              openSupportPage();
            }}
            className={styles.topNavbarMenuItem}
          >
            <Typography className={styles.topNavbarMenuItemText}>
              Support
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => handleLogout()}
            className={styles.topNavbarMenuItem}
          >
            <Typography className={styles.topNavbarMenuItemText}>
              Logout
            </Typography>
          </MenuItem>
        </StyledMenu>

        <StyledSubMenu
          anchorEl={statusAnchorEl}
          open={Boolean(statusAnchorEl)}
          onClose={handleStatusClose}
          className={styles.topNavbarSubmenu}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            className={styles.topNavbarSubmenuHeader}
          >
            <Typography className={styles.topNavbarSubmenuHeaderText}>
              Status
            </Typography>
            <CloseIcon />
          </Stack>

          {STATUS_LISTS.map((item, index) => (
            <MenuItem key={index} className={styles.topNavbarSubmenuItem}>
              <span
                style={{ backgroundColor: item.color }}
                className={styles.topNavbarStatusCircle}
              />
              <Typography className={styles.topNavbarSubmenuItemText}>
                {item?.name}
              </Typography>
            </MenuItem>
          ))}
        </StyledSubMenu>
      </Stack>
    </Stack>
  );
};

export default TopNavbar;
