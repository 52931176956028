import React, { useEffect, useState } from "react";
import g from "./BookEventerTimeSlot.module.css";
import { Box, Button, Grow, Stack, Typography } from "@mui/material";
import { DatePickerWithIcon } from "../../../../../common";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getAvailableTimeSlots__api } from "../../../../../../redux";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

// Extend dayjs to use the plugins
dayjs.extend(utc);
dayjs.extend(timezone);

interface BookEventerTimeSlotProps {
  setSelectedTimeSlot?: any;
  setdateSelected?: any;
}
type Params = Record<string, string | undefined>;

const BookEventerTimeSlot: React.FC<BookEventerTimeSlotProps> = ({
  setSelectedTimeSlot,
  setdateSelected,
}) => {
  const { slug } = useParams<Params>();
  const dispatch = useDispatch();

  const [datePicked, setDatePicked] = useState<any>("");
  const [timeSlots, setTimeSlots] = useState<any>([]);

  // Utility function to format the date
  const formatDate = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const day = date.getDate();
    const suffix =
      day % 10 === 1 && day !== 11
        ? "st"
        : day % 10 === 2 && day !== 12
        ? "nd"
        : day % 10 === 3 && day !== 13
        ? "rd"
        : "th";

    return `${date
      .toLocaleDateString("en-US", options)
      .replace(day.toString(), `${day}${suffix}`)}`;
  };

  // Utility function to convert a Dayjs object to YYYY-MM-DD format for API
  const parseDateForApi = (date: dayjs.Dayjs): string => {
    if (!date.isValid()) {
      console.error("Invalid date:", date);
      return ""; // Return empty string for invalid date
    }

    return date.format("YYYY-MM-DD"); // Return date in YYYY-MM-DD format
  };

  const getUserTimeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  const getTimeSlots = async (date: any) => {
    try {
      if (!date || !slug) return;

      const userTimeZone = getUserTimeZone(); // Get current user's timezone

      // Ensure the date is treated as is, without timezone adjustments
      const localDate = dayjs(date).startOf("day"); // Set the date to the start of the day

      // Get formatted date in YYYY-MM-DD
      const formattedDateForApi = parseDateForApi(localDate);

      if (!formattedDateForApi) {
        console.error("Invalid formatted date for API:", formattedDateForApi);
        return;
      }

      // Pass the exact date string to your API request
      const action = getAvailableTimeSlots__api(
        slug,
        formattedDateForApi,
        userTimeZone
      );

      const timeSlotRes: any = await dispatch(action);
      setTimeSlots([]);

      if (timeSlotRes?.success && timeSlotRes?.data?.length > 0) {
        setTimeSlots(timeSlotRes.data);
        if (setSelectedTimeSlot) {
          setSelectedTimeSlot(null);
        }
      } else {
        if (setSelectedTimeSlot) {
          setTimeSlots([]);
          setSelectedTimeSlot(null);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Effect to call getTimeSlots whenever datePicked changes
  useEffect(() => {
    if (datePicked) {
      const localDate = dayjs(datePicked);
      const formattedDate = parseDateForApi(localDate); // Convert date for API
      if (formattedDate) {
        getTimeSlots(formattedDate);
      } else {
        console.error("Invalid date for API:", datePicked);
      }
    }
  }, [datePicked]);

  // Store both start and end time in the selectedSlot
  const [selectedSlot, setSelectedSlot] = useState<{
    startTime: string;
    endTime: string;
  } | null>(null);

  const handleSelectSlot = (slot: { startTime: string; endTime: string }) => {
    setSelectedSlot(slot); // Store both startTime and endTime
    if (setSelectedTimeSlot) {
      setSelectedTimeSlot(slot); // Store both startTime and endTime
    }
  };

  // Updated to directly return the time string as it is from the API
  const formatTime = (time: string) => {
    return time; // Just return the time string from API
  };

  return (
    <Box className={g.TimeSlot__container}>
      <Stack>
        <Typography className={g.timeSlot__header}>
          Find a time that works best for you below
        </Typography>
      </Stack>

      <Stack className={g.timeSlot___Picker__container}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={1}
        >
          <DatePickerWithIcon
            onDateChange={(dateString: any) => {
              // Use Dayjs directly to handle the date
              const selectedDay = dayjs(dateString).startOf("day"); // Ensure no time component

              // Directly set the selected date as a JavaScript Date if needed
              if (setdateSelected) {
                const localDate = dayjs(selectedDay);
                const formattedDate = parseDateForApi(localDate); // Convert date for API
                setdateSelected(formattedDate); // Convert to JavaScript Date object
              }

              // Format the date separately if you need a string for display
              const formattedDate = selectedDay.format("MM/DD/YYYY"); // Or any format you prefer
              setDatePicked(formattedDate); // Set the formatted date string if needed
            }}
            sx={{ fontSize: "15px" }}
            hideIcon={true}
          />

          <div className={g.divider___timeSlot}></div>
          <Typography className={g.TimeSlot__total__slots}>
            {timeSlots?.length} Total
          </Typography>
        </Stack>
        <Stack mt={2}>
          <Typography className={g.TimeSlot__total__slots}>
            {datePicked}
          </Typography>
        </Stack>

        {/* <-----------------> slots */}
        <Stack
          mt={2}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          flexWrap={"wrap"}
          gap={2}
        >
          {timeSlots && timeSlots?.length > 0 ? (
            timeSlots?.map((slot: any) => (
              <Grow
                in={true}
                style={{ transformOrigin: "0 0 0" }}
                {...(true ? { timeout: 600 } : {})}
                key={slot.startTime} // Use startTime as the key
              >
                <Button
                  className={`${g.timeSlotMain} ${
                    selectedSlot?.startTime === slot.startTime
                      ? g.timeSlotMain__selected
                      : ""
                  }`}
                  onClick={() => handleSelectSlot(slot)} // Call with the slot directly from API
                >
                  {formatTime(slot.startTime)}{" "}
                  {/* Directly display the formatted time */}
                </Button>
              </Grow>
            ))
          ) : (
            <Typography
              className={g.TimeSlot__total__slots}
              variant="body1"
              color="textSecondary"
            >
              No time slots available
            </Typography>
          )}
        </Stack>

        {/* <-----------------> slots */}
      </Stack>
    </Box>
  );
};

export default BookEventerTimeSlot;
