import { IconButton, Stack } from "@mui/material";
import React, { lazy, Suspense } from "react";
import {
  a11yProps,
  StyledTab,
  TabPanel,
  TabsWithState,
} from "../../../../common/CardTabsWithoutIcon";
import { SettingsIcon } from "../../../../../images";
import { useTranslation } from "react-i18next";
import {TabLoader} from "../../../../common/TabLoader";


interface TraxStatusTabsProps {}

// const LazyNewUpdatesTab = lazy(
//   () => import("./tabs/NewUpdatesTab/NewUpdatesTab")
// );
// const LazyApprovedTab = lazy(() => import("./tabs/ApprovedTab/ApprovedTab"));
// const LazyRejectedTab = lazy(() => import("./tabs/RejectedTab/RejectedTab"));
// const LazySnoozedTab = lazy(() => import("./tabs/SnoozedTab/SnoozedTab"));
// const LazyPendingTab = lazy(() => import("./tabs/PendingTab/PendingTab"));
// const LazyDoneTab = lazy(() => import("./tabs/DoneTab/DoneTab"));

const tabs = [
  {
    label: "TRAX.STATUS_TAB.NEW_UPDATES",
    content: "NEW_UPDATES",
  },
  {
    label: "TRAX.STATUS_TAB.APPROVED",
    content:  "NEW_UPDATES",
  },
  {
    label: "TRAX.STATUS_TAB.REJECTED",
    content:  "NEW_UPDATES",
  },
  {
    label: "TRAX.STATUS_TAB.SNOOZED",
    content:  "NEW_UPDATES",
    hidden: false,
  },
  {
    label: "TRAX.STATUS_TAB.PENDING",
    content:  "NEW_UPDATES",
  },
  {
    label: "TRAX.STATUS_TAB.DONE",
    content:  "NEW_UPDATES",
  },
];

const TraxStatusTabs: React.FC<TraxStatusTabsProps> = ({}) => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = React.useState(0);
  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        gap: "20px",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          borderBottom: "1px solid #E9EDF2",
        }}
      >
        <Stack direction="row" gap="20px" alignItems={"center"}>
          <TabsWithState
            ariaLabel="Trax status tabs"
            value={tabValue}
            onChange={(event, newValue) => setTabValue(newValue)}
            tabIndicatorColor="#008D4C"
            sx={{
              maxWidth: "max-content",
              gap: "25px",
              borderBottom: "0px solid #E9EDF2",
              "& .css-1aquho2-MuiTabs-indicator": {
                bottom: "0px !important",
              },
            }}
          >
            {tabs
              .filter((item) => item.hidden !== true)
              .map((tab, index) => (
                <StyledTab
                  style={{
                    font: "normal normal 300 15px/19px Source Serif Pro",
                    padding: "10px 0px",
                  }}
                  key={index}
                  disableRipple
                  // label={tab.label}
                  label={t(tab.label)}
                  {...a11yProps(index)}
                />
              ))}
          </TabsWithState>

          <IconButton>
            <SettingsIcon w={15} />
          </IconButton>
        </Stack>
      </Stack>

      {tabs
        .filter((item) => item.hidden !== true)
        .map((tab, index) => (
          <TabPanel
            style={{ display: "contents" }}
            key={index}
            value={tabValue}
            index={index}
          >
            <Suspense fallback={<TabLoader />}>{tab.content}</Suspense>
          </TabPanel>
        ))}
    </Stack>
  );
};

export default TraxStatusTabs;
