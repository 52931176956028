import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  Modal,
  Stack,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import AddLinkIcon from "@mui/icons-material/AddLink";
import React, { useEffect, useState } from "react";
import { XCircleIcon } from "../../../images";
import { EmailRichTextMenu } from "../EmailRichTextMenu";
import { SendMessageButton } from "../Buttons";
import { SendMessageButtonColor } from "../Buttons/AllButtonProps";
import styles from "./EmailSmallerPopout.module.css";
import { DropdownWithImageBadge } from "../DropdownWithImageBadge";
import { DropdownWithImgBadgeInterface } from "../DropdownWithImageBadge/DropdownWithImageBadgeTypes";
import { SnoozeEmail } from "../../pages/Email/components";
import axios from "axios";
import { baseURL } from "../../../redux/middleware/apiMiddleware";
import { AttechmentViewContainer } from "../AttechmentViewContainer";
import { AttachmentWithIconSmall } from "../AttachmentWithIconSmall";
import { getAccessToken, getToken } from "../LocalStorage";
import { getUser } from "../../../Utils";

interface EmailSmallerPopoutProps {
  onCloseClick?: () => void;
  emailData: any;
  popupType?: any;
}

interface MessageData {
  attachments: any[];
  accessToken: string;
  to: string[];
  subject: string;
  content: string;
  cc: string[];
  bcc: string[];
  scheduledTime: string | null;
}

interface EmailAddress {
  name: string;
  address: string;
}

interface Recipient {
  emailAddress: EmailAddress;
}

const EmailSmallerPopout: React.FC<EmailSmallerPopoutProps> = ({
  onCloseClick,
  emailData,
  popupType,
}) => {
  const [open, setOpen] = React.useState(false);
  const [userListData, setListData] = useState<DropdownWithImgBadgeInterface[]>(
    []
  );
  const [ccShow, setCcShow] = useState<boolean>(false);
  const [bccShow, setBccShow] = useState<boolean>(false);
  const [msgData, setData] = useState<MessageData>({
    attachments: [],
    accessToken: "",
    to: [],
    subject: "",
    content: "",
    cc: [],
    bcc: [],
    scheduledTime: null,
  });
  const token = getToken();
  const accessToken = getAccessToken();
  const typeStyle = { font: "normal normal 900 14px/18px Source Serif Pro" };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files) {
      const filesArray = Array.from(files);
      setData((prev) => ({
        ...prev,
        attachments: [...prev.attachments, ...filesArray],
      }));
    }
  };

  const handleAddAttachmentClick = () => {
    document.getElementById("file-input")?.click();
  };

  const [loader, setLoader] = useState<boolean>(false);

  const handleSendEmail = async () => {
    try {
      const formData = new FormData();

      if (msgData?.attachments?.length > 0) {
        msgData?.attachments?.forEach((attachment, index) => {
          formData.append(`attachments[${index}]`, attachment);
        });
      } else {
        formData.append("attachments", JSON.stringify([])); // Send an empty array as JSON string when no attachments
      }
      formData.append("accessToken", msgData.accessToken);
      formData.append("to", JSON.stringify(msgData.to));
      formData.append("subject", msgData.subject);
      formData.append("content", msgData.content);
      formData.append("cc", JSON.stringify(msgData.cc));
      formData.append("bcc", JSON.stringify(msgData.bcc));
      if (msgData.scheduledTime) {
        formData.append("scheduledTime", msgData.scheduledTime);
      }
      setLoader(true);
      const res = await axios.post(`${baseURL}/email/send-email`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (res.status === 200) {
        onCloseClick && onCloseClick();
      }

      return res;
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  const user = getUser();

  useEffect(() => {
    const getUsers = async () => {
      try {
        const res = await axios.get(`${baseURL}/user`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setListData(res?.data?.data);
      } catch (err) {
        console.log(err);
      }
    };
    getUsers();
  }, []);

  useEffect(() => {
    if (popupType === "forward" && emailData?.attachments) {
      setData((prev) => ({
        ...prev,
        attachments: emailData?.attachments?.map((attachment: any) => ({
          name: attachment?.name,
          data: attachment?.data,
        })),
        accessToken,
        subject: `FWD: ${emailData?.subject || ""}`,
        content: emailData?.body?.content || "",
      }));
    } else if (popupType === "reply") {
      setData((prev) => ({
        ...prev,
        accessToken,
        subject: `RE: ${emailData?.subject || ""}`,
        content: emailData?.body.content || "",
      }));
    } else {
      setData((prev) => ({ ...prev, accessToken }));
    }
  }, [emailData, popupType]);

  const ConditionallyDisplayMsgTitle = () => {
    if (popupType === "forward") {
      return "Forward";
    } else if (popupType === "reply") {
      return "Reply";
    } else {
      return "New Message";
    }
  };

  const ConditionallyDisplayFrom1 = () => {
    if (popupType === "forward") {
      return `${emailData?.toRecipients?.[0]?.emailAddress?.name}`;
      // || emailData?.sender?.emailAddress?.name
    } else if (popupType === "reply") {
      return `${emailData?.toRecipients?.[0]?.emailAddress?.name}`;
      // || emailData?.sender?.emailAddress?.name;
    } else {
      return user?.data?.firstName + " " + user?.data?.lastName;
    }
  };
  const ConditionallyDisplayFrom2 = () => {
    if (popupType === "forward") {
      return `${emailData?.toRecipients?.[0]?.emailAddress?.address}`;
      // || emailData?.sender?.emailAddress?.address;
    } else if (popupType === "reply") {
      return `${emailData?.toRecipients?.[0]?.emailAddress?.address}`;
      // || emailData?.sender?.emailAddress?.address;
    } else {
      return user?.data?.email;
    }
  };

  const ConditionallyDisplayFrom3 = () => {
    if (popupType === "forward") {
      return emailData?.sender?.avatar;
    } else if (popupType === "reply") {
      return emailData?.sender?.avatar;
    } else {
      return user?.data?.avatar;
    }
  };

  function formatDate(dateString: string): string {
    const date = new Date(dateString);

    const options: Intl.DateTimeFormatOptions = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );

    return `On ${formattedDate}`;
  }

  const ConditionallyDisplayBody = () => {
    if (popupType === "forward" || popupType === "reply") {
      const formattedtime = formatDate(emailData?.createdDateTime);
      const nameEMail = `${emailData?.toRecipients?.[0]?.emailAddress?.name} <${emailData?.toRecipients?.[0]?.emailAddress?.address}>`;
      const subject = emailData?.subject;
      const toList = emailData?.toRecipients
        ?.map((recipient: Recipient) => recipient.emailAddress.name)
        ?.join(", ");

      return (
        <>
          <p>
            <i>
              {popupType === "reply" ? (
                <small>{`${formattedtime} ${nameEMail} Wrote:`}</small>
              ) : (
                <small>
                  ---------- Forwarded message ---------
                  <br />
                  From: {nameEMail}
                  <br />
                  Date: {formattedtime}
                  <br />
                  Subject: {subject}
                  <br />
                  To: {toList}
                  <br />
                </small>
              )}
            </i>
          </p>

          <iframe
            id="emailPreviewIframe"
            srcDoc={emailData?.body?.content}
            style={{ width: "100%", height: "100%", border: "none" }}
            title="Email Preview"
          />
        </>
      );
    } else if (popupType === "compose") {
      return (
        <TextareaAutosize
          placeholder="Type your text messages here"
          minRows={8}
          style={{
            color: "black",
            scrollbarWidth: "none", // Hide scrollbar in Firefox
            flexGrow: 1, // Allow textarea to grow
            resize: "none", // Disable resizing
            border: "0px", // Remove border
            padding: "0px 10px 0px 10px", // Remove padding
            outline: "none", // Remove outline
            font: "normal normal 300 13px/16px Source Serif Pro", // Font style
            overflow: "hidden", // Hide any overflow in other directions
          }}
          value={msgData?.content}
          onChange={(e) => setData((n) => ({ ...n, content: e.target.value }))}
        />
      );
    }
  };

  const RenderAttachment = () => {
    return (
      <AttechmentViewContainer>
        {msgData?.attachments?.map((file, index) => (
          <AttachmentWithIconSmall
            key={index}
            fileName={file?.name}
            showDeleteButton
            onDeleteButtonClick={() => {
              setData((prev) => ({
                ...prev,
                attachments: prev?.attachments?.filter((_, i) => i !== index),
              }));
            }}
          />
        ))}
        <input
          type="file"
          id="file-input"
          style={{ display: "none" }}
          multiple
          onChange={handleFileChange}
        />
        <AddLinkIcon
          sx={{ cursor: "pointer" }}
          onClick={handleAddAttachmentClick}
        />
      </AttechmentViewContainer>
    );
  };

  useEffect(() => {
    const iframe = document.getElementById(
      "emailPreviewIframe"
    ) as HTMLIFrameElement;

    if (iframe) {
      iframe.onload = () => {
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow?.document;
        if (iframeDocument) {
          iframeDocument.designMode = "on";
        }
      };
    }
  }, [emailData?.body?.content]);

  const toName = emailData?.toRecipients?.[0]?.emailAddress?.name;
  const toMail = emailData?.toRecipients?.[0]?.emailAddress?.address;

  const SelectedOpns = (): DropdownWithImgBadgeInterface[] => {
    if (popupType === "reply") {
      return [
        {
          name: toName || "", // Return an empty string if `toName` is null or undefined
          email: toMail || "", // Return an empty string if `toMail` is null or undefined
          userIconSrc: "", // You can add a default icon source or keep it empty
        },
      ];
    }

    return []; // Return an empty array if popupType is not "reply"
  };

  return (
    <Stack className={styles["EmailSmallerPopout"]}>
      {/* ------------ Header Section ------------ */}
      <Stack className={styles["EmailSmallerPopout__header"]}>
        <Typography className={styles["EmailSmallerPopout__title"]}>
          {ConditionallyDisplayMsgTitle()}
          {/* New Message */}
        </Typography>
        <IconButton
          onClick={() => onCloseClick && onCloseClick()}
          className={styles["EmailSmallerPopout__close-btn"]}
          disableRipple
        >
          <XCircleIcon w={15} />
        </IconButton>
      </Stack>

      {/* ------------ Email Body Section ------------ */}
      <Stack
        className={styles["EmailSmallerPopout__content"]}
        sx={{ gap: "20px" }}
      >
        {/* --------- Sender Details Here [FROM] --------- */}
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "start",
            width: "100%",
            gap: "15px",
          }}
        >
          <Typography sx={typeStyle}>From</Typography>
          <Stack sx={{ width: "calc(100% - 50px)" }}>
            <Stack
              sx={{
                width: "100%",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "5px 10px",
                font: "normal normal 300 14px/18px Source Serif Pro",
              }}
            >
              <Avatar
                sx={{ width: "20px", height: "20px" }}
                src={ConditionallyDisplayFrom3()}
                alt={ConditionallyDisplayFrom1()}
              ></Avatar>
              <Typography
                sx={{
                  font: "inherit",
                  maxWidth: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {ConditionallyDisplayFrom1()}
              </Typography>
              <Typography
                sx={{
                  font: "inherit",
                  maxWidth: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {`<${ConditionallyDisplayFrom2()}>`}
                {/* {"<jackiepo@bearishfh.com>"} */}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        {/* --------- Sender Details End --------- */}

        {/* --------- Receiver Details Here --------- */}
        <Stack
          sx={{
            flexDirection: "row",
            gap: "15px",
            justifyContent: "space-between",
            alignItems: "start",
            borderBottom: "1px solid #E9EDF2",
          }}
        >
          <Typography sx={typeStyle}>To</Typography>

          <Stack position={"relative"} top={-7} sx={{ flexGrow: 1 }}>
            <DropdownWithImageBadge
              // selectedOptions={SelectedOpns()}
              initialData={
                popupType === "reply" || popupType === "self-compose"
                  ? [
                      {
                        _id: `${emailData?.sender?.emailAddress?.name + 21}`,
                        name: emailData?.sender?.emailAddress?.name, // Map displayName to name
                        email: emailData?.sender?.emailAddress?.address,
                        displayName: emailData?.sender?.emailAddress?.name, // Optional, if you still need displayName
                      },
                    ]
                  : [] // Empty array if popupType is not "reply"
              }
              options={userListData}
              onChangeSelection={(value) => {
                const emailArray = value?.map((user) => user?.email);
                setData((n) => ({ ...n, to: emailArray }));
              }}
            />
          </Stack>
          <Stack sx={{ flexDirection: "row", gap: "5px" }}>
            {/* Add CC Button */}
            <Tooltip title="Add CC" placement="top" arrow>
              <IconButton
                sx={{ p: 0, borderRadius: 0, color: "black" }}
                disableRipple
                onClick={() => setCcShow(!ccShow)}
              >
                <Typography
                  sx={{ font: "normal normal 300 11px/14px Source Serif Pro" }}
                >
                  CC
                </Typography>
              </IconButton>
            </Tooltip>
            {/* Add BCC Button */}
            <Tooltip title="Add BCC" placement="top" arrow>
              <IconButton
                sx={{ p: 0, borderRadius: 0, color: "black" }}
                disableRipple
                onClick={() => setBccShow(!bccShow)}
              >
                <Typography
                  sx={{ font: "normal normal 300 11px/14px Source Serif Pro" }}
                >
                  BCC
                </Typography>
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>

        {ccShow && (
          <Stack
            sx={{
              flexDirection: "row",
              gap: "15px",
              justifyContent: "space-between",
              alignItems: "start",
              borderBottom: "1px solid #E9EDF2",
            }}
          >
            <Typography sx={typeStyle}>CC</Typography>

            <Stack position={"relative"} top={-7} sx={{ flexGrow: 1 }}>
              <DropdownWithImageBadge
                options={userListData}
                onChangeSelection={(value) => {
                  const emailArray = value.map((user) => user.email);
                  setData((n) => ({ ...n, cc: emailArray }));
                }}
              />
            </Stack>
            <Stack sx={{ flexDirection: "row", gap: "5px" }}></Stack>
          </Stack>
        )}

        {bccShow && (
          <Stack
            sx={{
              flexDirection: "row",
              gap: "15px",
              justifyContent: "space-between",
              alignItems: "start",
              borderBottom: "1px solid #E9EDF2",
            }}
          >
            <Typography sx={typeStyle}>BCC</Typography>

            <Stack position={"relative"} top={-7} sx={{ flexGrow: 1 }}>
              <DropdownWithImageBadge
                options={userListData}
                onChangeSelection={(value) => {
                  const emailArray = value.map((user) => user.email);
                  setData((n) => ({ ...n, bcc: emailArray }));
                }}
              />
            </Stack>
            <Stack sx={{ flexDirection: "row", gap: "5px" }}></Stack>
          </Stack>
        )}
        {/* --------- Receiver Details End --------- */}

        {/* --------- Subject Input Here --------- */}
        <Stack
          sx={{
            flexDirection: "row",
            gap: "10px",
            borderBottom: "0.5px solid #E9EDF2",
            paddingBottom: "3px",
          }}
        >
          <Typography sx={typeStyle}>Subject</Typography>
          <TextareaAutosize
            placeholder="Subject of email"
            maxRows={2}
            style={{
              flexGrow: 1,
              resize: "none",
              border: "0px",
              outline: "none",
              padding: 0,
              scrollbarWidth: "none",
              font: "normal normal 300 14px/18px Source Serif Pro",
            }}
            value={msgData?.subject}
            onChange={(e) =>
              setData((n) => ({ ...n, subject: e.target.value }))
            }
          />
        </Stack>
        {/* --------- Subject Input End --------- */}

        {/* --------- Email Content Area Here --------- */}
        <Stack sx={{ flex: 1 }}>
          {/* {true ? <iframe
            srcDoc={emailData?.body?.content}
            style={{ width: '100%', height: '100%', border: 'none' }}
            title="Email Preview"
          /> : <TextareaAutosize
            placeholder="Type your text messages here"
            minRows={8}
            style={{
              color: "black",
              scrollbarWidth: "none", // Hide scrollbar in Firefox
              flexGrow: 1, // Allow textarea to grow
              resize: "none", // Disable resizing
              border: "0px", // Remove border
              padding: "0px 10px 0px 10px", // Remove padding
              outline: "none", // Remove outline
              font: "normal normal 300 13px/16px Source Serif Pro", // Font style
              overflow: "hidden", // Hide any overflow in other directions
            }}
            value={msgData?.content}
            onChange={(e) => setData((n) => ({ ...n, content: e.target.value }))}
          />} */}

          {ConditionallyDisplayBody()}
        </Stack>
        {/* --------- Email Content Area End --------- */}

        {/* --------- Attachment Container Here --------- */}
        <Stack sx={{ gap: "10px" }}>
          <Typography
            sx={{ font: "normal normal 300 14px/18px Source Serif Pro" }}
          >
            {msgData?.attachments?.length > 0 && `Attachments`}
          </Typography>
          {RenderAttachment()}
        </Stack>
        {/* --------- Attachment Container End --------- */}
      </Stack>
      {/* ------------ Email Body End ------------ */}

      {/* ------------ Footer Section ------------ */}
      <Stack className={styles["EmailSmallerPopout__footer"]}>
        <EmailRichTextMenu />
        <SendMessageButton
          buttonSize={90}
          onClick={() => handleOpen()}
          text={"Schedule"}
          colorVarient={SendMessageButtonColor.Blue}
        />
        {loader ? (
          <>
            <CircularProgress />
          </>
        ) : (
          <SendMessageButton
            onClick={() => handleSendEmail()}
            buttonSize={41}
            colorVarient={SendMessageButtonColor.Blue}
          />
        )}
      </Stack>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <SnoozeEmail variant="ScheduleEmail" />
        </Box>
      </Modal>
      {/* ------------ Footer Section End ------------ */}
    </Stack>
  );
};

export default EmailSmallerPopout;
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  boxShadow: 24,
};
