import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import styles from "./NotesCard.module.css";
import { AvatarNormal } from "../AvatarNormal";

// New structure of props based on the data you provided
interface NotesCardProps {
  cardTitleIcon: string | React.ElementType;
  cardTitleText: string;
  note?: {
    title: string;
    content: string;
    createdAt: string;
    updatedAt: string;
    author: {
      _id: string;
      firstName: string;
      lastName: string;
      avatar: string;
    };
  };
  notesTitle?: string;
  notesDescription?: string;
  userName?: string;
  userIcon?: string;
  dateStamp?: string;
}

const NotesCard: React.FC<NotesCardProps> = ({
  cardTitleIcon,
  cardTitleText,
  note, // Destructure the note object
}) => {
  const {
    title = "",
    content = "",
    createdAt = "",
    author = { _id: "", firstName: "", lastName: "", avatar: "" },
  } = note || {};

  return (
    <Stack className={styles["notesCard"]}>
      <Stack className={styles["notesCard__content"]}>
        <Stack className={styles["notesCard__header"]}>
          {typeof cardTitleIcon === "string" ? (
            <Box
              component="img"
              src={cardTitleIcon || ""}
              alt="logo"
              className={styles["notesCard__logo"]}
            />
          ) : (
            React.createElement(cardTitleIcon, { w: 20 })
          )}

          <Typography className={styles["notesCard__platformName"]}>
            {cardTitleText}
          </Typography>
        </Stack>
        <Stack className={styles["notesCard__body"]}>
          <Typography className={styles["notesCard__noteName"]}>
            {title} {/* Display the note title */}
          </Typography>
          <Stack className={styles["notesCard__description"]}>
            <Typography className={styles["notesCard__descriptionText"]}>
              {content} {/* Display the note content */}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack className={styles["notesCard__dividerContainer"]}>
        <Stack className={styles["notesCard__divider"]} />
      </Stack>
      <Stack className={styles["notesCard__footer"]}>
        <Stack className={styles["notesCard__userInfo"]}>
          <AvatarNormal
            size={20}
            username={`${author?.firstName || ""} ${author?.lastName || ""}`} // Combine firstName and lastName
            imgSrc={author?.avatar || ""} // Use author's avatar
          />
          <Typography
            className={styles["notesCard__userName"]}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "200px",
            }}
          >
            {`${author?.firstName || ""} ${author?.lastName || ""}`}{" "}
            {/* Display author's full name */}
          </Typography>
        </Stack>
        <Typography className={styles["notesCard__date"]}>
          {new Date(createdAt).toLocaleString()}{" "}
          {/* Display formatted creation date */}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default NotesCard;
