import {
  AirplaneIcon,
  BikeCycleIcon,
  BirthdayCakeIcon,
  BookAlbumIcon,
  Briefcase2Icon,
  BuildingIcon,
  BusIcon,
  CallIcon,
  CarIcon,
  CreditCardIcon,
  DeadlineIcon,
  FundingIcon,
  GymIcon,
  HomeIcon,
  MarketingIcon,
  MealIcon,
  MedicalBottleIcon,
  MovieMediaPlayerIcon,
  MusicIcon,
  ProgressIcon,
  RecordingIcon,
  ShoppingCardIcon,
  SoccerBallIcon,
  StarIcon,
  TeamMemberIcon,
  TravelIcon,
  TruckIcon,
  WrenchIcon,
} from "../../../../../images";

export enum IconPopupCalendarIconsEnum {
  StarIcon = "StarIcon",
  TravelIcon = "TravelIcon",
  AirplaneIcon = "AirplaneIcon",
  SoccerBallIcon = "SoccerBallIcon",
  BuildingIcon = "BuildingIcon",
  ProgressIcon = "ProgressIcon",
  DeadlineIcon = "DeadlineIcon",
  BusIcon = "BusIcon",
  BikeCycleIcon = "BikeCycleIcon",
  MedicalBottleIcon = "MedicalBottleIcon",
  FundingIcon = "FundingIcon",
  MarketingIcon = "MarketingIcon",
  GymIcon = "GymIcon",
  MovieMediaPlayerIcon = "MovieMediaPlayerIcon",
  HomeIcon = "HomeIcon",
  RecordingIcon = "RecordingIcon",
  CallIcon = "CallIcon",
  WrenchIcon = "WrenchIcon",
  CreditCardIcon = "CreditCardIcon",
  BriefcaseIcon = "BriefcaseIcon",
  BookAlbumIcon = "BookAlbumIcon",
  CarIcon = "CarIcon",
  MealIcon = "MealIcon",
  MusicIcon = "MusicIcon",
  ShoppingCardIcon = "ShoppingCardIcon",
  TruckIcon = "TruckIcon",
  BirthdayCakeIcon = "BirthdayCakeIcon",
  TeamMemberIcon = "TeamMemberIcon",
}

export interface IconPopupCalendarListInterface {
  id: number | string;
  iconName: IconPopupCalendarIconsEnum;
}

// List with id and value
export const IconPopupCalendarList: IconPopupCalendarListInterface[] = [
  { id: 1, iconName: IconPopupCalendarIconsEnum.StarIcon },
  { id: 2, iconName: IconPopupCalendarIconsEnum.TravelIcon },
  { id: 3, iconName: IconPopupCalendarIconsEnum.AirplaneIcon },
  { id: 4, iconName: IconPopupCalendarIconsEnum.SoccerBallIcon },
  { id: 5, iconName: IconPopupCalendarIconsEnum.BuildingIcon },
  { id: 6, iconName: IconPopupCalendarIconsEnum.ProgressIcon },
  { id: 7, iconName: IconPopupCalendarIconsEnum.DeadlineIcon },
  { id: 8, iconName: IconPopupCalendarIconsEnum.BusIcon },
  { id: 9, iconName: IconPopupCalendarIconsEnum.BikeCycleIcon },
  { id: 10, iconName: IconPopupCalendarIconsEnum.MedicalBottleIcon },
  { id: 11, iconName: IconPopupCalendarIconsEnum.FundingIcon },
  { id: 12, iconName: IconPopupCalendarIconsEnum.MarketingIcon },
  { id: 13, iconName: IconPopupCalendarIconsEnum.GymIcon },
  { id: 14, iconName: IconPopupCalendarIconsEnum.MovieMediaPlayerIcon },
  { id: 15, iconName: IconPopupCalendarIconsEnum.HomeIcon },
  { id: 16, iconName: IconPopupCalendarIconsEnum.RecordingIcon },
  { id: 17, iconName: IconPopupCalendarIconsEnum.CallIcon },
  { id: 18, iconName: IconPopupCalendarIconsEnum.WrenchIcon },
  { id: 19, iconName: IconPopupCalendarIconsEnum.CreditCardIcon },
  { id: 20, iconName: IconPopupCalendarIconsEnum.BriefcaseIcon },
  { id: 21, iconName: IconPopupCalendarIconsEnum.BookAlbumIcon },
  { id: 22, iconName: IconPopupCalendarIconsEnum.CarIcon },
  { id: 23, iconName: IconPopupCalendarIconsEnum.MealIcon },
  { id: 24, iconName: IconPopupCalendarIconsEnum.MusicIcon },
  { id: 25, iconName: IconPopupCalendarIconsEnum.ShoppingCardIcon },
  { id: 26, iconName: IconPopupCalendarIconsEnum.TruckIcon },
  { id: 27, iconName: IconPopupCalendarIconsEnum.BirthdayCakeIcon },
  { id: 28, iconName: IconPopupCalendarIconsEnum.TeamMemberIcon },
];

// Function to get the icon component based on the enum value
export const getIconPopupCalendarIcons = (
  iconName: IconPopupCalendarIconsEnum
): React.ElementType => {
  switch (iconName) {
    case IconPopupCalendarIconsEnum.StarIcon:
      return StarIcon;
    case IconPopupCalendarIconsEnum.TravelIcon:
      return TravelIcon;
    case IconPopupCalendarIconsEnum.AirplaneIcon:
      return AirplaneIcon;
    case IconPopupCalendarIconsEnum.SoccerBallIcon:
      return SoccerBallIcon;
    case IconPopupCalendarIconsEnum.BuildingIcon:
      return BuildingIcon;
    case IconPopupCalendarIconsEnum.ProgressIcon:
      return ProgressIcon;
    case IconPopupCalendarIconsEnum.DeadlineIcon:
      return DeadlineIcon;
    case IconPopupCalendarIconsEnum.BusIcon:
      return BusIcon;
    case IconPopupCalendarIconsEnum.BikeCycleIcon:
      return BikeCycleIcon;
    case IconPopupCalendarIconsEnum.MedicalBottleIcon:
      return MedicalBottleIcon;
    case IconPopupCalendarIconsEnum.FundingIcon:
      return FundingIcon;
    case IconPopupCalendarIconsEnum.MarketingIcon:
      return MarketingIcon;
    case IconPopupCalendarIconsEnum.GymIcon:
      return GymIcon;
    case IconPopupCalendarIconsEnum.MovieMediaPlayerIcon:
      return MovieMediaPlayerIcon;
    case IconPopupCalendarIconsEnum.HomeIcon:
      return HomeIcon;
    case IconPopupCalendarIconsEnum.RecordingIcon:
      return RecordingIcon;
    case IconPopupCalendarIconsEnum.CallIcon:
      return CallIcon;
    case IconPopupCalendarIconsEnum.WrenchIcon:
      return WrenchIcon;
    case IconPopupCalendarIconsEnum.CreditCardIcon:
      return CreditCardIcon;
    case IconPopupCalendarIconsEnum.BriefcaseIcon:
      return Briefcase2Icon;
    case IconPopupCalendarIconsEnum.BookAlbumIcon:
      return BookAlbumIcon;
    case IconPopupCalendarIconsEnum.CarIcon:
      return CarIcon;
    case IconPopupCalendarIconsEnum.MealIcon:
      return MealIcon;
    case IconPopupCalendarIconsEnum.MusicIcon:
      return MusicIcon;
    case IconPopupCalendarIconsEnum.ShoppingCardIcon:
      return ShoppingCardIcon;
    case IconPopupCalendarIconsEnum.TruckIcon:
      return TruckIcon;
    case IconPopupCalendarIconsEnum.BirthdayCakeIcon:
      return BirthdayCakeIcon;
    case IconPopupCalendarIconsEnum.TeamMemberIcon:
      return TeamMemberIcon;
    default:
      return StarIcon;
  }
};
