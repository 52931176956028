import { Avatar, Box, Stack, Typography } from "@mui/material";
import React from "react";
import { EditEmployeeThreeDot } from "../EditEmployeeThreeDot";
import { DummyImage } from "../../../../../images";
import { ContactDetailsView, DetailsViewArea } from "./view";

interface UserDetailsAreaProps {
  hideSocialButtons?: boolean;
}

const UserDetailsArea: React.FC<UserDetailsAreaProps> = ({
  hideSocialButtons = false,
}) => {
  return (
    <Stack
      sx={{
        width: "100%",
        gap: "20px",
        boxSizing: "border-box",
        bgcolor: "white",
      }}
    >
      <Typography sx={{ font: "normal normal 400 15px/19px Source Serif Pro" }}>
        Details
      </Typography>

      <Stack sx={{ borderRadius: "5px", border: "1px solid #E9EDF2" }}>
        {/* upper component */}
        <Stack
          sx={{
            height: "75px",
            boxSizing: "border-box",
            padding: "10px",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "start",
            borderBottom: "1px solid #E9EDF2",
            position: "relative",
          }}
        >
          <ContactDetailsView
            companyName="Google"
            emailId="contactemail@email.com"
            imageSrc={DummyImage}
            phoneNumber="123-456-789-000"
            hideSocialButtons={hideSocialButtons}
          />
          <span style={{ position: "absolute", right: "15px" }}>
            <EditEmployeeThreeDot />
          </span>
        </Stack>

        {/* lower component */}
        <Stack sx={{ flexDirection: "row", justifyContent: "space-between" }}>
          <DetailsViewArea label="Current Time">
            <Typography
              sx={{ font: "normal normal 400 13px/16px Source Serif Pro" }}
            >
              01:55 pm (CET)
            </Typography>
          </DetailsViewArea>

          <DetailsViewArea label="Location">
            <Avatar
              src={DummyImage}
              sx={{
                height: "20px",
                width: "20px",
                border: "1px solid #E9EDF2",
                boxSizing: "border-box",
              }}
            />
            <Typography
              sx={{ font: "normal normal 400 13px/16px Source Serif Pro" }}
            >
              Madrid
            </Typography>
          </DetailsViewArea>

          <DetailsViewArea label="Department">
            <Box
              sx={{
                height: "10px",
                width: "10px",
                borderRadius: "10px",
                bgcolor: "#008D4C",
                boxSizing: "border-box",
                flexShrink: 0,
              }}
            />
            <Typography
              sx={{ font: "normal normal 400 13px/16px Source Serif Pro" }}
            >
              R&D
            </Typography>
          </DetailsViewArea>

          <DetailsViewArea label="Team" hideBorderRight>
            <Typography
              sx={{
                font: "normal normal 400 13px/16px Source Serif Pro",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              Joe’s Team
            </Typography>
          </DetailsViewArea>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default UserDetailsArea;
