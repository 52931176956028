import React from "react";
import { Box, Typography, Button, Grid } from "@mui/material";

const PlansDetail = () => {
  return (
    <Box sx={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      {/* Main Plans Section */}
      <Typography
        variant="h4"
        sx={{
          textAlign: "center",
          fontFamily: "Source Serif Pro, serif",
          marginBottom: "5px",
        }}
      >
        Empower Your Team with Advanced Tools
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          textAlign: "center",
          fontFamily: "Source Serif Pro, serif",
          marginBottom: "20px",
          color: "gray",
        }}
      >
        Select a plan that best matches your organization's goals and workflow
      </Typography>
      <Grid container justifyContent="center" spacing={2} wrap="wrap">
        {mainPlans.map((plan) => (
          <Grid item xs={12} sm={6} md={4} lg={3.7} key={plan.title}>
            <Box
              sx={{
                border: "1px solid #ddd",
                borderRadius: "8px",
                padding: "20px",
                textAlign: "center",
                boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
                fontFamily: "Source Serif Pro",
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Typography
                variant="h5"
                color={plan.color}
                sx={{
                  fontWeight: "bold",
                  mb: 1,
                  fontFamily: "Source Serif Pro",
                }}
              >
                {plan.title}
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "bold",
                  fontFamily: "Source Serif Pro",
                  my: 1,
                }}
              >
                {plan.price}
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontFamily: "Source Serif Pro" }}
                color="textSecondary"
              >
                {plan.priceDescription}
              </Typography>
              <Box
                component="ul"
                sx={{
                  listStyle: "none",
                  padding: 0,
                  textAlign: "left",
                  mt: 2,
                  mb: "auto",
                }}
              >
                {plan.features.map((feature, index) => (
                  <Typography
                    component="li"
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontFamily: "Source Serif Pro",
                      mb: 1,
                    }}
                  >
                    <span style={{ color: "green", marginRight: "5px" }}>
                      ✔
                    </span>
                    {feature}
                  </Typography>
                ))}
              </Box>
              <Button
                variant="contained"
                sx={{
                  mt: 3,
                  backgroundColor: plan.buttonColor,
                  color: "#fff",
                  ":hover": { backgroundColor: plan.buttonColor },
                  fontFamily: "Source Serif Pro, serif",
                }}
              >
                {plan.buttonText}
              </Button>
            </Box>
          </Grid>
        ))}
      </Grid>

      {/* Add-On Plans Section */}
      <Typography
        variant="h4"
        sx={{
          textAlign: "center",
          fontFamily: "Source Serif Pro, serif",
          marginTop: "40px",
          marginBottom: "20px",
        }}
      >
        Light Features or Enrich Existing Plans with Add-Ons
      </Typography>
      <Grid container justifyContent="center" spacing={2} wrap="wrap">
        {addOns.map((addOn) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={addOn.title}>
            <Box
              sx={{
                border: "1px solid #ddd",
                borderRadius: "8px",
                padding: "20px",
                textAlign: "center",
                boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
                fontFamily: "Source Serif Pro",
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Typography
                variant="h6"
                color={addOn.color}
                sx={{
                  fontWeight: "bold",
                  fontFamily: "Source Serif Pro",
                  mb: 1,
                }}
              >
                {addOn.title}
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "bold",
                  fontFamily: "Source Serif Pro",
                  my: 1,
                }}
              >
                {addOn.price}
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontFamily: "Source Serif Pro" }}
                color="textSecondary"
              >
                {addOn.priceDescription}
              </Typography>
              <Box
                component="ul"
                sx={{
                  listStyle: "none",
                  padding: 0,
                  textAlign: "left",
                  mt: 2,
                  mb: "auto",
                }}
              >
                {addOn.features.map((feature, index) => (
                  <Typography
                    component="li"
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontFamily: "Source Serif Pro",
                      mb: 1,
                    }}
                  >
                    <span style={{ color: "green", marginRight: "5px" }}>
                      ✔
                    </span>
                    {feature}
                  </Typography>
                ))}
              </Box>
              <Button
                variant="contained"
                sx={{
                  mt: 3,
                  backgroundColor: addOn.buttonColor,
                  color: "#fff",
                  ":hover": { backgroundColor: addOn.buttonColor },
                  fontFamily: "Source Serif Pro, serif",
                }}
              >
                {addOn.buttonText}
              </Button>
              <Typography
                variant="caption"
                sx={{ color: "#777", fontFamily: "Source Serif Pro", mt: 1 }}
              >
                {addOn.note}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

// Plan data
const mainPlans = [
  {
    title: "Bearish Cub 365",
    price: "$21.99",
    priceDescription: "Per User Per Month",
    color: "#003366",
    features: [
      "Supercharged Email Client",
      "Crystal Clear Video Calls",
      "Project Management",
      "Docs, Decks, Grids, & more",
      "Relationship Manager",
      "Unlimited Guests Free",
      "20+ Intelligently Connections",
    ],
    buttonColor: "#003366",
    buttonText: "Start FREE Now",
  },
  {
    title: "Bearish OS 365",
    price: "$69.99",
    priceDescription: "Per User Per Month",
    color: "#006633",
    features: [
      "Everything in Cub 365",
      "BEBA AI, Generate & Analyze",
      "Intelligent Connections+",
      "Granular Time Tracking",
      "Unlimited CRM Groups",
      "Screen Recording",
      "Document Analytics",
      "Attachment Analytics",
      "Call Recordings",
      "Schedule Links",
      "Real-Time Editing",
      "Template Snippets",
    ],
    buttonColor: "#006633",
    buttonText: "Start FREE Now",
  },
  {
    title: "Relationships 365",
    price: "$14.00",
    priceDescription: "Per User Per Month",
    color: "#CC3300",
    features: [
      "Relationship Manager",
      "Unlimited Contacts",
      "Unlimited Groups",
      "Unlimited Deals",
      "Unlimited Guests",
      "20+ Intelligently Connections",
      "Contact Enrichment",
      "Limited BEBA AI",
      "20 GB Cloud Storage",
      "Document Open Rates",
      "Basic Analytics",
      "Basic Email Client",
    ],
    buttonColor: "#CC3300",
    buttonText: "Start FREE Now",
  },
  {
    title: "Bearish HR 365",
    price: "$22.00",
    priceDescription: "Per User Per Month",
    color: "#663399",
    features: [
      "HR 365",
      "Company Directory",
      "Team Knowledge Hub",
      "Team Time Tracking",
      "Document Management",
      "Document Analytics",
      "Document Version Control",
      "Team Engagement Surveys",
      "Organizational Surveys",
      "Time Logging",
      "Leave Management",
      "20 GB Cloud Storage",
    ],
    buttonColor: "#663399",
    buttonText: "Start FREE Now",
  },
  {
    title: "Communicate 365",
    price: "$19.00",
    priceDescription: "Per User Per Month",
    color: "#FFCC33",
    features: [
      "Communicate 365",
      "Crystal Clear Video Calls",
      "AI Call Transcriptions",
      "Integrated Call Notes",
      "Intelligent Email Client",
      "Email Read Receipts",
      "Email Analytics",
      "Team Chat",
      "Rich Text & Audio Chats",
      "Screen Recording",
      "20+ Intelligent Connections",
      "20 GB Cloud Storage",
    ],
    buttonColor: "#FFCC33",
    buttonText: "Start FREE Now",
  },
];
// Add-On Plans Data
const addOns = [
  {
    title: "Projects: Plan & Execute",
    price: "$3.99",
    priceDescription: "Per User Per Month",
    color: "#663399",
    features: [
      "Unlimited Lists, Checklists, Boards",
      "Unlimited Docs, Wikis, Decks & Grids",
      "Rich Comments and Analytics",
      "Unlimited Free Guests",
      "Limited Screen Recording",
    ],
    buttonColor: "#003366",
    buttonText: "Add On",
    note: "Can be added to any Bearish OS plan or be purchased independently for unlimited Plan, Track & Execute with limited storage",
  },
  {
    title: "BEBA AI",
    price: "$15.00",
    priceDescription: "Per User Per Month",
    color: "#CC0000",
    features: [
      "Generate Text and Images",
      "Summarize and Consolidate Text",
      "Analyze and Understand Text",
      "Chat with AI, PDFs, Files, and Bearish OS",
      "More features added weekly...",
    ],
    buttonColor: "#CC0000",
    buttonText: "Add On",
    note: "BEBA AI can be purchased as an add-on to most plans or can be purchased to connect with your existing Tech stack",
  },
  {
    title: "Smart Cloud & Analytics",
    price: "$8.99",
    priceDescription: "Per User Per Month",
    color: "#003366",
    features: [
      "100 GB of Premium Cloud Storage",
      "Link Tracking and Asset Analytics",
      "Media and Content Manager",
      "Robust Automatic AI Asset Tagging",
      "Universal Asset Search",
    ],
    buttonColor: "#003366",
    buttonText: "Add On",
    note: "Powerful Cloud Storage, Document Analytics, Media and Content Manager with universal search and automatic tagging",
  },
];

export default PlansDetail;
