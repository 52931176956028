import React, { useState } from "react";
import { Box, Divider, Stack } from "@mui/material";
import { TotalCRM } from "../components";
import { DealTable } from "./components";
import {
 
  CRM_sortOrFilterMenuList,
  DUMMY_CRM_COMPANIES_LIST,
} from "../common";
import {
  DropdownButtonAllCompanies,
  DropdownButtonCSV,
  DropdownButtonFilterMenu,
  DropdownButtonSortMenu,
  DropdownButtonVisibleMenu,
  SwitchSmall,
} from "../../../common";
import { ViewMode } from "../../Projects/types";
import { DealStageMap } from "./DealStageMap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  FilterItem,
  recallAPI__CRM,
  updateFilterKey,
  updateSortingKey,
} from "../../../../redux";
import { DealsIcon } from "../../../../images";

// Define the props for the Deal component
interface DealProps {}

const Deal: React.FC<DealProps> = () => {
  const [ViewMode, setViewMode] = useState<ViewMode>("square");
  const crmState = useSelector((state: RootState) => state && state?.crm);
  const { dealList } = crmState || {};

  const dispatch = useDispatch();

  const handleSortSelection = (sortSelection: any) => {
    // Assume sortSelection is an array of objects similar to the provided JSON data
    sortSelection?.forEach((item: any) => {
      const field = CRM_sortOrFilterMenuList?.find(
        (option: any) => option?.label === item?.selectedItem?.label
      )?.value;
      const order = item?.order?.toLowerCase() === "ascending" ? "asc" : "desc";

      if (field) {
        dispatch(
          updateSortingKey({
            section: "deals", // This needs to be dynamic based on your actual CRM sections
            sortConfig: { field, order },
          })
        );
      }
    });

    setTimeout(() => {
      dispatch(recallAPI__CRM("getAllDeals"));
    }, 1000); // 1000 milliseconds delay
  };
  const handleFilterUpdate = (filters: FilterItem[]) => {
    filters?.forEach((filter: FilterItem) => {
      const { selectedItem, operator, filterInput, condition } = filter;
      const field = selectedItem.value; // Use the value as the field identifier
      const filterCondition = {
        type: operator, // Corrected from 'operator' to 'type' to match FilterCondition
        value: filterInput || "", // Ensure undefined input is handled as empty string
        conjunction: condition, // Correctly pass the logical operator
      };

      dispatch(
        updateFilterKey({
          section: "deals", // This should be dynamic or correctly set based on your app structure
          filterConfig: {
            field: field,
            conditions: [filterCondition], // Supports adding or updating multiple conditions per field
          },
        })
      );
    });

    setTimeout(() => {
      dispatch(recallAPI__CRM("getAllDeals"));
    }, 1000); // 1000 milliseconds delay
  };

  return (
    <Box sx={{ padding: "10px 0 25px 0" }}>
      <Stack mb={1} p={1} direction={"column"} gap={1.2}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={0.5}
        >
          <Stack direction={"row"} alignItems={"center"} gap={1.5}>
            <DropdownButtonAllCompanies
              buttonLabel="Deals"
              labelIcon={DealsIcon}
              options={DUMMY_CRM_COMPANIES_LIST}
            />
            <TotalCRM label="Total Deals" totalCount={dealList?.length} />
          </Stack>
          <Stack direction={"row"} alignItems={"center"} gap={1.5}>
            <DropdownButtonSortMenu
              buttonColor="CRMOrange"
              sortOption={CRM_sortOrFilterMenuList}
              sortedList={(value: any) => {
                handleSortSelection(value);
              }}
            />
            <DropdownButtonCSV onSelect={(value) => null} />
          </Stack>
        </Stack>
        <Divider />
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={0.5}
        >
          <Stack direction={"row"} alignItems={"center"} gap={1.5}>
            <DropdownButtonSortMenu
              buttonColor="CRMOrange"
              sortOption={CRM_sortOrFilterMenuList}
              sortedList={(value: any) => {
                handleSortSelection(value);
              }}
            />
            <DropdownButtonFilterMenu
              buttonColor="CRMOrange"
              filterOption={CRM_sortOrFilterMenuList}
              filteredList={(list: any) => {
                handleFilterUpdate(list);
              }}
            />
          </Stack>
          <Stack direction={"row"} alignItems={"center"} gap={1.5}>
            <SwitchSmall viewMode={ViewMode} setViewMode={setViewMode} />
          </Stack>
        </Stack>
      </Stack>
      {ViewMode === "square" ? (
        <Stack
          width={"100%"}
          direction={"row"}
          alignItems={"flex-start"}
          gap={0}
        >
          <DealTable />
        </Stack>
      ) : (
        <DealStageMap />
      )}
    </Box>
  );
};

export default Deal;
