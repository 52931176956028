import {Box, Stack} from "@mui/material";
import React, { lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";
import {
  a11yProps,
  StyledTab,
  TabPanel,
  TabsWithState,
} from "../../../../common/CardTabsWithoutIcon";
import {TabLoader} from "../../../../common/TabLoader";

interface VideoCenterTabsProps {}

const LazyNewUpdatesTab = lazy(
  () => import("./tabs/NewUpdatesTab/NewUpdatesTab")
);
const LazyDoneTab = lazy(() => import("./tabs/DoneTab/DoneTab"));
const LazySnoozedTab = lazy(() => import("./tabs/SnoozedTab/SnoozedTab"));

const tabs = [
  {
    label: "PAST_CALL_DETAILS.NEW_UPDATES",
    content: <LazyNewUpdatesTab />,
  },
  {
    label: "MESSAGE.DONE",
    content: <LazyDoneTab />,
  },
  {
    label: "MESSAGE.SNOOZED",
    content: <LazySnoozedTab />,
  },
];

const VideoCenterTabs: React.FC<VideoCenterTabsProps> = ({}) => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = React.useState(0);
  return (

      <Box
          display="flex"
          padding="0 15px 0 15px"
          flexDirection="column"
          width="100%"
      >
          <Box display="flex" justifyContent="end" alignItems="center" p={2}>
              {/*<SearchBar*/}
              {/*    iconSize={15}*/}
              {/*    font="normal normal 400 11px/14px Source Serif Pro"*/}
              {/*    customStyles={{ width: "330px", display: "flex", color: "black" }}*/}
              {/*    containerSx={{ borderRadius: "5px!important" }}*/}
              {/*    placeholder={t("VIDEO_CENTER.MEETING.SEARCH_CALLS")}*/}
              {/*/>*/}
          </Box>
          <Box
              display="flex"
              alignItems="center"
              style={{ padding: "0 20px 0 20px" }}
          >
              <Stack
                  sx={{
                      width: "100%",
                      height: "100%",
                      boxSizing: "border-box",
                      gap: "20px",
                  }}
              >
                  <Stack
                      sx={{
                          flexDirection: "row",
                          alignItems: "center",
                          borderBottom: "1px solid white",
                      }}
                  >
                      <TabsWithState
                          ariaLabel="call center tabs"
                          value={tabValue}
                          onChange={(event, newValue) => setTabValue(newValue)}
                          // tabIndicatorColor="white"
                          sx={{
                              maxWidth: "max-content",
                              gap: "25px",
                              borderBottom: "0px solid #E9EDF2",
                              "& .css-1aquho2-MuiTabs-indicator": {
                                  bottom: "0px !important",
                              },
                          }}
                      >
                          {tabs.map((tab, index) => (
                              <StyledTab
                                  sx={{
                                      font: "normal normal 300 15px/19px Source Serif Pro",
                                      padding: "10px 0px",
                                      // color: "white !important",
                                  }}
                                  key={index}
                                  // disableRipple
                                  label={t(tab.label)}
                                  {...a11yProps(index)}
                              />
                          ))}
                      </TabsWithState>
                  </Stack>

                  {tabs.map((tab, index) => (
                      <TabPanel
                          style={{ display: "contents" }}
                          key={index}
                          value={tabValue}
                          index={index}
                      >
                          <Suspense fallback={<TabLoader />}>{tab.content}</Suspense>
                      </TabPanel>
                  ))}
              </Stack>
          </Box>
      </Box>

  );
};

export default VideoCenterTabs;
