import React, { Suspense } from "react";
import RoutesComponent from "./Router";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18n from "./i18n";
import { LangSwitch, MySnackbar } from "./components/common";
import { Paper, StyledEngineProvider, styled } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { MeetingProvider, PusherProvider } from "./components/Povider";
import { SuspenseState } from "./Utils";

const AppContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  minHeight: "100vh",
  backgroundColor: "gray",
});
const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };
  const currentLanguage = i18n.language;
  return (
    <div style={{ position: "fixed", top: "90px", right: "123px", zIndex:"9999" }}>
      <LangSwitch
        changeLanguage={changeLanguage}
        currentLanguage={currentLanguage}
      />
    </div>
  );
};

const App: React.FC = () => (
  <StyledEngineProvider injectFirst={true}>
    <Suspense fallback={<SuspenseState />}>
      {/* Internationalization provider */}
      <I18nextProvider i18n={i18n}>
        {/* Redux store provider */}
        {/* Language switcher component */}
        {false && <LanguageSwitcher />}
        {/* Router component */}
        <SnackbarProvider maxSnack={5} preventDuplicate>
          <MeetingProvider>
            {/* <LanguageSwitcher /> */}
            <PusherProvider>
              <AppContainer>
                <Paper
                  variant="elevation"
                  sx={{ minHeight: "100vh" }}
                  square={false}
                >
                  <RoutesComponent />
                </Paper>
              </AppContainer>
            </PusherProvider>
          </MeetingProvider>
        </SnackbarProvider>
        {/* Snackbar component */}
        <MySnackbar />
      </I18nextProvider>
    </Suspense>
  </StyledEngineProvider>
);

export default App;
