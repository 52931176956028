import { Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import { CustomCheckboxDropdownMultiple } from "../../../../../../../../common";

interface MultiSelectDropdownProps {
  options: { value: string; color: string }[]; // Options to display
  initialValues?: string[]; // Initial selected values
  onChange?: (selectedLabels: string[]) => void; // Callback to handle changes
}

const MultiSelectLabelView: React.FC<MultiSelectDropdownProps> = ({
  options,
  initialValues = [],
  onChange,
}) => {
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);

  // Update selectedLabels only when initialValues changes and only if initialValues is not empty
  useEffect(() => {
    if (initialValues && initialValues?.length > 0) {
      setSelectedLabels(initialValues);
    }
  }, [initialValues]);

  const handleSelectionChange = (data: {
    selectedOption: string[];
    updatedOptions: { value: string; color: string }[];
  }) => {
    const newSelectedLabels = Array?.from(
      new Set([...selectedLabels, ...data.selectedOption])
    );

    // Update the selectedLabels state to reflect the most recent selections
    setSelectedLabels(newSelectedLabels);

    // Call the onChange callback if provided with all selected labels
    if (onChange) {
      onChange(newSelectedLabels); // Pass all selected values to the parent component
    }
  };

  return (
    <Stack>
      <CustomCheckboxDropdownMultiple
        badgeWithoutText={false}
        placeHolder="Select options..." // Placeholder when no options are selected
        options={options}
        initialValues={selectedLabels} // Use selectedLabels as the initial selected values
        onChange={handleSelectionChange} // Handle change events
      />
    </Stack>
  );
};

export default MultiSelectLabelView;
