import {
  Box,
  Divider,
  Drawer,
  IconButton,
  InputBase,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useRef, useEffect, useState } from "react";
import {
  PlainRightArrowIcon,
  TemplateViewIcon,
  WebexPng,
} from "../../../../../images";
import styles from "./MeetingNotesCardLarge.module.css";
import {
  ButtonSmall,
  ButtonType,
  ConnectCommunicationDropdown,
  DottedButton,
  EditableTextArea,
  EmailRichTextMenu,
  SmallButtonColor,
  ThreeDotMenuNotes,
  ViewTemplatesNotes,
} from "../../../../common";
import { useDispatch } from "react-redux";
import { createMeetingNotes } from "../../../../../redux";
import { useTranslation } from "react-i18next";

interface MeetingNotesCardLargeProps {
  cardTitle?: string;
  isPreMeeting?: boolean;
  userData?: any;
  meetingData?: any;
  isDrawerOpen?: boolean;
  drawerCloseButtonClk?: () => void;
}

const MeetingNotesCardLarge: React.FC<MeetingNotesCardLargeProps> = ({
  cardTitle,
  isPreMeeting,
  userData,
  meetingData,
  isDrawerOpen = false,
  drawerCloseButtonClk,
}) => {
  const { t } = useTranslation();
  const elementRef = useRef<HTMLDivElement | null>(null);
  const [noteTitle, setNoteTitle] = useState("");
  const [noteContent, setNoteContent] = useState("");
  const [height, setHeight] = useState<number>(550);
  const [isFormValid, setIsFormValid] = useState(false);

  const dispatch = useDispatch();
  const validateForm = () => {
    const isValid = noteTitle.trim() !== "" && noteContent.trim() !== "";
    setIsFormValid(isValid);
  };

  useEffect(() => {
    validateForm();
  }, [noteTitle, noteContent]);
  useEffect(() => {
    if (elementRef.current) {
      // Get the height of the element using ref
      setHeight(elementRef.current.offsetHeight);
    }
  }, []);

  const handleSaveNote = () => {
    if (!isFormValid) return;
    const payload = {
      meetingId: meetingData?.chimeMeetingInfo?._id,
      authorId: userData?._id,
      content: noteContent,
      title: noteTitle,
      isPreMeeting: isPreMeeting,
    };
    dispatch(createMeetingNotes(payload));
    if (drawerCloseButtonClk) {
      drawerCloseButtonClk();
    }
  };
  return (
    <Drawer
      anchor={"right"}
      open={isDrawerOpen}
      onClose={() => {
        if (drawerCloseButtonClk) {
          drawerCloseButtonClk();
        }
      }}
      sx={{ zIndex: "1210 !important" }}
    >
      <Stack ref={elementRef} className={styles["MeetingNotesCardLarge"]}>
        {/* card header here */}
        <Stack className={styles["MeetingNotesCardLarge__header"]}>
          {/* icon-button and company name */}
          <Stack
            className={styles["MeetingNotesCardLarge__company"]}
            style={{ width: "500px" }}
          >
            <Tooltip title={t("PAST_CALL_DETAILS.CLOSE")} placement="top" arrow>
              <IconButton
                onClick={() => {
                  if (drawerCloseButtonClk) {
                    drawerCloseButtonClk();
                  }
                }}
                className={styles["MeetingNotesCardLarge__iconButton"]}
                disableRipple
              >
                <PlainRightArrowIcon w={25} />
              </IconButton>
            </Tooltip>
            <Stack className={styles["MeetingNotesCardLarge__companyInfo"]}>
              <Box
                component={"img"}
                src={WebexPng}
                className={styles["MeetingNotesCardLarge__companyLogo"]}
              />
              <Typography
                className={styles["MeetingNotesCardLarge__companyText"]}
              >
                {t("VIDEO_CENTER.MEETING.WEBEX")}
              </Typography>
            </Stack>
            {isPreMeeting && (
              <Stack
                style={{
                  width: "190px",
                  display: "flex",
                  flexDirection: "row-reverse",
                }}
              >
                <Typography
                  style={{
                    font: "normal normal 600 16.29px/13px Source Serif Pro",
                    color: "#000000",
                  }}
                >
                  {t("VIDEO_CENTER.MEETING.PRE_MEETING_NOTE")}
                </Typography>
              </Stack>
            )}
            <Stack width={"160px"} />
          </Stack>

          {/* fix title */}
          <Stack>
            {cardTitle && (
              <Typography className={styles["MeetingNotesCardLarge__title"]}>
                {cardTitle}
              </Typography>
            )}
          </Stack>

          <Stack width={"160px"} />
        </Stack>
        {/* ---------------------------------------- */}
        <Divider />
        {/* ---------------------------------------- */}

        <Stack className={styles["MeetingNotesCardLarge__body"]}>
          {/* body headers // options and title here */}
          <Stack className={styles["MeetingNotesCardLarge__bodyHeader"]}>
            {/* title and threedot-menu */}
            <Stack className={styles["MeetingNotesCardLarge__bodyHeaderTitle"]}>
              <InputBase
                className={
                  styles["MeetingNotesCardLarge__bodyHeaderTitleTextInput"]
                }
                placeholder={t("VIDEO_CENTER.MEETING.UNTITLED_NOTE")}
                onChange={(e) => setNoteTitle(e.target.value)}
              />

              <ThreeDotMenuNotes />
            </Stack>

            {/* options button here [2] */}
            <Stack className={styles["MeetingNotesCardLarge__options"]}>
              <ConnectCommunicationDropdown />
            </Stack>
          </Stack>
          {/*------------input area here-------------- */}
          <Stack className={styles["MeetingNotesCardLarge__inputArea"]}>
            <EditableTextArea
              placeholder={t("VIDEO_CENTER.MEETING.TYPE_HERE")}
              style={{
                // maxHeight: `${height - 130}px`,
                font: "normal normal normal 14px/20px Source Serif Pro",
                marginBottom: "30px",
              }}
              maxRows={(height - 360) / 20}
              onTextChange={(text) => setNoteContent(text)}
            />
            <Stack sx={{ flexDirection: "row", justifyContent: "start" }}>
              <EmailRichTextMenu />
            </Stack>
          </Stack>
          {/*------------input area here-------------- */}
        </Stack>

        {/* ---------------------------------------- */}
        <Divider />
        {/* ---------------------------------------- */}
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0px 15px",
          }}
        >
          <Stack className={styles["MeetingNotesCardLarge__footer"]}>
            <DottedButton
              icon={TemplateViewIcon}
              title={t("VIDEO_CENTER.MEETING.CREATE_NEW_TEMPLATE")}
            />
            <ViewTemplatesNotes />
          </Stack>

          <ButtonSmall
            label={t("VIDEO_CENTER.MEETING.SAVE")}
            types={ButtonType.Button}
            disabled={!isFormValid}
            colorVarient={SmallButtonColor.Orange}
            onClick={handleSaveNote}
          />
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default MeetingNotesCardLarge;
