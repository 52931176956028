import { PayloadAction } from "@reduxjs/toolkit";
import { CRMCenterState } from "../CRMCenterType";
import {
  getAllBusiness__Failure,
  getAllContacts__Failure,
  getAllDeals__Failure,
  getAllGroups__Failure,
} from "./CRMCenterFailure";

/* Add Contact */
export const addContact__Success = (
  state: CRMCenterState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

/* Get One Contact */
export const getOneContact__Success = (
  state: CRMCenterState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

/* Update One Contact */
export const updateOneContact__Success = (
  state: CRMCenterState,
  action: PayloadAction<any>
) => {
  const updatedContact = action?.payload?.data; // The updated contact data from the response
  const contactId = updatedContact?._id; // Extract the ID of the updated contact

  // Check if the contact ID exists in the contactList
  const index = state?.contactList?.findIndex(
    (contact: any) => contact?._id === contactId
  );

  if (index !== -1) {
    // If found, update the existing entry with the new data
    state.contactList[index] = updatedContact;
  } else {
    // If not found, log a warning or handle as needed
    console.warn(`Contact with ID ${contactId} not found in contactList.`);
  }

  // Optionally update the state.data to reflect the latest contact information
  state.data = updatedContact;
};

/* Delete One Contact */
export const deleteOneContact__Success = (
  state: CRMCenterState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data; // or any relevant data for deletion
};

/* Add Business */
export const addBusiness__Success = (
  state: CRMCenterState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

/* Get One Business */
export const getOneBusiness__Success = (
  state: CRMCenterState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

/* Update One Business */
export const updateOneBusiness__Success = (
  state: CRMCenterState,
  action: PayloadAction<any>
) => {
  const updatedBusiness = action?.payload?.data; // The updated business data from the response
  const businessId = updatedBusiness?._id; // Extract the ID of the updated business

  // Check if the business ID exists in the companyList
  const index = state?.companyList?.findIndex(
    (business: any) => business?._id === businessId
  );

  if (index !== -1) {
    // If found, update the existing entry with the new data
    state.companyList[index] = updatedBusiness;
  } else {
    // If not found, you might want to handle this case
    console.warn(`Business with ID ${businessId} not found in companyList.`);
  }

  // Optionally update the state.data to reflect the latest business information
  state.data = updatedBusiness;
};

/* Delete One Business */
export const deleteOneBusiness__Success = (
  state: CRMCenterState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data; // or any relevant data for deletion
};

/* Update Custom Elements */
export const updateCustomElements__Success = (
  state: CRMCenterState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data; // or any relevant data for updates
};

/* Get All Contacts */
export const getAllContacts__Success = (
  state: CRMCenterState,
  action: PayloadAction<any>
) => {
  const data = action?.payload?.data;

  if (data?.length === 0) {
    getAllContacts__Failure(state, action as any);
    state.error = "No contacts found";
    return;
  }
  state.contactList = action?.payload?.data;
  state.data = action?.payload?.data;
};

/* Get All Business */
export const getAllBusiness__Success = (
  state: CRMCenterState,
  action: PayloadAction<any>
) => {
  const data = action?.payload?.data;

  if (data?.length === 0) {
    getAllBusiness__Failure(state, action as any);
    state.error = "No buniess found";
    return;
  }

  state.companyList = action?.payload?.data;
  state.data = action?.payload?.data;
};

/* Create Group Success */
export const createGroup__Success = (
  state: CRMCenterState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

/* Get All Groups Success */
export const getAllGroups__Success = (
  state: CRMCenterState,
  action: PayloadAction<any>
) => {
  const data = action?.payload?.data;

  if (data?.length === 0) {
    getAllGroups__Failure(state, action as any);
    state.error = "No group found";
    return;
  }

  state.groupList = action?.payload?.data;
  state.data = action?.payload?.data;
};

/* Get Group By ID Success */
export const getGroupById__Success = (
  state: CRMCenterState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

/* Update Group Success */
export const updateGroup__Success = (
  state: CRMCenterState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

/* Delete Group Success */
export const deleteGroup__Success = (
  state: CRMCenterState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

/* Add to Group Success */
export const addToGroup__Success = (
  state: CRMCenterState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

/* Create Deal Success */
export const createDeal__Success = (
  state: CRMCenterState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

/* Get All Deals Success */
export const getAllDeals__Success = (
  state: CRMCenterState,
  action: PayloadAction<any>
) => {
  const data = action?.payload?.data;

  if (data?.length === 0) {
    getAllDeals__Failure(state, action as any);
    state.error = "No deal found";
    return;
  }

  state.dealList = action?.payload?.data;
  state.data = action?.payload?.data;
};

/* Get Deal By ID Success */
export const getDealById__Success = (
  state: CRMCenterState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

/* Update Deal Success */
export const updateDeal__Success = (
  state: CRMCenterState,
  action: PayloadAction<any>
) => {
  const updatedDeal = action.payload.data; // Get the updated deal from the action payload

  // Update the dealList with the newly updated deal
  state.dealList = state?.dealList?.map(
    (deal: any) => (deal?._id === updatedDeal?._id ? updatedDeal : deal) // Replace the updated deal while keeping others intact
  );

  // Optionally, update the state.data if needed
  state.data = updatedDeal; // If you want to keep the current deal in data
};

/* Delete Deal Success */
export const deleteDeal__Success = (
  state: CRMCenterState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

/* Add Note to Deal Success */
export const addNoteTo__Success = (
  state: CRMCenterState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getNoteTo__Success = (
  state: CRMCenterState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

/* Outlook === */
/* Get All Outlook Contacts Success */
export const getAllOutlookContacts__Success = (
  state: CRMCenterState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
  state.outlookContactList = action?.payload?.data?.value;
};

/* Get Outlook Contact By ID Success */
export const getOutlookContactById__Success = (
  state: CRMCenterState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

/* Create Outlook Contact Success */
export const createOutlookContact__Success = (
  state: CRMCenterState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

/* Update Outlook Contact Success */
export const updateOutlookContact__Success = (
  state: CRMCenterState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

/* Outlook === */
