import { Stack } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { ButtonSmall } from "../../Buttons";
import {
  DragAndDropMenuListType,
  FilterCondition,
  FilterCriteriaType,
  FilterOperator,
  FilterOperatorOptions,
} from "../MenuTypes";
import { SmallButtonColor } from "../../Buttons/AllButtonProps";
import {
  AscendingDropdown,
  ConditionSelector,
  InputArea,
  ValueDropdown,
} from "../view";
import { SmallThreeDotMenu } from "../../SmallThreeDotMenu";
import { DeleteIcon } from "../../../../images";
import { useTranslation } from "react-i18next";

interface FilterMenuProps {
  buttonColor: "CRMOrange" | "TraxBluish";
  SelectedOptList?: FilterCriteriaType[];
  options: DragAndDropMenuListType[];
  onChange: (newList: FilterCriteriaType[]) => void;
}

const FilterMenu: React.FC<FilterMenuProps> = ({
  buttonColor,
  onChange,
  options,
  SelectedOptList,
}) => {
  const { t } = useTranslation();
  const lastItemRef = useRef<HTMLDivElement>(null);
  const [selectedOption, setSelectedOption] = useState<FilterCriteriaType[]>(
    SelectedOptList || []
  );
  const [remainingList, setRemainingList] = useState<DragAndDropMenuListType[]>(
    []
  );

  const handleValueSelect = (
    index: number,
    newValue: DragAndDropMenuListType
  ) => {
    const updatedOptions = [...selectedOption];
    updatedOptions[index].selectedItem = newValue;
    setSelectedOption(updatedOptions);
  };

  const handleConditionChange = (index: number, newValue: FilterCondition) => {
    const updatedOptions = [...selectedOption];
    updatedOptions[index].condition = newValue;
    setSelectedOption(updatedOptions);
  };

  const handleOperatorSelect = (index: number, newOrder: string) => {
    const updatedOptions = [...selectedOption];
    updatedOptions[index].operator = newOrder as FilterOperator;
    setSelectedOption(updatedOptions);
  };

  const handleFilterInputChange = (index: number, newValue: string) => {
    const updatedOptions = [...selectedOption];
    updatedOptions[index].filterInput = newValue;
    setSelectedOption(updatedOptions);
  };

  const handleDeleteItem = (labelName: string) => {
    const updatedOptions = selectedOption.filter(
      (item) => item.selectedItem.label !== labelName
    );
    setSelectedOption(updatedOptions);
  };

  const addButtonHandler = () => {
    const isEmptyAdded: boolean = selectedOption.some(
      (item) => Object.keys(item.selectedItem).length === 0
    );

    if (!isEmptyAdded) {
      const newValue: FilterCriteriaType = {
        condition: "and",
        operator: "contains",
        selectedItem: {},
      };
      setSelectedOption((pre) => [...pre, newValue]);
      setTimeout(() => {
        if (lastItemRef.current) {
          lastItemRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }, 50);
    }
    setTimeout(() => {
      if (lastItemRef.current) {
        lastItemRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 50);
  };

  useEffect(() => {
    if (selectedOption.every((item) => !!item.selectedItem.label)) {
      onChange(selectedOption);

      const selectedLabels = selectedOption.map(
        (item) => item.selectedItem.label
      );
      const remainingOptions = options.filter(
        (option) => !selectedLabels.includes(option.label)
      );

      // Set remaining options in state
      setRemainingList(remainingOptions);
    }
  }, [selectedOption]);

  const getButtonColor = (): SmallButtonColor => {
    switch (buttonColor) {
      case "CRMOrange":
        return SmallButtonColor.CRMOrange;
      case "TraxBluish":
        return SmallButtonColor.TraxBluish;
    }
  };

  // console.log("dadadada",selectedOption)

  return (
    <Stack
      sx={{
        border: "1px solid #E9EDF2",
        borderRadius: "5px",
        maxHeight: "400px",
        minHeight: "150px",
        padding: "20px 20px 15px 20px",
        boxSizing: "border-box",
        gap: "25px",
        justifyContent: "space-between",
      }}
    >
      <Stack
        sx={{
          overflow: "auto",
          scrollbarWidth: "none",
          gap: "15px",
          pt: "2px",
        }}
      >
        {selectedOption.map((item, index) => {
          const isLastItem = index === selectedOption.length - 1;
          return (
            <Stack
              ref={isLastItem ? lastItemRef : null}
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <ConditionSelector
                initialValue={item.condition}
                onChange={(value) => handleConditionChange(index, value)}
              />

              <ValueDropdown
                initialValue={item.selectedItem}
                onSelect={(value) => handleValueSelect(index, value)}
                options={remainingList}
                width="150px"
              />

              <AscendingDropdown
                initialValue={item.operator}
                onSelect={(value) => handleOperatorSelect(index, value)}
                options={FilterOperatorOptions}
                width="140px"
              />

              <InputArea
                readChangeOnEachWord
                placeholder={t("TRAX.ENTER_TEXT")}
                text={item.filterInput}
                onTextChange={(value) => handleFilterInputChange(index, value)}
              />

              <SmallThreeDotMenu
                menuItems={[
                  {
                    id: 1,
                    icon: DeleteIcon,
                    text: "Delete Filter",
                    LANG: "TRAX.DELETE_FILTER",
                    color: "red",
                  },
                ]}
                displayOption="left"
                menuWidth="130px"
                onClickAction={() => handleDeleteItem(item.selectedItem.label!)}
              />
            </Stack>
          );
        })}
      </Stack>

      <Stack>
        <ButtonSmall
          onClick={addButtonHandler}
          label={t("TRAX.ADD_FILTER")}
          colorVarient={getButtonColor()}
          sx={{ padding: "0px 8px" }}
        />
      </Stack>
    </Stack>
  );
};

export default FilterMenu;
