import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import g from "./BookEventSuccess.module.css";
import { BearishIcon } from "../../../../../../images";
import { ButtonSmall, SmallButtonColor } from "../../../../../common";
// Helper function to format the date (e.g., "October 7th, 2024")
const formatDate = (isoDateString: string) => {
  const date = new Date(isoDateString);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return date.toLocaleDateString("en-US", options).replace(",", "th"); // Handle ordinal suffix
};

type Props = { dateSelected: any; redirectToLogin:any; };

const BookEventSuccess = ({ dateSelected, redirectToLogin }: Props) => {
  return (
    <Box
      sx={{
        padding: "20px",
        width: "650px",
        height: "650px",
        boxShadow: "0px 3px 3px 0px #00000029",
        border: "3px solid #E9EDF2",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Stack
        gap={2}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <BearishIcon width={60} height={60} color={"orange"} />
        <Typography
          children={"Bearish OS"}
          className={g.success__event__ProductName}
        />
      </Stack>

      <Stack mt={5}>
        <Stack alignItems={"center"} justifyContent={"center"}>
          <Typography className={g.success__event__Success_WORD}>
            <span style={{ fontSize: "50px" }}>Success!</span> Your event was
            scheduled for
          </Typography>
          <Typography
            sx={{ marginTop: "10px" }}
            className={g.success__event__Success_WORD}
          >
            {formatDate(dateSelected)}
          </Typography>
        </Stack>

        <Stack padding={"30px"}>
          <Divider
            sx={{ width: "100%", height: "0px", border: "1px solid #E9EDF2" }}
          />
        </Stack>

        <Stack
          mt={3}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography className={g.success__event__ProductName__info}>
            Bearish OS is zero software to download Work OS <br></br> that
            connects, replaces, and supercharges with <br></br> our privacy
            first AI
          </Typography>
        </Stack>

        <Stack
          mt={3}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography className={g.success__event__ProductName__info}>
            “On average it saves us 2 hrs a day and replaced <br></br> more than
            $1100/a year worth of our software…” <br></br>{" "}
            <span style={{ fontSize: "11px", fontStyle: "italic" }}>
              Justin T. VP of Product, Deckers
            </span>{" "}
            <br></br> Get your free account now!
          </Typography>
        </Stack>

        <Stack
          mt={3}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <ButtonSmall
            label={"Create your free account"}
            colorVarient={SmallButtonColor.MidGreen}
            onClick={() => redirectToLogin && redirectToLogin()}
            sx={{ minWidth: "260px", fontSize: "18px", minHeight: "40px" }}
          />
        </Stack>

        <Stack
          mt={3}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography className={g.success___last}>
            No credit card required, no hidden fees, 100% privacy first software
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default BookEventSuccess;
