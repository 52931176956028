import { Stack } from "@mui/material";
import React from "react";
import { InlineContainer } from "./view";

interface WorksheetContainerProps {}

const WorksheetContainer: React.FC<WorksheetContainerProps> = ({}) => {
  return (
    <Stack sx={{ flexDirection: "row" }}>
      <InlineContainer
        bgcolor="#001BB1"
        opacity={45}
        label="Total Worked"
        fontColor="white"
        value="100 days"
        sx={{ borderRadius: "5px 0px 0px 5px" }}
      />
      <InlineContainer
        bgcolor="#A47407"
        opacity={45}
        label="Leaves Taken"
        fontColor="white"
        value="5 days"
      />
      <InlineContainer
        bgcolor="#660ED7"
        opacity={45}
        label="Leaves Left"
        fontColor="white"
        value="02 days"
      />

      <InlineContainer
        bgcolor="#FF0000"
        opacity={45}
        label="Overtime"
        fontColor="white"
        value="15 hrs"
      />
      <InlineContainer
        bgcolor="#046D48"
        opacity={75}
        label="Expenses"
        value="$1000 USD"
        fontColor="white"
        sx={{ borderRadius: "0px 5px 5px 0px" }}
      />
    </Stack>
  );
};

export default WorksheetContainer;
