import * as React from "react";
import {
    useAutocomplete,
    AutocompleteGetTagProps,
} from "@mui/base/useAutocomplete";
import { styled } from "@mui/material/styles";
import { autocompleteClasses } from "@mui/material/Autocomplete";

import { AvatarNormal } from "../AvatarNormal";
import { Stack, Typography } from "@mui/material";
import { XCircleIcon } from "../../../images";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducer";
import { AuthState } from "../../../redux";
import {DropdownWithImgBadgeInterface} from "../DropdownWithImageBadge/DropdownWithImageBadgeTypes";

const Root = styled("div")(
    ({ theme }) => `
  width:100%;
  box-sizing: border-box;
  color: ${
        theme.palette.mode === "dark" ? "rgba(255,255,255,0.65)" : "rgba(0,0,0,.85)"
    };
  font-size: 14px;
`
);

const InputWrapper = styled("div")(
    () => `
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  & input {
    color: #000;
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
    font:normal normal 300 13px/16px Source Serif Pro;
  }
`
);

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
    label: string;
    imgSrc?: string;
}

function Tag(props: any) {
    const { label, imgSrc, onDelete, ...other } = props;
    return (
        <div {...other}>
            <AvatarNormal size={20} username={label} imgSrc={imgSrc} />
            <span>{label}</span>
            <span style={{ display: "flex" }} onClick={onDelete}>
        <XCircleIcon w={12} />
      </span>
        </div>
    );
}

const StyledTag = styled(Tag)<TagProps>(
    () => `
  display: flex;
  align-items: center;
  margin:2px;
  height: 30px;
  border: 1px solid #E9EDF2;
  border-radius:100px;
  box-sizing: content-box;
  padding: 0px 8px;
  gap:5px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color:  #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font:normal normal 300 13px/16px Source Serif Pro;
  }

  & svg {
    cursor: pointer;
  }
`
);

const Listbox = styled("ul")(
    ({ theme }) => `
  box-sizing: border-box;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;
  scrollbar-width: none;

  & li {
    padding: 5px 12px;
    display: flex;
    box-sizing: border-box;
  }

  & li[aria-selected='true'] {
    background-color:#e6f7ff;
  }

  & li.${autocompleteClasses.focused} {
    background-color: #ebebeb;
    cursor: pointer;
  }
`
);

interface CustomizedHookProps {
    options: DropdownWithImgBadgeInterface[];
    onChangeSelection: (selectedOptions: DropdownWithImgBadgeInterface[]) => void;
    singleSelect?: boolean;  // Added singleSelect prop
}

export default function Custom({
                                           options,
                                           onChangeSelection,
                                           singleSelect,
                                       }: CustomizedHookProps) {
    const [inputValue, setInputValue] = React.useState("");
    const [customOptions, setCustomOptions] =
    React.useState<DropdownWithImgBadgeInterface[]>(options);
    const userState = useSelector((state: RootState) => state.auth as AuthState);
    // Email validation regex
    const isValidEmail = (email: string) => {
        return /^[^\s@]+@[^\s@]+\.com$/.test(email);
    };

    React.useEffect(() => {
        const lastOptionIndex = customOptions.findIndex(
            (option) => option.name === inputValue && option.email === inputValue
        );

        if (isValidEmail(inputValue) && lastOptionIndex === -1) {
            // Add the new valid email option
            setCustomOptions([
                ...options,
                { name: inputValue, email: inputValue, userIconSrc: "" },
            ]);
        } else if (!isValidEmail(inputValue) && lastOptionIndex !== -1) {
            // Remove the invalid email option
            const updatedOptions = customOptions.filter(
                (option) => option.name !== inputValue
            );
            setCustomOptions(updatedOptions);
        }
    }, [inputValue, customOptions, options]);

    const {
        getRootProps,
        getInputProps,
        getTagProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
        value,
        focused,
        setAnchorEl,
    } = useAutocomplete({
        id: "customized-hook-demo",
        multiple: !singleSelect,
        options: customOptions,
        getOptionLabel: (option) => option.email + option.name,
        inputValue: inputValue, // Bind the inputValue state to the input field
        // onInputChange: (event, newInputValue) => {

        //   setInputValue(newInputValue); // Update the inputValue state
        // },
        onChange: (event, newValue) => {
            if (singleSelect) {
                // Handle single-select mode
                onChangeSelection(newValue ? [newValue as any] : []);  // Convert newValue to array
            } else {
                // Handle multi-select mode
                onChangeSelection(newValue as any[]);  // Use newValue as is

            }

            // onChangeSelection(newValue as any[]);
        },
    });
    const isArray = (value: any): value is DropdownWithImgBadgeInterface[] => {
        return Array.isArray(value);
    };

    return (
        <Root>
            <div {...getRootProps()}>
                <InputWrapper ref={setAnchorEl} className={focused ? "focused" : ""}>
                    {/* { value.map((option: DropdownWithImgBadgeInterface, index: number) => {
            const { key, ...tagProps } = getTagProps({ index });
            return (
              <StyledTag
                key={key}
                {...tagProps}
                label={option?.firstName}
                imgSrc={option.userIconSrc}
              />
            );
          })} */}

                    {isArray(value) ? (
                        value.map((option: DropdownWithImgBadgeInterface, index: number) => {
                            const { key, ...tagProps } = getTagProps({ index });
                            return (
                                <StyledTag
                                    key={key}
                                    {...tagProps}
                                    label={option?.displayName}
                                    imgSrc={option.userIconSrc}
                                />
                            );
                        })
                    ) : (
                        value && (
                            <StyledTag
                                // Use a unique key, email could be unique for a single option
                                label={value.firstName}
                                imgSrc={value?.userIconSrc}
                            />
                        )
                    )}
                    <input
                        {...getInputProps()}
                        // value={inputValue} // Display the current input value in the input field
                    />
                </InputWrapper>
            </div>
            {groupedOptions.length > 0 ? (
                <Listbox {...getListboxProps()}>
                    {(groupedOptions as any[]).map(
                        (option, index) => {
                            const { ...optionProps } = getOptionProps({ option, index });
                            if  (option._id === userState?.user?.data?._id){
                                return
                            }
                            return (
                                <li
                                    //@ts-ignore
                                    key={index}
                                    {...optionProps}
                                    style={{
                                        height: "40px",
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "row",
                                        gap: "10px",
                                    }}
                                >
                                    <AvatarNormal
                                        username={option.avatar}
                                        size={30}
                                        imgSrc={option.avatar}
                                    />
                                    <Stack>
                                        <Typography
                                            sx={{
                                                font: "normal normal 400 14px/18px Source Serif Pro",
                                            }}
                                        >
                                            {option.displayName}
                                        </Typography>
                                    </Stack>
                                </li>
                            );
                        }
                    )}
                </Listbox>
            ) : null}
        </Root>
    );
}
