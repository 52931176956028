import React from "react";
import { useSelector } from "react-redux";
import { VideoCenterState } from "../../../../redux/Slice/videoCenter/VideoCenterTypes";
import { RootState } from "../../../../redux/store";
import {Box, Typography} from "@mui/material";
import { SearchBar } from "../../CRM/components";
import { FullScreenLoader } from "../../../common";
import { useTranslation } from "react-i18next";
import PastCallCenterTabs from "../components/PastCallCenterTabs/CallCenterTabs";
import dayjs from "dayjs";
import {PlanIcon} from "../../../../Router/RouteIcon";

type CallDetailsProps = {};
const PastCallDetails = (props: CallDetailsProps) => {
  const {t}=useTranslation()
  const {selectedPastMeeting} = useSelector(
      (state: RootState) => state.videoCenter as VideoCenterState
  );
  const { loading } = useSelector(
    (state: RootState) => state.videoCenter as VideoCenterState
  );
  const meetingStartedEvent: any = selectedPastMeeting?.activity?.find((event: { type: string }) => event?.type === "MEETING_STARTED")
  const meetingEndedEvent: any = selectedPastMeeting?.activity?.find((event: { type: string }) => event?.type === "MEETING_ENDED")
  return (
    <Box
      display="flex"
      padding="0 15px 0 15px"
      flexDirection="column"
      width="100%"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
        <Box style={{ marginTop: "25px" }}>
          <Typography
              sx={{ font: "normal normal 400 18.5px/15px Source Serif Pro" }}
          >
            Example Call Name Here
          </Typography>
          <Typography
              sx={{
                display:'flex',
                alignItems:"center",
                gap:"5px",
                font: "normal normal 300 13.78px/11px Source Serif Pro",
                marginTop: "12px",
              }}
          >
           <PlanIcon/> {dayjs(meetingStartedEvent?.timestamp).format('DD-MM-YYYY')} at {dayjs(meetingStartedEvent?.timestamp).format('h:mm A')} to {dayjs(meetingEndedEvent?.timestamp).format('h:mm A')}
          </Typography>
        </Box>
        <SearchBar
          iconSize={15}
          font="normal normal 400 11px/14px Source Serif Pro"
          customStyles={{ width: "330px", display: "flex", color: "black"}}
          containerSx={{borderRadius:"5px!important"}}
          placeholder={t('VIDEO_CENTER.MEETING.SEARCH_CALLS')}
        />
        {loading && <FullScreenLoader />}
      </Box>
      <Box display="flex" alignItems="center" style={{padding:"0 20px 0 20px"}} >
        <PastCallCenterTabs />
      </Box>
    </Box>
  );
};

export default PastCallDetails;
