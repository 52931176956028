import { PayloadAction } from "@reduxjs/toolkit";
import { ShareState } from "../ShareType";

// Add Share
export const addShare__Success = (
  state: ShareState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Get Share
export const getShare__Success = (
  state: ShareState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};
