import * as React from "react";
import {
  Box,
  Popover,
  InputBase,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { Dayjs } from "dayjs";
import { CalendarIcon, DateIcon } from "../../../images";
import { adjustHexColor } from "../../../Utils";

interface CustomDateRangePickerType {
  inputView?: "normal" | "large";
  placeholder?: string;
  onDateChange: (startDate: Dayjs | null, endDate: Dayjs | null) => void;
}

const CustomDateRangePicker: React.FC<CustomDateRangePickerType> = ({
  inputView = "normal",
  placeholder = "Select timeline",
  onDateChange,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [startDate, setStartDate] = React.useState<Dayjs | null>(null);
  const [endDate, setEndDate] = React.useState<Dayjs | null>(null);
  const [isEndDateSet, setIsEndDateSet] = React.useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
    if (!isEndDateSet) {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "date-range-Popover" : undefined;

  const formatStartDate = startDate ? startDate.format("MMM D") : "";
  const formatEndDate = endDate ? endDate.format("MMM D, YYYY") : "";

  const formatStartDate2 = startDate ? startDate.format("DD MM YYYY") : "";
  const formatEndDate2 = endDate ? endDate.format("DD MM YYYY") : "";

  const handleStartDateChange = (newValue: Dayjs | null) => {
    if (startDate && newValue && startDate.isSame(newValue, "day")) {
      setStartDate(null);
      setEndDate(null);
      setIsEndDateSet(false);
    } else {
      setStartDate(newValue);
      if (!isEndDateSet) {
        setEndDate(newValue);
      }
    }
  };

  const handleEndDateChange = (newValue: Dayjs | null) => {
    if (endDate && newValue && endDate.isSame(newValue, "day")) {
      setEndDate(null);
      setIsEndDateSet(false);
    } else {
      setEndDate(newValue);
      setIsEndDateSet(true);
      if (newValue) {
        setAnchorEl(null);
        onDateChange(startDate, newValue);
      }
    }
  };

  const getHeaderComponent = () => {
    switch (inputView) {
      case "normal":
      default:
        return (
          <InputBase
            inputProps={{ style: { cursor: "pointer" } }}
            sx={{
              borderRadius: "5px",
              cursor: "pointer",
              height: "25px",
              width: "125px",
              border: "0.5px solid #E9EDF2",
              boxSizing: "border-box",
              font: "normal normal normal 10px/13px Source Serif Pro",
              paddingLeft: "0px",
            }}
            startAdornment={
              <InputAdornment
                position="start"
                sx={{ marginRight: "5px", marginLeft: "5px", padding: 0 }}
              >
                <CalendarIcon w={15} />
              </InputAdornment>
            }
            onClick={handleClick}
            value={
              startDate && endDate
                ? `${formatStartDate} - ${formatEndDate}`
                : ""
            }
            placeholder={placeholder}
            readOnly
          />
        );

      case "large":
        return (
          <Stack
            sx={{
              cursor: "pointer",
              width: "295px",
              height: "35px",
              borderRadius: "5px",
              border: "1px solid #E9EDF2",
              boxSizing: "border-box",
              flexDirection: "row",
              alignItems: "center",
              padding: "0px 10px",
              gap: "5px",
              justifyContent: "space-between",
            }}
            onClick={handleClick}
          >
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
                cursor: "pointer",
              }}
            >
              <Typography
                sx={{ font: "normal normal 400 13px/16px Source Serif Pro" }}
              >
                Time period
              </Typography>

              {startDate && endDate ? (
                <>
                  <Typography
                    sx={{
                      font: "normal normal 300 14px/16px Source Serif Pro",
                    }}
                  >
                    {formatStartDate2}
                  </Typography>
                  <Typography
                    sx={{
                      font: "normal normal 400 13px/16px Source Serif Pro",
                    }}
                  >
                    to
                  </Typography>
                  <Typography
                    sx={{
                      font: "normal normal 300 14px/16px Source Serif Pro",
                    }}
                  >
                    {formatEndDate2}
                  </Typography>
                </>
              ) : (
                <Typography
                  sx={{
                    font: "normal normal 300 13px/16px Source Serif Pro",
                    paddingLeft: "10px",
                    color: adjustHexColor("#000000", 70),
                  }}
                >
                  {placeholder}
                </Typography>
              )}
            </Stack>

            <DateIcon w={20} />
          </Stack>
        );
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box>
        {getHeaderComponent()}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClickAway}
        >
          <Box
            sx={{
              bgcolor: "background.paper",
              display: "flex",
              boxShadow: 1,
            }}
          >
            <DateCalendar
              value={startDate}
              onChange={handleStartDateChange}
              shouldDisableDate={(date) =>
                endDate ? date.isAfter(endDate, "day") : false
              }
            />
            <Box
              sx={{
                width: "1px",
                backgroundColor: "#E9EDF2",
              }}
            />
            <DateCalendar
              disabled={!startDate}
              value={endDate}
              onChange={handleEndDateChange}
              shouldDisableDate={(date) =>
                startDate ? date.isBefore(startDate, "day") : false
              }
            />
          </Box>
        </Popover>
      </Box>
    </LocalizationProvider>
  );
};

export default CustomDateRangePicker;
