import React from 'react';
import { Box, Typography, Container } from '@mui/material';

type Props = {};

const Profile = (props: Props) => {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '70vh',
        textAlign: 'center',
      }}
    >
      <Box
        sx={{
          padding: 4,
          borderRadius: '8px',
          maxWidth: 500,
          width: '100%',
          backgroundColor: '#fff',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          '&:hover': {
            transform: 'scale(1.03)',
            boxShadow: '0px 6px 30px rgba(0, 0, 0, 0.15)',
          },
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          color="primary"
          fontFamily="Source Serif Pro"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            fontSize: '1.75rem',
          }}
        >
          User Profile Coming Soon
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          fontFamily="Source Serif Pro"
          sx={{
            fontSize: '1rem',
            lineHeight: 1.6,
            marginTop: 2,
          }}
        >
          This section will soon allow you to manage your profile settings.
          Look forward to customizing your preferences, updating your details, and more features designed for a personalized experience.
        </Typography>
      </Box>
    </Container>
  );
};

export default Profile;
