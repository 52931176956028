import { Stack, Typography } from "@mui/material";
import React from "react";
import { AvatarNormal } from "../../../../../../common";
import { adjustHexColor, getUser } from "../../../../../../../Utils";

interface AddCommentViewProps {
  onClickbox?: () => void;
}

const AddCommentView: React.FC<AddCommentViewProps> = ({ onClickbox }) => {
  const user = getUser();

  const isValidUrl = (url: string | undefined): boolean => {
    if (!url) return false;
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z0-9]+([\\-\\.][a-z0-9]+)*)?\\.)+[a-z]{2,}|" + // domain name
        "localhost|" + // localhost
        "\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}|" + // IP address
        "\\[?[a-f0-9]*:[a-f0-9:%.~\\-\\[\\]]*\\])" + // IPv6
        "(\\:\\d+)?(\\/[-a-z0-9+&@#\\/%?=~_|!:,.;]*[a-z0-9+&@#\\/%=~_|])?$",
      "i"
    ); // fragment locator
    return !!pattern?.test(url);
  };
  const avatarUrl = user?.data?.avatar;
  const avatarColor = avatarUrl?.startsWith("#")
    ? avatarUrl?.split("|")?.[0]
    : undefined;

  return (
    <Stack
      sx={{
        flexDirection: "row",
        gap: "10px",
        alignItems: "center",
        padding: "0",
      }}
      onClick={() => onClickbox && onClickbox()}
    >
      <AvatarNormal
        size={30}
        imgSrc={isValidUrl(avatarUrl) ? avatarUrl : undefined} // Set imgSrc conditionally
        avatarColor={avatarColor} // Set avatarColor conditionally
        username={user?.data?.firstName + " " + user?.data?.lastName}
      />
      <Typography
        sx={{
          font: "normal normal 400 13px/16px Source Serif Pro",
          color: adjustHexColor("#000000", 25),
          ":hover": { cursor: "default" },
        }}
      >
        Add a new comment, task, or video comment
      </Typography>
    </Stack>
  );
};

export default AddCommentView;
