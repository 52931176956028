import { Button, Typography } from "@mui/material";
import React from "react";
import { FlagIcon } from "../../../../../../images";
import {useTranslation} from "react-i18next";

interface HighlightAndFlagButtonProps {}

const HighlightAndFlagButton: React.FC<HighlightAndFlagButtonProps> = ({}) => {
    const {t}=useTranslation()
  return (
    <Button
      variant="text"
      sx={{
        border: "1px solid #E9EDF2",
        borderRadius: "5px",
        flexDirection: "row",
        alignItems: "center",
        boxSizing: "border-box",
        padding: "0px 5px",
        gap: "5px",
        textTransform: "none",
        width: "125px",
        height: "25px",
      }}
    >
      <FlagIcon w={15} />
      <Typography
        sx={{
          font: "normal normal 400 10px/13px Source Serif Pro",
          color: "black",
        }}
      >
          {t("MESSAGE.HIGHLIGHT_FLAG")}
      </Typography>
    </Button>
  );
};

export default HighlightAndFlagButton;
