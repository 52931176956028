import { PayloadAction } from "@reduxjs/toolkit";
import { ShareState } from "../ShareType";

export const addShare__Failure = (
    state: ShareState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};


export const getShare__Failure = (
    state: ShareState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};
