import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState, ConnectedAccount } from "./authTypes";
import {
  /* Forgot */
  forgotPasswordFailure,
  forgotPasswordRequest,
  forgotPasswordSuccess,
  updateUserProfileFailure,
  updateUserProfileRequest,
  updateUserProfileSuccess,

  /* Auth with google */
  googleAuthFailure,
  googleAuthRequest,
  googleAuthSuccess,

  /* Login with Bearish */
  loginFailure,
  loginRequest,
  loginSuccess,

  /* Login with OAuth */
  oAuthCallBackFailure,
  oAuthCallBackRequest,
  oAuthCallBackSuccess,

  /* Logout */
  logoutRequest,

  /* Auth with microsoft */
  microsoftAuthFailure,
  microsoftAuthRequest,
  microsoftAuthSuccess,

  /* Opt send */
  optSendFailure,
  optSendRequest,
  optSendSuccess,

  /* Opt Verify */
  optVerificationFailure,
  optVerificationRequest,
  optVerificationSuccess,

  /* state reset for opt */
  resetOptSend,
  resetOptVerify,

  /* Signup with bearish */
  signUpFailure,
  signUpRequest,
  signUpSuccess,

  /* Company availibity check */
  companyAvailibityRequest,
  companyAvailibitySuccess,
  companyAvailibityFailure,

  /* department list */
  getAllDepartmentsRequest,
  getAllDepartmentsSuccess,
  getAllDepartmentsFailure,

  /* BrandFetch Company list */
  fetchCompanyListRequest,
  fetchCompanyListFailure,
  fetchCompanyListSuccess,

  /* temp auth store rarely used */
  tempAuthStoreSuccess,
  tempAuthStoreRequest,
  getAllUsersRequest,
  getAllUsersSuccess,
  getAllUsersFailure,
  createNewGuestSuccess,
  createNewGuestRequest,
  createNewGuestFailure,
} from "./AuthSliceActions";

/*
 * ==============================================
 * Define the initial state for the authentication slice
 * ==============================================
 */
const initialState: AuthState = {
  isAuthenticated: false, // Indicates if the user is authenticated
  user_token: "default_testing_token", 
  user: null, // Stores user information when authenticated
  error: null, // Stores error messages if any
  isSignUp: false, // Add the isSignUp property
  externalEntities: null, // Initialize externalEntities as null
  eMessage: null, //  external entities
  usersList: [],
  users: {},
  usersListWithId: {},
  connectedAccounts: [],
  workOSClient: null, // Store Work OS client here
};

/*
 * ==============================================
 * Create a slice for authentication logic
 * ==============================================
 */
const authSlice = createSlice({
  name: "auth", // Name of the slice
  initialState, // Initial state of the slice
  reducers: {
    // Reducers to handle different actions

    addConnectedAccount(state, action: PayloadAction<ConnectedAccount>) {
      const { id, name, type, isTokenAlive, center, accountProfilePic } =
        action.payload;

      const existingAccountIndex = state.connectedAccounts.findIndex(
        (account) => account.id === id
      );

      if (existingAccountIndex !== -1) {
        // Update the existing account's properties without mutating the state directly
        const updatedAccount = {
          ...state.connectedAccounts[existingAccountIndex],
          isTokenAlive,
          name,
          center,
          accountProfilePic:
            accountProfilePic ||
            state.connectedAccounts[existingAccountIndex].accountProfilePic, // Keep the old pic if no new one is provided
        };

        // Return a new state with the updated account
        state.connectedAccounts = [
          ...state.connectedAccounts.slice(0, existingAccountIndex),
          updatedAccount,
          ...state.connectedAccounts.slice(existingAccountIndex + 1),
        ];

        state.error = null; // Clear any previous errors
      } else {
        // If it doesn't exist, push the new account
        state.connectedAccounts.push(action.payload);
        state.error = null; // Clear any previous errors
      }
    },
    setWorkOSClient: (state, action: PayloadAction<any>) => {
      state.workOSClient = action.payload;
    },
    removeConnectedAccount(state, action: PayloadAction<string>) {
      const accountId = action.payload;

      // Check if the account exists before removing
      const accountExists = state.connectedAccounts.some(
        (account) => account.id === accountId
      );
      if (!accountExists) {
        state.error = `Account with ID ${accountId} does not exist.`;
        return;
      }

      state.connectedAccounts = state.connectedAccounts.filter(
        (account) => account.id !== accountId
      );
      state.error = null; // Clear any previous errors
    },

    renameConnectedAccount(
      state,
      action: PayloadAction<{ id: string; newName: string }>
    ) {
      const { id, newName } = action.payload;

      // Ensure the new name is valid
      if (!newName) {
        state.error = "New name cannot be empty.";
        return;
      }

      const account = state.connectedAccounts.find(
        (account) => account.id === id
      );
      if (!account) {
        state.error = `Account with ID ${id} does not exist.`;
        return;
      }

      account.name = newName;
      state.error = null; // Clear any previous errors
    },

    updateTokenStatus(
      state,
      action: PayloadAction<{ id: string; isTokenAlive: boolean }>
    ) {
      const { id, isTokenAlive } = action.payload;
      const account = state.connectedAccounts.find(
        (account) => account.id === id
      );

      if (account) {
        account.isTokenAlive = isTokenAlive; // Update the token status
      } else {
        state.error = `Account with ID ${id} does not exist.`;
      }
    },

    // New Action: Connect an account
    connectAccount(state, action: PayloadAction<ConnectedAccount>) {
      const account = action.payload;
      const accountExists = state.connectedAccounts.some(
        (a) => a.id === account.id
      );

      if (accountExists) {
        state.error = `Account with ID ${account.id} is already connected.`;
      } else {
        state.connectedAccounts.push(account);
        state.error = null; // Clear any previous errors
      }
    },

    // New Action: Disconnect an account
    disconnectAccount(state, action: PayloadAction<string>) {
      const accountId = action.payload;
      const accountExists = state.connectedAccounts.some(
        (account) => account.id === accountId
      );

      if (!accountExists) {
        state.error = `Account with ID ${accountId} does not exist.`;
        return;
      }

      state.connectedAccounts = state.connectedAccounts.filter(
        (account) => account.id !== accountId
      );
      state.error = null; // Clear any previous errors
    },

    // New Action: Refresh account token
    refreshAccountToken(
      state,
      action: PayloadAction<{ id: string; newToken: string }>
    ) {
      const { id, newToken } = action.payload;
      const account = state.connectedAccounts.find(
        (account) => account.id === id
      );

      if (account) {
        account.token = newToken; // Update the token for the account
        state.error = null; // Clear any previous errors
      } else {
        state.error = `Account with ID ${id} does not exist.`;
      }
    },

    // New Action: Fetch account details (can be extended to handle side effects)
    fetchAccountDetails(state, action: PayloadAction<string>) {
      const accountId = action.payload;
      const account = state.connectedAccounts.find(
        (account) => account.id === accountId
      );

      if (account) {
        // Assuming you might want to do something with the account details
        // For now, we can just return or log
        console.log("Fetched account details:", account);
        state.error = null; // Clear any previous errors
      } else {
        state.error = `Account with ID ${accountId} does not exist.`;
      }
    },

    // New Action: Toggle account visibility
    toggleAccountVisibility(state, action: PayloadAction<string>) {
      const accountId = action.payload;
      const account = state.connectedAccounts.find(
        (account) => account.id === accountId
      );

      if (account) {
        account.isVisible = !account.isVisible; // Assuming you have a property for visibility
        state.error = null; // Clear any previous errors
      } else {
        state.error = `Account with ID ${accountId} does not exist.`;
      }
    },

    // New Action: Update account settings
    updateAccountSettings(
      state,
      action: PayloadAction<{ id: string; settings: Partial<ConnectedAccount> }>
    ) {
      const { id, settings } = action.payload;
      const account = state.connectedAccounts.find(
        (account) => account.id === id
      );

      if (account) {
        Object.assign(account, settings); // Merge the new settings into the account
        state.error = null; // Clear any previous errors
      } else {
        state.error = `Account with ID ${id} does not exist.`;
      }
    },

    loginRequest,
    loginSuccess,
    loginFailure,

    optSendRequest,
    optSendSuccess,
    optSendFailure,

    optVerificationRequest,
    optVerificationSuccess,
    optVerificationFailure,

    logoutRequest,
    signUpRequest,

    googleAuthRequest,
    microsoftAuthRequest,

    signUpSuccess,

    googleAuthSuccess,

    microsoftAuthSuccess,

    signUpFailure,

    googleAuthFailure,
    microsoftAuthFailure,

    forgotPasswordRequest,
    forgotPasswordSuccess,
    forgotPasswordFailure,

    resetOptSend,
    resetOptVerify,

    companyAvailibityRequest,
    companyAvailibitySuccess,
    companyAvailibityFailure,

    getAllDepartmentsRequest,
    getAllDepartmentsSuccess,
    getAllDepartmentsFailure,

    fetchCompanyListRequest,
    fetchCompanyListFailure,
    fetchCompanyListSuccess,

    tempAuthStoreSuccess,
    tempAuthStoreRequest,

    getAllUsersRequest,
    getAllUsersSuccess,
    getAllUsersFailure,
    createNewGuestRequest,
    createNewGuestSuccess,
    createNewGuestFailure,

    oAuthCallBackFailure,
    oAuthCallBackRequest,
    oAuthCallBackSuccess,

    updateUserProfileFailure,
    updateUserProfileRequest,
    updateUserProfileSuccess,
  },
});

/*
 * ======================================================
 * Export the actions to be used in dispatch calls
 * ======================================================
 */
export const {
  setWorkOSClient,
  addConnectedAccount,
  removeConnectedAccount,
  renameConnectedAccount,
  updateTokenStatus,
  /* Login */
  loginRequest: loginRequestAction,
  loginSuccess: loginSuccessAction,
  loginFailure: loginFailureAction,

  /* Signup */
  signUpRequest: signUpRequestAction,
  signUpSuccess: signUpSuccessAction,
  signUpFailure: signUpFailureAction,

  /* google signup/login */
  googleAuthRequest: googleAuthRequestAction,
  googleAuthSuccess: googleAuthSuccessAction,
  googleAuthFailure: googleAuthFailureAction,

  /* microsoft signup/login */
  microsoftAuthRequest: microsoftAuthRequestAction,
  microsoftAuthSuccess: microsoftAuthSuccessAction,
  microsoftAuthFailure: microsoftAuthFailureAction,

  /* Opt send*/
  optSendRequest: optSendRequestAction,
  optSendSuccess: optSendSuccessAction,
  optSendFailure: optSendFailureAction,

  /* Opt verify */
  optVerificationRequest: optVerificationRequestAction,
  optVerificationSuccess: optVerificationSuccessAction,
  optVerificationFailure: optVerificationFailureAction,

  /* Opt reset state in frontend */
  resetOptSend: resetOptSendAction,

  /* Otp reset state for verify */
  resetOptVerify: resetOptVerifyAction,

  /* Logout */
  logoutRequest: logoutRequestAction,

  /* Forgot */
  forgotPasswordRequest: forgotPasswordRequestAction,
  forgotPasswordSuccess: forgotPasswordSuccessAction,
  forgotPasswordFailure: forgotPasswordFailureAction,

  /* Company Avaibility check */
  companyAvailibityRequest: companyAvailibityRequestAction,
  companyAvailibitySuccess: companyAvailibitySuccessAction,
  companyAvailibityFailure: companyAvailibityFailureAction,

  /* Get all department */
  getAllDepartmentsRequest: getAllDepartmentsRequestAction,
  getAllDepartmentsSuccess: getAllDepartmentsSuccessAction,
  getAllDepartmentsFailure: getAllDepartmentsFailureAction,

  /* Fetch company list */
  fetchCompanyListRequest: fetchCompanyListRequestAction,
  fetchCompanyListSuccess: fetchCompanyListSuccessAction,
  fetchCompanyListFailure: fetchCompanyListFailureAction,

  /* Temp state that rarely used */
  tempAuthStoreRequest: tempAuthStoreRequestAction,
  tempAuthStoreSuccess: tempAuthStoreSuccessAction,

  /* Get all users */
  getAllUsersRequest: getAllUsersRequestAction,
  getAllUsersSuccess: getAllUsersSuccessAction,
  getAllUsersFailure: getAllUsersFailureAction,

  /* Create Guest User */
  createNewGuestRequest: createNewGuestRequestAction,
  createNewGuestSuccess: createNewGuestSuccessAction,
  createNewGuestFailure: createNewGuestFailureAction,

  oAuthCallBackFailure: oAuthCallBackFailureAction,
  oAuthCallBackRequest: oAuthCallBackRequestAction,
  oAuthCallBackSuccess: oAuthCallBackSuccessAction,

  updateUserProfileFailure: updateUserProfileFailureAction,
  updateUserProfileRequest: updateUserProfileRequestAction,
  updateUserProfileSuccess: updateUserProfileSuccessAction,
} = authSlice.actions;

/*
 * ======================================================
 * Export the reducer to be used in the store
 * ======================================================
 */
export default authSlice.reducer;
