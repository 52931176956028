import { Stack, Typography } from "@mui/material";
import React from "react";
import { getFileIcon } from "../../../../../../Utils";

interface AttachmentChipViewProps {
  name: string;
}

const AttachmentChipView: React.FC<AttachmentChipViewProps> = ({
  name,
}) => {
  return (
    <Stack
      sx={{
        width: "150px",
        height: "20px",
        borderRadius: "25px",
        border: "0.5px solid #E9EDF2",
        padding: "0px 10px",
        gap: "5px",
        flexShrink: 0,
        boxSizing: "border-box",

        flexDirection: "row",
        alignItems: "center",
      }}
    >
      {getFileIcon({ iconGroup: "group2", name: name, iconSize: 15 })}
      <Typography
        sx={{
          font: "normal normal 300 11px/14px Source Serif Pro",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {name}
      </Typography>
    </Stack>
  );
};

export default AttachmentChipView;
