import React from "react";
import { Stack } from "@mui/material";
import { InlineContainer } from "./view";

interface TimesheetContainerProps {}

const TimesheetContainer: React.FC<TimesheetContainerProps> = ({}) => {
  return (
    <Stack sx={{ flexDirection: "row" }}>
      <InlineContainer
        bgcolor="#BCC4F1F0"
        opacity={45}
        label="Regular"
        value="45 hrs"
        sx={{ borderRadius: "5px 0px 0px 5px" }}
      />
      <InlineContainer
        bgcolor="#AFBAF6"
        opacity={45}
        label="PTO"
        value="45 hrs"
      />
      <InlineContainer
        bgcolor="#96A3ED"
        opacity={45}
        label="Overtime"
        value="45 hrs"
      />
      <InlineContainer
        bgcolor="#6173D6"
        opacity={45}
        label="Holiday"
        value="45 hrs"
      />
      <InlineContainer
        bgcolor="#7A89DD"
        opacity={75}
        label="Total Hours"
        value="45 hrs"
        fontColor="white"
        sx={{ borderRadius: "0px 5px 5px 0px" }}
      />
    </Stack>
  );
};

export default TimesheetContainer;
