import { Stack } from "@mui/material";
import React from "react";
import { DateHeaderView, EventView, TaskView } from "./view";
import { adjustHexColor, CalendarTypesEnum } from "../../../../../Utils"; // Import your enum

interface SidebarCalendarProps {
  dataList: any[]; // The raw data you're passing in (the original 'datalist')
  transparantBackground?: boolean;
  maxHeight?: string;
}

const SidebarCalendar: React.FC<SidebarCalendarProps> = ({
  dataList,
  transparantBackground = true,
  maxHeight = "100vh",
}) => {
  // Transformation function to convert raw data into SidebarCalendarDataListInterface format
  const transformDataList = (data: any) => {
    const groupedData: any = {};

    data?.forEach((item: any) => {
      // Convert date to a human-readable format (e.g., Tuesday, Sep 10)
      const date = new Date(item?.date).toLocaleDateString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      // Prepare the task or event object based on the type
      const dataItem = {
        type: item?.taskOrEvent,
        data: {
          id: item?._id,
          taskName: item?.taskOrEvent === "task" ? item?.title : undefined,
          isCompleted:
            item?.taskOrEvent === "task" ? item?.forTaskCompletion : undefined,
          taskTime:
            item?.startTime + " - " + item?.endTime + " " + item?.timezone,
          calendarType:
            item?.eventType === "meeting"
              ? CalendarTypesEnum.GoogleCalendar
              : CalendarTypesEnum.OutlookCalendar,
          eventName: item?.taskOrEvent === "event" ? item?.title : undefined,
          eventTime:
            item?.startTime + " - " + item?.endTime + " " + item?.timezone,
          status: item?.isDeleted ? "removed" : "pending", // Modify as needed
        },
      };

      // Group by date
      if (!groupedData?.[date]) {
        groupedData[date] = [];
      }
      groupedData[date]?.push(dataItem);
    });

    // Convert grouped data into the final format
    return Object?.entries(groupedData)?.map(([date, dataList], index) => ({
      id: index + 1, // or use a unique identifier from your data if available
      dateAndTime: date,
      dataList,
    }));
  };

  // Ensure `transformDataList` returns an array, even if `dataList` is undefined or empty
  const finalDataList: any[] =
    Array.isArray(dataList) && dataList.length > 0
      ? transformDataList(dataList)
      : [];

  return (
    <Stack
      sx={{
        width: "265px",
        height: "100%",
        overflowY: "scroll",
        scrollbarWidth: "none",
        backgroundColor: transparantBackground
          ? adjustHexColor("#ffffff", 65)
          : "white",
        borderRadius: "5px",
        padding: "12px 12px 15px 15px",
        boxSizing: "border-box",
        gap: "3px",
        maxHeight: { maxHeight },
        borderTop: transparantBackground ? "1px solid #E9EDF2" : "",
        borderBottom: transparantBackground ? "1px solid #E9EDF2" : "",
        borderLeft: transparantBackground ? "1px solid #E9EDF2" : "",
      }}
    >
      {Array?.isArray(finalDataList) && finalDataList?.length > 0 ? (
        finalDataList?.map((item: any) => (
          <Stack sx={{ gap: "3px" }} key={item?.id}>
            <DateHeaderView date={item?.dateAndTime} />
            <Stack sx={{ gap: "3px" }}>
              {Array?.isArray(item?.dataList) &&
                item?.dataList?.map((value: any) =>
                  value?.type === "event" ? (
                    <EventView key={value?.data?.id} events={value?.data} />
                  ) : (
                    <TaskView key={value?.data?.id} tasks={value?.data} />
                  )
                )}
            </Stack>
          </Stack>
        ))
      ) : (
        <div>No data available</div> // Handle empty or invalid data
      )}
    </Stack>
  );
};

export default SidebarCalendar;
