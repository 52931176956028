import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CenterContainer,
  HeaderView,
  LeftContainer,
  RightContainer,
} from "./view";
import { EmpDirectoryType } from "../../EmployeeDirectory";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/rootReducer";
import { useNavigate } from "react-router-dom";
import { employeeDrawerHandler } from "../../../../../redux";

interface EmployeePreviewPopupLargeProps {
  onPreviewButtonClick: () => void;
}

const EmployeePreviewPopupLarge: React.FC<EmployeePreviewPopupLargeProps> = ({
  onPreviewButtonClick,
}) => {
  const navigate = useNavigate();
  const traxState = useSelector((state: RootState) => state?.trax);
  const { popupEmployeeDataId, dirEmployees } = traxState || {};
  const [empData, setEmpData] = useState<EmpDirectoryType>();
  const dispatch = useDispatch();

  const fullViewHandler = (empId: string) => {
    dispatch(employeeDrawerHandler(false));
    navigate(`/Trax/employee-directory/${empId}`);
  };

  useEffect(() => {
    if (dirEmployees && popupEmployeeDataId) {
      const data = dirEmployees.find(
        (item) => item._id === popupEmployeeDataId
      );
      setEmpData(data);
    }
  }, [popupEmployeeDataId, dirEmployees]);

  if (!empData) {
    return null; // Render nothing until popupData is available
  }

  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        bgcolor: "white",
        overflow: "hidden",
        borderRadius: "5px",
      }}
    >
      <HeaderView
        onPreviewButtonClick={onPreviewButtonClick}
        viewFullDetailsBtnClick={() => fullViewHandler(empData._id)}
      />
      <Stack
        sx={{
          flexDirection: "row",
          flexGrow: 1,
          marginTop: "24px",
          overflow: "auto",
        }}
      >
        {/* Left Container */}
        <Stack
          sx={{
            width: "260px",
            padding: "0px 15px 0px 25px",
            boxSizing: "border-box",
            flexShrink: 0,
            borderRight: "1px solid #E9EDF2",
          }}
        >
          <LeftContainer EmpData={empData} />
        </Stack>

        <Stack
          sx={{
            flex: 1,
            flexDirection: "row",
            overflow: "auto",
            scrollbarWidth: "none",
          }}
        >
          {/* Center Container */}
          <Stack
            sx={{
              flex: 1,
              scrollbarWidth: "none",
            }}
          >
            <CenterContainer
              sx={{
                padding: "0px 20px",
                borderRight: "1px solid #E9EDF2",
              }}
            />
          </Stack>

          {/* Right Container */}
          <Stack
            sx={{
              width: "260px",
              padding: "0px 20px",
              scrollbarWidth: "none",
              flexShrink: 0,
              boxSizing: "border-box",
            }}
          >
            <RightContainer />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default EmployeePreviewPopupLarge;
