import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../Utils";
import { StreamBearishOSLogoIconPng } from "../../../../../images";
import styles from "./UpcomingCallCard.module.css";

interface UpcomingCallCardProps {
  isSelected?: boolean;
  meetingDetail?: string;
  meetingTime?: string;
  meetingId?: string;
  handleMeetingClick?: any;
  meeting_Id?: string;
  meetingDate?: string;
}

const UpcomingCallCard: React.FC<UpcomingCallCardProps> = ({
  isSelected = false,
  meetingDetail = "Instant Meeting",
  meetingTime = "11:03 AM",
  meetingId,
  handleMeetingClick,
  meeting_Id,
  meetingDate = "2024-11-18T15:23:00",
}) => {
  const formatMeetingDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    };
    return date.toLocaleDateString('en-GB', options);
  };

  meetingDate = formatMeetingDate(meetingDate);
  const formattedDateTime = `${meetingDate} - ${meetingTime}`;
  return (
    <Stack className={styles.upcomingCallCard}>
      <Stack className={styles.upcomingCallCard__indicator} />
      <Stack
        className={styles.upcomingCallCard__content}
        sx={{ bgcolor: isSelected ? adjustHexColor("#E9EDF2", 35) : "inherit" }}
        onClick={() => handleMeetingClick(meeting_Id)}
      >
        <Stack className={styles.upcomingCallCard__header}>
          <Box
            component={"img"}
            src={StreamBearishOSLogoIconPng}
            sx={{ height: "20px", width: "20px" }}
            alt="logo"
          />
          <Typography className={styles.upcomingCallCard__meetingName}>
            {meetingDetail}
          </Typography>
        </Stack>
        <Typography className={styles.upcomingCallCard__time}>
          {formattedDateTime}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default UpcomingCallCard;
