import { EmployeeDirectory } from "./EmployeeDirectory";
import { Status } from "./Status";

export type TraxPaths =
    | "/Trax/employee-directory"
    | "/Trax/time-attendance"
    | "/Trax/expenses"
    | "/Trax/payroll"
    | "/Trax/reviews"
    | "/Trax/development"
    | "/Trax/compliance"
    | "/Trax/auditing-reports";

export const TraxComponentMap: Record<TraxPaths, React.ComponentType> = {
    "/Trax/employee-directory": EmployeeDirectory,
    "/Trax/time-attendance": Status,
    "/Trax/expenses": Status,
    "/Trax/payroll": Status,
    "/Trax/reviews": Status,
    "/Trax/development": Status,
    "/Trax/compliance": Status,
    "/Trax/auditing-reports": Status,
};
