import React, { useState, useEffect } from "react";
import { PublicTopBar } from "../../../common";
import { Box, Stack } from "@mui/material";
import {
  BookEventer,
  BookEventerSubmitSlot,
  BookEventerTimeSlot,
  BookEventSuccess,
} from "./components";
import { useParams } from "react-router-dom";
import axios from "axios";
import { isMobile, isTablet, browserName } from "react-device-detect";
import {
  createBookingEvent__api,
  getPublicBookingLink__api,
  trackVisit__api,
} from "../../../../redux";
import { useDispatch } from "react-redux";

type Params = Record<string, string | undefined>;

const BookingEventPublicPage = () => {
  const dispatch = useDispatch();
  const { slug } = useParams<Params>();

  // State variables
  const [loginUserData, setLoginUserData] = useState<null | any | {}>(null);
  const [bookingReady, setBookingReady] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const [hostData, setHostData] = useState<any>();
  const [linkData, setLinkData] = useState<any>();

  // Console logs for debugging
  // Fetch the user's IP information
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const res = await axios.get("https://ipapi.co/json/");
        setLoginUserData(res.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  // Fetch booking link data
  const getHostLinkData = async () => {
    try {
      // Validate necessary inputs
      if (!slug || !loginUserData?.ip) {
        console.warn("Missing slug or IP address. Cannot fetch data.");
        return;
      }

      setIsLoading(true); // Start loading

      // Prepare payload
      const payload = {
        ipAddress: loginUserData.ip, // User's IP address
        browser: browserName || "Unknown", // Browser name
        deviceType: isMobile ? "Mobile" : isTablet ? "Tablet" : "Desktop", // Device type
      };

      // Dispatch action to fetch booking link data
      const getLinkDataRes: any = await dispatch(
        getPublicBookingLink__api(slug, payload)
      );

      // Check response and update state
      if (getLinkDataRes?.success) {
        setBookingReady(true); // Set booking ready state
        setHostData(getLinkDataRes.data.host); // Set host data
        setLinkData(getLinkDataRes.data.bookingLink); // Set link data
      } else {
        console.error(
          "Failed to fetch link data:",
          getLinkDataRes?.message || "Unknown error"
        );
      }
    } catch (err) {
      console.error("Error getting host link data:", err);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  // Effect to fetch link data when user data is available
  useEffect(() => {
    if (loginUserData) {
      getHostLinkData(); // Fetch link data after user data is available
    }
  }, [loginUserData, slug]); // Depend on user data and slug

  // Effect to track user visits
  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (bookingReady) {
      intervalId = setInterval(() => {
        keepTrackingUser();
      }, 2000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [bookingReady]); // Track when bookingReady changes

  // Function to track user visits
  const keepTrackingUser = async () => {
    try {
      if (!slug || !loginUserData?.ip) {
        return;
      }

      // Construct the payload
      const payload = {
        ipAddress: loginUserData.ip, // Use the fetched IP address
        browser: browserName || "Unknown", // Browser name
        deviceType: isMobile ? "Mobile" : isTablet ? "Tablet" : "Desktop", // Device type
      };

      // Dispatch the tracking action
      dispatch(trackVisit__api(slug, payload));
    } catch (err) {
      console.error("Error tracking user visit:", err);
    }
  };

  const [selectedTimeSlot, setSelectedTimeSlot] = useState<any | null>(null);
  const [dateSelected, setdateSelected] = useState<any | null>(null);

  const handleBookMyEvent = async (bookingEventData: any) => {
    // Validate required booking event data
    const { timeSlot, firstName, lastName, date } = bookingEventData || {};
    if (!timeSlot || !firstName || !lastName || !date || !slug) {
      console.warn("Missing required booking event data.");
      return;
    }

    try {
      // Construct the updated booking event data
      const updatedBookingEventData = {
        ...bookingEventData,
        ipAddress: loginUserData?.ip || "Unknown IP", // Include IP address
        device: isMobile ? "Mobile" : isTablet ? "Tablet" : "Desktop", // Device type
        browser: browserName || "Unknown Browser", // Browser name
      };

      // Dispatch the API call to create a booking event
      const action = createBookingEvent__api(slug, updatedBookingEventData);
      const bookSuccess: any = await dispatch(action);

      // Set success state based on response
      setShowSuccess(bookSuccess?.success || false);
    } catch (err) {
      console.error("Error booking event:", err); // Improved error logging
      setShowSuccess(false); // Ensure to set success state to false on error
    }
  };

  const redirectToLogin = () => {
    window.open("https://os.bearishos.com/login", "_blank");
  };

  return (
    <Box>
      <PublicTopBar redirectToLogin={redirectToLogin} />
      <Stack
        direction={"row"}
        alignItems={"flex-start"}
        justifyContent={"space-evenly"}
        gap={1}
        mt={3}
      >
        <BookEventer hostData={hostData} linkData={linkData} />
        {isLoading && <p>Loading...</p>} {/* Show loading indicator */}
        {/* {bookingReady && <BookEventSuccess />} */}
        {!showSuccess && (
          <BookEventerTimeSlot
            setdateSelected={setdateSelected}
            setSelectedTimeSlot={setSelectedTimeSlot}
          />
        )}
        {!showSuccess && selectedTimeSlot !== null && (
          <BookEventerSubmitSlot
            handleBookMyEvent={handleBookMyEvent}
            dateSelected={dateSelected}
            selectedTimeSlot={selectedTimeSlot}
          />
        )}
        {showSuccess && (
          <BookEventSuccess
            redirectToLogin={redirectToLogin}
            dateSelected={dateSelected}
          />
        )}
      </Stack>
    </Box>
  );
};

export default BookingEventPublicPage;
