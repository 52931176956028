import { IconButton, Tooltip, Typography, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { adjustHexColor } from "../../../../../Utils";
import {useTranslation} from "react-i18next";

interface PlaybackButtonProps {
  initialValue?: string;
  onSelect: (value: string) => void;
}

const PlaybackButton: React.FC<PlaybackButtonProps> = ({
  onSelect,
  initialValue = "1",
}) => {
  const speed: string[] = ["1", "1.25", "1.50", "1.75", "2"];
  const {t}=useTranslation()

  const [selectedSpeed, setSelectedSpeed] = useState(initialValue);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectHandler = (value: string): void => {
    setSelectedSpeed(value);
    handleClose();
    onSelect(value);
  };

  return (
    <>
      <Tooltip title={t("PAST_CALL_DETAILS.PLAYBACK_SPEED")} placement="top" arrow disableInteractive>
        <IconButton
          sx={{
            p: 0,
            height: "50px",
            width: "50px",
            boxSizing: "border-box",
            border: "1px solid white",
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.16)",
            bgcolor: adjustHexColor("#E9EDF2", 50),
            ":hover": {
              bgcolor: adjustHexColor("#E9EDF2", 60),
            },
          }}
          onClick={handleClick}
        >
          <Typography
            sx={{
              font: "normal normal 900 17px/21px Source Serif Pro",
              color: "black",
            }}
          >
            {selectedSpeed}x
          </Typography>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            width: "115px",
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            borderRadius: "5px",
            boxSizing: "border-box",
            padding: "5px 0px 0px 0px",
            marginTop: "10px",
          },
        }}
      >
        <Typography
          sx={{
            font: "normal normal 400 13px/16px Source Serif Pro",
            padding: "0px 0px 2px 10px",
          }}
        >
          Playback speed
        </Typography>

        {speed.map((item, index) => (
          <MenuItem
            key={index}
            sx={{ minHeight: 0, height: "25px", paddingLeft: "10px" }}
            onClick={() => selectHandler(item)}
          >
            <Typography
              sx={{
                font: "normal normal 300 13px/16px Source Serif Pro",
              }}
            >
              {item}x
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default PlaybackButton;
