import React from 'react';

interface CreateQuickCallViewProps {
  
}

const CreateQuickCallView: React.FC<CreateQuickCallViewProps> = ({  }) => {
  return <div>CreateQuickCallView</div>;
};

export default CreateQuickCallView;