import { Stack, Typography } from "@mui/material";
import React from "react";

type PrimaryLocationTableProps = {
  TableData: any;
};

const PrimaryLocationTable: React.FC<PrimaryLocationTableProps> = ({
  TableData,
}) => {
  // const { state, city, zipCode, country } = TableData;

  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        gap: "5px",
        flexWrap: "wrap",
        font: "normal normal 400 13px/16px Source Serif Pro",
      }}
    >
      <Typography sx={{ font: "inherit" }}>{TableData}</Typography>
      {/* <Typography sx={{ font: "inherit" }}>{city}</Typography>
      {country && <Typography sx={{ font: "inherit" }}>{country}</Typography>}
      {zipCode && <Typography sx={{ font: "inherit" }}>- {zipCode}</Typography>} */}
    </Stack>
  );
};

export default PrimaryLocationTable;
