import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import {
  EmailIcon,
  FirstMessageIcon,
  QuickMessageIcon,
} from "../../../../../../../../images";
import { TouchPointVariantEnum, TouchPointViewProps } from "../TouchPointsType";

type MsgInnerViewProps = Extract<
  TouchPointViewProps,
  { variant: TouchPointVariantEnum.Message }
>;

const MsgInnerView: React.FC<MsgInnerViewProps> = ({ timeZone, timestamp }) => {
  return (
    <Stack sx={{ flexDirection: "row", alignItems: "start", gap: "15px" }}>
      <Box sx={{ flexShrink: 0 }}>
        <QuickMessageIcon w={15} />
      </Box>
      <Stack
        sx={{
          flexDirection: "column", // Change to column to stack elements vertically
          alignItems: "flex-start", // Align items to the start
          gap: "4px",
          font: "normal normal 300 13px/16px Source Serif Pro",
        }}
      >
        <Typography
          sx={{
            font: "inherit",
            display: "block", // Display in a block to ensure it takes full width
            whiteSpace: "nowrap", // Prevent wrapping of "Message thread..."
          }}
        >
          Message thread...
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
            flexWrap: "wrap",
          }}
        >
          <span>on</span>
          <span>{timestamp}</span>
          <span>{timeZone}</span>
        </Box>
      </Stack>
    </Stack>
  );
};

export default MsgInnerView;
