import React, { useState } from "react";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import { NotSelectedIcon, SelectedIcon } from "./CheckboxIcons";

interface CheckboxMediumProps extends CheckboxProps {
  boxSize?: number;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  readonlyComponent?: boolean;
}

/* 

onClick={(e: any) => {
                updateField && updateField("checker", e?.target?.checked);
              }}

*/

const CheckboxMedium: React.FC<CheckboxMediumProps> = ({
  boxSize = 20,
  checked: checkedProp,
  onChange,
  readonlyComponent = false,
  ...props
}) => {
  // Local state to manage the checked value if not provided through props
  const [checked, setChecked] = useState(checkedProp ?? false);

  // Update the local state when the checked prop changes
  React.useEffect(() => {
    if (typeof checkedProp !== "undefined") {
      setChecked(checkedProp);
    }
  }, [checkedProp]);

  // Create a wrapper function for the onChange handler
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!readonlyComponent) {
      event.preventDefault(); // Prevent default action
      event.stopPropagation(); // Stop event propagation

      // Toggle the checked state internally
      setChecked((prev) => !prev);

      // Call the provided onChange handler if available
      if (onChange) {
        onChange(event);
      }
    }
  };

  return (
    <Checkbox
      {...props}
      size="small"
      sx={{
        p: 0,
        borderRadius: "5px",
        height: `${boxSize}px`,
        width: `${boxSize}px`,
        boxSizing: "border-box",
        flexShrink: 0,
      }}
      checked={checked}
      onChange={handleChange} // Attach the wrapped onChange handler
      icon={<NotSelectedIcon iconSize={boxSize} />}
      checkedIcon={<SelectedIcon iconSize={boxSize} />}
    />
  );
};

export default CheckboxMedium;
