import React, { useEffect, useState } from "react";
import { IconDropDownButton } from "../IconDropDownButton";
import { SortIcon } from "../../../images";
import { Popover } from "@mui/material";
import {
  DragAndDropMenu,
  DragAndDropMenuListType,
  SortMenu,
  SortSelectedListType,
} from "../DragAndDropMenu";
import { useTranslation } from "react-i18next";

interface DropdownButtonSortMenuProps {
  sortOption: DragAndDropMenuListType[];
  sortedList?: (listData: SortSelectedListType[]) => void;
  buttonColor: "CRMOrange" | "TraxBluish";
}

const DropdownButtonSortMenu: React.FC<DropdownButtonSortMenuProps> = ({
  sortOption,
  buttonColor,
  sortedList,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOpt, setMenuOpt] = useState<DragAndDropMenuListType[]>(sortOption);

  const [selectedValueList, setSelectedValueList] = useState<
    SortSelectedListType[]
  >([]);

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget); // Toggle the popover
  };

  const handleClose = () => {
    setAnchorEl(null); // Close the popover
  };

  const open = Boolean(anchorEl);
  const id = open ? "dropdown-popover" : undefined;

  const menuSelectHandler = (value: DragAndDropMenuListType) => {
    const data: SortSelectedListType = {
      dragable: true,
      order: "Ascending",
      selectedItem: value,
    };
    setSelectedValueList((pre) => [...pre, data]);
  };

  const updateSelectionList = (list: SortSelectedListType[]) => {
    const uniqueList = list.filter(
      (item, index, self) =>
        index ===
        self.findIndex((t) => t.selectedItem.label === item.selectedItem.label)
    );

    setSelectedValueList(uniqueList);
  };

  useEffect(() => {
    if (sortedList) {
      sortedList(selectedValueList);
    }
  }, [selectedValueList]);

  return (
    <div>
      <IconDropDownButton
        label={selectedValueList.length > 0 ? t("TRAX.SORTED") : t("TRAX.SORT")}
        icon={SortIcon}
        numCount={
          selectedValueList.length > 0 ? selectedValueList.length : undefined
        }
        menuOpen={open} // Menu is open if anchorEl is set
        onButtonClick={handleButtonClick} // Handle button click
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            padding: 0,
            // width: "160px",
            marginTop: "10px",
            zIndex: 100,
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.16)",
            borderRadius: "5px",
          },
        }}
      >
        {selectedValueList.length > 0 ? (
          <SortMenu
            buttonColor={buttonColor}
            options={menuOpt}
            SelectedOptList={selectedValueList}
            onChange={(newList) => updateSelectionList(newList)}
          />
        ) : (
          <DragAndDropMenu
            labelIcon={SortIcon}
            menuItems={menuOpt}
            label={t("TRAX.SORT_BY_COLUMN")}
            setMenuItems={(newValue) => setMenuOpt(newValue)}
            onMenuSelect={(opt) => menuSelectHandler(opt[0])}
            AddButtonLabel={t("TRAX.ADVANCE_SORT")}
            AddButtonClick={() => null}
            dragIconPlace="left"
            menuWidth="250px"
            maxHeight="400px"
            backgroundColor="white"
          />
        )}
      </Popover>
    </div>
  );
};

export default DropdownButtonSortMenu;
