import React from "react";
import IconButton from "@mui/material/IconButton";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/system";
import { MenuIcon } from "../../../images"; // replace with your actual import
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import styles from "./ThreeDotMenuLarge.module.css";
import {
  ThreeDotLargeMenuHeaderOptions,
  ThreeDotLargeMenuHeaderOptionsInterface,
  ThreeDotLargeMenuItemProps,
} from "./ThreeDotMenuTypes";

const StyledMenu = styled((props: MenuProps) => {
  const horizontal =
    props.anchorEl instanceof HTMLElement &&
    props.anchorEl.getAttribute("data-display-option") === "right"
      ? "center"
      : "center";

  const transformHorizontal =
    props.anchorEl instanceof HTMLElement &&
    props.anchorEl.getAttribute("data-display-option") === "right"
      ? "left"
      : "right";

  return (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal,
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: transformHorizontal,
      }}
      {...props}
    />
  );
})(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 5,
    border: "0.5px solid #E9EDF2",
    minWidth: 225,
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    maxHeight: "450px",
    "& .MuiMenu-list::-webkit-scrollbar": {
      width: "0px",
    },
  },
}));

interface ThreeDotMenuLargeProps {
  options: ThreeDotLargeMenuItemProps[];
  getClickedOption?: (name: any) => void;
  headersOptions?: ThreeDotLargeMenuHeaderOptionsInterface[];
  displayOption?: "left" | "right";
  tooltipText?: string;
}

// Define TextOptionsProps
type TextOptionsProps = ThreeDotLargeMenuHeaderOptionsInterface & {
  onClick: () => void;
};

// Inline component
const TextOption: React.FC<TextOptionsProps> = (props) => {
  const { textOrIcon, textStyle, title, titleStyle, onClick } = props;

  return (
      <Box
      component="span"
      onClick={onClick}
      className={styles["three-dot-menu-large__text-option"]}
      sx={{ ":hover": { bgcolor: "#f1f1f1" } }}
    >
      {typeof textOrIcon === "string" ? (
        <Typography
          style={{ font: textStyle?.font }}
          className={styles["three-dot-menu-large__typography--title"]}
        >
          {textOrIcon}
        </Typography>
      ) : (
        <span style={{ marginBottom: "2px" }}>
          {React.createElement(textOrIcon, { w: 20 })}
        </span>
      )}

      <Typography
        style={{ font: titleStyle.font }}
        className={styles["three-dot-menu-large__typography--subtitle"]}
      >
        {title}
      </Typography>
    </Box>
  );
};

const ThreeDotMenuLarge: React.FC<ThreeDotMenuLargeProps> = ({
  options,
  getClickedOption,
  headersOptions = ThreeDotLargeMenuHeaderOptions,
  displayOption = "left",
  tooltipText,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.currentTarget.setAttribute("data-display-option", displayOption);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {tooltipText ? (
        <Tooltip title={tooltipText} arrow placement="top">
          <IconButton
            onClick={handleClick}
            className={styles["three-dot-menu-large__button"]}
            disableRipple
            disableFocusRipple
          >
            <MenuIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton
          onClick={handleClick}
          className={styles["three-dot-menu-large__button"]}
          disableRipple
          disableFocusRipple
        >
          <MenuIcon />
        </IconButton>
      )}

      <StyledMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Stack
          sx={{
            height: "90px",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          {headersOptions.map((option, index) => (
            <TextOption
              {...option}
              key={index}
              onClick={() => {
                getClickedOption && getClickedOption(option?.title || "title");
                handleClose();
              }}
            />
          ))}
        </Stack>
        <Divider />
        {options.map((item, index) =>
          item.divider ? (
            <Divider key={index} />
          ) : (
            <MenuItem
              key={index}
              onClick={() => {
                getClickedOption && getClickedOption(item?.text || "title");
                handleClose();
              }}
              sx={{
                padding: "0px 15px",
                height: "35px",
                color: "black",
                alignItems: "center",
                gap: "10px",
                minHeight: 0,
              }}
            >
              {React.createElement(item.icon, { color: "black", w: 15, ...item.iconProps })}
              <Typography
                sx={{ font: "normal normal 300 14px/20px Source Serif Pro" }}
              >
                {item.text}
              </Typography>
            </MenuItem>
          )
        )}
      </StyledMenu>
    </>
  );
};

export default ThreeDotMenuLarge;
