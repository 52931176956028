import { IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { GreenDropdownIcon } from "../../../../../../../images";

interface CardScrollableContainerViewProps {
  children?: React.ReactNode;
  hideMoreButton?: boolean;
}

const CardScrollableContainerView: React.FC<
  CardScrollableContainerViewProps
> = ({ children, hideMoreButton = false }) => {
  const [isExpended, setIsExpended] = useState<boolean>(false);
  return (
    <Stack sx={{ gap: "10px", overflow: "scroll", scrollbarWidth: "none" }}>
      <Stack
        sx={{
          maxHeight: isExpended ? "auto" : "250px",
          overflow: isExpended ? "visible" : "hidden",
        }}
      >
        {children}
      </Stack>

      {!hideMoreButton && (
        <Stack sx={{ flexDirection: "row", justifyContent: "start" }}>
          <IconButton
            disableRipple
            onClick={() => setIsExpended((pre) => !pre)}
            sx={{
              p: "1px 5px 2px 10px",
              borderRadius: 0,
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <GreenDropdownIcon open={!isExpended} />
            <Typography
              sx={{ font: "normal normal 400 11px/14px Source Serif Pro" }}
            >
              {isExpended ? "View Less" : "View More"}
            </Typography>
          </IconButton>
        </Stack>
      )}
    </Stack>
  );
};

export default CardScrollableContainerView;
