export type RegularMenuItem = {
  id: number;
  divider?: false;
  text: string;
  textColor?: string;
  icon?: React.FC<{ w?: number; color?: string; direction?: "up" | "down" | "left" | "right"; }>;
  color?: string;
  direction?: "up" | "down" | "left" | "right";
  LANG?: string
}


type DividerMenuItem = {
  id: number;
  divider: true;
  text?: undefined;
  textColor?: undefined;
  icon?: undefined;
  color?: undefined;
  direction?: undefined;
  LANG?: undefined
};

export type SmallThreeDotMenuListInterface = RegularMenuItem | DividerMenuItem;

export const commentsThreeDotMenuList: SmallThreeDotMenuListInterface[] = [
  { id: 1, text: "View Resolved Comments" },
  { id: 2, text: "View Current Comments" },
  { id: 3, text: "View All Comments" },
];
