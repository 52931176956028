import { SortSelectedListType } from "../MenuTypes";

export const handleSortMenuDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    index: number
) => {
    event.dataTransfer.setData("index", index?.toString());
};

export const sortMenuHandleDrop = (
    event: React.DragEvent<HTMLDivElement>,
    menuItems: SortSelectedListType[],
    setMenuItems: React.Dispatch<React.SetStateAction<SortSelectedListType[]>>
) => {
    event.preventDefault();
    const fromIndex = Number(event.dataTransfer.getData("index"));
    const toIndex = Number(event.currentTarget.getAttribute("data-index"));
    const updatedItems = [...menuItems];
    const [draggedItem] = updatedItems.splice(fromIndex, 1);
    updatedItems.splice(toIndex, 0, draggedItem);
    setMenuItems(updatedItems);
};

export const sortMenuAllowDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
};