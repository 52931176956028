import React, { useEffect, useState } from "react";
import { Stack, Popover } from "@mui/material";
import { LastCalendarIcon } from "../../../../../../../images";
import {
  DatePickerMini,
  DatePickerWithIcon,
  DropdownWIconLarge,
} from "../../../../../../common";
import { getAllTimezonesFormatted } from "../../../../../Settings/Components/common";

interface EventTimeViewProps {
  updateField?: any;
  updateDate?: any;
  updateStartTime?: any;
  updateEndTime?: any;
}

const EventTimeView: React.FC<EventTimeViewProps> = ({
  updateField,
  updateDate,
  updateEndTime,
  updateStartTime,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [timezones, setTimezones] = useState<string[]>([]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "pst-popover" : undefined;

  useEffect(() => {
    const fetchedTimezones = getAllTimezonesFormatted();
    setTimezones(
      fetchedTimezones?.map((timezone) => timezone?.name || timezone?.id)
    );
  }, []);

  const handleDateChange = (data: any) => {
    if (data && data?.$d) {
      const isoDate = new Date(data?.$d)?.toISOString();
      // Only update start and end date-time fields (combined date + time)
      updateField && updateField("start", isoDate); // Update the start field
      updateField && updateField("end", isoDate); // Update the end field (default to same time)
    }
  };

  const handleTimeChange = (field: "startTime" | "endTime", time: any) => {
    if (time) {
      const formattedTime = time.format("HH:mm"); // Use 24-hour format
      if (field === "startTime") {
        updateStartTime && updateStartTime(formattedTime);
      } else if (field === "endTime") {
        updateEndTime && updateEndTime(formattedTime);
      }
    }
  };

  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        font: "inherit",
        justifyContent: "space-between",
      }}
    >
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
        <LastCalendarIcon w={20} />
        <DatePickerWithIcon
          hideIcon
          onDateChange={updateDate && updateDate}
          dateFormat="MMM D, YYYY"
          sx={{
            width: "85px",
            font: "normal normal 500 15px/19px Source Serif Pro",
          }}
        />
      </Stack>

      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
        <DatePickerMini
          hideIcon
          sx={{
            p: 0,
            border: "none",
            width: "65px",
            height: "20px",
            font: "normal normal 500 15px/19px Source Serif Pro",
          }}
          onTimeChange={(time) => {
            console.log("🚀 ----------------🚀");
            console.log("🚀 ~ startTime time:", time);
            console.log("🚀 ----------------🚀");

            handleTimeChange("startTime", time);
          }}
        />
        -
        <DatePickerMini
          hideIcon
          sx={{
            p: 0,
            border: "none",
            width: "65px",
            height: "20px",
            font: "normal normal 500 15px/19px Source Serif Pro",
          }}
          onTimeChange={(time) => {
            console.log("🚀 ~ endTime time:", time);

            handleTimeChange("endTime", time);
          }}
        />
      </Stack>

      <Stack onClick={handleClick} sx={{ cursor: "pointer" }}>
        PST
      </Stack>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: { boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)" },
        }}
      >
        <DropdownWIconLarge
          onChange={(e: any) => updateField && updateField("timezone", e)}
          options={timezones}
          icon="chevron"
        />
      </Popover>
    </Stack>
  );
};

export default EventTimeView;
