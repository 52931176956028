import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { AvatarNormal } from "../AvatarNormal";
import { XCircleIcon } from "../../../images";

interface ChipWithAvatarProps {
  userName: string;
  userImgSrc?: string;
  userAvatarClr?: string;
  onCloseButtonClick?: () => void;
}

const ChipWithAvatar: React.FC<ChipWithAvatarProps> = ({
  onCloseButtonClick,
  userName,
  userAvatarClr,
  userImgSrc,
}) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        gap: "5px",
        border: "0.5px solid #E9EDF2",
        borderRadius: "50px",
        boxSizing: "border-box",
        padding: "5px 2px 5px 5px",
        font: "normal normal 300 13px/16px Source Serif Pro",
      }}
    >
      <AvatarNormal
        size={20}
        username={userName}
        avatarColor={userAvatarClr}
        imgSrc={userImgSrc}
      />
      <Typography sx={{ font: "inherit" }}>{userName}</Typography>

      <IconButton sx={{ p: "4px" }} onClick={onCloseButtonClick}>
        <XCircleIcon color="black" w={13} />
      </IconButton>
    </Stack>
  );
};

export default ChipWithAvatar;
