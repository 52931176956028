import { RadioGroup, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import {
  ChipInputField,
  CustomRadioFormControlLabel,
} from "../../../../../../common";

interface SubjectKeywordViewProps {
  updateFilterData: (key: "name" | "from" | "subjectKeywords" | "subjectMatchType" | "bodyKeywords" | "bodyMatchType" | "hosts" | "basicFilters" | "priorityLevel" | "advancedActions", value: any) => void;
  subKeydData: string[] | any;
  subMatchType: string | any;
}

const SubjectKeywordView: React.FC<SubjectKeywordViewProps> = ({ updateFilterData, subKeydData = [], subMatchType }) => {
  const [  subjectKeyword, setSubjectKeyword] = React.useState("EXACT_MATCH");

  const handleSubjectRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    if (value !== subjectKeyword) {
      setSubjectKeyword(value);
      updateFilterData("subjectMatchType", value);
    };
  };

  const handleDropdownChange = (e: string[]) => {
    const selectedValues = e?.map(option => option);
    updateFilterData("subjectKeywords", selectedValues);
  };


  useEffect(() => {
    if (subMatchType && subMatchType !== subjectKeyword) {
      setSubjectKeyword(subMatchType);
    }
  }, [subMatchType]);

  return (
    <Stack sx={{ gap: "15px", marginTop: "10px" }}>
      <Stack sx={{ flexDirection: "row", gap: "10px", alignItems: "start", borderBottom: "0.5px solid #E9EDF2" }}>
        <Typography sx={{ font: "normal normal 300 14px/18px Source Serif Pro", whiteSpace: "nowrap", flexShrink: 0, paddingTop: "10px" }}>
          Subject keywords
        </Typography>
        <ChipInputField initialChips={subKeydData} onChange={handleDropdownChange} />
      </Stack>
      <RadioGroup
        aria-labelledby="subjectKeyword-radio-buttons-group"
        name="subjectKeyword-radio-buttons-group"
        value={subjectKeyword}
        onChange={handleSubjectRadioChange}
        sx={{ p: 0, marginTop: "5px", gap: "13px" }}
      >
        <CustomRadioFormControlLabel value="EXACT_MATCH" label="Exact match" />
        <CustomRadioFormControlLabel value="CONTAINS" label="Contains" />
        <CustomRadioFormControlLabel value="DOES_NOT_CONTAIN" label="Does not contain" />
      </RadioGroup>
    </Stack>
  );
};

export default SubjectKeywordView;


