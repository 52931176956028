import * as React from "react";
import {
  Box,
  FormControl,
  InputBase,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import S from "./DropdownShortLarge.module.css";
import { ChevronIcon, GreenDropdownIcon, LinkPng } from "../../../images";
import { CheckboxMedium } from "../CheckboxMedium";
import {
  DropdownShortLargeListInterface,
  DropdownShortLargeList,
} from "./DropdownShortLargeTypes";

interface DropdownShortLargeProps {
  label?: string;
  options?: DropdownShortLargeListInterface[];
  placeholder: string;
  width?: string;
  initialValue?: string; // New prop for initial value
  disabled?: boolean;
  onChange: (selectedOption: string) => void;
  borderColor?: "black" | "lightBlue";
  icon?: "greenArrow" | "chevron";
}

const DropDownShortLarge: React.FC<DropdownShortLargeProps> = React.memo(
  ({
    label,
    options = DropdownShortLargeList,
    placeholder,
    width = "325px",
    onChange,
    disabled = false,
    borderColor = "lightBlue",
    icon = "greenArrow",
    initialValue, // Use the initialValue prop
    ...props
  }) => {
    const [selectedValues, setSelectedValues] = React.useState<string[]>(
      initialValue ? [initialValue] : []
    ); // UseEffect to update the state if initialValue changes
    React.useEffect(() => {
      if (initialValue) {
        setSelectedValues([initialValue]);
      }
    }, [initialValue]);
    const [isOpen, setIsOpen] = React.useState(false);
    const [customLinkValue, setCustomLinkValue] = React.useState<string>("");
    const inputRef = React.useRef<HTMLInputElement | null>(null);

    const getOpenedIcon = () => {
      switch (icon) {
        case "chevron":
          return (
            <ChevronIcon
              w={20}
              direction={isOpen ? "down" : "up"}
              style={{ marginRight: "10px" }}
            />
          );

        case "greenArrow":
          return (
            <GreenDropdownIcon open={isOpen} sx={{ marginRight: "10px" }} />
          );
        default:
          return (
            <GreenDropdownIcon open={isOpen} sx={{ marginRight: "10px" }} />
          );
      }
    };

    const handleChange = React.useCallback(
      (event: SelectChangeEvent<typeof selectedValues>) => {
        const {
          target: { value },
        } = event;
        setSelectedValues(typeof value === "string" ? value.split(",") : value);

        if (onChange) {
          const selectedValue =
            typeof value === "string" ? value : value.join(", ");
          onChange(selectedValue);
        }
      },
      [onChange]
    );

    const handleCheckboxClick = (event: React.MouseEvent) => {
      if (!customLinkValue && customLinkValue === "") {
        event.stopPropagation();
      }
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();
      event.preventDefault();
      setCustomLinkValue(event.target.value);
    };

    const menuProps = {
      PaperProps: {
        className: `${S["dropdown-short-large__menu"]} ${
          S[`dropdown-short-large__menu--border-${borderColor}`]
        }`,
        style: { width: width },
      },
    };

    return (
      <div>
        <FormControl
          size="small"
          style={{ width }}
          disabled={disabled}
          {...props}
        >
          {label && (
            <Typography
              sx={{
                font: "normal normal normal 10px/13px Source Serif Pro",
                color: "black",
                marginBottom: "5px",
              }}
            >
              {label}
            </Typography>
          )}
          <Select
            onClick={!isOpen && !disabled ? () => setIsOpen(true) : () => null}
            open={isOpen}
            displayEmpty
            IconComponent={() => getOpenedIcon()}
            value={selectedValues}
            onChange={handleChange}
            input={
              <OutlinedInput
                className={`${S["dropdown-short-large__inputbox"]} ${
                  S[`dropdown-short-large__inputbox--border-${borderColor}`]
                }`}
                classes={{
                  notchedOutline: S["dropdown-short-large__notchedOutline"],
                  focused: S["dropdown-short-large__focused"],
                }}
              />
            }
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            renderValue={(selected) => {
              if (selected?.length === 0) {
                return (
                  <em className={S["dropdown-short-large__select-placeholder"]}>
                    {placeholder}
                  </em>
                );
              }

              return (
                <span className={S["dropdown-short-large__selected"]}>
                  {selected?.join(", ")}
                </span>
              );
            }}
            MenuProps={menuProps}
          >
            {options.map((option) => (
              <MenuItem
                className={S["dropdown-short-large__menu-item"]}
                key={option.title}
                value={option.title}
              >
                <CheckboxMedium
                  className={S["dropdown-short-large__checkbox"]}
                  checked={selectedValues.indexOf(option.title) > -1}
                />
                <Box
                  component={"img"}
                  src={option.imgSrc}
                  alt="logo"
                  sx={{ height: "11px", width: "11px" }}
                />
                <ListItemText
                  primary={option.title}
                  primaryTypographyProps={{
                    className: S["dropdown-short-large__list-item-text"],
                  }}
                />
              </MenuItem>
            ))}
            <MenuItem
              className={S["dropdown-short-large__menu-item"]}
              value={customLinkValue}
            >
              <CheckboxMedium
                className={S["dropdown-short-large__checkbox"]}
                checked={selectedValues.includes(customLinkValue)}
                onClick={(e) => handleCheckboxClick(e)}
              />
              <Box
                component={"img"}
                src={LinkPng}
                alt="logo"
                sx={{ height: "11px", width: "11px" }}
              />
              <div
                onMouseDown={(e) => e.stopPropagation()}
                onClick={(e) => e.stopPropagation()}
              >
                <InputBase
                  value={customLinkValue}
                  inputRef={inputRef}
                  onChange={handleInputChange}
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={(e) => e.stopPropagation()}
                  onKeyDown={(e) => e.stopPropagation()} // Prevent key events from propagating
                  onKeyUp={(e) => e.stopPropagation()}
                  placeholder="Paste a Meeting Link"
                  className={S["dropdown-short-large__list-item-text"]}
                />
              </div>
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    );
  }
);

export default DropDownShortLarge;
