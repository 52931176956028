import countryList from 'react-select-country-list';
// import { getCities } from 'city-state-country';

export const allCountryList: string[] = countryList().getData().map((item:any) => item.label)

const getCountryCode = (countryName: string) => {
    const allCountry = countryList().getData()
    const countryCode = allCountry.find((item:any) => item?.label === countryName)?.value
    return countryCode
}

// export const getCityList = (countryName: string | undefined) => {
//     if (!countryName) {
//         return []
//     }
//     const code = getCountryCode(countryName)
//     if (!code) {
//         return []
//     }
//     const cityList = getCities(code)
//     return cityList
// }