import {
  General as AppGeneral,
  Notification as AppNotifications,
  Security as AppSecurity,
  Profile as AppProfile,
  BebaAI as AppBebaAI,
  TranquilAI as AppTranquilAI,
  PlansDetail as AppPlansDetail,
  IntelligentConnection as AppIntelligentConnection,
} from "./Components";

export type SettingsPaths =
  | "/Settings/general"
  | "/Settings/app-notifications"
  | "/Settings/app-security"
  | "/Settings/profile"
  | "/Settings/beba-ai"
  | "/Settings/tranquil-ai"
  | "/Settings/plan-details"
  | "/Settings/intelligent-connections";

export const SettingsComponentMap: Record<SettingsPaths, React.ComponentType> =
{
  "/Settings/general": AppGeneral,
  "/Settings/app-notifications": AppNotifications,
  "/Settings/app-security": AppSecurity,
  "/Settings/profile": AppProfile,
  "/Settings/beba-ai": AppBebaAI,
  "/Settings/tranquil-ai": AppTranquilAI,
  "/Settings/intelligent-connections": AppIntelligentConnection,
  "/Settings/plan-details": AppPlansDetail,
};
