import { Stack, SxProps, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../../Utils";

type InlineContainerType = {
  label: string;
  value: string;
  bgcolor: string;
  fontColor?: string;
  opacity: number;
  sx?: SxProps;
};

const InlineContainer: React.FC<InlineContainerType> = ({
  bgcolor,
  label,
  opacity,
  value,
  fontColor = "black",
  sx,
}) => {
  return (
    <Stack
      sx={{
        width: "85px",
        height: "70px",
        boxSizing: "border-box",
        padding: "13px 0px 0px 10px",
        gap: "13px",
        bgcolor: adjustHexColor(bgcolor, opacity),
        ...sx,
      }}
    >
      <Typography
        sx={{
          font: "normal normal 900 11px/14px Source Serif Pro",
          color: fontColor,
        }}
      >
        {label}
      </Typography>
      <Typography
        sx={{
          font: "normal normal 900 13px/16px Source Serif Pro",
          color: fontColor,
        }}
      >
        {value}
      </Typography>
    </Stack>
  );
};

export default InlineContainer;
