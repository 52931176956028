import React from "react";
import {
  SmallThreeDotMenu,
  SmallThreeDotMenuListInterface,
} from "../../../../common";
import {
  AddressLocationIcon,
  BearishIcon,
  BearishOSIcon,
  bearishOSOctaIcon,
  CompanyIcon,
  CreatedIcon,
  DepartmentIcon,
  TeamMemberIcon,
} from "../../../../../images";

interface EditEmployeeThreeDotProps {}

const EditEmployeeThreeDotoptions: SmallThreeDotMenuListInterface[] = [
  { id: 1, icon: CreatedIcon, text: "Edit Social Links" },
  { id: 2, icon: AddressLocationIcon, text: "Edit Location" },
  { id: 3, divider: true },
  { id: 4, icon: DepartmentIcon, text: "Update Department" },
  { id: 5, icon: DepartmentIcon, text: "Update Team" },
  { id: 6, icon: CompanyIcon, text: "Update Company" },
  { id: 7, divider: true },
  { id: 8, icon: TeamMemberIcon, text: "Update HR status" },
  { id: 9, icon: BearishOSIcon, text: "Bearish OS Status" },
];

const EditEmployeeThreeDot: React.FC<EditEmployeeThreeDotProps> = ({}) => {
  return (
    <SmallThreeDotMenu
      menuItems={EditEmployeeThreeDotoptions}
      displayOption="left"
      menuWidth="150px"
      onClickAction={(value) => null}
    />
  );
};

export default EditEmployeeThreeDot;
