import { Stack } from "@mui/material";
import React, { useState } from "react";
import ViewHeaderButton from "../ViewHeaderButton/ViewHeaderButton";
import DefaultQuickNotesView from "./screen/DefaultQuickNotesView";
import CreateQuickNotesView from "./screen/CreateQuickNotesView";

interface NotesViewProps {
  onBackButtonClick: () => void;
}

const NotesView: React.FC<NotesViewProps> = ({ onBackButtonClick }) => {
  const [taskScreen, setTaskScreen] = useState<"default" | "create">("default");

  return (
    <Stack sx={{ flex: 1, height: "100%" }}>
      {taskScreen === "default" ? (
        <>
          <ViewHeaderButton
            headerText="All notes"
            createNewButtonText="Create new notes"
            onBackButtonClick={onBackButtonClick}
            onCreateButtonClick={() => setTaskScreen("create")}
          />
            <DefaultQuickNotesView />
        </>
      ) : (
        <CreateQuickNotesView onCloseClick={() => setTaskScreen("default")} />
      )}
    </Stack>
  );
};

export default NotesView;
