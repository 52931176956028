import React from "react";
import { Stack, Typography } from "@mui/material";
import { BearishOSIcon } from "../../../images";
import styles from "./WelcomeBannerPublicShort.module.css";

interface WelcomeBannerPublicShortProps {}

const WelcomeBannerPublicShort: React.FC<
  WelcomeBannerPublicShortProps
> = () => {
  return (
    <Stack className={styles["welcome-banner-public-short"]}>
      <Stack className={styles["welcome-banner-public-short__header"]}>
        <Typography className={styles["welcome-banner-public-short__title"]}>
          Welcome to Bearish OS
        </Typography>
        <BearishOSIcon w={25} />
      </Stack>
      <Typography
        className={styles["welcome-banner-public-short__description"]}
      >
        You’re viewing this item as a guest. Start free now to create your own
        and more.
      </Typography>
    </Stack>
  );
};

export default WelcomeBannerPublicShort;
