import { IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { XCircleIcon } from "../../../images";
import {
  AiSummaryView,
  DetailsPoster,
  ItemInfoView,
  ItemInsightsView,
  ItemPropertiesView,
  VersionHistoryView,
} from "./view";
import styles from "./DetailsCloudStorage.module.css";
import { useDispatch } from "react-redux";
import { getOneFile__api } from "../../../redux";
import { getUser } from "../../../Utils";

interface DetailsCloudStorageProps {
  closeDetails?: () => void;
  selectedFileData?: any;
  handleDialogSubmit?: any;
}

const DetailsCloudStorage: React.FC<DetailsCloudStorageProps> = ({
  closeDetails,
  selectedFileData,
  handleDialogSubmit,
}) => {
  const dispatch = useDispatch();

  const [fileData, setFileData] = useState<any | null>(null);
  const [isLocked, setIsLocked] = useState<boolean>(
    selectedFileData?.locking?.isLocked || false
  );
  const [description, setDescription] = useState<string>(
    selectedFileData?.description || ""
  );
  const [selectedTags, setSelectedTags] = useState<
    { tagName: string; color: string }[]
  >(selectedFileData?.tags || []);

  // Handle fetching file data
  const handleGetOneFile = async (fileId: string) => {
    if (!fileId) return;

    try {
      const action = getOneFile__api(fileId);
      const fileResponse: any = await dispatch(action);

      if (fileResponse?.success && fileResponse?.data) {
        setFileData(fileResponse.data);
        setIsLocked(fileResponse.data.locking.isLocked);
        setDescription(fileResponse.data.description);
        setSelectedTags(fileResponse.data.tags || []);
      } else {
        setFileData(null); // Reset to null if not found
      }
    } catch (error) {
      console.error("Error fetching file data:", error);
      setFileData(null); // Reset to null on error
    }
  };

  // Effect to get the file data when selectedFileData changes
  useEffect(() => {
    if (selectedFileData && selectedFileData?._id) {
      handleGetOneFile(selectedFileData?._id);
    }
  }, [selectedFileData]);

  const user = getUser();

  const handleSubmit = (
    newLockState = isLocked,
    newDescription = description
  ) => {
    const existingTags = fileData?.tags || [];
    const combinedTags = [
      ...existingTags,
      ...selectedTags?.filter(
        (newTag) =>
          !existingTags?.some(
            (existingTag: any) => existingTag?.tagName === newTag?.tagName
          )
      ),
    ];

    const payload = {
      name: fileData?.name,
      description: newDescription || "", // Use the new description
      tags: combinedTags || [],
      locking: {
        isLocked: newLockState || false, // Use the new lock state
        lockedUntil: fileData?.locking?.lockedUntil,
      },
      setExpiryDate: fileData?.setExpiryDate,
      folderLocation: fileData?.directory,
      enableWatermark: fileData?.enableWatermark || false,
    };

    // Call the submit function
    handleDialogSubmit && handleDialogSubmit(payload);
  };
  const handleLockAndDescriptionChange = (
    newLockState: any,
    newDescription: any
  ) => {
    setIsLocked(newLockState);
    setDescription(newDescription);

    handleSubmit(newLockState, newDescription); // Call submit with the latest values
  };

  return (
    <Stack    height="35%"
    overflow="hidden"
    maxHeight="35%"
    sx={{
      "&::-webkit-scrollbar": {
        width: "0 !important", // hide scrollbar on Chrome, Safari, etc.
      },
      scrollbarWidth: "none", // hide scrollbar on Firefox
      overflowX: "hidden",
      overflowY: "scroll",
    }} className={styles["details-sidebar"]}>
      {/* ----  header and close button ---- */}
      <Stack className={styles["details-sidebar__header"]}>
        <Typography className={styles["details-sidebar__title"]}>
          Details
        </Typography>
        <IconButton
          onClick={() => closeDetails && closeDetails()}
          className={styles["details-sidebar__close-button"]}
          disableRipple
        >
          <XCircleIcon w={15} color="#000" />
        </IconButton>
      </Stack>

      {/* ----- all view are here ----- */}
      <Stack className={styles["details-sidebar__body"]}>
        <DetailsPoster path={fileData?.path} />
        <ItemInsightsView
          linkCount={fileData?.link?.length || 0}
          shareCount={fileData?.totalShares || 0}
          viewCount={fileData?.totalViews || 0}
        />
        <ItemPropertiesView
          fileDataLock={fileData?.locking}
          fileDataDescription={fileData?.description}
          onLockChange={(newLockState) =>
            handleLockAndDescriptionChange(newLockState, description)
          }
          onDescriptionChange={(newDescription) =>
            handleLockAndDescriptionChange(isLocked, newDescription)
          }
        />
        <ItemInfoView
          ownerName={user?.data?.firstName + " " + user?.data?.lastName}
          uploader={user?.data?.firstName + " " + user?.data?.lastName}
          createdTimestamp={fileData?.uploadDate || fileData?.createdAt}
          lastModifyTimestamp={fileData?.updatedAt}
          size={fileData?.size}
          fileDataTags={fileData?.tags}
          onTagsChange={(newSelectedTags, previousTags) => {
            setSelectedTags(newSelectedTags);
            handleSubmit(); // Call handleSubmit when tags change
          }}
        />

        <AiSummaryView />
        <VersionHistoryView fileId={fileData?._id} />
      </Stack>
    </Stack>
  );
};

export default DetailsCloudStorage;
