import {
  Button,
  IconButton,
  Stack,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { XCircleIcon } from "../../../images";
import { SettingView } from "./View";
import { adjustHexColor } from "../../../Utils";
import styles from "./CreateLinkCloud.module.css";
import { useDispatch } from "react-redux";
import { createLink__api } from "../../../redux";

interface CreateLinkCloudProps {
  handleClose?: any;
  fileId?: string;
  handleGetAllLink?: any;
}

const CreateLinkCloud: React.FC<CreateLinkCloudProps> = ({
  fileId,
  handleClose,
  handleGetAllLink,
}) => {
  const dispatch = useDispatch();
  const [linkData, setLinkData] = useState({
    name: "",
    enableWDownload: false,
    askDetails: false,
    expirationDate: null,
    password: "",
    isWatermark: false,
  });

  const [createLoader, setCreateLoader] = useState<boolean | "Failed">(false);

  const handleUpdate = (field: keyof typeof linkData, value: any) => {
    setLinkData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      if (!fileId) {
        return;
      }

      // Ensure expirationDate is in the correct format
      const formattedExpirationDate = linkData?.expirationDate
        ? new Date(linkData?.expirationDate)?.toISOString() // convert to required format
        : null;

      // Create the payload object
      const payload = {
        name: linkData?.name,
        enableWDownload: linkData?.enableWDownload,
        askDetails: linkData?.askDetails,
        expirationDate: formattedExpirationDate, // formatted date or null
        password: linkData?.password,
        isWatermark: linkData?.isWatermark,
      };
      setCreateLoader(true);
      // Dispatch the API action
      const action = createLink__api(fileId, payload);
      const createLinkRes: any = await dispatch(action);

      if (createLinkRes?.success && handleClose && handleGetAllLink) {
        handleClose();
        handleGetAllLink();
      }
    } catch (e) {
      console.error("Error while creating the link", e);
    } finally {
      setCreateLoader(false);
    }
  };

  return (
    <Stack className={styles["create-link-cloud"]}>
      {/* ----------------    card title + Back button + close button --------------- */}
      <Stack className={styles["create-link-cloud__header"]}>
        <Typography className={styles["create-link-cloud__title"]}>
          Create a Link
        </Typography>

        <Tooltip title="close" placement="top" arrow>
          <IconButton
            onClick={() => handleClose()}
            className={styles["create-link-cloud__close-button"]}
            disableRipple
          >
            <XCircleIcon w={15} />
          </IconButton>
        </Tooltip>
      </Stack>
      {/* ----------------    card title + Back button + close button container end here --------------- */}

      {/*---------    scrollable container here  --------- */}
      <Stack className={styles["create-link-cloud__content"]}>
        <Typography className={styles["create-link-cloud__description"]}>
          This link allows you to track who, when, where, and how long a person
          is viewing your link.
        </Typography>

        {/* --- input area for create link ---  */}
        <Stack className={styles["create-link-cloud__input-container"]}>
          <Stack className={styles["create-link-cloud__input-header"]}>
            <Typography className={styles["create-link-cloud__input-title"]}>
              Link name
            </Typography>
          </Stack>
          <TextareaAutosize
            placeholder="Name your new link here"
            minRows={1}
            autoFocus
            className={styles["create-link-cloud__textarea"]}
            value={linkData.name}
            onChange={(e) => handleUpdate("name", e.target.value)}
          />
        </Stack>

        {/* --- Setting View --- */}
        <SettingView linkData={linkData} onUpdate={handleUpdate} />

        {/* --- Create link button --- */}
        <Stack className={styles["create-link-cloud__create-button-container"]}>
          <Button
            className={styles["create-link-cloud__create-button"]}
            sx={{ ":hover": { bgcolor: adjustHexColor("#008D4C", 110) } }}
            onClick={handleSubmit}
            disabled={!linkData?.name}
          >
            <Typography
              className={styles["create-link-cloud__create-button-text"]}
            >
              {createLoader ? `Creating Link...` : `Create new link`}
            </Typography>
          </Button>
        </Stack>
      </Stack>
      {/*---------    scrollable container end here  --------- */}
    </Stack>
  );
};

export default CreateLinkCloud;
