import { Stack, TextareaAutosize, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { LockAndUnlocked } from "../../../LockAndUnlocked";
import { adjustHexColor } from "../../../../../Utils";

interface ItemPropertiesViewProps {
  fileDataLock?: { isLocked: boolean };
  fileDataDescription?: string;
  onLockChange: (isLocked: boolean) => void; // Callback for lock state
  onDescriptionChange: (description: string) => void; // Callback for description
}

const ItemPropertiesView: React.FC<ItemPropertiesViewProps> = ({
  fileDataLock,
  fileDataDescription,
  onLockChange,
  onDescriptionChange,
}) => {
  const [isLocked, setIsLocked] = useState<boolean>(
    fileDataLock?.isLocked || false
  );
  const [description, setDescription] = useState<string>(
    fileDataDescription || "No description provided"
  );
  // Effect to update state when props change
  useEffect(() => {
    setIsLocked(fileDataLock?.isLocked || false);
    setDescription(fileDataDescription || "No description provided");
  }, [fileDataLock, fileDataDescription]);

  // Handle lock state change
  const handleLockChange = (value: string) => {
    const newLockState = value === "lock";
    setIsLocked(newLockState);
    onLockChange(newLockState); // Notify parent about the change
  };

  // Handle description change
  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newDescription = e.target.value;
    setDescription(newDescription);
    onDescriptionChange(newDescription); // Notify parent about the change
  };

  return (
    <Stack gap={"10px"}>
      <Stack sx={{ flexDirection: "row", gap: "20px" }}>
        <Stack>
          <Typography
            sx={{
              font: "normal normal 400 14px/18px Source Serif Pro",
              paddingBottom: "5px",
              borderBottom: "1px solid #E9EDF2",
            }}
          >
            Item Properties
          </Typography>
        </Stack>

        <LockAndUnlocked
          isLocked={isLocked || false}
          onSelect={handleLockChange} // Handle lock/unlock selection
        />
      </Stack>

      <Stack gap={"10px"}>
        <Typography
          sx={{ font: "normal normal 400 14px/18px Source Serif Pro" }}
        >
          Description
        </Typography>
        <TextareaAutosize
          placeholder="Enter your description here..."
          minRows={3}
          value={description}
          maxRows={3}
          onChange={handleDescriptionChange} // Handle description input
          style={{
            resize: "none",
            border: "0px",
            outline: "none",
            padding: "10px",
            font: "normal normal 400 14px/18px Source Serif Pro",
            scrollbarWidth: "none",
            backgroundColor: adjustHexColor("#E9EDF2", 15),
          }}
        />
      </Stack>
    </Stack>
  );
};

export default ItemPropertiesView;
