import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { adjustHexColor, formatDate, getUser } from "../../../Utils";
import { CloudStorageFileCompanyTypeEnum } from "../CloudStorageFileIcon/CloudStorageFileType";
import { CloudStorageFileIcon } from "../CloudStorageFileIcon";
import { CheckboxMedium } from "../CheckboxMedium";
import { SmallThreeDotMenu } from "../SmallThreeDotMenu";
import styles from "./CloudStorageItemGrid.module.css";
import { CloudStorageItemThreeDotOptions } from "../CloudStorageItemList/CloudStorageItemListType";

interface CloudStorageItemGridProps {
  plateform: CloudStorageFileCompanyTypeEnum;
  fileName: string;
  timeStamp: string;
  opendBy: string;
  fileSize: string;
  isSelected: boolean;
  onContainerClick: () => void;
  checkBoxClick?: () => void;
  path?: string;
  optionAction?:(args :string) => void;
}

const CloudStorageItemGrid: React.FC<CloudStorageItemGridProps> = ({
  fileName,
  fileSize,
  isSelected,
  onContainerClick,
  opendBy,
  plateform,
  timeStamp,
  checkBoxClick,
  path,optionAction
}) => {
  const [showDrag, setShowDrag] = useState<boolean>(false);

  const stopPropagation = (event: React.MouseEvent | React.KeyboardEvent) => {
    event.stopPropagation();
  };
  const user = getUser();
  return (
    <Stack
      className={`${styles["cloudStorageItemGrid"]} ${isSelected && styles["cloudStorageItemGridSelected"]}`}
      onMouseEnter={() => setShowDrag(true)}
      onMouseLeave={() => setShowDrag(false)}
    >
      <Stack
        className={styles.cloudStorageItemGrid__imageContainer}
        sx={{
          bgcolor: adjustHexColor("#E9EDF2", 20),
          border: `1.5px solid ${adjustHexColor("#E9EDF2", 20)} `,    backgroundImage: `url(${path})`, // Set the background image
          backgroundSize: 'cover',         // Cover the entire container
          backgroundPosition: 'center',    // Center the image
          backgroundRepeat: 'no-repeat',   // Prevent repeating
        }}
      >
        <Stack className={styles.cloudStorageItemGrid__controlsContainer}>
          <Stack
            className={`${styles.cloudStorageItemGrid__controls} ${
              showDrag || isSelected
                ? styles["cloudStorageItemGrid__controls--visible"]
                : ""
            }`}
          >
            <span onMouseDown={stopPropagation} onClick={stopPropagation}>
              <SmallThreeDotMenu
                menuItems={CloudStorageItemThreeDotOptions}
                onClickAction={(value) => optionAction && optionAction(value) }
              />
            </span>
            <CheckboxMedium
              onChange={() => checkBoxClick && checkBoxClick()}
              boxSize={15}
              checked={isSelected}
            />
          </Stack>
        </Stack>
      </Stack>

      <Stack
        onClick={(e: any) => {
          stopPropagation(e);
          onContainerClick();
        }}
        className={styles.cloudStorageItemGrid__infoContainer}
      >
        {/* ------------ */}
        <CloudStorageFileIcon fileName={fileName} plateform={plateform} />
        {/* ------------ */}
        <Stack className={styles.cloudStorageItemGrid__textContainer}>
          <Typography className={styles.cloudStorageItemGrid__fileName}>
            {fileName}
          </Typography>
          <Typography className={styles.cloudStorageItemGrid__fileInfo}>
            {formatDate(timeStamp)} by{" "}
            {`${user?.data?.firstName} ${user?.data?.lastName}` || opendBy}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CloudStorageItemGrid;
