import { IconButton, IconButtonProps, Typography } from "@mui/material";
import React from "react";

interface EditButtonViewProps extends IconButtonProps {
  ButtonLabel: string;
}

const EditButtonView: React.FC<EditButtonViewProps> = ({
  ButtonLabel,
  sx,
  ...props
}) => {
  return (
    <IconButton
      sx={{
        p: 0,
        borderRadius: 0,
        font: "normal normal 300 12px/15px Source Serif Pro",
        color: "#B5005B",
        ...sx,
      }}
      disableRipple
      {...props}
    >
      <Typography
        sx={{
          font: "inherit",
          color: "inherit",
        }}
      >
        {ButtonLabel}
      </Typography>
    </IconButton>
  );
};

export default EditButtonView;
