import React from "react";
import { InputBase, InputBaseProps } from "@mui/material";

interface PlainInputBoxProps extends InputBaseProps {}

const PlainInputBox: React.FC<PlainInputBoxProps> = (props) => {
  return (
    <InputBase
      {...props}
      sx={{
        height: "30px",
        width: "100%",
        border: "0.5px solid #E9EDF2",
        borderRadius: "5px",
        font: "normal normal 300 14px/18px Source Serif Pro",
        boxSizing: "border-box",
        padding: "0px 10px",
        bgcolor: "white",
        ...props.sx, // Allow customization of sx prop
      }}
    />
  );
};

export default PlainInputBox;
