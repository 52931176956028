import {
  IconButton,
  Stack,
  Typography,
  Popover,
  Box,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { AddCalPlusIcon } from "../../../../../images";
import styles from "./ItemInfoView.module.css";
import { ColoredBadge } from "../../../ColoredBadge";
import { adjustHexColor, formatDate } from "../../../../../Utils";

interface Tag {
  tagName: string;
  color: string;
}

interface ItemInfoViewProps {
  ownerName: string;
  uploader: string;
  createdTimestamp: string;
  lastModifyTimestamp: string;
  size: number;
  fileDataTags: Tag[];
  onTagsChange: (selectedTags: Tag[], previousTags: Tag[]) => void;
}

const ItemInfoView: React.FC<ItemInfoViewProps> = ({
  createdTimestamp,
  lastModifyTimestamp,
  ownerName,
  size,
  uploader,
  fileDataTags = [],
  onTagsChange,
}) => {
  const predefinedTags: Tag[] = [
    { tagName: "Business", color: "rgb(180 104 255 / 75%)" },
    { tagName: "Personal", color: "#F28C8C" },
    { tagName: "Important", color: "#FFD700" },
    { tagName: "Urgent", color: "#FF6347" },
    { tagName: "Finance", color: "#6A5ACD" },
    { tagName: "Work", color: "#7FFF00" },
  ];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedTags, setSelectedTags] = useState<Tag[]>(fileDataTags || []);

  useEffect(() => {
    setSelectedTags(fileDataTags);
  }, [fileDataTags]);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleTagSelect = (tag: Tag) => {
    const isTagSelected = selectedTags?.some(
      (selectedTag) => selectedTag?.tagName === tag?.tagName
    );

    let updatedTags;

    if (isTagSelected) {
      updatedTags = selectedTags?.filter(
        (selectedTag) => selectedTag?.tagName !== tag?.tagName
      );
    } else {
      updatedTags = [...selectedTags, tag];
    }

    setSelectedTags(updatedTags);

    // Call onTagsChange with updated selected tags and previous tags
    onTagsChange(updatedTags, selectedTags);
  };

  const formatFileSize = (size: number): string => {
    if (size < 1024) return `${size} bytes`;
    const i = Math.floor(Math.log(size) / Math.log(1024));
    const sizeInUnit = (size / Math.pow(1024, i)).toFixed(2);
    const units = ["bytes", "KB", "MB", "GB", "TB", "PB"];
    return `${sizeInUnit} ${units[i]}`;
  };

  return (
    <Stack className={styles["item-info-view"]}>
      {/* ----- Tags ---- */}
      <Stack className={styles["item-info-view__section"]}>
        <Typography className={styles["item-info-view__header"]}>
          Tags
        </Typography>
        <Stack
          direction="row"
          spacing={1}
          className={styles["item-info-view__tags-container"]}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            gap={1}
            flexWrap={"wrap"}
          >
            {/* Render selected tags */}
            {selectedTags?.map((tag, index) => (
              <ColoredBadge
                key={index}
                ColoredBadgeFont="normal normal 400 14px/18px Source Serif Pro"
                bgColor={adjustHexColor(tag?.color, 75)}
                title={tag?.tagName}
                sx={{ px: "10px" }}
              />
            ))}
          </Stack>

          <IconButton
            disableRipple
            className={styles["item-info-view__add-tags-button"]}
            onClick={handlePopoverOpen}
          >
            <AddCalPlusIcon w={15} />
            <Typography className={styles["item-info-view__add-tags-text"]}>
              Add tags
            </Typography>
          </IconButton>
        </Stack>
      </Stack>

      {/* Popover for selecting predefined tags */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box p={2}>
          <Typography
            sx={{ fontFamily: "Source Serif Pro" }}
            variant="h6"
            gutterBottom
          >
            Select Tags
          </Typography>
          {predefinedTags?.map((tag, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={selectedTags?.some(
                    (selectedTag) => selectedTag?.tagName === tag?.tagName
                  )}
                  onChange={() => handleTagSelect(tag)}
                  sx={{
                    color: tag.color,
                    "&.Mui-checked": {
                      color: tag.color,
                    },
                  }}
                />
              }
              label={
                <span
                  style={{
                    fontFamily: "Source Serif Pro", // Set the desired font-family
                    fontSize: "14px", // Set the desired font-size
                    fontWeight: "bold", // Set the desired font-weight (optional)
                  }}
                >
                  {tag?.tagName}
                </span>
              }
            />
          ))}
        </Box>
      </Popover>

      {/* Other sections... */}
      <Stack className={styles["item-info-view__section"]}>
        <Typography className={styles["item-info-view__header"]}>
          Owner
        </Typography>
        <Typography className={styles["item-info-view__text"]}>
          {ownerName}
        </Typography>
      </Stack>

      <Stack className={styles["item-info-view__section"]}>
        <Typography className={styles["item-info-view__header"]}>
          Uploader
        </Typography>
        <Typography className={styles["item-info-view__text"]}>
          {uploader}
        </Typography>
      </Stack>

      <Stack className={styles["item-info-view__section"]}>
        <Typography className={styles["item-info-view__header"]}>
          Created
        </Typography>
        <Typography className={styles["item-info-view__text"]}>
          {formatDate(createdTimestamp)}
        </Typography>
      </Stack>

      <Stack className={styles["item-info-view__section"]}>
        <Typography className={styles["item-info-view__header"]}>
          Last modified
        </Typography>
        <Typography className={styles["item-info-view__text"]}>
          {formatDate(lastModifyTimestamp)}
        </Typography>
      </Stack>

      <Stack className={styles["item-info-view__section"]}>
        <Typography className={styles["item-info-view__header"]}>
          Size
        </Typography>
        <Typography className={styles["item-info-view__text"]}>
          {formatFileSize(size)}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ItemInfoView;
