import { Stack, Typography, Popover, Divider } from "@mui/material";
import React, { useState } from "react";
import { CheckboxMedium } from "../../../../common";
import {
  ShowAsFilterListInterface,
  ShowAsFilterOptionList,
} from "./ShowAsFilterType";

// Extract the union of value types from ShowAsFilterOptionList
type ShowAsFilterValueType = (typeof ShowAsFilterOptionList)[number]["value"];

interface ShowAsFilterProps {
  initialValue?: ShowAsFilterValueType; // Constrain initialValue to allowed values
  onSelect: (value: ShowAsFilterValueType) => void; // Constrain onSelect to allowed values
  options?: ShowAsFilterListInterface[];
  readonlyComponent?: boolean;
}

const ShowAsFilter: React.FC<ShowAsFilterProps> = ({
  initialValue = "Free",
  onSelect,
  options = ShowAsFilterOptionList,
  readonlyComponent = false,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedValue, setSelectedValue] =
    useState<ShowAsFilterValueType>(initialValue);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!readonlyComponent) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (value: ShowAsFilterValueType) => {
    setSelectedValue(value);
    onSelect(value);
    handleClose(); // Close the popover when an option is selected
  };

  const open = Boolean(anchorEl);
  const id = open ? "ShowAsFilter-popover" : undefined;

  return (
    <Stack>
      <Stack
        sx={{ width: "100px", flexDirection: "row", cursor: "pointer" }}
        onClick={handleClick}
      >
        <Typography
          sx={{ font: "normal normal 300 15px/19px Source Serif Pro" }}
        >
          {selectedValue}
        </Typography>
      </Stack>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            padding: "0px",
          },
        }}
      >
        <Stack
          sx={{
            width: "150px",
            padding: "15px 10px 10px 15px",
            boxSizing: "border-box",
            borderRadius: "5px",
            gap: "12px",
            cursor: "pointer",
          }}
        >
          <Typography
            sx={{ font: "normal normal 300 15px/19px Source Serif Pro" }}
          >
            Show as
          </Typography>

          {options.map((item, index) =>
            item.divider ? (
              <Divider key={index} />
            ) : (
              <Stack
                key={item.id} // Use `key` for each item
                sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}
                onClick={() =>
                  handleSelect(item.value as ShowAsFilterValueType)
                }
              >
                <CheckboxMedium
                  boxSize={18}
                  checked={selectedValue === item.value}
                />
                <Typography
                  sx={{
                    font: "normal normal 300 15px/19px Source Serif Pro",
                  }}
                >
                  {item.value}
                </Typography>
              </Stack>
            )
          )}
        </Stack>
      </Popover>
    </Stack>
  );
};

export default ShowAsFilter;
