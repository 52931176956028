import { DateIcon, HybridViewIcon, LayersIcon, NewIcon, NoteIcon, WindowIcon } from "../../../../../images";
import { CRMIcon } from "../../../../../Router/RouteIcon";

export interface AddToMeetingMenuListInterface {
    id: number;
    icon: React.ElementType;
    value: string,
    LANG: string
}

export const AddToMeetingMenuOptionList: AddToMeetingMenuListInterface[] = [
    { id: 1, icon: NoteIcon, value: 'New Pre-Meeting Note', LANG: "VIDEO_CENTER.MEETING.NEW_PRE_MEETING_NOTE" },
    { id: 2, icon: HybridViewIcon, value: 'New Agenda Item', LANG: "VIDEO_CENTER.MEETING.NEW_AGENDA_ITEM" },
    { id: 3, icon: LayersIcon, value: 'Connect a File', LANG: "VIDEO_CENTER.MEETING.CONNECT_A_FILE" },
    { id: 4, icon: NewIcon, value: 'Add a Project', LANG: "VIDEO_CENTER.MEETING.ADD_A_PROJECT" },
    { id: 5, icon: WindowIcon, value: 'Add a Workspace', LANG: "VIDEO_CENTER.MEETING.ADD_A_WORKSPACE" },
    { id: 6, icon: DateIcon, value: 'Update Meeting Time', LANG: "VIDEO_CENTER.MEETING.UPDATE_MEETING_TIME" },
    { id: 7, icon: CRMIcon, value: 'Update Guests', LANG: "VIDEO_CENTER.MEETING.UPDATE_GUESTS" }
]