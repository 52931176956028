import React from 'react';

interface EmpPerformanceProps {
  
}

const EmpPerformance: React.FC<EmpPerformanceProps> = ({  }) => {
  return <div>EmpPerformance</div>;
};

export default EmpPerformance;