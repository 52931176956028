import { Menu, MenuItem, Stack, Typography } from "@mui/material";
import React, { useState, MouseEvent } from "react";
import {
  TravelTimeMenuListInterface,
  TravelTimeMenuOptionsList120,
  TravelTimeMenuOptionsList150,
} from "./TravelTimeMenuType";

interface TravelTimeMenuProps {
  options: "TravelTimeMenuOptionsList120" | "TravelTimeMenuOptionsList150";
  onSelect: (value: number) => void;
  initialValue?: number;
}

const TravelTimeMenu: React.FC<TravelTimeMenuProps> = ({
  options = "TravelTimeMenuOptionsList120",
  onSelect,
  initialValue = 15,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedValue, setSelectedValue] = useState<number>(initialValue);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (value: number) => {
    setSelectedValue(value);
    onSelect(value); // Call onSelect here directly
    handleClose();
  };

  const renderOptions = (): TravelTimeMenuListInterface[] => {
    switch (options) {
      case "TravelTimeMenuOptionsList150":
        return TravelTimeMenuOptionsList150;
      case "TravelTimeMenuOptionsList120":
        return TravelTimeMenuOptionsList120;
      default:
        return TravelTimeMenuOptionsList120;
    }
  };

  return (
    <Stack>
      <Stack
        onClick={handleClick}
        sx={{
          font: "normal normal 400 15px/16px Source Serif Pro",
          width: "65px",
          cursor: "pointer",
          textAlign: "center",
        }}
      >
        <Typography sx={{ font: "inherit" }}>{selectedValue} mins</Typography>
      </Stack>

      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: "150px", // Set width here
            borderRadius: "5px",
            bgcolor: "white",
            marginTop: "5px",
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        {renderOptions().map((item) => (
          <MenuItem
            key={item.id}
            onClick={() => handleSelect(item.value)}
            sx={{
              padding: "0px 0px 0px 10px",
              boxSizing: "border-box",
              height: "25px",
              minHeight: 0,
              font: "normal normal 300 13px/16px Source Serif Pro",
            }}
          >
            {item.value} mins
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
};

export default TravelTimeMenu;
