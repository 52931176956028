// useEmailApiAction.ts
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { baseURL } from '../../../../../redux/middleware/apiMiddleware';
import { fetchEmailsFromBackend } from '../../../../../redux/Slice/email/MsalInitialize';
import { getToken } from '../../../../common/LocalStorage';

const useEmailApiAction = () => {
    const dispatch = useDispatch();
    const token = getToken();

    const EmailApiAction = async (accessToken: string, messageId: string | null, actionType: 'delete' | 'archive' | 'markRead') => {
        if (!accessToken || !messageId) {
            console.error("Access token or message ID not found");
            return;
        }

        try {
            const actionAPI = () => {
                if (actionType === "delete") {
                    return axios.delete(`${baseURL}/email/move-email`, {
                        data: { accessToken, messageId },
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                } else if (actionType === "archive") {
                    return axios.put(`${baseURL}/email/move-email`, { accessToken, messageId }, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                } else if (actionType === "markRead") {
                    return axios.put(`${baseURL}/email/read-toggle`, { accessToken, messageId }, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                }
            };

            const res = await actionAPI();

            if (res?.status === 200 || res?.status === 201) {
                fetchEmailsFromBackend(accessToken, dispatch, "");
            }

            return res;
        } catch (err) {
            console.error("Error performing API action:", err);
        }
    };

    return { EmailApiAction };
};

export default useEmailApiAction;
