import React, { useState, MouseEvent } from "react";
import { IconDropDownButton } from "../IconDropDownButton";
import { Stack, Menu, MenuItem, Divider, Typography } from "@mui/material";
import {
  ExportCSVAsExcelIcon,
  ExportCSVIcon,
  HelpCSVIcon,
  ImportCSVIcon,
  UploadIcon,
} from "../../../images";
import { SmallThreeDotMenuListInterface } from "../SmallThreeDotMenu/SmallThreeDotMenuTypes";
import { useTranslation } from "react-i18next";

const DropdownButtonCsvList: SmallThreeDotMenuListInterface[] = [
  {
    id: 1,
    icon: ImportCSVIcon,
    text: "Import CSV",
    LANG: "TRAX.IMPORT_CSV",
  },
  {
    id: 2,
    divider: true,
  },
  {
    id: 3,
    icon: ExportCSVIcon,
    text: "Export as CSV",
    LANG: "TRAX.EXPORT_AS_CSV",
  },
  {
    id: 4,
    icon: ExportCSVAsExcelIcon,
    text: "Export as Excel",
    LANG: "TRAX.EXPORT_AS_EXCEL",
  },
  {
    id: 5,
    divider: true,
  },
  {
    id: 6,
    icon: HelpCSVIcon,
    text: "Help me import",
    LANG: "TRAX.HELP_ME_IMPORT",
  },
];

interface DropdownButtonCSVProps {
  options?: SmallThreeDotMenuListInterface[];
  onSelect: (value: string) => void;
}

const DropdownButtonCSV: React.FC<DropdownButtonCSVProps> = ({
  onSelect,
  options = DropdownButtonCsvList,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget); // Open the menu at the button's position
  };

  const handleClose = () => {
    setAnchorEl(null); // Close the menu
  };

  return (
    <Stack>
      <IconDropDownButton
        label={t("TRAX.IMPORT")}
        icon={UploadIcon}
        menuOpen={Boolean(anchorEl)} // Menu is open if anchorEl is set
        onButtonClick={handleButtonClick} // Handle button click
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            padding: 0,
            border: "1px solid #E9EDF2",
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            borderRadius: "5px",
            width: "fit-content",
            minWidth: "160px",
            marginTop: "10px",
          },
        }}
      >
        {options.map((item, index) =>
          item.divider ? (
            <Divider
              key={item.id} // Add key to Divider
              sx={{
                marginTop: "1px !important",
                marginBottom: "1px !important",
                marginX: "10px",
              }}
            />
          ) : (
            <MenuItem
              key={item.id}
              sx={{
                padding: "0px 10px",
                minHeight: 0,
                height: "30px",
                color: "black",
                alignItems: "center",
                gap: "5px",
              }}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                onSelect && onSelect(item.text);
                handleClose();
              }}
            >
              {item.icon && (
                <item.icon
                  w={15}
                  color={item.color || "black"}
                  direction={item.direction}
                />
              )}
              <Typography
                sx={{
                  color: item.textColor || "black",
                  font: "normal normal 400 13px/16px Source Serif Pro",
                }}
              >
                {(item.LANG && t(item.LANG)) || item.text}
              </Typography>
            </MenuItem>
          )
        )}
      </Menu>
    </Stack>
  );
};

export default DropdownButtonCSV;
