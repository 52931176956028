import { PayloadAction } from "@reduxjs/toolkit";
import { CloudStorageState } from "../CloudStorageType";
// Upload File to Cloud Success
export const uploadCloudFile__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

// Get All Files
export const getAllFiles__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    const updatedData = action?.payload?.data?.map((file: any) => ({
        ...file,
        platform: "BearishOS", // Add static platform field
        isSelected: false, // Is selected when clicking
    }));

    state.data = updatedData;
    state.bearishOSCloudStorage = updatedData;
};

// Get One File
export const getOneFile__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
    state.memberOfCloudStorageFile = action?.payload?.data;
};

// Download File
export const downloadFile__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
    state.downloadFile = action?.payload?.data;
};

// Share File With Users
export const shareFileWithUsers__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

// Share File With Email
export const shareFileWithEmail__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

// Delete File
export const deleteFile__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

// Update File
export const updateFile__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

// Upload New Version
export const uploadNewVersion__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

// Create Folder
export const createFolder__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

// Rename Folder
export const renameFolder__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

// Get All Folders
export const getAllFolders__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    const updatedFolders = action?.payload?.data?.map((folder: any) => ({
        ...folder,
        platform: "BearishOS", // Add static platform field
        isSelected: false, // Is selected when clicking
    }));

    state.data = updatedFolders;
    state.bearishOSCloudStorageFolders = updatedFolders;
};

export const getAllMemberCS__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    // Assuming action.payload.data is an array of member objects.
    const members = action?.payload?.data ?? [];

    // Process the members if needed (e.g., filtering or mapping).
    const processedMembers = members?.map((member: any) => ({
        ...member,
        // Add any transformations here if 2needed
    }));

    // Store the processed members into the state
    state.data = processedMembers;
    state.memberOfCloudStorageFile = processedMembers;
};

// Create Link
export const createLink__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

// Get Link
export const getLink__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
    state.linkData = action?.payload?.data;
};

// Delete Link
export const deleteLink__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

// Get All Link
export const getAllLink__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

// Add View
export const addView__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

// Add Time
export const addTime__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

// Toggle Download
export const toggleDownload__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

// Toggle Ask Details
export const toggleAskDetails__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

// Update Expiration
export const updateExpiration__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

// Update Link Password
export const updateLinkPassword__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

// Toggle Watermark
export const toggleWatermark__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};
