import React, { useState } from "react";
import { Button, ButtonGroup, IconButton, Typography } from "@mui/material";

const TimePickerCloudStorage: React.FC = () => {
  const [selected, setSelected] = useState("1W");

  const handleClick = (value: string) => {
    setSelected(value);
  };

  const selectedStyle = {
    backgroundColor: "#008D4C", // Green background for the selected button
    color: "#fff", // White text for the selected button
  };

  const unselectedStyle = {
    backgroundColor: "transparent", // Transparent background for unselected buttons
    color: "#000", // Black text for unselected buttons
  };

  return (
    <ButtonGroup sx={{ gap: "15px" }}>
      {["1W", "1M", "3M", "1Y"].map((timeframe) => (
        <IconButton
          disableRipple
          key={timeframe}
          onClick={() => handleClick(timeframe)}
          style={{
            padding: 0,
            borderRadius: "5px",
            height: "20px",
            width: "30px",
            alignItems: "center",
            justifyContent: "center",
            ...(selected === timeframe ? selectedStyle : unselectedStyle),
          }}
        >
          <Typography
            sx={{
              font: "normal normal 600 14px/18px Source Serif Pro",
            }}
          >
            {timeframe}
          </Typography>
        </IconButton>
      ))}
    </ButtonGroup>
  );
};

export default TimePickerCloudStorage;
