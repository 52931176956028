import React, { useEffect, useState } from "react";
import ChecklistItemPM from "./ChecklistItemPM";
import { getAllSubTasks__api } from "../../../../../redux";
import { useDispatch } from "react-redux";

type Props = { item: any };

const SubTask = ({ item }: Props) => {
  const dispatch = useDispatch();
  const [subTaskData, setSubTaskData] = useState<any[]>([]); // State for storing subtasks

  const handleGetAllSubtasks = async () => {
    if (!item?.id) return;
    const action = getAllSubTasks__api(item?.id, 1, 10, "asc");
    const res: any = await dispatch(action);
    if (res?.success && res?.data) {
      setSubTaskData(res.data); // Update state with fetched subtask data
    }
  };

  useEffect(() => {
    handleGetAllSubtasks(); // Fetch subtasks when the component mounts
  }, [item?.id]);

  return (
    <div>
      {/* Render subtasks */}
      {subTaskData?.length > 0 ? (
        subTaskData?.map((subtask) => (
          <ChecklistItemPM
            key={subtask?._id}
            title={subtask?.name}
            checked={subtask?.checked}
            showDetails={subtask?.showDetails}
            componentType={"subTask"} // Mark as subtask
            opened={subtask?.opened}
            statusColor={
              subtask?.status?.customColorHex ||
              subtask?.status?.defaultColorHex
            }
            priority={subtask?.priority}
            dueDate={subtask?.dueDate}
            item={subtask}
          />
        ))
      ) : (
        <div style={{ font: "normal normal bold 14px/17px Source Serif Pro" }}>
          No Sub Task Found
        </div>
      )}
    </div>
  );
};

export default SubTask;
