import { Button, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DetailsView, NumberView, TimeContainerView } from "./view";
import styles from "./AgendaCardSquare.module.css";
import { TrashIcon } from "../../../../../images";
import { useDispatch } from "react-redux";
import { updateAgendaStatus } from "../../../../../redux";
import { useTranslation } from "react-i18next";

interface AgendaCardSquareProps {
  timeLimit?: number;
  agendaTitle?: string;
  agendaDetail?: string;
  index?: number;
  removeAgenda?: (agendaId: any) => void;
  onTimeUpdate?: (timeInMinutes: number) => void;
  key?: number;
  agendaId?: string;
  onStartStopAgendaClick?: any;
  totalTimeTaken?: number;
  agendaStatus?: boolean;
}

const AgendaCardSquare: React.FC<AgendaCardSquareProps> = ({
  timeLimit = 3,
  agendaTitle,
  agendaDetail = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus feugiat, vestibulum mi nec, ultricies turpis",
  index = 0,
  removeAgenda,
  onTimeUpdate,
  key,
  agendaId,
  onStartStopAgendaClick,
  totalTimeTaken = 0,
  agendaStatus = false,
}) => {
  const {t}=useTranslation()
  const [totalTimeInSec, setTotalTimeInSec] = useState<number>(
    timeLimit  || 0 * 60
  ); // Total time in seconds for the timer
  const [expendedTime, setExpendedTime] = useState<number>(
    totalTimeTaken  || 0 * 60
  ); // Time already expended in seconds
  const [isTimerRunning, setIsTimerRunning] = useState(agendaStatus); // State indicating whether the timer is currently running
  const dispatch = useDispatch();
  // useEffect(() => {
  //   setExpendedTime(totalTimeTaken);
  // }, [totalTimeTaken]);

  useEffect(() => {
    setIsTimerRunning(agendaStatus);
  }, [agendaStatus]);

  useEffect(() => {
    if(agendaStatus) {
      onResetTotalTime(totalTimeTaken, agendaId);
    }
  }, [agendaStatus]);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (isTimerRunning) {
      timer = setInterval(() => {
        setExpendedTime((prev) => prev + 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isTimerRunning, expendedTime, totalTimeInSec]);

  /**
   * Determines the border color of the card based on the current state of the timer.
   *
   * @returns {string} The color code representing the current state:
   * - "#FF0000" when the time exceeds the limit.
   * - "#C7D252" when the timer is running and the remaining time is less than or equal to 1 minute.
   * - "#7CF0BA" when the timer is stopped and the expended time is within the allotted time.
   * - "#E9EDF2" as the default color.
   */
  const getBorderColor = (): "#FF0000" | "#C7D252" | "#7CF0BA" | "#E9EDF2" => {
    if (expendedTime >= totalTimeInSec) {
      return "#FF0000"; // Red when time exceeds the limit
    } else if (isTimerRunning && totalTimeInSec - expendedTime <= 60) {
      return "#C7D252"; // Yellow when time is less than or equal to 10 seconds
    } else if (
      !isTimerRunning &&
      expendedTime <= totalTimeInSec &&
      expendedTime !== 0
    ) {
      return "#7CF0BA"; // Green when stopped and within time
    }
    return "#E9EDF2"; // Default
  };

  const timeHandler = () => {
    setIsTimerRunning((prev) => {
      if (prev) {
        const elapsedTimeInMinutes = expendedTime / 60;
        if (onTimeUpdate) {
          onTimeUpdate(elapsedTimeInMinutes);
        }
      }
      return !prev;
    });
    onStartStopAgendaClick(isTimerRunning, expendedTime, agendaId);
  };
  const handleRemoveAgenda = () => {
    if (removeAgenda) {
      removeAgenda(agendaId);
    }
  };

  const onResetTotalTime = async (
    expendedTime: any,
    agendaId: any
  ) => {
    const payload = {
      action: "START",
      totalTimeTaken: Math.round(expendedTime * 100) / 100,
    };
    await dispatch(updateAgendaStatus(agendaId, payload));
  };

  return (
    <Stack
      className={styles["agendaCardSquare"]}
      sx={{
        border: `2px solid ${getBorderColor()}`,
      }}
      key={key}
    >
      <Stack
        className={styles["agendaCardSquare__inner"]}
        sx={{
          border:
            getBorderColor() === "#E9EDF2"
              ? `2px solid white`
              : `2px solid ${getBorderColor()}`,
        }}
      >
        <Stack className={styles["agendaCardSquare__header"]}>
          <Stack className={styles["agendaCardSquare__headerContent"]}>
            <NumberView orderNum={index + 1} />
            <Typography className={styles["agendaCardSquare__title"]}>
              {agendaTitle}
            </Typography>
          </Stack>
          <IconButton onClick={handleRemoveAgenda} sx={{ p: "2px" }}>
            <TrashIcon w={20} />
          </IconButton>
        </Stack>
        {/* --------------- */}

        <DetailsView text={agendaDetail} />

        <Stack className={styles["agendaCardSquare__viewDetails"]}>
          <Typography className={styles["agendaCardSquare__viewAll"]}>
            {t('VIDEO_CENTER.MEETING.VIEW_ALL_DETAILS_ATTACHMENTS')}
          </Typography>
        </Stack>

        <TimeContainerView
          expendedTime={expendedTime}
          totalTimeInSec={totalTimeInSec}
        />
        {/* --------------- */}

        <Stack className={styles["agendaCardSquare__buttonContainer"]}>
          <Button
            variant="text"
            className={styles["agendaCardSquare__button"]}
            onClick={timeHandler}
          >
            <Typography className={styles["agendaCardSquare__buttonText"]}>
              {isTimerRunning ? t('VIDEO_CENTER.MEETING.STOP_AGENDA_ITEM') : t('VIDEO_CENTER.MEETING.START_AGENDA_ITEM')}
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AgendaCardSquare;
