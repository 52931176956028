import { Stack, Typography } from "@mui/material";
import React from "react";
import g from "./checkliststyle.module.css";
import { useSelector } from "react-redux";
import { LayoutState } from "../../../../../../redux";
type Props = {};

const Smallheader = (props: Props) => {
  const layoutState = useSelector(
    (state: { layout: LayoutState }) => state.layout
  );

  const workspaceLayout = layoutState?.isDrawer_Workspace;
  return (
    <div style={{ position: "relative" }}>
      <Stack
        p={1}
        borderRadius={1}
        width={"100%"}
        direction={"row"}
        alignItems={"center"}
        position="absolute"
        left={"20px"}
      >
        <Stack sx={{ mr: "250px", ml: "105px" }}>
          <Typography className={g.h__header__checklist}>Name</Typography>
        </Stack>
        <Stack sx={{ mr: workspaceLayout ? "32px" : "86px", ml: "120px" }}>
          <Typography className={g.h__header__checklist}>Assignee</Typography>
        </Stack>
        <Stack sx={{ mr: workspaceLayout ? "32px" : "85px" }}>
          <Typography className={g.h__header__checklist}>Priority</Typography>
        </Stack>
        <Stack sx={{ mr: "30px", ml: "49px" }}>
          <Typography className={g.h__header__checklist}>Due Date</Typography>
        </Stack>
        <Stack sx={{ mr: "38px", ml: workspaceLayout ? "93px" : "147px" }}>
          <Typography className={g.h__header__checklist}>Add</Typography>
        </Stack>
      </Stack>
    </div>
  );
};

export default Smallheader;
