// Centralized configuration for date formatting
interface DateFormatConfig {
  locale: string;
  options: Intl.DateTimeFormatOptions;
}

const dateFormatConfig: DateFormatConfig = {
  locale: "en-US",
  options: {
    day: "numeric",
    month: "long",
    year: "numeric",
  },
};

/**
 * Formats a date string into a more readable format with an ordinal suffix.
 * @param dateString - The date string to format.
 * @returns The formatted date or an error message if the date is invalid.
 */
export const formatDate = (dateString: string): string => {
  // Validate input type and sanitize input
  const sanitizedDateString =
    typeof dateString === "string" ? dateString.trim() : "";
  if (!sanitizedDateString) {
    return "Invalid date";
  }

  // Create a Date object and validate its value
  const date = new Date(sanitizedDateString);
  if (isNaN(date.getTime())) {
    console.warn("Invalid date provided:", sanitizedDateString);
    return "Invalid date";
  }

  // Format the date using the centralized configuration
  let formattedDate: string;
  try {
    formattedDate = new Intl.DateTimeFormat(
      dateFormatConfig.locale,
      dateFormatConfig.options
    ).format(date);
  } catch (error) {
    console.error("Date formatting failed:", error);
    return "Date formatting error";
  }

  const day = date.getDate();

  // Determine the ordinal suffix
  const daySuffix = (day: number): string => {
    if ([11, 12, 13].includes(day)) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Safely replace day number with ordinal
  try {
    return formattedDate.replace(/\d+/, `${day}${daySuffix(day)}`);
  } catch (error) {
    console.error("Error in replacing day with ordinal:", error);
    return formattedDate; // Return formatted date without suffix if error occurs
  }
};

/* How to use : 


? Example date strings to format
const date1 = '2024-08-19'; // Standard date string
const date2 = '   2024-12-01  '; // Date string with extra spaces
const date3 = 'invalid-date'; // Invalid date string

? Using the formatDate function
const formattedDate1 = formatDate(date1); // Expected output: "August 19th, 2024"
const formattedDate2 = formatDate(date2); // Expected output: "December 1st, 2024"
const formattedDate3 = formatDate(date3); // Expected output: "Invalid date"

*/
