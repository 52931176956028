import { Box, Fade } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { People } from "./People";
import { CrmComponentMap, CrmPaths } from "./CRMTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  getAllBusiness__api,
  getAllContacts__api,
  getAllDeals__api,
  getAllGroups__api,
  getAllOutlookContacts__api,
  recallAPI__CRM,
  reCallCRM_APIs_service,
  getAllUsers,
} from "../../../redux";
import { msalInstance } from "./authConfigCRM";
const Crm: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const crmState = useSelector((state: RootState) => state && state?.crm);
  const { reCallAPIs, sortingDetails, filteringDetails } = crmState || {};

  const [checked, setChecked] = useState(true);
  const [currentPath, setCurrentPath] = useState<CrmPaths>(
    location.pathname as CrmPaths
  );

  const Component =
    CrmComponentMap[currentPath as keyof typeof CrmComponentMap] || People;

  useEffect(() => {
    setChecked(false);
  }, [location?.pathname]);

  const fetchData = async (apiFunc: any, payload?: any) => {
    try {
      const action = apiFunc(payload);
      await dispatch(action);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllContact = async () => {
    // Preparing the payload without stringifying
    const payload = {
      page: 1,
      limit: 100,
      sort: sortingDetails?.contacts?.map((sort) => ({
        field: sort?.field,
        order: sort?.order,
      })),
      filters: filteringDetails?.contacts?.map((filter) => ({
        field: filter?.field,
        conditions: filter?.conditions?.map((condition) => ({
          type: condition?.type,
          value: condition?.value,
          conjunction: condition?.conjunction,
        })),
      })),
    };

    // console.log("Sending Payload:", payload);

    await fetchData(getAllContacts__api, payload);
  };

  const getAllBusiness = async () => {
    const payload = {
      page: 1,
      limit: 100,
      sort: sortingDetails?.companies?.map((sort) => ({
        field: sort?.field,
        order: sort?.order,
      })),
      filters: filteringDetails?.companies?.map((filter) => ({
        field: filter?.field,
        conditions: filter?.conditions?.map((condition) => ({
          type: condition?.type,
          value: condition?.value,
          conjunction: condition?.conjunction,
        })),
      })),
    };

    await fetchData(getAllBusiness__api, payload);
  };

  const getAllDeals = async () => {
    const payload = {
      page: 1,
      limit: 100,
      sort: sortingDetails?.deals?.map((sort) => ({
        field: sort?.field,
        order: sort?.order,
      })),
      filters: filteringDetails?.deals?.map((filter) => ({
        field: filter?.field,
        conditions: filter?.conditions?.map((condition) => ({
          type: condition?.type,
          value: condition?.value,
          conjunction: condition?.conjunction,
        })),
      })),
    };

    await fetchData(getAllDeals__api, payload);
  };

  const getAllGroups = async () => {
    const payload = {
      page: 1,
      limit: 100,
      sort: sortingDetails?.groups?.map((sort) => ({
        field: sort?.field,
        order: sort?.order,
      })),
      filters: filteringDetails?.groups?.map((filter) => ({
        field: filter?.field,
        conditions: filter?.conditions?.map((condition) => ({
          type: condition?.type,
          value: condition?.value,
          conjunction: condition?.conjunction,
        })),
      })),
    };

    await fetchData(getAllGroups__api, payload);
  };

  const loginOutLook = async () => {
    login();
  };

  const getOutLookContact = async () => {
    try {
      // Retrieve the access token from local storage
      const accessToken = localStorage.getItem("outlookAccessToken");
      if (!accessToken) {
        return;
      }
      // Create the payload
      const payload = {
        accessToken: accessToken || "", // Provide a fallback if the token is not found
      };

      // Make the API call
      await fetchData(getAllOutlookContacts__api, payload);
    } catch (error) {
      console.error("Error fetching Outlook contacts:", error);
    }
  };

  useEffect(() => {
    const apiActions: Record<
      NonNullable<reCallCRM_APIs_service>,
      () => Promise<void>
    > = {
      All: async () => {
        await Promise?.all([
          getAllContact(),
          getAllBusiness(),
          getAllDeals(),
          getAllGroups(),
          getOutLookContact(),
        ]);
        dispatch(recallAPI__CRM(null));
      },
      getAllContacts: async () => {
        await getAllContact();
        await getOutLookContact();
        dispatch(recallAPI__CRM(null));
      },
      getAllCompanies: async () => {
        await getAllBusiness();
        dispatch(recallAPI__CRM(null));
      },
      getAllDeals: async () => {
        await getAllDeals();
        dispatch(recallAPI__CRM(null));
      },
      getAllGroups: async () => {
        await getAllGroups();
        dispatch(recallAPI__CRM(null));
      },
      loginOutLookContacts: async () => {
        await loginOutLook();
        dispatch(recallAPI__CRM(null));
      },
    };

    // Check if reCallAPIs is not null and is a valid key in apiActions
    if (reCallAPIs && apiActions[reCallAPIs]) {
      apiActions[reCallAPIs]();
    } 
    // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [reCallAPIs]);

  useEffect(() => {
    if (!checked) {
      const timer = setTimeout(() => {
        setCurrentPath(location?.pathname as CrmPaths);
        setChecked(true);
      }, 200); // Duration of the fade out transition
      return () => clearTimeout(timer);
    }
  }, [checked, location.pathname]);

  const [isMsalInitialized, setIsMsalInitialized] = useState<boolean>(false);

  // Updated scopes to include Contacts.ReadWrite
  const loginRequest = {
    scopes: [
      "openid",
      "profile",
      "User.Read", // To access user's profile info, including their photo
      "Contacts.Read", // To read contacts
      "Contacts.ReadWrite", // To read and write contacts (if needed)
      "offline_access", // To get refresh tokens
    ],
  };

  useEffect(() => {
    const initializeMsal = async () => {
      try {
        // Only initialize MSAL once
        await msalInstance.initialize();
        setIsMsalInitialized(true);

        // Check for redirect and handle it immediately
        const handleRedirectResponse = async () => {
          const authResponse: any = await msalInstance.handleRedirectPromise();
          if (authResponse) {
            sendTokensToBackend(authResponse);
          }
        };

        await handleRedirectResponse();
      } catch (err) {
        console.error("MSAL Initialization Error:", err);
      }
    };

    initializeMsal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = async () => {
    try {
      if (!isMsalInitialized) return;

      // This will open a popup for the user to login
      const authResponse = await msalInstance.loginPopup(loginRequest);
      sendTokensToBackend(authResponse);
      dispatch(recallAPI__CRM(null));
    } catch (error) {
      console.error("Login failed", error);
    }
  };

  const sendTokensToBackend = async (allDataResponse: any) => {
    try {
      // Extract the access token from the response
      const accessToken = allDataResponse?.accessToken; // Ensure that this property exists in your response

      // Save the access token in local storage
      await localStorage.setItem("outlookAccessToken", accessToken);

      dispatch(recallAPI__CRM("getAllContacts"));
    } catch (error) {
      console.error("Error saving tokens:", error);
    }
  };
  const handleGetAlluser = async () => {
    await dispatch(getAllUsers());
  };

  useEffect(() => {
    handleGetAlluser();
  }, []);
  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Fade in={checked} timeout={{ enter: 200, exit: 200 }}>
        <Box sx={{ width: "100%" }}>
          <Component />
        </Box>
      </Fade>
    </Box>
  );
};

export default Crm;
