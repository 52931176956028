import { Box, Stack, Typography } from "@mui/material";
import React from "react";

interface CurrentTimeAreaProps {
  value: any;
}

const CurrentTimeArea: React.FC<CurrentTimeAreaProps> = ({ value }) => {
  const { flagSrc, countryName, time } = value;
  return (
    <Stack
      sx={{
        flexDirection: "row",
        gap: "10px",
        justifyContent: "space-between",
      }}
    >
      <Stack sx={{ flexDirection: "row", gap: "5px" }}>
        <Box
          component={"img"}
          src={flagSrc}
          alt="flag src"
          sx={{ height: "15px", width: "23px", borderRadius: "20px" }}
        />
        <Typography
          sx={{
            font: "normal normal 400 14px/18px Source Serif Pro",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {countryName}
        </Typography>
      </Stack>
      <Typography
        sx={{
          font: "normal normal 400 14px/18px Source Serif Pro",
          whiteSpace: "nowrap",
          flexShrink: 0,
        }}
      >
        {time}
      </Typography>
    </Stack>
  );
};

export default CurrentTimeArea;
