import * as React from "react";
import {
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  InputBase,
  Tooltip,
} from "@mui/material";
import S from "./TodoMenuDropdown.module.css";
import { GreenDropdownIcon } from "../../../images";
import { TodoMenuDropdownOptionTypes } from "./TodoMenuDropdownTypes";
import { ColorPickerDot } from "../ColorPickerDot";

interface TodoMenuDropdownProps {
  options?: TodoMenuDropdownOptionTypes[];
  componentHeader?: string;
  multiple?: boolean;
  width?: string;
  onChange?: (selectedOption: string) => void;
}

const DUMMY_DATA: TodoMenuDropdownOptionTypes[] = [
  {
    id: 1,
    value: "To Do",
    defaultColorHex: "#808080",
    customColorHex: "#808080",
  },
  {
    id: 2,
    value: "In Progress",
    defaultColorHex: "#007BFF",
    customColorHex: "#007BFF",
  },
  {
    id: 3,
    value: "In Review",
    defaultColorHex: "#FFA500",
    customColorHex: "#FFA500",
  },
  {
    id: 4,
    value: "Done",
    defaultColorHex: "#28A745",
    customColorHex: "#28A745",
  },
  {
    id: 5,
    value: "Delayed",
    defaultColorHex: "#DC3545",
    customColorHex: "#DC3545",
  },
];

const TodoMenuDropdown: React.FC<TodoMenuDropdownProps> = React.memo(
  ({
    options = DUMMY_DATA,
    componentHeader,
    multiple = false,
    width = "125px",
    onChange,
    ...props
  }) => {
    const [personName, setPersonName] = React.useState<string[]>([]);
    const [open, setOpen] = React.useState(false);
    const [localOptions, setLocalOptions] =
      React.useState<TodoMenuDropdownOptionTypes[]>(options);
    const [addingCustom, setAddingCustom] = React.useState(false);
    const [newCustomValue, setNewCustomValue] = React.useState("");
    const [newCustomColor, setNewCustomColor] = React.useState("#007BB2");

    const handleChange = React.useCallback(
      (event: SelectChangeEvent<string[]>) => {
        const {
          target: { value },
        } = event;
        setPersonName(typeof value === "string" ? value.split(",") : value);

        if (onChange) {
          const selectedValue =
            typeof value === "string" ? value : value.join(", ");
          onChange(selectedValue);
        }
      },
      [onChange]
    );

    const stopPropagation = (event: React.MouseEvent | React.KeyboardEvent) => {
      event.stopPropagation();
    };

    const handleAddCustomClick = (event: React.MouseEvent) => {
      event.stopPropagation();
      setAddingCustom(true);
    };

    const handleSaveCustomClick = () => {
      if (newCustomValue?.trim() !== "") {
        const newCustomOption: TodoMenuDropdownOptionTypes = {
          id: localOptions.length + 1,
          value: newCustomValue,
          defaultColorHex: newCustomColor,
          customColorHex: newCustomColor,
        };
        setLocalOptions([...localOptions, newCustomOption]);
      }
      setAddingCustom(false);
      setNewCustomValue("");
      setNewCustomColor("#000000");
    };

    const handleClose = () => {
      if (addingCustom && newCustomValue?.trim() !== "") {
        handleSaveCustomClick();
      }
      setAddingCustom(false);
      setOpen(false);
    };

    const handleColorChange = (id: number, color: string) => {
      const updatedOptions = localOptions.map((option) =>
        option.id === id ? { ...option, customColorHex: color } : option
      );
      setLocalOptions(updatedOptions);
    };

    const MenuProps = {
      PaperProps: {
        className: S["todo-menu-dropdown__menu"],
        style: { width: width },
      },
    };

    return (
      <div>
        <FormControl size="small" style={{ width }} {...props}>
          <Select
            multiple={multiple}
            displayEmpty
            IconComponent={() => (
              <GreenDropdownIcon
                open={open}
                sx={{ width: "40%", height: "40%", marginRight: "2px" }}
              />
            )}
            value={personName}
            onChange={handleChange}
            input={
              <OutlinedInput
                className={S["todo-menu-dropdown__inputbox"]}
                classes={{
                  notchedOutline: S["todo-menu-dropdown__notchedOutline"],
                  focused: S["todo-menu-dropdown__focused"],
                }}
              />
            }
            onOpen={() => setOpen(true)}
            onClose={handleClose}
            renderValue={(selected) => {
              if (selected?.length === 0) {
                return (
                  <em className={S["todo-menu-dropdown__selectPlaceholder"]}>
                    {componentHeader}
                  </em>
                );
              }
              return (
                <span className={S["todo-menu-dropdown__selected"]}>
                  <span
                    style={{
                      maxWidth: "75px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      display: "inline-block",
                    }}
                  >
                    {selected?.join(", ")}
                  </span>
                  <span onMouseDown={stopPropagation} onClick={stopPropagation}>
                    <ColorPickerDot />
                  </span>
                </span>
              );
            }}
            MenuProps={MenuProps}
          >
            {localOptions.map((item) => (
              <MenuItem
                className={S["todo-menu-dropdown__menu-item"]}
                key={item.id}
                value={item.value}
              >
                <ListItemText
                  primary={item.value}
                  primaryTypographyProps={{
                    className: S["todo-menu-dropdown__list-item-text"],
                  }}
                />
                <div onMouseDown={stopPropagation} onClick={stopPropagation}>
                  <ColorPickerDot
                    initialColor={item.customColorHex}
                    onColorChange={(color) => handleColorChange(item.id, color)}
                  />
                </div>
              </MenuItem>
            ))}
            {addingCustom ? (
              <Stack className={S["todo-menu-dropdown__menu-item"]}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <InputBase
                    value={newCustomValue}
                    onChange={(e) => setNewCustomValue(e.target.value)}
                    placeholder="Enter text"
                    fullWidth
                    onMouseDown={stopPropagation}
                    onClick={stopPropagation}
                    onKeyDown={stopPropagation} // Prevent key events from propagating
                    onKeyUp={stopPropagation} // Prevent key events from propagating
                    sx={{
                      height: "20px",
                      border: "0.5px solid #D9E3EF",
                      font: "normal normal 300 12px/16px Source Serif Pro",
                    }}
                  />
                  <div onMouseDown={stopPropagation} onClick={stopPropagation}>
                    <ColorPickerDot
                      onColorChange={(color) => setNewCustomColor(color)}
                    />
                  </div>
                </Stack>
              </Stack>
            ) : (
              <MenuItem className={S["todo-menu-dropdown__menu-item"]}>
                <Tooltip title="Add new priority" placement="right" arrow>
                  <ListItemText
                    onClick={(e) => handleAddCustomClick(e)}
                    primary="Add Custom"
                    primaryTypographyProps={{
                      className: S["todo-menu-dropdown__list-item-text"],
                    }}
                  />
                </Tooltip>
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </div>
    );
  }
);

export default TodoMenuDropdown;
