import { Dialog, Stack } from "@mui/material";
import React, {lazy, Suspense, useEffect} from "react";
import {
  a11yProps,
  StyledTab,
  TabPanel,
  TabsWithState,
} from "../../../../common/CardTabsWithoutIcon";
import { AddAgendaPopup } from "../AddAgendaPopup";
import {AuthState, getPastMeetingDetails} from "../../../../../redux";
import { RootState } from "../../../../../redux/store";
import {useDispatch, useSelector} from "react-redux";
import { VideoCenterState } from "../../../../../redux/Slice/videoCenter/VideoCenterTypes";
import { useTranslation } from "react-i18next";
import { MeetingNotesCardLarge } from "../MeetingNotesCardLarge";
import {useParams} from "react-router-dom";

interface CallCenterTabsProps {}

const LazyAgendaTab = lazy(() => import("./tabs/AgendaTab/AgendaTab"));
const LazyDetailsTab = lazy(() => import("./tabs/DetailsTab/DetailsTab"));
const LazyFilesTab = lazy(() => import("./tabs/FilesTab/FilesTab"));
const LazyNotesTab = lazy(() => import("./tabs/NotesTab/NotesTab"));
const LazyPostMeetingTab = lazy(() => import("./tabs/PostMeeting/PostMeetingTab"));
const LazyActivityTab = lazy(() => import("./tabs/ActivityTab/ActivityTab"));

const PastCallCenterTabs: React.FC<CallCenterTabsProps> = ({}) => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = React.useState(0);
  const [openNotePopup, setOpenNotePopup] = React.useState(false);
  const [openAgendaPopup, setOpenAgendaPopup] = React.useState(false);
  const [editAgendaItem, setEditAgendaItem] = React.useState(null);
  const dispatch = useDispatch();
  const {id}= useParams()

  const { selectedMeeting } = useSelector(
    (state: RootState) => state.videoCenter as VideoCenterState
  );
  const { user } = useSelector((state: RootState) => state.auth as AuthState);
  const tabs: {
    label: string;
    content: React.ReactNode;
  }[] = [
    {
      label: t("PAST_CALL_DETAILS.POST_MEETING"),
      content: <LazyPostMeetingTab />,
    },
    {
      label: t("VIDEO_CENTER.MEETING.DETAILS"),
      content: <LazyDetailsTab />,
    },
    {
      label: t("VIDEO_CENTER.MEETING.NOTES"),
      content: <LazyNotesTab />,
    },
    {
      label: t("VIDEO_CENTER.MEETING.AGENDA"),
      content: <LazyAgendaTab />,
    },
    {
      label: t("VIDEO_CENTER.MEETING.FILES"),
      content:  <LazyFilesTab />,
    },
    {
      label: t("PAST_CALL_DETAILS.ACTIVITY"),
      content: <LazyActivityTab />,
    },
  ];

  const handleClickOnCancelNote = () => {
    setOpenNotePopup(false);
  };

  const handleClickOnCancelAgenda = () => {
    setOpenAgendaPopup(false);
    setEditAgendaItem(null);
  };
  useEffect(() => {
    dispatch(getPastMeetingDetails(id))
  }, [id]);

  return (
    <Stack
      sx={{
        bgcolor: "white",
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        gap: "20px",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "24px",
          borderBottom: "1px solid #E9EDF2",
          justifyContent: "space-between",
        }}
      >
        <Stack direction="row" gap="24px">
          <TabsWithState
            ariaLabel="Call center tabs"
            value={tabValue}
            onChange={(event, newValue) => setTabValue(newValue)}
            tabIndicatorColor="#FF8181"
            sx={{
              maxWidth: "max-content",
              gap: "25px",
              borderBottom: "0px solid #E9EDF2",
              "& .css-1aquho2-MuiTabs-indicator": {
                bottom: "0px !important",
              },
            }}
          >
            {tabs.map((tab, index) => (
              <StyledTab
                style={{
                  font: "normal normal 300 15px/19px Source Serif Pro",
                }}
                key={index}
                disableRipple
                label={tab.label}
                {...a11yProps(index)}
              />
            ))}
          </TabsWithState>
        </Stack>
      </Stack>
      <Dialog onClose={handleClickOnCancelNote} open={openNotePopup}>
        <MeetingNotesCardLarge
          userData={user?.data}
          meetingData={selectedMeeting?.meeting}
          isPreMeeting={true}
          drawerCloseButtonClk={handleClickOnCancelNote}
        />
      </Dialog>
      <Dialog onClose={handleClickOnCancelAgenda} open={openAgendaPopup}>
        <AddAgendaPopup
          userData={user?.data}
          meetingData={selectedMeeting?.meeting}
          editAgendaItem={editAgendaItem}
          onClose={handleClickOnCancelAgenda}
        />
      </Dialog>

      {tabs.map((tab, index) => (
        <TabPanel
          style={{ display: "contents" }}
          key={index}
          value={tabValue}
          index={index}
        >
          <Suspense fallback={<div>Loading...</div>}>{tab.content}</Suspense>
        </TabPanel>
      ))}
    </Stack>
  );
};

export default PastCallCenterTabs;
