import React, { useState, useEffect } from "react";

interface DateViewProps {
  initialValue?: string;
  onChange?: (value: string) => void;
}

const DateView: React.FC<DateViewProps> = ({ initialValue, onChange }) => {
  const [selectedDate, setSelectedDate] = useState<string | undefined>(
    initialValue
  );

  // Update state when initialValue changes
  useEffect(() => {
    setSelectedDate(initialValue);
  }, [initialValue]);

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = event.target.value;

    // Convert to ISO format
    const isoDate = new Date(newDate)?.toISOString();
    setSelectedDate(isoDate);

    if (onChange) {
      onChange(isoDate);
    }
  };

  return (
    <input
      type="date"
      value={
        selectedDate ? new Date(selectedDate)?.toISOString()?.split("T")?.[0] : ""
      }
      onChange={handleDateChange}
    />
  );
};

export default DateView;
