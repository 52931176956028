import {
  Box,
  IconButton,
  InputBase,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CalendarIconBearishOSIconPng,
  LocationPng,
} from "../../../../../images";
import {
  ButtonSmall,
  ButtonType,
  DropdownWIconLarge,
  EditableTextArea,
  EmailRichTextMenu,
  SmallButtonColor,
} from "../../../../common";
import { getAllTimezonesFormatted } from "../../../Settings/Components/common";
import {
  getIconPopupCalendarIcons,
  IconPopupCalendarIconsEnum,
} from "../IconPopupCalendar/IconPopupCalendarTypes";
import { IconPopupCalendar } from "../IconPopupCalendar";
import { useDispatch } from "react-redux";
import {
  CalendarData,
  createCalendar__api,
  recallAPI,
  updateCalendar__api,
} from "../../../../../redux";

interface CalendarSettingsEditPopupProps {
  setZoomType?: any;
  createTrue?: boolean;
  calData?: any; // Removed existingCalData, assuming you want to use calData directly
  getAllCalendar?: any; //
}

const CalendarSettingsEditPopup: React.FC<CalendarSettingsEditPopupProps> = ({
  setZoomType,
  createTrue = false,
  calData, // Directly use calData
  getAllCalendar,
}) => {
  const dispatch = useDispatch();
  const [timezones, setTimezones] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [calendarName, setCalendarName] = useState("");
  const [calendarDetails, setCalendarDetails] = useState("");
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [selectedIcon, setSelectedIcon] = useState<IconPopupCalendarIconsEnum>(
    IconPopupCalendarIconsEnum.SoccerBallIcon
  );

  useEffect(() => {
    const fetchedTimezones = getAllTimezonesFormatted();
    setTimezones(
      fetchedTimezones?.map((timezone) => timezone?.name || timezone?.id)
    );

    // Set state based on calData if available
    if (calData) {
      setCalendarName(calData.name);
      setCalendarDetails(calData.description);
      setSelectedTimeZone(calData.timezone);
      setSelectedIcon(calData.icon as IconPopupCalendarIconsEnum); // Cast to enum type if necessary
    } else {
      // Reset to default values if no calData is provided
      setCalendarName("");
      setCalendarDetails("");
      setSelectedTimeZone("America/Belize");
      setSelectedIcon(IconPopupCalendarIconsEnum.SoccerBallIcon);
    }
  }, [calData]); // Trigger effect when calData changes

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleIconChange = (selectedIcon: IconPopupCalendarIconsEnum) => {
    setSelectedIcon(selectedIcon);
    handleClose();
  };

  const handleCreateOrUpdateCalendar = async () => {
    try {
      const payload: CalendarData = {
        name: calendarName,
        description: calendarDetails,
        timezone: selectedTimeZone,
        icon: selectedIcon,
      };

      if (!payload?.name) {
        return;
      }

      if (createTrue) {
        const action = createCalendar__api(payload);
        const calendarCreateRes: any = await dispatch(action);

        if (calendarCreateRes?.success && setZoomType) {
          setZoomType(null);
          if (getAllCalendar) {
            getAllCalendar();
          }
        }
      } else if (calData?._id) {
        const action = updateCalendar__api(calData?._id, payload);
        const calendarCreateRes: any = await dispatch(action);

        if (calendarCreateRes?.success && setZoomType) {
          setZoomType(null);
          if (getAllCalendar) {
            getAllCalendar();
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Stack
      sx={{
        width: "335px",
        borderRadius: "5px",
        boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
        bgcolor: "white",
        padding: "15px 20px 12px 15px",
        boxSizing: "border-box",
        gap: "10px",
      }}
    >
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
        <Box
          component={"img"}
          src={CalendarIconBearishOSIconPng}
          alt="calender png"
          sx={{ height: "17px", width: "17px" }}
        />
        <Typography
          sx={{ font: "normal normal 400 14px/14px Source Serif Pro" }}
        >
          {createTrue ? `Create Calendar` : `Calendar Settings`}
        </Typography>
      </Stack>
      <Typography
        sx={{
          font: "normal italic 300 14px/14px Source Serif Pro",
          width: "250px",
          marginBottom: "5px",
        }}
      >
        Adjust the settings on your calendar below...
      </Typography>
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "8px" }}>
        <Typography
          sx={{ font: "normal normal 400 14px/18px Source Serif Pro" }}
        >
          Calendar name
        </Typography>
        <IconButton sx={{ p: "2px" }} onClick={handleClick}>
          {React.createElement(getIconPopupCalendarIcons(selectedIcon), {
            w: 15,
          })}
        </IconButton>
      </Stack>
      <InputBase
        placeholder="Calendar name"
        sx={{
          height: "40px",
          width: "100%",
          border: "0.5px solid #E9EDF2",
          borderRadius: "5px",
          font: "normal normal 300 14px/18px Source Serif Pro",
          boxSizing: "border-box",
          padding: "0px 10px",
          bgcolor: "white",
        }}
        value={calendarName}
        onChange={(e: any) => setCalendarName(e?.target?.value)}
      />
      <Typography sx={{ font: "normal normal 400 14px/18px Source Serif Pro" }}>
        Calendar details
      </Typography>
      <EditableTextArea
        style={{
          height: "130px",
          border: "1px solid #E9EDF2",
          borderRadius: "5px",
          padding: "10px",
          boxSizing: "border-box",
        }}
        value={calendarDetails}
        onChange={(e: any) => setCalendarDetails(e?.target?.value)}
        placeholder="Calendar details"
      />

      <Stack
        sx={{ flexDirection: "row", justifyContent: "end", marginY: "5px" }}
      >
        <EmailRichTextMenu />
      </Stack>

      <DropdownWIconLarge
        iconSrc={LocationPng}
        initialValue={selectedTimeZone}
        options={timezones}
        label="Timezone"
        onChange={(e: any) => setSelectedTimeZone(e)}
      />
      <Stack sx={{ flexDirection: "row", justifyContent: "center" }}>
        <ButtonSmall
          label={createTrue ? "Create" : "Save"}
          onClick={() => handleCreateOrUpdateCalendar()}
          disabled={!calendarName}
          types={ButtonType.Button}
          colorVarient={SmallButtonColor.CalendarMarronPink}
        />
      </Stack>

      {/* Popover Component */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            padding: "0px",
          },
        }}
      >
        <IconPopupCalendar
          disabledIcons={[
            IconPopupCalendarIconsEnum.AirplaneIcon,
            IconPopupCalendarIconsEnum.BusIcon,
            IconPopupCalendarIconsEnum.CreditCardIcon,
            IconPopupCalendarIconsEnum.MovieMediaPlayerIcon,
            IconPopupCalendarIconsEnum.CarIcon,
            IconPopupCalendarIconsEnum.CallIcon,
          ]}
          onSelect={(value: IconPopupCalendarIconsEnum) =>
            handleIconChange(value)
          }
        />
      </Popover>
    </Stack>
  );
};

export default CalendarSettingsEditPopup;
