import React, { useState } from "react";
import ViewHeaderButton from "../ViewHeaderButton/ViewHeaderButton";
import { Stack } from "@mui/material";
import DefaultQuickTaskView from "./screen/DefaultQuickTaskView";
import CreateQuickTaskView from "./screen/CreateQuickTaskView";

interface NewTaskViewProps {
  onBackButtonClick: () => void;
}

const NewTaskView: React.FC<NewTaskViewProps> = ({ onBackButtonClick }) => {
  const [taskScreen, setTaskScreen] = useState<"default" | "create">("default");
  return (
    <Stack sx={{ flex: 1, height: "100%" }}>
      {taskScreen === "default" ? (
        <>
          <ViewHeaderButton
            headerText="All tasks"
            createNewButtonText="Create new task"
            onBackButtonClick={onBackButtonClick}
            onCreateButtonClick={() => setTaskScreen("create")}
          />
          <DefaultQuickTaskView />
        </>
      ) : (
        <CreateQuickTaskView />
      )}
    </Stack>
  );
};

export default NewTaskView;
