import { IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { WeekDaysEnum } from "../../../../../../../Utils";

interface WeekSelectorViewProps {
  onChange: (value: WeekDaysEnum[]) => void; // Use WeekDaysEnum for type safety
  initialValue?: WeekDaysEnum[];
}

const WeekSelectorView: React.FC<WeekSelectorViewProps> = ({
  onChange,
  initialValue = [],
}) => {
  const daylist: WeekDaysEnum[] = Object.values(WeekDaysEnum);

  // Initialize selected days based on initialValue
  const [selectedDays, setSelectedDays] = useState<number[]>(
    initialValue
      .map((day) => daylist.indexOf(day))
      .filter((index) => index !== -1)
  );

  // Toggle day selection
  const toggleDay = (index: number) => {
    setSelectedDays((prevSelectedDays) =>
      prevSelectedDays.includes(index)
        ? prevSelectedDays.filter((day) => day !== index)
        : [...prevSelectedDays, index]
    );
  };

  useEffect(() => {
    onChange(daylist.filter((item, index) => selectedDays.includes(index)));
  }, [selectedDays]);

  return (
    <Stack sx={{ flexDirection: "row", gap: "5px" }}>
      {daylist.map((day, index) => (
        <IconButton
          key={index}
          onClick={() => toggleDay(index)}
          sx={{
            p: 0,
            height: "20px",
            width: "20px",
            borderRadius: "50%",
            border: "0.5px solid #E9EDF2",
            boxSizing: "border-box",
            backgroundColor: selectedDays.includes(index)
              ? "#B5005B66"
              : "white",
            ":hover": {
              bgcolor: selectedDays.includes(index) ? "#B5005B66" : "white",
            },
          }}
        >
          <Typography
            sx={{
              font: "normal normal 300 13px/16px Source Serif Pro",
              color: selectedDays.includes(index) ? "white" : "black",
              fontWeight: selectedDays.includes(index) ? "700" : "300",
              paddingTop: "2px",
            }}
          >
            {day.slice(0, 1).toUpperCase()}
          </Typography>
        </IconButton>
      ))}
    </Stack>
  );
};

export default WeekSelectorView;
