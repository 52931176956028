// App.tsx
import React from "react";
import { Box } from "@mui/material";
import { MessageCenterTabs } from "./components";
import { SearchBar } from "../CRM/components";
import { useTranslation } from "react-i18next";

const Message: React.FC = () => {

  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      padding="0 30px 0 30px"
      flexDirection="column"
      height="92vh"
      width="100%"
    >
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" justifyContent="end" alignItems="center" p={2}>
          <SearchBar
            iconSize={15}
            containerSx={{ borderRadius: "5px" }}
            font="normal normal 400 11px/14px Source Serif Pro"
            customStyles={{ width: "330px", display: "flex", color: "black" }}
            placeholder={t("MESSAGE.SEARCH_ALL_CHATS")}
          />
        </Box>
        <MessageCenterTabs />
      </Box>
    </Box>
  );
};

export default Message;
