// Interface for regular menu items
export interface AppointmentMenuItemInterface {
  id: number;
  value: string;
  divider?: false;
}

// Type for divider
export interface AppointmentMenuDividerInterface {
  divider: true;
  id?: undefined;
  value?: undefined;
}

// Union type for both menu items and dividers
export type AppointmentMenuListInterface =
  | AppointmentMenuItemInterface
  | AppointmentMenuDividerInterface;

// List including both items and dividers
export const AppointmentMenuOptionList: AppointmentMenuListInterface[] = [
  { id: 1, value: "Appointment" },
  { divider: true },
  { id: 2, value: "Meeting" },
  { id: 3, value: "Reminders" },
  { id: 4, value: "Bills/Payment" },
  { id: 5, value: "Workshop" },
  { id: 6, value: "Other" },
  { id: 7, value: "Out of Office" },
];
