import { Box, Stack } from "@mui/material";
import React from "react";
import { TouchPointVariantEnum, TouchPointViewProps } from "../TouchPointsType";
import { TraxIcon } from "../../../../../../../../Router/RouteIcon";

type BillPropsType = Extract<
  TouchPointViewProps,
  { variant: TouchPointVariantEnum.Bill }
>;

const BillsInnerView: React.FC<BillPropsType> = ({
  invoiceNum,
  invoiceStatus,
  timeZone,
  timestamp,
}) => {
  return (
    <Stack sx={{ flexDirection: "row", alignItems: "start", gap: "15px" }}>
      <Box sx={{ flexShrink: 0 }}>
        <TraxIcon w={15} />
      </Box>
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "4px",
          flexWrap: "wrap",
          font: "normal normal 300 13px/16px Source Serif Pro",
        }}
      >
        <span>Invoice</span>
        <span>{invoiceNum}</span>
        <span>sent</span>
        <span>on</span>
        <span>{timestamp}</span>
        <span>{timeZone}</span>
        <span>{invoiceStatus}</span>
      </Stack>
    </Stack>
  );
};

export default BillsInnerView;
