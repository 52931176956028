import React from "react";
import { Typography, Box } from "@mui/material";

type Props = {};

const OverViewMain = (props: Props) => {
  return (
    <Box sx={{ padding: 2 }}>
      <Typography
        variant="h2"
        sx={{ marginBottom: 1, fontFamily: "Source Serif Pro" }}
      >
        Task Management Overview
      </Typography>
      <Typography
        variant="body1"
        sx={{ color: "text.secondary", fontFamily: "Source Serif Pro" }}
      >
        This section provides a comprehensive overview of all your tasks.
        Explore different views to manage your tasks efficiently, track
        progress, and ensure nothing falls through the cracks.
      </Typography>
      {/* You can add your different views here */}
    </Box>
  );
};

export default OverViewMain;
