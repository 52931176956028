import React, { useState } from "react";
import {
  ClickAwayListener,
  MenuItem,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { PlayArrowIcon } from "../../../../../images";
import styles from "./CalendarViewSelect.module.css";
import { useTranslation } from "react-i18next";

interface CalendarViewSelectProps {
  onClick: (value: string) => void;
}

const CalendarViewSelect: React.FC<CalendarViewSelectProps> = ({ onClick }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();
  const [selectedView, setSelectedView] = useState<string>("MONTH_VIEW");
  // Handle opening the popover
  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle closing the popover
  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  // Handle selecting a menu item
  const handleMenuItemClick = (view: string) => {
    setSelectedView(view);
    onClick(
      view === "DAY_VIEW"
        ? "Day View"
        : view === "WEEK_VIEW"
        ? "Week View"
        : view === "MONTH_VIEW"
        ? "Month View"
        : "Month View"
    );
    handleClosePopover();
  };

  return (
    <ClickAwayListener onClickAway={() => setIsVisible(false)}>
      <Stack spacing={1} className={styles["calendar-view-select"]}>
        {/* Display the main view selection area */}
        <Stack className={styles["calendar-view-select__container"]}>
          <Typography
            onClick={() => setIsVisible((pre) => !pre)}
            className={styles["calendar-view-select__title"]}
          >
            {t("CALENDAR.CALENDAR")}
          </Typography>

          <PlayArrowIcon direction="right" color="black" w={15} />

          {isVisible && (
            <Typography
              className={styles["calendar-view-select__selected-view"]}
              onClick={handleOpenPopover}
            >
              {t(`CALENDAR.${selectedView}`)}
            </Typography>
          )}
        </Stack>

        {/* Popover for menu items */}
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          PaperProps={{
            className: styles["calendar-view-select__popover-paper"],
          }}
        >
          <Stack className={styles["calendar-view-select__popover-content"]}>
            {["DAY_VIEW", "WEEK_VIEW", "MONTH_VIEW"].map(
              (item, index) => (
                <MenuItem
                  key={index}
                  className={styles["calendar-view-select__menu-item"]}
                  onClick={() => handleMenuItemClick(item)}
                >
                  <Typography
                    className={styles["calendar-view-select__menu-item-text"]}
                  >
                    {t(`CALENDAR.${item}`)}
                  </Typography>
                </MenuItem>
              )
            )}
          </Stack>
        </Popover>
      </Stack>
    </ClickAwayListener>
  );
};

export default CalendarViewSelect;
