import { Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { CheckboxMedium } from "../../../../common";
import {
  AttachmentView,
  IconsGroupView,
  ReplyButtonView,
  StarToggleView,
  TimeView,
  UserCount,
  UserInfoChip,
} from "../EmailCardComponents";
import { EmailFilterChips } from "../EmailFilterChips";
import { EmailFilterChipsVariantEnum } from "../EmailFilterChips/EmailFilterChipsTypes";
import { EmailClickedMenu } from "../EmailClickedMenu";
import { getAccessToken } from "../../../../common/LocalStorage";

interface EmailCardLongProps {
  isSelected?: boolean;
  isClicked?: boolean;
  showReplyButton?: boolean;
  email: any;
  handleOpenBody?: (messageId: any) => Promise<void>;
  handleAddStar?: (messageId: any) => Promise<void>;
  isRead?: boolean;
}

const EmailCardLong: React.FC<EmailCardLongProps> = ({
  isClicked = false,
  isSelected = false,
  showReplyButton = false,
  email,
  handleOpenBody,
  handleAddStar,
  isRead = false,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const [accessToken, setAccessToken] = useState<string>("");
  const messageId = email?.id;
  const [isToggled, setIsToggled] = useState(email?.isStarted);

  const cardRef = useRef<HTMLDivElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      cardRef.current &&
      !cardRef.current.contains(event.target as Node) &&
      menuRef.current &&
      !menuRef.current.contains(event.target as Node)
    ) {
      setIsHovered(false);
    }
  };

  useEffect(() => {
    const token = getAccessToken();
    setAccessToken(token);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleStar = (messageId: string) => {
    setIsToggled((val: any) => !val);
    handleAddStar && handleAddStar(messageId);
  };
  const generateColorFromEmail = (email: string) => {
    let hash = 0;
    for (let i = 0; i < email?.length; i++) {
      hash = email?.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = `#${(hash & 0x00ffffff)?.toString(16)?.padStart(6, "0")}`;
    return color;
  };
  return (
    <Stack
      className="Esquare-container"
      ref={cardRef}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        height: "50px",
        bgcolor: "#ffffff87",
        // borderRadius: "5px",
        boxSizing: "border-box",
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        padding: "0px 15px 0px 11px",
        borderBottom: "1px solid #E9EDF2",
        gap: "10px",
        justifyContent: "space-between",
        boxShadow: isHovered ? "0px 3px 3px 0px rgba(0, 0, 0, 0.15)" : null,
        borderLeft:
          isSelected || isClicked ? "4px solid #E9EDF2" : "4px solid #fff ",
      }}
    >
      {/* left side components */}
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
        <CheckboxMedium boxSize={15} checked={isSelected} />
        {showReplyButton && <ReplyButtonView />}
        <Stack minWidth={'180px'} sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
          <StarToggleView
            onStarClick={() => handleStar(messageId)}
            isToogled={isToggled}
          />
          <UserInfoChip
            popoverPlace="bottom"
            userName={email?.sender?.emailAddress?.name}
            userEmail={email?.sender?.emailAddress?.address}
            userIconSrc={email?.sender?.emailAddress?.address?.logo}
            userAvatarColor={generateColorFromEmail(
              email?.sender?.emailAddress?.address
            )}
          />
        </Stack>
        <UserCount userList={email?.ccRecipients} />

        <Typography
          sx={{
            font: `normal normal ${
              isRead ? 400 : 700
            } 11px/14px Source Serif Pro`,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "225px",
            cursor: "pointer",
          }}
          onClick={() => handleOpenBody && handleOpenBody(messageId)}
        >
          {email?.subject}
        </Typography>

        <Typography
          sx={{
            font: "normal normal 300 11px/14px Source Serif Pro",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "245px",
            cursor: "pointer",
          }}
          onClick={() => handleOpenBody && handleOpenBody(messageId)}
        >
          {email?.bodyText}
        </Typography>

        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
          <EmailFilterChips
            variant={email?.folderName as EmailFilterChipsVariantEnum}
          />

          <AttachmentView AttachmentChipViewData={email?.attachments} />
        </Stack>
      </Stack>

      {/* right side components */}
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
        {/* {!isHovered ? <TimeView time={email?.createdDateTime} /> : <IconsGroupView email={email} accessToken={accessToken} messageId={messageId} />} */}
        <TimeView time={email?.createdDateTime} />
        <IconsGroupView
          email={email}
          accessToken={accessToken}
          messageId={messageId}
        />
        <div ref={menuRef}>
          <EmailClickedMenu accessToken={accessToken} messageId={messageId} />
        </div>
      </Stack>
    </Stack>
  );
};

export default EmailCardLong;
