import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CRMCenterState,
  FilterConfig,
  FilteringDetails,
  reCallCRM_APIs_service,
  SortConfig,
  SortingDetails,
} from "./CRMCenterType";
import {
  /* Add to Contact */
  addContact__Failure,
  addContact__Request,
  addContact__Success,

  /* Get One Contact */
  getOneContact__Failure,
  getOneContact__Request,
  getOneContact__Success,

  /* Update One Contact */
  updateOneContact__Failure,
  updateOneContact__Request,
  updateOneContact__Success,

  /* Delete One Contact */
  deleteOneContact__Failure,
  deleteOneContact__Request,
  deleteOneContact__Success,

  /* Add Business */
  addBusiness__Failure,
  addBusiness__Request,
  addBusiness__Success,

  /* Get One Business */
  getOneBusiness__Failure,
  getOneBusiness__Request,
  getOneBusiness__Success,

  /* Update One Business */
  updateOneBusiness__Failure,
  updateOneBusiness__Request,
  updateOneBusiness__Success,

  /* Delete One Business */
  deleteOneBusiness__Failure,
  deleteOneBusiness__Request,
  deleteOneBusiness__Success,

  /* Update Custom Elements */
  updateCustomElements__Failure,
  updateCustomElements__Request,
  updateCustomElements__Success,

  /* Get All Contacts */
  getAllContacts__Failure,
  getAllContacts__Request,
  getAllContacts__Success,

  /* Get All Business */
  getAllBusiness__Failure,
  getAllBusiness__Request,
  getAllBusiness__Success,

  /* Create Group */
  createGroup__Failure,
  createGroup__Request,
  createGroup__Success,

  /* Get All Groups */
  getAllGroups__Failure,
  getAllGroups__Request,
  getAllGroups__Success,

  /* Get Group By ID */
  getGroupById__Failure,
  getGroupById__Request,
  getGroupById__Success,

  /* Update Group */
  updateGroup__Failure,
  updateGroup__Request,
  updateGroup__Success,

  /* Delete Group */
  deleteGroup__Failure,
  deleteGroup__Request,
  deleteGroup__Success,

  /* Add to Group */
  addToGroup__Failure,
  addToGroup__Request,
  addToGroup__Success,

  /* Create Deal */
  createDeal__Failure,
  createDeal__Request,
  createDeal__Success,

  /* Get All Deals */
  getAllDeals__Failure,
  getAllDeals__Request,
  getAllDeals__Success,

  /* Get Deal By ID */
  getDealById__Failure,
  getDealById__Request,
  getDealById__Success,

  /* Update Deal */
  updateDeal__Failure,
  updateDeal__Request,
  updateDeal__Success,

  /* Delete Deal */
  deleteDeal__Failure,
  deleteDeal__Request,
  deleteDeal__Success,

  /* Add Note to Deal */
  addNoteTo__Failure,
  addNoteTo__Request,
  addNoteTo__Success,
  getNoteTo__Failure,
  getNoteTo__Request,
  getNoteTo__Success,
  getAllOutlookContacts__Request,
  getAllOutlookContacts__Failure,
  getAllOutlookContacts__Success,

  /* Get Outlook Contact By ID */
  getOutlookContactById__Request,
  getOutlookContactById__Failure,
  getOutlookContactById__Success,

  /* Create Outlook Contact */
  createOutlookContact__Request,
  createOutlookContact__Failure,
  createOutlookContact__Success,

  /* Update Outlook Contact */
  updateOutlookContact__Request,
  updateOutlookContact__Failure,
  updateOutlookContact__Success,
} from "./CRMCenterSliceAction";

const initialState: CRMCenterState = {
  error: null,
  data: null,
  reCallAPIs: "All",
  contactList: null,
  companyList: null,
  dealList: null,
  groupList: null,
  outlookContactList: null,
  sortingDetails: {
    contacts: [],
    companies: [],
    deals: [],
    groups: [],
    outlookContacts: [],
  },
  filteringDetails: {
    contacts: [],
    companies: [],
    deals: [],
    groups: [],
    outlookContacts: [],
  },
};

const crmCenter = createSlice({
  name: "crm", // Name of the slice
  initialState, // Initial state of the slice
  reducers: {
    /* Add to Contact */
    addContact__Failure,
    addContact__Request,
    addContact__Success,

    /* Get One Contact */
    getOneContact__Failure,
    getOneContact__Request,
    getOneContact__Success,

    /* Update One Contact */
    updateOneContact__Failure,
    updateOneContact__Request,
    updateOneContact__Success,

    /* Delete One Contact */
    deleteOneContact__Failure,
    deleteOneContact__Request,
    deleteOneContact__Success,

    /* Add Business */
    addBusiness__Failure,
    addBusiness__Request,
    addBusiness__Success,

    /* Get One Business */
    getOneBusiness__Failure,
    getOneBusiness__Request,
    getOneBusiness__Success,

    /* Update One Business */
    updateOneBusiness__Failure,
    updateOneBusiness__Request,
    updateOneBusiness__Success,

    /* Delete One Business */
    deleteOneBusiness__Failure,
    deleteOneBusiness__Request,
    deleteOneBusiness__Success,

    /* Update Custom Elements */
    updateCustomElements__Failure,
    updateCustomElements__Request,
    updateCustomElements__Success,

    /* Get All Contacts */
    getAllContacts__Failure,
    getAllContacts__Request,
    getAllContacts__Success,

    /* Get All Business */
    getAllBusiness__Failure,
    getAllBusiness__Request,
    getAllBusiness__Success,

    /* Create Group */
    createGroup__Failure,
    createGroup__Request,
    createGroup__Success,

    /* Get All Groups */
    getAllGroups__Failure,
    getAllGroups__Request,
    getAllGroups__Success,

    /* Get Group By ID */
    getGroupById__Failure,
    getGroupById__Request,
    getGroupById__Success,

    /* Update Group */
    updateGroup__Failure,
    updateGroup__Request,
    updateGroup__Success,

    /* Delete Group */
    deleteGroup__Failure,
    deleteGroup__Request,
    deleteGroup__Success,

    /* Add to Group */
    addToGroup__Failure,
    addToGroup__Request,
    addToGroup__Success,

    /* Create Deal */
    createDeal__Failure,
    createDeal__Request,
    createDeal__Success,

    /* Get All Deals */
    getAllDeals__Failure,
    getAllDeals__Request,
    getAllDeals__Success,

    /* Get Deal By ID */
    getDealById__Failure,
    getDealById__Request,
    getDealById__Success,

    /* Update Deal */
    updateDeal__Failure,
    updateDeal__Request,
    updateDeal__Success,

    /* Delete Deal */
    deleteDeal__Failure,
    deleteDeal__Request,
    deleteDeal__Success,

    /* Add Note to Deal */
    addNoteTo__Failure,
    addNoteTo__Request,
    addNoteTo__Success,

    /* get Note to Deal */
    getNoteTo__Failure,
    getNoteTo__Request,
    getNoteTo__Success,

    getAllOutlookContacts__Request,
    getAllOutlookContacts__Failure,
    getAllOutlookContacts__Success,

    /* Get Outlook Contact By ID */
    getOutlookContactById__Request,
    getOutlookContactById__Failure,
    getOutlookContactById__Success,

    /* Create Outlook Contact */
    createOutlookContact__Request,
    createOutlookContact__Failure,
    createOutlookContact__Success,

    /* Update Outlook Contact */
    updateOutlookContact__Request,
    updateOutlookContact__Failure,
    updateOutlookContact__Success,

    recallAPI__CRM: (
      state,
      { payload }: PayloadAction<reCallCRM_APIs_service>
    ) => {
      if (payload) {
        state.reCallAPIs = payload; // Update the state with the payload value
      } else {
        state.reCallAPIs = null;
      }
    },
    updateSortingKey(
      state,
      {
        payload,
      }: PayloadAction<{
        section: keyof SortingDetails;
        sortConfig: SortConfig;
      }>
    ) {
      const { section, sortConfig } = payload;
      const sortingSection = state.sortingDetails[section];
      const existingIndex = sortingSection.findIndex(
        (s) => s.field === sortConfig.field
      );

      if (existingIndex !== -1) {
        sortingSection[existingIndex].order = sortConfig.order;
      } else {
        sortingSection.push(sortConfig);
      }
    },
    updateFilterKey(
      state,
      action: PayloadAction<{
        section: keyof FilteringDetails;
        filterConfig: FilterConfig;
      }>
    ) {
      const { section, filterConfig } = action.payload;
      let filteringSection = state.filteringDetails[section];

      // If the section does not exist, initialize it
      if (!filteringSection) {
        state.filteringDetails[section] = [];
        filteringSection = state.filteringDetails[section];
      }

      const existingIndex = filteringSection.findIndex(
        (f) => f.field === filterConfig.field
      );

      // If the filter already exists, replace it; otherwise, add the new filter
      if (existingIndex !== -1) {
        filteringSection[existingIndex] = filterConfig;
      } else {
        filteringSection.push(filterConfig);
      }
    },
    deleteSortingKey(
      state,
      {
        payload,
      }: PayloadAction<{ section: keyof SortingDetails; field: string }>
    ) {
      const { section, field } = payload;
      const sortingSection = state.sortingDetails[section];
      const indexToDelete = sortingSection.findIndex((s) => s.field === field);
      if (indexToDelete !== -1) {
        sortingSection.splice(indexToDelete, 1);
      }
    },
    deleteFilterKey(
      state,
      {
        payload,
      }: PayloadAction<{ section: keyof FilteringDetails; field: string }>
    ) {
      const { section, field } = payload;
      const filteringSection = state.filteringDetails[section];
      const indexToDelete = filteringSection.findIndex(
        (f) => f.field === field
      );
      if (indexToDelete !== -1) {
        filteringSection.splice(indexToDelete, 1);
      }
    },

    linkRelatedRecords(
      state,
      {
        payload,
      }: PayloadAction<{
        contactId: string;
        companyId?: string;
        dealId?: string;
      }>
    ) {
      const contact = state.contactList.find(
        (contact: any) => contact.id === payload.contactId
      );
      if (contact) {
        if (payload.companyId) {
          contact.linkedCompanies.push(payload.companyId); // Assuming linkedCompanies is an array
        }
        if (payload.dealId) {
          contact.linkedDeals.push(payload.dealId); // Assuming linkedDeals is an array
        }
      } else {
        state.error = `Contact with ID ${payload.contactId} does not exist.`;
      }
    },
  },
});

export const {
  /* frontend crm api call manage */
  recallAPI__CRM,
  updateSortingKey,
  updateFilterKey,
  deleteSortingKey,
  deleteFilterKey,

  /* Add to Contact action */
  addContact__Request: addContact__RequestAction,
  addContact__Failure: addContact__FailureAction,
  addContact__Success: addContact__SuccessAction,

  /* Get One Contact action */
  getOneContact__Request: getOneContact__RequestAction,
  getOneContact__Failure: getOneContact__FailureAction,
  getOneContact__Success: getOneContact__SuccessAction,

  /* Update One Contact action */
  updateOneContact__Request: updateOneContact__RequestAction,
  updateOneContact__Failure: updateOneContact__FailureAction,
  updateOneContact__Success: updateOneContact__SuccessAction,

  /* Delete One Contact action */
  deleteOneContact__Request: deleteOneContact__RequestAction,
  deleteOneContact__Failure: deleteOneContact__FailureAction,
  deleteOneContact__Success: deleteOneContact__SuccessAction,

  /* Add Business action */
  addBusiness__Request: addBusiness__RequestAction,
  addBusiness__Failure: addBusiness__FailureAction,
  addBusiness__Success: addBusiness__SuccessAction,

  /* Get One Business action */
  getOneBusiness__Request: getOneBusiness__RequestAction,
  getOneBusiness__Failure: getOneBusiness__FailureAction,
  getOneBusiness__Success: getOneBusiness__SuccessAction,

  /* Update One Business action */
  updateOneBusiness__Request: updateOneBusiness__RequestAction,
  updateOneBusiness__Failure: updateOneBusiness__FailureAction,
  updateOneBusiness__Success: updateOneBusiness__SuccessAction,

  /* Delete One Business action */
  deleteOneBusiness__Request: deleteOneBusiness__RequestAction,
  deleteOneBusiness__Failure: deleteOneBusiness__FailureAction,
  deleteOneBusiness__Success: deleteOneBusiness__SuccessAction,

  /* Update Custom Elements action */
  updateCustomElements__Request: updateCustomElements__RequestAction,
  updateCustomElements__Failure: updateCustomElements__FailureAction,
  updateCustomElements__Success: updateCustomElements__SuccessAction,

  /* Get All Contacts action */
  getAllContacts__Request: getAllContacts__RequestAction,
  getAllContacts__Failure: getAllContacts__FailureAction,
  getAllContacts__Success: getAllContacts__SuccessAction,

  /* Get All Business action */
  getAllBusiness__Request: getAllBusiness__RequestAction,
  getAllBusiness__Failure: getAllBusiness__FailureAction,
  getAllBusiness__Success: getAllBusiness__SuccessAction,

  /* Create Group action */
  createGroup__Request: createGroup__RequestAction,
  createGroup__Failure: createGroup__FailureAction,
  createGroup__Success: createGroup__SuccessAction,

  /* Get All Groups action */
  getAllGroups__Request: getAllGroups__RequestAction,
  getAllGroups__Failure: getAllGroups__FailureAction,
  getAllGroups__Success: getAllGroups__SuccessAction,

  /* Get Group By ID action */
  getGroupById__Request: getGroupById__RequestAction,
  getGroupById__Failure: getGroupById__FailureAction,
  getGroupById__Success: getGroupById__SuccessAction,

  /* Update Group action */
  updateGroup__Request: updateGroup__RequestAction,
  updateGroup__Failure: updateGroup__FailureAction,
  updateGroup__Success: updateGroup__SuccessAction,

  /* Delete Group action */
  deleteGroup__Request: deleteGroup__RequestAction,
  deleteGroup__Failure: deleteGroup__FailureAction,
  deleteGroup__Success: deleteGroup__SuccessAction,

  /* Add to Group action */
  addToGroup__Request: addToGroup__RequestAction,
  addToGroup__Failure: addToGroup__FailureAction,
  addToGroup__Success: addToGroup__SuccessAction,

  /* Create Deal action */
  createDeal__Request: createDeal__RequestAction,
  createDeal__Failure: createDeal__FailureAction,
  createDeal__Success: createDeal__SuccessAction,

  /* Get All Deals action */
  getAllDeals__Request: getAllDeals__RequestAction,
  getAllDeals__Failure: getAllDeals__FailureAction,
  getAllDeals__Success: getAllDeals__SuccessAction,

  /* Get Deal By ID action */
  getDealById__Request: getDealById__RequestAction,
  getDealById__Failure: getDealById__FailureAction,
  getDealById__Success: getDealById__SuccessAction,

  /* Update Deal action */
  updateDeal__Request: updateDeal__RequestAction,
  updateDeal__Failure: updateDeal__FailureAction,
  updateDeal__Success: updateDeal__SuccessAction,

  /* Delete Deal action */
  deleteDeal__Request: deleteDeal__RequestAction,
  deleteDeal__Failure: deleteDeal__FailureAction,
  deleteDeal__Success: deleteDeal__SuccessAction,

  /* Add Note to Deal action */
  addNoteTo__Request: addNoteTo__RequestAction,
  addNoteTo__Failure: addNoteTo__FailureAction,
  addNoteTo__Success: addNoteTo__SuccessAction,

  getNoteTo__Request: getNoteTo__RequestAction,
  getNoteTo__Failure: getNoteTo__FailureAction,
  getNoteTo__Success: getNoteTo__SuccessAction,

  getAllOutlookContacts__Request: getAllOutlookContacts__RequestAction,
  getAllOutlookContacts__Failure: getAllOutlookContacts__FailureAction,
  getAllOutlookContacts__Success: getAllOutlookContacts__SuccessAction,

  /* Get Outlook Contact By ID action */
  getOutlookContactById__Request: getOutlookContactById__RequestAction,
  getOutlookContactById__Failure: getOutlookContactById__FailureAction,
  getOutlookContactById__Success: getOutlookContactById__SuccessAction,

  /* Create Outlook Contact action */
  createOutlookContact__Request: createOutlookContact__RequestAction,
  createOutlookContact__Failure: createOutlookContact__FailureAction,
  createOutlookContact__Success: createOutlookContact__SuccessAction,

  /* Update Outlook Contact action */
  updateOutlookContact__Request: updateOutlookContact__RequestAction,
  updateOutlookContact__Failure: updateOutlookContact__FailureAction,
  updateOutlookContact__Success: updateOutlookContact__SuccessAction,
} = crmCenter.actions;

export default crmCenter.reducer;
