import { PayloadAction } from "@reduxjs/toolkit";
import { CalendarState } from "../CalendarType";

// Failures
export const createCalendar__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};

// Failures
export const LoginExternalCalendar__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};

// Failures
export const AuthExternalCalendar__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};

export const subscribeToICal__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};

export const updateCalendarAppearance__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};

export const shareCalendarWithUser__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};

export const subscribeToUserCal__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};
export const getSharedWith__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};

export const updateEventDate__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};
export const updateEventTaskStatus__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};

export const getAllCalendars__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
  state.isGetAllCalendarList = false;
  state.lastApiCallTime = Date.now(); // Set timestamp when the API request is initiated
};

export const getOneCalendar__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};

export const getOneEvent__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};

export const updateCalendar__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};

export const deleteCalendar__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};

export const createCalendarSettings__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};

export const getCalendarSettings__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};

export const updateCalendarSettings__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};

export const addConnectedCalendar__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};

export const removeConnectedCalendar__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};

export const createEvent__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};

export const getAllEvents__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};

export const createTask__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};

export const getAllTasks__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};

export const createBookingLink__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};

export const getBookingLinks__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};

export const eventResponse__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};
// Failures
export const createSchedule__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};

export const getAllSchedules__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};

export const getOneSchedule__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};

export const updateSchedule__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};

export const deleteSchedule__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};

export const getAllBookingLinks__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};

export const trackVisit__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};

export const getPublicBookingLink__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};

export const getPrivateBookingLink__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};

export const getAvailableTimeSlots__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};

export const createBookingEvent__Failure = (
  state: CalendarState,
  action: PayloadAction<string>
) => {
  state.error = action.payload;
  state.data = null;
};
