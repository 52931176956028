import {
  Avatar,
  IconButton,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CopyIcon } from "../../../../../images";

interface NameViewProps {
  linkName: string;
  createdBy: any;
  onLinkEdit: (editedLink: string) => void;
}

const NameView: React.FC<NameViewProps> = ({
  createdBy,
  linkName,
  onLinkEdit,
}) => {
  const [linkValue, setLinkValue] = useState(linkName);

  useEffect(() => {
    if (linkName) {
      setLinkValue(linkName);
    }
  }, [linkName]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLinkValue(event.target.value);
  };

  const handleBlur = () => {
    onLinkEdit(linkValue);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onLinkEdit(linkValue);
      event.currentTarget.blur(); // Remove focus from textarea
    }
  };

  return (
    <Stack gap={"20px"}>
      {/* --------------  link name and copy button ------------------ */}
      <Stack gap={"10px"}>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "start",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{ font: "normal normal 300 14px/18px Source Sarif Pro" }}
          >
            Link name
          </Typography>

          <IconButton
            sx={{
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              p: 0,
              borderRadius: "0px",
            }}
            disableRipple
          >
            <CopyIcon w={15} />
            <Typography
              sx={{
                font: "normal normal 400 14px/18px Source Sarif Pro",
                color: "#000AFF",
              }}
            >
              Copy link
            </Typography>
          </IconButton>
        </Stack>

        <TextareaAutosize
          minRows={1}
          value={linkValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          style={{
            resize: "none",
            border: "0px",
            outline: "none",
            font: "normal normal 600 14px/18px Source Serif Pro",
            scrollbarWidth: "none",
          }}
        />
      </Stack>
      {/* --------------  link name and copy button end------------------ */}

      {/* --------------    Created by ------------------ */}
      <Stack gap={"10px"}>
        <Typography
          sx={{ font: "normal normal 300 14px/18px Source Sarif Pro" }}
        >
          Created by
        </Typography>

        <Stack direction="row" gap="5px" alignItems="center">
          <Avatar
            alt={`${createdBy?.firstName} ${createdBy?.lastName}`}
            src={createdBy?.avatar}
            sx={{ width: 20, height: 20 }}
          />
          <Typography
            sx={{
              font: "normal normal 300 12px/16px Source Serif Pro",
              color: "black",
            }}
          >
            {`${createdBy?.firstName} ${createdBy?.lastName}`}
          </Typography>
        </Stack>
      </Stack>
      {/* --------------  ---------------  ------------------ */}
    </Stack>
  );
};

export default NameView;
