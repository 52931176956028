import * as React from "react";
import {
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import S from "./DropdownLarge.module.css";
import { GreenDropdownIcon } from "../../../images";
import { CheckboxMedium } from "../CheckboxMedium";
import { getDepartmentList } from "../../../Utils";

interface DropdownLargeOptionType {
  readonly _id: string;
  name: string;
  isDeleted: boolean;
  createdAt?: string;
  updatedAt?: string;
  __v?: number;
}

interface DropdownLargeProps {
  label?: string;
  options: DropdownLargeOptionType[];
  componentHeader: string;
  multiple?: boolean;
  width?: string; // Add width prop
  initialSelectedOptions?: DropdownLargeOptionType[];
  onChange?: (
    selectedOptions: { _id: string; name: string; isDeleted: boolean }[]
  ) => void;
  borderColor: "black" | "lightBlue"; // Add border color
}

const DropdownLarge: React.FC<DropdownLargeProps> = ({
  label,
  options,
  componentHeader,
  multiple = false,
  width = "375px", // Default to full width
  initialSelectedOptions = [],
  onChange,
  borderColor = "0.5px solid #e9edf2",
  ...props
}) => {
  const [selectedOptions, setSelectedOptions] = React.useState<
    { _id: string; name: string; isDeleted: boolean }[]
  >([]);

  const [open, setOpen] = React.useState(false); // Manage open state
  const allDepartment = getDepartmentList();


  React.useEffect(() => {
    if (initialSelectedOptions && initialSelectedOptions?.length > 0) {
      // Check if the first item is a string or an object to determine the structure
      const isIdList = typeof initialSelectedOptions?.[0] === "string";

      const initialOptions = isIdList
        ? allDepartment
            ?.filter((department: any) =>
              initialSelectedOptions?.includes(department?._id)
            ) // Filter by _id if initialSelectedOptions contains only IDs
            ?.map((department: any) => ({
              _id: department?._id,
              name: department?.name,
              isDeleted: department?.isDeleted,
            }))
        : initialSelectedOptions?.map((option: any) => ({
            _id: option?._id,
            name: option?.name,
            isDeleted: option?.isDeleted,
          })); // Directly map if initialSelectedOptions contains full objects

      setSelectedOptions(initialOptions);
    }
  }, [initialSelectedOptions, allDepartment]);

  // console.log("selected value => ", selectedOptions);

  const handleChange = React.useCallback(
    (event: SelectChangeEvent<string[]>) => {
      const {
        target: { value },
      } = event;

      const selectedValues =
        typeof value === "string" ? value.split(",") : value;
      const newSelectedOptions = selectedValues
        .map((name) => {
          const option = options.find((option) => option.name === name);
          return option
            ? {
                _id: option._id,
                name: option.name,
                isDeleted: option.isDeleted,
              }
            : null;
        })
        .filter(Boolean) as { _id: string; name: string; isDeleted: boolean }[];

      if (
        newSelectedOptions.length !== selectedOptions.length ||
        !newSelectedOptions.every(
          (opt, index) => opt._id === selectedOptions[index]._id
        )
      ) {
        setSelectedOptions(newSelectedOptions);
        if (onChange) {
          onChange(newSelectedOptions);
        }
      }
    },
    [onChange, options, selectedOptions]
  );

  const MenuProps = React.useMemo(
    () => ({
      PaperProps: {
        className: ` ${S["dropdown-large__menu"]}  ${
          S[`dropdown-large__menu--border-${borderColor}`]
        }`,
        style: { width: width },
      },
    }),
    [borderColor, width]
  );

  return (
    <div>
      <FormControl
        size="small"
        style={{ width }} // Apply the width prop dynamically
        {...props}
      >
        {label && (
          <Typography
            sx={{
              font: "normal normal normal 10px/13px Source Serif Pro",
              color: "black",
              marginBottom: "5px",
            }}
          >
            {label}
          </Typography>
        )}
        <Select
          onClick={!open ? () => setOpen(true) : () => null}
          multiple={multiple}
          displayEmpty
          IconComponent={() => (
            <GreenDropdownIcon open={open} sx={{ marginRight: "10px" }} />
          )} // Pass open state as prop
          value={selectedOptions.map((option) => option.name)}
          open={open}
          onChange={handleChange} // Use handleChange function
          input={
            <OutlinedInput
              className={` ${S["dropdown-large__inputbox"]}  ${
                S[`dropdown-large__inputbox--border-${borderColor}`]
              }`}
              classes={{
                notchedOutline: S["dropdown-large__notchedOutline"],
                focused: S["dropdown-large__focused"],
              }}
            />
          }
          onOpen={() => setOpen(true)} // Handle open state
          onClose={() => setOpen(false)} // Handle close state
          renderValue={(selected) => {
            if (selected?.length === 0) {
              return (
                <em className={S["dropdown-large__selectPlaceholder"]}>
                  {componentHeader}
                </em>
              );
            }

            return (
              <span className={S["dropdown-large__selected"]}>
                {(selected as string[]).join(", ")}
              </span>
            );
          }}
          MenuProps={MenuProps}
        >
          {(options).map((optionItem) => (
            <MenuItem
              className={S["dropdown-large__menu-item"]}
              key={optionItem._id}
              value={optionItem.name}
            >
              <CheckboxMedium
                className={` ${S["dropdown-large__checkbox"]}`}
                checked={selectedOptions?.some(
                  (selected) => selected._id === optionItem._id
                )}
              />
              <ListItemText
                primary={optionItem.name}
                primaryTypographyProps={{
                  className: S["dropdown-large__list-item-text"],
                }}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default React.memo(DropdownLarge);
