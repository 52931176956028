/* @FabricWhiteboard.jsx */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as fabric from "fabric";
import {
  TextRichPopup,
  UndoRedoButtons,
  WhiteBoardTools,
  WhiteboardZoomControllers,
} from "../../../../../common";
import { Box, Popover, Stack, Typography } from "@mui/material";
import "./fabricJSStyle.css";
import {
  getOneWhiteboard__api,
  updateObject__api,
  uploadFile__api,
} from "../../../../../../redux";
import { useParams } from "react-router-dom";
import { SearchIcon } from "../../../../../../images";
const Whiteboard = () => {
  const dispatch = useDispatch();

  const workspaceState = useSelector((state) => state?.workspace);
  const { error, insideWorkspaceComponentData } = workspaceState || {};
  const { workspaceId, componentId, sharedType } = useParams();

  // Fetch whiteboard data when componentId changes
  // useEffect(() => {
  //   const fetchWhiteboardData = async () => {
  //     clearCanvas();
  //     const action = getOneWhiteboard__api(workspaceId, componentId);
  //     const result = await dispatch(action);
  //     if (result.success) {
  //       loadCanvasData();
  //     } else {
  //       console.error("Failed to fetch whiteboard data.");
  //     }
  //   };

  //   fetchWhiteboardData();
  // }, [dispatch, workspaceId, componentId]);
  // Function to fetch the latest workspace item from Redux

  // Clear the canvas
  const clearCanvas = () => {
    if (canvasRef.current) {
      if (
        canvasRef.current.clear &&
        typeof canvasRef.current.clear === "function"
      ) {
        canvasRef.current.clear();
        canvasRef.current.setBackgroundColor(
          "white",
          canvasRef.current.renderAll.bind(canvasRef.current)
        );
      } else {
        console.error("Canvas is not properly initialized.");
      }
    }
  };
  const canvasRef = useRef(null);

  const undoStack = useRef([]);
  const redoStack = useRef([]);

  const isDraggingRef = useRef(false);
  const isPanModeRef = useRef(false);
  const isPencilModeRef = useRef(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedObject, setSelectedObject] = useState(null);
  const [anchorElc, setAnchorElc] = useState(null);
  const [openc, setOpenc] = useState(false);
  const [popoverContent, setPopoverContent] = useState("");
  const [whiteboardData, setWhiteboardData] = useState(null);

  const saveCanvasData = async (canvasData = null) => {
    if (sharedType) {
      return;
    }
    if (canvasRef.current) {
      const serializedCanvasData = canvasRef.current.toJSON([
        "backgroundColor",
      ]);

      // Serialize canvas data to a string
      const payload = {
        items: JSON.stringify(serializedCanvasData), // Convert to string before sending
      };

      try {
        const action = updateObject__api(workspaceId, componentId, payload);

        // Await the dispatch call, but don't block rendering
        await dispatch(action);
      } catch (error) {
        console.error("Error saving canvas data:", error);
      }
    }
  };

  const handlePopoverClose = () => {
    setOpenc(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedObject(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const saveState = () => {
    if (canvasRef.current) {
      // Include backgroundColor in the JSON data
      const canvasState = JSON.stringify(
        canvasRef.current.toJSON(["backgroundColor"])
      );

      // Push the current state to the undo stack
      undoStack.current.push(canvasState);

      // Clear the redo stack whenever a new action is performed
      redoStack.current = [];
    }
  };

  // Function to log canvas data (optional, for debugging purposes)
  const logCanvasData = () => {
    // console.log("Logging canvas data:", canvasRef.current.toJSON());
    saveCanvasData();
  };

  // Function to clear the canvas
  // const clearCanvas = () => {
  //   if (canvasRef.current) {
  //     canvasRef.current.clear();
  //     canvasRef.current.setBackgroundColor(
  //       "white",
  //       canvasRef.current.renderAll.bind(canvasRef.current)
  //     );
  //   }
  // };

  // Load canvas data from API
  const loadCanvasData = async () => {
    try {
      if (sharedType) {
        return;
      }
      const action = getOneWhiteboard__api(workspaceId, componentId);
      const result = await dispatch(action);

      if (result.success && canvasRef.current) {
        setWhiteboardData(result.data);
        const serializedCanvasData = result.data.objects.items;
        if (serializedCanvasData) {
          const canvasData = JSON.parse(serializedCanvasData);
          canvasRef.current.loadFromJSON(canvasData, () => {
            // Ensure re-rendering after loading
            canvasRef.current.renderAll();
            // Force a re-render of the canvas to ensure everything is displayed immediately
            setTimeout(() => {
              canvasRef.current.renderAll();
            }, 0);
          });
        }
      } else {
        clearCanvas(); // Clear if no valid data found
      }
    } catch (error) {
      console.error("Error loading canvas data:", error);
      clearCanvas();
    }
  };

  // Initialize canvas and set up event listeners
  useEffect(() => {
    const initializeCanvas = () => {
      const canvas = new fabric.Canvas("whiteboard-canvas", {
        backgroundColor: "white",
        height: 3000,
        width: 3000,
        preserveObjectStacking: true,
      });
      canvasRef.current = canvas;

      // Handle right-click
      canvas.upperCanvasEl.addEventListener("contextmenu", (e) => {
        e.preventDefault();
        const activeObject = canvas.findTarget(e, false);
        if (activeObject) {
          setSelectedObject(activeObject);
          setAnchorEl({ mouseX: e.clientX, mouseY: e.clientY });
        } else {
          setAnchorEl(null);
          setSelectedObject(null);
        }
      });

      // Handle mouse down event for dragging
      canvas.on("mouse:down", (opt) => {
        if (isPanModeRef.current) {
          isDraggingRef.current = true;
          canvas.selection = false;
          canvas.forEachObject((obj) => {
            obj.selectable = false;
          });
          canvas.setCursor("grab");
        }
      });

      // Handle mouse move event for dragging
      canvas.on("mouse:move", (opt) => {
        if (isDraggingRef.current && isPanModeRef.current) {
          const e = opt.e;
          canvas.relativePan(new fabric.Point(e.movementX, e.movementY));
          canvas.setCursor("grabbing");
        }
      });

      // Handle mouse up event to stop dragging
      canvas.on("mouse:up", () => {
        isDraggingRef.current = false;
        canvas.setCursor(isPanModeRef.current ? "grab" : "default");
      });

      // Prevent default context menu
      document.addEventListener("contextmenu", (e) => e.preventDefault());

      // Re-enable interactions after erasing
      canvas.on("object:removed", () => {
        canvas.selection = isPanModeRef.current ? false : true;
        canvas.forEachObject((obj) => {
          obj.selectable = !isPanModeRef.current;
        });
        canvas.renderAll();
      });

      // Handle object addition
      canvas.on("object:added", logCanvasData);

      // Handle object modification
      canvas.on("object:modified", logCanvasData);

      // Listen for text changes
      canvas.on("text:changed", logCanvasData);

      // Log any changes to the background color
      canvas.on("background:color:changed", logCanvasData);

      // Handle object movement
      canvas.on("object:moved", logCanvasData);

      // Handle freehand drawing events (pencil mode)
      canvas.on("path:created", logCanvasData);

      const handleKeydown = (e) => {
        if (e.key === "Delete" || e.key === "Backspace") {
          const activeObject = canvas.getActiveObject();
          if (activeObject) {
            canvas.remove(activeObject);
            logCanvasData();
            canvas.renderAll();
          }
        }
      };
      document.addEventListener("keydown", handleKeydown);
      loadCanvasData();
    };
    // Event listener for "Delete" key to remove selected object

    // Initialize and load data when componentId changes
    initializeCanvas();

    return () => {
      if (canvasRef.current) {
        canvasRef.current.dispose();
      }
      document.removeEventListener("contextmenu", (e) => e.preventDefault());
    };
  }, [componentId]);

  // const loadCanvasData = async () => {
  //   try {
  //     console.log(insideWorkspaceComponentData, "loadCanvasData");
  //     // Check if workspaceId in insideWorkspaceItem matches the current workspaceId
  //     if (insideWorkspaceComponentData._id !== componentId) {
  //       console.log(
  //         insideWorkspaceComponentData._id,
  //         "insideWorkspaceComponentData._id",
  //         componentId,
  //         "componentId"
  //       );
  //       console.log("Component ID mismatch. Cannot load canvas data.");
  //       return;
  //     }

  //     // Access the serialized canvas data from insideWorkspaceItem
  //     const serializedCanvasData = insideWorkspaceComponentData.objects.items;

  //     if (serializedCanvasData && canvasRef.current) {
  //       // Parse the serialized data back into a JSON object
  //       const canvasData = JSON.parse(serializedCanvasData);

  //       // Load the canvas with the parsed data
  //       canvasRef.current.loadFromJSON(canvasData, () => {
  //         // Ensure re-rendering after loading
  //         canvasRef.current.renderAll();

  //         // // Optionally log the creator of each object (if stored)
  //         // canvasRef.current.getObjects().forEach((obj) => {
  //         //   console.log("Object created by:", obj.createdBy);
  //         // });
  //       });
  //     } else {
  //       console.log("No valid canvas data found.");
  //     }
  //   } catch (error) {
  //     console.error("Error loading canvas data:", error);
  //   }
  // };

  // useEffect(() => {
  //   if (
  //     insideWorkspaceComponentData &&
  //     insideWorkspaceComponentData._id &&
  //     insideWorkspaceComponentData._id === componentId
  //   ) {
  //     console.log(
  //       "Entering and loading canvas with id " +
  //         insideWorkspaceComponentData._id +
  //         " as " +
  //         componentId
  //     );
  //     loadCanvasData(); // Load the canvas data
  //   } else {
  //     console.log("No canvas data found, clearing canvas.");

  //     // If no data found, clear the canvas
  //     if (canvasRef.current) {
  //       canvasRef.current.clear(); // Remove all objects from the canvas
  //       canvasRef.current.backgroundColor = "white"; // Reset background color
  //       canvasRef.current.renderAll(); // Re-render the canvas
  //     }
  //   }
  // }, [insideWorkspaceComponentData, componentId]); // Dependencies include both insideWorkspaceItem and componentId

  // const logCanvasData = useCallback(
  //   throttle(() => {
  //     if (canvasRef.current) {
  //       const canvasData = canvasRef.current.toJSON();

  //       if (canvasData) {
  //         saveCanvasData(canvasData);
  //         canvasRef.current.renderAll();
  //       }
  //     }
  //   }, 1000), // Throttle the function to run once every second
  //   [canvasRef.current]
  // );

  const handleUndo = () => {
    if (undoStack.current.length > 1) {
      redoStack.current.push(undoStack.current.pop());
      const previousState = undoStack.current[undoStack.current.length - 1];
      canvasRef.current.loadFromJSON(previousState, () => {
        canvasRef.current.renderAll();
      });
    } else if (undoStack.current.length === 1) {
      canvasRef.current.clear();
      undoStack.current = [];
      redoStack.current = [];
    }
  };

  const handleRedo = () => {
    if (redoStack.current.length > 0) {
      const nextState = redoStack.current.pop();
      undoStack.current.push(nextState);
      canvasRef.current.loadFromJSON(nextState, () => {
        canvasRef.current.renderAll();
      });
    }
  };

  const [isPanModeActive, setIsPanModeActive] = useState(false);
  const togglePanMode = (enablePanMode) => {
    // Disable pencil mode if it's on
    if (isPencilModeRef.current) {
      isPencilModeRef.current = false;
      canvasRef.current.isDrawingMode = false;
    }

    // Set pan mode based on the argument passed
    isPanModeRef.current = enablePanMode;
    setIsPanModeActive(enablePanMode); // Update state

    canvasRef.current.setCursor(isPanModeRef.current ? "grab" : "default");

    if (isPanModeRef.current) {
      canvasRef.current.selection = false; // Disable group selection in pan mode
      canvasRef.current.forEachObject((obj) => {
        obj.selectable = false; // Make objects non-selectable
      });
    } else {
      canvasRef.current.selection = true; // Re-enable group selection
      canvasRef.current.forEachObject((obj) => {
        obj.selectable = true; // Make objects selectable again
      });
    }

    // Re-render the canvas to apply changes
    canvasRef.current.renderAll();
  };

  const addText = () => {
    turnOffPencilMode();
    const viewport = canvasRef.current.viewportTransform;
    const text = new fabric.IText("Sample Text", {
      left: 100 / viewport[0] - viewport[4] / viewport[0],
      top: 100 / viewport[3] - viewport[5] / viewport[3],
      fontFamily: "Arial",
      fontSize: 24,
    });
    canvasRef.current.add(text);
    canvasRef.current.setActiveObject(text);
    canvasRef.current.renderAll();
    saveState();
  };

  const handleImageUpload = (event) => {
    turnOffPencilMode();
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (f) => {
        const data = f.target.result;

        const imgElement = new Image();
        imgElement.src = data;

        imgElement.onload = () => {
          const viewport = canvasRef.current.viewportTransform;
          const imgInstance = new fabric.Image(imgElement, {
            left: 100 / viewport[0] - viewport[4] / viewport[0],
            top: 100 / viewport[3] - viewport[5] / viewport[3],
            scaleX: 0.5,
            scaleY: 0.5,
          });
          canvasRef.current.add(imgInstance);
          canvasRef.current.setActiveObject(imgInstance);
          canvasRef.current.renderAll();
          if (isFullScreen) {
            enterFullScreenMode();
          }
          saveState();
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const enterFullScreenMode = () => {
    try {
      const elem = document.getElementById("full-ScreenMode");
      if (!elem) {
        console.error("Element with ID 'full-ScreenMode' not found.");
        return;
      }

      // Ensure the element is focusable to prevent fullscreen errors
      if (typeof elem.requestFullscreen === "function") {
        elem.setAttribute("tabindex", "-1"); // Make the element focusable if it isn't already
      }

      const requestFullscreen =
        elem.requestFullscreen?.bind(elem) ||
        elem.mozRequestFullScreen?.bind(elem) || // Firefox
        elem.webkitRequestFullscreen?.bind(elem) || // Chrome, Safari, Opera
        elem.msRequestFullscreen?.bind(elem); // IE/Edge

      if (requestFullscreen) {
        requestFullscreen().catch((err) => {
          console.error("Error attempting to enter full screen mode:", err);
        });
      } else {
        console.error("Fullscreen mode is not supported by this browser.");
      }
    } catch (error) {
      console.error(
        "An unexpected error occurred while attempting to enter full screen mode:",
        error
      );
    }

    // Additional error handling for fullscreen errors
    document.addEventListener("fullscreenerror", (event) => {
      console.error("A fullscreen error occurred:", event);
    });

    document.addEventListener("webkitfullscreenerror", (event) => {
      console.error("A webkit fullscreen error occurred:", event);
    });

    document.addEventListener("mozfullscreenerror", (event) => {
      console.error("A moz fullscreen error occurred:", event);
    });

    document.addEventListener("MSFullscreenError", (event) => {
      console.error("A MS fullscreen error occurred:", event);
    });
  };

  const handleImageUploadServer = async (event) => {
    turnOffPencilMode();
    const file = event.target.files[0];
    if (file) {
      // Step 1: Upload the image file to the server
      try {
        const formData = new FormData();
        formData.append("files", file);

        const action = uploadFile__api(formData);
        let result = "";

        try {
          const response = await dispatch(action);
          if (response.success && response.data) {
            result = response?.data?.[0].path;
          }
        } catch (e) {
          console.log(e, "Error uploading");
          return;
        }

        const imageUrl = result; // Assuming the server returns the image URL in this field

        // Step 2: Add the uploaded image to the whiteboard
        const imgElement = new Image();
        imgElement.src = imageUrl;

        imgElement.onload = () => {
          const viewport = canvasRef.current.viewportTransform;
          const imgInstance = new fabric.Image(imgElement, {
            left: 100 / viewport[0] - viewport[4] / viewport[0],
            top: 100 / viewport[3] - viewport[5] / viewport[3],
            scaleX: 0.5,
            scaleY: 0.5,
          });
          canvasRef.current.add(imgInstance);
          canvasRef.current.setActiveObject(imgInstance);
          canvasRef.current.renderAll();
          saveState(); // Save the canvas state if necessary
        };
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const addShape = (shapeName) => {
    turnOffPencilMode();
    const viewport = canvasRef.current.viewportTransform;
    let shape;

    switch (shapeName.toLowerCase()) {
      case "square":
        shape = new fabric.Rect({
          left: 100 / viewport[0] - viewport[4] / viewport[0],
          top: 100 / viewport[3] - viewport[5] / viewport[3],
          fill: "blue",
          width: 100,
          height: 100,
        });
        break;

      case "roundedsquare":
        shape = new fabric.Rect({
          left: 100 / viewport[0] - viewport[4] / viewport[0],
          top: 100 / viewport[3] - viewport[5] / viewport[3],
          fill: "green",
          width: 100,
          height: 100,
          rx: 20, // Rounded corners
          ry: 20,
        });
        break;

      case "circle":
        shape = new fabric.Circle({
          left: 100 / viewport[0] - viewport[4] / viewport[0],
          top: 100 / viewport[3] - viewport[5] / viewport[3],
          fill: "red",
          radius: 50,
        });
        break;

      case "triangle":
        shape = new fabric.Triangle({
          left: 100 / viewport[0] - viewport[4] / viewport[0],
          top: 100 / viewport[3] - viewport[5] / viewport[3],
          fill: "yellow",
          width: 100,
          height: 100,
        });
        break;

      case "star":
        shape = new fabric.Polygon(
          [
            { x: 50, y: 0 },
            { x: 61, y: 35 },
            { x: 98, y: 35 },
            { x: 68, y: 57 },
            { x: 79, y: 91 },
            { x: 50, y: 70 },
            { x: 21, y: 91 },
            { x: 32, y: 57 },
            { x: 2, y: 35 },
            { x: 39, y: 35 },
          ],
          {
            left: 100 / viewport[0] - viewport[4] / viewport[0],
            top: 100 / viewport[3] - viewport[5] / viewport[3],
            fill: "orange",
            scaleX: 2,
            scaleY: 2,
          }
        );
        break;

      case "pageshape":
        // Create a rectangle to represent a page and add text inside it
        shape = new fabric.Rect({
          left: 100 / viewport[0] - viewport[4] / viewport[0],
          top: 100 / viewport[3] - viewport[5] / viewport[3],
          fill: "white",
          width: 200,
          height: 300,
          stroke: "black",
          strokeWidth: 2,
        });
        const text = new fabric.Textbox("Enter text here", {
          left: shape.left + 10,
          top: shape.top + 10,
          width: 180,
          fontSize: 16,
          fill: "black",
        });
        canvasRef.current.add(shape);
        canvasRef.current.add(text);
        canvasRef.current.renderAll();
        return; // Exit function after adding PageShape

      default:
        console.error("Unknown shape:", shapeName);
        return;
    }

    canvasRef.current.add(shape);
    canvasRef.current.setActiveObject(shape);
    canvasRef.current.renderAll();
    saveState();
  };

  const [isPencilModeActive, setIsPencilModeActive] = useState(false);

  const togglePencilMode = (
    wantPenMode = false,
    color = "black",
    type = "PencilBrush",
    strokeSize = 5
  ) => {
    // If dragging mode is on, turn it off before enabling pencil mode
    if (isPanModeRef.current) {
      togglePanMode(false); // Disable dragging if it's on
    }

    if (wantPenMode) {
      // Enable pencil mode with the specified settings
      isPencilModeRef.current = true;
      setIsPencilModeActive(true); // Update state to reflect pencil mode is active
      canvasRef.current.isDrawingMode = true;

      // Initialize the drawing brush based on the specified type
      if (type === "PencilBrush") {
        canvasRef.current.freeDrawingBrush = new fabric.PencilBrush(
          canvasRef.current
        );
      } else if (type === "SprayBrush") {
        canvasRef.current.freeDrawingBrush = new fabric.SprayBrush(
          canvasRef.current
        );
      } else if (type === "CircleBrush") {
        canvasRef.current.freeDrawingBrush = new fabric.CircleBrush(
          canvasRef.current
        );
      } else {
        console.warn("Unknown brush type, defaulting to PencilBrush");
        canvasRef.current.freeDrawingBrush = new fabric.PencilBrush(
          canvasRef.current
        );
      }

      // Set drawing mode properties like brush width and color
      canvasRef.current.freeDrawingBrush.color = color;
      canvasRef.current.freeDrawingBrush.width = strokeSize;
    } else {
      turnOffPencilMode();
    }

    // Re-render the canvas to apply changes
    canvasRef.current.renderAll();
    saveState();
  };

  const turnOffPencilMode = () => {
    if (isPencilModeRef.current) {
      isPencilModeRef.current = false;
      setIsPencilModeActive(false); // Update state to reflect pencil mode is inactive
      if (canvasRef.current) {
        canvasRef.current.isDrawingMode = false;
      }
    }
  };

  const addLine = (lineType) => {
    const canvas = canvasRef.current;
    const viewport = canvas.viewportTransform;
    let line, arrowHead, startArrowHead, endArrowHead, curvedArrowHead;

    switch (lineType.toLowerCase()) {
      case "straight":
        line = new fabric.Line([50, 100, 200, 100], {
          left: 100 / viewport[0] - viewport[4] / viewport[0],
          top: 100 / viewport[3] - viewport[5] / viewport[3],
          stroke: "black",
          strokeWidth: 5,
        });
        break;

      case "arrow":
        line = new fabric.Line([50, 100, 200, 100], {
          stroke: "black",
          strokeWidth: 5,
          originX: "center",
          originY: "center",
        });

        arrowHead = new fabric.Triangle({
          width: 15,
          height: 15,
          fill: "black",
          originX: "center",
          originY: "center",
          angle: 90,
        });

        const updateArrowPosition = () => {
          const { x1, y1, x2, y2 } = line;
          arrowHead.set({
            left: x2,
            top: y2,
            angle: Math.atan2(y2 - y1, x2 - x1) * (180 / Math.PI) + 90,
          });
          arrowHead.setCoords();
        };

        updateArrowPosition();
        line.on("modified", updateArrowPosition);
        line.on("moving", updateArrowPosition);
        line.on("scaling", updateArrowPosition);

        const arrowGroup = new fabric.Group([line, arrowHead], {
          selectable: true,
          originX: "center",
          originY: "center",
        });

        canvas.add(arrowGroup);
        break;

      case "doublearrow":
        line = new fabric.Line([50, 100, 200, 100], {
          stroke: "black",
          strokeWidth: 5,
          originX: "center",
          originY: "center",
        });

        startArrowHead = new fabric.Triangle({
          width: 15,
          height: 15,
          fill: "black",
          originX: "center",
          originY: "center",
        });

        endArrowHead = new fabric.Triangle({
          width: 15,
          height: 15,
          fill: "black",
          originX: "center",
          originY: "center",
        });

        const updateDoubleArrowPosition = () => {
          const { x1, y1, x2, y2 } = line;
          const lineAngle = Math.atan2(y2 - y1, x2 - x1);
          startArrowHead.set({
            left: x1,
            top: y1,
            angle: lineAngle * (180 / Math.PI) - 90,
          });
          startArrowHead.setCoords();

          endArrowHead.set({
            left: x2,
            top: y2,
            angle: lineAngle * (180 / Math.PI) + 90,
          });
          endArrowHead.setCoords();
        };

        updateDoubleArrowPosition();
        line.on("modified", updateDoubleArrowPosition);
        line.on("moving", updateDoubleArrowPosition);
        line.on("scaling", updateDoubleArrowPosition);

        canvas.add(line, startArrowHead, endArrowHead);
        break;

      case "curve":
        line = new fabric.Path("M 50 100 Q 150 0 250 100", {
          stroke: "black",
          strokeWidth: 5,
          fill: "",
          originX: "center",
          originY: "center",
        });
        break;

      case "curvedarrow":
        line = new fabric.Path("M 50 100 Q 150 0 250 100", {
          stroke: "black",
          strokeWidth: 5,
          fill: "",
          originX: "center",
          originY: "center",
        });

        curvedArrowHead = new fabric.Triangle({
          width: 15,
          height: 15,
          fill: "black",
          originX: "center",
          originY: "center",
        });

        const updateCurvedArrowPosition = () => {
          const lastPoint = line.path[line.path.length - 1];
          curvedArrowHead.set({
            left: lastPoint[1],
            top: lastPoint[2],
            angle:
              Math.atan2(
                lastPoint[2] - lastPoint[0],
                lastPoint[1] - lastPoint[0]
              ) *
                (180 / Math.PI) +
              90,
          });
          curvedArrowHead.setCoords();
        };

        updateCurvedArrowPosition();
        line.on("modified", updateCurvedArrowPosition);
        line.on("moving", updateCurvedArrowPosition);
        line.on("scaling", updateCurvedArrowPosition);

        canvas.add(line, curvedArrowHead);
        break;

      default:
        console.error("Unknown line type:", lineType);
        return;
    }

    if (line) {
      canvas.add(line);
      canvas.setActiveObject(line);
      canvas.renderAll();
    }
  };
  const [isEraserModeActive, setIsEraserModeActive] = useState(false);
  const isEraserModeActiveRef = useRef(isEraserModeActive);

  // Sync the ref with the state
  useEffect(() => {
    isEraserModeActiveRef.current = isEraserModeActive;
  }, [isEraserModeActive]);

  const handleErase = (opt) => {
    const canvas = canvasRef.current;

    // Use ref to check the eraser mode status
    if (!isEraserModeActiveRef.current) {
      return;
    }

    const pointer = canvas.getPointer(opt.e);
    const activeObject = canvas.findTarget(opt.e);

    if (activeObject) {
      canvas.remove(activeObject); // Remove the object
    } else {
      const eraser = new fabric.Rect({
        left: pointer.x,
        top: pointer.y,
        width: 5,
        height: 5,
        fill: canvas.backgroundColor || "#ffffff",
        selectable: false,
        evented: false,
      });

      canvas.add(eraser);
      canvas.remove(eraser); // Immediately remove the eraser to create a "hole"
    }

    canvas.renderAll();
  };

  const toggleEraserMode = (wantEraserMode = false, strokeSize = 10) => {
    const canvas = canvasRef.current;

    if (isPanModeRef.current) {
      console.warn("Disable dragging before using the eraser.");
      return;
    }

    if (wantEraserMode) {
      setIsEraserModeActive(true);
      canvas.on("mouse:down", handleErase);
    } else {
      setIsEraserModeActive(false);
      canvas.off("mouse:down", handleErase);
    }
  };
  const changeFontFamily = (fontFamily) => {
    const activeObject = canvasRef.current.getActiveObject();

    if (
      activeObject &&
      (activeObject.type === "textbox" || activeObject.type === "i-text")
    ) {
      if (
        [
          "Source Service Pro",
          "Arial",
          "Courier New",
          "Georgia",
          "Times New Roman",
          "Verdana",
          "Roboto",
          "Helvetica",
          "Tahoma",
          "Trebuchet MS",
          "Comic Sans MS",
        ].includes(fontFamily)
      ) {
        activeObject.set({ fontFamily });
        canvasRef.current.renderAll();
        saveState();
      } else {
        console.warn("Invalid font family provided.");
      }
    } else {
      console.warn(
        "No text object selected or the selected object is not a text object."
      );
    }
  };

  const changeWhiteboardBgColor = (color) => {
    const colorMap = {
      White: "#ffffff",
      PartiallyPaid: "#FFEE58",
      LightGreen: "#A1FF9F",
      LightOrange: "#FFDCB2",
      CallsSalmon: "#FF8181",
      LightPurple: "#CFA0FF",
      LightPink: "#FFC6F2",
      LightTeal: "#CEF8FA",
    };

    const resolvedColor = colorMap[color] || color;

    if (canvasRef.current) {
      canvasRef.current.set("backgroundColor", resolvedColor);
      canvasRef.current.renderAll();
      saveState(); // Save the canvas state if necessary
    } else {
      console.warn("Canvas not initialized or unsupported");
    }
  };

  const changeShapeOutlineColor = (color) => {
    const canvas = canvasRef.current;
    const activeObject = canvas.getActiveObject();

    if (!activeObject) {
      console.warn("No object selected.");
      return;
    }

    if (
      activeObject.type === "rect" ||
      activeObject.type === "circle" ||
      activeObject.type === "triangle" ||
      activeObject.type === "polygon" ||
      activeObject.type === "star" ||
      activeObject.type === "line"
    ) {
      // Change shape outline (stroke) color
      activeObject.set("stroke", color);
      activeObject.set("strokeWidth", activeObject.strokeWidth || 2);
    } else if (
      activeObject.type === "textbox" ||
      activeObject.type === "i-text"
    ) {
      console.warn("Text objects do not have a stroke property for outlining.");
    } else if (activeObject.type === "image") {
      console.warn("Cannot change outline color of an image.");
      // Optionally, apply a filter or create an outline using another approach.
    } else {
      console.warn(
        "Unsupported object type for outline color change.",
        activeObject.type
      );
    }

    canvas.renderAll(); // Re-render the canvas to apply changes
  };

  const changeObjectFillColor = (color) => {
    const canvas = canvasRef.current;
    const activeObject = canvas.getActiveObject();

    if (!activeObject) {
      console.warn("No object selected.");
      return;
    }

    if (activeObject.type === "textbox" || activeObject.type === "i-text") {
      // Change text color
      activeObject.set("fill", color);
    } else if (
      activeObject.type === "rect" ||
      activeObject.type === "circle" ||
      activeObject.type === "triangle" ||
      activeObject.type === "star" ||
      activeObject.type === "line" ||
      activeObject.type === "polygon"
    ) {
      changeShapeOutlineColor(color);
    } else if (activeObject.type === "image") {
      console.warn("Cannot change color of an image directly.");
      // Optionally, apply a filter to change the color overlay
    } else {
      console.warn("Unsupported object type for color change.");
    }
    saveState();
    canvas.renderAll(); // Re-render the canvas to apply changes
  };

  const changeFontSize = (fontSize) => {
    const activeObject = canvasRef.current.getActiveObject();
    if (
      activeObject &&
      (activeObject.type === "textbox" || activeObject.type === "i-text")
    ) {
      activeObject.set({ fontSize: fontSize });
      canvasRef.current.renderAll();
      saveState();
    }
  };
  const changeFontWeight = (fontWeight) => {
    const activeObject = canvasRef.current.getActiveObject();
    if (activeObject && activeObject.type === "textbox") {
      activeObject.set({ fontWeight: fontWeight });
      canvasRef.current.renderAll();
      saveState();
    }
  };
  const changeObjectBgColor = (bgColor) => {
    const canvas = canvasRef.current;
    const activeObject = canvas.getActiveObject();

    if (!activeObject) {
      console.warn("No object selected.");
      return;
    }

    if (activeObject.type === "textbox" || activeObject.type === "i-text") {
      // Change text background color
      activeObject.set("backgroundColor", bgColor);
    } else if (
      activeObject.type === "rect" ||
      activeObject.type === "circle" ||
      activeObject.type === "triangle" ||
      activeObject.type === "star" ||
      activeObject.type === "polygon"
    ) {
      // For shapes, the background color might be the fill color (if you're referring to the object fill)
      activeObject.set("fill", bgColor);
    } else {
      console.warn("Unsupported object type for background color change.");
    }

    canvas.renderAll(); // Re-render the canvas to apply changes
  };
  const toggleBold = () => {
    const activeObject = canvasRef.current.getActiveObject();
    if (
      activeObject &&
      (activeObject.type === "textbox" || activeObject.type === "i-text")
    ) {
      const isBold = activeObject.fontWeight === "bold";
      activeObject.set({ fontWeight: isBold ? "normal" : "bold" });
      canvasRef.current.renderAll();
      saveState();
    }
  };
  const toggleItalic = () => {
    const activeObject = canvasRef.current.getActiveObject();
    if (
      activeObject &&
      (activeObject.type === "textbox" || activeObject.type === "i-text")
    ) {
      const isItalic = activeObject.fontStyle === "italic";
      activeObject.set({ fontStyle: isItalic ? "normal" : "italic" });
      canvasRef.current.renderAll();
      saveState();
    }
  };
  const toggleUnderline = () => {
    const activeObject = canvasRef.current.getActiveObject();
    if (
      activeObject &&
      (activeObject.type === "textbox" || activeObject.type === "i-text")
    ) {
      const isUnderline = activeObject.underline;
      activeObject.set({ underline: !isUnderline });
      canvasRef.current.renderAll();
      saveState();
    }
  };
  const toggleStrikethrough = () => {
    const activeObject = canvasRef.current.getActiveObject();
    if (
      activeObject &&
      (activeObject.type === "textbox" || activeObject.type === "i-text")
    ) {
      const isStrikethrough = activeObject.linethrough;
      activeObject.set({ linethrough: !isStrikethrough });
      canvasRef.current.renderAll();
      saveState();
    }
  };

  const applySubscriptSuperscript = (formatType) => {
    const canvas = canvasRef.current;
    const activeObject = canvas.getActiveObject();

    if (
      !activeObject ||
      (activeObject.type !== "i-text" && activeObject.type !== "textbox")
    ) {
      console.warn("No text object selected.");
      return;
    }

    const selectedTextStart = activeObject.selectionStart;
    const selectedTextEnd = activeObject.selectionEnd;

    if (selectedTextStart === selectedTextEnd) {
      console.warn("No text selected.");
      return;
    }

    const currentStyle = activeObject.getSelectionStyles(
      selectedTextStart,
      selectedTextEnd
    )[0];

    if (formatType === "superscript") {
      activeObject.setSelectionStyles(
        {
          fontSize: currentStyle.fontSize
            ? currentStyle.fontSize * 0.75
            : activeObject.fontSize * 0.75,
          deltaY: -activeObject.fontSize * 0.25, // Move the text up
        },
        selectedTextStart,
        selectedTextEnd
      );
    } else if (formatType === "subscript") {
      activeObject.setSelectionStyles(
        {
          fontSize: currentStyle.fontSize
            ? currentStyle.fontSize * 0.75
            : activeObject.fontSize * 0.75,
          deltaY: activeObject.fontSize * 0.25, // Move the text down
        },
        selectedTextStart,
        selectedTextEnd
      );
    } else {
      console.warn("Unknown format type.");
    }

    canvas.renderAll(); // Re-render the canvas to apply changes
  };

  const changeTextAlignment = (alignment) => {
    const activeObject = canvasRef.current.getActiveObject();
    if (
      activeObject &&
      (activeObject.type === "textbox" || activeObject.type === "i-text")
    ) {
      let textAlignValue;

      switch (alignment) {
        case "LeftAlignment":
          textAlignValue = "left";
          break;
        case "Justify":
          textAlignValue = "justify";
          break;
        case "Center":
          textAlignValue = "center";
          break;
        case "RightAlignment":
          textAlignValue = "right";
          break;
        default:
          console.warn("Unknown alignment type:", alignment);
          return;
      }

      activeObject.set({ textAlign: textAlignValue });
      canvasRef.current.renderAll();
      saveState(); // Save the current state if necessary
    } else {
      console.warn("No text object selected or invalid object type.");
    }
  };

  const applyTextFormat = (formatType) => {
    const activeObject = canvasRef.current.getActiveObject();
    if (
      activeObject &&
      (activeObject.type === "textbox" || activeObject.type === "i-text")
    ) {
      let formattedText = activeObject.text
        .split("\n")
        .map((line, index) => {
          // Remove existing bullet points or numbers
          line = line.replace(/^•\s/, "").replace(/^\d+\.\s/, "");

          if (formatType === "BulletPoint") {
            return `• ${line?.trim()}`;
          } else if (formatType === "Numbered") {
            return `${index + 1}. ${line?.trim()}`;
          }
          return line;
        })
        .join("\n");

      activeObject.set({ text: formattedText });
      canvasRef.current.renderAll();
      saveState();
    }
  };

  const addLinkedText = (url, highlightText = null) => {
    const canvas = canvasRef.current;
    const activeObject = canvas.getActiveObject();

    if (
      activeObject &&
      (activeObject.type === "textbox" || activeObject.type === "i-text")
    ) {
      const text = activeObject.text;
      let selectedText = highlightText;

      // If no specific text is highlighted, link the entire text
      if (!highlightText) {
        selectedText = text;
      }

      // Find the starting index of the highlighted text
      const startIndex = text?.indexOf(selectedText);

      // If the text is found, we proceed with adding the link
      if (startIndex !== -1) {
        // Create a new text object with linked text
        const beforeText = text.substring(0, startIndex);
        const afterText = text.substring(startIndex + selectedText.length);

        const linkedText = new fabric.Textbox(
          beforeText + selectedText + afterText,
          {
            left: activeObject.left,
            top: activeObject.top,
            fontSize: activeObject.fontSize,
            fill: activeObject.fill,
            fontWeight: activeObject.fontWeight,
            underline: true,
            selectable: true,
            evented: true,
          }
        );

        // Replace the original object with the new linked text
        canvas.remove(activeObject);
        canvas.add(linkedText);
        canvas.setActiveObject(linkedText);

        canvas.renderAll();

        // Attach the click event to the specific portion of text
        linkedText.on("mousedown", (e) => {
          const pointer = canvas.getPointer(e.e);
          const index = linkedText.getSelectionStartFromPointer(pointer);

          // If the click was on the linked text
          if (
            index >= startIndex &&
            index <= startIndex + selectedText.length
          ) {
            window.open(url, "_blank");
          }
        });
      }
    } else {
      console.warn("No text object selected or no highlighted text provided.");
    }
  };

  /* addLinkedText('Open Google', 'https://www.google.com', { fontSize: 24, left: 150, top: 200 });
   */

  const addCommentToObject = (commentText = "Hello world") => {
    const canvas = canvasRef.current;
    const object = canvas.getActiveObject();

    if (!object) {
      console.warn("No object selected for commenting.");
      return;
    }

    const commentIcon = new fabric.Circle({
      radius: 5,
      fill: "red",
      left: object.left + object.width / 2,
      top: object.top - 10,
      originX: "center",
      originY: "center",
      selectable: false,
      evented: true,
    });

    object.comment = { text: commentText, icon: commentIcon };
    canvas.add(commentIcon);

    const showComment = () => {
      const canvasElement = canvasRef.current.lowerCanvasEl; // Get the actual HTML canvas element
      const rect = canvasElement.getBoundingClientRect(); // Get canvas position relative to viewport

      setPopoverContent(commentText);
      setAnchorElc({
        mouseX: rect.left + commentIcon.left * canvasRef.current.getZoom(),
        mouseY: rect.top + commentIcon.top * canvasRef.current.getZoom(),
      });
      setOpenc(true);
    };

    const hideComment = () => {
      setOpenc(false);
    };

    object.on("selected", (e) => {
      showComment(e);
    });

    object.on("deselected", hideComment);

    object.on("modified", () => {
      commentIcon.set({
        left: object.left + object.width / 2,
        top: object.top - 10,
      });
      canvas.renderAll();
    });

    canvas.renderAll();
  };

  const zoomWhiteboard = (zoomValue) => {
    const canvas = canvasRef.current;
    if (canvas) {
      const zoom = zoomValue / 100; // Convert percentage to scale

      // Get the current transformation matrix
      const transform = canvas?.viewportTransform?.slice();

      if (!transform) {
        return;
      }

      // Apply the new zoom scale
      transform[0] = zoom;
      transform[3] = zoom;

      // Update the transformation matrix
      canvas?.setViewportTransform(transform);

      // Optionally, adjust canvas width/height if you want to resize it
      canvas.setWidth(3000 * zoom);
      canvas.setHeight(3000 * zoom);

      canvas.renderAll(); // Re-render the canvas to apply changes
    }
  };

  const applyWhiteboardGrid = (gridType) => {
    const canvas = canvasRef.current;
    const gridSize = 50; // Major grid line spacing
    const minorGridSize = 10; // Minor grid line spacing (optional)

    // Clear any existing grid
    if (canvas.gridGroup) {
      canvas.remove(canvas.gridGroup);
    }

    let gridGroup;

    if (gridType === "lineGrid") {
      gridGroup = createLineGrid(canvas, gridSize, minorGridSize);
    } else if (gridType === "dotGrid") {
      gridGroup = createDotGrid(canvas, gridSize);
    } else {
      return; // If no grid, just return
    }

    // Add the grid to the canvas and set it as the gridGroup property
    canvas.add(gridGroup);
    canvas.gridGroup = gridGroup;
    canvas.gridGroup.selectable = false; // Prevent selection of grid lines/dots
    canvas.gridGroup.evented = false; // Disable events for the grid

    // Ensure the grid stays in place when panning or zooming
    canvas.on("before:render", () => {
      if (canvas.gridGroup) {
        canvas.gridGroup.set({
          left: -canvas.viewportTransform[4],
          top: -canvas.viewportTransform[5],
        });
        canvas.gridGroup.setCoords();
      }
    });

    // Render the canvas
    canvas.renderAll();
  };

  const createLineGrid = (canvas, gridSize, minorGridSize) => {
    const lines = [];
    const width = canvas.getWidth();
    const height = canvas.getHeight();

    // Minor grid lines
    for (let i = -width; i < width * 2; i += minorGridSize) {
      const minorVerticalLine = new fabric.Line([i, -height, i, height * 2], {
        stroke: "#eee",
        selectable: false,
        evented: false,
      });
      const minorHorizontalLine = new fabric.Line([-width, i, width * 2, i], {
        stroke: "#eee",
        selectable: false,
        evented: false,
      });
      lines.push(minorVerticalLine, minorHorizontalLine);
    }

    // Major grid lines
    for (let i = -width; i < width * 2; i += gridSize) {
      const majorVerticalLine = new fabric.Line([i, -height, i, height * 2], {
        stroke: "#ccc",
        selectable: false,
        evented: false,
      });
      const majorHorizontalLine = new fabric.Line([-width, i, width * 2, i], {
        stroke: "#ccc",
        selectable: false,
        evented: false,
      });
      lines.push(majorVerticalLine, majorHorizontalLine);
    }

    return new fabric.Group(lines, {
      selectable: false,
      evented: false,
      excludeFromExport: true, // Avoid exporting the grid in JSON or image exports
    });
  };

  const createDotGrid = (canvas, gridSize) => {
    const dots = [];
    const width = canvas.getWidth();
    const height = canvas.getHeight();

    for (let i = -width; i < width * 2; i += gridSize) {
      for (let j = -height; j < height * 2; j += gridSize) {
        const dot = new fabric.Circle({
          left: i,
          top: j,
          radius: 2,
          fill: "#ccc",
          selectable: false,
          evented: false,
        });
        dots.push(dot);
      }
    }

    return new fabric.Group(dots, {
      selectable: false,
      evented: false,
      excludeFromExport: true, // Avoid exporting the grid in JSON or image exports
    });
  };
  const whiteboardRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };
  const toggleWhiteboardFullScreen = () => {
    const fullScreenElement = document.getElementById("full-ScreenMode");

    if (!document.fullscreenElement) {
      // Request full-screen mode for the element
      fullScreenElement.requestFullscreen().catch((err) => {
        console.error(
          `Error attempting to enable full-screen mode: ${err.message}`
        );
      });

      // Adjust whiteboard height to full screen
      fullScreenElement.style.backgroundColor = "#fff"; // Set background color
      document.body.classList.add("full-screen-mode");
      toggleFullScreen();
    } else {
      // Exit full-screen mode
      document.exitFullscreen().catch((err) => {
        console.error(
          `Error attempting to exit full-screen mode: ${err.message}`
        );
      });
      toggleFullScreen();
      document.body.classList.remove("full-screen-mode");
    }
  };

  return (
    <Box>
      <div id="toolbar">
        {/* @controller : this below controller will be used when we right click on
        text boxes, shapes and for shape filling */}

        {/* <button onClick={addText}>Add Text</button> */}
        {/* <input type="file" accept="image/*" onChange={handleImageUpload} /> */}
        {/* <button onClick={togglePanMode}>
          {isPanModeRef.current ? "Disable Dragging" : "Enable Dragging"}
        </button> */}
        {/* <button onClick={() => togglePencilMode("red", "PencilBrush", 10)}>
          Enable Red Pencil
        </button> */}
        {/* <button onClick={() => togglePencilMode("blue", "SprayBrush", 20)}>
          Enable Blue Spray Brush
        </button>
        <button onClick={() => togglePencilMode("green", "CircleBrush", 15)}>
          Enable Green Circle Brush
        </button> */}
        {/* <button onClick={() => addShape("square")}>add square</button>
        <button onClick={() => addShape("circle")}>add circle</button>
        <button onClick={() => addShape("triangle")}>add triangle</button>
        <button onClick={() => addShape("roundedsquare")}>
          add round square
        </button>
        <button onClick={() => addShape("star")}>add star</button> */}

        {/* <button onClick={() => addLine("straight")}>add straight line</button>
        <button onClick={() => addLine("arrow")}>add arrow line</button>
        <button onClick={() => addLine("doublearrow")}>
          add doublearrow line
        </button>
        <button onClick={() => addLine("curve")}>add curve line</button>
        <button onClick={() => addLine("curvedarrow")}>
          add curvedarrow line
        </button>
        <button onClick={() => toggleEraserMode(20)}>Eraser</button> */}
      </div>
      <Stack
        id="full-ScreenMode"
        ref={whiteboardRef}
        width={"100%"}
        height={"100vh"}
        direction={"column"}
        gap={1}
        alignItems={"center"}
      >
        <Stack
          width={"100%"}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            gap={2}
          >
            <Typography
              sx={{
                font: "Source Serif Pro",
                fontWeight: "900",
                fontSize: "25px",
                lineHeight: "31.33px",
                color: "#000000",
              }}
            >
              {whiteboardData?.title}
            </Typography>
            <SearchIcon />
          </Stack>
          <Stack
            width={"100%"}
            direction={"row"}
            alignItems={"flex-end"}
            justifyContent={"flex-end"}
            mr={"50px"}
          >
            <WhiteboardZoomControllers
              changeWhiteboardBgColor={changeWhiteboardBgColor}
              zoomWhiteboard={zoomWhiteboard}
              applyWhiteboardGrid={applyWhiteboardGrid}
              toggleWhiteboardFullScreen={toggleWhiteboardFullScreen}
            />
          </Stack>
        </Stack>
        <Stack
          width={"100%"}
          direction={"row"}
          alignItems={"flex-start"}
          gap={1}
          justifyContent={"flex-start"}
        >
          <Stack direction={"column"} gap={1} alignItems={"center"}>
            <WhiteBoardTools
              addText={addText}
              handleImageUpload={handleImageUploadServer}
              addShape={addShape}
              togglePencilMode={togglePencilMode}
              toggleEraserMode={toggleEraserMode}
              togglePanMode={togglePanMode}
              addLine={addLine}
              isPanModeActive={isPanModeActive}
              isPencilModeActive={isPencilModeActive}
              isEraserModeActive={isEraserModeActive}
            />
            <UndoRedoButtons handleUndo={handleUndo} handleRedo={handleRedo} />
          </Stack>
          <div
            style={{
              width: "95%",
              height: isFullScreen ? "calc(95vh - 20px)" : "600px",
              overflow: "hidden",
              borderRadius: "5px",
            }}
          >
            <canvas id="whiteboard-canvas" ref={canvasRef}></canvas>
          </div>
        </Stack>

        <Popover
          id={id}
          open={open}
          anchorReference="anchorPosition"
          anchorPosition={
            anchorEl
              ? { top: anchorEl.mouseY, left: anchorEl.mouseX }
              : undefined
          }
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          container={document.getElementById("full-ScreenMode")}
        >
          <TextRichPopup
            changeFontSize={changeFontSize}
            changeObjectFillColor={changeObjectFillColor}
            changeObjectBgColor={changeObjectBgColor}
            toggleBold={toggleBold}
            toggleItalic={toggleItalic}
            toggleUnderline={toggleUnderline}
            toggleStrikethrough={toggleStrikethrough}
            changeTextAlignment={changeTextAlignment}
            applyTextFormat={applyTextFormat}
            applySubscriptSuperscript={applySubscriptSuperscript}
            addLinkedText={addLinkedText}
            addCommentToObject={addCommentToObject}
            changeFontFamily={changeFontFamily}
            selectedObject={selectedObject}
          />
        </Popover>

        <Popover
          open={openc}
          anchorReference="anchorPosition"
          anchorPosition={
            anchorElc
              ? { top: anchorElc.mouseY, left: anchorElc.mouseX }
              : undefined
          }
          onClose={handlePopoverClose}
          container={document.getElementById("full-ScreenMode")}
        >
          <Typography sx={{ p: 2 }}>{popoverContent}</Typography>
        </Popover>
      </Stack>
    </Box>
  );
};

export default Whiteboard;
