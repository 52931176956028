import React, { useState, useEffect, useRef } from "react";
import { InputBase, Stack, Tooltip } from "@mui/material";
import { BorderLinearProgress } from "../../../../../../../../common";

interface ProgressManualViewProps {
  initialValue: number;
  onChange: (value: number) => void;
}

const ProgressManualView: React.FC<ProgressManualViewProps> = ({
  initialValue = 0,
  onChange,
}) => {
  const [progress, setProgress] = useState<number>(initialValue);
  const [inputValue, setInputValue] = useState<string>(`${initialValue}`);
  const [error, setError] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setProgress(initialValue);
    setInputValue(`${initialValue}`);
  }, [initialValue]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.replace(/[^0-9]/g, "");

    // Remove leading zeros
    value = value.replace(/^0+/, "") || "0";

    const numericValue = parseInt(value, 10);

    if (isNaN(numericValue) || numericValue < 0 || numericValue > 100) {
      setError(true);
    } else {
      setError(false);
      setProgress(numericValue);
      if (onChange) {
        onChange(numericValue);
      }
    }
    setInputValue(value);
  };

  const handleBlur = () => {
    const numericValue = parseInt(inputValue, 10);

    if (isNaN(numericValue) || numericValue < 0 || numericValue > 100) {
      setProgress(0);
      setInputValue("0");
      setError(false);
      if (onChange) {
        onChange(0);
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault(); // Prevent the default behavior of the arrow keys

      let newValue = parseInt(inputValue, 10) || 0;

      if (event.key === "ArrowUp" && newValue < 100) {
        newValue += 1;
      } else if (event.key === "ArrowDown" && newValue > 0) {
        newValue -= 1;
      }

      setProgress(newValue);
      setInputValue(newValue?.toString());
      if (onChange) {
        onChange(newValue);
      }
    } else if (event.key === "Enter") {
      handleBlur(); // Call handleBlur to save the value when Enter is pressed
      if (inputRef.current) {
        inputRef.current.blur(); // Unfocus the input
      }
    }
  };

  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "30px" }}>
      <Stack sx={{ width: "80px", height: "5px" }}>
        <BorderLinearProgress progressPercent={progress} />
      </Stack>
      <Stack direction={"row"} gap={"2px"}>
        <Tooltip
          title="Percentage must be 1-100"
          placement="top"
          open={error}
          arrow
        >
          <InputBase
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            inputRef={inputRef}
            inputProps={{
              style: {
                textAlign: "end",
              },
            }}
            sx={{
              font: "normal normal 300 14px/20px Source Serif Pro",
              width: "min-content",
              minWidth: "23px",
            }}
          />
        </Tooltip>
        <InputBase
          value={"%"}
          readOnly
          sx={{
            font: "normal normal 300 14px/20px Source Serif Pro",
            width: "15px",
          }}
        />
      </Stack>
    </Stack>
  );
};

export default ProgressManualView;
