import React, { useState } from "react";
import { DragAndDropMenu, DragAndDropMenuListType } from "../../../../common";
import {
  AddressLocationIcon,
  AngelListIcon,
  BirthdayCakeOutlineIcon,
  EmploymentTypeIcon,
  BuildingIcon,
  CallIcon,
  DataIDnumberIcon,
  DateIcon,
  DepartmentIcon,
  EmailIcon,
  EmployeeCountIcon,
  FacebookIcon,
  FullNameIcon,
  GenderIcon,
  InstagramIcon,
  JobTitleIcon,
  LastCalendarIcon,
  LinkedinIcon,
  LocationIcon,
  NamebadgeIcon,
  RelationshipStrengthIcon,
  TotalhoursIcon,
  XIcon,
} from "../../../../../images";

interface AddColumnEmployeeDirectoryProps {
  options?: DragAndDropMenuListType[];
}

const AddColumnEmployeeDirectoryList: DragAndDropMenuListType[] = [
  { id: 1, icon: DataIDnumberIcon, label: "Full name", draggable: true },
  { id: 2, icon: NamebadgeIcon, label: "Nickname", draggable: true },
  {
    id: 3,
    icon: BirthdayCakeOutlineIcon,
    label: "Date of Birth",
    draggable: true,
  },
  { id: 4, icon: GenderIcon, label: "Gender", draggable: true },
  { id: 5, icon: FullNameIcon, label: "Profile Picture", draggable: true },
  { id: 6, icon: EmailIcon, label: "Personal Email", draggable: true },
  {
    id: 7,
    icon: CallIcon,
    label: "Phone Number Mobile",
    draggable: true,
  },
  {
    id: 8,
    icon: CallIcon,
    label: "Phone Number Home",
    draggable: true,
  },
  { id: 9, icon: AddressLocationIcon, label: "Home Address", draggable: true },
  { id: 10, icon: AngelListIcon, label: "AngelList", draggable: true },
  { id: 11, icon: FacebookIcon, label: "Facebook", draggable: true },
  { id: 12, icon: InstagramIcon, label: "Instagram", draggable: true },
  { id: 13, icon: XIcon, label: "X", draggable: true },
  { id: 14, icon: LinkedinIcon, label: "LinkedIn", draggable: true },
  { id: 15, icon: JobTitleIcon, label: "Employee ID", draggable: true },
  { id: 16, icon: EmployeeCountIcon, label: "Job Title", draggable: true },
  { id: 17, icon: DepartmentIcon, label: "Department", draggable: true },
  { id: 18, icon: DepartmentIcon, label: "Manager", draggable: true },
  { id: 19, icon: DepartmentIcon, label: "Team Lead", draggable: true },
  { id: 20, icon: DepartmentIcon, label: "Team name", draggable: true },
  {
    id: 21,
    icon: EmploymentTypeIcon,
    label: "Employment Type",
    draggable: true,
  },
  { id: 22, icon: DateIcon, label: "Start Date", draggable: true },
  { id: 23, icon: LastCalendarIcon, label: "End Date", draggable: true },
  {
    id: 24,
    icon: RelationshipStrengthIcon,
    label: "Employment Status",
    draggable: true,
  },
  {
    id: 25,
    icon: LocationIcon,
    label: "Location Country",
    draggable: true,
  },
  { id: 26, icon: BuildingIcon, label: "Location City", draggable: true },
  {
    id: 27,
    icon: TotalhoursIcon,
    label: "Location Timezone",
    draggable: true,
  },
  {
    id: 28,
    icon: CallIcon,
    label: "Office Phone number",
    draggable: true,
  },
  { id: 29, icon: EmailIcon, label: "Employee Email", draggable: true },
];

const AddColumnEmployeeDirectory: React.FC<AddColumnEmployeeDirectoryProps> = ({
  options = AddColumnEmployeeDirectoryList,
}) => {
  const [menuOpt, setMenuOpt] = useState<DragAndDropMenuListType[]>(options);
  return (
    <DragAndDropMenu
      menuItems={menuOpt}
      setMenuItems={(newValue) => setMenuOpt(newValue)}
      label="Add new columns"
      onMenuSelect={(opt) => console.log(opt)}
      dragIconPlace="left"
      menuWidth="250px"
      maxHeight="400px"
      backgroundColor="white"
    />
  );
};

export default AddColumnEmployeeDirectory;
