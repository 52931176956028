import { People as PeoplePage } from "./People";
import { Company as CompanyPage } from "./Company";
import { Deal as DealPage } from "./Deal";

// Define paths for CRM components
export type CrmPaths = "/CRM/people" | "/CRM/company" | "/CRM/deal";

// Map CRM paths to their respective components
export const CrmComponentMap: Record<CrmPaths, React.ComponentType> = {
    "/CRM/people": PeoplePage,
    "/CRM/company": CompanyPage,
    "/CRM/deal": DealPage,
};

// Function to get the component by path
export const getCrmComponent = (path: CrmPaths): React.ComponentType => {
    return CrmComponentMap[path];
};
