import { RadioGroup, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import {
  ChipInputField,
  CustomRadioFormControlLabel,
} from "../../../../../../common";

interface BodyKeywordViewProps {
  updateFilterData: (key: "name" | "from" | "bodyMatchType" | "subjectKeywords" | "bodyKeywords" | "hosts" | "basicFilters" | "priorityLevel" | "advancedActions", value: any) => void;
  bodyKeydData: string[] | any;
  bodyMatchType: string | any;
}

const BodyKeywordView: React.FC<BodyKeywordViewProps> = ({ updateFilterData, bodyKeydData, bodyMatchType }) => {
  const [bodyKeyword, setBodyKeyword] = React.useState<string>("EXACT_MATCH");


  const handleBodyRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;

    if (value !== bodyKeyword) {
      setBodyKeyword(value);
      updateFilterData("bodyMatchType", value);
    }
  };

  const handleDropdownChange = (e: string[]) => {
    const selectedValues = e?.map(option => option);
    updateFilterData("bodyKeywords", selectedValues);
  };


  useEffect(() => {
    if (bodyMatchType && bodyMatchType !== bodyKeyword) {
      setBodyKeyword(bodyMatchType);
    }
  }, [bodyMatchType]);

  return (
    <Stack sx={{ gap: "15px", marginTop: "10px" }}>
      <Stack
        sx={{
          flexDirection: "row",
          gap: "10px",
          alignItems: "start",
          borderBottom: "0.5px solid #E9EDF2",
        }}
      >
        <Typography
          sx={{
            font: "normal normal 300 14px/18px Source Serif Pro",
            whiteSpace: "nowrap",
            flexShrink: 0,
            paddingTop: "10px",
          }}
        >
          Body keywords
        </Typography>
        <ChipInputField onChange={handleDropdownChange} initialChips={bodyKeydData} />
      </Stack>

      <RadioGroup
        aria-labelledby="bodyKeyword-radio-buttons-group"
        name="bodyKeyword-radio-buttons-group"
        value={bodyKeyword}
        onChange={handleBodyRadioChange}
        sx={{ p: 0, marginTop: "5px", gap: "13px" }}
      >
        <CustomRadioFormControlLabel value="EXACT_MATCH" label="Exact match" />
        <CustomRadioFormControlLabel value="CONTAINS" label="Contains" />
        <CustomRadioFormControlLabel value="DOES_NOT_CONTAIN" label="Does not contain" />
      </RadioGroup>
    </Stack>
  );
};

export default BodyKeywordView;
