import {IconButton, Stack, Tooltip, Typography} from "@mui/material";
import React from "react";
import {CheckboxFilledIcon, MultiCircleIcon, XCircleIcon,} from "../../../../../images";
import {EventIconButton} from "../../../../common";
import {useTranslation} from "react-i18next";

interface JoinRequestCardProps {
    cardType: "joinRequest" | "rejected" | "wait";
    handleAccept?: () => void;
    handleReject?: () => void;
    OnHandleWait?: () => void;
    /**
     * Here pass user first and last name
     */
    userName: string;
}

const JoinRequestCard: React.FC<JoinRequestCardProps> = ({
                                                             cardType,
                                                             userName,
                                                             handleAccept,
                                                             handleReject,
                                                             OnHandleWait
                                                         }) => {
    const {t}=useTranslation()
    const getText = (): React.ReactNode => {
        switch (cardType) {
            case "joinRequest":
                return (
                    <Typography
                        sx={{font: "normal normal 300 13px/16px Source Serif Pro"}}
                    >
                        <span style={{fontWeight: 400}}>{userName}</span> {t('VIDEO_CENTER.MEETING.HAS_REQUESTED_TO_JOIN_CALL')}
                    </Typography>
                );
            case "rejected":
                return (
                    <Typography
                        sx={{font: "normal normal 300 13px/16px Source Serif Pro"}}
                    >
                        {t('VIDEO_CENTER.MEETING.REQUEST_REJECTED')}
                    </Typography>
                );
            case "wait":
                return (
                    <Typography
                        sx={{font: "normal normal 300 13px/16px Source Serif Pro"}}
                    >
                        {t('VIDEO_CENTER.MEETING.HELLO')}
                        <span style={{fontWeight: 400}}>{userName}</span>{t('VIDEO_CENTER.MEETING.THANK_YOU_FOR_COMING')}
                    </Typography>
                );
            default:
                return (
                    <Typography>Unknown status. Please refresh the page.</Typography>
                ); // Handle unexpected cardType values
        }
    };

    return (
        <Stack
            sx={{
                width: "300px",
                border: "2px solid #E9EDF2",
                borderRadius: "5px",
                padding: "11px 8px 15px 10px",
                boxSizing: "border-box",
                bgcolor: "white",
                gap: "10px",
            }}
        >
            <Stack
                sx={{
                    flexDirection: "row",
                    alignItems: "start",
                    justifyContent: "space-between",
                    gap: "25px",
                }}
            >
                {getText()}
                <Tooltip title={"Close"} placement="top" arrow>
                    <IconButton aria-label="Close" sx={{p: 0}} disableRipple>
                        <XCircleIcon w={20} color="black"/>
                    </IconButton>
                </Tooltip>
            </Stack>

            {cardType === "joinRequest" && (
                <Stack
                    sx={{flexDirection: "row", gap: "10px", justifyContent: "center"}}
                >
                    <EventIconButton
                        buttonTitle={t('VIDEO_CENTER.MEETING.ACCEPT')}
                        icon={CheckboxFilledIcon}
                        iconColor="#21B481"
                        onClick={handleAccept}
                    />
                    <EventIconButton
                        buttonTitle={t('VIDEO_CENTER.MEETING.REJECT')}
                        icon={XCircleIcon}
                        iconColor="#FF0000"
                        onClick={handleReject}
                    />
                    <EventIconButton
                        buttonTitle={t('VIDEO_CENTER.MEETING.PLEASE_WAIT')}
                        icon={MultiCircleIcon}
                        iconColor="#FFB100"
                        onClick={OnHandleWait}
                    />
                </Stack>
            )}
        </Stack>
    );
};

export default JoinRequestCard;
