import { IconButton, Link, Popover, Stack, Typography } from "@mui/material";
import React, {  useState } from "react";
import { CheckboxMedium } from "../../../../common";
import { SettingsIcon } from "../../../../../images";
import { CreateSmartFilter } from "../CreateSmartFilter";
import axios from "axios";
import { baseURL } from "../../../../../redux/middleware/apiMiddleware";
import { getAccessToken, getToken } from "../../../../common/LocalStorage";

interface SmartFiltersDropdownProps {
  getFilters: () => Promise<void>;
  dataList: FilterItem[];
}

interface FilterItem {
  id: number;
  _id: string;
  isActive: boolean;
  name: string;
}

interface Host {
  domain: string;
  name: string;
  icon: string;
  _id: string;
}

interface BasicFilters {
  hasAttachment: boolean;
  hasLink: boolean;
  read: boolean;
  unread: boolean;
}

interface PriorityLevel {
  highest: boolean;
  medium: boolean;
  lowest: boolean;
}

interface AdvancedActions {
  moveTo: boolean;
  markAsRead: boolean;
  markImportant: boolean;
  moveToTrash: boolean;
}

interface Filter {
  basicFilters: BasicFilters;
  priorityLevel: PriorityLevel;
  advancedActions: AdvancedActions;
  isActive: boolean;
  _id: string;
  createdBy: string;
  name: string;
  from: string[];
  hosts: Host[];
  subjectKeywords: string[];
  subjectMatchType: string;
  bodyKeywords: string[];
  bodyMatchType: string;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}


// const dataList: { id: number; name: string; onSettingClick: () => void }[] = [
//   { id: 1, name: "Main Inbox", onSettingClick: () => null },
//   { id: 2, name: "No replies", onSettingClick: () => null },
//   { id: 3, name: "Bills", onSettingClick: () => null },
//   { id: 4, name: "Newsletters", onSettingClick: () => null },
//   { id: 5, name: "Events", onSettingClick: () => null },
//   { id: 6, name: "Social Media", onSettingClick: () => null },
// ];

const SmartFiltersDropdown: React.FC<SmartFiltersDropdownProps> = ({ dataList, getFilters }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [filterData, setFilterData] = useState<Filter | null>(null);

  const accesstoken = getAccessToken();
  const token = getToken();

  
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setFilterData(null)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGetFilter = (event: React.MouseEvent<HTMLButtonElement>, data: unknown) => {
    setAnchorEl(event.currentTarget);
    setFilterData(data as Filter)

  };

  const handleCheckboxClick = async (id: string) => {
    try {
      const res = await axios.put(`${baseURL}/email/toggle-smart-filter/${id}`, {  accesstoken }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (res.status === 200 && getFilters) {
        getFilters();
      }

      return res;

    } catch (err) {
      console.log(err)
    }
  };

 

  return (
    <Stack
      sx={{
        width: "140px",
        bgcolor: "white",
        padding: "10px 15px 10px 10px",
        boxSizing: "border-box",
        borderRadius: "5px",
        boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
        gap: "12px",
      }}
    >
      <Stack gap={"15px"}>
        {dataList.map((item) => (
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Stack
              sx={{
                flexDirection: "row",
                gap: "5px",
                alignItems: "center",
              }}
            >
              <CheckboxMedium checked={item?.isActive} boxSize={15} onClick={() => handleCheckboxClick(item?._id)} />
              <Typography
                sx={{
                  font: "normal normal 300 11px/11px Source Serif Pro",
                  alignItems: "center",
                  paddingTop: "3px",
                }}
              >
                {item?.name}
              </Typography>
            </Stack>

            <IconButton
              sx={{ p: 0, borderRadius: "5px" }}
              disableRipple
              onClick={(e) => handleGetFilter(e, item)}>
              <SettingsIcon w={10} />
            </IconButton>
          </Stack>
        ))}
      </Stack>

      <IconButton
        onClick={handleClick}
        sx={{
          p: 0,
          borderRadius: 0,
          justifyContent: "center",
          alignItems: "center",
        }}
        disableRipple
      >
        <Link
          underline="hover"
          sx={{ font: "normal normal 300 11px/14px Source Serif Pro", color: "#000AFF" }}>
          Create a smart filter
        </Link>
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <CreateSmartFilter getFilters={getFilters} filterData={filterData} setAnchorEl={setAnchorEl} />
      </Popover>
    </Stack>
  );
};

export default SmartFiltersDropdown;
