import React from "react";

import g from "./CommentFilePanel.module.css";
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputBase,
  Stack,
  Typography,
} from "@mui/material";
import {
  CloseIcon,
  HDotsIcon,
  MicIcon,
  PaintRollerIcon,
  VideoCallIcon,
} from "../../../../../../../images";

type CommentFilePanelProps = {};

const CommentFilePanel = (props: CommentFilePanelProps) => {
  return (
    <Box
      sx={{
        borderLeft: "1px solid #f4f4f4",
        height: "100%",
        marginLeft: "10px",
      }}
      p={"5px 15px 15px 15px"}
    >
      <Stack gap={1} direction={"column"}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography className={g.Comment__file_text} children={"Comments"} />
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <IconButton>
              <HDotsIcon w={15} />
            </IconButton>
            <IconButton>
              <CloseIcon w={15} />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>{" "}
      <Divider orientation="horizontal" sx={{ borderColor: "#E9EDF2" }} />
      <Stack height={"90%"} padding={"15px"}>
        {/* COmment box */}
      </Stack>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"flex-start"}
        gap={2}
      >
        <PaintRollerIcon w={31} />
        <InputBase
          className={g.comment_file_input}
          type="text"
          placeholder="Type your text here"
        />
        <MicIcon w={31} />
        <VideoCallIcon w={31} />
        <Button className={g.button__comment}>Comment</Button>
      </Stack>
    </Box>
  );
};

export default CommentFilePanel;
