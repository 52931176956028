import React from "react";
import g from "./ColumnControls.module.css";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  AllDirArrowsIcon,
  ClosedEyeIcon,
  EditIconCrm,
  HideIcon,
} from "../../../../../images";

type ColumnControlItem = {
  icon: React.ReactNode;
  text: string;
  action?: () => void; // Optional action handler
};

type ColumnControlsProps = {
  onSortAscending?: () => void;
  onSortDescending?: () => void;
  onMoveRight?: () => void;
  onMoveLeft?: () => void;
  onEditColumnName?: () => void;
  onHideColumn?: () => void;
};

const ColumnControls: React.FC<ColumnControlsProps> = ({
  onSortAscending,
  onSortDescending,
  onMoveRight,
  onMoveLeft,
  onEditColumnName,
  onHideColumn,
}) => {
  const sortControls: ColumnControlItem[] = [
    {
      icon: <AllDirArrowsIcon direction="up" />,
      text: "Sort ascending",
      action: onSortAscending,
    },
    {
      icon: <AllDirArrowsIcon direction="down" />,
      text: "Sort descending",
      action: onSortDescending,
    },
    {
      icon: <AllDirArrowsIcon direction="right" />,
      text: "Move to the right",
      action: onMoveRight,
    },
    {
      icon: <AllDirArrowsIcon direction="left" />,
      text: "Move to the left",
      action: onMoveLeft,
    },
  ];

  const otherControls: ColumnControlItem[] = [
    {
      icon: <EditIconCrm />,
      text: "Edit column name",
      action: onEditColumnName,
    },
    {
      icon: <ClosedEyeIcon />,
      text: "Hide column from view",
      action: onHideColumn,
    },
  ];

  const renderListItem = (item: ColumnControlItem) => (
    <ListItem disablePadding key={item.text}>
      <ListItemButton sx={{ padding: "5px" }} onClick={item.action}>
        <ListItemIcon sx={{ minWidth: "30px", marginLeft: "10px" }}>
          {item.icon}
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{ className: g.listStyleColControl }}
          primary={item.text}
        />
      </ListItemButton>
    </ListItem>
  );

  return (
    <Box className={g.ColcontrolHeadipBox}>
      <List>
        <Typography className={g.ColcontrolHeadip}>Column controls</Typography>
        {sortControls.map(renderListItem)}
      </List>
      <Divider />
      <List>{otherControls.map(renderListItem)}</List>
    </Box>
  );
};

export default ColumnControls;
