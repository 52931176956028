import {
    ClipboardPng,
    ConnectionsPng,
    SettingPng,
    Tranquil_AIPng,
    globePersonPng,
    multiPersonPng,
    notificationPng,
    securityPng,
    singlePersonPng,
} from "../../../../../images";
import { getUser } from "../../../../../Utils";

interface SettingsOptions {
    id: number;
    name: string;
    iconSrcs: string;
    nUrl?: string;
    hasAccess?: boolean;
    workOS___redirect?: boolean;
    stripe___redirect?: boolean;
}

const user = getUser();

export const SETTINGS_1_NAVBAR_ROUTES: SettingsOptions[] = [
    {
        id: 1,
        name: "SETTINGS.DRAWER_LABELS.GENERAL", // Use language key
        iconSrcs: SettingPng,
        nUrl: "/Settings/general",
        hasAccess: true,
        workOS___redirect: false
    },
    {
        id: 2,
        name: "SETTINGS.DRAWER_LABELS.PLAN_DETAILS", // Use language key
        iconSrcs: ClipboardPng,
        nUrl: "/Settings/plan-details",
        hasAccess: true,
        workOS___redirect: false
    },
    {
        id: 3,
        name: "SETTINGS.DRAWER_LABELS.APP_NOTIFICATIONS", // Use language key
        iconSrcs: notificationPng,
        nUrl: "/Settings/app-notifications",
        hasAccess: true,
        workOS___redirect: false
    },
    {
        id: 4,
        name: "SETTINGS.DRAWER_LABELS.YOUR_PROFILE", // Use language key
        iconSrcs: singlePersonPng,
        nUrl: "/Settings/profile",
        hasAccess: true,
        workOS___redirect: false
    },
];

export const SETTINGS_2_NAVBAR_ROUTES: SettingsOptions[] = [
    {
        id: 5,
        name: "SETTINGS.DRAWER_LABELS.SECURITY", // Use language key
        iconSrcs: securityPng,
        nUrl: "/Settings/app-security",
        hasAccess: true,
        workOS___redirect: false
    },
    {
        id: 6,
        name: "SETTINGS.DRAWER_LABELS.ADMIN_CONSOLE", // Use language key
        iconSrcs: multiPersonPng,
        hasAccess: user?.data?.userType === "Admin" ? true : false,
        workOS___redirect: true
    },
];

export const SETTINGS_3_NAVBAR_ROUTES: SettingsOptions[] = [
    {
        id: 7,
        name: "SETTINGS.DRAWER_LABELS.BEBA_AI", // Use language key
        iconSrcs: globePersonPng,
        nUrl: "/Settings/beba-ai",
        hasAccess: true,
        workOS___redirect: false,
    },
    {
        id: 8,
        name: "SETTINGS.DRAWER_LABELS.TRANQUIL_AI", // Use language key
        iconSrcs: Tranquil_AIPng,
        nUrl: "/Settings/tranquil-ai",
        hasAccess: true,
        workOS___redirect: false,
    },
    {
        id: 9,
        name: "SETTINGS.DRAWER_LABELS.INTELLIGENT_CONNECTIONS", // Use language key
        iconSrcs: ConnectionsPng,
        nUrl: "/Settings/intelligent-connections",
        hasAccess: true,
        workOS___redirect: false
    },
];
