import { Stack, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../Utils";

export interface CrmStatusBadgeProps {
  variant:
    | "onTime"
    | "late"
    | "sick"
    | "holiday"
    | "pto"
    | "absent"
    | "dayoff"
    | "active"
    | "inActive";
}

const CrmStatusBadge: React.FC<CrmStatusBadgeProps> = ({ variant }) => {
  const getBadgeData = (): {
    fontColor: string;
    badgeColor: string;
    clrOpacity: number;
    title: string;
  } => {
    switch (variant) {
      case "onTime":
        return {
          badgeColor: "#21B481",
          clrOpacity: 35,
          fontColor: "#000",
          title: "On Time",
        };
      case "late":
        return {
          badgeColor: "#FF0000",
          clrOpacity: 35,
          fontColor: "#000",
          title: "Late",
        };
      case "sick":
        return {
          badgeColor: "#FFB100",
          clrOpacity: 35,
          fontColor: "#000",
          title: "Sick",
        };
      case "holiday":
        return {
          badgeColor: "#96A3ED",
          clrOpacity: 35,
          fontColor: "#000",
          title: "Holiday",
        };
      case "pto":
        return {
          badgeColor: "#660ED7",
          clrOpacity: 35,
          fontColor: "#000",
          title: "PTO",
        };
      case "absent":
        return {
          badgeColor: "#D9D9D9",
          clrOpacity: 35,
          fontColor: "#000",
          title: "Absent",
        };
      case "dayoff":
        return {
          badgeColor: "#B5005B",
          clrOpacity: 35,
          fontColor: "#000",
          title: "Day off",
        };
      case "active":
        return {
          badgeColor: "#21B481",
          clrOpacity: 35,
          fontColor: "#000",
          title: "Active",
        };
      case "inActive":
        return {
          badgeColor: "#E9EDF2",
          clrOpacity: 35,
          fontColor: "#000",
          title: "Inactive",
        };
      default:
        return {
          badgeColor: "#E9EDF2",
          clrOpacity: 35,
          fontColor: "#000",
          title: variant,
        };
    }
  };

  return (
    <Stack
      sx={{
        flexDirection: "row",
        borderRadius: "5px",
        bgcolor: "white",
        flexShrink: 0,
      }}
    >
      <Typography
        sx={{
          font: "normal normal 400 12px/16px Source Serif Pro",
          padding: "2px 4px",
          borderRadius: "5px",
          color: getBadgeData().fontColor,
          bgcolor: adjustHexColor(
            getBadgeData().badgeColor,
            getBadgeData().clrOpacity
          ),
        }}
      >
        {getBadgeData().title}
      </Typography>
    </Stack>
  );
};

export default CrmStatusBadge;
