import {
  CheckedCircleIcon,
  EmailIcon,
  NotesLineIcon,
  QuickMessageIcon,
  VideoCallIcon,
} from "../../../images";
import { CRMIcon, PlanIcon, TraxIcon } from "../../../Router/RouteIcon";

export interface QuickActionsMenuListInterface {
  icon: React.ElementType;
  name: string;
  LANG: string;
}

export const QuickActionsMenuList: QuickActionsMenuListInterface[] = [
  { icon: NotesLineIcon, name: "Notes",LANG: "VIDEO_CENTER.MEETING.NOTES" },
  { icon: CheckedCircleIcon, name: "Task",LANG: "CALL_DETAILS.TASK"  },
  { icon: EmailIcon, name: "Email",LANG: "VIDEO_CENTER.MEETING.EMAIL"  },
  { icon: QuickMessageIcon, name: "Message",LANG: "MESSAGE.MESSAGE"},
  { icon: PlanIcon, name: "Event",LANG: "CALL_DETAILS.EVENT"  },
  { icon: VideoCallIcon, name: "Call",LANG: "CALL_DETAILS.CALL"  },
  { icon: CRMIcon, name: "CRM",LANG: "CALL_DETAILS.CRM"  },
  { icon: TraxIcon, name: "Bill",LANG: "CALL_DETAILS.BILL"  },
];
