import { InputBase, InputBaseProps, Stack, Typography } from "@mui/material";
import React from "react";

interface InputAreaWithLabelProps extends InputBaseProps {
  inputLabel: string;
  inputWidth?: string;
}

const InputAreaWithLabel: React.FC<InputAreaWithLabelProps> = ({
  inputLabel,
  inputWidth = "115px",
  ...props
}) => {
  return (
    <Stack sx={{ gap: "3px" }}>
      <Typography sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}>
          {inputLabel}
      </Typography>
      <InputBase
        sx={{
          width: inputWidth,
          boxSizing: "border-box",
          border: "1px solid #E9EDF2",
          borderRadius: "5px",
          font: "normal normal 300 13px/16px Source Serif Pro",
          padding: "0px 5px",
        }}
        {...props}
      />
    </Stack>
  );
};

export default InputAreaWithLabel;
