import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import VideoTiles from "./VideoTiles";
import { Box, Dialog, Stack, Typography } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { getAllExistingUsers } from "../../../redux/Slice/auth/authActions";
import CenterVideoTile from "./CenterVideoTile";
import "react-notifications/lib/notifications.css";
import { MeetingContext } from "../../Povider";
import { InCallGuestMenu, PrePostMeetingNotes } from "./components";
import AgendaLeftSideBar from "./components/AgendaLeftSideBar";
import "./Notifection/Notification.Module.css";
import BlackLongArrowButton from "./components/BlackLongArrowButton/BlackLongArrowButton";
import { FullScreenLoader } from "../../common";

const Meeting = ({ workMode }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const meetingContext = useContext(MeetingContext);
  const { user } = useSelector((state) => state.auth);
  const users = useSelector((state) => state.auth.users);
  const { onGoingMeeting } = useSelector((state) => state.videoCenter);
  const [loading, setLoading] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [showAllTiles, setShowAllTiles] = useState(false);
  const toggleTiles = () => {
    setShowAllTiles(!showAllTiles);
  };

  const videoTiles = useMemo(() => {
    const sorted = meetingContext.VideoPreferences.sortVideoTiles(
      Object.values(meetingContext.realtimeAttendees)
    );
    return sorted;
  }, [meetingContext.VideoPreferences]);

  const ownVideoTile = useMemo(
    () =>
      videoTiles.find(
        ({ userId, chimeAttendeeId }) =>
          user?.data?._id === userId && !chimeAttendeeId?.includes("#content")
      ),
    [user?.data?._id, videoTiles]
  );

  const ownContentTile = useMemo(
    () =>
      videoTiles.find(
        ({ userId, chimeAttendeeId }) =>
          user?.data?._id === userId && chimeAttendeeId?.includes("#content")
      ),
    [user?.data?._id, videoTiles]
  );

  const participants = videoTiles.map((attendee) => {
    const isCurrentUser = attendee.userId === user?.data?._id;
    const isContentShare = attendee.chimeAttendeeId?.includes("#content");
    return {
      ...attendee,
      isMicOn: !attendee.muted,
      isVideoOn: attendee.videoTileState?.boundVideoStream?.active,
      userName:
        !isContentShare && isCurrentUser
          ? `${users?.[attendee.userId]?.firstName} ${
              users?.[attendee.userId]?.lastName
            } (You)`
          : `${users?.[attendee.userId]?.firstName} ${
              users?.[attendee.userId]?.lastName
            }`.trim(),
      userEmail: users?.[attendee.userId]?.email,
    };
  });

  const WatingparticipantsWithUserDetails = useMemo(
    () =>
      Object.values(onGoingMeeting?.guestRequests || {}).map((participant) => ({
        ...participant,
        userName: [
          users?.[participant?.userId]?.firstName,
          users?.[participant?.userId]?.lastName,
        ]
          .filter(Boolean)
          .join(" "),
        userEmail: users?.[participant?.userId]?.email,
        avatarText: users?.[participant.userId]?.avatar?.split?.("|")?.[1],
      })),
    [onGoingMeeting?.guestRequests, users]
  );

  const handleFetchUserList = async () => {
    await dispatch(getAllExistingUsers());
  };

  const handleClickOnclose = () => {
    setOpenPopup(false);
  };
  const handleCopyLink = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url).then(
      () => console.log("Message copied to clipboard!"),
      (err) => console.error("Failed to copy message:", err)
    );
  };

  useEffect(() => {
    handleFetchUserList();
  }, []);

  useEffect(() => {
    if (onGoingMeeting?.meeting?.meetingHostId) {
      setLoading(false);
    }
    if (onGoingMeeting?.meeting?.isMeetingEnded === true) {
      setOpenPopup(true);
      setTimeout(() => {
        navigate("/Calls");
      }, 3000);
    }
  }, [onGoingMeeting?.meeting]);

  if (loading) {
    return <FullScreenLoader />;
  }
  
  return (
    <Stack
      p={2}
      sx={{
        flexGrow: 1,
        flexDirection: "row",
        gap: 4,
        padding: "0",
        height: "100vh",
        marginTop: "15px",
      }}
    >
      <Stack flex={2}>
        <AgendaLeftSideBar />
      </Stack>
      <Dialog
        onClose={handleClickOnclose}
        open={openPopup}
        PaperProps={{
          sx: {
            borderRadius: "12px",
            padding: "24px",
            maxWidth: "400px",
            textAlign: "center",
          },
        }}
      >
        <Box sx={{ textAlign: "center", padding: "16px" }}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", marginBottom: "8px" }}
          >
            Meeting Ended
          </Typography>
          <Typography variant="body1" sx={{ color: "#757575" }}>
            The meeting has ended. Thank you for participating.
          </Typography>
        </Box>
      </Dialog>

      <Stack flex={9} sx={{ flexDirection: "column", gap: 1 }}>
        <Stack
          sx={{
            flexDirection: "row",
            gap: 2,
            marginLeft: 4.1,
            marginRight: 2.3,
          }}
        >
          <Stack flex={2.5} justifyContent={workMode && "space-between"}>
            {!workMode ? (
                  <CenterVideoTile
                      videoTile={videoTiles?.[0]}
                      ownVideoTile={ownVideoTile}
                      ownContentTile={ownContentTile}
                      meetingDetails={onGoingMeeting.meeting}
                      videoTiledata={videoTiles}
                      userId={user?.data?._id}
                  />
            ) : (
              <div style={{height:"200px"}}>
                
              </div>
            )}
            <Box style={{ marginTop: "40px" }}>
              <PrePostMeetingNotes
                meetingId={onGoingMeeting?.meeting?._id}
                userId={user?.data?._id}
                accessDenied={false}
              />
            </Box>
          </Stack>

          <Stack flex={1} sx={{maxWidth:"275px"}}>
            <Stack
              flexDirection={"column"}
              style={{ maxWidth: "275px" }}
              gap={1}
            >
              {workMode && (
                <div>
                  <CenterVideoTile
                    videoTile={videoTiles?.[0]}
                    ownVideoTile={ownVideoTile}
                    ownContentTile={ownContentTile}
                    newcss={true}
                    videoTiledata={videoTiles}
                    meetingDetails={onGoingMeeting.meeting}
                    userId={user?.data?._id}
                  />
                </div>
              )}
            </Stack>
            {!workMode && (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: 1,
                  flex: 3,

                }}
              >
                {videoTiles.slice(1, showAllTiles ? 9 : 5).map((tile, i) => (
                  <VideoTiles
                    {...tile}
                    aspectRatio={1}
                    key={i}
                    showIcon={true}
                  />
                ))}
              </Box>
            )}
            {videoTiles.length > 5 && (
              <Box style={{ marginTop: "15px", marginBottom: "20px" }}>
                <BlackLongArrowButton onClick={toggleTiles} />
              </Box>
            )}
            <Stack flex={6} sx={{justifyContent:"flex-end"}}>
              <InCallGuestMenu
                joinedUserList={participants}
                waitingUserList={WatingparticipantsWithUserDetails}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
};

export default Meeting;
