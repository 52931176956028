import React, { useEffect, useState } from "react";
import { Gantt, Task, ViewMode as GanttViewMode } from "gantt-task-react";
import "gantt-task-react/dist/index.css"; // Import the required CSS
import NoTask from "../NoTask";
import { Stack } from "@mui/material";
import ViewModeSelector from "./ViewModeButton";
import { adjustHexColor } from "../../../../../../Utils";
import { updateOneTask__api, UpdateTaskPayload } from "../../../../../../redux";
import { useDispatch } from "react-redux";

// Define a type for the component props (currently empty)
type GanttOverViewProps = {
  workspaceItemData?: any;
  taskWorkspaceItemData?: any;
};

const GanttOverView: React.FC<GanttOverViewProps> = ({
  taskWorkspaceItemData,
}) => {
  const dispatch = useDispatch();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [viewMode, setViewMode] = useState<GanttViewMode>(GanttViewMode?.Day);

  // Event handler options
  const handleSelect = (task: Task, isSelected: boolean) => {
    console.log("Selected task:", task, "Is selected:", isSelected);
  };

  const handleDoubleClick = (task: Task) => {
    console.log("Double-clicked task:", task);
  };

  const handleClick = (task: Task) => {
    console.log("Clicked task:", task);
  };

  // Function to generate the update payload, preserving all other task fields
  const getUpdatedTaskPayload = (
    updatedTask: Task,
    taskWorkspaceItemData: any
  ): UpdateTaskPayload => {
    // Find the corresponding task from taskWorkspaceItemData based on updatedTask ID
    const originalTaskData = taskWorkspaceItemData
      ?.find((item: any) =>
        item?.tasks?.some((task: any) => task?._id === updatedTask?.id)
      )
      ?.tasks?.find((task: any) => task?._id === updatedTask?.id);

    return {
      taskId: updatedTask?.id, // Directly from updatedTask
      name: updatedTask?.name, // Preserving name
      dueDate: updatedTask?.end?.toISOString(), // Update dueDate as ISO 8601 string
      assignedUserIds:
        originalTaskData?.assignedUsers?.map((user: any) => user._id) || [], // Use data from taskWorkspaceItemData
      priority: {
        id: originalTaskData?.priority?.id,
        value: originalTaskData?.priority?.value,
        defaultColorHex: originalTaskData?.priority?.defaultColorHex,
        customColorHex: originalTaskData?.priority?.customColorHex,
      },
      status: {
        id: originalTaskData?.status?.id,
        value: originalTaskData?.status?.value,
        defaultColorHex: originalTaskData?.status?.defaultColorHex,
        customColorHex: originalTaskData?.status?.customColorHex,
      },
      taskColor: originalTaskData?.cardColor, // Task color from styles
      estimatedTime: originalTaskData?.estimatedTime || 0, // Assuming this is still available in Task
      dependencies: originalTaskData?.dependencies || 0, // Number of dependencies
      cardColor: originalTaskData?.cardColor || "#ffffff", // Default to white if not available
      emoji: originalTaskData?.emoji || "", // Default to empty string if not available
      taskType: originalTaskData.taskType, // Assuming this is still available
      customElements: originalTaskData?.customElements || {}, // Default to empty object if not available
      checklists: originalTaskData?.checklists || [], // Include checklists if present
    };
  };

  // Function to handle date change and create the payload for API
  const handleDateChange = (updatedTask: Task, children: Task[]) => {
    console.log("Date changed for task:", updatedTask);

    // Update the tasks state with the modified task
    setTasks((prevTasks) =>
      prevTasks?.map((task) =>
        task?.id === updatedTask?.id ? updatedTask : task
      )
    );

    // Create the payload for API (preserving all other fields)
    const payload = getUpdatedTaskPayload(updatedTask, taskWorkspaceItemData);

    // Trigger the API call to save the updated task
    handleUpdateTask(payload);

    return true; // Return true to accept the changes
  };

  // Function to handle the API call for updating the task's details
  const handleUpdateTask = async (payload: UpdateTaskPayload) => {
    try {
      // Call your API with the payload (with all relevant fields)
      const action = updateOneTask__api(payload.taskId, payload);
      await dispatch(action); // Dispatch action to update the task
    } catch (error) {
      console.error("Failed to update task:", error);
    }
  };

  const handleProgressChange = (task: Task, children: Task[]) => {
    return true;
  };

  const handleDelete = (task: Task) => {
    return true;
  };

  const handleExpanderClick = (task: Task) => {};

  // Transform workspace data into Gantt tasks
  useEffect(() => {
    if (taskWorkspaceItemData) {
      const transformedTasks = transformToGanttTasks(taskWorkspaceItemData);
      if (transformedTasks?.length === 0) {
        console.log("No valid tasks found after transformation.");
      }
      setTasks(transformedTasks);
    }
  }, [taskWorkspaceItemData]);

  // Utility to transform the data into Gantt Task format
  const transformToGanttTasks = (workspaceItemData: any) => {
    return workspaceItemData?.reduce((acc: any, group: any) => {
      group?.tasks?.forEach((task: any) => {
        if (task?._id && task?.createdAt && task?.dueDate) {
          // Define default and custom colors based on the task status
          const defaultBarColor =
            task.status?.value === "Done" ? "#00c853" : "#ffab00"; // Example default colors for bar fill
          const customBarColor =
            task.status?.customColorHex || task.status?.defaultColorHex
              ? task.status?.customColorHex || task.status?.defaultColorHex
              : defaultBarColor; // If task has custom color, use it

          const ganttTask: Task = {
            id: task?._id,
            name: task?.name || "Untitled",
            start: new Date(task?.createdAt),
            end: new Date(task?.dueDate),
            progress: task?.status?.value === "Done" ? 100 : 0,
            type: "task",
            dependencies: task?.dependencies
              ? task?.dependencies?.map((dep: any) => dep?._id)
              : [],
            styles: {
              backgroundColor:
                adjustHexColor(customBarColor || "#ff9e0d", 80) ||
                customBarColor, // Rectangle fill color
              backgroundSelectedColor:
                adjustHexColor(customBarColor || "#ff9e0d", 80) ||
                customBarColor, // Selected rectangle fill color
              progressColor:
                adjustHexColor(customBarColor || "#ff9e0d", 80) ||
                customBarColor ||
                "#ffffff", // Optional: Set progress color inside the bar
              progressSelectedColor:
                adjustHexColor(customBarColor || "#ff9e0d", 80) ||
                customBarColor ||
                "#ffffff", // Optional: Progress color when selected
            },
          };
          acc?.push(ganttTask);
        } else {
          console.warn("Invalid task detected:", task);
        }
      });
      return acc;
    }, []);
  };

  // Function to set view mode based on user selection
  const changeViewMode = (mode: GanttViewMode) => {
    setViewMode(mode);
  };

  // Styling Options
  const stylingOptions = {
    headerHeight: 50,
    columnWidth: 120,
    rowHeight: 50,
    barCornerRadius: 3,
    barFill: 80,
    barProgressColor: adjustHexColor("#ff9e0d", 80) || "#ff9e0d",
    barBackgroundColor: adjustHexColor("#e0e0e0", 80) || "#e0e0e0",
    milestoneBackgroundColor: adjustHexColor("#f65a5a", 80) || "#f65a5a",
    arrowColor: adjustHexColor("#ff9e0d", 80) || "#ff9e0d",
    todayColor: adjustHexColor("#800080", 20) || "#ffc107",
    fontFamily: "Source Serif Pro",
  };

  // Display Options
  const displayOptions = {
    viewMode,
    viewDate: new Date(), // Set today's date as default
    locale: "en-US", // Default locale
  };

  // Event Options
  const eventOptions = {
    timeStep: 15,
    onSelect: handleSelect,
    onDoubleClick: handleDoubleClick,
    onClick: handleClick,
    onDateChange: handleDateChange,
    onProgressChange: handleProgressChange,
    onDelete: handleDelete,
    onExpanderClick: handleExpanderClick,
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        overflow: "hidden",
        fontFamily: "'Source Serif Pro'",
      }}
    >
      <div
        style={{
          padding: "10px",
          background: "#f0f0f0",
          borderBottom: "1px solid #ddd",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Buttons for each view mode */}
        <Stack>
          <ViewModeSelector
            initialSelectedDays={[viewMode]} // Set the initial selected mode
            onChange={(selectedDays) => {
              // Since selectedDays will only contain one item, get the first element
              if (selectedDays?.length > 0) {
                changeViewMode(selectedDays?.[0] as GanttViewMode);
              }
            }}
          />
        </Stack>
      </div>
      {tasks && tasks?.length > 0 ? (
        <Gantt
          tasks={tasks}
          {...stylingOptions}
          {...displayOptions}
          {...eventOptions}
        />
      ) : (
        <NoTask />
      )}
    </div>
  );
};

export default GanttOverView;
