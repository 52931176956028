import { Menu, MenuItem, Popover, Stack, Typography } from "@mui/material";
import React, { MouseEvent, useEffect, useState } from "react";
import {
  repeatOptionMenuOptionList,
  RepeatOptionMenuOptions,
  repeatOptionMenuRepeatModeEnum,
} from "./RepeatOptionMenuType";
import { RepeatView, WeekSelectorView } from "./view";
import { DatePickerWithIcon, StyledRadioButtonBlack } from "../../../../common";
import { WeekDaysEnum } from "../../../../../Utils";

interface RepeatOptionMenuProps {
  options?: RepeatOptionMenuOptions[];
  onSelect: (value: any) => void;
  width?: string;
}

interface CustomValueProps {
  repeatFrequency: string;
  repeatMode: repeatOptionMenuRepeatModeEnum;
  selectedWeekDays?: WeekDaysEnum[];
  repeatEnd: "never" | "specific";
  endTime?: string;
}

const RepeatOptionMenu: React.FC<RepeatOptionMenuProps> = ({
  onSelect,
  options = repeatOptionMenuOptionList,
  width = "150px",
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [selectedValue, setSelectedValue] = useState<string>("Does not repeat");
  const [customValue, setCustomValue] = useState<CustomValueProps>({
    repeatFrequency: "1",
    repeatMode: repeatOptionMenuRepeatModeEnum.Day,
    selectedWeekDays: [],
    repeatEnd: "never",
    endTime: "", // Initialize with an empty string
  });

  const open = Boolean(anchorEl);
  const popoverOpen = Boolean(popoverAnchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (
    value: string,
    event: MouseEvent<HTMLElement>
  ): void => {
    if (value === "Custom") {
      setPopoverAnchorEl(event.currentTarget);
    } else {
      handleClose();
      setSelectedValue(value);

      // Prepare repeat configuration based on the selected value
      const repeatConfig = prepareRepeatConfig(value);
      onSelect(repeatConfig); // Pass the repeat config to the parent
    }
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };

  useEffect(() => {
    if (popoverOpen) {
      // Update onSelect with custom configuration when custom values change
      const repeatConfig = prepareRepeatConfig("Custom");
      onSelect(repeatConfig);
    }
  }, [customValue]);

  const prepareRepeatConfig = (value: string): any => {
    if (value === "Custom") {
      return {
        type: "custom",
        custom: {
          interval: parseInt(customValue.repeatFrequency, 10),
          repeatDays: customValue.selectedWeekDays,
          frequency:
            customValue.repeatMode === repeatOptionMenuRepeatModeEnum.Day
              ? "day"
              : customValue.repeatMode === repeatOptionMenuRepeatModeEnum.Week
              ? "week"
              : "month", // Default to "month" if none of the above match

          endDate:
            customValue.repeatEnd === "specific" ? customValue.endTime : null,
        },
      };
    } else {
      switch (value.toLowerCase()) {
        case "none":
          return {
            type: "none",
          };
        case "daily":
          return {
            type: "daily",
            custom: {
              interval: 1,
              repeatDays: [],
              frequency: "day",
              endDate: null,
            },
          };
        case "weekly":
          return {
            type: "weekly",
            custom: {
              interval: 1,
              repeatDays: [customValue.selectedWeekDays?.[0] || "monday"], // Default repeat day
              frequency: "week",
              endDate: null,
            },
          };
        case "monthly":
          return {
            type: "monthly",
            custom: {
              interval: 1,
              repeatDays: [],
              frequency: "month",
              endDate: null,
            },
          };
        case "every weekday":
          return {
            type: "weekly",
            custom: {
              interval: 6,
              repeatDays: [
                "monday",
                "tuesday",
                "wednesday",
                "thursday",
                "friday",
              ],
              frequency: "week",
              endDate: null,
            },
          };
        default:
          return {
            type: "none",
          };
      }
    }
  };

  const handleDateChange = (date?: string) => {
    setCustomValue((prev) => ({
      ...prev,
      endTime: date || "", // Adjust to save string directly
    }));
  };

  return (
    <Stack>
      <Stack
        onClick={handleClick}
        sx={{
          font: "normal normal 300 13px/16px Source Serif Pro",
          height: "25px",
          width: width,
          cursor: "pointer",
          bgcolor: "white",
          boxSizing: "border-box",
          flexDirection: "row",
          alignItems: "center",
          padding: "0px 10px",
        }}
      >
        <Typography
          sx={{
            font: "inherit",
            whiteSpace: "nowrap",
            overflow: "scroll",
            scrollbarWidth: "none",
          }}
        >
          {selectedValue}
        </Typography>
      </Stack>

      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: "150px",
            bgcolor: "white",
            marginTop: "5px",
            borderRadius: popoverOpen ? "5px 0px 0px 5px" : "5px",
            boxShadow: popoverOpen
              ? "-3px 3px 3px 0px rgba(0, 0, 0, 0.15)"
              : "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        {options.map((item, index) => (
          <MenuItem
            key={index}
            onClick={(event) => handleSelect(item.optionName, event)}
            sx={{
              padding: "0px 0px 0px 10px",
              boxSizing: "border-box",
              height: "25px",
              minHeight: 0,
              flexShrink: 0,
              font: "normal normal 300 13px/16px Source Serif Pro",
            }}
          >
            {item.optionName}
          </MenuItem>
        ))}
      </Menu>

      {/* Popover for "Custom" option */}
      <Popover
        open={popoverOpen}
        anchorEl={popoverAnchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            marginTop: "8px",
            boxShadow: "3px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            borderRadius: "0px 5px 5px 0px",
            borderLeft: "1px solid #E9EDF2",
          },
        }}
      >
        <Stack
          sx={{
            height: "166px",
            width: "200px",
            bgcolor: "white",
            boxSizing: "border-box",
            padding: "10px",
            gap: "15px",
          }}
        >
          <Stack
            sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}
          >
            <Typography
              sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
            >
              Repeat every
            </Typography>
            <RepeatView
              componentType="numCount"
              onSelect={(value: string) =>
                setCustomValue((prev) => ({
                  ...prev,
                  repeatFrequency: value as string,
                }))
              }
            />
            <RepeatView
              componentType="dayMonthCount"
              onSelect={(value: repeatOptionMenuRepeatModeEnum | string) =>
                setCustomValue((prev) => ({
                  ...prev,
                  repeatMode: value as repeatOptionMenuRepeatModeEnum,
                }))
              }
            />
          </Stack>

          {customValue.repeatMode === "Week" && (
            <WeekSelectorView
              onChange={(selectedDays: WeekDaysEnum[]) =>
                setCustomValue((prev) => ({
                  ...prev,
                  selectedWeekDays: selectedDays,
                }))
              }
              initialValue={customValue.selectedWeekDays}
            />
          )}

          <Typography
            sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
          >
            Ends
          </Typography>

          <Stack
            sx={{ flexDirection: "row", gap: "5px", cursor: "pointer" }}
            onClick={() =>
              setCustomValue((prev) => ({
                ...prev,
                repeatEnd: "never",
              }))
            }
          >
            <StyledRadioButtonBlack
              checked={customValue.repeatEnd === "never"}
            />
            <Typography
              sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
            >
              Never
            </Typography>
          </Stack>

          <Stack sx={{ flexDirection: "row", gap: "5px", cursor: "pointer" }}>
            <StyledRadioButtonBlack
              checked={customValue.repeatEnd === "specific"}
              onClick={() =>
                setCustomValue((prev) => ({
                  ...prev,
                  repeatEnd: "specific",
                }))
              }
            />
            <Typography
              sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
            >
              On
            </Typography>
            {customValue.repeatEnd === "specific" && (
              <DatePickerWithIcon
                onDateChange={handleDateChange} // Ensure this matches what DatePickerWithIcon expects
              />
            )}
          </Stack>
        </Stack>
      </Popover>
    </Stack>
  );
};

export default RepeatOptionMenu;
