import { IconButton, Stack, Typography } from "@mui/material";
import React, { useRef } from "react";
import { GreenDropdownIcon } from "../../../../../images";
import { SmallPinnedCardforMessage } from "../../../../common";
import styles from "./SidebarPinnedChatContainer.module.css";
import { MessagesState } from "../../../../../redux/Slice/message/messageTypes";
import { RootState } from "../../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addChannelIdAction,
  addChannelNameAction,
} from "../../../../../redux/Slice/message/messageSlice";
import { AuthState } from "../../../../../redux";
import { useTranslation } from "react-i18next";

interface SidebarPinnedForChannelContainerProps {}

const SidebarPinnedForChannelContainer: React.FC<
  SidebarPinnedForChannelContainerProps
> = ({}) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { channels } = useSelector(
    (state: RootState) => state.message as MessagesState
  );
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.auth as AuthState);
  const scroll = (direction: "left" | "right") => {
    if (scrollRef.current) {
      const scrollAmount = 100;
      scrollRef.current.scrollBy({
        left: direction === "right" ? scrollAmount : -scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const handleOnClick = async (channelId: any, channelName: any) => {
    await dispatch(addChannelIdAction(channelId));
    await dispatch(addChannelNameAction(channelName));
    navigate(`/Message/${channelId}`);
  };
  return (
    <Stack
      className={styles.sidebarPinnedChatContainer}
      style={{ marginBottom: "10px" }}
    >
      <Stack className={styles.sidebarPinnedChatContainer__header}>
        <Typography className={styles.sidebarPinnedChatContainer__headerText}>
          {t("MESSAGE.PINNED_CHATS")}
        </Typography>
        <Stack className={styles.sidebarPinnedChatContainer__divider} />
      </Stack>
      <Stack className={styles.sidebarPinnedChatContainer__controls}>
        <IconButton sx={{ p: 0 }} disableRipple onClick={() => scroll("left")}>
          <GreenDropdownIcon open sx={{ transform: "rotate(180deg)" }} />
        </IconButton>

        <Stack
          ref={scrollRef}
          className={styles.sidebarPinnedChatContainer__scrollContainer}
        >
          {Object.values(channels).map((chatItem, index) => {
            if (!chatItem.isPinned) return;
            return (
              <SmallPinnedCardforMessage
                userName={chatItem.displayName}
                size={40}
                {...chatItem}
                chatItem={chatItem}
                userId={user?.data?._id}
                onClick={() =>
                  handleOnClick(chatItem._id, chatItem.displayName)
                }
                key={index}
              />
            );
          })}
        </Stack>

        <IconButton sx={{ p: 0 }} disableRipple onClick={() => scroll("right")}>
          <GreenDropdownIcon open sx={{ transform: "rotate(0deg)" }} />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default SidebarPinnedForChannelContainer;
