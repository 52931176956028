import { Link, Stack, Typography } from "@mui/material";
import React from "react";
import { ItemInsightsAccessCard } from "../../../ItemInsightsAccessCard";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

interface AccessViewProps {}

const AccessView: React.FC<AccessViewProps> = ({}) => {
  const cloudStorageState = useSelector(
    (state: RootState) => state.cloudStorage
  );
  const { memberOfCloudStorageFile } = cloudStorageState || {};

  const cloudStorageMembers = memberOfCloudStorageFile?.members || [];

  const contentToDisplay =
    cloudStorageMembers?.length > 0
      ? cloudStorageMembers // Show cloud storage members if fileId exists and workspaceId is absent
      : null;

  return (
    <Stack gap={"20px"}>
      {/* ----------------------- */}
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "start",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{ font: "normal normal 400 14px/18px Source Sarif Pro" }}
        >
          Access
        </Typography>
        <Link
          underline="hover"
          sx={{
            font: "normal normal 400 14px/18px Source Sarif Pro",
            color: "#000AFF",
            cursor: "pointer",
          }}
        >
          Share
        </Link>
      </Stack>
      {/* ----------------------- */}
      {/* ----------------------- */}

      <Stack
        sx={{
          maxHeight: "160px",
          overflowY: "scroll",
          scrollbarWidth: "none",
          gap: "20px",
        }}
      >
        {contentToDisplay &&
          contentToDisplay?.map((item: any, index: number) => {
            return (
              <ItemInsightsAccessCard
                key={index}
                userNameOrEmail={
                  item?.userId
                    ? `${item?.userId?.firstName} ${item?.userId?.lastName}`
                    : ""
                }
                userRole={item?.role || "Admin"}
                userIconSrc={item?.userId?.avatar || ""}
                timestamp={item?.timestamp}
              />
            );
          })}
      </Stack>

      {/* ----------------------- */}
      {/* ----------------------- */}

      {contentToDisplay && contentToDisplay?.length > 4 && (
        <Link
          underline="hover"
          sx={{
            font: "normal normal 400 14px/18px Source Sarif Pro",
            color: "#000AFF",
            cursor: "pointer",
          }}
        >
          View more
        </Link>
      )}
      {/* ----------------------- */}
    </Stack>
  );
};

export default AccessView;
