import React, { useState } from "react";
import { Stack, Rating, Typography } from "@mui/material";

interface RatingViewProps {
  initialValue?: number; // Initial rating value (e.g., 4)
  onChange?: (rating: number) => void; // Callback to update the rating value
}

const RatingView: React.FC<RatingViewProps> = ({
  initialValue = 0,
  onChange,
}) => {
  const [rating, setRating] = useState<number | null>(initialValue);

  const handleRatingChange = (
    event: React.ChangeEvent<{}>,
    newValue: number | null
  ) => {
    setRating(newValue);
    if (newValue !== null && onChange) {
      onChange(newValue); // Pass the selected rating to the parent
    }
  };

  return (
    <Stack>
      <Rating
        name="custom-rating"
        value={rating}
        onChange={handleRatingChange} // Handle the rating change
        precision={0.5} // Allow half-star ratings
        size="large" // Optional size for larger stars
      />
    </Stack>
  );
};

export default RatingView;
