import * as React from "react";
import {
  Box,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import S from "./CompanyLongDropdown.module.css";
import { CompanyIcon, GreenDropdownIcon } from "../../../images";
import {
  CompanyLongDropdownOptionInterface,
  // DUMMY_CompanyLongDropdownOption,
} from "./CompanyLongDropdownTypes";

interface CompanyLongDropdownProps {
  width?: string;
  options: CompanyLongDropdownOptionInterface[];
  onChange?: (selectedOption: CompanyLongDropdownOptionInterface) => void;
  initialSelectedOption?: CompanyLongDropdownOptionInterface;
  placeholder?: string;
  label?: string;
}

const CompanyLongDropdown: React.FC<CompanyLongDropdownProps> = ({
  width = "305px",
  options,
  onChange,
  initialSelectedOption,
  placeholder,
  label,
  ...props
}) => {
  const [selectedOption, setSelectedOption] = React.useState<
    CompanyLongDropdownOptionInterface | undefined
  >(initialSelectedOption);
  const [isOpen, setIsOpen] = React.useState(false);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const selected = options.find(
      (option) => option?.companyName === event.target.value
    );
    setSelectedOption(selected);
    if (onChange && selected) {
      onChange(selected);
    }
  };

  const menuProps = {
    PaperProps: {
      className: S["company-long-dropdown__menu"],
      style: { width: "inherit" },
    },
    anchorOrigin: {
      vertical: "bottom" as const,
      horizontal: "right" as const,
    },
    transformOrigin: {
      vertical: "top" as const,
      horizontal: "right" as const,
    },
  };

  return (
    <div style={{ width: width }}>
      <FormControl style={{ width }} {...props}>
        {label && (
          <Typography
            sx={{
              font: "normal normal 300 10px/13px Source Serif Pro",
              marginBottom: "5px",
            }}
          >
            {label}
          </Typography>
        )}
        <Select
          open={isOpen}
          onClick={!isOpen ? () => setIsOpen(true) : () => null}
          IconComponent={() => <GreenDropdownIcon open={isOpen} />}
          displayEmpty
          value={selectedOption?.companyName}
          onChange={handleChange}
          input={
            <OutlinedInput
              startAdornment={
                <Stack sx={{ flexDirection: "row" }}>
                  <CompanyIcon w={15} />
                </Stack>
              }
              className={S["company-long-dropdown__inputbox"]}
              classes={{
                notchedOutline: S["company-long-dropdown__notchedOutline"],
                focused: S["company-long-dropdown__focused"],
              }}
            />
          }
          onClose={() => setIsOpen(false)}
          renderValue={(selected) => {
            if (!selected) {
              return (
                <em
                  style={{ paddingLeft: "5px" }}
                  className={S["company-long-dropdown__select-placeholder"]}
                >
                  {placeholder}
                </em>
              );
            }

            return (
              <span className={S["company-long-dropdown__selected"]}>
                {selectedOption && (
                  <Box
                    component={"img"}
                    src={selectedOption.companyIconSrc}
                    sx={{
                      height: "15px",
                      width: "15px",
                      marginX: "5px",
                      borderRadius:"50%"
                    }}
                  />
                )}
                {selectedOption?.companyName}
              </span>
            );
          }}
          MenuProps={menuProps}
        >
          {options?.map((option, index) => (
            <MenuItem
              className={S["company-long-dropdown__menu-item"]}
              key={index}
              value={option?.companyName}
            >
              <Stack direction="row" alignItems="center" gap={"10px"}>
                <Box
                  component={"img"}
                  sx={{ height: "30px", width: "30px",  borderRadius:"25%" }}
                  src={option?.companyIconSrc}
                  alt="logo"
                />

                <Stack>
                  <Typography className={S["company-long-dropdown__title"]}>
                    {option?.companyName}
                  </Typography>
                  <Typography className={S["company-long-dropdown__subtitle"]}>
                    {option?.companyMail}
                  </Typography>
                </Stack>
              </Stack>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default CompanyLongDropdown;
