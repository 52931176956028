import React from "react";
import { Button, ButtonProps, Typography } from "@mui/material";
import { adjustHexColor } from "../../../../../Utils";
import {useTranslation, } from "react-i18next";

interface EndCallButtonProps extends ButtonProps {
  btnSize: "small" | "large";
}

const EndCallButton: React.FC<EndCallButtonProps> = ({ btnSize, ...props }) => {
    const {t}=useTranslation()
  return (
    <Button
      variant="text"
      sx={{
        textTransform: "none",
        minWidth: "0px",
        p: 0,
        height: "50px",
        width: btnSize === "large" ? "125px" : "50px",
        boxSizing: "border-box",
        borderRadius: "25px",
        // border: "1px solid white",
        // boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.16)",
        bgcolor: adjustHexColor("#FF0000", 100),
        ":hover": {
          bgcolor: adjustHexColor("#FF0000", 100 + 10),
        },
      }}
      {...props}
    >
      <Typography
        sx={{ font: "normal normal 400 18px/22px Inter", color: "white" }}
      >
        {btnSize === "large" ? t("VIDEO_CENTER.MEETING.END_CALL") : t("VIDEO_CENTER.MEETING.END")}
      </Typography>
    </Button>
  );
};

export default EndCallButton;
