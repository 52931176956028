import React, { useState } from "react";
import styles from "./ListTableSwitch.module.css";
import { Box, Stack, Typography } from "@mui/material";
import { ListViewIcon, TableIcon } from "../../../images";
import { SwitchSmallProps } from "../../pages";

const ListTableSwitch: React.FC<SwitchSmallProps> = () => {
  const [viewMode, setViewMode] = useState<"list" | "table">("list");

  return (
    <Stack className={styles.ListTableSwitchContainer}>
      <Box
        className={`${styles.ListTableSwitch} ${
          viewMode === "list" ? styles["ListTableSwitch--selected"] : ""
        }`}
        component="span"
        onClick={() => setViewMode("list")}
        // style={{ pointerEvents: disabled ? "none" : "auto" }}
      >
        <ListViewIcon w={15} />
        <Typography
          sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
        >
          List
        </Typography>
      </Box>
      <Box
        className={`${styles.ListTableSwitch} ${
          viewMode === "table" ? styles["ListTableSwitch--selected"] : ""
        }`}
        component="span"
        onClick={() => setViewMode("table")}
        // style={{ pointerEvents: disabled ? "none" : "auto" }} // Disable click when disabled
      >
        <TableIcon w={15} />
        <Typography
          sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
        >
          Table
        </Typography>
      </Box>
    </Stack>
  );
};

export default ListTableSwitch;
