import React, { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { StyledRadioButtonBlack } from "../../../../common";
import {
  getIconPopupCalendarIcons,
  IconPopupCalendarIconsEnum,
} from "../IconPopupCalendar/IconPopupCalendarTypes";
import { adjustHexColor } from "../../../../../Utils";
import { PendingEvent } from "../PendingEvent";
import {
  eventResponse__api,
  updateEventTaskStatus__api,
} from "../../../../../redux";
import { useDispatch } from "react-redux";

interface BaseEventCardProps {
  timeDuration: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  fullWidth?: boolean;
  eventName: string;
  cardColor: string;

  //   StartTime:string;
  //   endTime:string;
}

interface CardEventCardProps extends BaseEventCardProps {
  cardType: "card";
  iconName?: IconPopupCalendarIconsEnum;
  status: "pending" | "Accept" | "Reject" | "Maybe";
  extendedProps?: any;
}
// Function to convert to 12-hour time format
const convertTo12HourFormat = (isoDate: string): string => {
  const date = new Date(isoDate);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12; // Converts 24-hour to 12-hour format
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};
interface TaskEventCardProps extends BaseEventCardProps {
  cardType: "task";
  taskCompleted: boolean;
  extendedProps?: any;
  // taskSatus?: (value: boolean) => void;
}

type EventCardProps = CardEventCardProps | TaskEventCardProps;

const EventCard: React.FC<EventCardProps> = (props) => {
  const dispatch = useDispatch();

  const {
    timeDuration,
    fullWidth = false,
    cardType,
    cardColor,
    eventName,
    extendedProps,
  } = props;

  const primaryHeight: number = 20;
  const boxHeight: number = timeDuration * primaryHeight;

  const [taskCompleted, setTaskCompleted] = useState(
    cardType === "task" ? (props as TaskEventCardProps).taskCompleted : false
  );
  const [cardStatus, setCardStatus] = useState<
    "pending" | "Accept" | "Reject" | "Maybe"
  >(cardType === "card" ? (props as CardEventCardProps).status : "pending");

  const handleEventResponse = async (responseType: string) => {
    try {
      if (!extendedProps?.cId || !extendedProps?.eId || !responseType) {
        return;
      }
      let forMatRes =
        responseType === "Accept"
          ? "accepted"
          : responseType === "Reject"
          ? "rejected"
          : responseType === "Maybe"
          ? "maybe"
          : null;

      if (forMatRes === null) {
        return;
      }

      const action = eventResponse__api(
        extendedProps?.cId,
        extendedProps?.eId,
        forMatRes,
        extendedProps?.ceType
      );
      await dispatch(action);
    } catch (err) {
      console.log(err);
    }
  };

  const updateTaskState = async () => {
    if (!extendedProps?.cId || !extendedProps?.eId) {
      return;
    }

    const action = updateEventTaskStatus__api(
      extendedProps?.cId,
      extendedProps?.eId
    );

    await dispatch(action);
  };

  return (
    <Stack sx={{ flexDirection: "row", gap: "3px" }}>
      {cardType === "card" &&
        (cardStatus === "pending" || cardStatus === "Maybe") && (
          <PendingEvent
            width="5px"
            cardStatus={cardStatus}
            onButtonClick={(value) => {
              handleEventResponse(value);
              setCardStatus(value);
            }}
          />
        )}
      {/* adjust this with real api data and , remove the state and render based on api response here  */}
      {/* {cardType === "card" &&
        (props.status === "pending" || props.status === "Maybe") && (
         <PendingEvent
            width="5px"
            cardStatus={cardStatus}
            onButtonClick={(value) => setCardStatus(value)}
          />
        )} */}
      <Stack
        sx={{
          height: `${boxHeight}px`,
          width: fullWidth ? "calc(100% - 10px)" : "140px",
          border: `0.5px solid ${cardColor}`,
          borderRadius: "2px",
          boxSizing: "border-box",
        }}
      >
        <Stack
          sx={{
            borderRadius: "2px",
            border: "0.5px solid white",
            flex: 1,
            boxSizing: "border-box",
            bgcolor: adjustHexColor(cardColor, 30),
            flexDirection: "row",
            alignItems: "start",
            padding: "0px 2px",
            paddingTop: boxHeight === primaryHeight ? "2px" : "4px",
            gap: "3px",
          }}
        >
          <span
            style={{
              flexShrink: 0,
              height: "14px",
              width: "14px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {cardType === "card" &&
              (props.iconName
                ? React.createElement(
                    getIconPopupCalendarIcons(props.iconName),
                    {
                      w: 14,
                      color: cardColor,
                    }
                  )
                : React.createElement(
                    getIconPopupCalendarIcons(
                      IconPopupCalendarIconsEnum.BuildingIcon
                    ),
                    {
                      w: 14,
                      color: cardColor,
                    }
                  ))}

            {cardType === "task" && (
              <StyledRadioButtonBlack
                radioSize={15}
                checked={taskCompleted}
                onClick={() => {
                  setTaskCompleted((pre) => !pre);
                  updateTaskState();
                }}
              />
            )}
          </span>
          <Stack
            sx={{
              boxSizing: "border-box",
              maxWidth: "calc(100% - 20px)",
              flexDirection: boxHeight === primaryHeight ? "row" : "column",
              alignItems: boxHeight === primaryHeight ? "center" : "start",
              gap: "2px",
            }}
          >
            <Stack
              sx={{
                boxSizing: "border-box",
                overflow: "scroll",
                textOverflow: "inherit",
                scrollbarWidth: "none",
                whiteSpace: "nowrap",
                width: boxHeight === primaryHeight ? "inherit" : "100%",
              }}
            >
              <Typography
                sx={{
                  font: "normal normal 400 11px/14px Source Serif Pro",
                  textDecoration:
                    cardType === "task" && taskCompleted
                      ? "line-through"
                      : "null",
                }}
              >
                {eventName}
              </Typography>
            </Stack>

            <Typography
              sx={{
                font: "normal normal 400 11px/14px Source Serif Pro",
                textDecoration:
                  cardType === "task" && taskCompleted
                    ? "line-through"
                    : "null",
                flexShrink: 0,
              }}
            >
              {extendedProps?.st
                ? convertTo12HourFormat(extendedProps.st)
                : "all"}{" "}
              {extendedProps?.et
                ? `to ${convertTo12HourFormat(extendedProps.et)} `
                : "day"}{" "}
              {primaryHeight === boxHeight ? "" : "PM"}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default EventCard;
