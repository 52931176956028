import React, { useEffect, useState } from "react";
import SavedView from "./SavedView";
import { Stack, Typography } from "@mui/material"; // Import Typography for text display
import { getAllSubComments__api } from "../../../../../../../redux";
import { useDispatch } from "react-redux";

type Props = {
  _id: string;
  getAllCommentsOfDocument?: any;
};

const ReplyManage = ({ _id, getAllCommentsOfDocument }: Props) => {
  const dispatch = useDispatch();
  const [allCommentData, setAllCommentData] = useState<any[]>([]); // Initialize as empty array
  const [noCommentsMessage, setNoCommentsMessage] = useState<string | null>(
    null
  ); // State for no comments message

  const getAllSubCommentsOfDocument = async () => {
    if (!_id) {
      return;
    }

    try {
      const action = getAllSubComments__api(_id, 1, 50, "asc");
      const allComments: any = await dispatch(action);

      if (allComments?.success) {
        if (
          allComments?.data &&
          Object?.keys(allComments?.data)?.length === 0
        ) {
          // If data is an empty object
          setNoCommentsMessage(allComments?.message); // Set message from response
          setAllCommentData([]); // Reset to empty array
        } else {
          // Assuming allComments.data is an array if there are comments
          setAllCommentData(allComments?.data || []); // Set state to comments or empty array
          setNoCommentsMessage(null); // Clear no comments message
        }
      } else {
        console.warn("Failed to fetch comments:", allComments);
        setNoCommentsMessage("Failed to fetch comments"); // Set error message
        setAllCommentData([]); // Reset to empty array
      }
    } catch (error) {
      console.error("Failed to fetch comments:", error);
      setNoCommentsMessage("Error fetching comments"); // Set error message
      setAllCommentData([]); // Reset to empty array
    }
  };

  useEffect(() => {
    getAllSubCommentsOfDocument();
  }, [getAllCommentsOfDocument]);

  return (
    <Stack mt={"20px"} direction={"column"} gap={2}>
      {allCommentData?.map((comment: any) => (
        <SavedView
          getAllCommentsOfDocument={getAllCommentsOfDocument}
          key={comment?._id}
          commentDatas={comment}
        />
      ))}
    </Stack>
  );
};

export default ReplyManage;
