export interface TravelTimeMenuListInterface {
  id: number;
  value: number;
}

export const TravelTimeMenuOptionsList120: TravelTimeMenuListInterface[] = [
  { id: 1, value: 15 },
  { id: 2, value: 30 },
  { id: 3, value: 60 },
  { id: 4, value: 120 },
];

export const TravelTimeMenuOptionsList150: TravelTimeMenuListInterface[] = [
  { id: 1, value: 15 },
  { id: 2, value: 30 },
  { id: 3, value: 45 },
  { id: 4, value: 60 },
  { id: 5, value: 80 },
  { id: 6, value: 120 },
  { id: 7, value: 150 },
];
