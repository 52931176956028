import React, { useEffect, useState } from "react";
import { DropdownWIconLarge } from "../DropdownWIconLarge";
import { TimeZonePng } from "../../../images";
import { getAllTimezonesFormatted } from "../../pages/Settings/Components/common";

interface TimeZoneCalendarDropdownProps {
  initialValue?: string;
  onChange?: (selectedOption: string) => void;
}

const TimeZoneCalendarDropdown: React.FC<TimeZoneCalendarDropdownProps> = ({
  // initialValue = "America/Argentina/Catamarca",
  initialValue,
  onChange,
}) => {
  const [timezones, setTimezones] = useState<string[]>([]);

  useEffect(() => {
    const fetchedTimezones = getAllTimezonesFormatted();
    setTimezones(
      fetchedTimezones?.map((timezone) => timezone?.name || timezone?.id)
    );
  }, []);
  return (
    <DropdownWIconLarge
      multiple={false}
      inputHeight={25}
      initialValue={initialValue}
      width="200px"
      icon={"chevron"}
      iconSrc={TimeZonePng}
      options={timezones}
      onChange={(value) => onChange && onChange(value)}
    />
  );
};

export default TimeZoneCalendarDropdown;
