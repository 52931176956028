import { CallIcon, ConnectedIcon, EmailIcon, LikeIcon, MoneyIcon, MultiselectIcon, PercentageIcon, RelationshipStrengthIcon, SelectIcon, TasksInvolvedIcon } from "../../../images";
import { AddElementListInterface, AddElementOptionValuesEnum } from "../ElementsOptionsList/ElementsOptionsListType";

export const AddElementDropdownOptionsList: AddElementListInterface[] = [
    {
        id: 7,
        icon: SelectIcon,
        value: AddElementOptionValuesEnum.SelectDropdown,
    },
    {
        id: 9,
        icon: MultiselectIcon,
        value: AddElementOptionValuesEnum.MultiselectLabels,
    },
    {
        id: 12,
        icon: ConnectedIcon,
        value: AddElementOptionValuesEnum.URL,
    },
    {
        id: 13,
        icon: EmailIcon,
        value: AddElementOptionValuesEnum.Email,
    },
    {
        id: 14,
        icon: CallIcon,
        value: AddElementOptionValuesEnum.Phone,
    },
    {
        id: 20,
        icon: RelationshipStrengthIcon,
        value: AddElementOptionValuesEnum.Rating,
    },
    {
        id: 21,
        icon: MoneyIcon,
        value: AddElementOptionValuesEnum.Money,
    },
    {
        id: 24,
        icon: TasksInvolvedIcon,
        value: AddElementOptionValuesEnum.RichText,
    },
    {
        id: 28,
        icon: LikeIcon,
        value: AddElementOptionValuesEnum.Voting,
    },
    {
        id: 29,
        icon: PercentageIcon,
        value: AddElementOptionValuesEnum.Percentage,
    },
]