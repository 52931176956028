import { IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { GreenDropdownIcon } from "../../../../../images";
import styles from "./SidebarMsgContainer.module.css";
import { useTranslation } from "react-i18next";

interface SidebarMsgContainerProps {
  label: string;
  viewAllButtonClick?: () => void;
  children?: React.ReactNode;
  isOpen?: boolean | undefined;
  viewAll?: boolean;
}

const SidebarMsgContainer: React.FC<SidebarMsgContainerProps> = ({
  children,
  label,
  viewAllButtonClick,
  isOpen = false,
  viewAll = true,
}) => {
  const { t } = useTranslation();
  const [isOpened, setIsOpened] = useState<boolean | undefined>(true);

  return (
    <Stack
      className={styles["sidebar-msg-container"]}
      style={{ maxHeight: !isOpened ? "19px" : "250px" }}
    >
      <Stack className={styles["sidebar-msg-container__header"]}>
        <Typography
          onClick={() => setIsOpened((value) => !value)}
          className={styles["sidebar-msg-container__label"]}
        >
          {label}
        </Typography>
        <IconButton
          className={styles["sidebar-msg-container__icon-button"]}
          disableRipple
          onClick={() => setIsOpened((value) => !value)}
        >
          <GreenDropdownIcon
            sx={{ height: "10px" }}
            open={isOpen && isOpened}
          />
        </IconButton>
        <Stack className={styles["sidebar-msg-container__divider"]} />
      </Stack>

      <Stack className={styles["sidebar-msg-container__content"]}>
        {children}
      </Stack>
      {isOpen && (
        <Stack className={styles["sidebar-msg-container__footer"]}>
          <Typography
            onClick={viewAllButtonClick}
            className={styles["sidebar-msg-container__view-all"]}
          >
            {isOpen && !viewAll
              ? t("MESSAGE.VIEW_ALL")
              : t("VIDEO_CENTER.MEETING.VIEW_LESS")}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default SidebarMsgContainer;
