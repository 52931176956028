import { Stack, Typography } from "@mui/material";
import React from "react";

interface NumberViewProps {
  orderNum: number;
}

const NumberView: React.FC<NumberViewProps> = ({ orderNum }) => {
  return (
    <Stack
      sx={{
        flexShrink: 0,
        height: "40px",
        width: "40px",
        border: "2px solid #E9EDF2",
        boxSizing: "border-box",
        borderRadius: "50px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        sx={{
          font: "normal normal 400 20px/25px Source Serif Pro",
          textAlign: "center",
        }}
      >
        {orderNum}
      </Typography>
    </Stack>
  );
};

export default NumberView;
