import { IconButton, IconButtonProps, Typography } from "@mui/material";
import React from "react";
import { GreenDropdownIcon } from "../../../../../../../images";
import { useTranslation } from "react-i18next";

interface BackButtonViewProps extends IconButtonProps {
  onClickButtonHandle: () => void;
}

const BackButtonView: React.FC<BackButtonViewProps> = ({ onClickButtonHandle, ...props }) => {
 
  const { t } = useTranslation();
  return (
    <IconButton
      onClick={onClickButtonHandle}
      disableRipple
      sx={{
        p: 0,
        flexDirection: "row",
        alignItems: "center",
        gap: "5px",
        borderRadius: 0,
        width: "fit-content",
      }}
      {...props}
    >
      <GreenDropdownIcon
        open
        sx={{ transform: "rotate(180deg)", height: "12px" }}
      />
      <Typography sx={{ font: "normal normal 300 15px/19px Source Serif Pro" }}>
        {t("CALENDAR.BACK_TO_ALL_BOOKING_LINK")}
      </Typography>
    </IconButton>
  );
};

export default BackButtonView;
