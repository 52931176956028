import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { ButtonSmall } from "../Buttons";
import { ButtonType, SmallButtonColor } from "../Buttons/AllButtonProps";
import { CheckboxMedium } from "../CheckboxMedium";
import { SingleDatePickerSmall } from "../SingleDatePickerSmall";
import styles from "./LockItemCloudStorage.module.css";

interface LockItemCloudStorageProps {
  initialLockState: boolean;
  initialLockUntil: string | null;
  onSave: (isLocked: boolean, lockedUntil: string | null) => void; // Callback to return the lock state
}

const LockItemCloudStorage: React.FC<LockItemCloudStorageProps> = ({
  initialLockState,
  initialLockUntil,
  onSave,
}) => {
  const [isLocked, setIsLocked] = useState(initialLockState); // Manage locking state
  const [lockUntilDate, setLockUntilDate] = useState<string | null>(
    initialLockUntil // Store as string for compatibility with date picker
  );

  // Handle checkbox change for locking indefinitely
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsLocked(e.target.checked);
    if (!e.target.checked) {
      setLockUntilDate(null); // Reset the date if locking is turned off
    }
  };

  // Handle date picker change
  const handleDateChange = (date: string | null) => {
    setLockUntilDate(date); // Update the selected lock until date
  };

  // Handle save button click
  const handleSave = () => {
    onSave(isLocked, lockUntilDate); // Call onSave with the current state
  };

  return (
    <Stack className={styles["lockItemCloudStorage"]}>
      {/* --- header of card [ card title] ------ */}
      <Stack className={styles["lockItemCloudStorage__header"]}>
        <Typography className={styles["lockItemCloudStorage__title"]}>
          Lock an item
        </Typography>
      </Stack>

      {/* -----------   body of card   ------------- */}
      <Stack className={styles["lockItemCloudStorage__body"]}>
        <Typography className={styles["lockItemCloudStorage__description"]}>
          Locking this item means only you can edit, view, or comment on it.
          Downloading this item will also no longer be possible until you unlock
          it.
        </Typography>

        {/* Indefinite Lock Checkbox */}
        <Stack className={styles["lockItemCloudStorage__checkboxContainer"]}>
          <CheckboxMedium
            boxSize={15}
            checked={isLocked} // Bind to state
            onChange={handleCheckboxChange} // Handle state update
          />
          <Typography className={styles["lockItemCloudStorage__checkboxLabel"]}>
            Lock indefinitely
          </Typography>
        </Stack>

        {/* Lock Until Date Checkbox */}
        <Stack className={styles["lockItemCloudStorage__checkboxWithDate"]}>
          <Stack
            id="objectivedatepicker"
            className={styles["lockItemCloudStorage__checkboxContainer"]}
          >
            <CheckboxMedium
              boxSize={15}
              checked={lockUntilDate !== null} // Check if a date is set
              onChange={handleCheckboxChange} // Handle checkbox change
            />
            <Typography
              className={styles["lockItemCloudStorage__checkboxLabel"]}
            >
              Lock until specific date
            </Typography>
          </Stack>
          <SingleDatePickerSmall
            initialDate={lockUntilDate || undefined} // Bind to state
            onSelect={handleDateChange} // Capture selected date as string
          />
        </Stack>
      </Stack>

      {/* ---- buttons here ---- */}
      <Stack className={styles["lockItemCloudStorage__buttonContainer"]}>
        <ButtonSmall
          label="Cancel"
          types={ButtonType.Button}
          colorVarient={SmallButtonColor.Transparent}
        />
        <ButtonSmall
          label="Save"
          types={ButtonType.Button}
          colorVarient={SmallButtonColor.Orange}
          onClick={handleSave} // Handle Save click
        />
      </Stack>
    </Stack>
  );
};

export default LockItemCloudStorage;
