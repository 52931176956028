import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import { adjustHexColor } from "../../../../../Utils";

type InlineButtonProps = {
  label: string;
  onBtnClick: () => void;
  isSelected: boolean;
};
const InlineButton: React.FC<InlineButtonProps> = ({
  isSelected,
  label,
  onBtnClick,
}) => {
  return (
    <Button
      onClick={onBtnClick}
      variant="text"
      sx={{
        height: "25px",
        padding: "0px 10px",
        borderRadius: "5px",
        bgcolor: isSelected ? adjustHexColor("#00767B", 25) : "transparent",
        textTransform: "none",
        ":hover": {
          bgcolor: isSelected
            ? adjustHexColor("#00767B", 25)
            : adjustHexColor("#00767B", 15),
        },
      }}
    >
      <Typography
        sx={{
          font: "normal normal 600 15px/19px Source Serif Pro",
          color: "black",
        }}
      >
        {label}
      </Typography>
    </Button>
  );
};

interface TabButtonProps {
  selectedPage: "trax" | "performance";
  btnSelect: (value: "trax" | "performance") => void;
}
const TabButton: React.FC<TabButtonProps> = ({ selectedPage, btnSelect }) => {
  return (
    <Stack
      sx={{
        height: "40px",
        flexShrink: 0,
        boxSizing: "border-box",
        bgcolor: adjustHexColor("#E9EDF2", 35),
        flexDirection: "row",
        alignItems: "center",
        gap: "25px",
        paddingLeft: "15px",
        borderRadius: "0px 0px 5px 5px",
      }}
    >
      <InlineButton
        label="Trax"
        onBtnClick={() => selectedPage !== "trax" && btnSelect("trax")}
        isSelected={selectedPage === "trax"}
      />
      <InlineButton
        label="Performance"
        onBtnClick={() =>
          selectedPage !== "performance" && btnSelect("performance")
        }
        isSelected={selectedPage === "performance"}
      />
    </Stack>
  );
};

export default TabButton;
