import { Box, Divider, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import g from "./General.module.css";
import {
  AvatarLarge,
  BgColorCode,
  ButtonSmall,
  DropdownLarge,
  DropdownLargeSingle,
  DropdownWIconLarge,
  IconButtonLong,
  MediumInputIconBox,
} from "../../../../common";
import {
  LocationPng,
  Phone50Icon,
  Phone64Icon,
  TimeZonePng,
} from "../../../../../images";
import {
  ButtonType,
  SmallButtonColor,
} from "../../../../common/Buttons/AllButtonProps";
import {
  getCurrentDateFormats,
  getCurrentTimeFormats,
} from "../../../../../Utils";
import { getAllCountries, getAllTimezonesFormatted } from "../common";
import { getDepartmentList, getUser } from "../../../../../Utils/authService";
import {
  getAllDepartment,
  updateUserProfile__api,
  uploadFile__api,
} from "../../../../../redux";
import { useDispatch } from "react-redux";
import {
  Department,
  UserProfileData,
  initialUserProfileData,
} from "./generalTypes";
import { useTranslation } from "react-i18next";

type GeneralProps = {};

const WorkingLocationOptions = [
  "In Office",
  "Remote",
  "Out Sick",
  "On Break",
  "Out of Office",
  "Working Outside",
  "Personal Time",
];

const LanguageCodeMap = {
  en: "English",
  jp: "日本語",
  es: "Español",
  fr: "Français",
  ar: "عربي",
  it: "Italiano",
  pt: "Português",
};

const LanguageOptions = Object.keys(LanguageCodeMap); // ["en", "jp", "es", "fr", "ar", "it", "pt"]

const timeFormat = getCurrentTimeFormats();
const dateFormat = getCurrentDateFormats();

const General: React.FC<GeneralProps> = () => {
  const { i18n } = useTranslation();
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };
  const dispatch = useDispatch();
  const [userProfileData, setUserProfileData] = useState<UserProfileData>(
    initialUserProfileData
  );

  const userData: any = getUser();
  const [gAllDep, setGAllDep] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Assuming getDepartmentList() returns a promise that resolves to an array
        const departments = await getDepartmentList();
        if (departments.length === 0) {
          await getALlDepartments();
        }
        setGAllDep(departments);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    fetchData();
    // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []); // Empty dependency array means this effect runs only once after the initial render

  useEffect(() => {
    // Transform and set the data from userData
    const transformedUserData: any = {
      firstName: userData?.data?.firstName || "",
      lastName: userData?.data?.lastName || "",
      avatarPic: userData?.data?.avatar, // You can handle avatar upload separately
      avatarDefault: userData?.data?.avatar || "",
      emailAddress: userData?.data?.email || "",
      language: userData?.data?.language || "en", // Default to English if not provided
      jobTitle: userData?.data?.jobTitle || "",
      department: userData?.data?.departmentIds || [],
      phoneNumber: userData?.data?.phoneNumber || "",
      mobileNumber: userData?.data?.mobileNumber || "",
      workingLocation: userData?.data?.workingLocation, // Assuming it needs to be set manually
      location: userData?.data?.location || "",
      timeZone: userData?.data?.timezone || "GMT", // Default to GMT
      timeFormat: userData?.data?.timeFormat, // Default, this can be changed in the UI
      dateFormat: userData?.data?.dateFormat, // Default date format
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    };
    setFile(userData?.data?.avatar);
    // Set the transformed data into state
    setUserProfileData(transformedUserData);
    // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const getALlDepartments = async () => {
    try {
      // Assuming getAllDepartment() is an async action creator that dispatches an action
      await dispatch(getAllDepartment());
      // After dispatching, you might want to update gAllDep state again if necessary
      const updatedDepartments = await getDepartmentList();
      setGAllDep(updatedDepartments);
    } catch (error) {
      console.error("Error getting all departments:", error);
    }
  };
  const [file, setFile] = useState<string | undefined>(
    userProfileData?.avatarDefault
  );
  const [locations, setLocations] = useState<string[]>([]);
  const [timezones, setTimezones] = useState<string[]>([]);

  useEffect(() => {
    const fetchedLocations = getAllCountries();
    const fetchedTimezones = getAllTimezonesFormatted();
    setLocations(fetchedLocations?.map((location) => location?.name));
    setTimezones(
      fetchedTimezones?.map((timezone) => timezone?.name || timezone?.id)
    );
  }, []);

  const handleUpdateUserProfile = async (data: UserProfileData) => {
    try {
      // Transform timeFormat
      const transformedTimeFormat = data?.timeFormat?.includes("AM | PM")
        ? "12-hour"
        : "24-hour";

      // Transform dateFormat
      const transformedDateFormat = data?.dateFormat?.includes(",")
        ? "MM-DD-YYYY"
        : "DD-MM-YYYY";

      // Check if avatarPic is a file and needs to be uploaded
      let avatarPicUrl: any = data?.avatarPic;
      if (data?.avatarPic && data?.avatarPic instanceof File) {
        // Upload file if it's a File object
        avatarPicUrl = await handleFileUpload(data.avatarPic);
      }

      // Prepare payload for the API
      const payload: any = {
        firstName: data?.firstName,
        lastName: data?.lastName,
        avatarPic: avatarPicUrl, // Use the URL if file was uploaded
        avatarDefault: data?.avatarDefault,
        emailAddress: data?.emailAddress,
        language: data?.language,
        jobTitle: data?.jobTitle,
        phoneNumber: data?.phoneNumber,
        mobileNumber: data?.mobileNumber,
        workingLocation: data?.workingLocation,
        location: data?.location,
        timeZone: data?.timeZone,
        timeFormat: transformedTimeFormat, // Transformed timeFormat
        dateFormat: transformedDateFormat, // Transformed dateFormat
      };

      // Dispatch the update profile API action
      const action = updateUserProfile__api(payload);
      await dispatch(action);

      console.log("Profile updated successfully");
    } catch (error) {
      console.error("Error updating user profile:", error);
    }
  };

  const handleFileUpload = async (file: any) => {
    if (file instanceof File || file instanceof Blob) {
      try {
        const formData = new FormData();
        formData.append("files", file);

        const action = uploadFile__api(formData);
        let result = "";

        try {
          const response: any = await dispatch(action);
          if (response?.success && response?.data) {
            result = response?.data?.[0]?.path; // Adjust based on your API response
          }
        } catch (e) {
          console.log(e, "Error uploading");
          return;
        }

        const mediaUrl = result || ""; // Set media URL based on API response

        if (mediaUrl) {
          return mediaUrl;
        }
      } catch (error) {
        alert("Error uploading media");
        return false;
      }
    } else {
      console.log("Unknown input type");
      return false;
    }
  };

  // Handler to update form data
  const handleInputChange = async (name: string, value: string) => {
    // Update the state with the new value
    setUserProfileData((prevState) => {
      const updatedData = {
        ...prevState,
        [name]: value,
      };

      // Immediately send updated value to API after the state is updated
      handleUpdateUserProfile(updatedData);

      return updatedData;
    });

    // If the input is for the language field, change language dynamically
    if (name === "language") {
      changeLanguage(value === "jp" ? "ja" : value);
    }
  };

  // Example handler for file input (avatarPic)
  const handleFileChange = (file: File) => {
    if (!file) {
      return;
    }

    // Preserve the data below
    const fileUrl = URL.createObjectURL(file);
    setFile(fileUrl); // Update file state with the created object URL
    setUserProfileData({
      ...userProfileData,
      avatarPic: file || null, // Set the selected file in user profile data
    });

    // Call handleUpdateUserProfile after file change to trigger the profile update
    handleUpdateUserProfile({
      ...userProfileData,
      avatarPic: file || userProfileData.avatarPic, // Ensure avatarPic is updated
    });
  };

  // Example handler for department select
  const handleDepartmentChange = (departments: Department[]) => {
    setUserProfileData({
      ...userProfileData,
      department: departments,
    });
  };

  // Define array of field configurations
  const fieldsConfig_One = [
    {
      label: "First Name",
      name: "firstName",
      value: userProfileData?.firstName,
    },
    { label: "Last Name", name: "lastName", value: userProfileData?.lastName },
  ];

  const fieldsConfig_Two = [
    {
      label: "Email Address",
      name: "emailAddress",
      value: userProfileData?.emailAddress,
    },
    {
      label: "Job Title",
      name: "jobTitle",
      value: userProfileData?.jobTitle,
    },
  ];

  const fieldsConfig_Three = [
    {
      label: "Working Location",
      options: WorkingLocationOptions,
      componentHeader: "Working location",
      name: "workingLocation",
      value: userProfileData?.workingLocation,
    },
    {
      label: "Language",
      options: LanguageOptions,
      componentHeader: "Language",
      name: "language",
      value: userProfileData?.language,
    },
  ];

  const fieldsConfig_Four = [
    {
      label: "Phone number",
      value: userProfileData?.phoneNumber,
      name: "phoneNumber",
      icon: <Phone50Icon />,
    },
    {
      label: "Mobile number",
      value: userProfileData?.mobileNumber,
      name: "mobileNumber",
      icon: <Phone64Icon />,
    },
  ];

  const fieldConfig_Five = [
    {
      label: "Location",
      options: locations,
      iconSrc: LocationPng,
      name: "location",
      value: userProfileData?.location || "No location",
    },
    {
      label: "Timezone",
      options: timezones,
      iconSrc: TimeZonePng,
      name: "timeZone",
      value: userProfileData?.timeZone,
    },
  ];

  const fieldConfig_Six = [
    {
      label: "Time format",
      options: timeFormat,
      componentHeader: "Time format",
      name: "timeFormat",
      value: userProfileData?.timeFormat,
    },
    {
      label: "Date format",
      options: dateFormat,
      componentHeader: "Date format",
      name: "dateFormat",
      value: userProfileData?.dateFormat,
    },
  ];

  const fieldConfig_Seven = [
    {
      label: "Current password",
      name: "currentPassword",
      value: userProfileData.currentPassword,
    },
    {
      label: "New password",
      name: "newPassword",
      value: userProfileData.newPassword,
    },
    {
      label: "Confirm new password",
      name: "confirmNewPassword",
      value: userProfileData.confirmNewPassword,
    },
  ];

  return (
    <Box sx={{ padding: "20px 25px 25px 25px" }}>
      <div>
        <Typography
          className={g["gs-container-header"]}
          children={"General Settings"}
        />
      </div>
      <Stack>
        <Stack
          padding={"0 15px 0 15px"}
          direction={"row"}
          mt={"20px"}
          gap={"10px"}
          alignItems={"flex-start"}
          justifyContent={"space-between"}
        >
          {/* Section 1 -- */}
          <Stack mt={"15px"}>
            {/* Avatar Container */}
            <AvatarLarge
              imgSrc={file}
              size={80}
              text="AA"
              bgColorCode={BgColorCode.Purple}
            />
            <div>
              <IconButtonLong onFileSelect={handleFileChange} />
            </div>
          </Stack>

          {/* Section 2 -- */}
          <Stack gap={"15px"} padding={"0 30px 0 15px"}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={"10px"}
            >
              {fieldsConfig_One.map((field) => {
                return (
                  <MediumInputIconBox
                    label={field.label}
                    key={field.label}
                    customePass={true}
                    value={field.value || null}
                    onChange={(e) => {
                      handleInputChange(
                        field.name,
                        typeof e === "string" ? e : e.target.value
                      );
                    }}
                    name={field.name}
                  />
                );
              })}
            </Stack>

            {fieldsConfig_Two.map((field) => {
              return (
                <MediumInputIconBox
                  label={field.label}
                  key={field.label}
                  customePass={true}
                  value={field.value}
                  sx={{ width: "100%" }}
                  onChange={(e) => {
                    handleInputChange(
                      field.name,
                      typeof e === "string" ? e : e.target.value
                    );
                  }}
                  name={field.name}
                />
              );
            })}
          </Stack>

          {/* Section 3 -- */}
          <Stack gap={"15px"} padding={"0 0 0 15px"}>
            {fieldsConfig_Three?.map((field) => {
              return (
                <DropdownLargeSingle
                  label={field.label}
                  options={field.options}
                  key={field.label}
                  componentHeader={field.componentHeader}
                  borderColor={"lightBlue"}
                  onChange={(e) => {
                    handleInputChange(field.name, typeof e && e);
                  }}
                  initialSelectedOptions={[field?.value]}
                />
              );
            })}

            <DropdownLarge
              label="Department"
              options={gAllDep || []}
              componentHeader={"Operations"}
              borderColor={"lightBlue"}
              onChange={handleDepartmentChange}
              multiple={true} // Ensure multiple selection is enabled
              initialSelectedOptions={userProfileData?.department}
            />
          </Stack>
        </Stack>
        <Stack mt={"15px"} mb={"15px"}>
          <Divider />
        </Stack>

        <Stack
          direction={"row"}
          alignItems={"flex-start"}
          justifyContent={"space-between"}
          padding={"0 15px 0 15px"}
        >
          <Stack gap={"15px"}>
            {fieldsConfig_Four.map((field) => {
              return (
                <MediumInputIconBox
                  label={field.label}
                  key={field.label}
                  value={field.value}
                  onChange={(e) => {
                    handleInputChange(
                      field.name,
                      typeof e === "string" ? e : e.target.value
                    );
                  }}
                  icon={field.icon}
                  name={field.name}
                  customePass={true}
                />
              );
            })}
          </Stack>
          <Stack gap={"15px"}>
            {fieldConfig_Five.map((field) => {
              return (
                <DropdownWIconLarge
                  label={field.label}
                  key={field.label}
                  options={field.options}
                  iconSrc={field.iconSrc}
                  onChange={(e) => {
                    handleInputChange(field.name, typeof e && e);
                  }}
                />
              );
            })}
          </Stack>
          <Stack gap={"15px"}>
            {fieldConfig_Six.map((field) => {
              return (
                <DropdownLargeSingle
                  label={field.label}
                  key={field.label}
                  options={field.options}
                  componentHeader={field.componentHeader}
                  borderColor={"lightBlue"}
                  onChange={(e) => {
                    handleInputChange(field.name, typeof e && e);
                  }}
                  initialSelectedOptions={[field?.value]}
                />
              );
            })}
          </Stack>
        </Stack>

        <Stack mt={"15px"} mb={"15px"}>
          <Divider />
        </Stack>

        <Stack
          gap={"15px"}
          direction={"row"}
          padding={"0 15px 0 15px"}
          alignItems={"center"}
          justifyContent={"flex-start"}
        >
          {fieldConfig_Seven.map((field) => {
            return (
              <MediumInputIconBox
                label={field.label}
                key={field.label}
                customePass={true}
                value={field.value || null}
                onChange={(e) => {
                  handleInputChange(
                    field.name,
                    typeof e === "string" ? e : e.target.value
                  );
                }}
                name={field?.name}
              />
            );
          })}

          <ButtonSmall
            colorVarient={SmallButtonColor.Orange}
            label={"Save"}
            types={ButtonType.Button}
          />
        </Stack>

        <Stack mt={"15px"} padding={"0 15px 0 15px"}>
          <Typography className={g.fpr}>
            Forgot password? Reset via email
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default General;
