import { Stack, SxProps, Typography } from "@mui/material";
import React from "react";

interface BadgeAreaViewProps {
  label: string;
  children: React.ReactNode;
  sx?: SxProps;
}

const BadgeAreaView: React.FC<BadgeAreaViewProps> = ({
  children,
  label,
  sx,
}) => {
  return (
    <Stack sx={{ gap: "14px", ...sx }}>
      <Typography sx={{ font: "normal normal 400 15px/19px Source Serif Pro" }}>
        {label}
      </Typography>
      <Stack sx={{ flexDirection: "row", flexWrap: "wrap", gap: "5px" }}>
        {children}
      </Stack>
    </Stack>
  );
};

export default BadgeAreaView;
