import React, { useEffect, useRef, useState } from "react";
import { CrmBoardCard, CrmBoardCardProps } from "../../components";
import { IconButton, Stack, Typography } from "@mui/material";
import g from "./DealStageMap.module.css";
import { AddCalPlusIcon, DragIcon } from "../../../../../images";
import { adjustHexColor } from "../../../../../Utils";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
// Define the type for each item, which will include the component type and props
type ColumnItem<T> = {
  id: number | string;
  component: React.ComponentType<T>;
  props: T;
};

// Define the type for each column
type Column = {
  id: number | string;
  name: string;
  color: string;
  items: ColumnItem<CrmBoardCardProps>[];
};

let pngImage =
  "https://images.contentstack.io/v3/assets/bltd14fd2a03236233f/bltab28bd9a758b3f81/60d617644763af56bad5a0d5/download";

// Sample initial columns with their respective components and props
const initialColumns: Column[] = [
  {
    id: 1,
    name: "No stage",
    color: "white",
    items: [
      {
        id: 2,
        component: CrmBoardCard,
        props: {
          iconSrc: pngImage, // temporary
          cardHeader: "Task 1",
          description: "Description of Task 1",
          funding: "1000",
          timeStamp: "2024-07-21",
        },
      },
      {
        id: 3,
        component: CrmBoardCard,
        props: {
          iconSrc: pngImage,
          cardHeader: "Task 2",
          description: "Description of Task 2",
          funding: "2000",
          timeStamp: "2024-07-22",
        },
      },
    ],
  },
  {
    id: 4,
    name: "Lead",
    color: "#007BFF",
    items: [
      {
        id: 5,
        component: CrmBoardCard,
        props: {
          iconSrc: pngImage,
          cardHeader: "Task 3",
          description: "Description of Task 3",
          funding: "3000",
          timeStamp: "2024-07-23",
        },
      },
    ],
  },
  {
    id: 6,
    name: "In progress",
    color: "#FFA500",
    items: [
      {
        id: 7,
        component: CrmBoardCard,
        props: {
          iconSrc: pngImage,
          cardHeader: "Task 4",
          description: "Description of Task 4",
          funding: "4000",
          timeStamp: "2024-07-24",
        },
      },
      {
        id: 8,
        component: CrmBoardCard,
        props: {
          iconSrc: pngImage,
          cardHeader: "Task 5",
          description: "Description of Task 5",
          funding: "5000",
          timeStamp: "2024-07-25",
        },
      },
    ],
  },

  {
    id: 9,
    name: "Bought in",
    color: "#59D0D5",
    items: [
      {
        id: 10,
        component: CrmBoardCard,
        props: {
          iconSrc: pngImage,
          cardHeader: "Task 6",
          description: "Description of Task 4",
          funding: "4000",
          timeStamp: "2024-07-24",
        },
      },
      {
        id: 11,
        component: CrmBoardCard,
        props: {
          iconSrc: pngImage,
          cardHeader: "Task 7",
          description: "Description of Task 5",
          funding: "5000",
          timeStamp: "2024-07-25",
        },
      },
    ],
  },

  {
    id: 12,
    name: "Lost",
    color: "#DC3545",
    items: [
      {
        id: 13,
        component: CrmBoardCard,
        props: {
          iconSrc: pngImage,
          cardHeader: "Task 8",
          description: "Description of Task 4",
          funding: "4000",
          timeStamp: "2024-07-24",
        },
      },
      {
        id: 14,
        component: CrmBoardCard,
        props: {
          iconSrc: pngImage,
          cardHeader: "Task 9",
          description: "Description of Task 5",
          funding: "5000",
          timeStamp: "2024-07-25",
        },
      },
    ],
  },
];

// Utility function to generate random color
const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

// Utility function to generate random name
const getRandomName = () => {
  const names = ["Planning", "Review", "Testing", "Deployment", "Research"];
  return names[Math.floor(Math.random() * names.length)];
};

const ItemRenderer = <T extends object>({
  component: Component,
  props,
  color, // Add additional props here
}: ColumnItem<T> & { color?: string }) => {
  return <Component {...props} bgcolor={color} />;
};

type Props = {};

const ColumnHeaderRenderer = ({
  color,
  name,
}: {
  color: string;
  name: string;
}) => {
  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"flex-start"}
        gap={0.5}
      >
        <Stack
          sx={{
            width: "10px",
            height: "10px",
            backgroundColor: color,
            borderRadius: "50%",
          }}
        >
          {" "}
          {/* component for color badge */}{" "}
        </Stack>
        <Stack>
          {" "}
          <Typography className={g.deal__header__title}>{name}</Typography>{" "}
        </Stack>
      </Stack>
    </>
  );
};

const DealStageMap: React.FC<Props> = () => {
  const crmState = useSelector((state: RootState) => state && state?.crm);
  const { dealList } = crmState || {};
  const containerRef = useRef<HTMLDivElement>(null);
  const [columns, setColumns] = useState<Column[]>([]);

  const getColumnsFromDealList = (deals: any[]): Column[] => {
    const stageMap: Record<string, Column> = {};

    deals?.forEach((deal, index) => {
      const {
        dealStage = "No stage",
        dealName,
        dealValue,
        createdAt,
        dealIcon,
      } = deal;

      if (!stageMap?.[dealStage]) {
        stageMap[dealStage] = {
          id: dealStage,
          name: dealStage,
          color: getRandomColor(),
          items: [],
        };
      }

      stageMap[dealStage]?.items?.push({
        id: index,
        component: CrmBoardCard,
        props: {
          iconSrc: pngImage || dealIcon,
          cardHeader: dealName || "No Title",
          description: deal?.description || "N/A",
          funding: dealValue ? `$${dealValue?.toLocaleString()}` : "N/A",
          timeStamp: `${new Date(createdAt)?.toLocaleString()}`,
        },
      });
    });

    return Object?.values(stageMap);
  };

  // UseEffect to monitor changes in dealList
  useEffect(() => {
    if (dealList) {
      const transformedColumns = getColumnsFromDealList(dealList);
      setColumns(transformedColumns);
    }
  }, [dealList]);
  const addColumn = () => {
    const newColumn: Column = {
      id: columns.length + 1,
      name: getRandomName(),
      color: getRandomColor(),
      items: [],
    };
    setColumns([...columns, newColumn]);
  };

  const handleDrop = (
    dragIndex: number,
    dropIndex: number,
    draggedItemId: number,
    dropItemId: number | null | any,
    position: "above" | "below"
  ) => {
    const newStacks = [...columns];
    const sourceStack = newStacks[dragIndex];
    const targetStack = newStacks[dropIndex];
    const itemIndex = sourceStack.items.findIndex(
      (item) => item.id === draggedItemId
    );

    if (itemIndex > -1) {
      const [item] = sourceStack.items.splice(itemIndex, 1);
      if (dropItemId !== null) {
        const dropItemIndex = targetStack.items.findIndex(
          (item) => item.id === dropItemId
        );
        const indexAdjustment = position === "above" ? 0 : 1;
        targetStack.items.splice(dropItemIndex + indexAdjustment, 0, item);
      } else {
        // If no items or dropItemId is null, push to the list
        targetStack.items.push(item);
      }
      setColumns(newStacks);
    }
  };

  const handleColumnDrop = (dragIndex: number, dropIndex: number) => {
    const newStacks = [...columns];
    const [movedColumn] = newStacks.splice(dragIndex, 1);
    newStacks.splice(dropIndex, 0, movedColumn);
    setColumns(newStacks);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    const container = containerRef.current;
    if (container) {
      const rect = container.getBoundingClientRect();
      const scrollWidth = container.scrollWidth - container.clientWidth;

      const leftThreshold = 100;
      const rightThreshold = rect.width - 100;

      const mouseX = event.clientX - rect.left;

      if (mouseX < leftThreshold && container.scrollLeft > 0) {
        container.scrollLeft -= 20; // Adjust speed as necessary
      } else if (
        mouseX > rightThreshold &&
        container.scrollLeft < scrollWidth
      ) {
        container.scrollLeft += 20; // Adjust speed as necessary
      }
    }
  };

  return (
    <Stack
      ref={containerRef}
      direction={"row"}
      alignItems={"flex-start"}
      gap={0}
      justifyContent={"flex-start"}
      sx={{
        maxHeight: "45rem",
        minHeight: "45rem",
        overflow: "hidden",
        overflowX: "hidden",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        "-ms-overflow-style": "none", // IE and Edge
        "scrollbar-width": "none", // Firefox
      }}
      onDragOver={handleDragOver}
    >
      {columns?.map((column, columnIndex) => (
        <Stack
          key={column.id}
          width={"255px"}
          height="100%"
          onDragOver={(event) => event.preventDefault()}
          onDrop={(event) => {
            event.preventDefault();
            const { dragIndex, type, itemId } = JSON.parse(
              event.dataTransfer.getData("application/json")
            );
            if (type === "column") {
              handleColumnDrop(dragIndex, columnIndex);
            } else {
              const rect = event.currentTarget.getBoundingClientRect();
              const halfway = rect.top + rect.height / 2;
              const position = event.clientY < halfway ? "above" : "below";
              handleDrop(
                dragIndex,
                columnIndex,
                itemId,
                column?.items[0]?.id,
                position
              );
            }
          }}
          style={{
            //   margin: "10px",
            cursor: "move",
          }}
        >
          <Stack
            onDragStart={(event: React.DragEvent<HTMLDivElement>) => {
              event.stopPropagation();
              event.dataTransfer.setData(
                "application/json",
                JSON.stringify({ dragIndex: columnIndex, type: "column" })
              );
              event.dataTransfer.effectAllowed = "move";
            }}
            sx={{
              bgcolor: adjustHexColor(column.color, 8),
              height: "40px",
              // borderRadius: "5px 5px 0px 0px",
              // padding: "0px 5px 0px 10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignitems: "center",
              cursor: "move", // Make sure this is draggable
            }}
            draggable="true"
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            className={g.column__header___scraping}
            minWidth={"250px"}
          >
            <ColumnHeaderRenderer name={column.name} color={column.color} />
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              gap={0.5}
            >
              <IconButton onClick={addColumn}>
                {" "}
                <AddCalPlusIcon w={13} />{" "}
              </IconButton>
              <IconButton>
                <DragIcon />
              </IconButton>
            </Stack>
          </Stack>
          <Stack p={0.5} direction={"column"} gap={1}>
            {column.items.map((item, itemIndex) => (
              <div
                key={item.id}
                draggable
                onDragStart={(event: React.DragEvent<HTMLDivElement>) => {
                  event.dataTransfer.setData(
                    "application/json",
                    JSON.stringify({ dragIndex: columnIndex, itemId: item.id })
                  );
                  event.dataTransfer.effectAllowed = "move";
                }}
                onDragOver={(event: React.DragEvent<HTMLDivElement>) => {
                  event.preventDefault();
                  const target = event.currentTarget;
                  const rect = target.getBoundingClientRect();
                  const halfway = rect.top + rect.height / 2;
                  if (event.clientY < halfway) {
                    target.style.borderTop = "3px solid red"; // Indicates drop above
                    target.style.borderBottom = "";
                  } else {
                    target.style.borderTop = "";
                    target.style.borderBottom = "3px solid green"; // Indicates drop below
                  }
                }}
                onDragLeave={(event: React.DragEvent<HTMLDivElement>) => {
                  event.currentTarget.style.borderTop = "";
                  event.currentTarget.style.borderBottom = "";
                }}
                onDrop={(event: React.DragEvent<HTMLDivElement>) => {
                  event.preventDefault();
                  const { dragIndex, itemId: draggedItemId } = JSON.parse(
                    event.dataTransfer.getData("application/json")
                  );
                  const target = event.currentTarget;
                  const rect = target.getBoundingClientRect();
                  const halfway = rect.top + rect.height / 2;
                  const position = event.clientY < halfway ? "above" : "below";

                  handleDrop(
                    dragIndex,
                    columnIndex,
                    draggedItemId,
                    item.id,
                    position
                  );

                  target.style.borderTop = "";
                  target.style.borderBottom = "";
                }}
              >
                <ItemRenderer
                  {...item}
                  color={adjustHexColor(column.color, 5)}
                />
              </div>
            ))}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export default DealStageMap;
