import { ButtonProps, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../Utils";
import { TvRedIcon } from "../../../../../images";
import { useTranslation } from "react-i18next";

interface ShareScreenButtonProps extends ButtonProps {}

const ShareScreenButton: React.FC<ShareScreenButtonProps> = ({ ...props }) => {
    const {t}=useTranslation()
  return (
    <Tooltip title={t("VIDEO_CENTER.MEETING.SHARE_SCREEN")} placement="top" arrow disableInteractive>
      <IconButton
        sx={{
          p: 0,
          height: "50px",
          width: "50px",
          boxSizing: "border-box",
          border: "1px solid white",
          boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.16)",
          bgcolor: adjustHexColor("#E9EDF2", 50),
          ":hover": {
            bgcolor: adjustHexColor("#E9EDF2", 50 + 10),
          },
        }}
        {...props}
      >
        <TvRedIcon w={25} color="black" />
      </IconButton>
    </Tooltip>
  );
};

export default ShareScreenButton;
