import { Avatar, Popper, Stack, Typography, Paper } from "@mui/material";
import React, { useState } from "react";
import { CrmInfoPopup } from "../CrmInfoPopup";
import { useNavigate } from "react-router-dom";

type CNameProps = {
  value: string;
  br?: string;
  hPopover?: boolean;
  targetOpen?: boolean;
  targetLocation?: string;
  p?: any;
  fortarget?: any;
};

export const CName: React.FC<CNameProps> = ({
  value,
  br = "50%",
  hPopover = false,
  targetOpen = false,
  targetLocation = "people",
  p,
  fortarget,
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const getPopperPlacement: any = () => {
    return "bottom-start"; // Adjust placement based on preference, e.g., "bottom-start", "right-start", etc.
  };

  const isValidObjectId = (id: string) => {
    const objectIdPattern = /^[0-9a-fA-F]{24}$/;
    return objectIdPattern?.test(id);
  };

  const handleNavigate = (id: string) => {
    const emailQuery = `?q=${p?.data?.allData?.email}`;
    const baseUrl = `/CRM/${targetLocation}`;

    if (isValidObjectId(id) && targetOpen) {
      if (targetLocation === "people") {
        navigate(`${baseUrl}/${id}${emailQuery}`);
      } else {
        navigate(`${baseUrl}/${id}`);
      }
    }

    if (isValidObjectId(p?.data?.eId) && targetOpen) {
      if (targetLocation === "people") {
        navigate(`${baseUrl}/${p?.data?.eId}${emailQuery}`);
      } else {
        navigate(`${baseUrl}/${p?.data?.eId}`);
      }
    }
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      gap={1}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        cursor: "pointer",
        position: "relative",
      }}
    >
      <Avatar
        src={
          fortarget === "user"
            ? p?.data?.allData?.image
            : p?.data?.allData?.businessId?.businessLogo ||
              p?.data?.allData?.businessLogo ||
              p?.data?.dealIcon
        }
        alt={
          fortarget === "user"
            ? `${p?.data?.allData?.firstName} ${p?.data?.allData?.lastName}`
            : p?.data?.allData?.businessName || ""
        }
        sx={{
          width: 18,
          height: 18,
          borderRadius: br,
          objectFit: "cover",
        }}
      />

      <Typography
        onClick={() =>
          handleNavigate(`${p?.data?.dataIDNumber || p?.data?._id}`)
        }
        sx={{
          fontFamily: "Source Serif Pro, serif",
          fontSize: "14px",
          lineHeight: "20px",
          fontWeight: "normal",
          transition: "font-weight 0.3s",
          "&:hover": {
            fontWeight: "bold",
          },
        }}
      >
        {value}
      </Typography>

      <Popper
        open={Boolean(anchorEl) && hPopover}
        anchorEl={anchorEl}
        placement={getPopperPlacement()}
        onMouseLeave={handleMouseLeave}
        sx={{
          zIndex: 10,
        }}
      >
        <Paper sx={{ borderRadius: "5px" }}>
          <CrmInfoPopup
            companyData={{
              description:
                p?.data?.allData?.description ||
                p?.data?.allData?.businessId?.description ||
                "No description",
              location:
                p?.data?.allData?.primaryLocation ||
                p?.data?.allData?.businessId?.primaryLocation ||
                "No location specified",
              name:
                fortarget === "user"
                  ? `${p?.data?.allData?.firstName} ${p?.data?.allData?.lastName}`
                  : p?.data?.company?.companyName || "",
              website:
                p?.data?.allData?.domains?.[0] ||
                p?.data?.allData?.businessId?.domains?.[0] ||
                "No Website specified",
              icon:
                fortarget === "user"
                  ? p?.data?.allData?.image
                  : p?.data?.allData?.businessId?.businessLogo ||
                    p?.data?.allData?.businessLogo,
              tags: p?.data?.categories || [],
            }}
          />
        </Paper>
      </Popper>
    </Stack>
  );
};
