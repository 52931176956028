import { TextareaAutosize } from "@mui/material";
import React, { useState } from "react";

interface EditableTextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  /**
   * Initial text value for the textarea.
   */
  text?: string;

  /**
   * Callback function that is called when the text changes.
   * @param newText The new text value.
   */
  onTextChange?: (newText: string) => void;

  /**
   * Minimum number of rows for the textarea.
   */
  minRows?: number | string;

  /**
   * Maximum number of rows for the textarea.
   */
  maxRows?: number | string;

  /**
   * Additional styles for the textarea.
   */
  style?: React.CSSProperties;
}

const EditableTextArea: React.FC<EditableTextAreaProps> = ({
  onTextChange,
  text,
  minRows,
  maxRows,
  style,
  ...rest
}) => {
  const [textValue, setTextValue] = useState(text || "");

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextValue(event.target.value);
    if (onTextChange) {
      onTextChange(event.target.value);
    }
  };

  const handleBlur = () => {
    onTextChange && onTextChange(textValue);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      onTextChange && onTextChange(textValue);
      event.currentTarget.blur(); // Remove focus from textarea
    }
  };

  return (
    <TextareaAutosize
      minRows={minRows}
      maxRows={maxRows}
      value={textValue}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      style={{
        resize: "none",
        border: "0px",
        borderRadius:'5px',
        outline: "none",
        font: "normal normal 300 13px/16px Source Serif Pro",
        scrollbarWidth: "none",
        paddingRight: "10px",
        boxSizing: "border-box",
        width: "100%",
        ...style,
      }}
      {...rest} // Forward additional props
    />
  );
};

export default EditableTextArea;
