import { Stack, Typography } from "@mui/material";
import React from "react";
import { DealStageCurrencySymbols } from "../../../../common";

interface DealValueViewProps {
  value: string;
}

const DealValueView: React.FC<DealValueViewProps> = ({ value }) => {
  return (
    <Stack>
      <Typography
        sx={{
          fontFamily: "Source Serif Pro, serif",
          fontSize: "14px",
          lineHeight: "20px",
          fontWeight: "normal",
        }}
      >
        {/* {DealStageCurrencySymbols.USD} */}
        {value}
      </Typography>
    </Stack>
  );
};

export default DealValueView;
