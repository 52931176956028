import React from "react";
import { Button, ButtonProps, Typography } from "@mui/material";
import { ListCheckIcon } from "../../../images";
import { useTranslation } from "react-i18next";

interface ListViewButtonCalendarProps extends ButtonProps {
  onClick: () => void;
}

const ListViewButtonCalendar: React.FC<ListViewButtonCalendarProps> = ({
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <Button
      onClick={onClick}
      variant="text"
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        gap: "5px",
        padding: "0px 5px",
        height: "30px",
        textTransform: "none",
        color: "black",
      }}
      // {...props}
    >
      <ListCheckIcon w={20} />
      <Typography
        sx={{
          font: "normal normal 300 15px/19px Source Serif Pro",
          color: "#000000",
        }}
      >
        {t("CALENDAR.LIST_VIEW")}
      </Typography>
    </Button>
  );
};

export default ListViewButtonCalendar;
