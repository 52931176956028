import {
  ActivtiyIcon,
  ConnectedIcon,
  DeleteIcon,
  DownlaodIcon,
  LockIcon,
  MoveIcon,
  RenameIcon,
  StartUpIcon,
  TagIcon,
  TrackedIcon,
  UpdateIcon,
  WatermarkIcon,
} from "../../../images";
import { SmallThreeDotMenuListInterface } from "../SmallThreeDotMenu/SmallThreeDotMenuTypes";

export const CloudStorageItemThreeDotOptions: SmallThreeDotMenuListInterface[] =
  [
    {
      id: 1,
      text: "Upload New Version",
      icon: UpdateIcon,
    },
    {
      id: 2,
      text: "Download",
      icon: DownlaodIcon,
    },
    {
      id: 3,
      text: "Share",
      icon: ConnectedIcon,
    },
    {
      id: 4,
      text: "Move",
      icon: MoveIcon,
    },
    {
      id: 5,
      text: "Lock",
      icon: LockIcon,
    },
    {
      id: 6,
      text: "Set Expiration",
      icon: TrackedIcon,
    },
    {
      id: 7,
      text: "Rename",
      icon: RenameIcon,
    },
    {
      id: 8,
      text: "Watermark",
      icon: WatermarkIcon,
    },
    {
      id: 9,
      text: "Edit Tags",
      icon: TagIcon,
    },
    {
      id: 10,
      text: "Details",
      icon: ActivtiyIcon,
    },
    {
      id: 11,
      text: "Automations",
      icon: StartUpIcon,
      color: "#000",
    },
    {
      id: 12,
      text: "Delete",
      icon: DeleteIcon,
      color:"red"
    },
  ];
