import React, { useState } from "react";
import { Stack, Popover, Typography } from "@mui/material";
import { AvatarNormal, SingleTeammateDropdown } from "../../../../common";
import { DummyImage } from "../../../../../images";

interface TableDealOwnerViewProps {
  p?: any;
}

const TableDealOwnerView: React.FC<TableDealOwnerViewProps> = ({ p }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  // Handle click event to open the popover
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle closing the popover
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Boolean to check if popover is open
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Helper function to parse avatar
  const parseAvatar = (avatar: string) => {
    if (!avatar) return { imgSrc: DummyImage, initials: "", avatarColor: "" };

    // Check if avatar is in the format "#color|initials"
    if (avatar?.includes("|")) {
      const [avatarColor, initials] = avatar?.split("|");
      return { imgSrc: "", initials, avatarColor };
    }

    // If avatar is a URL
    return { imgSrc: avatar, initials: "", avatarColor: "" };
  };

  // Safely get user details with a default fallback to an empty object
  const {
    firstName = "",
    lastName = "",
    avatar = "",
    email = "",
  } = p?.value || {};

  // Parse avatar field
  const { imgSrc, initials, avatarColor } = parseAvatar(avatar);

  // Generate initials if not provided in the avatar string
  const fallbackInitials = `${firstName?.charAt(0) || ""}${
    lastName?.charAt(0) || ""
  }`?.toUpperCase();

  return (
    <>
      {/* Stack component with click event to open popover */}
      <Stack
        aria-describedby={id}
        onClick={handleClick}
        style={{
          cursor: "pointer",
          flexDirection: "row",
          alignItems: "center",
          gap: "5px",
          width: "100%",
        }}
      >
        <AvatarNormal
          size={20}
          username={initials || fallbackInitials} // Use initials or fallback
          avatarColor={avatarColor || "green"} // Use parsed color or default
          imgSrc={imgSrc} // Use parsed image or fallback image
        />
        <Typography
          sx={{
            fontFamily: "Source Serif Pro, serif",
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: "normal",
            cursor: "pointer",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            transition: "font-weight 0.3s",
            width: "100%",
            "&:hover": {
              fontWeight: "bold",
            },
          }}
        >
          {firstName} {lastName}
        </Typography>
      </Stack>

      {/* Popover component */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: { boxShadow: "0px 3px 6px #00000029", padding: "0px" },
        }}
      >
        <SingleTeammateDropdown searchBarPlaceholder="Search team" />
      </Popover>
    </>
  );
};

export default TableDealOwnerView;
