import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { BearishOSIcon, XCircleIcon } from "../../../images";
import styles from "./WelcomeBannerPublicLong.module.css";

interface WelcomeBannerPublicLongProps {
  onCloseClick?: () => void;
}

const WelcomeBannerPublicLong: React.FC<WelcomeBannerPublicLongProps> = ({
  onCloseClick,
}) => {
  return (
    <Stack className={styles["welcome-banner-public-long"]}>
      <Stack className={styles["welcome-banner-public-long__header"]}>
        <Typography className={styles["welcome-banner-public-long__title"]}>
          Welcome to Bearish OS
        </Typography>

        <BearishOSIcon w={40} />
        <IconButton
          onClick={onCloseClick}
          className={styles["welcome-banner-public-long__icon-button"]}
        >
          <XCircleIcon w={25} color="#A53A01" />
        </IconButton>
      </Stack>
      <Typography className={styles["welcome-banner-public-long__description"]}>
        You’re viewing this item as a guest. Start free now to create your own
        and more.
      </Typography>
    </Stack>
  );
};

export default WelcomeBannerPublicLong;
