import { CommentState } from "../CommentsType";


export const addCommentToTask__Request = (state: CommentState) => {
    state.error = null;
    state.data = null;
};

export const addCommentToDocument__Request = (state: CommentState) => {
    state.error = null;
    state.data = null;
};

export const addCommentToWhiteboard__Request = (state: CommentState) => {
    state.error = null;
    state.data = null;
};

export const addCommentToProject__Request = (state: CommentState) => {
    state.error = null;
    state.data = null;
};

export const toggleReactionToComment__Request = (state: CommentState) => {
    state.error = null;
    state.data = null;
};

export const addCommentToCell__Request = (state: CommentState) => {
    state.error = null;
    state.data = null;
};

export const addSubCommentToComment__Request = (state: CommentState) => {
    state.error = null;
    state.data = null;
};

export const toggleArchiveComment__Request = (state: CommentState) => {
    state.error = null;
    state.data = null;
};

export const toggleResolveComment__Request = (state: CommentState) => {
    state.error = null;
    state.data = null;
};

export const editComment__Request = (state: CommentState) => {
    state.error = null;
    state.data = null;
};

export const getAllSubComments__Request = (state: CommentState) => {
    state.error = null;
    state.data = null;
};

export const getAllComments__Request = (state: CommentState) => {
    state.error = null;
    state.data = null;
};
