import { Stack } from "@mui/material";
import React from "react";
import DefaultQuickMsgView from "./screen/DefaultQuickMsgView";

interface MessageViewProps {
  onBackButtonClick: any;
  channels?: any;
}

const MessageView: React.FC<MessageViewProps> = ({ onBackButtonClick,channels }) => {
  return (
    <Stack sx={{ flex: 1, height: "100%",marginTop:"15px" }}>
      <DefaultQuickMsgView channelsArray={channels ? channels : {}} />
    </Stack>
  );
};

export default MessageView;
