import React, { useRef } from "react";
import { IHeaderParams } from "ag-grid-community";

interface TableHeaderProps extends IHeaderParams {
  displayName: string;
  imageUrl?: string | null;
  headerSvg?: React.ElementType;
}

const TableHeader: React.FC<TableHeaderProps> = (props) => {
  const eLabel = useRef<HTMLDivElement>(null);
  const eText = useRef<HTMLSpanElement>(null);

  return (
    <div
      className="ag-cell-label-container"
      role="presentation"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div
        ref={eLabel}
        className="ag-header-cell-label"
        role="presentation"
        style={{ display: "flex", alignItems: "center", flexGrow: 1 }}
      >
        {props.imageUrl && (
          <img
            src={props.imageUrl || ""}
            width="15"
            height="15"
            className="mr"
            alt="icon"
            style={{ marginRight: "8px" }}
          />
        )}
        {props.headerSvg && (
          <span style={{ marginRight: "8px" }}>
            {React.createElement(props.headerSvg, { w: 15, color: "black" })}
          </span>
        )}
        <span
          ref={eText}
          className="ag-header-cell-text"
          role="columnheader"
          onClick={() => props.progressSort(false)}
          style={{
            cursor: "pointer",
            font: "normal normal 400 14px/18px Source Serif Pro",
          }}
        >
          {props.displayName}
        </span>
      </div>
    </div>
  );
};

export default TableHeader;
