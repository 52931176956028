import React, { useState, MouseEvent } from "react";
import { Stack, Menu, MenuItem, Divider, Typography } from "@mui/material";
import {
  IconDropDownButton,
  RegularMenuItem,
  SmallThreeDotMenuListInterface,
} from "../../../../common";
import {
  DepartmentIcon,
  focusIcon,
  HRIcon,
  RecruitingIcon,
  TeamMemberIcon,
  VentureCapitalIcon,
} from "../../../../../images";
import { useTranslation } from "react-i18next";

const AllEmployeesTraxList: SmallThreeDotMenuListInterface[] = [
  {
    id: 1,
    icon: TeamMemberIcon,
    text: "TRAX.ALL_EMPLOYESS",
  },
  {
    id: 2,
    icon: HRIcon,
    text: "TRAX.FULL_TIME",
  },
  {
    id: 3,
    icon: VentureCapitalIcon,
    text: "TRAX.PART_TIME",
  },

  {
    id: 4,
    icon: RecruitingIcon,
    text: "TRAX.CONTRACT",
  },
  {
    id: 5,
    icon: focusIcon,
    text: "TRAX.INDEPENDENT_CON",
  },
  {
    id: 6,
    icon: DepartmentIcon,
    text: "TRAX.DEPARTMENTS",
  },
  {
    id: 7,
    icon: DepartmentIcon,
    text: "TRAX.TEAMS",
  },
];

interface AllEmployeesTraxProps {
  options?: SmallThreeDotMenuListInterface[];
  onSelect: (value: string) => void;
  initialValue?: string;
}

const AllEmployeesTrax: React.FC<AllEmployeesTraxProps> = ({
  onSelect,
  options = AllEmployeesTraxList,
  initialValue,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedValue, setSelectedValue] = useState<RegularMenuItem>(
    (options.find(
      (item) => item.text === initialValue && !item.divider
    ) as RegularMenuItem) || AllEmployeesTraxList[0]
  );

  const handleButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (item: RegularMenuItem) => {
    setSelectedValue(item);
    onSelect(item.text);
    handleClose(); // Close the menu
  };

  return (
    <Stack>
      <IconDropDownButton
        sx={{ width: "145px" }}
        label={t(selectedValue.text)}
        icon={selectedValue.icon}
        menuOpen={Boolean(anchorEl)}
        onButtonClick={handleButtonClick}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            padding: 0,
            border: "1px solid #E9EDF2",
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            borderRadius: "5px",
            width: "160px",
            marginTop: "10px",
          },
        }}
      >
        {options.map((item) =>
          item.divider ? (
            <Divider
              key={item.id}
              sx={{
                marginTop: "1px !important",
                marginBottom: "1px !important",
                marginX: "10px",
              }}
            />
          ) : (
            <MenuItem
              key={item.id}
              sx={{
                padding: "0px 10px",
                minHeight: 0,
                height: "30px",
                color: "black",
                alignItems: "center",
                gap: "5px",
              }}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleSelect(item); // Pass the selected item to handleSelect
              }}
            >
              {item.icon && (
                <item.icon
                  w={15}
                  color={item.color || "black"}
                  direction={item.direction}
                />
              )}
              <Typography
                sx={{
                  color: item.textColor || "black",
                  font: "normal normal 400 13px/16px Source Serif Pro",
                }}
              >
                {t(item.text)}
              </Typography>
            </MenuItem>
          )
        )}
      </Menu>
    </Stack>
  );
};

export default AllEmployeesTrax;
