import { Stack } from "@mui/material";
import React from "react";
import { CrmStatusBadge } from "../../../CrmStatusBadge";
import { CrmStatusBadgeProps } from "../../../CrmStatusBadge/CrmStatusBadge";

interface StatusViewProps {
  status: string;
}

const isValidStatus = (
  status: string
): status is CrmStatusBadgeProps["variant"] => {
  return [
    "onTime",
    "late",
    "sick",
    "holiday",
    "pto",
    "absent",
    "dayoff",
    "active",
    "inActive",
  ].includes(status);
};

const StatusView: React.FC<StatusViewProps> = ({ status }) => {
  return (
    <Stack
      sx={{
        width: "60px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {isValidStatus(status) ? (
        <CrmStatusBadge variant={status} />
      ) : (
        <CrmStatusBadge variant='onTime' />
      )}
    </Stack>
  );
};

export default StatusView;
