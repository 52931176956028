import { IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { ChevronIcon } from "../../../images";

interface PageControllerButtonsProps {
  totalPage: number;
  onSelect: (value: number) => void;
  initialPage?: number;
}

const PageControllerButtons: React.FC<PageControllerButtonsProps> = ({
  onSelect,
  totalPage,
  initialPage = 1,
}) => {
  const [selectedPage, setSelectedPage] = useState(initialPage);

  const handlePageChange = (newPage: number) => {
    setSelectedPage(newPage);
    onSelect(newPage); // Call onSelect directly
  };

  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        bgcolor: "white",
      }}
    >
      <IconButton
        onClick={() =>
          handlePageChange(Math.min(selectedPage + 1, totalPage))
        }
        sx={{ p: 0 }}
        disableRipple
      >
        <ChevronIcon w={25} direction="up" />
      </IconButton>

      <Stack
        sx={{
          flexDirection: "row",
          width: "65px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{ font: "normal normal 600 18px/23px Source Serif Pro" }}
        >
          {selectedPage}/{totalPage}
        </Typography>
      </Stack>

      <IconButton
        onClick={() => handlePageChange(Math.max(selectedPage - 1, 1))}
        sx={{ p: 0 }}
        disableRipple
      >
        <ChevronIcon w={25} direction="down" />
      </IconButton>
    </Stack>
  );
};

export default PageControllerButtons;
