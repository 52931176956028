import React from "react";
import { Stack, Typography } from "@mui/material";
import styles from "./ObjectiveTaskDropdown.module.css";
import dayjs from "dayjs";
import { CheckboxMedium } from "../CheckboxMedium";

import { IconDropdownUser } from "../IconDropdownUser";
import { DropdownWithColor } from "../DropdownWithColor";
import { DUMMY_TODO_OPTIONS } from "../DropdownWithColor/DropdownWithColorTypes";
import { DatePickerWithIcon } from "../DatePickerWithIcon";
import { getUserList } from "../../../Utils/authService";

interface ObjectiveTaskDropdownProps {
  options: any; // Update this according to your data structure
  handleCreateSubTask?: (
    task: string,
    status: any,
    dueDate: any,
    selectedUser: any
  ) => void;
  contentRef?: any; //
  expanded?: any;
  setStatus?: any;
  setDueDate?: any;
  setSelectedUser?: any;
}

const ObjectiveTaskDropdown: React.FC<ObjectiveTaskDropdownProps> = ({
  options,
  setStatus,
  setDueDate,
  setSelectedUser,
}) => {
  const userList = getUserList();
console.log(options)
  return (
    <Stack className={styles["objective-task-dropdown"]}>
      {/* Display all subtasks */}
      <Stack>
        {options?.length ? (
          options?.map((data: any, index: number) => (
            <Stack key={index} mt={2} direction={"column"}>
              <Stack direction={"row"} gap={3} alignItems={"center"}>
                <CheckboxMedium boxSize={19} />
                <Typography>{data?.name}</Typography>
              </Stack>

              <Stack ml={4.2} mt={2} gap={3} direction={"row"}>
                <DropdownWithColor
                  options={DUMMY_TODO_OPTIONS}
                  onChange={(value) => null}
                  onSelect={(value: any) => {
                    setStatus(value);
                  }}
                  initialValue={data?.status || DUMMY_TODO_OPTIONS[1]}
                />
                <DatePickerWithIcon
                  initialDate={data?.dueDate ? dayjs(data?.dueDate) : null}
                  onDateChange={(date: string | undefined) =>
                    setDueDate(date || "")
                  }
                />
                <IconDropdownUser
                  componentType="showUser"
                  showSelectedUser={data?.assignedUsers?.[0]}
                  onSelect={(item) => setSelectedUser(item)}
                  options={userList}
                />
              </Stack>
            </Stack>
          ))
        ) : (
          <Typography sx={{ color: "grey" }}>No subtasks available</Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default ObjectiveTaskDropdown;
