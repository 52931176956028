import {useTranslation} from "react-i18next";
import VideoCenterTabs from "./components/VideoCenterTabs/VideoCenterTabs";


type CallsProps = {};

const Calls = (props: CallsProps) => {

    const {t}=useTranslation()




    return(
        <div >
            <VideoCenterTabs/>
        </div>
    )
};

export default Calls;
