import { Stack } from "@mui/material";
import React from "react";
import {
  CalenderEventsInnerView,
  CallsInnerView,
  BillsInnerView,
  MsgInnerView,
  OtherEmailsInnerView,
  TasksInnerView,
} from "./TouchPointInnerView";
import { TouchPointVariantEnum, TouchPointViewProps } from "./TouchPointsType";

const TouchPointsView: React.FC<TouchPointViewProps> = (props) => {
  const { variant } = props;

  const renderComponent = () => {
    switch (variant) {
      case TouchPointVariantEnum.Bill:
        return <BillsInnerView {...props} />;

      case TouchPointVariantEnum.CalendarEvent:
        return <CalenderEventsInnerView {...props} />;

      case TouchPointVariantEnum.Call:
        return <CallsInnerView {...props} />;

      case TouchPointVariantEnum.Email:
        return <OtherEmailsInnerView {...props} />;

      case TouchPointVariantEnum.Message:
        return <MsgInnerView {...props} />;

      case TouchPointVariantEnum.Task:
        return <TasksInnerView {...props} />;

      default:
        break;
    }
  };

  return <Stack>{renderComponent()}</Stack>;
};

export default TouchPointsView;
