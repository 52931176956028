import React from "react";
import { Stack, Typography, Divider } from "@mui/material";
import { CheckboxMedium } from "../../../../../../common";
import { FilterButtonSubListInterface } from "../../FilterButtonTypes";
import styles from "./FilterOptionView.module.css";
import { SelectedSecondaryListProps } from "../../FilterButton";

interface FilterOptionViewProps {
  optionList: FilterButtonSubListInterface[];
  FilterLabel: string;
  secondaryList: SelectedSecondaryListProps[];
  setSecondaryList: React.Dispatch<
    React.SetStateAction<SelectedSecondaryListProps[]>
  >;
}

const FilterOptionView: React.FC<FilterOptionViewProps> = ({
  optionList,
  FilterLabel,
  secondaryList,
  setSecondaryList,
}) => {
  // Function to handle checkbox changes
  const handleCheckboxChange = (value: string, role?: string) => {
    setSecondaryList((prev) => {
      const existingFilter = prev.find(
        (item) => item.FilterLabel === FilterLabel
      );
      if (existingFilter) {
        const updatedOptions = existingFilter.selectedOptions.find(
          (option) => option.role === role
        );

        if (updatedOptions) {
          // If value exists, remove it
          const updatedValues = updatedOptions.value.includes(value)
            ? updatedOptions.value.filter((v) => v !== value)
            : [...updatedOptions.value, value];

          // Update the existing role or remove if empty
          const newSelectedOptions = updatedValues.length
            ? { ...updatedOptions, value: updatedValues }
            : undefined;

          return prev.map((item) =>
            item.FilterLabel === FilterLabel
              ? {
                  ...item,
                  selectedOptions: newSelectedOptions
                    ? item.selectedOptions.map((opt) =>
                        opt.role === role ? newSelectedOptions : opt
                      )
                    : item.selectedOptions.filter((opt) => opt.role !== role),
                }
              : item
          );
        } else {
          // If role doesn't exist, add new
          return prev.map((item) =>
            item.FilterLabel === FilterLabel
              ? {
                  ...item,
                  selectedOptions: [
                    ...item.selectedOptions,
                    { role, value: [value] },
                  ],
                }
              : item
          );
        }
      } else {
        // If FilterLabel doesn't exist, add new
        return [
          ...prev,
          {
            FilterLabel,
            selectedOptions: [{ role, value: [value] }],
          },
        ];
      }
    });
  };

  return (
    <Stack className={styles["filter-option-view"]}>
      <Stack className={styles["filter-option-view__stack"]}>
        {optionList.map((option, index) =>
          option.divider ? (
            <Divider key={`divider-${index}`} orientation="horizontal" />
          ) : (
            <Stack
              key={`option-${index}`}
              className={styles["filter-option-view__option"]}
            >
              {option.optionLabel && (
                <Typography className={styles["filter-option-view__label"]}>
                  {option.optionLabel}
                </Typography>
              )}
              <Stack className={styles["filter-option-view__values"]}>
                {option.optionValue.map((value, idx) => (
                  <Stack
                    key={`value-${idx}`}
                    className={styles["filter-option-view__value"]}
                    onClick={() =>
                      handleCheckboxChange(
                        value,
                        FilterLabel === "Meetings"
                          ? option.optionLabel === "I’m the organizer"
                            ? "organizer"
                            : option.optionLabel === "I’m the attendee"
                              ? "attendee"
                              : undefined
                          : undefined
                      )
                    }
                  >
                    <CheckboxMedium
                      boxSize={16}
                      checked={secondaryList.some((item) =>
                        item.selectedOptions.some(
                          (opt) =>
                            opt.role ===
                              (FilterLabel === "Meetings"
                                ? option.optionLabel === "I’m the organizer"
                                  ? "organizer"
                                  : option.optionLabel === "I’m the attendee"
                                    ? "attendee"
                                    : undefined
                                : undefined) && opt.value.includes(value)
                        )
                      )}
                    />
                    <Typography
                      className={styles["filter-option-view__value-text"]}
                      sx={{
                        color: option.coloredtext
                          ? option.coloredtext[idx]?.colorValue
                          : "inherit",
                      }}
                    >
                      {value}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          )
        )}
      </Stack>
    </Stack>
  );
};

export default FilterOptionView;
