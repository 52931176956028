import { Dialog, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { AgendaCardSquare } from "../AgendaCardSquare";
import { RootState } from "../../../../../redux/store";
import { VideoCenterState } from "../../../../../redux/Slice/videoCenter/VideoCenterTypes";
import { useSelector, useDispatch } from "react-redux";
import {
  AuthState,
  deleteAgenda,
  updateAgendaStatus,
} from "../../../../../redux";
import { setDeleteAgendaAction } from "../../../../../redux/Slice/videoCenter/VideoCenterSlice";
import { AgendaCardSquareForAttendee } from "../AgendaCardSquareForAttendee";
import { AddAgendaPopup } from "../AddAgendaPopup";
import { useTranslation } from "react-i18next";

interface AgendaLeftSideBarProps {}

const AgendaLeftSideBar: React.FC<AgendaLeftSideBarProps> = ({}) => {
  const { t } = useTranslation();
  const [openAgendaPopup, setOpenAgendaPopup] = useState(false);
  const [editAgendaItem, setEditAgendaItem] = useState(null);
  const { onGoingMeeting } = useSelector(
    (state: RootState) => state.videoCenter as VideoCenterState
  );
  const { user } = useSelector((state: RootState) => state.auth as AuthState);
  const dispatch = useDispatch();
  const handleTimeUpdate = (timeInMinutes: number) => {
    console.log(`Total time in minutes: ${timeInMinutes}`);
  };

  const handleRemoveAgenda = async (agendaId: any) => {
    await dispatch(deleteAgenda(agendaId));
  dispatch(setDeleteAgendaAction(agendaId));
  };

  const onStartStopAgendaClick = async (
    isTimerRunning: any,
    expendedTime: any,
    agendaId: number
  ) => {
    const payload = {
      action: isTimerRunning ? "STOP" : "START",
      totalTimeTaken: Math.round(expendedTime * 100) / 100,
    };
    await dispatch(updateAgendaStatus(agendaId, payload));
  };
  const handleClickOnCancelAgenda = () => {
    setOpenAgendaPopup(false);
    setEditAgendaItem(null);
  };
  const handleAddAgenda = async () => {
    setOpenAgendaPopup(true);
  };
  const handleEdit = (id: string) => {
    const agendaItem = onGoingMeeting.meetingAgendas[id];
    setEditAgendaItem(agendaItem);
    setOpenAgendaPopup(true);
  };

  return (
    <Stack
      sx={{
        maxHeight: "780px",
        bgcolor: "white",
        marginTop: "10px",
        padding: "10px",
        alignItems: "center",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
          marginBottom: "10px",
          width: "250px",
        }}
      >
        <Typography
          sx={{ font: "normal normal 600 15px/19px Source Serif Pro" }}
        >
          {t("VIDEO_CENTER.MEETING.AGENDA")}
        </Typography>
        <Stack
          sx={{
            height: "3px",
            borderRadius: "2px",
            flex: 1,
            bgcolor: "#E9EDF2",
          }}
        />
        {onGoingMeeting?.meeting?.meetingHostId === user?.data?._id && (
          <>
            {" "}
            <Typography
              sx={{
                font: "normal normal 400 11px/13.78px Source Serif Pro",
                cursor: "pointer",
              }}
              onClick={handleAddAgenda}
            >
              {t("VIDEO_CENTER.MEETING.ADD_AGENDA_ITEM")}
            </Typography>{" "}
          </>
        )}
      </Stack>
      <Dialog onClose={handleClickOnCancelAgenda} open={openAgendaPopup}>
        <AddAgendaPopup
          isOngoingMeeting={true}
          userData={user?.data}
          meetingData={onGoingMeeting?.meeting}
          editAgendaItem={editAgendaItem}
          onClose={handleClickOnCancelAgenda}
        />
      </Dialog>
      <Stack sx={{ gap: "10px", overflow: "auto", scrollbarWidth: "none" }}>
        {onGoingMeeting?.meetingAgendas &&
        Object.keys(onGoingMeeting.meetingAgendas).length > 0 ? (
          onGoingMeeting?.meeting?.meetingHostId === user?.data?._id ? (
            Object.values(onGoingMeeting.meetingAgendas).map(
              (agenda: any, index: number) => {
                return (
                  <AgendaCardSquare
                      key={index}
                      index={index}
                    removeAgenda={handleRemoveAgenda}
                    timeLimit={agenda.timeAllotted}
                    agendaTitle={agenda.title}
                    agendaId={agenda._id}
                    agendaDetail={agenda.description}
                    onTimeUpdate={handleTimeUpdate}
                    onStartStopAgendaClick={onStartStopAgendaClick}
                    totalTimeTaken={agenda.totalTimeTaken}
                    agendaStatus={agenda?.isActive}
                  />
                );
              }
            )
          ) : (
            Object.values(onGoingMeeting.meetingAgendas).map(
              (agenda: any, index: number) => {
                return (
                  <AgendaCardSquareForAttendee
                    key={index}
                    index={index}
                    removeAgenda={handleRemoveAgenda}
                    timeLimit={agenda.timeAllotted}
                    agendaTitle={agenda.title}
                    agendaId={agenda._id}
                    agendaDetail={agenda.description}
                    onTimeUpdate={handleTimeUpdate}
                    onStartStopAgendaClick={onStartStopAgendaClick}
                    totalTimeTaken={agenda.totalTimeTaken}
                    agendaStatus={agenda?.isActive}
                    startTime={agenda.startTime}
                  />
                );
              }
            )
          )
        ) : (
          <Typography
            sx={{
              color: "#757575",
              fontStyle: "italic",
              fontSize: "16px",
            }}
          >
            {t("VIDEO_CENTER.MEETING.NO_AGENDA_ITEMS")}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default AgendaLeftSideBar;
