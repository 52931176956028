import { Stack, Typography } from "@mui/material";
import React from "react";
import { FolderFileIcon } from "../../../images";
import { CheckboxMedium } from "../CheckboxMedium";

interface FilePreviewWithCheckboxProps {
  fileName: string;
  isChecked: boolean;
  onCheckBoxClick: () => void;
}

const FilePreviewWithCheckbox: React.FC<FilePreviewWithCheckboxProps> = ({
  fileName,
  isChecked,
  onCheckBoxClick,
}) => {
  return (
    <Stack
      sx={{
        height: "40px",
        padding: "5px 0px",
        borderBottom: "1px solid #E9EDF2",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
          maxWidth: "calc(100% - 30px)",
        }}
      >
        <Stack flexShrink={0}>
          <FolderFileIcon w={30} />
        </Stack>
        <Typography
          sx={{
            font: "normal normal 300 14px/18px Source Serif Pro",
            maxWidth: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {fileName}
        </Typography>
      </Stack>
      <CheckboxMedium
        onClick={onCheckBoxClick}
        checked={isChecked}
        boxSize={17}
      />
    </Stack>
  );
};

export default FilePreviewWithCheckbox;
