import React, { useState } from "react";
import {
  List,
  ListItem,
  Paper,
  Stack,
  InputBase,
  Typography,
} from "@mui/material";
import { ButtonSmall } from "../Buttons";
import { ButtonType } from "../Buttons/AllButtonProps";
import styles from "./LongSearchBearishOS.module.css";
import { CheckboxMedium } from "../CheckboxMedium";
import { VideoIcon } from "../../../images";

// Update LongSearchBearishOSPropsType interface
interface LongSearchBearishOSPropsType {
  options: any;
  buttonLabel: string;
  width: string;
  inputPlaceHolder: string;
  onSelectOption?: (selectedOptions: any) => void; // Updated to accept an array
}

const LongSearchBearishOS: React.FC<LongSearchBearishOSPropsType> = ({
  options,
  buttonLabel,
  width,
  inputPlaceHolder,
  onSelectOption, // New prop
}) => {
  const [typedValue, setTypedValue] = useState<
    string | number | symbol | boolean
  >("");

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedItems, setSelectedItems] = useState<any>([]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTypedValue(event.target.value);
    setDropdownVisible(event.target.value.length > 0);
  };

  const handleItemToggle = (item: any) => {
    const currentIndex = selectedItems.findIndex(
      (selectedItem: any) => selectedItem.id === item.id
    );
    const newSelectedItems = [...selectedItems];

    if (currentIndex === -1) {
      newSelectedItems.push(item);
    } else {
      newSelectedItems.splice(currentIndex, 1);
    }

    setSelectedItems(newSelectedItems);
    setDropdownVisible(true);
  };

  const handleButtonClick = () => {
    if (onSelectOption && selectedItems && selectedItems.length > 0) {
      onSelectOption(selectedItems);
    }

    setDropdownVisible(false);
    setSelectedItems([]);
    setTypedValue("");
  };

  const filteredItems = options?.filter((item: any) => {
    if (typeof typedValue === "string") {
      return item?.email?.toLowerCase()?.includes(typedValue?.toLowerCase());
    }
    return false; // Handle other types if necessary
  });

  return (
    <div
      className={styles["long-search-bearish-os__container"]}
      style={{ width }}
    >
      <Stack className={styles["long-search-bearish-os__input-container"]}>
        <InputBase
          className={styles["long-search-bearish-os__input"]}
          sx={{
            height: "30px",
            paddingRight: "75px", // Adjust padding to ensure text does not hide under the button
          }}
          value={
            selectedItems.length > 0
              ? selectedItems?.map((item: any) => item?.email).join(", ")
              : typedValue
          }
          onChange={handleInputChange}
          onFocus={() => {
            if (typeof typedValue === "string") {
              setDropdownVisible(typedValue.length > 0);
            } else {
              setDropdownVisible(false); // Handle other types accordingly
            }
          }}
          placeholder={inputPlaceHolder}
          readOnly={selectedItems.length > 0}
        />
        <ButtonSmall
          sx={{
            height: "24px",
            width: "60px",
            position: "absolute",
            right: "10px",
          }}
          label={buttonLabel} // Use the provided button label
          types={ButtonType.Button}
          onClick={handleButtonClick}
        />
      </Stack>
      <Paper
        className={styles["long-search-bearish-os__dropdown"]}
        sx={{
          display:
            dropdownVisible && filteredItems?.length > 0 ? "block" : "none",
        }}
      >
        <List>
          {filteredItems?.map((item: any) => (
            <ListItem
              key={item?.id || item?._id}
              className={styles["long-search-bearish-os__list-item"]}
              onClick={() => handleItemToggle(item)}
            >
              <Stack
                className={styles["long-search-bearish-os__list-item-stack"]}
              >
                <Stack
                  className={
                    styles["long-search-bearish-os__list-item-content"]
                  }
                >
                  <VideoIcon w={14} />
                  <Typography
                    className={styles["long-search-bearish-os__item-text"]}
                  >
                    {item?.firstName}
                    {item?.lastName}
                  </Typography>
                </Stack>
                <CheckboxMedium
                  checked={
                    selectedItems.find(
                      (selectedItem: any) => selectedItem._id === item._id
                    ) !== undefined
                  }
                  tabIndex={-1}
                  disableRipple
                />
              </Stack>
            </ListItem>
          ))}
        </List>
      </Paper>
    </div>
  );
};

export default LongSearchBearishOS;
