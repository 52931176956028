import React, { useEffect, useState } from "react";
import { IconDropDownButton } from "../../../IconDropDownButton";
import { Menu, MenuItem, Typography } from "@mui/material";
import { AscendingDropdownOptionsType } from "../../MenuTypes";
import { useTranslation } from "react-i18next";

interface AscendingDropdownProps {
  options: AscendingDropdownOptionsType[];
  initialValue?: string;
  onSelect: (selectedValue: string) => void;
  width?: string;
}

const AscendingDropdown: React.FC<AscendingDropdownProps> = ({
  initialValue,
  onSelect,
  options,
  width = "115px",
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedValue, setSelectedValue] = useState<string>();

  useEffect(() => {
    setSelectedValue(initialValue);
  }, [initialValue]);

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget); // Open the menu at the button's position
  };

  const handleMenuClose = () => {
    setAnchorEl(null); // Close the menu
  };

  const handleMenuSelect = (value: string) => {
    setSelectedValue(value);
    setAnchorEl(null); // Close the menu after selection
    onSelect(value); // Trigger the onSelect callback with the selected value
  };

  const isMenuOpen = Boolean(anchorEl); // Check if the menu is open
  const labeldata: string =
    options.find((item) => item.value === initialValue)?.translatedWord ||
    "TRAX.UNDEFINED";

  return (
    <div>
      <IconDropDownButton
        sx={{ width: width }}
        label={t(labeldata)}
        menuOpen={isMenuOpen}
        onButtonClick={handleButtonClick} // Open the MUI Menu
      />

      {/* MUI Menu */}
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            padding: 0,
            marginTop: "5px",
            zIndex: 110,
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.16)",
            borderRadius: "5px",
            width: width,
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            selected={option.value == selectedValue}
            onClick={() => handleMenuSelect(option.value)}
            sx={{
              minHeight: "30px !important",
              maxHeight: "30px",
              padding: "0px 10px",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              height: "30px",
              boxSizing: "border-box",
            }}
          >
            <Typography
              sx={{ font: "normal normal normal 14px/20px Source Serif Pro" }}
            >
              {t(option.translatedWord)}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default AscendingDropdown;
