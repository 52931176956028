export interface RepeatOptionMenuOptions {
    id: number | string;
    optionName: string;
}

export const repeatOptionMenuOptionList: RepeatOptionMenuOptions[] = [
    { id: 1, optionName: 'Does not repeat' },
    { id: 2, optionName: 'Daily' },
    { id: 3, optionName: 'Weekly' },
    { id: 4, optionName: 'Monthly' },
    { id: 5, optionName: 'Every weekday' },
    { id: 6, optionName: 'Custom' },
]

export enum repeatOptionMenuRepeatModeEnum {
    Day = "Day",
    Week = "Week",
    Month = "Month"
}