import { PayloadAction } from "@reduxjs/toolkit";
import { CommentState } from "../CommentsType";

export const addCommentToTask__Success = (
  state: CommentState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addCommentToDocument__Success = (
  state: CommentState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addCommentToWhiteboard__Success = (
  state: CommentState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addCommentToProject__Success = (
  state: CommentState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const toggleReactionToComment__Success = (
  state: CommentState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addCommentToCell__Success = (
  state: CommentState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addSubCommentToComment__Success = (
  state: CommentState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const toggleArchiveComment__Success = (
  state: CommentState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const toggleResolveComment__Success = (
  state: CommentState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const editComment__Success = (
  state: CommentState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getAllSubComments__Success = (
  state: CommentState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getAllComments__Success = (
  state: CommentState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};
