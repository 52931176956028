import React from 'react';

const DocumentViewer: React.FC<{ path: string }> = ({ path }) => (
  <div style={{ height: '100vh', width: '100%' }}>
    <iframe 
      title="Document Viewer"
      src={`https://docs.google.com/gview?url=${`${path}`}&embedded=true`} 
      style={{ width: '100%', height: '100%', border: 'none' }} 
    />
  </div>
);

export default DocumentViewer;
