import { IconButtonProps } from "@mui/material";
import React from "react";
import { VideoActionRoundedButtons } from "../../../../common";
import { VolumeIcon } from "../../../../../images";
import {useTranslation} from "react-i18next";

interface SoundButtonProps extends IconButtonProps {
  initialStatus: boolean;
  onClickChange: (status: boolean) => void;
}

const SoundButton: React.FC<SoundButtonProps> = ({
  initialStatus,
  onClickChange,
  ...props
}) => {
  const {t}=useTranslation()

  return (
    <VideoActionRoundedButtons
      activeColor="#E9EDF2"
      inactiveColor="#FF8181"
      activeHoverText={t("PAST_CALL_DETAILS.MUTE")}
      inActiveHoverText={t("PAST_CALL_DETAILS.UNMUTE")}
      icon={VolumeIcon}
      iconColor="black"
      initialStatus={initialStatus}
      onClickChange={onClickChange}
      {...props}
    />
  );
};
export default SoundButton;
