import { Stack, Typography } from "@mui/material";
import React from "react";
import { TextLinkButton } from "../../../../common";
import { ValueArea } from "./view";

interface PayrollMiniContainerProps {}

const PayrollMiniContainer: React.FC<PayrollMiniContainerProps> = ({}) => {
  return (
    <Stack gap={"12px"} flexShrink={0}>
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
        <Typography
          sx={{ font: "normal normal 400 15px/19px Source Serif Pro" }}
        >
          Payroll
        </Typography>

        <TextLinkButton buttonLabel="Go to Payroll" />
      </Stack>

      <Stack
        sx={{
          width: "430px",
          height: "60px",
          border: "1px solid #E9EDF2",
          flexDirection: "row",
        }}
      >
        <ValueArea
          label="Current Pay Period"
          value="00-00 to 00-00"
          width="120px"
        />
        <ValueArea label="Base Pay" value="$09038.00" width="100px" />
        <ValueArea label="Overtime Pay" value="$09038.00" width="100px" />
        <ValueArea
          label="Reimbursements"
          value="$09038.00"
          sx={{ flex: 1 }}
        />
      </Stack>
    </Stack>
  );
};

export default PayrollMiniContainer;
