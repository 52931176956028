import CrmDrawer from "./CrmDrawer";

export { CrmDrawer };

export const MUIModalstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 0,
    borderRadius: "5px",
};
