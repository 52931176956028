import { Box, Stack } from "@mui/material";
import React from "react";
import { VideoCallIcon } from "../../../../../../../../images";
import { UserCount, UserInfoChip } from "../../../../EmailCardComponents";
import { TouchPointVariantEnum, TouchPointViewProps } from "../TouchPointsType";

type CallsInnerViewProps = Extract<
  TouchPointViewProps,
  { variant: TouchPointVariantEnum.Call }
>;

const extractFirstName = (fullName: string): string => {
  const names = fullName.split(" ");
  return names[0]; // Assuming the first name is the first part of the full name
};

const CallsInnerView: React.FC<CallsInnerViewProps> = ({
  platform,
  timeZone,
  timestamp,
  usersList,
}) => {
  const userCount = usersList.length;

  const renderUsers = () => {
    if (userCount === 1) {
      return (
        <UserInfoChip
          popoverPlace="bottom"
          showOnlyName
          fontSx={{ font: "inherit" }}
          userName={usersList[0].userName}
          userFirstName={extractFirstName(usersList[0].userName)}
          userEmail={usersList[0].userEmail}
          userIconSrc={usersList[0].userIconSrc}
          userAvatarColor={usersList[0].userAvatarColor}
        />
      );
    }

    if (userCount === 2) {
      return (
        <>
          <UserInfoChip
            popoverPlace="bottom"
            showOnlyName
            fontSx={{ font: "inherit" }}
            userName={usersList[0].userName}
            userFirstName={extractFirstName(usersList[0].userName)}
            userEmail={usersList[0].userEmail}
            userIconSrc={usersList[0].userIconSrc}
            userAvatarColor={usersList[0].userAvatarColor}
          />
          <span>and</span>
          <UserInfoChip
            popoverPlace="bottom"
            showOnlyName
            fontSx={{ font: "inherit" }}
            userName={usersList[1].userName}
            userFirstName={extractFirstName(usersList[1].userName)}
            userEmail={usersList[1].userEmail}
            userIconSrc={usersList[1].userIconSrc}
            userAvatarColor={usersList[1].userAvatarColor}
          />
        </>
      );
    }

    if (userCount === 3) {
      return (
        <>
          {usersList.slice(0, 3).map((user, index) => (
            <React.Fragment key={user.userEmail}>
              <UserInfoChip
                popoverPlace="bottom"
                showOnlyName
                fontSx={{ font: "inherit" }}
                userName={user.userName}
                userFirstName={extractFirstName(user.userName)}
                userEmail={user.userEmail}
                userIconSrc={user.userIconSrc}
                userAvatarColor={user.userAvatarColor}
              />
              {index < 2 && <span>{index === 0 ? "," : " and "}</span>}
            </React.Fragment>
          ))}
        </>
      );
    }

    if (userCount > 3) {
      return (
        <>
          {usersList.slice(0, 3).map((user, index) => (
            <React.Fragment key={user.userEmail}>
              <UserInfoChip
                popoverPlace="bottom"
                showOnlyName
                fontSx={{ font: "inherit" }}
                userName={user.userName}
                userFirstName={extractFirstName(user.userName)}
                userEmail={user.userEmail}
                userIconSrc={user.userIconSrc}
                userAvatarColor={user.userAvatarColor}
              />
              {index < 2 && <span>{index === 0 ? "," : " and "}</span>}
            </React.Fragment>
          ))}
          <UserCount
            userList={usersList.slice(3)}
            sx={{ font: "inherit", color: "black" }}
          />
        </>
      );
    }
  };

  return (
    <Stack sx={{ flexDirection: "row", alignItems: "start", gap: "15px" }}>
      <Box sx={{ flexShrink: 0 }}>
        <VideoCallIcon w={16} />
      </Box>
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "4px",
          flexWrap: "wrap",
          font: "normal normal 300 13px/16px Source Serif Pro",
        }}
      >
        <span style={{ backgroundColor: "white" }}>Call with</span>
        {renderUsers()}
        <span>on</span>
        <span>{timestamp}</span>
        <span>{timeZone}</span>
        <span>via</span>
        <span>{platform}</span>
      </Stack>
    </Stack>
  );
};

export default CallsInnerView;
