import React from "react";
import { CallJoinedUserProps } from "../../InCallGuestMenuType";
import { Box, Stack, Typography } from "@mui/material";
import {
  CardScrollableContainerView,
  CopyButtonView,
  UserCardView,
} from "../../view";
import { woollyAtTheSea } from "../../../../../../../images";
import {useTranslation} from "react-i18next";

interface AddedUserTabViewProps {
  joinedUserList?: CallJoinedUserProps[];
}

const AddedUserTabView: React.FC<AddedUserTabViewProps> = ({
  joinedUserList = [],
}) => {
  const {t}=useTranslation()
  return (
    <>
      {joinedUserList?.length > 0 ? (
        <CardScrollableContainerView
          hideMoreButton={joinedUserList.length <= 5}
        >
          {joinedUserList.map((item, index) => (
            <UserCardView
              cardType="joined"
              isMic={item?.isMicOn}
              isVideo={item?.isVideoOn}
              userEmail={item?.userEmail}
              userName={item?.userName}
              darkBg={index % 2 === 0}
              key={index}
            />
          ))}
        </CardScrollableContainerView>
      ) : (
        <Stack sx={{ alignItems: "center", gap: "30px" }}>
          <Typography
            sx={{ font: "normal normal 400 15px/19px Source Serif Pro" }}
          >
            {t("VIDEO_CENTER.MEETING.LOOKS_LIKE")}
          </Typography>
          <Box
            component={"img"}
            src={woollyAtTheSea}
            alt="Bear png"
            sx={{ height: "150px", width: "150px", marginTop: "8px" }}
          />

          <CopyButtonView linkValue="linkNameHere" />
        </Stack>
      )}
    </>
  );
};

export default AddedUserTabView;
