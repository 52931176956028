import React, { useEffect, useState } from "react";
import { ChecklistItemPM } from "../../../components";
import { Box, Popover, Stack, Typography } from "@mui/material";
import g from "./lcv.module.css";
import { ColumnControls } from "../../../../CRM/components";
import {
  LayoutState,
  updateOneTask__api,
  UpdateTaskPayload,
} from "../../../../../../redux";
import { useDispatch, useSelector } from "react-redux";
import NoTask from "../NoTask";

// Updated Type definition for checklist item data
type ChecklistItemData = {
  id: string;
  title: string;
  checked: boolean;
  showDetails: boolean;
  componentType: "main" | "subTask";
  opened: boolean;
  status: {
    id: number;
    value: string;
    defaultColorHex: string;
    customColorHex?: string;
  };
  priority: {
    id: number;
    value: string;
    defaultColorHex: string;
    customColorHex?: string;
  };
  dueDate: string;
  assignedUsers: Array<{
    _id: string;
    firstName: string;
    lastName: string;
    avatar: string;
  }>;
  taskType: string;
  cardColor: string;
  emoji: string;
  estimatedTime: number;
  customElements: Record<string, any>;
  checklists: Array<{
    name: string;
    item: Array<{
      itemName: string;
      assignee: string;
      isChecked: boolean;
    }>;
  }>;
};

type Props = {
  tableView?: "free-form-viewMode-only" | "advanced-table-view-drag-and-drop";
  workspaceItemData?: any;
  taskWorkspaceItemData?: any;
};

const ListOverView: React.FC<Props> = ({ taskWorkspaceItemData }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [checklistItemsData, setChecklistItemsData] = useState<
    ChecklistItemData[]
  >([]);

  const layoutState = useSelector(
    (state: { layout: LayoutState }) => state && state?.layout
  );

  const workspaceLayout = layoutState?.isDrawer_Workspace;

  useEffect(() => {
    if (taskWorkspaceItemData && taskWorkspaceItemData?.length > 0) {
      const items = taskWorkspaceItemData?.flatMap((group: any) =>
        group?.tasks?.map((task: any) => ({
          id: task?._id,
          title: task?.name,
          checked: false,
          showDetails: true,
          componentType: task?.isSubTask ? "subTask" : "main",
          opened: false,
          status: task?.status,
          priority: task?.priority,
          dueDate: task?.dueDate,
          assignedUsers: task?.assignedUsers,
          taskType: task?.taskType,
          cardColor: task?.cardColor || "#808080",
          emoji: task?.emoji,
          estimatedTime: task?.estimatedTime,
          customElements: task?.customElements,
          checklists: task?.checklists,
        }))
      );

      setChecklistItemsData(items);
    } else {
      setChecklistItemsData([]);
    }
  }, [taskWorkspaceItemData]);

  // Function to generate the update payload for checklist items
  const getUpdatedChecklistItemPayload = (
    item: ChecklistItemData
  ): UpdateTaskPayload => {
    return {
      taskId: item?.id,
      name: item?.title,
      assignedUserIds: item?.assignedUsers?.map((user: any) => user?._id),
      priority: {
        id: item?.priority?.id,
        value: item?.priority?.value,
        defaultColorHex: item?.priority?.defaultColorHex,
        customColorHex: item?.priority?.customColorHex,
      },
      dueDate: item?.dueDate,
      status: {
        id: item?.status?.id,
        value: item?.status?.value,
        defaultColorHex: item?.status?.defaultColorHex,
        customColorHex: item?.status?.customColorHex,
      },
      taskColor: item?.cardColor,
      estimatedTime: item?.estimatedTime,
      emoji: item?.emoji,
      taskType: item?.taskType,
      customElements: item?.customElements,
      checklists: item?.checklists,
    };
  };

  // Function to handle API update
  const handleUpdateTask = async (item: ChecklistItemData) => {
    const payload = getUpdatedChecklistItemPayload(item);

    try {
      const action = updateOneTask__api(payload?.taskId, payload);
      await dispatch(action);
    } catch (error) {
      console.error("Failed to update task:", error);
    }
  };

  // Update function to handle item updates
  const updateChecklistItemData = async (
    taskId: string,
    field: keyof ChecklistItemData | "r" | "customElements",
    value: any
  ) => {
    const updatedItems = checklistItemsData?.map((item) => {
      if (item?.id === taskId) {
        let updatedItem = { ...item };

        // Use switch-case to update the specific field
        switch (field) {
          case "title":
            updatedItem.title = value;
            break;
          case "checked":
            updatedItem.checked = value;
            break;
          case "showDetails":
            updatedItem.showDetails = value;
            break;
          case "componentType":
            updatedItem.componentType = value;
            break;
          case "opened":
            updatedItem.opened = value;
            break;
          case "status":
            updatedItem.status = {
              ...updatedItem.status,
              ...value,
            };
            break;
          case "priority":
            updatedItem.priority = {
              ...updatedItem.priority,
              ...value,
            };
            break;
          case "dueDate":
            updatedItem.dueDate = value;
            break;

          case "customElements":
            // Directly handle customElements without dataSet
            if (updatedItem?.customElements) {
              const newCustomElements = { ...updatedItem.customElements };

              // Update or add the specific fields in customElements
              Object?.keys(value)?.forEach((key) => {
                if (
                  value?.[key] !== undefined &&
                  value?.[key] !== null &&
                  value?.[key] !== ""
                ) {
                  // Only update if value is not empty, null, or undefined
                  newCustomElements[key] = value?.[key];
                }
              });

              // Update the task's customElements with the new values
              updatedItem.customElements = newCustomElements;
            }
            break;

          default:
            break;
        }

        if (field !== "checked") {
          handleUpdateTask(updatedItem);
        }

        return updatedItem;
      }
      return item;
    });

    if (field === "r") {
      return;
    }

    // Update the checklistItemsData state
    setChecklistItemsData(updatedItems);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box sx={{ width: "100%" }}>
      <Stack
        p={1}
        borderRadius={1}
        width={"100%"}
        bgcolor={"#E9EDF2"}
        direction={"row"}
        alignItems={"center"}
      >
        <Stack
          onClick={(e: any) => handleClick(e)}
          sx={{ mr: "250px", ml: "105px" }}
        >
          <Typography className={g.h__header}>Name</Typography>
        </Stack>
        <Stack
          onClick={(e: any) => handleClick(e)}
          sx={{ mr: workspaceLayout ? "40px" : "95px", ml: "123px" }}
        >
          <Typography className={g.h__header}>Assignee</Typography>
        </Stack>
        <Stack onClick={(e: any) => handleClick(e)} sx={{ mr: "48px" }}>
          <Typography className={g.h__header}>Priority</Typography>
        </Stack>
        <Stack
          onClick={(e: any) => handleClick(e)}
          sx={{
            mr: !workspaceLayout ? "85px" : "38px",
            ml: !workspaceLayout ? "100px" : "43px",
          }}
        >
          <Typography className={g.h__header}>Due Date</Typography>
        </Stack>
        <Stack
          onClick={(e: any) => handleClick(e)}
          sx={{ mr: "38px", ml: "93px" }}
        >
          <Typography className={g.h__header}>Add</Typography>
        </Stack>
      </Stack>

      <Stack
        direction={"column"}
        alignItems={"flex-start"}
        sx={{ width: "100%" }}
        gap={1}
      >
        {checklistItemsData && checklistItemsData?.length > 0 ? (
          checklistItemsData?.map((item) => (
            <ChecklistItemPM
              key={item?.id}
              onCheckChange={() =>
                updateChecklistItemData(item?.id, "checked", !item.checked)
              }
              title={item?.title}
              checked={item?.checked}
              showDetails={item?.showDetails}
              componentType={item?.componentType}
              opened={item?.opened}
              statusColor={
                item?.status?.customColorHex || item?.status?.defaultColorHex
              }
              priority={item?.priority}
              dueDate={item?.dueDate}
              item={item}
              updateDroppable={(field: any, value: any) => {
                updateChecklistItemData(item?.id, field, value);
              }}
            />
          ))
        ) : (
          <NoTask />
        )}
      </Stack>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <ColumnControls />
      </Popover>
    </Box>
  );
};

export default ListOverView;
