import React from "react";
import {
  SmallThreeDotMenu,
  SmallThreeDotMenuListInterface,
} from "../../../../common";
import {
  AllDirArrowsIcon,
  ConnectedIcon,
  DeleteIcon,
  EyeIcon,
  RenameIcon,
} from "../../../../../images";

interface ThreeIconDropDownWorkspaceProps {
  displayOption?: "left" | "right";
}

const ThreeIconDropDownWorkspaceOptList: SmallThreeDotMenuListInterface[] = [
  {
    id: 1,
    icon: ConnectedIcon,
    text: "Copy link",
  },
  {
    id: 2,
    icon: AllDirArrowsIcon,
    direction: "right",
    text: "Move to",
  },
  {
    id: 3,
    icon: RenameIcon,
    text: "Rename",
  },
  {
    id: 4,
    icon: EyeIcon,
    text: "View",
  },
  {
    id: 5,
    icon: DeleteIcon,
    text: "Delete Workspace",
    color: "red",
  },
];

const ThreeIconDropDownWorkspace: React.FC<ThreeIconDropDownWorkspaceProps> = ({
  displayOption = "left",
}) => {
  return (
    <SmallThreeDotMenu
      menuItems={ThreeIconDropDownWorkspaceOptList}
      onClickAction={(value) => console.log(value)}
      displayOption={displayOption}
    />
  );
};

export default ThreeIconDropDownWorkspace;
