import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  CheckboxMedium,
  StyledRadioButtonBlack,
} from "../../../../../../common";
import { RepeatOptionMenu } from "../../../RepeatOptionMenu";
import { TravelTimeMenu } from "../../../TravelTimeMenu";
// Define the type for travel time
interface TravelTime {
  time: 0 | 15 | 30 | 45 | 90 | 120;
  direction: "before" | "after" | "both" | undefined; // Direction of travel time
}
interface RepeatTimeViewProps {
  isExpended: boolean;
  onContainerClick: () => void;
  updateField?: any;
}

const RepeatTimeView: React.FC<RepeatTimeViewProps> = ({
  isExpended,
  onContainerClick,
  updateField,
}) => {
  const handleRepeatChange = (
    value: string | { type: string; custom?: any }
  ) => {
    let repeatConfig;
    console.log(value);

    // Check if the value is an object (custom repeat settings)
    if (typeof value === "object") {
      // If it's an object, directly use its properties
      repeatConfig = {
        type: value.type,
        custom: value.custom || undefined,
      };
    } else if (typeof value === "string") {
      // Handle predefined repeat values
      switch (value.toLowerCase()) {
        case "none":
          repeatConfig = {
            type: "none",
            custom: undefined, // No custom fields for 'none'
          };
          break;
        case "daily":
          repeatConfig = {
            type: "daily",
            custom: {
              interval: 1,
              repeatDays: [],
              frequency: "day",
              endDate: null,
            },
          };
          break;
        case "weekly":
          repeatConfig = {
            type: "weekly",
            custom: {
              interval: 1,
              repeatDays: [],
              frequency: "week",
              endDate: null,
            },
          };
          break;
        case "monthly":
          repeatConfig = {
            type: "monthly",
            custom: {
              interval: 1,
              repeatDays: [],
              frequency: "month",
              endDate: null,
            },
          };
          break;
        case "every weekday":
          repeatConfig = {
            type: "weekly",
            custom: {
              interval: 1,
              repeatDays: [
                "monday",
                "tuesday",
                "wednesday",
                "thursday",
                "friday",
              ],
              frequency: "week",
              endDate: null,
            },
          };
          break;
        case "custom":
          repeatConfig = {
            type: "custom",
            custom: {
              interval: 4,
              repeatDays: [],
              frequency: "week",
              endDate: new Date()?.toISOString()?.split?.("T")?.[0],
            },
          };
          break;
        default:
          repeatConfig = {
            type: "none",
            custom: undefined,
          };
      }
    } else {
      // If value is neither string nor object, handle it appropriately (if needed)
      repeatConfig = {
        type: "none",
        custom: undefined,
      };
    }

    // Log the repeatConfig to debug the output
    console.log("Repeat Configuration:", repeatConfig);

    // Update the state to reflect the new repeat configuration
    updateField("repeat", repeatConfig);
  };

  const [travelBefore, setTravelBefore] = useState<boolean>(false);
  const [travelAfter, setTravelAfter] = useState<boolean>(false);
  const [travelTime, setTravelTime] = useState<TravelTime>({
    time: 15, // Default time
    direction: undefined, // Initial direction
  });

  // Function to update travel time and direction
  const updateTravelTime = (
    time: 0 | 15 | 30 | 45 | 90 | 120,
    before: boolean,
    after: boolean
  ) => {
    // Determine direction based on the selections
    const direction =
      before && after
        ? "both"
        : before
        ? "before"
        : after
        ? "after"
        : undefined; // No direction if neither is selected

    const updatedTravelTime: TravelTime = {
      time,
      direction,
    };

    setTravelTime(updatedTravelTime); // Update the local state
    updateField("travelTime", updatedTravelTime); // Call the function to update the parent state
  };

  return (
    <>
      {!isExpended ? (
        <Stack
          onClick={onContainerClick}
          sx={{
            flexDirection: "row",
            height: "40px",
            font: "inherit",
            gap: "20px",
            cursor: "pointer",
          }}
        >
          <Typography sx={{ font: "inherit" }}>All day</Typography>
          <Typography sx={{ font: "inherit" }}>Repeat</Typography>
          <Typography sx={{ font: "inherit" }}>Travel Time</Typography>
        </Stack>
      ) : (
        <Stack sx={{ font: "inherit", gap: "20px" }}>
          {/* ----------- */}
          <Stack
            sx={{
              font: "inherit",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Typography sx={{ font: "inherit" }}>All day</Typography>
            <CheckboxMedium
              onClick={(e: any) => {
                updateField && updateField("allDay", e?.target?.checked);
              }}
              boxSize={15}
            />
          </Stack>
          {/* ----------- */}

          <Stack
            sx={{
              font: "inherit",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Typography sx={{ font: "inherit" }}>Repeats</Typography>
            <RepeatOptionMenu
              onSelect={(value) => {
                handleRepeatChange(value);
              }}
              width="300px"
            />
          </Stack>
          {/* ----------- */}
          <Stack
            sx={{
              font: "inherit",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Typography sx={{ font: "inherit" }}>Travel Time</Typography>
            <TravelTimeMenu
              options="TravelTimeMenuOptionsList120"
              onSelect={(value) => {
                updateTravelTime(
                  value as 15 | 30 | 45 | 90 | 120,
                  travelBefore,
                  travelAfter
                );
              }}
            />

            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                const newValue = !travelBefore;
                setTravelBefore(newValue);
                // Pass the updated before value to the update function
                updateTravelTime(travelTime.time, newValue, travelAfter);
              }}
            >
              <StyledRadioButtonBlack checked={travelBefore} />
              <Typography
                sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
              >
                Before
              </Typography>
            </Stack>

            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                const newValue = !travelAfter;
                setTravelAfter(newValue);
                // Pass the updated after value to the update function
                updateTravelTime(travelTime.time, travelBefore, newValue);
              }}
            >
              <StyledRadioButtonBlack checked={travelAfter} />
              <Typography
                sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
              >
                After
              </Typography>
            </Stack>
          </Stack>
          {/* ----------- */}
        </Stack>
      )}
    </>
  );
};

export default RepeatTimeView;
