import { DateIcon } from "../../../images";
import { DragAndDropMenuListType } from "../DragAndDropMenu";

export const connectEventOptions: DragAndDropMenuListType[] = [
    {
        icon: DateIcon,
        label: "Video call",
        draggable: true,
    },
    {
        icon: DateIcon,
        label: "Calendar call",
        draggable: true,
    },
    {
        icon: DateIcon,
        label: "Calendar event",
        draggable: true,
    },
    {
        icon: DateIcon,
        label: "Audio call",
        draggable: true,
    },
    {
        icon: DateIcon,
        label: "Task assigned",
        draggable: true,
    },
    {
        icon: DateIcon,
        label: "Project assigned",
        draggable: true,
    },
]